/**
 * Colors for 3D rendering
 */
/**
 * OVERLOOK
 */
export const OVERLOOK_EXTERIEUR = [0.486, 0.537, 0.565, 1];
export const OVERLOOK_HEATED_LOCAL = [0.808, 0.729, 0.671, 1];
export const OVERLOOK_UNHEATED_LOCAL = [0.847, 0.835, 0.749, 1];
export const OVERLOOK_FULL_GROUND = [0.729, 0.729, 0.729, 1];

/**
 * RENOVATION
 */
export const RENOVATION = [0, 0, 0.9, 0.6];
