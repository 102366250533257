import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'dpe-icon',
  templateUrl: './dpe-icon.component.html',
  styleUrls: ['./dpe-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpeIconComponent implements OnChanges {
  @Input() labelBefore: string;
  @Input() label: string;
  @Input() mode = 'dpe';
  @Input() value: number;

  readonly labelRange = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];
  readonly dimensions = {
    height: 0,
    width: 0,
  };
  distance: number;
  svgContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this.computeArrow();
  }

  computeArrow(): void {
    if (this.label && this.labelBefore) {
      const valBefore = this.labelRange.indexOf(this.labelBefore.toLowerCase()) || 0;
      const valAfter = this.labelRange.indexOf(this.label.toLowerCase()) || 0;
      this.distance = valBefore - valAfter;
      if (this.distance != 0) {
        this.dimensions.height = 19 * this.distance + 13;
        this.dimensions.width = 10 * this.distance + 2;
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(`
<path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.46967 6.53035C0.176777 6.23746 0.176777 5.76259 0.46967 5.46969L5.24264 0.696721C5.53553
                    0.403827 6.01041 0.403827 6.3033 0.696721C6.59619 0.989614 6.59619 1.46449 6.3033 1.75738L2.81066 5.25002L
                    ${this.dimensions.width + 26.75 - 27} 5.25002V${this.dimensions.height}H${
          this.dimensions.width + 25.25 - 27
        }V6.75002L2.81066 6.75002L6.3033 10.2427C6.59619 10.5356 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.46967 6.53035Z"
                     fill="black"/>
                `);
      } else {
        this.dimensions.height = 16;
        this.dimensions.width = 15;
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(`
                <path d="M2.20451 2.35C3.56473 0.899998 5.43152 -1.67301e-06 7.50469 -1.31052e-06C11.651 -5.85552e-07 15 3.58 15 8C15 12.42 11.651 16 7.50469 16C4.00563 16 1.08818 13.45 0.253285 10L2.2045 10C2.97373 12.33 5.05628 14 7.50469 14C10.6098 14 13.1332 11.31 13.1332 8C13.1332 4.69 10.6098 2 7.50469 2C5.94747 2 4.5591 2.69 3.54597 3.78L6.5666 7L1.6371e-06 7L2.86102e-06 -2.62268e-06L2.20451 2.35Z" fill="black"/>
`);
      }
    } else {
      this.dimensions.height = 0;
      this.dimensions.width = 0;
    }
  }
}
