import { User } from '../model/user.model';

export class UserUtils {
  static getUserDisplayName(user: User) {
    if (!user) {
      return '';
    }
    if (user.firstName || user.lastName) {
      return user.firstName + ' ' + user.lastName;
    }
    return user.email;
  }
}
