'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Topography class
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Space class
//***********************************************************************************
import { fh_polygon, fh_matrix } from '@enerbim/fh-3d-viewer';
import * as cn_configuration_option from '../utils/cn_plugin_option';
import { cn_add, cn_box, cn_dot, cn_mul, cn_normal, cn_normalize, cn_point_on_segment, cn_sub, cnx_add, cnx_dist, cnx_mul, cnx_normalize, cnx_sub } from '../utils/cn_utilities';
import { cn_image_dir } from '../utils/image_dir';
import { cn_contour } from './cn_contour';
import { cn_element } from './cn_element';
import { cn_opening } from './cn_opening';
import { cn_space_measure } from './cn_space_measure';
import { cn_building } from './cn_building';
import * as THREE from 'three';
import { cn_bbp_geometry } from '../utils/cn_bbp_geometry';

export class cn_topography extends cn_element {
    /**
     * Constructor
     * @param {cn_building} building
     */
    constructor(building) {
        super(building);

        //*** Model data
        this.origin = [-10, -10];
        this.size = [20, 20];
        this.heights = [];
        this.z = 0;

        this.max_height = 0;
        this.min_height = 0;

        this.resize(this.origin[0], this.origin[1], this.size[0], this.size[1]);
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.origin = this.origin;
        json.size = this.size;
        json.heights = this.heights;
        return json;
    }

    static unserialize(json, building) {
        var topography = new cn_topography(building);

        if (typeof (json.origin) == 'object')
            topography.origin = json.origin;

        if (typeof (json.size) == 'object')
            topography.size = json.size;

        if (typeof (json.heights) == 'object')
            topography.heights = json.heights;

        topography.resize(topography.origin[0], topography.origin[1], topography.size[0], topography.size[1]);

        return topography;
    }

    //***********************************************************************************
    /**
     * Returns grid height at given indices. If indices out of scope, returns 0.
     */
    draw_topography(camera, sampling) {
    }

    //***********************************************************************************
    /**
     * Returns grid height at given indices. If indices out of scope, returns 0.
     * @param {number} i
     * @param {number} j
     * @returns {number}
     */
    get_height(i, j) {
        if (i < 0 || i >= this.size[0]) return 0;
        if (j < 0 || j >= this.size[1]) return 0;
        const index = i + this.size[0] * j;
        if (index >= this.heights.length) return 0;
        return this.heights[index];
    }

    //***********************************************************************************
    /**
     * Returns point at given indices
     * @param {number} i
     * @param {number} j
     * @returns {number[]}
     */
    get_point(i, j) {
        const pt = [this.origin[0] + i, this.origin[1] + j, 0];
        pt[2] = this.compute_height(pt);
        return pt;
    }

    //***********************************************************************************
    /**
     * Computes height at give position
     * @param {number[]} pt
     * @returns {number}
     */
    compute_height(pt) {
        const x = pt[0] - this.origin[0];
        const y = pt[1] - this.origin[1];
        const i0 = Math.floor(x);
        const j0 = Math.floor(y);

        var z = 0;
        for (var k = 0; k < 4; k++) {
            const tx = (k & 1) ? x - i0 : 1 - x + i0;
            const ty = (k & 2) ? y - j0 : 1 - y + j0;
            z += tx * ty * this.get_height(i0 + (k & 1), j0 + (k & 2) / 2);
        }
        return z + this.z;
    }

    //***********************************************************************************
    /**
     * Resize, keeping existing heights
     * @param {number} ofx
     * @param {number} ofy
     * @param {number} sfx
     * @param {number} sfy
     */
    resize(ofx, ofy, sfx, sfy) {
        const minsz = 16;
        var ox = 0;
        var oy = 0;
        var sx = minsz;
        var sy = minsz;
        while (ox > ofx) ox -= minsz;
        while (oy > ofy) oy -= minsz;
        while (ox + sx < ofx + sfx) sx += minsz;
        while (oy + sy < ofy + sfy) sy += minsz;
        sx++;
        sy++;

        var new_heights = Array(sx * sy);
        new_heights.fill(0);
        for (var j = 0; j < sy; j++) {
            for (var i = 0; i < sx; i++)
                new_heights[i + j * sx] = this.get_height(i + ox - this.origin[0], j + oy - this.origin[1]);
        }
        this.origin = [ox, oy];
        this.size = [sx, sy];
        this.heights = new_heights;
    }

    //***********************************************************************************
    /**
     * Set height at given position, and set neighbouring heights
     * @param {number} i : x position to set height to
     * @param {number} j : y position to set height to
     * @param {number} h : new value for height
     * @param {number} range : range on which values are changed
     */
    set_height_smart(i, j, h, range) {
        if (i < 0 || i >= this.size[0]) return;
        if (j < 0 || j >= this.size[1]) return;

        const delta = h - this.get_height(i, j);
        for (var jj = j - range + 1; jj < j + range; jj++) {
            if (jj < 0) continue;
            if (jj >= this.size[1]) break;
            const ty = 1 - Math.abs(jj - j) / range;
            for (var ii = i - range + 1; ii < i + range; ii++) {
                if (ii < 0) continue;
                if (ii >= this.size[0]) break;
                const tx = 1 - Math.abs(ii - i) / range;
                this.heights[ii + jj * this.size[0]] += delta * tx * ty;
            }
        }
        this._update_max_height();
    }

    /**
     * Reset all heights at given value
     */
    reset_heights(value = 0) {
        for (var k = 0; k < this.heights.length; k++)
            this.heights[k] = value;
        this._update_max_height();
    }

    //***********************************************************************************
    /**
     * Projects a 3D polygon on this topography, and returns a tesselation
     * @param {fh_polygon} polygon
     */
    polygon_to_tesselation(polygon) {

        var tesselation = new cn_bbp_geometry(false)
        const bb = polygon.get_bounding_box();

        for (var j = Math.floor(bb.position[1]); j < Math.ceil((bb.position[1] + bb.size[1])); j++) {
            for (var i = Math.floor(bb.position[0]); i < Math.ceil((bb.position[0] + bb.size[0])); i++) {
                const tile = new fh_polygon([0, 0, 0], [0, 0, 1]);
                tile.add_contour([[i, j, 0], [i + 1, j, 0], [i + 1, j + 1, 0], [i, j + 1, 0]]);
                tile.intersects(polygon);
                tile.compute_tesselation();
                const offset = tesselation.vertices.length;
                tesselation.vertices = tesselation.vertices.concat(tile.tesselation_vertices);
                tesselation.triangles = tesselation.triangles.concat(tile.tesselation_triangles.map(tr => tr + offset));
            }
        }

        tesselation.vertices.forEach(v => {
            v[2] = this.compute_height(v);
        });
        // @ts-ignore
        tesselation.vertices = tesselation.vertices.flat();

        tesselation.extension_data = cn_bbp_geometry._polygon_extension_data(polygon, 0);
        tesselation.extension_data.contour_sizes = [];
        tesselation.extension_data.contour_vertices = [];
        var offset = 0;
        for (var nct = 0; nct < polygon.contour_sizes.length; nct++) {
            const sz = polygon.contour_sizes[nct];
            var nsz = tesselation.extension_data.contour_vertices.length;
            for (var n = 0; n < sz; n++) {
                const p0 = polygon.contour_vertices[offset + n];
                const p1 = polygon.contour_vertices[offset + ((n + 1) % sz)];
                const dir = cnx_sub(p1, p0);
                const length = cnx_normalize(dir);
                for (var x = 0; x < length; x += 0.5) {
                    tesselation.extension_data.contour_vertices.push(cnx_add(p0, cnx_mul(dir, x)));
                }
            }
            tesselation.extension_data.contour_sizes.push(tesselation.extension_data.contour_vertices.length - nsz);
            offset += sz;
        }
        tesselation.extension_data.contour_vertices.forEach(v => {
            v[2] = this.compute_height([v[0], v[1]]);
        });
        tesselation.extension_data.contour_vertices = tesselation.extension_data.contour_vertices.flat();

        return tesselation;
    }

    //***********************************************************************************
    /**
     * Sets height for a list of points in the topography, and smooths according to range on neighbouring points.
     * @param {number[][]} points
     * @param {number} height
     * @param {number} range
     */
    flatten(points, height, range) {
        const sx = this.size[0];
        const sy = this.size[1];

        var smoothing = Array(sx * sy);
        smoothing.fill(0);

        var delta_list = Array(sx * sy);
        delta_list.fill(0);

        //*** initial fill */
        points.forEach(pt => {
            if (pt[0] >= 0 && pt[0] < sx && pt[1] >= 0 && pt[1] < sy) {
                smoothing[pt[0] + pt[1] * sx] = range;
                delta_list[pt[0] + pt[1] * sx] = height - this.heights[pt[0] + pt[1] * sx];
            }
        });

        //*** loop on range */
        for (var r = range - 1; r > 0; r--) {
            for (var j = 0; j < sy; j++) {
                for (var i = 0; i < sx; i++) {
                    if (smoothing[i + j * sx] != 0) continue;
                    var found = 0;
                    var delta = 0;
                    for (var k = 0; k < 4; k++) {
                        var index = i + j * sx;
                        if (k & 1) {
                            if (i == sx - 1) continue;
                            index++;
                        } else {
                            if (i == 0) continue;
                            index--;
                        }
                        if (k & 2) {
                            if (j == sy - 1) continue;
                            index += sx;
                        } else {
                            if (j == 0) continue;
                            index -= sx;
                        }
                        if (smoothing[index] == r + 1) {
                            found++;
                            delta += delta_list[index];
                        }
                    }
                    if (found > 0) {
                        smoothing[i + j * sx] = r;
                        delta_list[i + j * sx] = delta / found;
                    }
                }
            }
        }

        //*** apply smoothing */
        for (var n = 0; n < sx * sy; n++) {
            if (smoothing[n] == 0) continue;
            this.heights[n] += delta_list[n] * smoothing[n] / range;
        }
        this._update_max_height();
    }

    //***********************************************************************************
    /**
     * Creates a dummy distribution of non zero heights
     */
    dummy_heights() {
        for (var j = 0; j < this.size[1]; j++) {
            const hy = Math.cos(j * 0.2);
            for (var i = 0; i < this.size[0]; i++) {
                const hx = Math.cos(i * 0.2);
                this.set_height_smart(i, j, hx * hy, 1);
            }
        }
    }

    //***********************************************************************************
    /**
     * Compute vertical offset for an element made of a list of 3D vertices.
     * if 'above' is 'true' offset will be computed so that no vertex is under ground.
     * Otherwise,
     *
     * @param {number[]} vertices
     * @param {fh_matrix} matrix
     * @param {boolean} above : if 'true', offset will be computed so that
     */
    compute_offset(vertices, matrix, above) {
        var best_h = (above) ? -10000 : 10000;
        var lower_z = 10000;
        for (var nv = 0; nv < vertices.length; nv += 3) {
            const vtx = [vertices[nv], vertices[nv + 1], vertices[nv + 2]];
            const v = (matrix) ? matrix.transform_point(vtx) : vtx;
            if (v[2] < lower_z) lower_z = v[2];
            const h = this.compute_height(v);
            if (above) {
                if (h > best_h) best_h = h;
            } else {
                if (h < best_h) best_h = h;
            }
        }
        return best_h - lower_z;
    }

    _update_max_height() {
        this.max_height = this.heights[0];
        this.min_height = this.heights[0];
        this.heights.forEach(h => {
            if (h > this.max_height) this.max_height = h;
            if (h < this.min_height) this.min_height = h;
        });
    }

    update_3d(building_3d) {
        const objects = building_3d.get_topography_objects();
        objects.forEach(object => {
            object._meshes.forEach(mesh => {
                mesh.geometry.vertices.forEach(vtx => {
                    const zzz = this.compute_height([vtx.x, vtx.y]);
                    vtx.z = zzz;
                });
                mesh.geometry.verticesNeedUpdate = true;
                mesh.geometry.normalsNeedUpdate = true;
                mesh.geometry.colorsNeedUpdate = true;
                mesh.geometry.elementsNeedUpdate = true;
                mesh.geometry.computeFaceNormals();
                mesh.geometry.computeBoundingBox();
                mesh.geometry.computeBoundingSphere();
            });
            object.json_object.geometries.filter(geo => geo.extension_data && geo.extension_data.contour_sizes).forEach(geo => {
                for (var k = 0; k < geo.extension_data.contour_vertices.length; k += 3)
                    geo.extension_data.contour_vertices[k + 2] = this.compute_height([geo.extension_data.contour_vertices[k], geo.extension_data.contour_vertices[k + 1]]);
            });
        })

        //*** Transform upon ground elements */
        const upon_ground_elements = building_3d.get_topography_objects('upon');
        upon_ground_elements.forEach(obj => {
            var best_h = 10000;
            var lower_z = 10000;
            const matvtx = new THREE.Vector3();
            obj.children.forEach(mesh => {
                if (mesh._is_3d && typeof (mesh.geometry) == 'object' && typeof (mesh.geometry.vertices) == 'object') {
                    mesh.geometry.vertices.forEach(vtx => {
                        matvtx.copy(vtx);
                        matvtx.applyMatrix4(mesh.matrix);
                        if (matvtx.z < lower_z) lower_z = matvtx.z;
                        const h = this.compute_height([matvtx.x, matvtx.y]);
                        if (h < best_h) best_h = h;
                    });
                }
            });
            //lower_z += obj.position.z;
            obj.position.z = best_h - lower_z;
            obj.json_object.geometries.forEach(g => g.zoffset = best_h);
            obj.updateMatrix();
        });

        //*** Transform above ground elements */
        const above_ground_elements = building_3d.get_topography_objects('above');
        above_ground_elements.forEach(obj => {
            var best_h = -10000;
            var lower_z = 10000;
            const matvtx = new THREE.Vector3();
            obj.children.forEach(mesh => {
                if (mesh._is_3d && typeof (mesh.geometry) == 'object' && typeof (mesh.geometry.vertices) == 'object') {
                    mesh.geometry.vertices.forEach(vtx => {
                        matvtx.copy(vtx);
                        matvtx.applyMatrix4(mesh.matrix);
                        if (matvtx.z < lower_z) lower_z = matvtx.z;
                        const h = this.compute_height([matvtx.x, matvtx.y]);
                        if (h > best_h) best_h = h;
                    });
                }
            });
            //lower_z += obj.position.z;
            obj.position.z = best_h - lower_z;
            obj.updateMatrix();
        });
    }
}

