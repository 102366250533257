import { cn_add, cn_dot, cn_mul, cn_normal, cn_sub } from './cn_utilities';

/**
 *
 * @param {number[]} p
 * @param {number} orientation
 * @param {number[]} offset
 * @param {number[]} image_size
 * @param {number} scale
 * @returns
 */
export function to_image(p, orientation, offset, image_size, scale) {
    const angle = orientation * Math.PI / 180;
    const dx = [Math.cos(angle), Math.sin(angle)];
    const dy = cn_normal(dx);
    const pp = cn_sub(p, offset);
    const x = cn_dot(pp, dx);
    const y = cn_dot(pp, dy);
    return [0.5 + x / (image_size[0] * scale), 0.5 + y / (image_size[1] * scale)];
}

/**
 *
 * @param {number[]} p
 * @param {number} orientation
 * @param {number[]} offset
 * @param {number[]} image_size
 * @param {number} scale
 * @returns
 */
export function to_world(p, orientation, offset, image_size, scale) {
    const angle = orientation * Math.PI / 180;
    let dx = [Math.cos(angle), Math.sin(angle)];
    let dy = cn_normal(dx);
    dx = cn_mul(dx, image_size[0] * scale);
    dy = cn_mul(dy, image_size[1] * scale);
    return cn_add(offset, cn_add(cn_mul(dx, p[0] - 0.5), cn_mul(dy, p[1] - 0.5)));
}
