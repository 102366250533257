<div id="fh_3d_container" [ngClass]="{ higher: resultPage }">
  <div class="map_window" id="fh_map_container"></div>
</div>
<div class="tools mat-elevation-z4" (touchstart)="t($event)">
  <div class="py-1">
    <button
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="appMenu"
      color="primary"
      mat-button
      (touchstart)="menuTrigger.openMenu()">
      <mat-icon>layers</mat-icon>
      <span>Niveau {{ selectedStorey.text }}</span>
      <mat-icon *ngIf="!menuTrigger.menuOpen">expand_more</mat-icon>
      <mat-icon *ngIf="menuTrigger.menuOpen">expand_less</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
      <button (click)="changeCurrentStorey(storey.levelIndex)" *ngFor="let storey of storeyMenu" mat-menu-item>
        {{ storey.text || storey.levelIndex }}
      </button>
    </mat-menu>
  </div>
  <div class="py-1 d-flex justify-content-between align-items-center">
    <button
      mat-button
      color="primary"
      matTooltip="Zoom avant"
      (click)="zoom(true)"
      (mousedown)="zoom(true)"
      (touchstart)="zoom(true); t($event)">
      <mat-icon>zoom_in</mat-icon>
    </button>
    <button
      mat-button
      color="primary"
      matTooltip="Zoom arrière"
      (click)="zoom(false)"
      (mousedown)="zoom(false)"
      (touchstart)="zoom(false); t($event)">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <button
      mat-button
      color="primary"
      matTooltip="Recentrer la vue"
      (click)="resetView()"
      (mousedown)="resetView()"
      (touchstart)="resetView(); t($event)">
      <mat-icon>center_focus_strong</mat-icon>
    </button>
  </div>
</div>
