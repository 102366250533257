'use strict';

import { cn_event_manager } from './cn_event_manager';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_camera } from './cn_camera';

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_event_handler
 * An abstract class to manage mouse events
 */

export class cn_event_handler extends cn_event_manager {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_event_manager} parent
     */
    constructor(parent = null) {
        super(parent);

        this.drawing_storey = null;
        this._handlers = [];
        this._focus_handler = null;
        this.visible = true;
        this.active = true;
    }

    /**
     * Removes a handler
     * @param {cn_event_manager} h
     */
    remove_handler(h) {
        const index = this._handlers.indexOf(h);
        if (index >= 0) this._handlers.splice(index, 1);
    }

    /**
     * Draws in svg
     * @param {cn_camera} camera
     * @returns
     */
    draw(camera) {
        if (!this.visible || !this.active) return '';
        var html = '';
        if (this.drawing_storey) camera.storey = this.drawing_storey;
        this._handlers.forEach(h => {
            if (h.drawing_storey) camera.storey = h.drawing_storey;
            if (h.visible) html += h.draw(camera);
        });
        return html;
    }

    /**
     * Clear move effects
     */
    clear_move() {
        this._handlers.forEach(h => h.clear_move());
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    move(mouse_event) {
        if (!this.active) return false;
        this.clear_move();
        this._focus_handler = this._handlers.concat([]).reverse().find(h => h.active && h.move(mouse_event));
        return !!this._focus_handler;
    }

    /**
     * Manage a grab. To return 'true' if grab and drag is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab(mouse_event) {
        this._dragging_handler = null;
        if (!this.active) return false;
        if (this._focus_handler && this._focus_handler.grab(mouse_event)) {
            this._dragging_handler = this._focus_handler;
            return true;
        }
        return false;
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    drag(mouse_event) {
        return (this._dragging_handler) ? this._dragging_handler.drag(mouse_event) : false;
    }

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    drop(mouse_event) {
        return (this._dragging_handler) ? this._dragging_handler.drop(mouse_event) : false;
    }

    /**
     * Manage a click. Only after a grab that returned true, and no drag. To return 'true' if click had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    click(mouse_event) {
        return (this._focus_handler) ? this._focus_handler.click(mouse_event) : false;
    }

    /**
     * Manage a wheel event. To return 'true' if it had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    wheel(mouse_event) {
        return false;
    }

    /**
     * Manage a keyboard event. To return 'true' if it had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    keydown(mouse_event) {
        return false;
    }

    /**
     * During a drag and drop, the owner of the drag and drop may change.
     * Return true to accept owning the drag and drop events.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab_element(mouse_event) {
        return false;
    }

    /**
     * Manage a drag, during a drag and drop.
     * This may follow:
     * - Either a grab event, where drag and drop has been triggered (by setting drag_and_drop_element on the mouse event)
     * - Either after an accepted grab_element event.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    drag_element(mouse_event) {
        return false;
    }

    /**
     * If drag and dropped is passed on another event handler,
     * this method is called on the previous owner of the drag and drop.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    drag_element_stop(mouse_event) {
        return false;
    }

    /**
     * Manage a drop, in a drag and drop event.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    drop_element(mouse_event) {
        return false;
    }

}

