import { Perimeter, User } from './user.model';

export enum CompanyRights {
  TECHNICIAN = 'TECHNICIAN',
  TECHNICIAN_EXPERIENCED = 'TECHNICIAN_EXPERIENCED',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
  ADMIN = 'ADMIN',
}

export type CompanyType = 'PRODUCTIVE' | 'ADMINISTRATIVE' | 'INDIVIDUAL';

export class CompanyMember {
  constructor(public user?: User, public rights?: CompanyRights, public version?: number) {}
}

export class Company {
  id?: string;
  name?: string;
  type?: CompanyType;
  address?: string;
  phone?: string;
  createdDate?: string;
  enabled?: boolean;
  membersCount?: number;
  members?: CompanyMember[];
  perimeters?: Perimeter[];
  /** Liste des ids des entités parentes */
  ancestors?: string[];
  /** Droits accordés à l'utilisateur sur la société. */
  rights?: CompanyRights;
  /** Droits étendus : droits ADMIN si l'utilisateur courant est superadmin, sinon droits accordés à l'utilisateur sur la société. */
  extendedRights?: CompanyRights;
  version?: number;
}
