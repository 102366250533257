import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Company, CompanyMember, CompanyRights } from '../model/company.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompanyStore } from '../store/company.store';
import { AuthenticationStore } from '../../core/auth/authentication.store';
import { User } from '../model/user.model';

export interface CompanyOptions {
  includeIndividual?: boolean;
  includeDescendants?: boolean;
  includeMembersDetails?: boolean;
  onlyWithAdminRights?: boolean;
}

/**
 * Service pour les sociétés.
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  public resourceUrl = environment.apiUrl + '/company';

  constructor(
    private http: HttpClient,
    private authenticationStore: AuthenticationStore,
    private companyStore: CompanyStore,
  ) {}

  getCurrentUserCompaniesWithDescendants(): Observable<Company[]> {
    return this.findAllForCurrentUser({
      includeDescendants: true,
      includeIndividual: true,
    });
  }

  getCurrentUserCompaniesForAdmin(options?: CompanyOptions): Observable<Company[]> {
    return this.findAllForCurrentUser({
      onlyWithAdminRights: true,
      includeIndividual: true,
      includeDescendants: true,
      ...(options || {}),
    });
  }

  reloadCurrentCompany(): void {
    this.companyStore
      .getCurrentCompanyOrNull()
      .pipe(
        take(1),
        switchMap(currentCompanyValue => {
          this.companyStore.unsetCurrentCompany();
          return currentCompanyValue ? this.findOne(currentCompanyValue.id) : of(null);
        }),
      )
      .subscribe(company => {
        this.companyStore.setCurrentCompany(company);
      });
  }

  findAllForCurrentUser(options: CompanyOptions): Observable<Company[]> {
    let params = new HttpParams();
    if (options.includeIndividual) params = params.set('includeIndividual', 'true');
    if (options.includeDescendants) params = params.set('includeDescendants', 'true');
    if (options.includeMembersDetails) params = params.set('includeMembersDetails', 'true');
    if (options.onlyWithAdminRights) params = params.set('onlyWithAdminRights', 'true');
    return this.http.get<Company[]>(this.resourceUrl, { params });
  }

  findOne(id: string): Observable<Company> {
    return this.http.get<Company>(this.resourceUrl + `/${id}`);
  }

  create(companyInfos: Company, manager: User): Observable<Company> {
    const companyWithManager: Company = JSON.parse(JSON.stringify(companyInfos));
    companyWithManager.members = [new CompanyMember(manager, CompanyRights.ADMIN)];
    return this.http.post<Company>(this.resourceUrl, companyWithManager);
  }

  update(companyInfos: Company): Observable<Company> {
    return this.http.post<Company>(this.resourceUrl, companyInfos);
  }

  searchUserByEmail(query: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.resourceUrl}/user/search-by-email/${query}`);
  }

  searchUserByEmailAndCompany(query: string, companyId: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.resourceUrl}/${companyId}/user/search-by-email/${query}`);
  }

  createUser(companyId: string, user: User, rights?: CompanyRights): Observable<User> {
    let params = new HttpParams();
    if (rights) {
      params = params.set('rights', rights);
    }
    return this.http.post<User>(`${this.resourceUrl}/${companyId}/user`, user, { params });
  }

  updateUserRights(companyId: string, userId: string, rights: CompanyRights, version: number): Observable<any> {
    return this.http.post(`${this.resourceUrl}/${companyId}/user/${userId}`, { rights, version });
  }

  deleteUser(companyId: string, userId: string): Observable<User> {
    return this.http.delete<User>(`${this.resourceUrl}/${companyId}/user/${userId}`);
  }

  restoreUser(companyId: string, userId: string): Observable<any> {
    return this.http.post(`${this.resourceUrl}/${companyId}/user/${userId}/restore`, {});
  }

  reloadCompaniesForUser$(): Observable<void> {
    return this.getCurrentUserCompaniesWithDescendants().pipe(
      tap(companies =>
        this.authenticationStore.setCompanies(companies.sort((c1, c2) => c1.name.localeCompare(c2.name))),
      ),
      map(() => {}),
    );
  }
}
