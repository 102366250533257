import { Injectable, NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'trustHtml' })
export class TrustHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
