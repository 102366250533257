'use strict';
import { cn_roof_height } from '../model/cn_roof_height';
import { cn_svg_map } from './cn_svg_map';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_edit_box } from './cn_edit_box';
import { cn_edition_handler } from './cn_edition_handler';

export class cn_roof_height_handler extends cn_edition_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_roof_height>} heights
     * @param {cn_svg_map} map
     * @param {boolean} creation
     */
    constructor(heights, map, creation = false) {
        super(heights, map);

        this._height = (heights.length == 1) ? heights[0] : null;
        this._map = map;
        this._scene = this._map._scene;
        this._transaction_manager = this._map._building.transaction_manager;

        //*** Edit box for mass selection */
        const edit_box = new cn_edit_box(this, heights, this._readOnly);
        this._handlers.push(edit_box);

        if (!this._readOnly) {
            edit_box.add_lock_pastille(this._transaction_manager);
        }
    }

    //***********************************************************************************
    //**** Draws
    //***********************************************************************************
    draw(camera) {
        var html = '';

        html += super.draw(camera);

        return html;
    }

    //***********************************************************************************
    //**** clear move effects
    //***********************************************************************************
    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    move(ev) {
        if (super.move(ev)) return true;

        this._focus_handler = this;
        if (this._height && this._height.custom_mouseover(ev.mouse_world, ev.camera)) return true;
        this._focus_handler = null;
        return false;
    }

    grab(ev) {
        if (this._focus_handler == this) return true;
        return super.grab(ev);
    }

    drop(ev) {
        if (this._focus_handler == this) return true;
        return super.drop(ev);
    }

    click(ev) {
        if (this._focus_handler == this) return true;
        return super.click(ev);
    }

    drag(ev) {
        if (this._focus_handler == this) {
            if (this._height && !this._height.locked) {
                const was_locked = this._height.locks[0];
                var scene = this._map._scene;
                this._transaction_manager.push_transaction('Déplacement de hauteur', this._height.ID, () => {
                    if (was_locked) {
                        scene.update();
                        scene.update_deep();
                        this._map.call('roof_update');
                    }
                });
                this._transaction_manager.push_item_set(this._height, ['position', 'slab', 'locks']);

                this._height.position = ev.mouse_world;
                this._height.slab = this._scene.find_slab(ev.mouse_world);
                this._height.locks = [false];
                this._height.update();
                if (was_locked) {
                    scene.update();
                    scene.update_deep();
                    this._map.call('roof_update');
                }
            }
            return true;
        }
        return super.drag(ev);
    }
}

