import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirmation/confirm-dialog.component';
import { Observable } from 'rxjs';

export interface ConfirmationOptions {
  message?: string;
  titleLabel?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  warn?: boolean;
  messageCheckboxConfirm?: string;
}

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(public dialog: MatDialog) {}

  confirm(input: string | ConfirmationOptions, callback: () => any, callbackCancel?: () => any): MatDialogRef<any> {
    if (typeof input === 'string') {
      return this.doConfirm({ message: input }, callback, callbackCancel);
    }
    return this.doConfirm(input, callback, callbackCancel);
  }

  confirmWarn(input: string | ConfirmationOptions, callback: () => any, callbackCancel?: () => any): MatDialogRef<any> {
    if (typeof input === 'string') {
      return this.doConfirm({ message: input, warn: true }, callback, callbackCancel);
    }
    return this.doConfirm({ ...input, warn: true }, callback, callbackCancel);
  }

  confirmWarn$(input: string | ConfirmationOptions): Observable<boolean> {
    const confirmationOptions = typeof input === 'string' ? { message: input, warn: true } : { ...input, warn: true };
    return new Observable<boolean>(observer => {
      this.doConfirm(
        confirmationOptions,
        () => {
          observer.next(true);
          observer.complete();
        },
        () => {
          observer.next(false);
          observer.complete();
        },
      );
    });
  }

  private doConfirm(options: ConfirmationOptions, callback: () => any, callbackCancel?: () => any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: options });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult === true && callback) {
        callback();
      } else if (dialogResult === false && callbackCancel) {
        callbackCancel();
      }
    });
    return dialogRef;
  }
}
