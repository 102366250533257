import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export class NotificationModalData {
  constructor(
    public message: string,
    public actionMessage?: string,
    public icon?: string,
    public showClose?: boolean,
  ) {}
}

@Component({
  selector: 'lib-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationModalData,
    public ref: MatSnackBarRef<NotificationComponent>,
  ) {}

  ngOnInit() {}

  action() {
    this.ref.dismissWithAction();
  }

  close() {
    this.ref.dismiss();
  }
}
