'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_roof : class that contains all roof data to be drawn
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_roof_line } from './cn_roof_line';
import { cn_roof_vertex } from './cn_roof_vertex';
import { cn_roof_slab, ROOF_SLAB_EXTERIOR_LABEL } from './cn_roof_slab';
import { cn_roof_opening } from './cn_roof_opening';
import { cn_roof_height } from './cn_roof_height';
import { cn_add, cn_box, cn_clone, cn_dist, cn_dot, cn_mul, cn_normal, cn_polar } from '../utils/cn_utilities';
import { cn_roof_engine } from './cn_roof_engine';
import { cn_roof_contour } from './cn_roof_contour';
import { fh_polygon } from '@enerbim/fh-3d-viewer';
import { colors } from '../utils/cn_zpso_color';
import { cn_object_instance } from './cn_object_instance';
import { cn_layer } from './cn_layer';
import { cn_roof_dormer } from './cn_roof_dormer';
import { cn_wall } from './cn_wall';
import { logger } from '../utils/cn_logger';

const USE_ROOF_HEIGHTS = true;

export class cn_roof extends cn_element {

    constructor(storey) {
        super(storey);

        this.removable = false;

        //*** Scene data
        this.vertices = [];
        this.lines = [];
        this.slabs = [];
        this.heights = [];
        this.openings = [];
        this.openings = [];
        this.roof_dormers = [];
        this.object_instances = [];

        //*** volatile data
        this.storey = storey;
        this.building = storey.building;
        this.roof_footprint = null;
        this.upper_footprint = null;
        this.actual_footprint = null;
        this.draw_zpsos = [];
        this.draw_samplings = false;
        this.draw_comments = false;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;

        json.vertices = [];
        for (var i in this.vertices) {
            this.vertices[i].s_index = parseInt(i);
            json.vertices.push(this.vertices[i].serialize());
        }
        json.lines = [];
        for (var i in this.lines) {
            this.lines[i].s_index = parseInt(i);
            json.lines.push(this.lines[i].serialize());
        }
        json.slabs = [];
        for (var i in this.slabs) {
            this.slabs[i].s_index = parseInt(i);
            json.slabs.push(this.slabs[i].serialize());
        }

        json.heights = [];
        for (var i in this.heights) {
            json.heights.push(this.heights[i].serialize());
        }

        json.openings = [];
        for (var i in this.openings) {
            this.openings[i].s_index = parseInt(i);
            json.openings.push(this.openings[i].serialize());
        }

        json.roof_dormers = [];
        for (var i in this.roof_dormers) {
            this.roof_dormers[i].s_index = parseInt(i);
            json.roof_dormers.push(this.roof_dormers[i].serialize());
        }

        json.object_instances = this.object_instances.map(oi => oi.serialize());

        return json;
    }

    static unserialize(json, storey) {
        if (typeof (json.vertices) != 'object')
            throw 'Error reading roof : \'vertices\' not found';
        if (typeof (json.lines) != 'object')
            throw 'Error reading roof : \'lines\' not found';
        if (typeof (json.slabs) != 'object')
            throw 'Error reading roof : \'slabs\' not found';

        var roof = new cn_roof(storey);
        if (typeof (json.ID) == 'string') roof.ID = json.ID;

        for (var i in json.vertices)
            cn_roof_vertex.unserialize(json.vertices[i], roof);

        for (var i in json.lines)
            cn_roof_line.unserialize(json.lines[i], roof);

        for (var i in json.slabs)
            cn_roof_slab.unserialize(json.slabs[i], roof);

        for (var i in json.heights)
            cn_roof_height.unserialize(json.heights[i], roof);

        if (typeof (json.openings) == 'object') {
            for (var i in json.openings)
                cn_roof_opening.unserialize(json.openings[i], roof);
        }

        if (typeof (json.roof_dormers) == 'object') {
            for (var i in json.roof_dormers)
                cn_roof_dormer.unserialize(json.roof_dormers[i], roof);
        }

        if (typeof (json.object_instances) == 'object') {
            json.object_instances.forEach(oi => cn_object_instance.unserialize(oi, roof));
        }

        roof.update();
        roof.update_deep();
        return roof;
    }


    //***********************************************************************************
    /**
     * Checks existence of an element in the scene
     * @param {any} element
     * @returns {boolean}
     */
    check_element(element) {
        if (element == null) return false;
        if (element.constructor == cn_roof_vertex)
            return this.vertices.indexOf(element) >= 0;
        if (element.constructor == cn_roof_line)
            return this.lines.indexOf(element) >= 0;
        if (element.constructor == cn_roof_slab)
            return this.slabs.indexOf(element) >= 0;
        if (element.constructor == cn_roof_height)
            return this.heights.indexOf(element) >= 0;
        if (element.constructor == cn_roof_opening)
            return this.openings.indexOf(element) >= 0;
        if (element.constructor == cn_roof_dormer)
            return this.roof_dormers.indexOf(element) >= 0;
        if (element.constructor == cn_object_instance)
            return this.object_instances.indexOf(element) >= 0;
        return false;
    }

    //***********************************************************************************
    //**** Build roof footprint
    //***********************************************************************************
    build_footprint(z) {
        var pg = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.slabs) {
            pg.unites(this.slabs[i].build_slab_polygon(z));
        }
        return pg;
    }

    //***********************************************************************************
    //**** Check roof contour
    //***********************************************************************************
    check_roof_contour() {
        var footprint = this.build_footprint(0);
        var area = footprint.get_area();
        if (this.upper_footprint) footprint.substracts(this.upper_footprint);
        if (this.roof_footprint) {
            const fp = this.roof_footprint.clone()
            fp.offset(0.01);
            if (this.upper_footprint) fp.substracts(this.upper_footprint);
            fp._compute_area();
            const new_fp_area = fp.get_area();
            fp.intersects(footprint);
            const intersect_rfp_area = fp.get_area();
            if (Math.abs(new_fp_area - area) > 0.01 || Math.abs(new_fp_area - intersect_rfp_area) > 0.01) {
                return false;
            }
        }
        return true;
    }

    //***********************************************************************************
    //**** update from polygon
    //***********************************************************************************
    update_from_footprint(roof_footprint, upper_footprint) {
        var t0 = new Date();

        //*** Check roof contour */
        this.roof_footprint = roof_footprint;
        this.upper_footprint = upper_footprint;
        if (this.check_roof_contour()) {
            logger.log('Roof still ok !');
            return;
        }

        logger.log('Reset geometry for roof');
        this.reset_geometry();

        var t1 = new Date();

        logger.log('update_from_footprint time : ' + (t1.getTime() - t0.getTime()));
    }

    //***********************************************************************************
    //**** returns true if base geometry is up to date
    //***********************************************************************************
    up_to_date() {
        return (this.actual_footprint == null);
    }

    //***********************************************************************************
    //**** initialize from polygon
    //***********************************************************************************
    reset_geometry() {
        if (this.roof_footprint == null) return;

        var footprint = this.roof_footprint.clone();
        footprint.offset(0.01);
        if (this.upper_footprint)
            footprint.substracts(this.upper_footprint);

        var old_vertices = this.vertices;
        var old_lines = this.lines;

        this.vertices = [];
        this.lines = [];
        this.slabs = [];
        this.heights = [];
        var polygons = footprint.split();
        for (var np = 0; np < polygons.length; np++) {
            var vertex_offset = this.vertices.length;

            var slab = new cn_roof_slab(this);
            this.slabs.push(slab);
            slab.slab_type = this.building.get_roof_types()[0];

            //*** build vertices
            polygons[np].compute_contours();
            for (var i in polygons[np].contour_vertices) {
                var vtx = new cn_roof_vertex(polygons[np].contour_vertices[i]);
                vtx.liberties = 0;
                this.vertices.push(vtx);
            }

            //*** Build lines
            var offset = 0;
            for (var nct = 0; nct < polygons[np].contour_sizes.length; nct++) {
                var sz = polygons[np].contour_sizes[nct];
                var v0 = this.vertices[vertex_offset + offset + sz - 1];
                var first_line = null;
                for (var nv = 0; nv < sz; nv++) {
                    var v1 = this.vertices[vertex_offset + offset + nv];
                    var line = new cn_roof_line(v1, v0);
                    line.fixed = true;
                    this.lines.push(line);
                    v0 = v1;
                }
                offset += sz;
            }
        }

        this.build_automatic_slabs();
    }

    //***********************************************************************************
    //**** run scene diagnostic
    //***********************************************************************************
    run_diagnostic() {

        var errors = this.run_line_diagnostic();

        this.build_automatic_slabs();

    }

    run_line_diagnostic() {
        var errors = 0;
        var vertex_merge = false;
        while (true) {
            var change = false;
            for (var i in this.lines) {
                var l = this.lines[i];

                if (l.bounds.length < 0.001) {
                    logger.log('WARNING : wall with length 0');
                    errors++;
                    this.merge_vertices(l.vertices[0], l.vertices[1]);
                    vertex_merge = true;
                    change = true;
                    break;
                }
            }
            if (!change) break;
        }

        return errors;
    }

    //***********************************************************************************
    //**** Refresh
    //***********************************************************************************
    draw(camera) {
        var html = '';
        const zpsos_higlighted_elements = new Map();
        if (this.draw_zpsos.length) {
            this.draw_zpsos.forEach(zpso => {
                const element_types_zpso = zpso.elements_types.map(et => et.ID);
                const current_elements = [];
                current_elements.push(...this.slabs.filter(slab => element_types_zpso.indexOf(slab.slab_type.ID) >= 0));
                current_elements.push(...this.openings.filter(opening => element_types_zpso.indexOf(opening.opening_type.ID) >= 0));
                current_elements.forEach(el => zpsos_higlighted_elements.set(el, `style="fill:${colors[this.building.zpsos.indexOf(zpso) % 10]};opacity:1;"`))
            });
        }

        [
            ...this.lines,
            ...this.vertices,
            ...this.slabs,
            ...this.openings,
            ...this.roof_dormers,
            ...this.object_instances
        ].forEach(element => {
            const inactive = (camera.element_filter && !camera.element_filter(element));
            if (inactive) html += '<g opacity=\'0.3\'>';
            html += element.draw(camera, [], zpsos_higlighted_elements.get(element) || '');
            if (inactive) html += '</g>';
        });

        if (this.draw_samplings) {
            this.storey.samplings.filter(sampling => sampling.roof).forEach(sampling => {
                sampling.update();
                const inactive = (camera.element_filter && !camera.element_filter(sampling));
                if (inactive) html += '<g opacity=\'0.3\'>';
                html += sampling.draw(camera);
                if (inactive) html += '</g>';
            });
        } else if (zpsos_higlighted_elements.size) {
            const elements = Array.from(zpsos_higlighted_elements.keys()).map(el => el.ID);
            this.storey.samplings.filter(sampling => elements.includes(sampling.element.ID)).forEach(sampling => {
                sampling.update();
                const inactive = (camera.element_filter && !camera.element_filter(sampling));
                if (inactive) html += '<g opacity=\'0.3\'>';
                html += sampling.draw(camera);
                if (inactive) html += '</g>';
            });
        }
        if (this.draw_comments) {
            this.storey.markers.filter(marker => marker.roof).forEach(marker => {
                marker.update();
                let pic = !!marker['pictures'] && !!marker['pictures'].length;
                const inactive = (camera.element_filter && !camera.element_filter(marker));
                if (inactive) html += '<g opacity=\'0.3\'>';
                html += marker.draw(camera, [], false, pic);
                if (inactive) html += '</g>';
            });
        }

        return html;
    }

    /**
     * Simple draw
     *
     */
    simple_draw(camera) {
        return this.draw(camera);
    }

    //***********************************************************************************
    //**** update vertices
    //***********************************************************************************
    update_vertices() {
        for (var i in this.vertices)
            this.vertices[i].lines = [];
        this.vertices = [];
        for (var i in this.lines) {
            var w = this.lines[i];
            for (var k = 0; k < 2; k++) {
                var vtx = w.vertices[k];
                vtx.lines.push(w);
                if (this.vertices.indexOf(vtx) < 0)
                    this.vertices.push(vtx);
            }
        }
        for (var i in this.vertices)
            this.vertices[i].update();
    }

    //***********************************************************************************
    //**** update lines
    //***********************************************************************************
    update_lines() {
        try {
            for (var i in this.lines)
                this.lines[i].build_self();
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    //**** update slabs
    //***********************************************************************************
    update_slabs() {
        try {
            for (var i in this.slabs)
                this.slabs[i].update();
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    //**** update slabs
    //***********************************************************************************
    update_heights() {
        try {
            for (var i in this.heights)
                this.heights[i].update();
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    /**
     * update openings
     */
    update_openings() {
        try {
            for (var i in this.openings)
                this.openings[i].update();
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    /**
     * update openings
     */
    update_roof_dormers() {
        try {
            this.roof_dormers.forEach(rd => rd.update());
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    /**
     * update object instances
     */
    update_object_instances() {
        try {
            this.object_instances.forEach(oi => oi.update_deep());
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    //**** update
    //***********************************************************************************
    update() {
        try {
            this.update_vertices();
            this.update_lines();
            this.update_slabs();
            this.update_heights();
            this.update_openings();
            this.update_roof_dormers();
            this.update_object_instances();
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    //**** update
    //***********************************************************************************
    update_deep() {
        try {
            this.build_heights();

            var engine = new cn_roof_engine(this);
            engine.compute();
        } catch (err) {
            console.error(err);
        }
    }

    //***********************************************************************************
    //**** returns all adjacent lines with that share the same slabs
    //***********************************************************************************
    get_adjacent_lines(line) {
        var lines = [];
        lines.push(line);

        //*** forward
        var vertex = line.vertices[1];
        for (var l = line; l;) {
            if (lines.indexOf(l) < 0) lines.push(l);
            var vtx = l.other_vertex(vertex);
            if (vtx.lines.length != 2) break;
            if (vtx.lines[0] == l)
                l = vtx.lines[1];
            else
                l = vtx.lines[0];
            vertex = vtx;
        }

        //*** backward
        var vertex = line.vertices[0];
        for (var l = line; l;) {
            if (lines.indexOf(l) < 0) lines.push(l);
            var vtx = l.other_vertex(vertex);
            if (vtx.lines.length != 2) break;
            if (vtx.lines[0] == l)
                l = vtx.lines[1];
            else
                l = vtx.lines[0];
            vertex = vtx;
        }

        return lines;
    }

    //***********************************************************************************
    //**** Insert one set of lines
    //***********************************************************************************
    insert_lines(lines) {
        for (var i in lines) {
            if (this.lines.indexOf(lines[i]) >= 0) return;
        }
        this.lines = this.lines.concat(lines);

        //*** keep recycled slabs
        var recycled_slab = null;
        if (lines[0].slabs[0] && this.slabs.indexOf(lines[0].slabs[0]) < 0)
            recycled_slab = lines[0].slabs[0];
        else if (lines[0].slabs[1] && this.slabs.indexOf(lines[0].slabs[1]) < 0)
            recycled_slab = lines[0].slabs[1];

        //*** insert vertices
        for (var i in lines) {
            var line = lines[i];
            for (var s = 0; s < 2; s++) {
                if (this.vertices.indexOf(line.vertices[s]) < 0)
                    this.vertices.push(line.vertices[s]);
                line.contours[s] = null;
                line.slabs[s] = null;
            }
        }

        //*** update line and vertex data
        this.update_vertices();
        this.update_lines();

        //*** not isolated line
        var new_contour_0 = new cn_roof_contour();
        new_contour_0.follow(lines[0].vertices[0], lines[0]);

        //*** Which slab is implied ?
        var slab = null;
        var count = 0;
        for (var j = 0; j < new_contour_0.lines.length; j++) {
            var s = (new_contour_0.line_orientations[j]) ? 0 : 1;
            if (slab == null)
                slab = new_contour_0.lines[j].slabs[s];
            if (new_contour_0.lines[j] == lines[0]) count++;
        }

        //*** if second contour is not necessary
        if (count == 2) {
            if (slab) {
                slab.add_contour(new_contour_0);
                slab.update();
                slab.update_deep();
            } else
                new_contour_0.set_outside();
            return true;
        }

        var new_contour_1 = new cn_roof_contour();
        new_contour_1.follow(lines[0].vertices[1], lines[0]);

        //*** create new slab
        var new_slab = (recycled_slab) ? recycled_slab : new cn_roof_slab(this);
        this.slabs.push(new_slab);
        new_slab.contours = [];

        //*** If there is a slab (slabs split in 2)
        if (slab) {
            new_slab.slab_type = slab.slab_type;
            if (new_contour_0.perimeter > new_contour_1.perimeter) {
                slab.add_contour(new_contour_0);
                new_slab.add_contour(new_contour_1);
            } else {
                slab.add_contour(new_contour_1);
                new_slab.add_contour(new_contour_0);
            }
        }
        //***  slab is outside
        else {
            new_slab.slab_type = this.building.get_roof_types()[0];
            //*** 0 clockiwse and 1 counter : contour 0 is inside contour 1
            if (new_contour_0.clockwise) {
                new_slab.add_contour(new_contour_0);
                new_contour_1.set_outside();
            }

            //*** 1 clockiwse and 0 counter : contour 1 is inside contour 0
            if (new_contour_1.clockwise) {
                new_slab.add_contour(new_contour_1);
                new_contour_1.set_outside();
            }
        }

        this.update();
        this.update_deep();
        return true;
    }

    //***********************************************************************************
    //**** Remove one line. Will remove all lines between the same slabs. Returns number of removed lines.
    //***********************************************************************************
    remove_lines(lines) {
        if (lines[0].slabs[0] == null || lines[0].slabs[1] == null) return;
        //*** Remove lines from roof
        for (var i in lines) {
            var index = this.lines.indexOf(lines[i]);
            if (index < 0) return 0;
            this.lines.splice(index, 1);
        }
        this.update_vertices();
        this.update_lines();
        var line = lines[0];

        //*** merges slabs if different
        var slab = line.slabs[0];
        if (line.slabs[0] != line.slabs[1]) {
            if (line.slabs[0] == null)
                slab = line.slabs[0];
            else if (line.slabs[1] == null)
                slab = line.slabs[1];
            else if (!line.contours[0].clockwise)
                slab = line.slabs[0];
            else if (!line.contours[1].clockwise)
                slab = line.slabs[1];

            var other_slab = (slab == line.slabs[0]) ? line.slabs[1] : line.slabs[0];
            index = this.slabs.indexOf(other_slab);
            if (index >= 0)
                this.slabs.splice(index, 1);

            //** move lines
            for (var i in this.lines) {
                if (this.lines[i].slabs[0] != other_slab && this.lines[i].slabs[1] != other_slab) continue;

                if (this.lines[i].slabs[0] == other_slab)
                    this.lines[i].slabs[0] = slab;
                if (this.lines[i].slabs[1] == other_slab)
                    this.lines[i].slabs[1] = slab;
            }
        }

        //*** clear contours
        for (var i in this.lines) {
            if (this.lines[i].slabs[0] == slab)
                this.lines[i].contours[0] = null;
            if (this.lines[i].slabs[1] == slab)
                this.lines[i].contours[1] = null;
        }

        //*** update contours
        if (slab) {
            slab.contours = [];

            //*** recreate contours
            for (var i in this.lines) {
                for (var side = 0; side < 2; side++) {
                    if (this.lines[i].slabs[side] != slab) continue;
                    if (this.lines[i].contours[side]) continue;
                    var new_contour = new cn_roof_contour();
                    new_contour.follow(this.lines[i].vertices[side], this.lines[i]);
                    slab.add_contour(new_contour);
                }
            }
            this.update_slabs();
        }
    }

    //***********************************************************************************
    //**** raytrace
    //***********************************************************************************
    raytrace(origin, direction, max_distance, start_vertex = null, start_line = null) {
        var res = null;
        for (var i in this.lines) {
            var w = this.lines[i];
            if (w == start_line) continue;
            if (w.vertices[0] == start_vertex || w.vertices[1] == start_vertex) continue;
            const impact = w.raytrace(origin, direction, max_distance);
            if (impact) {
                max_distance = impact.distance;
                res = impact;
            }
        }
        return res;
    }

    //***********************************************************************************
    //**** raytrace on walls
    //***********************************************************************************
    raytrace_walls(origin, direction, max_distance, start_vertex = null, start_wall = null) {
        var res = null;
        for (var i in this.roof_dormers) {
            const roof_dormer = this.roof_dormers[i];
            for (var j in roof_dormer.walls) {
                var w = roof_dormer.walls[j];
                if (w == start_wall) continue;
                if (w.vertices[0] == start_vertex || w.vertices[1] == start_vertex) continue;
                var rr = w.raytrace(origin, direction, max_distance);
                if (rr == false) continue;
                res = rr;
                max_distance = res.distance;
            }
        }
        return res;
    }

    //***********************************************************************************
    //**** find a vertex by a point
    //***********************************************************************************
    find_vertex(pt, tolerance) {
        for (var i = 0; i < this.vertices.length; i++) {
            if (this.vertices[i].liberties > 0 && cn_dist(pt, this.vertices[i].position) <= tolerance)
                return this.vertices[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** find a line by a point
    //***********************************************************************************
    find_line(pt, tolerance) {
        for (var i = 0; i < this.lines.length; i++) {
            if (this.lines[i].intersects(pt, tolerance) >= 0)
                return this.lines[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** find a slab by a point
    //***********************************************************************************
    find_slab(pt) {
        for (var i = 0; i < this.slabs.length; i++) {
            if (this.slabs[i].contains(pt))
                return this.slabs[i];
        }
        return null;
    }

    //***********************************************************************************
    /**
     * Find an opening by a point
     * @param {number[]} pt
     * @returns {cn_roof_opening}
     */
    find_roof_opening(pt) {
        for (var i = 0; i < this.openings.length; i++) {
            if (this.openings[i].contains(pt))
                return this.openings[i];
        }
        return null;
    }

    //***********************************************************************************
    /**
     * Find an opening by a point
     * @param {number[]} pt
     * @returns {cn_roof_opening}
     */
    find_opening(pt) {
        const wall = this.find_wall(pt);
        if (!wall) return null;
        return wall.find_opening(pt);
    }

    //***********************************************************************************
    /**
     * Find an opening by a point
     * @param {number[]} pt
     * @returns {cn_wall}
     */
    find_wall(pt, tolerance = 0) {
        const roof_dormer = this.find_roof_dormer(pt);
        if (!roof_dormer) return null;
        const wall = roof_dormer.walls.find(w => w.contains(pt));
        if (!wall) return null;
        return wall;
    }

    //***********************************************************************************
    /**
     * Find an opening by a point
     * @param {number[]} pt
     * @returns {cn_roof_dormer}
     */
    find_roof_dormer(pt) {
        const found = this.roof_dormers.find(rd => rd.contains(pt));
        if (found) return found;
        return null;
    }

    //***********************************************************************************
    /**
     * Find an opening by a point
     * @param {number[]} pt
     * @returns {cn_object_instance}
     */
    find_object_instance(pt) {
        for (var i = 0; i < this.object_instances.length; i++) {
            if (this.object_instances[i].contains(pt))
                return this.object_instances[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** Check contour coherence, after change of some lines
    //***********************************************************************************
    check_changes(lines) {
        for (var i in lines) {
            if (lines[i].crosses(this.lines))
                return false;
        }

        return true;
    }

    //***********************************************************************************
    //**** Split line
    //***********************************************************************************
    split_line(line, new_vertex, new_line = null) {
        if (this.vertices.indexOf(new_vertex) < 0)
            this.vertices.push(new_vertex);
        var new_final_line = line.split(new_vertex, new_line);
        if (this.lines.indexOf(new_final_line) < 0)
            this.lines.push(new_final_line);
        return new_final_line;
    }

    //***********************************************************************************
    //**** Merge vertices
    //***********************************************************************************
    merge_vertices(v0, v1) {
        //*** remove vertex
        var index = this.vertices.indexOf(v1);
        if (index >= 0) this.vertices.splice(index, 1);

        //*** check lines
        for (var i = 0; i < this.lines.length; i++) {
            var line = this.lines[i];
            if (line.vertices[0] == v1)
                line.vertices[0] = v0;
            if (line.vertices[1] == v1)
                line.vertices[1] = v0;
            if (line.vertices[0] == line.vertices[1]) {
                this.lines.splice(i, 1);
                i--;
            }
        }

        this.update_vertices();
        this.update_lines();

    }

    //***********************************************************************************
    //**** Merge line
    //***********************************************************************************
    merge_line(line0, line1) {
        //*** search for common vertex
        var i0 = -1;
        var i1 = -1;
        for (var i = 0; i < 2; i++) {
            for (var j = 0; j < 2; j++) {
                if (line0.vertices[i] != line1.vertices[j]) continue;
                i0 = i;
                i1 = j;
                break;
            }
        }
        if (i0 < 0) return;

        if (line0.vertices[i0].lines.length != 2) return;

        var index = this.lines.indexOf(line1);
        if (index >= 0) this.lines.splice(index, 1);

        line0.vertices[i0] = line1.vertices[1 - i1];

        this.update_vertices();
        this.update_lines();

        //*** rebuild slabs contours
        if (line0.slabs[0]) {
            var contour = new cn_roof_contour();
            contour.follow(line0.vertices[0], line0);
            line0.slabs[0].add_contour(contour);
        }
        if (line0.slabs[1]) {
            var contour = new cn_roof_contour();
            contour.follow(line0.vertices[1], line0);
            line0.slabs[1].add_contour(contour);
        }
    }

    //***********************************************************************************
    //**** Returns bounding box
    //***********************************************************************************
    get_bounding_box() {
        var box = new cn_box();
        for (var i in this.vertices)
            box.enlarge_point(this.vertices[i].position);
        return box;
    }

    //***********************************************************************************
    //**** Sets status
    //***********************************************************************************
    set_status(s) {
        for (var i in this.vertices)
            this.vertices[i].status = s;
        for (var i in this.lines)
            this.lines[i].status = s;
        for (var i in this.slabs)
            this.slabs[i].status = s;
    }

    //***********************************************************************************
    //**** Build heights
    //***********************************************************************************
    build_heights() {
        try {
            //*** check all existing heights
            var vertex_heights = [];
            var line_heights = [];
            var slab_heights = [];
            for (var i in this.heights) {
                var h = this.heights[i];
                if (h.vertex)
                    vertex_heights.push(h);
                else if (h.line)
                    line_heights.push(h);
                else
                    slab_heights.push(h);
            }

            var scene = this.storey.scene;

            var new_heights = [];
            var obj = this;

            //*** Manage custom heights */
            for (var j = 0; j < this.heights.length; j++) {
                var rh = this.heights[j];
                if (!rh.custom) continue;
                this.heights.splice(j, 1);
                new_heights.push(rh);
                rh.slab = this.find_slab(rh.position);
                rh.update();
                j--;
            }

            //*** This local functions finds the closest existing point or build it.
            // @ts-ignore
            function find_closest_height(point, vertex = null) {

                var index = 0;
                for (; index < obj.heights.length; index++) {
                    if (obj.heights[index].custom) continue;
                    if (vertex) {
                        if (obj.heights[index].vertex == vertex)
                            break;
                    } else if (cn_dist(obj.heights[index].position, point) < 0.3)
                        break;
                }

                if (index < obj.heights.length) {
                    var h = obj.heights[index];
                    h.position = point;
                    new_heights.push(h);
                    obj.heights.splice(index, 1);
                    return h;
                }
                logger.log('could not find close height at position ' + point[0] + ' ' + point[1]);
                var hh = new cn_roof_height(point, obj);
                new_heights.push(hh);
                return hh;
            }

            //*** Build vertex heights
            for (var i in this.vertices) {
                //*** onbly inner vertices
                var vertex = this.vertices[i];
                if (!vertex.is_inner()) continue;

                var h = find_closest_height(vertex.position, vertex);
                h.vertex = vertex;
                h.position = cn_clone(vertex.position);
                h.line = null;
                h.slab = null;
                h.storey_angles = [];
            }

            //*** build line heights
            for (var i in this.lines) {
                //*** onbly inner lines
                var line = this.lines[i];
                //if (line.slabs[0] == null || line.slabs[1] == null) continue;

                var direction = line.bounds.direction;
                var ndirection = cn_mul(direction, -1);
                var xmin = 0;

                //*** we are going to march along the line
                for (var niter = 0; niter < 2; niter++) {
                    if (line.vertices[niter].is_inner()) continue;
                    var origin = line.vertices[niter].position;
                    if (niter == 1)
                        direction = cn_mul(direction, -1);

                    var h = null;
                    for (var x = 0; x < line.bounds.length; x += 0.1) {
                        var p = cn_add(origin, cn_mul(direction, x));
                        var space = scene.find_space(p, true);
                        if (space == null || !space.has_roof) continue;

                        //*** use raytacing to find backward slab limit or forward slab limit
                        var raytrace = space.raytrace(origin, direction, line.bounds.length - x, true);
                        if (raytrace == null) continue;

                        h = find_closest_height(raytrace.point);
                        h.vertex = null;
                        if (line.slabs[0] == null || line.slabs[1] == null) {
                            h.slab = (line.slabs[0]) ? line.slabs[0] : line.slabs[1];
                            break;
                        }
                        h.line = line;
                        h.slab = null;
                        var alpha = cn_polar(raytrace.contour_direction)[1];
                        h.storey_angles = [alpha - Math.PI, alpha];
                        break;
                    }

                    //*** HOTFIX ; we must build a height anycase */
                    if (h == null && line.slabs[0] && line.slabs[1] && !line.vertices[niter].is_inner()) {
                        h = find_closest_height(origin);
                        h.vertex = null;
                        if (line.slabs[0] == null || line.slabs[1] == null) {
                            h.slab = (line.slabs[0]) ? line.slabs[0] : line.slabs[1];
                        } else {
                            h.line = line;
                            h.slab = null;
                            var alpha = cn_polar(cn_normal(direction))[1];
                            h.storey_angles = [alpha - Math.PI, alpha];
                        }
                    }
                }
            }

            //*** Build slab heights
            scene.vertices.forEach(vertex => {
                const outer_walls = vertex.walls.filter(w => w.spaces[0].has_roof != w.spaces[1].has_roof);
                if (outer_walls.length != 2) return;

                const w0 = outer_walls[0];
                const w1 = outer_walls[1];
                var or0 = (w0.vertices[1] == vertex);
                var or1 = (w1.vertices[0] == vertex);
                var dir0 = outer_walls[0].bounds.direction;
                if (!or0) dir0 = cn_mul(dir0, -1);
                var dir1 = outer_walls[1].bounds.direction;
                if (!or1) dir1 = cn_mul(dir1, -1);
                if (cn_dot(dir0, dir1) > 0.9) return;

                let point;
                if (w0.spaces[0].has_roof)
                    point = (or0) ? w0.shape[3] : w0.shape[0];
                else
                    point = (or0) ? w0.shape[2] : w0.shape[1];

                //*** Which slab is above ? */
                var slab = this.find_slab(point);
                if (slab == null) return;

                //*** Is there a new height already here ? */
                var close_height_found = false;
                for (var k in new_heights) {
                    if (cn_dist(point, new_heights[k].position) > 0.3) continue;
                    close_height_found = true;
                    break;
                }
                if (close_height_found) return;

                //*** Build height there */
                var h = find_closest_height(point);
                h.vertex = null;
                h.line = null;
                h.slab = slab;
                h.storey_angles = [cn_polar(dir0)[1], cn_polar(dir1)[1]];
                while (h.storey_angles[1] < h.storey_angles[0]) h.storey_angles[1] += 2 * Math.PI;
            });


            this.heights = new_heights;
            logger.log('New heights : ', this.heights);
            this.update_heights();
        } catch (err) {
            console.error(err);
        }
    }


    //***********************************************************************************
    //**** Compute max height
    //***********************************************************************************
    get_max_height() {
        var max_height = -1000;
        for (var i in this.slabs) {
            var slab = this.slabs[i];
            for (var ctr in slab.contours) {
                var contour = slab.contours[ctr];
                for (var v in contour.vertices) {
                    var h = slab.compute_height(contour.vertices[v].position);
                    if (h > max_height)
                        max_height = h;
                }
            }
        }
        return max_height;
    }

    //***********************************************************************************
    //**** Build automatic spaces from existing vertices and lines
    //***********************************************************************************
    build_automatic_slabs() {
        this.update_vertices();
        this.update_lines();

        //*** Old slabs : are there different slab types ?  */
        var common_slab_type = null;
        for (var i in this.slabs) {
            if (this.slabs[i].outside) continue;
            if (common_slab_type == null)
                common_slab_type = this.slabs[i].slab_type;
            else if (this.slabs[i].slab_type != common_slab_type) {
                common_slab_type = null;
                break;
            }
        }

        //*** No common slab type ? create polygons for each old slab */
        if (common_slab_type == null) {
            for (var i in this.slabs)
                this.slabs[i].polygon = this.slabs[i].build_slab_polygon(0);
        }
        var old_slabs = this.slabs;

        //*** clear lines
        for (var i in this.lines) {
            var l = this.lines[i];
            for (var side = 0; side < 2; side++) {
                l.contours[side] = null;
                l.slabs[side] = null;
            }
        }

        //*** Find all contours
        var outer_contours = [];
        var inner_contours = [];
        for (var i in this.lines) {
            var l = this.lines[i];
            for (var side = 0; side < 2; side++) {
                //** avoid a wall that was already done on another contour
                if (l.contours[side]) continue;

                //*** build a contour from that wall
                var ctr = new cn_roof_contour();
                ctr.follow(l.vertices[side], l);

                //*** record the contour
                if (ctr.clockwise)
                    outer_contours.push(ctr);
                else
                    inner_contours.push(ctr);

                //*** memorize contour on all its lines
                for (var nl = 0; nl < ctr.lines.length; nl++) {
                    if (ctr.line_orientations[nl])
                        ctr.lines[nl].contours[0] = ctr;
                    else
                        ctr.lines[nl].contours[1] = ctr;
                }
            }
        }

        //*** Build outside slab
        var outside = new cn_roof_slab(this);
        outside.name = ROOF_SLAB_EXTERIOR_LABEL;
        outside.outside = true;
        this.slabs = [];

        logger.log('found ' + outer_contours.length + ' contours clockwise and ' + inner_contours.length + ' contours counter clockwise');

        //*** Build one slab for each clockwise contour
        for (var nct = 0; nct < outer_contours.length; nct++) {
            var roof_slab = new cn_roof_slab(this);
            this.slabs.push(roof_slab);
            roof_slab.add_contour(outer_contours[nct]);
        }

        //*** Loop on inner contours
        for (var nct = 0; nct < inner_contours.length; nct++) {
            var contour = inner_contours[nct];

            var best_contour = null;
            var pt = contour.vertices[0].position;

            //*** search for the smallest space contour that contains this contour
            for (var i in outer_contours) {
                if (!outer_contours[i].contains(pt)) continue;
                if (outer_contours[i].area <= contour.area * 1.01) continue;
                if (best_contour == null || outer_contours[i].area < best_contour.area)
                    best_contour = outer_contours[i];
            }

            //*** If a contour was found, add it to the space
            if (best_contour) {
                best_contour.slab.add_contour(contour);
                logger.log('added hole contour to outer contour', contour, best_contour);
            }

        }

        //*** Find slab types */
        for (var nct = 0; nct < this.slabs.length; nct++) {
            var slab = this.slabs[nct];
            if (slab.outside) continue;

            //*** Maube no problem on slab type */
            if (common_slab_type) {
                slab.slab_type = common_slab_type;
                continue;
            }

            //** otherwise compute best slab type (highest intersection area with old slabs) */
            var pg = slab.build_slab_polygon(0);
            var highest_intersection = 0;
            slab.slab_type = null;
            for (var j in old_slabs) {
                if (old_slabs[j].outside) continue;
                var p = pg.clone();
                p.intersects(old_slabs[j].polygon);
                var area = p.get_area();
                if (area <= highest_intersection) continue;
                highest_intersection = area;
                slab.slab_type = old_slabs[j].slab_type;
            }

            //*** In the end, apply default slab type */
            if (slab.slab_type == null)
                slab.slab_type = this.building.get_roof_types()[0];
        }

        this.build_heights();
        this.update();
        this.update_deep();
    }

    //***********************************************************************************
    /**
     * Compute height of roof at given position.
     * @param {number[]} point
     * @returns {number|false} returns false if no roof at position.
     */
    compute_height(point) {
        var slab = this.find_slab(point);
        if (slab == null) return false;
        return slab.compute_height(point);
    }

    //***********************************************************************************
    /**
     * Add layer to draw
     * @param {Array<cn_layer>} layers
     */
    add_layer_to_draw(...layers) {
    }

    //***********************************************************************************
    /**
     * Remove layer to draw
     * @param {Array<cn_layer>} layers
     */
    remove_layer_to_draw(...layers) {
    }

}
