<mat-form-field class="mat-form-field-no-underline">
  <button
    mat-button
    mat-icon-button
    matPrefix
    aria-label="Remove"
    (click)="incrementValue(-step)"
    [disabled]="readonly || control.value <= this.min">
    <mat-icon>remove</mat-icon>
  </button>
  <div class="d-flex mx-2">
    <input matInput type="number" [formControl]="control" [min]="min" [max]="max" [readonly]="readonly" />
    <ng-content></ng-content>
  </div>
  <button
    mat-button
    mat-icon-button
    matSuffix
    aria-label="Add"
    (click)="incrementValue(step)"
    [disabled]="readonly || control.value >= this.max">
    <mat-icon>add</mat-icon>
  </button>
</mat-form-field>
