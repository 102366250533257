'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Exporter for the BBS DPE engine
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import { cn_opening_type, code_to_label } from '../model/cn_opening_type';
import { cn_wall_type } from '../model/cn_wall_type';
import { fh_xml } from './fh_xml';
import { cn_azimut, cn_clone, cn_dist, cn_max, cn_md5, cn_middle, cn_min, cn_mul, cn_normal, cn_simplify_contour, cn_sub, cn_uuid } from './cn_utilities';
import { fh_add, fh_clone, fh_mul, fh_polygon, fh_solid } from '@enerbim/fh-3d-viewer';
import { cn_building } from '../model/cn_building';
import { cn_storey } from '../model/cn_storey';
import { cn_space } from '../model/cn_space';
import { cn_opening } from '../model/cn_opening';
import { cn_roof_opening } from '../model/cn_roof_opening';
import { cn_roof_opening_type, ROOF_OPENING_CATEGORY_LIST } from '../model/cn_roof_opening_type';
import { CN_MATERIAL_TYPE_HEAVY_STRUCTURE, CN_MATERIAL_TYPE_INSULATED_STRUCTURE, CN_MATERIAL_TYPE_INSULATOR } from '../model/cn_material';
import { cn_wall } from '../model/cn_wall';
import { cn_slab } from '../model/cn_slab';
import { cn_slab_type } from '../model/cn_slab_type';
import { logger } from './cn_logger';

export function cn_to_dpe(building) {
    var xml = new cn_dpe(building);
    return xml.to_dom().serialize();
}

//***********************************************************************************
//**** cn_dpe_information class :
/**
 * A class to manage information on DPE input
 */
//***********************************************************************************

export const DPE_INFORMATION = 0;
export const DPE_WARNING = 1;
export const DPE_ERROR = 2;

export const DPE_MISSING_ALTITUDE = 'L\'altitude du bâtiment n\'est pas définie.';
export const DPE_MISSING_CONSTRUCTION_DATE = 'L\'année de construction du bâtiment n\'est pas définie.';
export const DPE_MISSING_TYPOLOGY = 'Typologie de bâtiment invalide.';
export const DPE_MISSING_BUILDING_AREA = 'Surface totale du bâtiment non renseignée.';
export const DPE_MISSING_HEATED_SPACES = 'Aucune pièce chauffée.';
export const DPE_HEATED_SPACES = 'Espaces chauffés';
export const DPE_NON_HEATED_SPACES = 'Espaces non chauffés';
export const DPE_GENERIC_WALL_TYPE = 'Parois indéfinies';
export const DPE_DEFINED_WALL_TYPE = 'Parois définies';
export const DPE_GENERIC_FLOOR_TYPE = 'Planchers indéfinis';
export const DPE_DEFINED_FLOOR_TYPE = 'Planchers définis';
export const DPE_GENERIC_ROOF_TYPE = 'Plafonds indéfinis';
export const DPE_DEFINED_ROOF_TYPE = 'Plafonds définis';
export const DPE_WINDOWS = 'Fenêtres';
export const DPE_DOORS = 'Portes';

export class cn_dpe_information {
    /**
     * Creates an information message
     * @param {number} severity
     * @param {string} message
     * @param {object} parameters
     */
    constructor(severity, message, parameters = {}) {
        this.severity = severity;
        this.message = message;
        this.parameters = parameters;
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_dpe class :
//***********************************************************************************
//***********************************************************************************

export class cn_dpe {
    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building) {
        this._building = building;
        this._layers = [];
        this._storey = null;
        this.space_zone_map = new Map();
    }

    //********************************************************************************
    //**** Main function
    //********************************************************************************
    /**
     * Builds XML DPE data for the building
     * @returns {fh_xml}
     */
    to_dom() {
        this._building.rename_storeys();
        this._building.update_roofs();
        this._building.get_opening_types().forEach(ot => ot.compute_physics());
        this._information = [];

        logger.log('########### Vérification du bâtiment pour DPE');
        this._check_building();
        this._information.forEach(info => {
            if (info.parameters.U && info.parameters.area) info.parameters.U /= info.parameters.area;
            var severity = '';
            if (info.severity == DPE_INFORMATION) severity = 'INFORMATION';
            else if (info.severity == DPE_WARNING) severity = 'AVERSTISSEMENT';
            else if (info.severity == DPE_ERROR) severity = 'ERREUR';
            logger.log(`### ${severity} : ${info.message} `, info.parameters);
        });
        return this._build_building();
    }

    /**
     * Checks the building data
     * @returns {Array<cn_dpe_information>}
     */
    check_data() {
        this._building.rename_storeys();
        this._building.update_roofs();
        this._building.get_opening_types().forEach(ot => ot.compute_physics());
        this._information = [];
        this._check_building();
        return this._information;
    }

    // TODO FGO Ne peut pas fonctionner en l'état :
    //  - les codes de typologie ne sont pas les bons
    //  - les coordonnées ne sont pas renseignées
    //  - la surface n'est pas renseignée

    _check_building() {
        if (this._building.building_data.altitude == 0)
            this._information.push(new cn_dpe_information(DPE_ERROR, DPE_MISSING_ALTITUDE))
        if (this._building.building_data.construction_date == 0)
            this._information.push(new cn_dpe_information(DPE_ERROR, DPE_MISSING_CONSTRUCTION_DATE))
        if (this._building.building_data.typology != 'lodging_flat' && this._building.building_data.typology != 'lodging_house')
            this._information.push(new cn_dpe_information(DPE_ERROR, DPE_MISSING_TYPOLOGY))
        if (this._building.building_data.typology == 'lodging_flat' && this._building.building_data.building_area == 0)
            this._information.push(new cn_dpe_information(DPE_ERROR, DPE_MISSING_BUILDING_AREA));

        this._check_envelope();
    }

    _build_building() {
        const root = new fh_xml('batiment');
        root.set_attribute('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
        root.set_attribute('xmlns:xsd', 'http://www.w3.org/2001/XMLSchema');
        root.set_attribute('xsi:noNamespaceSchemaLocation', 'http://www.edibatec.org/ServiceWeb/MoteurDPE/WebServiceMoteurDPE.exe/wsdl/IMoteurDPE');

        root.append_child(new fh_xml('nom', this._building.name));
        var department = 31;
        if (this._building.building_data.zip.length >= 2) {
            const dept = parseInt(this._building.building_data.zip.substr(0, 2));
            if (!isNaN(dept) && dept > 0 && dept < 99) department = dept;
        }
        root.append_child(new fh_xml('NumeroDepartement', department.toFixed(0)));
        root.append_child(new fh_xml('altitude', this._building.building_data.altitude.toFixed(0)));
        root.append_child(new fh_xml('annee_construction', this._building.building_data.construction_date.toFixed(0)));
        root.append_child(new fh_xml('Is_bat_ancien', (this._building.building_data.construction_date < 2000) ? 'true' : 'false'));
        root.append_child(new fh_xml('Is_bat_climatise', 'false'));
        root.append_child(new fh_xml('type_logement', (this._building.building_data.typology == 'lodging_flat') ? '2' : '1'));
        root.append_child(new fh_xml('SH'));
        if (this._building.building_data.typology == 'lodging_flat')
            root.append_child(new fh_xml('SH_immeuble', this._building.building_data.building_area));

        root.append_child(this._build_enveloppe());

        return root;
    }

    _check_envelope() {
        var nb_spaces = 0;
        var heated_area = 0;
        var nb_non_heated_spaces = 0;
        var non_heated_heated_area = 0;
        this._building.storeys.forEach(storey => {
            storey.scene.spaces.forEach(space => {
                if (!space.outside && space.indoor) {
                    if (space.is_heated()) {
                        nb_spaces++;
                        heated_area += space.get_area();
                    } else {
                        nb_non_heated_spaces++;
                        non_heated_heated_area += space.get_area();
                    }
                }
            });
            storey.build_roof_polygons().forEach(p => this._check_roof(p));
        });

        if (nb_spaces == 0) {
            this._information.push(new cn_dpe_information(DPE_ERROR, DPE_MISSING_HEATED_SPACES));
        } else {
            this._information.push(new cn_dpe_information(DPE_INFORMATION, DPE_HEATED_SPACES, { number: nb_spaces, area: heated_area }));
            this._information.push(new cn_dpe_information(DPE_INFORMATION, DPE_NON_HEATED_SPACES, { number: nb_non_heated_spaces, area: non_heated_heated_area }));
        }

        this._building.storeys.forEach(storey => {
            storey.scene.walls.forEach(wall => this._check_wall(wall, storey));
            storey.slabs.forEach(wall => this._check_slab(wall));
        });
    }

    _build_enveloppe() {
        const enveloppe = new fh_xml('enveloppe');

        //*** average storey height */
        var hsp = 0;
        this._building.storeys.forEach(s => hsp += s.height);
        hsp /= this._building.storeys.length;
        enveloppe.append_child(new fh_xml('hsp', hsp.toFixed(2)));

        //*** heavy flags */
        this._walls_facade_heavy = 0;
        this._walls_facade_all = 0;
        this._walls_heavy = 0;
        this._walls_all = 0;
        this._floors_heavy = 0;
        this._floors_all = 0;
        this._roofs_heavy = 0;
        this._roofs_all = 0;

        const elements = new fh_xml('Elements');
        enveloppe.append_child(elements);

        const parois = new fh_xml('Parois');
        elements.append_child(parois);

        const murs = new fh_xml('Murs');
        parois.append_child(murs);
        const planchers = new fh_xml('Planchers');
        parois.append_child(planchers);
        const plafonds = new fh_xml('Plafonds');
        parois.append_child(plafonds);
        this._building.update_roofs();
        this._building.storeys.forEach(s => {
            s.roof_volume = s.build_roof_volume(false);
            s.scene.walls.forEach(w => {
                const dpe_wall = this._build_wall(w, s);
                if (dpe_wall) murs.append_child(dpe_wall);
            });
            s.slabs.forEach(slab => {
                const dpe_slab = this._build_slab(slab);
                if (dpe_slab) {
                    if (dpe_slab._name == 'Plafond')
                        plafonds.append_child(dpe_slab);
                    else
                        planchers.append_child(dpe_slab);
                }
            });
            s.build_roof_polygons().forEach(p => {
                const dpe_roof = this._build_roof(p);
                if (dpe_roof) plafonds.append_child(dpe_roof);
            });
        });

        //*** Compute heavyness for types of walls */
        const heavy_walls = ((this._walls_facade_heavy > 0.5 * this._walls_facade_all) || (this._walls_heavy > 0.75 * this._walls_all));
        enveloppe.append_child(new fh_xml('MursLourds', (heavy_walls) ? 'true' : 'false'));
        const heavy_floors = (this._floors_heavy > 0.5 * this._floors_all);
        enveloppe.append_child(new fh_xml('PlanchersLourds', (heavy_floors) ? 'true' : 'false'));
        const heavy_roofs = (this._roofs_heavy > 0.5 * this._roofs_all);
        enveloppe.append_child(new fh_xml('PlafondsLourds', (heavy_roofs) ? 'true' : 'false'));

        /** inertie:
         1 : Inertie légère,
         2 : Inertie moyenne,
         3 : Inertie lourde
         */
        var inertia = 1;
        if (heavy_walls) inertia++;
        if (heavy_floors) inertia++;
        if (heavy_roofs) inertia++;
        if (inertia > 3) inertia = 3;
        enveloppe.append_child(new fh_xml('inertie', inertia.toFixed(0)));
        return enveloppe;
    }

    /**
     * Checks a wall
     * @param {cn_wall} wall
     * @param {cn_storey} storey
     */
    _check_wall(wall, storey) {
        if (wall.balcony) return;
        if (wall.wall_type.free) return;
        const h0 = wall.spaces[0].is_heated();
        const h1 = wall.spaces[1].is_heated();
        if (!h0 && !h1) return;

        const pg = wall.build_simple_polygon(storey);
        if (pg == null || pg.get_area() < 0.01) return;
        const area = pg.get_area();

        if (wall.wall_type.category == 'generic') {
            var info = this._information.find(i => i.message == DPE_GENERIC_WALL_TYPE && i.parameters.wall_type == wall.wall_type);
            if (!info) {
                info = new cn_dpe_information(DPE_WARNING, DPE_GENERIC_WALL_TYPE, { wall_type: wall.wall_type, number: 0, area: 0 });
                this._information.push(info);
            }
            info.parameters.number++;
            info.parameters.area += area;
        } else {
            var info = this._information.find(i => i.message == DPE_DEFINED_WALL_TYPE);
            if (!info) {
                info = new cn_dpe_information(DPE_INFORMATION, DPE_DEFINED_WALL_TYPE, { number: 0, area: 0, U: 0 });
                this._information.push(info);
            }
            info.parameters.number++;
            info.parameters.area += area;
            info.parameters.U += area * wall.wall_type.get_U();
        }

        wall.openings.forEach(opening => this._check_opening(opening));
    }

    /**
     * Adds a wall
     * @param {cn_wall} wall
     * @param {cn_storey} storey
     * @returns {fh_xml}
     */
    _build_wall(wall, storey) {
        //*** check that spaces have a flux */
        if (wall.balcony) return null;
        if (wall.wall_type.free) return null;
        const h0 = wall.spaces[0].is_heated();
        const h1 = wall.spaces[1].is_heated();
        if (!h0 && !h1) return null;

        const pg = wall.build_simple_polygon(storey);
        if (pg == null || pg.get_area() < 0.01) return null;
        const area = pg.get_area();

        //*** compute inertia */
        var heavy_wall = false;
        if (wall.wall_type.category == 'facade' || wall.wall_type.category == 'inner') {
            const layers = wall.wall_type.layers.concat([]).reverse();
            for (var l in layers) {
                if (layers[l].code == 'gypsum') {
                    if (layers[l].thickness > 0.05)
                        heavy_wall = true;
                    continue;
                }
                if (layers[l].type == CN_MATERIAL_TYPE_INSULATED_STRUCTURE) {
                    if (layers[l].thickness > 0.3)
                        heavy_wall = true;
                } else if (layers[l].type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE) {
                    heavy_wall = true;
                }
                break;
            }
        }
        if (heavy_wall) this._walls_heavy += area;
        this._walls_all += area;

        if (h0 == h1) return null;

        if (heavy_wall) this._walls_facade_heavy += area;
        this._walls_facade_all += area;

        //*** CWall characteristics */
        const dpe_wall = new fh_xml('Mur');
        dpe_wall.set_attribute('Nom', 'Mur ' + wall.ID);
        dpe_wall.set_attribute('ID', wall.ID);

        //*** groupe "DonneesGenerales" */
        const donnees_generales = new fh_xml('DonneesGenerales');
        dpe_wall.append_child(donnees_generales);

        /** surface (float) */
        donnees_generales.append_child(new fh_xml('surface', (pg.get_area()).toFixed(2)));

        /** contact (int)
         1 : L'éxterieur,
         2 : L'intérieur (local ou espace tampon), ===> typeEspaceInt (int)
         1 : Local d'habitation,
         2 : Local autre qu'habitation,
         3 : Local non accessible,
         4 : Espace tampon  =======> espaceTampon (string) - ID de l'espace tampon
         3 : Un sous-sol
         4 : Un vide-sanitaire
         5 : Le sol
         */
        const contact = 1;
        donnees_generales.append_child(new fh_xml('contact', contact.toFixed(0)));

        /** epaisseur (float) */
        dpe_wall.append_child(new fh_xml('epaisseur', (wall.wall_type.thickness * 100).toFixed(0)));

        /** orientation (int)
         1 : Sud,
         2 : Est,
         3 : Nord,
         4 : Ouest
         */
        const flow_normal = cn_mul(wall.bounds.normal, wall.get_flow_direction() ? 1 : -1);
        const azimut = cn_azimut(flow_normal, this._building.compass_orientation);
        var orientation = 1;
        if (azimut > -135 && azimut <= -45) orientation = 4;
        else if (azimut > -45 && azimut <= 45) orientation = 3;
        else if (azimut > 45 && azimut <= 135) orientation = 2;
        dpe_wall.append_child(new fh_xml('orientation', orientation.toFixed(0)));

        //*** Thermic data */
        if (wall.wall_type.category == 'generic') {
            dpe_wall.append_child(new fh_xml('structure', '7'));
            const isolation = new fh_xml('isolation');
            dpe_wall.append_child(isolation);
            isolation.append_child(new fh_xml('type', '1'));
        } else {
            dpe_wall.append_child(new fh_xml('Umur', wall.wall_type.get_U().toFixed(3)));
        }

        //*** openings */
        const fenetres = [];
        const portes = [];
        wall.openings.forEach(opening => {
            const o = this._build_opening(opening);
            if (o) {
                if (o._name == 'Fenetre') fenetres.push(o);
                else portes.push(o);
            }
        });

        if (fenetres.length + portes.length > 0) {
            const menuiseries = new fh_xml('menuiseries');
            dpe_wall.append_child(menuiseries);

            if (fenetres.length > 0) {
                const liste_fenetres = new fh_xml('Fenetres');
                fenetres.forEach(f => liste_fenetres.append_child(f));
                menuiseries.append_child(liste_fenetres);
            }
            if (portes.length > 0) {
                const liste_portes = new fh_xml('Portes');
                portes.forEach(f => liste_portes.append_child(f));
                menuiseries.append_child(liste_portes);
            }
        }

        return dpe_wall;
    }

    /**
     * Checks an opening
     * @param {cn_opening} opening
     */
    _check_opening(opening) {
        if (!opening.valid) return null;
        if (opening.opening_type.free) return null;

        const is_fenetre = (opening.opening_type.glazing != 'none');

        const msg = (is_fenetre) ? DPE_WINDOWS : DPE_DOORS;
        var info = this._information.find(info => info.message == msg);
        if (!info) {
            info = new cn_dpe_information(DPE_INFORMATION, msg, { number: 0, area: 0, U: 0 });
            this._information.push(info);
        }
        info.parameters.number++;
        const area = opening.opening_type.get_area();
        info.parameters.area += area;
        info.parameters.U += area * opening.opening_type.Uw;
    }

    /**
     * Builds an opening
     * @param {cn_opening} opening
     * @returns {fh_xml}
     */
    _build_opening(opening) {
        if (!opening.valid) return null;
        if (opening.opening_type.free) return null;
        const is_fenetre = (opening.opening_type.glazing != 'none');
        const dpe_opening = new fh_xml((is_fenetre) ? 'Fenetre' : 'Porte');
        dpe_opening.set_attribute('Nom', opening.opening_type.get_label() + ' ' + opening.ID);
        dpe_opening.set_attribute('ID', opening.ID);

        //*** opening structure */
        const structure = (opening.opening_type.frame == 'wood') ? 1 : (opening.opening_type.frame == 'pvc') ? 2 : 3;
        dpe_opening.append_child(new fh_xml('structure', structure.toFixed(0)));

        //*** Oepnings position */
        var position = 2;
        if (opening.opening_position != 1) {
            if ((opening.opening_position == 0) == opening.wall.spaces[0].is_heated())
                position = 3;
            else
                position = 1;
        }
        dpe_opening.append_child(new fh_xml('position', position.toFixed(0)));

        //*** opening dimensions */
        const dimensions = new fh_xml('dimensions');
        dpe_opening.append_child(dimensions);
        dimensions.append_child(new fh_xml('largeur', opening.opening_type.width.toFixed(2)));
        dimensions.append_child(new fh_xml('hauteur', opening.opening_type.height.toFixed(2)));
        dimensions.append_child(new fh_xml('surface', opening.opening_type.get_area().toFixed(2)));

        if (opening.opening_type.glazing != 'none') {
            //*** opening typology */
            var typologie = 0;
            if (opening.opening_type.opening == 'none') typologie = 6;
            else if (opening.opening_type.opening == 'french') {
                if (opening.opening_type.category == 'window')
                    typologie = 1;
                else if (opening.opening_type.sill == 'opaque')
                    typologie = 4;
                else
                    typologie = 3;
            } else if (opening.opening_type.category == 'window')
                typologie = 2;
            else
                typologie = 5;
            dpe_opening.append_child(new fh_xml('typologie', typologie.toFixed(0)));

            //*** operning glazing */
            const vitrage = (opening.opening_type.glazing == 'single') ? 1 : (opening.opening_type.glazing == 'double') ? 3 : 4;
            dpe_opening.append_child(new fh_xml('CompoVitrage', vitrage.toFixed(0)));

            if (opening.opening_type.glazing == 'double') {
                const gazparse = opening.opening_type.glazing_gaz.split('_');

                var epaisseur_gaz = 0;
                if (gazparse[1] == '6') epaisseur_gaz = 1;
                else if (gazparse[1] == '8') epaisseur_gaz = 2;
                else if (gazparse[1] == '12') epaisseur_gaz = 4;
                else if (gazparse[1] == '16') epaisseur_gaz = 7;
                if (epaisseur_gaz != 0)
                    dpe_opening.append_child(new fh_xml('epaisseurLameGaz', epaisseur_gaz.toFixed(0)));

                var type_gaz = (gazparse[0] == 'air') ? 1 : 2;
                dpe_opening.append_child(new fh_xml('typeGaz', type_gaz.toFixed(0)));
            }
        }

        dpe_opening.append_child(new fh_xml((is_fenetre) ? 'Uw' : 'Ud', opening.opening_type.Uw));

        return dpe_opening;
    }

    /**
     * Checks a slab
     * @param {cn_slab} slab
     */
    _check_slab(slab) {
        const h0 = (slab.spaces[0] && slab.spaces[0].is_heated());
        const h1 = (slab.spaces[1] && slab.spaces[1].is_heated());
        if (!h0 && !h1) return null;

        const pg = slab.build_polygon(0);
        if (pg == null || pg.get_area() < 0.01) return null;
        const area = pg.get_area();

        this._check_slab_type(slab.slab_type, area, h0);
    }

    _check_slab_type(slab_type, area, is_roof) {
        if (slab_type.is_generic()) {
            const msg = (is_roof) ? DPE_GENERIC_ROOF_TYPE : DPE_GENERIC_FLOOR_TYPE;
            var info = this._information.find(i => i.message == msg && i.parameters.slab_type == slab_type);
            if (!info) {
                info = new cn_dpe_information(DPE_WARNING, msg, { slab_type: slab_type, number: 0, area: 0 });
                this._information.push(info);
            }
            info.parameters.number++;
            info.parameters.area += area;
        } else {
            const msg = (is_roof) ? DPE_DEFINED_ROOF_TYPE : DPE_DEFINED_FLOOR_TYPE;
            var info = this._information.find(i => i.message == msg);
            if (!info) {
                info = new cn_dpe_information(DPE_INFORMATION, msg, { number: 0, area: 0, U: 0 });
                this._information.push(info);
            }
            info.parameters.number++;
            info.parameters.area += area;
            info.parameters.U += area * slab_type.get_U();
        }
    }

    /**
     * Adds a slab
     * @param {cn_slab} slab
     * @returns {fh_xml}
     */
    _build_slab(slab) {

        const h0 = (slab.spaces[0] && slab.spaces[0].is_heated());
        const h1 = (slab.spaces[1] && slab.spaces[1].is_heated());
        if (!h0 && !h1) return null;

        const pg = slab.build_polygon(0);
        if (pg == null || pg.get_area() < 0.01) return null;
        const area = pg.get_area();

        //*** inertia */
        var heavy = (h1 && h0) ? this._is_intermediate_heavy(slab.slab_type) : (h1) ? this._is_floor_heavy(slab.slab_type) : this._is_roof_heavy(slab.slab_type);
        if (h1) {
            this._floors_all += area;
            if (heavy) this._floors_heavy += area;
        } else {
            this._roofs_all += area;
            if (heavy) this._roofs_heavy += area;
        }
        if (h0 && h1) return null;

        const dpe_slab = new fh_xml((h0) ? 'Plafond' : 'Plancher');
        dpe_slab.set_attribute('Nom', 'Dalle ' + slab.ID);
        dpe_slab.set_attribute('ID', slab.ID);

        //*** groupe "DonneesGenerales" */
        const donnees_generales = new fh_xml('DonneesGenerales');
        dpe_slab.append_child(donnees_generales);

        /** surface (float) */
        donnees_generales.append_child(new fh_xml('surface', (area.toFixed(2))));

        /** contact (int)
         1 : L'éxterieur,
         2 : L'intérieur (local ou espace tampon), ===> typeEspaceInt (int)
         1 : Local d'habitation,
         2 : Local autre qu'habitation,
         3 : Local non accessible,
         4 : Espace tampon  =======> espaceTampon (string) - ID de l'espace tampon
         3 : Un sous-sol
         4 : Un vide-sanitaire
         5 : Le sol
         */
        const contact = 1;
        donnees_generales.append_child(new fh_xml('contact', contact.toFixed(0)));

        if (slab.slab_type.is_generic()) {
            if (h0) {
                /** structure plafond
                 1 : Plafond "léger",
                 2 : Plafond "Lourd",
                 3 : Rampant de combles aménagés, toitures bac en acier,
                 4 : Toit de chaume,
                 5 : Structure inconnue (valeur pénalisante)
                 */
                dpe_slab.append_child(new fh_xml('structure', '5'));

                /** position
                 1 : Sous combles perdus,
                 2 : Rampant de combles aménagés,
                 3 : Sous terrasse,
                 */
                dpe_slab.append_child(new fh_xml('position', (slab.spaces[1] && slab.spaces[1].indoor) ? '1' : '3'));
            } else {
                /** structure plancher
                 1 : Plancher "léger",
                 2 : Plancher "lourd",
                 3 : structure inconnue (valeur pénalisante)
                 */
                dpe_slab.append_child(new fh_xml('structure', '3'));

                /** perimetreTerrePleinVideSaniLnc */
                /** surfaceTerrePleinVideSaniLnc */
            }
        } else {
            dpe_slab.append_child(new fh_xml((h0) ? 'Uph' : 'Upb', slab.slab_type.get_U()));
        }

        return dpe_slab;
    }

    _check_roof(roof_polygon) {
        const slab = roof_polygon['slab'];
        const space = roof_polygon['space'];
        if (!space.is_heated()) return;

        const area = roof_polygon.get_area();

        this._check_slab_type(slab.slab_type, area, true);
    }

    _build_roof(roof_polygon) {
        const slab = roof_polygon['slab'];
        const space = roof_polygon['space'];
        if (!space.is_heated()) return null;

        const area = roof_polygon.get_area();

        //*** inertia */
        var heavy = this._is_roof_heavy(slab.slab_type);
        this._roofs_all += area;
        if (heavy) this._roofs_heavy += area;

        const dpe_roof = new fh_xml('Plafond');
        dpe_roof.set_attribute('Nom', 'Dalle ' + slab.ID);
        dpe_roof.set_attribute('ID', slab.ID);

        //*** groupe "DonneesGenerales" */
        const donnees_generales = new fh_xml('DonneesGenerales');
        dpe_roof.append_child(donnees_generales);

        /** surface (float) */
        donnees_generales.append_child(new fh_xml('surface', (area.toFixed(2))));

        /** contact (int)
         1 : L'éxterieur,
         2 : L'intérieur (local ou espace tampon), ===> typeEspaceInt (int)
         1 : Local d'habitation,
         2 : Local autre qu'habitation,
         3 : Local non accessible,
         4 : Espace tampon  =======> espaceTampon (string) - ID de l'espace tampon
         3 : Un sous-sol
         4 : Un vide-sanitaire
         5 : Le sol
         */
        const contact = 1;
        donnees_generales.append_child(new fh_xml('contact', contact.toFixed(0)));

        if (slab.slab_type.is_generic()) {
            /** structure plafond
             1 : Plafond "léger",
             2 : Plafond "Lourd",
             3 : Rampant de combles aménagés, toitures bac en acier,
             4 : Toit de chaume,
             5 : Structure inconnue (valeur pénalisante)
             */
            dpe_roof.append_child(new fh_xml('structure', '5'));

            /** position
             1 : Sous combles perdus,
             2 : Rampant de combles aménagés,
             3 : Sous terrasse,
             */
            dpe_roof.append_child(new fh_xml('position', '2'));
        } else {
            dpe_roof.append_child(new fh_xml('Uph', slab.slab_type.get_U()));
        }

        roof_polygon['openings'].forEach(opening => {

        });

        //*** openings */
        const fenetres = [];
        roof_polygon['openings'].forEach(opening => {
            const o = this._build_roof_opening(opening);
            if (o) fenetres.push(o);
        });

        if (fenetres.length > 0) {
            const menuiseries = new fh_xml('menuiseries');
            dpe_roof.append_child(menuiseries);

            const liste_fenetres = new fh_xml('Fenetres');
            fenetres.forEach(f => liste_fenetres.append_child(f));
            menuiseries.append_child(liste_fenetres);
        }

        return dpe_roof;
    }

    /**
     * Builds an opening
     * @param {fh_polygon} opening_polygon
     * @returns {fh_xml}
     */
    _build_roof_opening(opening_polygon) {
        const opening = opening_polygon['opening'];

        const dpe_opening = new fh_xml('Fenetre');
        dpe_opening.set_attribute('Nom', opening.opening_type.get_label() + ' ' + opening.ID);
        dpe_opening.set_attribute('ID', opening.ID);

        //*** opening structure */
        const structure = (opening.opening_type.frame == 'wood') ? 1 : (opening.opening_type.frame == 'pvc') ? 2 : 3;
        dpe_opening.append_child(new fh_xml('structure', structure.toFixed(0)));

        //*** opening dimensions */
        const dimensions = new fh_xml('dimensions');
        dpe_opening.append_child(dimensions);
        dimensions.append_child(new fh_xml('largeur', opening.opening_type.width.toFixed(2)));
        dimensions.append_child(new fh_xml('hauteur', opening.opening_type.length.toFixed(2)));
        dimensions.append_child(new fh_xml('surface', opening_polygon.get_area().toFixed(2)));

        if (opening.opening_type.glazing != 'none') {
            /** opening typology
             1 : Fenêtre battante,
             2 : Fenêtre coulissante,
             3 : Porte-fenêtre battante sans soubassement,
             4 : Porte-fenêtre battante avec soubassement,
             5 : Porte-fenêtre coulissante,
             6 : Brique de verre,
             7 : Parois en polycarbonate
             */
            var typologie = (opening.opening_type.glazing == 'polycarbonate') ? 7 : 1;
            dpe_opening.append_child(new fh_xml('typologie', typologie.toFixed(0)));

            /** operning glazing
             1 : Simple vitrage,
             2 : Survitrage,
             3 : Double vitrage,
             4 : Triple vitrage */
            const vitrage = (opening.opening_type.glazing == 'single') ? 1 : (opening.opening_type.glazing == 'double') ? 3 : 1;
            dpe_opening.append_child(new fh_xml('CompoVitrage', vitrage.toFixed(0)));
        }

        dpe_opening.append_child(new fh_xml('Uw', opening.opening_type.Uw));

        return dpe_opening;
    }

    /**
     * Returns true if this slab type is heavy for a floor
     * @param {cn_slab_type} slab_type
     * @return {boolean}
     */
    _is_intermediate_heavy(slab_type) {
        //*** Unknown slabs are heavy */
        if (slab_type.is_generic()) return true;

        //*** Look at layser from up to down */
        for (var l = slab_type.layers.length - 1; l >= 0; l--) {
            const layer = slab_type.layers[l];
            if (layer.type == CN_MATERIAL_TYPE_INSULATOR) break;
            if (layer.type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE && layer.thickness > 0.15) return true;
        }
        for (var l = 0; l < slab_type.layers.length; l++) {
            const layer = slab_type.layers[l];
            if (layer.type == CN_MATERIAL_TYPE_INSULATOR) break;
            if (layer.type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE && layer.thickness > 0.15) return true;
        }
        return false;
    }

    /**
     * Returns true if this slab type is heavy for a floor
     * @param {cn_slab_type} slab_type
     * @return {boolean}
     */
    _is_floor_heavy(slab_type) {
        //*** Unknown slabs are heavy */
        if (slab_type.is_generic()) return true;

        //*** Look at layser from up to down */
        for (var l = slab_type.layers.length - 1; l >= 0; l--) {
            const layer = slab_type.layers[l];
            if (layer.type == CN_MATERIAL_TYPE_INSULATOR) return false;
            if (layer.type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE) return layer.thickness > 0.10;
        }
        return false;
    }

    /**
     * Returns true if this slab type is heavy for a roof
     * @param {cn_slab_type} slab_type
     * @return {boolean}
     */
    _is_roof_heavy(slab_type) {
        //*** Unknown slabs are heavy */
        if (slab_type.is_generic()) return false;

        //*** Look at layser from bottom to up */
        for (var l = 0; l < slab_type.layers.length; l++) {
            const layer = slab_type.layers[l];
            if (layer.type == CN_MATERIAL_TYPE_INSULATOR) return false;
            if (layer.type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE) return layer.thickness > 0.08;
        }
        return true;
    }

    _find_information(message) {
        return this._information.find(i => i.message == message);
    }
}
