'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Editor for object instances
//***********************************************************************************
//***********************************************************************************

import { cn_element } from '../model/cn_element';
import { cn_storey } from '../model/cn_storey';
import { cn_add } from '../utils/cn_utilities';
import { cn_svg_tool_edition } from './cn_svg_tool_edition';
import { cn_object_instance_handler } from './cn_object_instance_handler';
import { cn_object_instance } from '../model/cn_object_instance';
import { cn_mouse_event } from './cn_mouse_event';

export class cn_svg_tool_object_edition extends cn_svg_tool_edition {
    constructor(map) {
        super(map);

        //*** edition handler
        this._handler = null;

        this._pending_changes = false;
    }

    //***********************************************************************************
    //**** Selection callback
    //***********************************************************************************
    on_selection_change() {
        const obj = this;
        this._handler = null;
        this._handlers = [];

        //*** We expect the selection to contain only openings */
        var sel = (this._view_overlay) ? this._view_overlay.get_selection().map(s => s.element) : this._controller.get_selection();
        if (sel.length == 0 || sel.some(elt => elt.constructor != cn_object_instance))
            return false;

        var storey = (this._view_overlay && sel.length == 1) ? this._view_overlay.get_selection()[0].storey : null;
        this._handler = new cn_object_instance_handler(sel, (this._map) ? this._map : this._view_overlay, false, storey);
        this._handlers.push(this._handler);
        return true;
    }

    /**
     * Intended for 3d compatible tools. Must return true if the tool can edit the element
     * @param {{element: cn_element, storey: cn_storey}} storey_element
     * @returns {boolean}
     */
    can_edit(storey_element) {
        return storey_element.element.constructor == cn_object_instance;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************
    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab(mouse_event) {
        const res = super.grab(mouse_event);
        if (res && mouse_event.drag_and_drop_element)
            mouse_event.drag_and_drop_owner = this;
        return res;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    move(mouse_event) {
        if (this._pending_changes) {
            this._pending_changes = false;
            this._scene.update_deep();
            this._map.refresh();
        }
        return super.move(mouse_event);
    }

    /**
     * During a drag and drop, the owner of the drag and drop may change.
     * Return true to accept owning the drag and drop events.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    grab_element(mouse_event) {
        const instance = mouse_event.drag_and_drop_element;
        if (instance && instance.constructor == cn_object_instance) {
            instance.virtual = false;
            if (this._handler == null || this._handler.instance != instance) {
                this.remove_handler(this._handler);
                this._handler = new cn_object_instance_handler([instance], this._map, false);
                this._handlers.push(this._handler);
            }
            this._handler.grab_element(mouse_event);
            this._focus_handler = this._handler;
            return true;
        }
        return false;
    }

    /**
     * Manage a drag, during a drag and drop.
     * This may follow:
     * - Either a grab event, where drag and drop has been triggered (by setting drag_and_drop_element on the mouse event)
     * - Either after an accepted grab_element event.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    drag_element(mouse_event) {
        return (this._handler && this._handler.drag_element(mouse_event));
    }

    /**
     * If drag and dropped is passed on another event handler,
     * this method is called on the previous owner of the drag and drop.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    drag_element_stop(mouse_event) {
        this.remove_handler(this._handler);
        this._handler = null;
        return true;
    }

    /**
     * Manage a drop, in a drag and drop event.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
    drop_element(mouse_event) {
        return true;
    }

    translate(ev, offset) {
        var selection = this._controller.get_selection();
        for (var i in selection) {
            var elt = selection[i];
            if (elt.constructor != cn_object_instance) continue;
            this.push_item_set(elt, 'position');
            elt.position = cn_add(elt.position, offset);
            this._pending_changes = true;
        }
    }
}

