import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company } from '../model/company.model';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyStore {
  private currentCompany = new BehaviorSubject<Company>(null);

  get currentCompanySnapshot() {
    return this.currentCompany.getValue();
  }

  setCurrentCompany(company: Company) {
    this.currentCompany.next(company);
  }

  unsetCurrentCompany() {
    this.currentCompany.next(null);
  }

  getCurrentCompany(): Observable<Company> {
    return this.currentCompany.asObservable().pipe(filter(it => it != null));
  }

  getCurrentCompanySnapshot(): Company {
    return this.currentCompany.getValue();
  }

  getCurrentCompanyOrNull(): Observable<Company> {
    return this.currentCompany.asObservable();
  }
}
