import { Pipe, PipeTransform } from '@angular/core';
import { PacConfig } from '../model/pac-config.model';

@Pipe({
  name: 'pacWaterVolumeOptions',
})
export class PacWaterVolumeOptionsPipe implements PipeTransform {
  transform(pac: PacConfig): string {
    return [pac.bufferTankCompliant ? 'Ballon tampon' : '', pac.decouplingCompliant ? 'Découplage' : '']
      .filter(m => !!m)
      .join(' / ');
  }
}
