'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** area trimming : a hole in legal area
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_contour } from './cn_contour';
import { cn_add, cn_box, cn_clone } from '../utils/cn_utilities';
import { fh_polygon } from '@enerbim/fh-3d-viewer';

export class cn_area_trimming extends cn_element {
    constructor(scene) {
        super();
        this.scene = scene;

        //*** Model data
        this.contours = [];
        this.draw_priority = 0;
        this.level = 0; //*** Level of area context to apply */
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.contours = [];
        for (var i in this.contours)
            json.contours.push(this.contours[i].serialize());

        json.level = this.level;
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json.contours) != 'object') return false;
        var area_trimming = new cn_area_trimming(scene);
        scene.area_trimmings.push(area_trimming);

        if (typeof (json.level) == 'number')
            area_trimming.level = json.level;

        if (typeof (json.ID) == 'string')
            area_trimming.ID = json.ID;

        for (var i in json.contours) {
            var contour = cn_contour.unserialize(json.contours[i], scene);
            area_trimming.contours.push(contour);
        }

        return area_trimming;
    }

    //***********************************************************************************
    //**** update geometry
    //***********************************************************************************
    update() {
        this.area = 0;
        for (var i in this.contours) {
            this.contours[i].update();
            if (this.contours[i].clockwise)
                this.area += this.contours[i].area;
            else
                this.area -= this.contours[i].area;
        }
        this.area = Math.abs(this.area);
    }

    //***********************************************************************************
    //**** Draw the slab contour in svg
    //***********************************************************************************
    draw(camera, add_classes) {
        var html = '';
        if (this.status < 0) return html;

        var draw_class = 'area_trimming area_trimming_' + this.level;
        if (add_classes)
            draw_class += ' ' + add_classes.join(' ');
        html += '<path class=\'' + draw_class + '\' d=\'';
        for (var i in this.contours) {
            var inner_contour = this.contours[i].vertices;
            if (typeof (inner_contour) != 'object') continue;
            for (var j = 0; j < inner_contour.length; j++) {
                if (j == 0) html += 'M ';
                else if (j == 1) html += 'L ';
                var p = camera.world_to_screen(inner_contour[j].position);
                html += '' + p[0] + ' ' + p[1] + ' ';
            }
            html += 'Z ';
        }
        html += '\' />';
        return html;
    }

    //***********************************************************************************
    //**** Draw the slab contour in svg
    //***********************************************************************************
    draw_highlight(camera, mouseover) {
        if (mouseover)
            return this.draw(camera, ['line_mouseover']);
        return this.draw(camera, ['line_selected']);
    }

    //***********************************************************************************
    //**** Translates
    //***********************************************************************************
    translate(delta) {
        for (var i in this.contours) {
            var ctr = this.contours[i];
            ctr.inner_contour = [];
            for (var j in ctr.vertices) {
                ctr.vertices[j].position = cn_add(ctr.vertices[j].position, delta);
                ctr.inner_contour.push(cn_clone(ctr.vertices[j].position));
            }
        }
    }

    //***********************************************************************************
    /**
     * Vertex operation : transform all vertices
     * @param {function} operation : vertex operator
     */
    vertex_operation(operation) {
        for (var i in this.contours) {
            var ctr = this.contours[i];
            ctr.inner_contour = [];
            for (var j in ctr.vertices) {
                operation(ctr.vertices[j].position);
                ctr.inner_contour.push(cn_clone(ctr.vertices[j].position));
            }
        }
    }

    //***********************************************************************************
    /**
     * flip operation : transform all vertices
     * @param {number[]} center : center of flip
     * @param {boolean} horizontal : true for horizontal flip, vertical otherwise
     * @param {function} operation : vertex operator
     */
    perform_flip(center, horizontal, operation) {
        for (var i in this.contours) {
            var ctr = this.contours[i];
            ctr.inner_contour = [];
            for (var j in ctr.vertices) {
                operation(ctr.vertices[j].position);
                ctr.inner_contour.push(cn_clone(ctr.vertices[j].position));
            }
            ctr.vertices = ctr.vertices.reverse();
            ctr.inner_contour = ctr.inner_contour.reverse();
        }
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contains(p) {
        var inside = 0;
        for (var i in this.contours) {
            if (this.contours[i].contains(p, false))
                return true;
        }
        return false;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contained_by_box(box) {
        for (var i in this.contours) {
            if (!this.contours[i].contained_by_box(box))
                return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** get box
    //***********************************************************************************
    get_bounding_box() {
        var box = new cn_box();
        for (var i in this.contours)
            box.enlarge_box(this.contours[i].get_bounding_box());
        return box;
    }

    //***********************************************************************************
    /**
     * Build a polygon
     * @param {number} z
     * @returns {fh_polygon}
     */
    build_3d_polygon(z) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.contours)
            polygon.add_contour(this.contours[i].build_3d_contour(z, true));
        polygon.compute_contours();
        return polygon;
    }

}

