'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

import { cn_nomenclature, find_zone, push_cn_nomenclatures_zones } from '../cn_nomenclature';
import { fh_add, fh_matrix, fh_mul, fh_polygon } from '@enerbim/fh-3d-viewer';
import { cn_azimut, cn_clone, cn_dist, cn_mul, cn_polar } from '../cn_utilities';
import { cn_building } from '../../model/cn_building';
import { cn_storey, STOREY_EXTERIOR_LABEL } from '../../model/cn_storey';
import { extension_instance } from '../../extension/cn_extension';
import { cn_zone } from '../../model/cn_zone';

//***********************************************************************************
/**
 * Buils wall nomenclatures for the building
 * @param {cn_building} building
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_walls(building, is_exterior = false) {
    const output = [];
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Espace 1'));
    output.push(new cn_nomenclature('Espace 2'));
    if (!is_exterior) {
        output.push(new cn_nomenclature('Structure'));
        output.push(new cn_nomenclature('Isolation'));
    }
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));
    output.push(new cn_nomenclature('Longueur 1').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Longueur 2').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Hauteur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Surface totale 1').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Surface totale 2').withNumberDefinition('m²', 2));
    if (!is_exterior) {
        output.push(new cn_nomenclature('Surface d\'échange').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface hors baies 1').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface hors baies 2').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface d\'échange hors baies').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('U').withNumberDefinition('W/m²/K', 3));
    } else {
        output.push(new cn_nomenclature('Surface hors ouvertures 1').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface hors ouvertures 2').withNumberDefinition('m²', 2));
    }


    const zones = building.zones;
    if (is_exterior) {
        /*** exterior */
        populate_nomenclature_walls(building.exterior, output, zones, is_exterior);
    } else {
        /*** interior */
        building.storeys.length && building.storeys.forEach((storey) => {
            populate_nomenclature_walls(storey, output, zones, is_exterior);
        });
    }

    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 /**
 * Builds wall nomenclatures for the given storey
 * @param {cn_storey} storey
 * @param {any[]} output
 * @param {{[property: string]: cn_zone[]}} zones
 * @param {boolean} is_exterior
 */
function populate_nomenclature_walls(storey, output, zones, is_exterior = false) {
    const roof_volume = (storey.exterior) ? null : storey.build_roof_volume();
    storey.get_walls().forEach((wall) => {
        if (!wall.balcony) {

            const wall_type = wall.wall_type;
            let k = 0;

            //*** name */
            output[k].values.push(wall_type.get_label());
            k++

            //*** Type */
            output[k].values.push(wall_type.get_category_label());
            k++

            //*** storey */
            let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
            output[k].values.push(storey_name);
            k++

            const s0 = (wall.get_flow_direction()) ? 0 : 1;
            const s1 = 1 - s0;

            //*** Zone */
            extension_instance.zone.get_zones_tools().forEach(tool_zone => {
                let zone_to_display = find_zone(zones[tool_zone.property], wall.spaces[s0].ID, storey.ID);
                if (!zone_to_display) {
                    zone_to_display = find_zone(zones[tool_zone.property], wall.spaces[s1].ID, storey.ID);
                }
                output[k].values.push(zone_to_display);
                k++;
            });

            //*** Space 1 */
            output[k].values.push(wall.spaces[s0].get_name(storey));
            k++

            //*** Space 2 */
            output[k].values.push(wall.spaces[s1].get_name(storey));
            k++

            if (!is_exterior) {
                //*** Structure  */
                let structure = '';
                if (!wall.fence) {
                    structure = wall_type.get_structure_label();
                }
                output[k].values.push(structure);
                k++

                //*** Insulation  */
                let insulation = '';
                if (!wall.fence) {
                    insulation = wall_type.get_insulation_label();
                }
                output[k].values.push(insulation);
                k++
            }

            //*** orientation */
            var flow_normal = cn_mul(wall.bounds.normal, (s0 == 0) ? 1 : -1);
            output[k].values.push(cn_azimut(flow_normal, storey.building.compass_orientation));
            k++

            const polygons = [wall.build_side_polygon(0, storey), wall.build_side_polygon(1, storey)];

            const exchange_polygon = polygons[0].clone();
            exchange_polygon.intersects(polygons[1]);

            //*** length  */
            output[k].values.push(wall.get_length(s0));
            k++
            output[k].values.push(wall.get_length(s1));
            k++

            //*** height  */
            output[k].values.push(Math.max(polygons[0].get_bounding_box().size[2], polygons[1].get_bounding_box().size[2]));
            k++

            //*** thickness  */
            output[k].values.push(wall_type.thickness);
            k++

            //*** full area  */
            output[k].values.push(polygons[s0].get_area());
            k++
            output[k].values.push(polygons[s1].get_area());
            k++

            if (!is_exterior) {
                output[k].values.push(exchange_polygon.get_area());
                k++
            }

            //*** area without openings  */
            wall.remove_openings_from_polygon(polygons[0], storey);
            wall.remove_openings_from_polygon(polygons[1], storey);
            wall.remove_openings_from_polygon(exchange_polygon, storey);
            output[k].values.push(polygons[s0].get_area());
            k++

            output[k].values.push(polygons[s1].get_area());
            k++

            if (!is_exterior) {
                output[k].values.push(exchange_polygon.get_area());
                k++

                output[k].values.push(wall_type.get_U());
                k++
            }
        }
    });
}
