'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_gbxml converter :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import { cn_wall_type } from '../../model/cn_wall_type';
import { cnx_dot, cnx_mul, cnx_normalize } from '../cn_utilities';
import { cn_storey } from '../../model/cn_storey';
import { cn_thermal_model } from './cn_thermal_model';
import { cn_zone } from '../../model/cn_zone';
import { cn_slab_type } from '../../model/cn_slab_type';

export function cn_to_trnsys(building) {
    var trnsys = new cn_trnsys(building);
    return trnsys.serialize();
}

//***********************************************************************************
//***********************************************************************************
//**** cn_trnsys class :
//***********************************************************************************
//***********************************************************************************

const TRNSYS_COMMENT = `*-----------------------------------------------------------------------------------------\n`;

class cn_trnsys_orientation {
    /**
     * Constructor
     * @param {string} label
     * @param {Array<number>} direction
     */
    constructor(label, direction) {
        this.label = label;
        this.direction = direction;
        cnx_normalize(this.direction);
    }

    /**
     * Find the matching orientation in a list
     * @param {Array<number>} direction
     * @param {Array<cn_trnsys_orientation>} orientations
     * @returns {string}
     */
    static find(direction, orientations) {
        var best_value = -2;
        var best_orientation = null;
        orientations.forEach(ori => {
            const x = cnx_dot(direction, ori.direction);
            if (x > best_value) {
                best_value = x;
                best_orientation = ori;
            }
        });
        // @ts-ignore
        if (best_orientation) return best_orientation.label;
        return '';
    }

    /**
     * Build default orientations
     * @returns {Array<cn_trnsys_orientation>}
     */
    static default_orientations() {
        var orientations = [];

        function direction(phi, theta) {
            const costheta = Math.cos(theta * Math.PI / 180);
            const rad_phi = (-phi - 90) * Math.PI / 180;
            return [Math.cos(rad_phi) * costheta, Math.sin(rad_phi) * costheta, Math.sin(theta * Math.PI / 180)];
        }

        orientations.push(new cn_trnsys_orientation('N_180', direction(180, 0)));
        orientations.push(new cn_trnsys_orientation('NNO_157', direction(157, 0)));
        orientations.push(new cn_trnsys_orientation('NO_135', direction(135, 0)));
        orientations.push(new cn_trnsys_orientation('ONO_112', direction(112, 0)));
        orientations.push(new cn_trnsys_orientation('O_90', direction(90, 0)));
        orientations.push(new cn_trnsys_orientation('OSO_67', direction(67, 0)));
        orientations.push(new cn_trnsys_orientation('SO_45', direction(45, 0)));
        orientations.push(new cn_trnsys_orientation('SSO_22', direction(22, 0)));
        orientations.push(new cn_trnsys_orientation('S_0', direction(0, 0)));
        orientations.push(new cn_trnsys_orientation('SSE_337', direction(337, 0)));
        orientations.push(new cn_trnsys_orientation('SE_315', direction(315, 0)));
        orientations.push(new cn_trnsys_orientation('ESE_292', direction(292, 0)));
        orientations.push(new cn_trnsys_orientation('E_270', direction(270, 0)));
        orientations.push(new cn_trnsys_orientation('ENE_247', direction(247, 0)));
        orientations.push(new cn_trnsys_orientation('NE_225', direction(225, 0)));
        orientations.push(new cn_trnsys_orientation('NNE_202', direction(202, 0)));

        orientations.push(new cn_trnsys_orientation('INC_0', direction(0, 90)));

        orientations.push(new cn_trnsys_orientation('S_0_INC30', direction(0, 30)));
        orientations.push(new cn_trnsys_orientation('O_90_INC30', direction(90, 30)));
        orientations.push(new cn_trnsys_orientation('N_180_INC30', direction(180, 30)));
        orientations.push(new cn_trnsys_orientation('E_270_INC30', direction(270, 30)));

        return orientations;
    }
}

class cn_trnsys {
    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building) {
        this._building = building;
        this._nb_zones = 10;
        this._walls = [];
        this._orientations = cn_trnsys_orientation.default_orientations();
        this._element_count = 0;

    }

    //********************************************************************************
    /**
     * Main function
     * @returns {string}
     */
    serialize() {

        this._thermal_model = new cn_thermal_model(this._building);
        this._thermal_model.analyse();
        this._thermal_model.walls = this._thermal_model.walls.filter(w => w.element_type);
        this._nb_zones = this._thermal_model.zones.length;

        this._nb_surfaces = 0;
        this._thermal_model.walls.forEach(wall => {
            const increment = (wall.spaces[0] && wall.spaces[1] && wall.spaces[0].zone != wall.spaces[1].zone) ? 2 : 1;
            wall.surface = this._nb_surfaces;
            this._nb_surfaces += increment;
            wall.openings = wall.openings.filter(op => op.element_type);
            wall.openings.forEach(opening => {
                opening.surface = this._nb_surfaces;
                this._nb_surfaces += increment;
            });
        });

        var contents = '';
        contents += this._serialize_header();
        contents += `TYPES\n`;
        contents += this._serialize_layers();
        contents += this._serialize_inputs();
        contents += this._serialize_wall_types();
        contents += this._serialize_window_types();
        contents += this._serialize_misc();
        contents += this._serialize_building();

        contents += TRNSYS_COMMENT;
        contents += `* End\n`;
        contents += TRNSYS_COMMENT;
        contents += `END\n`;
        return contents;
    }

    //********************************************************************************
    /**
     * Header
     * @returns {string}
     */
    _serialize_header() {
        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Properties\n`;
        contents += TRNSYS_COMMENT;
        contents += `PROPERTIES\n`;
        contents += `DENSITY=1.204 : CAPACITY=1.012 : PRESSURE=101325.000 : HVAPOR=2454.0 : SIGMA=2.041e-007 : RTEMP=293.15\n`;
        contents += `KFLOORUP=7.2 : EFLOORUP=0.31 : KFLOORDOWN=3.888 : EFLOORDOWN=0.31\n`;
        contents += `KCEILUP=7.2 : ECEILUP=0.31 : KCEILDOWN=3.888 : ECEILDOWN=0.31\n`;
        contents += `KVERTICAL=5.76 : EVERTICAL=0.3\n`;

        return contents;
    }

    //********************************************************************************
    /**
     * Layers
     * @returns {string}
     */
    _serialize_layers() {
        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Layers\n`;
        contents += TRNSYS_COMMENT;

        //*** gather all used materials */
        var wall_layer_codes = [];
        this._thermal_model.walls.forEach(wall => {
            wall.element_type.layers.forEach(layer => {
                if (!wall_layer_codes.includes(layer.code)) {
                    wall_layer_codes.push(layer.code);
                    contents += `* Layer : ${layer.name}\n`;
                    contents += `LAYER ${layer.code}\n`;
                    contents += ` CONDUCTIVITY = ${layer.conductivity} : `;
                    contents += ` CAPACITY = ${0.001 * layer.specific_heat} : `;
                    contents += ` DENSITY = ${layer.density}\n`;
                }
            });
        });

        return contents;
    }

    _zone_prefix(z) {
        const zz = z + 1;
        if (zz < 10) return '0' + zz;
        return '' + zz;
    }

    //********************************************************************************
    /**
     * Inputs
     * @returns {string}
     */
    _serialize_inputs() {
        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Inputs\n`;
        contents += TRNSYS_COMMENT;

        contents += `INPUTS TEXT TGRD `;
        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `${prefix}INTRADGAIN `;
            contents += `${prefix}INTCONGAIN `;
            contents += `${prefix}INTABSHUM `;
            contents += `${prefix}VENTTEMP `;
            contents += `${prefix}VENTACH `;
            contents += `${prefix}VENTHUMI `;
            contents += `${prefix}COOLTEMP `;
            contents += `${prefix}COOLHR `;
            contents += `${prefix}COOLPOW `;
            contents += `${prefix}HEATTEMP `;
            contents += `${prefix}HEATHR `;
            contents += `${prefix}HEATPOW `;
            contents += `${prefix}INFARC `;
            contents += `ACH_${prefix}_EXT `;
            for (var z1 = 0; z1 < this._nb_zones; z1++) {
                if (z != z1) {
                    contents += `AF_${prefix}_Z${this._zone_prefix(z1)} `;
                }
            }
            contents += `OCC_${prefix} `;
            contents += `AF_${prefix};\n`;
        }
        return contents;
    }

    _format_element_count(x) {
        var txt = '' + x;
        if (x < 10) txt = '0' + txt;
        if (x < 100) txt = '0' + txt;
        if (x < 1000) txt = '0' + txt;
        if (x < 10000) txt = '0' + txt;
        return txt;
    }

    //********************************************************************************
    /**
     * Wall types
     * @returns {string}
     */
    _serialize_wall_types() {
        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Walls\n`;
        contents += TRNSYS_COMMENT;

        //*** gather all used materials *
        var wall_type_codes = [];
        var wall_type_count = 0;
        this._thermal_model.walls.forEach(wall => {
            const wt = wall.element_type;
            if (!wall_type_codes.includes(wt.ID)) {
                wall_type_count++;
                wt.trnsys_id = 'WALLTYPE_' + this._format_element_count(wall_type_count);
                wall_type_codes.push(wt.ID);
                if (wt.constructor == cn_wall_type)
                    contents += `* Wall type : ${wt.get_label()}\n`;
                else if (wt.constructor == cn_slab_type)
                    contents += `* Slab type : ${wt.get_label()}\n`;
                contents += `WALL ${wt.trnsys_id}\n`;
                contents += ` LAYERS =`;
                wt.layers.forEach(layer => {
                    contents += ' ' + layer.code
                });
                contents += `\n`;
                contents += ` THICKNESS =`;
                wt.layers.forEach(layer => {
                    contents += ' ' + layer.thickness
                });
                contents += `\n`;
                contents += ` ABS-FRONT= 0.6 : ABS-BACK= 0.5\n`;
                contents += ` EPS-FRONT= 0.9 : EPS-BACK= 0.9\n`;
                contents += ` HFRONT = 11 : HBACK= 64\n`;
            }
        });

        return contents;
    }

    //********************************************************************************
    /**
     * Window types
     * @returns {string}
     */
    _serialize_window_types() {

        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Windows\n`;
        contents += TRNSYS_COMMENT;

        var opening_type_codes = [];
        var opening_type_count = 0;
        this._thermal_model.walls.forEach(wall => {
            wall.openings.forEach(op => {
                const wt = op.element_type;
                if (!opening_type_codes.includes(wt.ID)) {
                    opening_type_count++;
                    wt.trnsys_id = 'WINDOWTYPE_' + this._format_element_count(opening_type_count);
                    opening_type_codes.push(wt.ID);
                    contents += `* Opening type : ${wt.get_label()}\n`;
                    contents += `WINDOW ${wt.trnsys_id}\n`;

                    var wtid = 0;
                    if (wt.glazing == 'single')
                        wtid = 13902;
                    else if (wt.glazing == 'triple')
                        wtid = 12007;
                    else if (wt.glazing == 'double') {
                        if (wt.glazing_gaz == 'air_6')
                            wtid = 13909;
                        else if (wt.glazing_gaz == 'air_8')
                            wtid = 13910;
                        else if (wt.glazing_gaz == 'air_12')
                            wtid = 13921;
                        else if (wt.glazing_gaz == 'air_16')
                            wtid = 2004;
                        else if (wt.glazing_gaz == 'argon_16')
                            wtid = 13002;
                    }

                    contents += ` WINID = ${wtid} :`;
                    contents += ` HINSIDE = 11 :`;
                    contents += ` HOUTSIDE = 64 :`;
                    contents += ` SLOPE = 90 :`;
                    contents += ` SPACID = 1 :`;
                    contents += ` WWID = ${wt.width} :`;
                    contents += ` WHEIG = ${wt.height} :`;
                    contents += ` FFRAME = ${0.15} :`;
                    contents += ` UFRAME = ${9} :`;
                    contents += ` ABSFRAME = ${0.6} :`;
                    contents += ` RISHADE = ${0} :`;
                    contents += ` RESHADE = ${0} :`;
                    contents += ` REFLISHADE = ${0.5} :`;
                    contents += ` REFLOSHADE = ${0.5} :`;
                    contents += ` CCISHADE = ${0.5} :`;
                    contents += ` EPSFRAME = ${0.9} :`;
                    contents += ` EPSISHADE = ${0.9} :`;
                    contents += ` ITSHADECLOSE = ${10000} :`;
                    contents += ` ITSHADEOPEN = ${9999}\n`;
                }
            });
        });
        return contents;
    }

    //********************************************************************************
    /**
     * Window types
     * @returns {string}
     */
    _serialize_misc() {

        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Default Gains\n`;
        contents += TRNSYS_COMMENT;

        contents += `GAIN PERS_ISO01\n`;
        contents += ` CONVECTIVE=144 : RADIATIVE=72 : HUMIDITY=0.059\n`;

        contents += TRNSYS_COMMENT;
        contents += `* Other Gains\n`;
        contents += TRNSYS_COMMENT;

        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `GAIN GAINSINTERNES_${prefix}\n`;
            contents += ` CONVECTIVE=INPUT 1*${prefix}INTCONGAIN\n`;
            contents += ` RADIATIVE=INPUT 1*${prefix}INTRADGAIN\n`;
            contents += ` HUMIDITY=INPUT 1*${prefix}INTABSHUM\n`;
        }

        contents += TRNSYS_COMMENT;
        contents += `* Infiltrations\n`;
        contents += TRNSYS_COMMENT;

        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `INFILTRATION INFIL_${prefix}\n`;
            contents += ` AIRCHANGE=INPUT 1*${prefix}INFARC\n`;
        }

        contents += TRNSYS_COMMENT;
        contents += `* Ventilation\n`;
        contents += TRNSYS_COMMENT;

        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `VENTILATION VENT_${prefix}\n`;
            contents += ` TEMPERATURE=INPUT 1*${prefix}VENTTEMP\n`;
            contents += ` AIRCHANGE=INPUT 1*${prefix}VENTACH\n`;
            contents += ` HUMIDITY=INPUT 1*${prefix}VENTHUMI\n`;
        }

        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `VENTILATION VENT_${prefix}_EXT\n`;
            contents += ` TEMPERATURE=OUTSIDE\n`;
            contents += ` AIRCHANGE=INPUT 1*ACH_${prefix}_EXT\n`;
            contents += ` HUMIDITY=OUTSIDE\n`;
        }

        contents += TRNSYS_COMMENT;
        contents += `* Cooling\n`;
        contents += TRNSYS_COMMENT;

        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `COOLING COOL_${prefix}\n`;
            contents += ` ON=INPUT 1*${prefix}COOLTEMP\n`;
            contents += ` POWER=INPUT 1*${prefix}COOLPOW\n`;
            contents += ` HUMIDITY=INPUT 1*${prefix}COOLHR\n`;
        }

        contents += TRNSYS_COMMENT;
        contents += `* Heating\n`;
        contents += TRNSYS_COMMENT;

        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = 'Z' + this._zone_prefix(z);
            contents += `HEATING HEAT_${prefix}\n`;
            contents += ` ON=INPUT 1*${prefix}HEATTEMP\n`;
            contents += ` POWER=INPUT 1*${prefix}HEATPOW\n`;
            contents += ` HUMIDITY=INPUT 1*${prefix}HEATHR\n`;
        }

        contents += TRNSYS_COMMENT;
        contents += `* Zones\n`;
        contents += TRNSYS_COMMENT;

        contents += `ZONES`;
        for (var z = 0; z < this._nb_zones; z++) {
            const prefix = this._zone_prefix(z);
            contents += ` ZONE${prefix}`;
        }
        contents += `\n`;

        contents += TRNSYS_COMMENT;
        contents += `* Orientations\n`;
        contents += TRNSYS_COMMENT;

        contents += `ORIENTATIONS`;
        this._orientations.forEach(or => {
            contents += ` ${or.label}`;
        })
        contents += `\n`;

        contents += `HEMISPHERE NORTHERN\n`;

        return contents;
    }

    //********************************************************************************
    /**
     * Building
     * @returns {string}
     */
    _serialize_building() {
        var contents = '';

        contents += TRNSYS_COMMENT;
        contents += `* Building\n`;
        if (this._building.has_non_empty_zone('thermal')) {
            contents += `* The zones are mapped on the zones defined in the building\n`;
            contents += `* Spaces not belonging to any zone are not described\n`;
        } else {
            contents += `* No zone is defined in the building. Zones are mapped on storeys.\n`;
            contents += `* All inner spaces are described\n`;
        }
        contents += TRNSYS_COMMENT;

        contents += `BUILDING\n`;

        for (var z = 0; z < this._nb_zones; z++)
            contents += this._serialize_zone(z);

        return contents;
    }


    //********************************************************************************
    /**
     * Zone
     * @param {number} z
     * @returns {string}
     */
    _serialize_zone(z) {
        var contents = '';

        const prefix = this._zone_prefix(z);

        const thermal_zone = this._thermal_model.zones[z];

        function element_count() {
            this._element_count++;
            var txt = '' + this._element_count;
            if (this._element_count < 10) txt = '0' + txt;
            if (this._element_count < 100) txt = '0' + txt;
            if (this._element_count < 1000) txt = '0' + txt;
            if (this._element_count < 10000) txt = '0' + txt;
            return txt;
        }

        contents += TRNSYS_COMMENT;
        contents += `* Zone ZONE${prefix} / Airnode ZONE${prefix}\n`;
        if (thermal_zone.element.constructor == cn_storey)
            contents += `* Zone based upon storey "${thermal_zone.element.name}"\n`;
        else if (thermal_zone.element.constructor == cn_zone)
            contents += `* Zone based upon zone "${thermal_zone.element.name}"\n`;

        contents += TRNSYS_COMMENT;

        contents += `ZONE ZONE${prefix}\n`;

        contents += `RADIATIONMODE\n`;
        contents += ` BEAM=STANDARD : DIFFUSE=STANDARD : LONGWAVE=STANDARD : GEOMODE=MANUAL : FSOLAIR=0\n`;
        contents += `AIRNODE ZONE${prefix}\n`;

        this._thermal_model.walls.filter(wall => (wall.spaces[0] && wall.spaces[0].zone == thermal_zone) || (wall.spaces[1] && wall.spaces[1].zone == thermal_zone)).forEach(wall => {
            const element_type = wall.element_type;

            if (element_type.constructor == cn_wall_type)
                contents += `* Wall type : ${element_type.get_label()}\n`;
            else if (element_type.constructor == cn_slab_type)
                contents += `* Slab type : ${element_type.get_label()}\n`;

            contents += `WALL = ${element_type.trnsys_id} :`;
            var surface_offset = (wall.spaces[0] && wall.spaces[1] && (wall.spaces[0].zone != wall.spaces[1].zone) && wall.spaces[1].zone == thermal_zone) ? 1 : 0;
            contents += ` SURF = ${wall.surface + surface_offset} :`;
            var boundary = '';
            contents += ` AREA = ${wall.polygon.get_area().toFixed(2)} :`;
            if (wall.spaces[0] && wall.spaces[1]) {
                if (wall.spaces[0].zone == wall.spaces[1].zone)
                    boundary += ` INTERNAL\n`;
                else {
                    const other_zone_index = this._thermal_model.zones.indexOf(wall.spaces[1 - surface_offset].zone);
                    boundary += ` ADJACENT = ZONE${this._zone_prefix(other_zone_index)} :`;
                    boundary += ` ADJ_SURF = #ADJ# :`;
                    if (surface_offset == 0)
                        boundary += ` FRONT :`;
                    else
                        boundary += ` BACK :`;
                    boundary += ` COUPL = INPUT 1*AF_Z${this._zone_prefix(other_zone_index)}\n`;
                }
            } else {
                const index = (wall.spaces[1]) ? 1 : 0;
                if (wall.cn_spaces[1 - index] && wall.cn_spaces[1 - index].indoor) {
                    boundary += ` BOUNDARY = IDENTICAL\n`;
                } else if (!wall.vertical && wall.spaces[1]) {
                    boundary += ` BOUNDARY = TGRD\n`;
                } else {
                    boundary += ` EXTERNAL :`;
                    var normal = wall.polygon.get_normal();
                    if (wall.spaces[1]) normal = cnx_mul(normal, -1);

                    boundary += ` ORI = ${cn_trnsys_orientation.find(normal, this._orientations)} :`;
                    boundary += ` FSKY = ${0.5 * normal[2] + 0.5} \n`;
                }
            }
            contents += boundary.replace('#ADJ#', '' + (wall.surface + 1 - surface_offset));

            wall.openings.forEach(opening => {
                contents += `* Opening type : ${opening.element_type.get_label()}\n`;
                contents += `WINDOW = ${opening.element_type.trnsys_id} :`;
                contents += ` SURF = ${opening.surface + surface_offset} :`;
                contents += ` AREA = ${opening.polygon.get_area().toFixed(2)} :`;
                contents += boundary.replace('#ADJ#', '' + (opening.surface + 1 - surface_offset));
            });
        });

        contents += `REGIME\n`;
        contents += ` GAIN = PERS_ISO01 : SCALE= INPUT 1*OCC_Z${prefix} : GEOPOS= 0\n`;
        contents += ` GAIN = GAINSINTERNES_Z${prefix} : SCALE= 1 : GEOPOS= 0\n`;
        contents += ` INFILTRATION = INFIL_Z${prefix}\n`;
        contents += ` VENTILATION = VENT_Z${prefix}\n`;
        contents += ` VENTILATION = VENT_Z${prefix}_EXT\n`;
        contents += ` COOLING = COOL_Z${prefix}\n`;
        contents += ` HEATING = HEAT_Z${prefix}\n`;
        contents += ` CAPACITANCE = 70.98 :`;
        var volume = 0;
        thermal_zone.spaces.forEach(ts => volume += ts.solid.get_volume());
        contents += ` VOLUME = ${volume} :`;
        contents += ` TINITIAL = 20 :`;
        contents += ` PHINITIAL = 50 :`;
        contents += ` WCAPR = 1\n`;
        return contents;
    }

    _encode_string(str) {
        return str;
    }
}
