'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_view_overlay - A SVG overlay over 3D view
//***********************************************************************************
//***********************************************************************************

import { cn_camera } from './cn_camera';
import { fh_view } from '@enerbim/fh-3d-viewer';
import { cnx_add, cnx_dist, cnx_dot, cnx_mul, cnx_sub } from '../utils/cn_utilities';
import { cn_3d_building } from '../model/cn_3d_building';

/**
 * @class cn_view_overlay - A SVG overlay over 3D view
 */
export class cn_overlay_camera extends cn_camera {

    //***********************************************************************************
    //**** Constructor
    //***********************************************************************************
    /**
     * Constructor
     * @param {fh_view} view  : 3d view
     * @param {cn_3d_building} building_3d  : 3d building
     */
    constructor(view, building_3d) {
        super();
        this._view = view;
        this._3d_building = building_3d;
        this._workplane = null;
    }

    /**
     * Does the camera uses 3D (true for derivate such as cn_overlay_camera)
     * @returns {boolean}
     */
    is_3d() {
        return true;
    }

    /**
     * World to screen conversion
     * @param {number[]} point
     * @return {number[]}
     */
    world_to_screen(point, z = 0) {
        var zz = z;
        if (point.length >= 3) zz += point[2];
        const res = this._view.world_to_screen(point[0], point[1], zz);
        if (res === false) return [];
        return [res.left, res.top];
    }

    /**
     * Screen to world conversion
     * @param {number[]} screen
     * @returns  {number[]}
     */
    screen_to_world(screen) {
        var ray = this._view.get_screen_ray(screen);
        var lambda = 1;
        if (this._workplane) {
            const dt = cnx_dot(ray.direction, this._workplane[1]);
            if (Math.abs(dt) > 0.001) {
                lambda = cnx_dot(this._workplane[1], cnx_sub(this._workplane[0], ray.origin)) / dt;
                if (lambda < 0.001) lambda = 1;
            }
        }
        return cnx_add(ray.origin, cnx_mul(ray.direction, lambda));
    }

    /**
     * Checks if a world point is visible on screen (only for 3D camera)
     * @param {number[]} point
     * @returns {boolean}
     */
    check_visibility(point) {
        const screen = this.world_to_screen(point);
        if (screen.length < 2) return false;

        var impact = this._view.get_screen_object(screen);
        if (impact == null) return true;
        var ray = this._view.get_screen_ray(screen);
        const dst0 = cnx_dist(ray.origin, impact.position);
        const dst1 = cnx_dist(ray.origin, point);
        if (dst1 > dst0 + 0.1) return false;
        return true;
    }

}

