//***********************************************************************************
//***********************************************************************************
//**** Vector utilities
//***********************************************************************************
//***********************************************************************************

export function fh_clone(p) {
	return [p[0],p[1],p[2]];
}

export function fh_add(p0, p1) {
	return [p0[0]+p1[0],p0[1]+p1[1],p0[2]+p1[2]];
}

export function fh_sub(p0, p1) {
	return [p0[0]-p1[0],p0[1]-p1[1],p0[2]-p1[2]];
}

export function fh_size(p) {
	return Math.sqrt(p[0]*p[0] + p[1]*p[1] + p[2]*p[2]);
}

export function fh_normalize(p) {
	var n = fh_size(p);
	var invn = (n > 0)? 1/n:n;
	p[0] *= invn;
	p[1] *= invn;
	p[2] *= invn;
	return n;
}

export function fh_dot(p0, p1) {
	return p0[0]*p1[0] + p0[1]*p1[1] + p0[2]*p1[2];
}

export function fh_cross(p0, p1) {
	return [p0[1]*p1[2] - p0[2]*p1[1],p0[2]*p1[0] - p0[0]*p1[2],p0[0]*p1[1] - p0[1]*p1[0]];
}

export function fh_dist(p0, p1) {
	return fh_size(fh_sub(p0,p1));
}

export function fh_manhattan_dist(p0, p1) {
	return Math.abs(p0[0]-p1[0]) + Math.abs(p0[1]-p1[1]) + Math.abs(p0[2]-p1[2]);
}

export function fh_mul(p, x) {
	return [p[0]*x,p[1]*x,p[2]*x];
}

export function fh_copy(dest,src) {
	dest[0] = src[0];
	dest[1] = src[1];
	dest[2] = src[2];
}

export function fh_polar(p) {
	var pol = [0,0,0];
	pol[0] = Math.sqrt(fh_dot(p,p));
	if (pol[0] == 0) return pol;
	pol[1] = Math.asin(p[2] / pol[0]);
	var pz = Math.sqrt(p[0] * p[0] + p[1] * p[1]);
	if (pz != 0)
	{
		pol[2] = Math.acos(p[0] / pz);
		if (p[1] < 0) pol[2] = -pol[2];
	}
	return pol;
}

export function fh_cart(p) {
	return [p[0] * Math.cos(p[2]) * Math.cos(p[1]),p[0] * Math.sin(p[2]) * Math.cos(p[1]),p[0] * Math.sin(p[1])];
}

export function fh_build_axis(normal,dx,dy) {
	fh_normalize(normal);
	var ddx;
	if (Math.abs(normal[2]) < 0.99)
		ddx = fh_cross([0,0,1],normal);
	else
		ddx = fh_cross([0,1,0],normal);
	fh_normalize(ddx);
	var ddy = fh_cross(normal,ddx);
	fh_normalize(ddy);
	fh_copy(dx,ddx);
	fh_copy(dy,ddy);
}
