/**
 *
 * @type {any}
 */
const configHolder = { imageDir: '' };

export function cn_image_dir() {

    return configHolder.imageDir;
}

export function cn_set_image_dir(dir) {
    configHolder.imageDir = dir;
}
