import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FrontVersionApiService {
  private semanticVersionRegExp = /(\d+)\.(\d+)\.(\d+)(\-rc)?(\d*)/;

  constructor(private http: HttpClient) {}

  getVersion(): Observable<string> {
    return this.http.get('/api/front-version', { responseType: 'text' });
  }

  isVersionOutdated(): Observable<boolean> {
    return this.getVersion().pipe(
      map(version => {
        const frontCachedVersion = this.getFields(environment.appVersion);
        const mandatoryVersion = this.getFields(version);
        return !frontCachedVersion.every(
          (num, i) =>
            num >= mandatoryVersion[i] || frontCachedVersion.slice(0, i).some((x, j) => x > mandatoryVersion[j]),
        );
      }),
    );
  }

  private getFields(version) {
    if (this.semanticVersionRegExp.test(version)) {
      let [scope, major, minor, patch, _, rc] = version.match(this.semanticVersionRegExp);
      rc = rc || '0';
      return [major, minor, patch, rc].map(m => +m);
    } else {
      return [0, 0, 0, 0];
    }
  }
}
