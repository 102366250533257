'use strict';
import { fh_add, fh_mul, fh_polygon } from '@enerbim/fh-3d-viewer';
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************
import { extension_instance } from '../extension/cn_extension';
import { BC_MATERIAL_LIST, BC_SHAPE_LIST } from '../model/cn_beam_column_type';
import { cn_building } from '../model/cn_building';
import { code_to_label } from '../model/cn_opening_type';
import { PIPE_FLUID_LIST, PIPE_MATERIAL_LIST } from '../model/cn_pipe_type';
import { cn_slab_type } from '../model/cn_slab_type';
import { SPACE_EXTERIOR_LABEL } from '../model/cn_space';
import { cn_storey, STOREY_EXTERIOR_LABEL } from '../model/cn_storey';
import { cn_wall_type } from '../model/cn_wall_type';
import { cn_zone } from '../model/cn_zone';
import { cn_azimut, cn_clone, cn_dist, cn_normalize, cn_sub } from './cn_utilities';

// Constants to be used by upper level libraries, i.e. Caparol configurator
export const NOMENCLATURE_LABEL_FACING_NONE = 'Aucun'
export const NOMENCLATURE_COLUMNS_ID_FACING = 'facing'
export const NOMENCLATURE_COLUMNS_ID_FACING_ID = 'facing_ID'
export const NOMENCLATURE_COLUMNS_ID_FACING_SURFACE = 'facing_surface'
export const NOMENCLATURE_COLUMNS_ID_FACADE_AREA = 'facade_area'
export const NOMENCLATURE_COLUMNS_ID_FACADE_FULL_AREA = 'facade_full_area'
export const NOMENCLATURE_COLUMNS_ID_FACADE_JOINING_ANGLE = 'facade_joining_angle'
export const NOMENCLATURE_COLUMNS_ID_FACADE_BORDER_ANGLE = 'facade_border_angle'
export const NOMENCLATURE_COLUMNS_ID_FACADE_HIGH_LINE = 'facade_high_line'
export const NOMENCLATURE_COLUMNS_ID_FACADE_LOW_LINE = 'facade_low_line'
export const NOMENCLATURE_COLUMNS_ID_FACADE_EMBRASURE = 'facade_embrasure'
export const NOMENCLATURE_COLUMNS_ID_FACADE_OPENING_AREA = 'facade_opening_area'
export const NOMENCLATURE_COLUMNS_ID_FACADE_DOOR_LEDGE = 'facade_door_ledge'
export const NOMENCLATURE_COLUMNS_ID_FACADE_WINDOW_LEDGE = 'facade_window_ledge'

/**
 * @class cn_nomenclature
 * Juster a label and a list of values
 */
export class cn_nomenclature {
    /**
     * @param { string } label
     */
    constructor(label = '') {
        this.id = null;
        /** Label including unit */
        this.label = label;
        /** Label without unit */
        this.raw_label = label;
        this.type = 'string';
        this.decimals = 0;
        this.visible = true;
        this.values = [];
        this.formatted_values = [];
        this.virtual = false;
    }

    /**
     * Sets attribute and return updated object
     * @param {string} id
     * @return {cn_nomenclature}
     */
    withId(id) {
        this.id = id;
        return this;
    }

    /**
     * Sets number attributes and return updated object
     * @param {string} unit
     * @param {number} decimals
     * @return {cn_nomenclature}
     */
    withNumberDefinition(unit, decimals = 0) {
        this.type = 'number';
        this.unit = unit;
        this.decimals = decimals;
        if (unit) {
            this.label = this.raw_label + ` (${unit})`;
        }
        return this;
    }

    /**
     * Sets attribute and return updated object
     * @param { 'string' | 'number' | 'date' | 'boolean' } type
     * @return {cn_nomenclature}
     */
    withType(type) {
        this.type = type;
        return this;
    }

    /**
     * Sets attribute and return updated object
     * @param {boolean} visible
     * @return {cn_nomenclature}
     */
    withVisibility(visible) {
        this.visible = visible;
        return this;
    }

    /**
     * Sets attribute and return updated object
     * @param {boolean} virtual
     * @return {cn_nomenclature}
     */
    withVirtual(virtual) {
        this.virtual = virtual;
        return this;
    }

    /**
     * Returns csv export of a list of nomenclatures
     * @param {cn_nomenclature[]} list
     * @returns {string} csv string
     */
    static to_csv(list) {
        const nom_list = this.format_values(list);
        let csv = '';
        let size = 0;
        for (let n = 0; n < nom_list.length; n++) {
            if (n > 0) csv += ';';
            csv += nom_list[n].label;
            if (nom_list[n].formatted_values.length > size)
                size = nom_list[n].formatted_values.length;
        }
        csv += '\n';

        for (let l = 0; l < size; l++) {
            for (let n = 0; n < nom_list.length; n++) {
                if (n > 0) csv += ';';
                if (l >= nom_list[n].formatted_values.length) continue;
                const v = nom_list[n].formatted_values[l];
                csv += v;
            }
            csv += '\n';
        }
        return csv;
    }

    /**
     * Returns html of a list of nomenclatures
     * @param {cn_nomenclature[]} list
     * @returns {string} csv string
     */
    static to_html(list) {
        const nom_list = this.format_values(list);
        let size = 0;
        for (let n = 0; n < nom_list.length; n++) {
            if (nom_list[n].values.length > size)
                size = nom_list[n].values.length;
        }

        let html = '';
        html += '<table width=\'100%\'>';
        html += '<tr>';
        nom_list.forEach(column => {
            html += `<th>${column.label}</th>`;
        });
        html += '</tr>';
        html += '<tbody>';
        for (let l = 0; l < size; l++) {
            html += '<tr>';
            nom_list.forEach(column => {
                html += `<td>${(l < column.values.length) ? column.formatted_values[l] : ''}</td>`;
            });
            html += '</tr>';
        }
        html += '</tbody>';
        html += '</table>';
        return html;
    }

    /**
     * Format values by type
     *
     * @param {cn_nomenclature[]} list
     */
    static format_values(list) {
        return list.map(nomenclature => {
            nomenclature.formatted_values = nomenclature.values.map(value => {
                let result = null
                if (value || value === 0 || value === false) {
                    if (nomenclature.type === 'string') {
                        result = value.toString();
                    } else if (nomenclature.type === 'number' && Number.isFinite(value)) {
                        result = value.toLocaleString('fr', { minimumFractionDigits: nomenclature.decimals || 0, maximumFractionDigits: nomenclature.decimals || 0 })
                    } else if (nomenclature.type === 'boolean') {
                        result = value ? 'Oui' : 'Non';
                    } else if (nomenclature.type === 'date') {
                        result = value.toLocaleDateString('fr');
                    } else {
                        result = value.toString();
                    }
                } else {
                    result = '';
                }
                return result;
            })
            return nomenclature;
        })
    }
}

//***********************************************************************************
/**
 * Buils space nomenclatures for the building
 * @param {cn_building} building
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_spaces(building, is_exterior = false) {
    const output = [];
    const zones = building.zones;
    push_cn_nomenclatures_zones(output, false);
    output.push(new cn_nomenclature('Nom'));

    if (is_exterior) {
        output.push(new cn_nomenclature('Revêtement'));
        output.push(new cn_nomenclature('Niveau'));
        output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface déclarée').withNumberDefinition('m²', 2).withVisibility(false));
        output.push(new cn_nomenclature('Surface nette').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Périmètre').withNumberDefinition('m', 2));
        output.push(new cn_nomenclature('Périmètre déclaré').withNumberDefinition('m', 2).withVisibility(false));
        output.push(new cn_nomenclature('Usage'));

        prepare_area_contexts(building, output);
        populate_nomenclature_spaces(building.exterior, zones, output, true, building)
    } else {
        output.push(new cn_nomenclature('Type'));
        output.push(new cn_nomenclature('Revêtement de sol'));
        output.push(new cn_nomenclature('Revêtement de plafond'));
        output.push(new cn_nomenclature('Niveau'));
        output.push(new cn_nomenclature('Hauteur max sous plafond').withNumberDefinition('m', 2));
        output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface plancher').withNumberDefinition('m²', 2));
        output.push(new cn_nomenclature('Surface déclarée').withNumberDefinition('m²', 2).withVisibility(false));
        output.push(new cn_nomenclature('Périmètre déclaré').withNumberDefinition('m²', 2).withVisibility(false));
        output.push(new cn_nomenclature('Volume').withNumberDefinition('m³', 2));
        output.push(new cn_nomenclature('Espace chauffé').withType('boolean'));
        output.push(new cn_nomenclature('Usage'));

        prepare_area_contexts(building, output);
        building.storeys.length && building.storeys.forEach((storey) => {
            populate_nomenclature_spaces(storey, zones, output, false, building);
        });
    }

    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 * Build slab's types nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_slabs_types(building) {
    const output = [];

    const default_configuration = cn_slab_type.configuration();
    const lines_per_tab = default_configuration.tabs.map(tab => tab.lines.length);
    const max_material = Math.max(...lines_per_tab);
    const slabs_tybes = building.get_slab_types();

    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('U').withNumberDefinition('W/m²/K', 3));
    output.push(new cn_nomenclature('Nombre d\'occurence').withNumberDefinition('', 0));
    output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));

    for (let i = 1; i <= max_material; i++) {
        const nomenclature = new cn_nomenclature('Matériau ' + i);
        nomenclature.values = Array(slabs_tybes.length).fill('');
        output.push(nomenclature);
    }

    const slabs = building.storeys.flatMap(st => st.slabs);

    slabs_tybes.forEach((slab_type, i) => {
        let nomenclature_entry_index = 0;
        output[nomenclature_entry_index].values[i] = slab_type.get_label();
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = slab_type.thickness;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = slab_type.get_U();
        nomenclature_entry_index++;
        const slabs_of_type = slabs.filter(sl => sl.slab_type.ID === slab_type.ID);
        output[nomenclature_entry_index].values[i] = slabs_of_type.length;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = slabs_of_type.reduce((agg, sl) => agg + sl.build_polygon(0).get_area(), 0);
        nomenclature_entry_index++;
        slab_type.layers.forEach(layer => {
            output[nomenclature_entry_index].values[i] = layer.get_label();
            nomenclature_entry_index++;
        });
    });

    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 * Buils wall's types  nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_walls_types(building) {
    const output = [];

    const default_configuration = cn_wall_type.configuration();
    const lines_per_tab = default_configuration.tabs.map(tab => tab.lines.length);
    const max_material = Math.max(...lines_per_tab);
    const wall_types = building.get_wall_types();

    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('U').withNumberDefinition('W/m²/K', 3));
    output.push(new cn_nomenclature('Nombre d\'occurence').withNumberDefinition('', 0));
    output.push(new cn_nomenclature('Surface totale hors baies 1').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Surface totale hors baies 2').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Surface d\'échange').withNumberDefinition('m²', 2));

    for (let i = 1; i <= max_material; i++) {
        const nomenclature = new cn_nomenclature('Matériau ' + i);
        nomenclature.values = Array(wall_types.length).fill('');
        output.push(nomenclature);
    }

    const walls = building.storeys.filter(st => st.scene.walls.length).flatMap(st => st.scene.walls.map(wall => ({ wall, storey: st })));

    wall_types.forEach((wall_type, i) => {
        let nomenclature_entry_index = 0;
        output[nomenclature_entry_index].values[i] = wall_type.get_label();
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = wall_type.thickness;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = wall_type.get_U();
        nomenclature_entry_index++;

        const walls_of_type = get_walls_and_surface_by_type(walls, wall_type);
        output[nomenclature_entry_index].values[i] = walls_of_type.length;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = walls_of_type.reduce((agg, w) => agg + w.surface_hors_baie_1, 0);
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = walls_of_type.reduce((agg, w) => agg + w.surface_hors_baie_2, 0);
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = walls_of_type.reduce((agg, w) => agg + w.surface_echange, 0);
        nomenclature_entry_index++;

        wall_type.layers.forEach(layer => {
            output[nomenclature_entry_index].values[i] = layer.get_label();
            nomenclature_entry_index++;
        });
    });

    return output;
}

function get_walls_and_surface_by_type(walls, wall_type) {
    return walls.filter(w => w.wall.wall_type.ID === wall_type.ID).map(w => {
        const roof_volume = w.storey.build_roof_volume();
        const s0 = (w.wall.get_flow_direction()) ? 0 : 1;
        const s1 = 1 - s0;
        const polygons = [null, null];
        const lowest_slab_height = w.wall.get_lowest_slab_height();
        const highest_slab_height = w.wall.get_highest_slab_height();
        const normal = cn_clone(w.wall.bounds.normal);
        normal.push(0);

        for (let sside = 0; sside < 2; sside++) {
            const side = (sside == 0) ? s0 : s1;

            const p0 = cn_clone(w.wall.measure_points[side][0]);
            p0.push(lowest_slab_height);

            const p1 = cn_clone(w.wall.measure_points[side][1]);
            p1.push(lowest_slab_height);

            const dz = [0, 0, w.storey.get_max_height()];
            polygons[side] = new fh_polygon(p0, normal);
            polygons[side].add_contour([p0, p1, fh_add(p1, dz), fh_add(p0, dz)]);
            if (roof_volume) {
                const pg = roof_volume.plane_intersection(p0, normal);
                polygons[side].intersects(pg);
            }
        }
        const exchange_polygon = polygons[0].clone();
        exchange_polygon.intersects(polygons[1]);
        const wp0 = w.wall.vertex_position(0);
        wp0.push(highest_slab_height);
        const dx = cn_clone(w.wall.bounds.direction);
        dx.push(0);
        w.wall.openings.length && w.wall.openings.forEach((opening) => {
            if (opening.valid) {
                const p = fh_add(wp0, fh_mul(dx, opening.position));
                const pg0 = opening.opening_type.build_piercing_polygon(p, dx, normal, [0, 0, 1]);
                polygons[0].substracts(pg0);
                polygons[1].substracts(pg0);
                exchange_polygon.substracts(pg0);
            }
        });
        return { surface_hors_baie_1: polygons[s0].get_area(), surface_hors_baie_2: polygons[s1].get_area(), surface_echange: exchange_polygon.get_area() };
    });
}

//***********************************************************************************
//***********************************************************************************
/**
 * Buils slab nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_slabs(building) {
    const output = [];

    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Niveau'));
    output.push(new cn_nomenclature('Espace inf'));
    output.push(new cn_nomenclature('Espace sup'));
    output.push(new cn_nomenclature('Structure'));
    output.push(new cn_nomenclature('Isolation'));
    output.push(new cn_nomenclature('Largeur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Longueur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Surface').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('U').withNumberDefinition('W/m²/K', 3));

    building.storeys.length && building.storeys.forEach((storey, index) => {
        const storey_below = (index > 0) ? building.storeys[index - 1] : null;
        storey.slabs.length && storey.slabs.forEach((slab) => {

            const slab_type = slab.slab_type;
            let k = 0;

            //*** name */
            output[k].values.push(slab_type.get_label());
            k++

            //*** Type */
            output[k].values.push(slab_type.get_label());
            k++

            //*** storey */
            output[k].values.push(storey.storey_index);
            k++

            //*** Space 1 */
            output[k].values.push(get_slab_space_name(slab.spaces[0], storey_below));
            k++

            //*** Space 2 */
            output[k].values.push(get_slab_space_name(slab.spaces[1], storey));
            k++

            //*** Structure  */
            output[k].values.push(slab_type.get_structure_label());
            k++

            //*** Insulation  */
            output[k].values.push(slab_type.get_insulation_label());
            k++

            //*** slab geometry */
            const bb = slab.get_bounding_box();

            //*** Width  */
            output[k].values.push(bb.size[0]);
            k++

            //*** length  */
            output[k].values.push(bb.size[1]);
            k++

            //*** thickness  */
            output[k].values.push(slab_type.thickness);
            k++

            //*** full area  */
            const polygon = slab.build_polygon(0);
            output[k].values.push(polygon.get_area());
            k++

            //*** U  */
            output[k].values.push(slab_type.get_U());
            k++
        });
    });
    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 * Buils roof nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_roofs(building) {
    const output = [];

    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Niveau'));
    output.push(new cn_nomenclature('Structure'));
    output.push(new cn_nomenclature('Isolation'));
    output.push(new cn_nomenclature('Couverture'));
    output.push(new cn_nomenclature('Inclinaison').withNumberDefinition('°', 2));
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Surface').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('U').withNumberDefinition('W/m²/K', 3));

    // for (var ns in building.storeys) {
    building.storeys.length && building.storeys.forEach((storey) => {
        if (storey.roof) {
            const roof = storey.roof;

            roof.slabs.length && roof.slabs.forEach((slab) => {

                const slab_type = slab.slab_type;
                let k = 0;

                //*** name */
                output[k].values.push(slab_type.get_label());
                k++

                //*** Type */
                output[k].values.push(slab_type.get_label());
                k++

                //*** storey */
                output[k].values.push(storey.storey_index);
                k++

                //*** Structure  */
                output[k].values.push(slab_type.get_structure_label());
                k++

                //*** Insulation  */
                output[k].values.push(slab_type.get_insulation_label());
                k++

                //*** Upper facing  */
                output[k].values.push(slab_type.get_upper_facing_label());
                k++

                //*** Inclinaison  */
                output[k].values.push(Math.abs(slab.slope));
                k++

                //*** Orientation  */
                let or = cn_azimut(slab.slope_direction, storey.building.compass_orientation);
                output[k].values.push(or);
                k++

                //*** thickness  */
                output[k].values.push(slab_type.thickness);
                k++

                //*** full area  */
                const polygon = slab.build_3d_polygon(0, true);
                output[k].values.push(polygon.get_area());
                k++

                //*** name */
                output[k].values.push(slab_type.get_U());
                k++

            });
        }
    });
    return output;
}

/**
 *
 * @param {any[]} output
 * @param {boolean} onlyMainZoneToolVisible
 */
export function push_cn_nomenclatures_zones(output, onlyMainZoneToolVisible) {
    const default_zoning_type_tool = extension_instance.zone.get_default_zone_tool();
    extension_instance.zone.get_zones_tools().forEach(tool_zone => {
        const nomenclature_space = new cn_nomenclature(tool_zone.label_singular);
        if (onlyMainZoneToolVisible) {
            nomenclature_space.visible = tool_zone.property === default_zoning_type_tool.property;
        }
        output.push(nomenclature_space);
    })
}

//***********************************************************************************
//***********************************************************************************
/**
 * Builds beams nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_beams(building) {
    const output = [];
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Espace'));
    output.push(new cn_nomenclature('Matériau'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));
    output.push(new cn_nomenclature('Hauteur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Largeur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Longueur').withNumberDefinition('m', 2));

    building.storeys.length && building.storeys.forEach((storey) => {
        const zones = building.zones;
        const scene = storey.scene;
        /*** columns */
        scene.columns.length && scene.columns.forEach((column) => {
            populate_beam(output, zones, storey, column, 'Poteau');
        });
        /*** beams */
        scene.beams.length && scene.beams.forEach((beam) => {
            populate_beam(output, zones, storey, beam, 'Poutre');
        });
    });

    return output;
}

//***********************************************************************************
/**
 * Builds pipes nomenclatures for the building
 * @param {cn_building} building
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_pipes(building, is_exterior = false) {
    const output = [];
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Espace'));
    output.push(new cn_nomenclature('Matériau'));
    output.push(new cn_nomenclature('Fluide'));
    output.push(new cn_nomenclature('Diamètre').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));
    output.push(new cn_nomenclature('Hauteur').withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Longueur').withNumberDefinition('m', 2));

    const zones = building.zones;

    if (is_exterior) {
        /*** exterior */
        populate_nomenclature_pipes(building.exterior, zones, output);
    } else {
        building.storeys.length && building.storeys.forEach((storey) => {
            populate_nomenclature_pipes(storey, zones, output);
        });
    }
    return output;
}

//***********************************************************************************
/**
 * Builds objects nomenclatures for the building with objects params
 * @param {cn_building} building
 * @param {{name: string, codeBim: string}[]} parameters_definitions
 * @param {string[]} dimensions_labels
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_objects(building, parameters_definitions, dimensions_labels = ['Largeur', 'Profondeur', 'Hauteur'], is_exterior = false) {

    const params_valued_codes_bim = [];

    // Récupère les colonnes correspondants aux paramètres de type valorisés
    building.objects.length && building.objects.forEach(obj => {
        if (obj.source && obj.source.parameters) {
            for (const [param_code_bim, param_value] of Object.entries(obj.source.parameters)) {
                if (param_value != null && param_value != '') {
                    if (!params_valued_codes_bim.find(it => it === param_code_bim)) {
                        params_valued_codes_bim.push(param_code_bim);
                    }
                }
            }
        }
    });

    // Récupère les colonnes correspondants aux paramètres d'instances valorisés
    building.storeys.length && building.storeys.forEach(storey => {
        storey.scene.object_instances.length && storey.scene.object_instances.forEach(obj => {
            if (obj.parameters) {
                for (const [param_code_bim, param_value] of Object.entries(obj.parameters)) {
                    if (param_value != null && param_value != '') {
                        if (!params_valued_codes_bim.find(it => it === param_code_bim)) {
                            params_valued_codes_bim.push(param_code_bim);
                        }
                    }
                }
            }
        });
    });

    const output = [];
    output.push(new cn_nomenclature('Catégorie'));
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Espace'));
    output.push(new cn_nomenclature(dimensions_labels[0]).withNumberDefinition('m', 2));
    output.push(new cn_nomenclature(dimensions_labels[1]).withNumberDefinition('m', 2));
    output.push(new cn_nomenclature(dimensions_labels[2]).withNumberDefinition('m', 2));
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));

    if (params_valued_codes_bim.length > 0) {
        params_valued_codes_bim.forEach(code_bim => {
            const param_definition = parameters_definitions.find(t => t.codeBim === code_bim);
            if (param_definition) {
                output.push(new cn_nomenclature(param_definition.name));
            } else {
                console.error(`Aucune définition de paramètre trouvée pour le code bim ${code_bim}`);
                output.push(new cn_nomenclature(code_bim));
            }
        });
    }

    const zones = building.zones;
    if (is_exterior) {
        populate_nomenclature_objects(building.exterior, output, zones, params_valued_codes_bim);
    } else {
        building.storeys.length && building.storeys.forEach((storey) => {
            populate_nomenclature_objects(storey, output, zones, params_valued_codes_bim);
        });
    }

    return output;
}

//***********************************************************************************
/**
 *
 * @param {cn_storey} storey
 * @param {{[property: string]: cn_zone[]}}zones
 * @param {any[]} output
 * @param {boolean} is_exterior
 * @param {cn_building} building
 */
function populate_nomenclature_spaces(storey, zones, output, is_exterior, building) {
    storey.scene.storey = storey;
    storey.scene.update_deep();

    storey.roof_volume = storey.build_roof_volume(false);
    storey.scene.spaces.length && storey.scene.spaces.forEach(space => {
        if (!space.outside) {
            let k = 0;

            //*** Zones */
            extension_instance.zone.get_zones_tools().forEach(tool_zone => {
                const zone_to_display = find_zone(zones[tool_zone.property] || [], space.ID, storey.ID);
                output[k].values.push(zone_to_display);
                k++;
            });

            //*** name */
            output[k].values.push(space.get_name(storey));
            k++

            if (!is_exterior) {
                //*** Type */
                if (!space.has_roof) {
                    output[k].values.push('Terrasse');
                } else if (!space.indoor) {
                    output[k].values.push('Terrasse couverte');
                } else {
                    output[k].values.push('Intérieur');
                }
                k++
            }

            //*** Floor Facing */
            output[k].values.push((space.facings[0]) ? space.facings[0].name : 'Aucun');
            k++;

            //*** Ceiling Facing */
            if (!is_exterior) {
                output[k].values.push((space.facings[1]) ? space.facings[1].name : 'Aucun');
                k++;
            }

            //*** storey */
            let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
            output[k].values.push(storey_name);
            k++

            //*** Compute space volume */
            const space_volume = (!is_exterior && space.has_roof) ? space.build_solid(storey) : null;

            if (!is_exterior) {
                //*** ceiling heigth */
                const space_height = (space_volume) ? space_volume.get_bounding_box().size[2] : 0;
                output[k].values.push(space_height);
                k++
            }

            //*** full area  */
            let polygon = space.build_inner_polygon(0, false);
            output[k].values.push(polygon.get_area());
            k++

            if (!is_exterior) {
                //*** floor area  */
                polygon = space.build_inner_polygon(0, true);
                output[k].values.push(polygon.get_area());
                k++
            }

            //*** Surface déclarée */
            const declared_area = space.declared_area && space.declared_area !== -1 ? space.declared_area : null;
            output[k].values.push(declared_area);
            k++

            if (is_exterior) {
                //*** Neat area  */
                output[k].values.push(space.plain_area);
                k++

                //*** Perimeter */
                output[k].values.push(space.get_perimeter());
                k++
            }

            //*** Périmètre déclaré */
            const declared_perimeter = space.declared_perimeter && space.declared_perimeter !== -1 ? space.declared_perimeter : null;
            output[k].values.push(declared_perimeter);
            k++

            if (!is_exterior) {
                const volume = (space_volume) ? space_volume.get_volume() : 0;
                output[k].values.push(volume);
                k++

                output[k].values.push(space.is_heated());
                k++
            }

            //*** Usage */
            const space_usage = space.space_usage ? space.space_usage : '';
            output[k].values.push(space_usage);
            k++

            //*** Display areas according to regulation */
            building.area_contexts.forEach(area_context => {
                const space_area = area_context.get_space_area(storey, space);
                for (let nl = 0; nl === 0 || nl < area_context.sub_labels.length; nl++) {
                    const a = (space_area && nl < space_area.sub_areas.length) ? space_area.sub_areas[nl] : 0;
                    output[k].values.push(a);
                    k++
                }
            });
        }

    });
}

/**
 * Builds pipes nomenclatures for the given storey
 * @param {any[]} output
 * @param {{[property: string]: cn_zone[]}} zones
 * @param {cn_storey} storey
 */
function populate_nomenclature_pipes(storey, zones, output) {
    const scene = storey.scene;
    scene.pipes.length && scene.pipes.forEach((item) => {
        const element_type = item.element_type;
        let k = 0;
        //*** Type */
        output[k].values.push(element_type.get_label());
        k++

        //*** storey */
        let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
        output[k].values.push(storey_name);
        k++

        const space_one = storey.scene.find_space(item.vertices[0]);
        const spaces = new Set();
        spaces.add(space_one);
        const direction = cn_sub(item.vertices[1], item.vertices[0]);
        let max_distance = cn_normalize(direction);
        let next_wall = null;
        let start_vertex = item.vertices[0];
        do {
            const next_wall_projection = storey.scene.raytrace(start_vertex, direction, max_distance, null, next_wall);
            if (next_wall_projection) {
                max_distance -= next_wall_projection.distance;
                next_wall = next_wall_projection.wall;
                start_vertex = next_wall_projection.point;
                spaces.add(next_wall.spaces[0]);
                spaces.add(next_wall.spaces[1]);
            } else {
                break;
            }
        } while (next_wall !== null)


        //*** zone */
        extension_instance.zone.get_zones_tools().forEach(tool_zone => {
            const zones_to_display = new Set();
            spaces.forEach(space => {
                const zone = find_zone(zones[tool_zone.property], space.ID, storey.ID);
                if (zone) {
                    zones_to_display.add(zone);
                }
            })
            output[k].values.push(Array.from(zones_to_display).join(', '));
            k++;
        });

        //*** space */
        output[k].values.push(Array.from(spaces).map(space => space.get_name(storey)).join(', '));
        k++

        //*** material */
        output[k].values.push(code_to_label(element_type.material, PIPE_MATERIAL_LIST));
        k++

        //*** fluid */
        output[k].values.push(code_to_label(element_type.fluid, PIPE_FLUID_LIST));
        k++

        //*** diameter */
        output[k].values.push(element_type.diameter);
        k++

        let a = item.vertices[0]
        let b = item.vertices[1]

        //*** Orientation */
        let orientation = '';
        if (a[0] === b[0]) {
            orientation = '0';
        }
        output[k].values.push(orientation);
        k++

        //*** height */
        if (a[0] === b[0]) {
            // Vertical
            output[k].values.push(a[2] < b[2] ? b[2] : a[2]);
        } else {
            output[k].values.push(a[2] <= b[2] ? (a[2].toFixed(2) + ' / ' + b[2].toFixed(2)) : (b[2].toFixed(2) + ' / ' + a[2].toFixed(2)));
        }
        k++

        //*** length
        let distance;

        if (a[0] === b[0]) {
            // Vertical
            distance = Number(Math.abs(a[2] - b[2]).toFixed(2));
        } else {
            distance = Number((Math.sqrt(Math.pow(cn_dist(a, b), 2) + Math.pow(a[2] - b[2], 2))).toFixed(2));
        }
        output[k].values.push(distance);
        k++;

    });
}

/**
 * Builds beam or column nomenclatures for the given storey
 * @param {any[]} output
 * @param {{[property: string]: cn_zone[]}}zones
 * @param {cn_storey} storey
 * @param item (beam or column)
 * @param item_type
 */
function populate_beam(output, zones, storey, item, item_type) {
    const element_type = item.element_type;
    let k = 0;

    //*** Type */
    output[k].values.push(item_type + ' ' + element_type.get_label());
    k++

    //*** storey */
    output[k].values.push(storey.storey_index);
    k++

    const spaces = item.get_spaces(storey.scene);

    //*** zone */
    extension_instance.zone.get_zones_tools().forEach(tool_zone => {
        const zones_to_display = new Set();
        spaces.forEach(space => {
            const zone = find_zone(zones[tool_zone.property], space.ID, storey.ID);
            if (zone) {
                zones_to_display.add(zone);
            }
        })
        output[k].values.push(Array.from(zones_to_display).join(', '));
        k++;
    });

    //*** space */
    //*** space */
    output[k].values.push(Array.from(spaces).map(space => space.get_name(storey)).join(', '));
    k++

    //*** material */
    output[k].values.push(code_to_label(element_type.material, BC_MATERIAL_LIST));
    k++

    //*** shape */
    output[k].values.push(code_to_label(element_type.shape, BC_SHAPE_LIST));
    k++

    //*** orientation */
    output[k].values.push(item.orientation);
    k++

    //*** height */
    if (item_type === 'Poteau') {
        output[k].values.push(item.height ? item.height : storey.height);
    } else {
        let heightA = item.vertices[0][2] === 0 ? storey.height : item.vertices[0][2];
        let heightB = item.vertices[1][2] === 0 ? storey.height : item.vertices[1][2];
        output[k].values.push(heightA <= heightB ? (heightA.toFixed(2) + ' / ' + heightB.toFixed(2)) : (heightB.toFixed(2) + ' / ' + heightA.toFixed(2)));
    }
    k++

    //*** thickness */
    output[k].values.push(item.element_type.thickness);
    k++

    //*** width */
    output[k].values.push(item.element_type.width);
    k++

    //*** length */
    let distance;
    if (item_type === 'Poteau') {
        distance = item.height === false ? storey.height : item.height
    } else {
        let a = item.vertices[0]
        let b = item.vertices[1]
        distance = Number((Math.sqrt(Math.pow(cn_dist(a, b), 2) + Math.pow(a[2] - b[2], 2))));
    }
    output[k].values.push(distance);
    k++
}

/**
 * Builds beam or column nomenclatures for the given storey
 * @param {cn_storey} storey
 * @param {any[]} output
 * @param {{[property: string]: cn_zone[]}} zones
 * @param params_codes_bim
 */
function populate_nomenclature_objects(storey, output, zones, params_codes_bim) {
    const scene = storey.scene;
    scene.object_instances.length && scene.object_instances.forEach((object_instance) => {
        let k = 0;

        //*** Category */
        output[k].values.push(object_instance.object.get_product_category());
        k++

        //*** Name */
        output[k].values.push(object_instance.object.get_label());
        k++

        //*** Storey */
        let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
        output[k].values.push(storey_name);
        k++

        //*** Zone */
        extension_instance.zone.get_zones_tools().forEach(tool_zone => {
            let zone_to_display = '';
            if (object_instance.space) {
                zone_to_display = find_zone(zones[tool_zone.property], object_instance.space.ID, storey.ID);
            }
            output[k].values.push(zone_to_display);
            k++;
        });

        //*** Pièce */
        let space_name = '';
        if (object_instance.space) space_name = object_instance.space.get_name(storey);
        output[k].values.push(space_name);
        k++


        //*** Largeur */
        output[k].values.push(object_instance.object.size[1]);
        k++

        //*** Profondeur */
        output[k].values.push(object_instance.object.size[0]);
        k++

        //*** Hauteur */
        output[k].values.push(object_instance.object.size[2]);
        k++

        //*** Orientation */
        output[k].values.push(object_instance.orientation);
        k++

        //*** Parameters */
        const regex_date = '^(\\d{4})-(\\d\\d)-(\\d\\d)T(\\d\\d):(\\d\\d):(\\d\\d).(\\d\\d\\d)Z$';
        params_codes_bim.forEach(code_bim => {
            let value = object_instance.parameters[code_bim] ? object_instance.parameters[code_bim] :
                object_instance.object.source.parameters[code_bim] ? object_instance.object.source.parameters[code_bim] :
                    '';
            if (value && isNaN(value) && value.match(regex_date)) {
                value = new Date(value).toLocaleDateString('fr');
            }
            output[k].values.push(value)
            k++
        })

    });
}

/**
 * Find a zone associated to a space+storey
 * @param {cn_zone[]} zones
 * @param space_ID
 * @param storey_ID
 * @returns {string}
 */
export function find_zone(zones, space_ID, storey_ID) {
    let zone_to_display = '';
    zones && zones.length && zones.some((zone) => {
        const room = zone.rooms.length && zone.rooms.find((r) => {
            return r.space === space_ID && r.storey === storey_ID;
        });
        if (room) {
            return zone_to_display = zone.name;
        }
    });
    return zone_to_display;
}

function get_slab_space_name(space, storey) {
    if (space && !space.outside) return space.get_name(storey);
    return SPACE_EXTERIOR_LABEL;
}

function prepare_area_contexts(building, output) {
    building.area_contexts.forEach(area_context => {
        area_context.update_deep();
        if (area_context.sub_labels.length === 0) {
            output.push(new cn_nomenclature(area_context.label).withNumberDefinition('m²', 2));
        } else {
            area_context.sub_labels.forEach(lbl => output.push(new cn_nomenclature(lbl).withNumberDefinition('m²', 2)));
        }
    });
}
