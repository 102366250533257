<h1 mat-dialog-title>Avertissement</h1>

<div mat-dialog-content>
  <ng-container>Il y a des modifications non enregistrées. Souhaitez-vous quitter sans enregistrer ?</ng-container>
</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="quitWithSaving()">
      <mat-icon>save</mat-icon>
      <span>Enregistrer puis quitter</span>
  </button>

  <button mat-button color="warn" (click)="quitWithoutSaving()">
    <lib-icon theme="mdi" iconName="content-save-off"></lib-icon>
    <span>Quitter sans enregistrer</span>
  </button>

  <button mat-button (click)="cancel()">
    <mat-icon>close</mat-icon>
    <span>Ne pas quitter</span>
  </button>
</div>
