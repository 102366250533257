'use strict';

import { cn_storey } from '../model/cn_storey';
import { CN_MIDDLE } from '../model/cn_wall';
import { extension_instance } from './cn_extension';
import { cn_extension_feature } from './cn_extension_feature';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** config extension
//***********************************************************************************
//***********************************************************************************

export class cn_config_extension extends cn_extension_feature {

    constructor() {
        super(false)
        this.default_axis_wall = CN_MIDDLE;
    }


}


