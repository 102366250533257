'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_space  : Manipulation of space
//***********************************************************************************
//***********************************************************************************

import { cn_camera } from './cn_camera';
import { cn_svg_map } from './cn_svg_map';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';

export class cn_svg_tool_space extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} svg_map
     */
    constructor(svg_map) {
        super(svg_map);
        this.selected_space = null;
        this._mouseover_space = null;
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
        super.open_tool();
    }

    //***********************************************************************************
    /**
     * SVG rendering
     * @param {cn_camera} camera
     * @returns {string} svg rendered
     */
    draw(camera) {
        var obj = this;
        var html = '';
        if (this._mouseover_space) {
            html += this._mouseover_space._draw_path(camera, 'space_label_highlight');
        }

        if (this.selected_space) {
            html += this.selected_space.draw(camera, ['selected']);
        }
        return html;
    }

    //***********************************************************************************
    /**
     * click management
     * @param {object} ev
     * @returns {boolean} true if click was used
     */
    click(ev) {
        var obj = this;

        this._find_mouseover(ev);

        this.selected_space = this._mouseover_space;
        if (this._mouseover_space) {
            if (!this.selected_space.outside)
                this.call('space_selected', this.selected_space);
            return true;
        }
        return false;
    }

    grab(ev) {
        return this._find_mouseover(ev);
    }

    //***********************************************************************************
    /**
     *
     * @param space
     */
    set_selected_space(space) {
        this.selected_space = space;
        this.call('space_selected', this.selected_space);
    }

    //***********************************************************************************
    /**
     * Set space name
     * @param {string} txt
     */
    set_space_name(txt) {
        if (this.selected_space && this.selected_space.name != txt) {
            this.push_transaction('Renommage de pièce');
            this.push_item_set(this.selected_space, 'name');
            this.selected_space.name = txt;
        }
    }

    //***********************************************************************************
    /**
     * Sets ceiling height
     * @param {number} value
     */
    set_space_ceiling_height(value) {
        if (this.selected_space && this.selected_space.ceiling_height != value) {
            this.push_transaction('Faux plafond');
            this.push_item_set(this.selected_space, 'ceiling_height');
            this.selected_space.ceiling_height = value;
        }
    }

    //***********************************************************************************
    /**
     * Sets floor offset
     * @param {number} value
     */
    set_space_slab_offset(value) {
        if (this.selected_space && this.selected_space.slab_offset != value) {
            this.push_transaction('Décalage de dalle');
            this.push_item_set(this.selected_space, 'slab_offset');
            this.selected_space.slab_offset = value;
        }
    }

    /**
     * Sets declared area space
     * @param {number} value
     */
    set_space_declared_area(value) {
        if (this.selected_space && this.selected_space.declared_area != value) {
            this.push_transaction('Déclaration de superficie');
            this.push_item_set(this.selected_space, 'declared_area');
            this.selected_space.declared_area = value;
        }
    }

    /**
     * Sets declared perimeter space
     * @param {number} value
     */
    set_space_declared_perimeter(value) {
        if (this.selected_space && this.selected_space.declared_perimeter != value) {
            this.push_transaction('Déclaration de périmètre');
            this.push_item_set(this.selected_space, 'declared_perimeter');
            this.selected_space.declared_perimeter = value;
        }
    }

    /**
     * Sets space usage
     * @param {string} value
     */
    set_space_usage(value, default_name = null) {
        if (this.selected_space && this.selected_space.space_usage != value) {
            this.push_transaction('Usage de l\'espace');
            this.push_item_set(this.selected_space, 'space_usage');
            if (default_name) {
                this.push_item_set(this.selected_space, 'name');
                this.selected_space.name = default_name;
            }
            this.selected_space.space_usage = value;
        }
    }

    //***********************************************************************************
    /**
     * passive move management
     * @param {object} ev
     * @returns {boolean} true if passive move was used
     */
    move(ev) {
        this._find_mouseover(ev);
        return true;
    }

    //***********************************************************************************
    _find_mouseover(ev) {
        this._mouseover_space = this._scene.find_space(ev.mouse_world, false);
        return this._mouseover_space != null;
    }
}

