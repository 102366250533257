import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationStore } from '../../core/auth/authentication.store';
import { User } from '../model/user.model';
import { UserUtils } from '../utils/user.utils';

/**
 * Traduction des énumérations
 */
@Pipe({ name: 'displayUser' })
export class DisplayUserPipe implements PipeTransform {
  constructor(private authenticationStore: AuthenticationStore) {}

  /**
   * Affiche le nom de l'utilisateur (nom, prénom, ou email à défaut de nom/prénom).
   *
   * @param user Utilisateur
   * @param customLabelForCurrentUser Si true, suffixe le nom de l'utilisateur par " (vous)", ou indique seulement "vous" si shortIfCurrentUser vaut true
   * @param shortIfCurrentUser Si true, affiche "vous" au lieu du nom s'il s'agit de l'utilisateur
   */
  transform(user: User, customLabelForCurrentUser = true, shortIfCurrentUser = false): string {
    if (!user) {
      return '';
    }
    if (customLabelForCurrentUser) {
      const currentUser = this.authenticationStore.getCurrentUserSnapshot();
      if (currentUser && user.email === currentUser.email) {
        if (shortIfCurrentUser) {
          return 'vous';
        } else {
          return UserUtils.getUserDisplayName(user) + ' (vous)';
        }
      }
    }
    return UserUtils.getUserDisplayName(user);
  }
}
