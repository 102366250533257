import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { ROLE_ADMIN } from '../../model/user.model';
import { AuthorizationService } from '../../services/authorization.service';
import { CompanyRights } from '../../model/company.model';

@Directive({
  selector: '[appIsAuthByRight]',
})
export class IsAuthByRightDirective implements OnChanges {
  @Input() appIsAuthByRight: CompanyRights | typeof ROLE_ADMIN;
  @Input() appIsAuthByRightCompanyId?: string;
  @Input() appIsAuthByRightElse?: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnChanges(): void {
    this.viewContainer.clear();
    if (this.authorizationService.isUserHasRight(this.appIsAuthByRight, this.appIsAuthByRightCompanyId)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.appIsAuthByRightElse) {
      this.viewContainer.createEmbeddedView(this.appIsAuthByRightElse);
    }
  }
}
