import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BuildingStore, IconData, IEditorHandler } from '@enerbim/cnmap-angular-editor-lib';
import { Backup } from '@enerbim/cnmap-angular-editor-lib/lib/model/backup.model';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationStore } from 'src/app/core/auth/authentication.store';
import { WorkflowService } from 'src/app/shared/services/workflow.service';
import { ConfirmationService } from 'src/app/shared/services/confirmation.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { DrawingStore } from '../../../shared/store/drawing.store';
import { SESSION_STORAGE_KEY_LAST_BUILDING_BACKUPS } from '../../../shared/constants/session-storage-key-constantes';
import { Perimeter } from 'src/app/shared/model/user.model';
import { EMITTER_CODE_BIM } from 'src/app/shared/constants/emitter-constantes';
import { cn_zone, zone_colors } from '@enerbim/cnmap-editor';
import { ZONE_TYPE_HEATER_DISTRIBUTION } from 'src/app/shared/utils/constants';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { CompanyRights } from 'src/app/shared/model/company.model';

@Injectable()
export class EditorHandler implements IEditorHandler {
  constructor(
    private projectService: ProjectService,
    private workflowService: WorkflowService,
    private buildingStore: BuildingStore,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private authenticationStore: AuthenticationStore,
    private authorizationService: AuthorizationService,
    private drawingStore: DrawingStore,
    private router: Router,
  ) {}

  getDrawingId(): string {
    return this.drawingStore.currentDrawingSnapshot?.id || '';
  }

  autoSave(backups: Backup[]): Observable<void> {
    sessionStorage.setItem(SESSION_STORAGE_KEY_LAST_BUILDING_BACKUPS, JSON.stringify(backups));
    return of(null);
  }

  getCurrentUserName(): Observable<string> {
    return this.authenticationStore.getCurrentUser().pipe(map(user => user.lastName + ' ' + user.firstName));
  }

  getBuildingName(): Observable<string> {
    return this.projectService.getCurrentProject().pipe(map(project => project.name));
  }

  getName(): Observable<string> {
    return this.drawingStore.currentDrawing$.pipe(map(drawing => drawing.name));
  }

  getLocalization(): Observable<{ address: string; postCode: string; city: string }> {
    return this.projectService.getCurrentProject().pipe(
      map(project => ({
        address: project.address.address ? project.address.address : '',
        postCode: project.address.postcode ? project.address.postcode : '',
        city: project.address.city ? project.address.city : '',
      })),
    );
  }

  getNotificationIcon(): Observable<IconData> {
    return of(null);
  }

  isReadOnly(): Observable<boolean> {
    return this.projectService
      .getCurrentProject()
      .pipe(
        map(
          project =>
            !!project &&
            project.projectStatus === 'VALID' &&
            !this.authorizationService.isUserHasRight(CompanyRights.TECHNICIAN_EXPERIENCED, project.companyId),
        ),
      );
  }

  back() {
    let config = this.workflowService.getCurrentConfig();
    if (config) {
      const planStage = config.stages.find(stage => stage.name === 'Plan du logement');
      if (planStage) {
        const stepNumber = planStage.steps[0].num - 1;
        const project = Object.assign(this.projectService.getCurrentProjectSnapshot(), { lastFilledStep: stepNumber });
        this.projectService.setCurrentProject(project);
      }
    }
    this.router.navigate(['/projects/project']);
  }

  save(forceCompletion = false): Observable<any /*should be void, but it forces to map to a void value*/> {
    const project = this.projectService.getCurrentProjectSnapshot();
    const drawing = this.drawingStore.currentDrawingSnapshot;
    const building = this.buildingStore.getCurrentBuildingSnapshot();
    this.completeHeatedFloorPacProject(project, building);
    return this.drawingStore.updateContent$(project, drawing, building).pipe(
      switchMap(() => this.projectService.findOne(project.id)),
      switchMap(updatedProject => {
        if (forceCompletion && updatedProject.pacSizing) {
          updatedProject.pacSizing.studyCompleteValidatedAt = new Date().toISOString();
          updatedProject.pacSizing.configurationValidatedAt = new Date().toISOString();
          updatedProject.pacSizing.calculationValidatedAt = new Date().toISOString();
        }
        return this.projectService.updateProjectFromBuilding$(
          updatedProject,
          this.buildingStore.getCurrentBuildingSnapshot(),
        );
      }),
      tap(() => {
        this.notificationService.success('Maquette enregistrée');
        this.buildingStore.setCurrentBuildingSaved();
      }),
      catchError(err => {
        if (err.status == 409) {
          this.notificationService.warn('Version obsolète');
          this.confirmationService.confirm(
            'La maquette a été modifiée par un autre utilisateur entre temps. Voulez-vous enregistrer sous ?',
            () => this.saveAs(),
          );
        }
        throw err;
      }),
    );
  }

  private completeHeatedFloorPacProject(project, building): void {
    if (project.type === Perimeter.PAC) {
      const feederTanks = building.storeys.flatMap(storey =>
        storey.scene.object_instances
          .filter(obj => !!obj.space && obj.object.source.product_type === EMITTER_CODE_BIM.NOURRICE_PLANCHER_CHAUFFANT)
          .map(obj => ({ obj, storey })),
      );
      if (feederTanks.length) {
        let defaultZone = null;
        if (building.zones[ZONE_TYPE_HEATER_DISTRIBUTION]?.length) {
          defaultZone = building.zones[ZONE_TYPE_HEATER_DISTRIBUTION][0];
        } else {
          defaultZone = new cn_zone('1', building.storeys[building.storey_0_index].ID);
          defaultZone.color = zone_colors[0];
          building.zones[ZONE_TYPE_HEATER_DISTRIBUTION] = [defaultZone];
        }
        feederTanks.forEach(feederTank => {
          if (
            !building.zones[ZONE_TYPE_HEATER_DISTRIBUTION].some(zone =>
              zone.rooms.find(room => room.storey === feederTank.storey.ID && room.space === feederTank.obj.space.ID),
            )
          ) {
            defaultZone.rooms.push({ storey: feederTank.storey.ID, space: feederTank.obj.space.ID });
          }
        });
      }
    }
  }

  saveAs() {
    // todo ASI: manage the concurrency errors
    return of(null);
  }

  getObjectsParametersHidden(): Observable<string[]> {
    return of([]);
  }

  setObjectsParameterHidden(codeBim: string, hidden: boolean): Observable<string[]> {
    return of([]);
  }

  displayWikipimCatalog(): boolean {
    return false;
  }

  canExportBbp(): Observable<boolean> {
    return of(false);
  }
}
