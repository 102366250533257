//***********************************************************************************
//***********************************************************************************
//**** fh_view_ortho : 2D view
//***********************************************************************************
//***********************************************************************************

import {Mesh, Scene, MeshBasicMaterial, ImageUtils, Color, Vector3, Geometry, Face3, Vector2, DoubleSide} from 'three';
import {dummy_target} from "./fh_view";
import {fh_view_ortho} from "./fh_view_ortho";
// @ts-ignore
import camera_frustrum from '../images/camera_frustrum.png';
//***********************************************************************************
//**** Class view
//***********************************************************************************

export class fh_view_map extends fh_view_ortho {
	//*****************************************************
	//*** Constructor
	constructor(div_id, scene, view_3d) {
		super(div_id, scene);

		this._current_space = null;
		this._view_3d = view_3d;

		this._camera_widget = new Scene();

		/*var geometry = new Geometry();
		geometry.vertices.push( new Vector3( 0, 0, 0 ) );
		geometry.vertices.push( new Vector3(  1, 0, 0 ) );
		geometry.vertices.push( new Vector3(  0,  1, 0 ) );
		this._camera_widget_geometry = geometry;

		geometry.faces.push( new Face3(0,1,2));
		var uv0 = new Vector2(0,0);
		var uv1 = new Vector2(1,0);
		geometry.faceVertexUvs[0].push( [uv0,uDoubleSidev1,uv1] );*/
		this._camera_widget_material = new MeshBasicMaterial();
		this._camera_widget_material.map = new ImageUtils.loadTexture(camera_frustrum);
		this._camera_widget_material.side = DoubleSide;
		this._camera_widget_material.transparent = true;
		this._camera_widget_material.depthWrite = false;
		this._camera_widget_material.depthTest = false;
		this._camera_widget_material.color = new Color(0xff0000);

		this._current_space_material = new MeshBasicMaterial();
		this._current_space_material.side = DoubleSide;
		this._current_space_material.transparent = true;
		this._current_space_material.depthWrite = false;
		this._current_space_material.depthTest = false;
		this._current_space_material.color = new Color(0,0,1);
		this._current_space_material.opacity=0.3;

		this._mouseover_space_material = new MeshBasicMaterial();
		this._mouseover_space_material.side = DoubleSide;
		this._mouseover_space_material.transparent = true;
		this._mouseover_space_material.depthWrite = false;
		this._mouseover_space_material.depthTest = false;
		this._mouseover_space_material.color = new Color(1,1,0);
		this._mouseover_space_material.opacity=0.3;

		this._mouseover_space = null;

		
		this._show_2d_scene = false;
		this._allow_selection = false;

		//the face normals and vertex normals can be calculated automatically if not supplied above
		/*geometry.computeFaceNormals();
		geometry.computeVertexNormals();

		this._camera_widget.add( new Mesh( geometry, mat ) );*/
	}

	//********************************************************
	//*** Update perspective camera
	update_camera()	{
		this._camera.updateProjectionMatrix();
	}

	//*****************************************************
	//**** Callbacks for navigation
	//*****************************************************

	//********************************************************
	//*** Mouse wheel management
	manage_mouse_wheel(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		var forward = (ev.deltaY > 0.2);

		if (forward)
			this._camera.scene_scale *= 1.1 ;
		else
			this._camera.scene_scale /= 1.1 ;
		this._camera.zoom = (0.5 * this._render_height + 0.5 * this._render_width) / this._camera.scene_scale;

		this.update_camera();

		this.refresh_rendering();

		this._view_3d.call("view_change");
	}

	//*****************************************************
	//*** Mouse operations
	update_mouseover_space(ev) {
		this._mouse_position = this.read_mouse_position(ev.clientX,ev.clientY);
		this.set_raycaster(this._mouse_position);
		this.update_visibility_list();
		var intersects = this._raycaster.intersectObjects(this._scene._spaces, true);
		var space = null;
		this._mouseover_hit = null;
		if (intersects.length>0)
		{
			space = intersects[0].object.parent;
			if (space.storey != this._current_storey)
				space = null;
			else
				this._mouseover_hit = intersects[0].point;
		}
		if (space == this._mouseover_space) return false;
		this._mouseover_space = space;
		return true;
	}
	//*****************************************************
	//*** Mouse operations
	manage_mouse_move(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		if (this.update_mouseover_space(ev))
			this.refresh_rendering();
	}

	//*****************************************************
	//*** Mouse leave
	manage_mouse_leave(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		this._mouseover_space = null;
		this.refresh_rendering();
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_down(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		this.update_mouseover_space(ev);
		if (this._mouseover_hit)
		{
			this._view_3d.go_to_position(this._mouseover_hit);
			this._view_3d.call("view_change");
		}
	}

	//********************************************************
	//*** Move camera
	move_camera(position) {
		var current_storey = 0;
		for (var i = 0; i < this._scene._storey_heights.length;i++)
		{
			current_storey = i;
			if (this._scene._storey_heights[i] > position.z) break;
		}
		this.set_current_storey(current_storey);

		this.update_visibility_list();
		var origin = position.clone();
		origin.z += 1;
		this._raycaster.set(origin,new Vector3(0,0,-1));
		var intersects = this._raycaster.intersectObjects(this._scene._spaces, true);
		this._current_space = null;
		if (intersects.length>0) this._current_space = intersects[0].object.parent;

		this._camera.position.x=position.x;
		this._camera.position.y=position.y;
		this.update_camera();
	}

	//***********************************************************************************
	//**** Geometry rendering
	render_geometry() {

		super.render_geometry();

		var position = this._view_3d._camera.position.clone();
		var direction = this._view_3d._camera.getWorldDirection(dummy_target).clone();
		direction.z = 0;
		direction.normalize();
		var vectorx = new Vector3();
		position.z = this._camera.position.z-0.2;
		//position.z = 0;
		vectorx.crossVectors(direction,new Vector3(0,0,1));
		vectorx.normalize();
		vectorx.multiplyScalar(0.5 * this._camera.scene_scale*0.2);
		direction.multiplyScalar(this._camera.scene_scale*0.2);


		var camera_widget = new Scene();

		var geometry = new Geometry();
		geometry.vertices.push(position.clone());
		position.add(direction);
		position.sub(vectorx);
		geometry.vertices.push(position.clone());
		position.add(vectorx);
		position.add(vectorx);
		geometry.vertices.push(position.clone());
		geometry.faces.push( new Face3(0,1,2));

		var uv0 = new Vector2(0,0);
		var uv1 = new Vector2(1,0);
		geometry.faceVertexUvs[0].push( [uv0,uv1,uv1] );

		//the face normals and vertex normals can be calculated automatically if not supplied above
		geometry.computeFaceNormals();
		geometry.computeVertexNormals();

		if (this._current_space)
		{
			for (var i in this._current_space._meshes)
			{
				var mesh = this._current_space._meshes[i];
				if (typeof(mesh.views) == 'undefined') continue;
				if (mesh.views.indexOf(this._view)<0) continue;

				camera_widget.add(new Mesh( mesh.geometry, this._current_space_material ) );
			}
		}

		if (this._mouseover_space && this._mouseover_space != this._current_space)
		{
			for (var i in this._mouseover_space._meshes)
			{
				var mesh = this._mouseover_space._meshes[i];
				if (typeof(mesh.views) == 'undefined') continue;
				if (mesh.views.indexOf(this._view)<0) continue;

				camera_widget.add(new Mesh( mesh.geometry, this._mouseover_space_material ) );
			}
		}

		camera_widget.add( new Mesh( geometry, this._camera_widget_material ) );

		this._renderer.render(camera_widget, this._camera);
	}
}
