(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@enerbim/fh-3d-viewer"), require("three"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define(["@enerbim/fh-3d-viewer", "three", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["CNMAP"] = factory(require("@enerbim/fh-3d-viewer"), require("three"), require("jquery"));
	else
		root["CNMAP"] = factory(root["FH3D"], root["THREE"], root["jQuery"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__764__, __WEBPACK_EXTERNAL_MODULE__381__, __WEBPACK_EXTERNAL_MODULE__47__) => {
return 