import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'civility',
})
export class CivilityPipe implements PipeTransform {
  readonly ref = new Map([
    ['M', 'Monsieur'],
    ['Mme', 'Madame'],
  ]);

  transform(value: string): string {
    return this.ref.get(value) || '';
  }
}
