import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BuildingGuard } from '../shared/guards/building.guard';
import { PacReferentialStore } from '../shared/store/pac-referential-store.service';
import { AuthenticationGuard } from './auth/authentication.guard';
import { LoggedLayoutComponent } from './logged-layout/logged-layout.component';

export const DEFAULT_LANDING_PAGE = '/projects';

const routes: Routes = [
  {
    path: '',
    redirectTo: DEFAULT_LANDING_PAGE,
    pathMatch: 'full',
  },
  {
    path: 'access-denied',
    loadChildren: () => import('../features/access-denied/access-denied.module').then(m => m.AccessDeniedPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('../features/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('../features/logout/logout.module').then(m => m.LogoutPageModule),
  },
  {
    path: 'password-reset/init',
    loadChildren: () =>
      import('../features/password-reset/init/password-reset-init.module').then(m => m.PasswordResetInitPageModule),
  },
  {
    path: 'password-reset/finish',
    loadChildren: () =>
      import('../features/password-reset/finish/password-reset-finish.module').then(m => m.FinishPageModule),
  },
  {
    path: '',
    component: LoggedLayoutComponent,
    canActivate: [AuthenticationGuard],
    resolve: { referential: PacReferentialStore }, // todo move it to its corresponding module
    children: [
      {
        path: 'admin',
        loadChildren: () => import('../features/admin/admin.module').then(m => m.AdminModule),
        data: {
          pageTitle: 'Administration',
          backLink: '/projects',
        },
      },
      {
        path: 'pac-catalogue',
        loadChildren: () => import('../features/pac-catalogue/pac-catalogue.module').then(m => m.PacCatalogueModule),
        data: {
          backLink: '/projects',
        },
      },
      {
        path: 'monitoring',
        loadChildren: () => import('../features/monitoring/monitoring.module').then(m => m.MonitoringModule),
        data: {
          pageTitle: 'Supervision',
          backLink: '/projects',
        },
      },
      {
        path: 'companies',
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
          import('../features/company/company-routing-hub.module').then(m => m.CompanyRoutingHubModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('../features/profile/profile.module').then(m => m.ProfilePageModule),
      },
      {
        path: 'projects',
        loadChildren: () => import('../features/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          breadcrumb: 'Projets',
        },
      },
      {
        path: 'changelog',
        loadChildren: () => import('../features/changelog/changelog.module').then(m => m.ChangelogModule),
      },
    ],
  },
  {
    path: 'drawings/:drawingId',
    canActivate: [AuthenticationGuard, BuildingGuard],
    resolve: { loadDrawing: BuildingGuard },
    data: {
      buildingRequired: true,
    },
    loadChildren: () => import('../features/editor/editor.module').then(m => m.EditorModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
