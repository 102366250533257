import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStep } from '../model/project-stepper/project-step';
import { ProjectStepperConfig } from '../model/project-stepper/project-stepper-config';

@Pipe({
  name: 'configStepSpec',
})
export class ConfigStepSpecPipe implements PipeTransform {
  transform(value: number, config: ProjectStepperConfig): ProjectStep {
    return config.stages.flatMap(stage => stage.steps).find(step => step.num === value);
  }
}
