import { Project } from '../project.model';

export enum StepStatus {
  LOCKED = 'locked',
  OPEN = 'open',
  COMPLETE = 'complete',
  ERRORED = 'errored',
}

export class ProjectStep {
  constructor(
    public num: number,
    public name: string,
    public alertClose = false,
    public hasActions = false,
    public hideMenu = false,
    public getCompletionStatus: (project: Project) => StepStatus = () => StepStatus.OPEN,
    public needStrictValidation = false, // if the current step does not have a completionStatus COMPLETE, the user cannot validate the page
  ) {}
}
