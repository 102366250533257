'use strict';
import { cn_wall } from '../model/cn_wall';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_svg_map } from './cn_svg_map';
import { cn_type_pastille } from './cn_type_pastille';
import { cn_edit_box } from './cn_edit_box';
import { cn_pastille } from './cn_pastille';
import { cn_wall_type } from '../model/cn_wall_type';
import { cn_balcony_type } from '../model/cn_balcony_type';
import { cn_fence_type } from '../model/cn_fence_type';

export class cn_wall_handler extends cn_edition_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_wall>} walls
     * @param {cn_svg_map} map
     * @param {boolean} creation
     */
    constructor(walls, map, creation = false) {
        super(walls, map);

        this._map = map;
        this._scene = this._map._scene;
        this._transaction_manager = this._map._building.transaction_manager;
        const obj = this;

        //*** pipe
        this.walls = walls;

        //*** Edit box for mass selection */
        const edit_box = new cn_edit_box(this, walls, this._readOnly);
        this._handlers.push(edit_box);

        if (!this._readOnly) {
            //*** axis pastille */
            const axis_pastille = new cn_pastille([0, 0], 'switch_wall_axis.png');
            edit_box.add_pastille(axis_pastille);
            axis_pastille.svg_class = 'pastille_background white';
            axis_pastille.title = 'Modifier l\'axe des murs';
            axis_pastille.clicked = () => {
                obj.set_wall_axis((1 + obj.walls[0].axis) % 3);
            }
        }

        let coherent = true;
        let wall_type_constructor = null;
        walls.forEach(w => {
            if (wall_type_constructor == null)
                wall_type_constructor = w.wall_type.constructor;
            else if (wall_type_constructor != w.wall_type.constructor)
                coherent = false;
        });

        if (coherent) {
            if (!this._readOnly) {
                //*** Type pastille */
                const transaction_name = (wall_type_constructor == cn_wall_type) ? 'Type de mur' : (wall_type_constructor == cn_balcony_type) ? 'Type de balcon' : 'Type de clôture';
                const type_pastille = new cn_type_pastille(walls, 'wall_type', () => {
                    if (wall_type_constructor == cn_wall_type)
                        return map._building.get_wall_types();
                    else if (wall_type_constructor == cn_balcony_type)
                        return map._building.get_balcony_types();
                    else if (wall_type_constructor == cn_fence_type)
                        return map._building.get_fence_types();
                }, transaction_name, map);
                type_pastille.title = 'Modifier le type de mur';
                edit_box.add_pastille(type_pastille);

                edit_box.add_lock_pastille(this._transaction_manager);
            }

            edit_box.add_select_siblings_pastille('wall_type');
            edit_box.add_information_pastille('wall_type');
        }
    }

    //*** Set wall axis for selection
    set_wall_axis(axis) {
        const scene = this._scene;
        this._transaction_manager.push_transaction('Modification de l\'axe des murs', '', () => {
            scene.full_update();
        });

        this.walls.forEach(w => {
            this._transaction_manager.push_item_set(w, 'axis');
            w.axis = axis;
        });
        this._scene.update();
        this._scene.update_deep();
    }
}

