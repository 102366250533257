import { cn_building, cn_element, cn_slab, cn_space, cn_storey, cn_wall } from '@enerbim/cnmap-editor';
import { HousingType, Opening, Project, Room, Slab, Storey, Wall } from '../model/project.model';
import { allInteriorSpaces, allOuterOpenings, allThermicSlabs, allThermicWalls, uniqueId } from './cn-building.utils';
import { merge } from './collections.utils';

function matchUids<T extends { uid: string }>(a: T, b: T) {
  return a.uid === b.uid;
}

function defaultLimitConditionFor(projet: Project, slab: cn_slab, storey: cn_storey, storeyIndex: number): string {
  const isGroundFloor = projet.generalInformation.floorSituation === 'first';
  const isLastFloor = projet.generalInformation.floorSituation === 'last';
  const isMaison = projet.generalInformation.housingType === HousingType.MAISON;
  if (storey && storeyIndex === 0) {
    if (isGroundFloor || isMaison) {
      return 'FULL_GROUND';
    }
  } else if (!storey) {
    if (isMaison || isLastFloor) {
      return 'EXTERIEUR';
    }
  } else if (isMaison) {
    return 'EXTERIEUR';
  }
  return 'HEATED_LOCAL';
}

export function updateProjectFromBuilding(building: cn_building, projet: Project) {
  const roomsFromPlan = allInteriorSpaces(building).map(
    ([room, storey]) =>
      ({
        id: room.ID,
        uid: uniqueId(storey, room),
        name: room.name || room.get_name(storey),
        area: room.area,
        storey: storey.storey_index,
        heatingTransmitters: [projet.generalInformation.heatingTransmitter],
      } as Room),
  );

  const slabsFromPlan = allThermicSlabs(building).map(
    ([slab, storey, index]) =>
      ({
        id: slab.ID,
        uid: slab.ID,
        area: slab.area,
        storey: index,
        overlook: defaultLimitConditionFor(projet, slab, storey, index),
      } as Slab),
  );

  const wallsFromPlan = allThermicWalls(building).map(
    ([wall, storey]) =>
      ({
        id: wall.ID,
        uid: uniqueId(storey, wall),
        storey: storey.storey_index,
        overlook: 'EXTERIEUR',
        roomIds: wall.spaces.map(space => space.ID),
      } as Wall),
  );

  const openingsFromPlan = allOuterOpenings(building).map(
    ([opening, etage, wall]) =>
      ({
        id: opening.ID,
        uid: uniqueId(etage, opening),
        storey: etage.storey_index,
        wallId: wall.ID,
        roomIds: wall.spaces.map(space => space.ID),
      } as Opening),
  );

  const storeysFromPlan = building.storeys.map(
    (etage, index) =>
      ({
        id: etage.ID,
        uid: etage.ID,
        index: etage.storey_index,
        area: etage.scene.spaces
          .filter(space => space.indoor)
          .map(space => space.area)
          .reduce((a, b) => a + b, 0),
        name: etage.short_name,
        wallsIds: etage.scene.walls.map(wall => wall.ID),
        openingsIds: etage.scene.walls.map(wall => wall.openings.map(opening => opening.ID)).flat(),
      } as Storey),
  );

  projet.rooms = merge(roomsFromPlan, projet.rooms, matchUids, (roomFromPlan, roomToUpdate) => {
    roomToUpdate.name = roomFromPlan.name;
    roomToUpdate.area = roomFromPlan.area;
    return roomToUpdate;
  });
  projet.slabs = merge(slabsFromPlan, projet.slabs, matchUids, (slabPlan, slabProjet) => {
    slabProjet.area = slabPlan.area;
    return slabProjet;
  });
  projet.walls = merge(wallsFromPlan, projet.walls, matchUids, (wallFromPlan, wallToUpdate) => {
    wallToUpdate.area = wallFromPlan.area;
    wallToUpdate.roomIds = wallFromPlan.roomIds;
    return wallToUpdate;
  });
  projet.openings = merge(openingsFromPlan, projet.openings, matchUids, (openingFromPlan, openingToUpdate) => {
    openingToUpdate.wallId = openingFromPlan.wallId;
    openingToUpdate.roomIds = openingFromPlan.roomIds;
    return openingToUpdate;
  });
  projet.storeys = storeysFromPlan;
}

export function byUid<T extends { uid: string }, CN extends cn_element>(element: CN, storey: cn_storey) {
  const uid = uniqueId(storey, element);
  return (item: T) => item.uid === uid;
}

export function replaceOrAppend<T extends { uid: string }>(items: T[], item: T): T[] {
  const existingIndex = items.findIndex(existingItem => existingItem.uid === item.uid);
  if (existingIndex > -1) {
    items.splice(existingIndex, 1, item);
    return items;
  }
  return items;
}

export function canSelectForLimitConditions(project: Project) {
  return (element: cn_element, storey: cn_storey) => {
    const uid = uniqueId(storey, element);
    return (
      project.walls
        .concat(project.openings)
        .map(o => o.uid)
        .includes(uid) || project.slabs.map(s => s.uid).includes(element.ID)
    );
  };
}

export function stateCallBack(project: Project, selectedElement: () => cn_element, storey: cn_storey) {
  return (element: cn_element) => {
    const currentSelectedElement = selectedElement();
    const isSelected = currentSelectedElement === element;
    let wallOrSlab: Wall | Slab | undefined;

    let classString = '';
    const typeElement = element.constructor;
    if (typeElement == cn_wall) {
      wallOrSlab = project.walls.find(byUid(element, storey));
    } else if (typeElement == cn_slab) {
      wallOrSlab = project.slabs.find(s => s.uid === element.ID);
    } else if (typeElement === cn_space) {
      const currentRoom = project.rooms.find(byUid(element, storey));
      if (currentRoom) {
        classString += currentRoom.heatingTransmitters.length ? ' heated' : ' unheated';
      }
    }

    if (wallOrSlab) {
      switch (wallOrSlab.overlook) {
        case 'EXTERIEUR':
          classString = 'exterior';
          break;
        case 'HEATED_LOCAL':
          classString = 'heated-local';
          break;
        case 'UNHEATED_LOCAL':
          classString = 'unheated-local';
          break;
        case 'FULL_GROUND':
          classString = 'full-ground';
          break;
        default:
          classString = 'exterior';
      }
    }

    classString += isSelected ? ' selected' : '';

    return classString;
  };
}
