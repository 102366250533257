'use strict';

import { cn_building } from '../../model/cn_building';
import { cn_storey } from '../../model/cn_storey';
import { cn_zone, ZONE_EXTERIOR_LABEL } from '../../model/cn_zone';
import {
    cn_nomenclature,
    find_zone,
    NOMENCLATURE_COLUMNS_ID_FACING,
    NOMENCLATURE_COLUMNS_ID_FACING_SURFACE,
    NOMENCLATURE_LABEL_FACING_NONE,
    push_cn_nomenclatures_zones
} from '../cn_nomenclature';
import { extension_instance } from '../../extension/cn_extension';

//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
/**
 * Buils facing nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_facings(building) {

    building.update_roofs();
    building.compute_altitudes();

    const output = [];
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Pièce'));
    output.push(new cn_nomenclature('Usage').withVisibility(false));
    output.push(new cn_nomenclature('Position'));
    output.push(new cn_nomenclature('Revêtement').withId(NOMENCLATURE_COLUMNS_ID_FACING));
    output.push(new cn_nomenclature('Surface').withId(NOMENCLATURE_COLUMNS_ID_FACING_SURFACE).withNumberDefinition('m²', 2));

    const zones = building.zones;
    [building.exterior, ...building.storeys].forEach((storey) => {
        populate_nomenclature_facings(building, storey, output, zones);
    });

    return output;
}

//***********************************************************************************
/**
 *
 * @param {cn_building} building
 * @param {cn_storey} storey
 * @param {cn_nomenclature[]} output
 * @param {{[property: string]: cn_zone[]}} zones
 */
function populate_nomenclature_facings(building, storey, output, zones) {
    storey.scene.storey = storey;
    storey.scene.update_deep();

    //*** We compute the storey volume */
    storey.build_roof_volume(true);

    function _populate_facing_list(facing_list, polygons, default_facing) {
        polygons.forEach(pg => {
            const facing = (pg['facing_trimming']) ? pg['facing_trimming'].facing : default_facing;
            const building_facing = building.facing_types.find(f => facing && facing.ID === f.ID);
            const facing_name = building_facing ? building_facing.name : NOMENCLATURE_LABEL_FACING_NONE;
            if (typeof (facing_list[facing_name]) != 'number') facing_list[facing_name] = 0;
            facing_list[facing_name] += pg.get_area();
        });
    }

    //*** Loop on storeys */
    storey.scene.spaces.length && storey.scene.spaces.forEach(space => {
        const storey_name = (storey.exterior) ? 'Extérieur' : storey.storey_index;
        const zones_names = [];
        extension_instance.zone.get_zones_tools().forEach(tool_zone => {
            const zone_name = (storey.exterior) ? '-' : (space.outside) ? ZONE_EXTERIOR_LABEL : find_zone(zones[tool_zone.property], space.ID, storey.ID);
            zones_names.push(zone_name);
        });
        const space_name = space.get_name(storey);
        const space_usage = space.space_usage ? space.space_usage : '';

        //*** Local method to add a line to the nomenclature */
        function add_line(position, facing, area) {
            let k = 0;
            output[k].values.push(storey_name);
            k++
            zones_names.forEach(zone_name => {
                output[k].values.push(zone_name);
                k++;
            });
            output[k].values.push(space_name);
            k++
            output[k].values.push(space_usage);
            k++
            output[k].values.push(position);
            k++
            output[k].values.push(facing);
            k++
            output[k].values.push(area);
            k++
        }

        //*** Floor spacing */
        if (!space.outside) {
            const area_per_facing = {};

            _populate_facing_list(area_per_facing, space.get_floor_facing_polygons(), space.facings[0]);
            for (var key in area_per_facing) {
                add_line('Sol', key, area_per_facing[key]);
            }

            if (space.has_roof) {
                const ceilings = building.build_3d_ceiling(storey, space);
                if (ceilings.length > 0) {
                    var area = 0;
                    ceilings.forEach(c => area += c.get_area());
                    const facing = building.facing_types.find(f => space.facings[1] && space.facings[1].ID === f.ID);
                    add_line('Plafond', facing ? facing.name : 'Aucun', area);
                }
            }
        }

        //*** wall spacing retrieval */
        var facing_list = {};
        space.contours.forEach(ctr => {
            ctr.walls.forEach((wall, index) => {
                const side = (ctr.wall_orientations[index]) ? 0 : 1;
                _populate_facing_list(facing_list, wall.build_facing_polygons(side, storey), wall.facings[side]);
            });
        });

        //*** wall spacing writing */
        for (var facing in facing_list) {
            if (!space.has_roof)
                add_line('Facade', facing, facing_list[facing]);
            else
                add_line('Mur', facing, facing_list[facing]);
        }
    });
}

