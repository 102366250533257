'use strict';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** building serialisation data extension
//***********************************************************************************
//***********************************************************************************


export class cn_data_extension {

    constructor() {
    }

    serialize() {
        return JSON.stringify(this);
    }

    static unserialize(json) {
        return Object.assign(new cn_data_extension(), JSON.parse(json));
    }

}


