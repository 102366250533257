import { Injectable } from '@angular/core';
import { cn_building } from '@enerbim/cnmap-editor';
import { ELECTRICAL_EMITTER, EMITTER_CODE_BIM } from '../constants/emitter-constantes';
import { PacEmitterDescription, TAP_TYPE } from '../model/pac-emitter-description';
import { PacRoomDescription } from '../model/pac-room-description';
import { ZONE_TYPE_HEATER_DISTRIBUTION } from '../utils/constants';
import { AdditionnalHeater, ObjectInstanceContract } from '../model/additionnal-heater.model';
import {
  AUXILIARIES_EQUIPEMENTS_LIST,
  AuxiliariesEquipments,
  INTERIOR_UNITY_CODE_BIM,
  SLAB_CODE_BIM,
  SWITCH_BOARD_CODE_BIM,
} from '../model/auxiliaries-equipments';

@Injectable({
  providedIn: 'root',
})
export class PacCnBuildingService {
  readonly emittersCodeBim: string[] = Object.values(EMITTER_CODE_BIM);

  getTotalArea(building: cn_building): number {
    return building.storeys
      .flatMap(storey => storey.scene.spaces)
      .filter(space => !space.outside)
      .reduce((a, b) => a + b.get_area(), 0);
  }

  getRoomsDescriptions(building: cn_building, addedHeaters: AdditionnalHeater[] = []): PacRoomDescription[] {
    if (!building) {
      return [];
    }

    const zonePerStoreyAndSpace = new Map();
    const replacedHeatedPerStorey = new Map();
    const replacedHeatedPerAddHeater = new Map();
    const mainFeederPerZone = new Map();
    building.storeys.forEach(storey => {
      replacedHeatedPerStorey.set(storey.ID, new Set());
      zonePerStoreyAndSpace.set(storey.ID, new Map());
    });

    if (addedHeaters.length) {
      addedHeaters
        .filter(heater => heater.objectIdReplaced)
        .forEach(heater => {
          replacedHeatedPerStorey.get(heater.storeyId).add(heater.objectIdReplaced);
          replacedHeatedPerAddHeater.set(heater.id, heater.objectIdReplaced);
        });
    }

    (building.zones[ZONE_TYPE_HEATER_DISTRIBUTION] || [])
      .flatMap(zone => zone.rooms.map(room => ({ ...room, zone: { ID: zone.ID, name: zone.name } })))
      .forEach(roomWithZone => {
        zonePerStoreyAndSpace.get(roomWithZone.storey).set(roomWithZone.space, roomWithZone.zone);
      });

    const spaces = building.storeys.flatMap(storey =>
      storey.scene.spaces.filter(space => !space.outside).map(space => ({ space, storey })),
    );
    const emitterPerSpace = new Map<string, Map<string, PacEmitterDescription[]>>();
    spaces.forEach(ss => {
      if (!emitterPerSpace.has(ss.storey.ID)) {
        ss.storey.scene.storey = ss.storey;
        ss.storey.scene.update_deep();
        ss.storey.roof_volume = ss.storey.build_roof_volume(false);
        emitterPerSpace.set(ss.storey.ID, new Map<string, PacEmitterDescription[]>());
      }
      emitterPerSpace.get(ss.storey.ID).set(ss.space.ID, []);
    });
    building.storeys
      .flatMap(storey =>
        storey.scene.object_instances.map(
          object => ({ instance: object, storey, addedHeater: false } as ObjectInstanceContract),
        ),
      )
      .filter(obj => !!obj.storey && !!obj.instance && !!obj.instance.space)
      .filter(obj => this.emittersCodeBim.includes(obj.instance.object.source.product_type))
      .concat(addedHeaters.map(heater => AdditionnalHeater.generateObjectInstanceContract(heater)))
      .forEach(obj => {
        const emitter = new PacEmitterDescription();
        emitter.id = obj.instance.ID;
        emitter.slug = obj.instance.object.source.product_type;
        emitter.label = obj.instance.object.name;
        emitter.isAddedHeater = obj.addedHeater;
        const strPower = obj.instance.parameters.puissance || obj.instance.object.source.parameters.puissance;
        if (strPower) {
          emitter.rawPower = +strPower;
        }
        emitter.isFeederTank = emitter.slug === EMITTER_CODE_BIM.NOURRICE_PLANCHER_CHAUFFANT;
        if (!emitter.isFeederTank) {
          emitter.thickness = obj.instance.object.size[0] * 100;
          emitter.width = obj.instance.object.size[1] * 100;
          emitter.height = obj.instance.object.size[2] * 100;
          emitter.isElectrical = ELECTRICAL_EMITTER.includes(emitter.slug as EMITTER_CODE_BIM);
          emitter.isWaterTowelDryer = EMITTER_CODE_BIM.SECHE_SERVIETTE_EAU === emitter.slug;
        } else {
          const correspondingZone = zonePerStoreyAndSpace.get(obj.storey.ID).get(obj.instance.space.ID);
          if (correspondingZone && !mainFeederPerZone.has(correspondingZone.ID)) {
            mainFeederPerZone.set(correspondingZone.ID, emitter);
          }
        }
        if (!emitter.isElectrical) {
          const typeRobinet =
            obj.instance.parameters.type_robinet || obj.instance.object.source.parameters.type_robinet;
          emitter.tapType = typeRobinet ? (typeRobinet[0] as TAP_TYPE) : '';
        }
        emitter.isReplaced = replacedHeatedPerStorey.get(obj.storey.ID).has(emitter.id);
        emitterPerSpace.get(obj.storey.ID).get(obj.instance.space.ID)?.push(emitter);
      });
    return spaces.map(spaceAndStorey => {
      const space = spaceAndStorey.space;
      const storey = spaceAndStorey.storey;
      const spaceVolume = space.build_solid(storey);
      const area = space.get_area();
      const height = spaceVolume.get_bounding_box().size[2];
      const volume = spaceVolume.get_volume();
      const emitters = emitterPerSpace.get(storey.ID).get(space.ID);
      const zone = zonePerStoreyAndSpace.get(storey.ID).get(space.ID);
      if (zone && !emitters.some(emitter => emitter.isFeederTank) && mainFeederPerZone.has(zone.ID)) {
        const fakeEmitter = Object.assign(new PacEmitterDescription(), mainFeederPerZone.get(zone.ID));
        fakeEmitter.isFake = true;
        emitters.push(fakeEmitter);
      }
      emitters.sort((a, b) => this.sortEmitters(a, b, replacedHeatedPerAddHeater));
      return {
        id: space.ID,
        zone: zone,
        storey: storey.ID,
        name: space.get_name(storey),
        area: area,
        height: height,
        volume: volume,
        hasHeatedFloor: mainFeederPerZone.has(zone?.ID || ''),
        emitterDescriptions: emitters,
      };
    });
  }

  getAuxiliariesEquipments(building: cn_building): AuxiliariesEquipments {
    const result = new AuxiliariesEquipments();

    [...building.storeys, building.exterior]
      .flatMap(storey => storey.scene.object_instances)
      .filter(instance => AUXILIARIES_EQUIPEMENTS_LIST.includes(instance.object.source.product_type))
      .forEach(instance => {
        result.equipments.set(instance.object.source.product_type, instance);
      });
    return result;
  }

  areAllEmittersHeatingFloor(building: cn_building): boolean {
    return building.storeys
      .flatMap(storey => storey.scene.object_instances.map(object => ({ instance: object, storey })))
      .filter(obj => !!obj.storey && !!obj.instance && !!obj.instance.space)
      .every(obj => EMITTER_CODE_BIM.NOURRICE_PLANCHER_CHAUFFANT === obj.instance.object.source.product_type);
  }

  getTotalGlazingArea(building: cn_building): number {
    const openings = building.storeys.flatMap(storey => storey.scene.get_openings());
    return openings.reduce((a, b) => a + b.opening_type.get_glazing_area(), 0);
  }

  private sortEmitters(
    a: PacEmitterDescription,
    b: PacEmitterDescription,
    replacedHeatedPerAddHeater: Map<string, string>,
  ): number {
    let result =
      (replacedHeatedPerAddHeater.get(a.id) || a.id).localeCompare(replacedHeatedPerAddHeater.get(b.id) || b.id) ||
      +b.isReplaced - +a.isReplaced;
    if (a.isAddedHeater && !replacedHeatedPerAddHeater.has(a.id)) {
      result = 1;
    } else if (b.isAddedHeater && !replacedHeatedPerAddHeater.has(b.id)) {
      result = -1;
    }
    return result;
  }

  getHotWaterEmitters(rooms: PacRoomDescription[]): PacEmitterDescription[] {
    return rooms.flatMap(room =>
      room.emitterDescriptions.filter(emitter => !emitter.isFeederTank && !emitter.isElectrical),
    );
  }

  getNotRegulatedWaterVolume(waterEmitters: PacEmitterDescription[]): number {
    return Math.floor(
      waterEmitters
        .filter(emitter => emitter.tapType === 'M')
        .reduce((agg, emitter) => agg + (emitter.height * emitter.width) / 1000, 0),
    );
  }

  getRealWaterVolume(waterEmitters: PacEmitterDescription[]): number {
    return Math.floor(waterEmitters.reduce((agg, emitter) => agg + (emitter.height * emitter.width) / 1000, 0));
  }
}
