import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DisplayConfig, Environment, PluginOptionService } from '@enerbim/cnmap-angular-editor-lib';
import { AuthenticationService } from '../auth/authentication.service';
import { NotificationService } from '../../shared/services/notification.service';
import { CN_OUTER, extension_instance } from '@enerbim/cnmap-editor';
import { cn_zone_extension } from '@enerbim/cnmap-editor';
import { ZONE_TYPE_HEATER_DISTRIBUTION } from 'src/app/shared/utils/constants';
import { FrontVersionApiService } from 'src/app/shared/services/front-version-api.service';
import { Observable, Subscription, from, interval, of } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  deferredPrompt: any;
  isVersionOutdated$: Observable<boolean>;
  updateVersionSubscription: Subscription = null;

  /** L'orientation du device est-elle correcte ? */
  correctOrientation = true;

  constructor(
    private authenticationService: AuthenticationService,
    private pluginOptionService: PluginOptionService,
    private notificationService: NotificationService,
    private frontVersionApiService: FrontVersionApiService,
    private swUpdate: SwUpdate,
  ) {
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @HostListener('window:orientationchange')
  onOrientationChange() {
    this.detectOrientation();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  beforeInstallPrompt(event) {
    // Stash the event so it can be triggered later.
    this.deferredPrompt = event;
    this.showInstallButton();
  }

  showInstallButton() {
    this.notificationService
      .notifyWithAction("Vous pouvez installer l'application sur votre appareil", "Cliquer ici pour l'installer")
      .subscribe(result => {
        if (result === true) this.install();
      });
  }

  install() {
    const promptEvent = this.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    promptEvent.userChoice.then(() => {
      // Reset the deferred prompt variable, since prompt() can only be called once.
      this.deferredPrompt = null;
    });
  }

  ngOnInit() {
    // Renseigne l'URL de l'API dans la librairie "angular-commons"
    // todo ASI: should be moved within editor component
    this.pluginOptionService.setDisplaySaveAs(false);
    this.pluginOptionService.setDisplayRecoverMenu(true);
    this.pluginOptionService.setActivateSketchMode(false);
    this.pluginOptionService.setActivatePhotoMode(false);
    this.pluginOptionService.setDisplayMainMenu(true);
    this.pluginOptionService.setDisplayCloneStorey(false);
    this.pluginOptionService.setActivateSketchMode(false);
    this.pluginOptionService.setActivatePhotoMode(false);
    this.pluginOptionService.setDisplayBuildingInfo(false);

    // Initialise l'état de l'authentification
    // todo ???
    this.authenticationService.queryAndSetCurrentUser().subscribe();

    Environment.defaultConfig = this.defaultConfigForDrawingMode();
    Environment.defaultObjectColor = '#ED7D7D';
    Environment.defaultObjectContact = 'wall';

    extension_instance.config.default_axis_wall = CN_OUTER;
    extension_instance.zone = new De3dZoneExtension();

    this.isVersionOutdated$ = this.frontVersionApiService.isVersionOutdated();

    // Détecte l'orientation
    this.detectOrientation();
  }

  private defaultConfigForDrawingMode() {
    const config = DisplayConfig.defaultConfigModeDrawing();
    config.drawGrid = false;
    config.showComments = true;
    config.showSamplings = true;
    config.drawBackground = true;
    config.drawPreviousStorey = false;
    config.drawExterior = false;
    config.drawSpaceNames = true;
    config.drawSpaceAreas = false;
    config.showAngles = true;
    config.showMeasures = true;
    config.showObjectsTopView = true;
    config.showObjectsIcons = true;
    config.drawHeightBox = false;
    config.showSpaceMeasure = false;
    return config;
  }

  private detectOrientation() {
    this.correctOrientation = !screen.orientation.type.includes('portrait');
  }

  refreshLocation(): void {
    if (this.swUpdate.isEnabled) {
      this.updateVersionSubscription = from(this.swUpdate.checkForUpdate())
        .pipe(switchMap(() => from(this.swUpdate.activateUpdate())))
        .subscribe(() => window.location.reload());
    } else {
      this.updateVersionSubscription = of({})
        .pipe(delay(3000))
        .subscribe(() => window.location.reload());
    }
  }
}

class De3dZoneExtension extends cn_zone_extension {
  constructor() {
    super();
    this.zones_tools_base = [
      {
        property: ZONE_TYPE_HEATER_DISTRIBUTION,
        label_singular: 'Plancher chauffant',
        label_plural: 'Planchers chauffants',
        label_plural_short: 'Planchers chauffants',
        icon: 'water-boiler',
      },
    ];
  }
}
