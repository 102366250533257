import { Component, Injector, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Project } from 'src/app/shared/model/project.model';
import { filter, map } from 'rxjs/operators';

@Component({ template: '' })
export class AbstractProjectStepComponent {
  readonly form = this.buildForm();

  initialProject: Project;
  disabledForm: boolean = false;

  @Input() set disabled(value: boolean) {
    this.disabledForm = value;
    if (value) this.form.disable();
    else this.form.enable();
  }

  @Input() set project(project: Project) {
    // the order is really important since patchProjectValue can trigger child function that might expect the initialProject to be initialized
    this.initialProject = project;
    this.patchProjectValue(project);
  }

  @Output() projectChange = this.form.valueChanges.pipe(
    filter(() => !this.disabledForm),
    map(value => this.mapProjectChange(value)),
  );

  constructor(protected injector: Injector) {}

  protected buildForm(): FormGroup {
    // be careful this method is executed before the child component is instantiated
    // then you can not inject services in the child constructor and then using them here
    // you need to use the injector
    throw new Error('BuildForm not implemented !!!');
  }

  protected patchProjectValue(project: Project): void {
    this.form.patchValue(project, { emitEvent: false });
  }

  protected mapProjectChange(change): Project {
    return Object.assign(new Project(this.initialProject.type), this.initialProject);
  }
}
