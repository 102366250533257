import { Pipe, PipeTransform } from '@angular/core';

/**
 * used documentation
 * https://vitrinelinguistique.oqlf.gouv.qc.ca/index.php?id=21116
 */
@Pipe({
  name: 'abbreviateFirstname',
})
export class AbbreviateFirstnamePipe implements PipeTransform {
  transform(firstname: string): string {
    const segments = (firstname || '')
      .trim()
      .split(/[ \-]/)
      .filter(s => !!s);

    return segments.reduce(
      (abbreviation, segment) => abbreviation.replace(segment, this._getSegmentAbbreviation(segment)),
      (firstname || '').trim(),
    );
  }

  private _getSegmentAbbreviation(segment: string): string {
    let abbreviation = segment[0].toUpperCase();
    return abbreviation + '.'; // English names are ignored
  }
}
