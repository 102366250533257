import { Pipe, PipeTransform } from '@angular/core';

export const labels = {
  PERFORMANCE: 'Performance',
  SMART: 'Rentabilité',
};

@Pipe({
  name: 'tagToText',
})
export class TagToTextPipe implements PipeTransform {
  transform(value: 'PERFORMANCE' | 'SMART' | undefined): string {
    return labels[value] || '';
  }
}
