'use strict';
//***********************************************************************************
//***********************************************************************************
//**** A tool to edit stairs
//***********************************************************************************
//***********************************************************************************

import { cn_add } from '../utils/cn_utilities';
import { cn_stairs } from '../model/cn_stairs';
import { cn_svg_tool_edition } from './cn_svg_tool_edition';
import { GeometryUtils as ely } from 'three';
import { cn_stairs_handler } from './cn_stairs_handler';

export class cn_svg_tool_stairs_edition extends cn_svg_tool_edition {
    constructor(map) {
        super(map);

        this._handler = null;
    }

    //***********************************************************************************
    //**** Selection callback
    //***********************************************************************************
    on_selection_change() {
        const obj = this;
        this._handlers = [];

        //*** We expect the selection to contain only openings */
        var sel = this._controller.get_selection();
        if (sel.length == 0 || sel.some(elt => elt.constructor != cn_stairs))
            return false;

        const handler = new cn_stairs_handler(sel, this._map);
        this._handlers.push(handler);
        return true;
    }

    //***********************************************************************************
    //**** Group translation
    //***********************************************************************************
    translate(ev, offset) {
        var selection = this._controller.get_selection();
        for (var i in selection) {
            var elt = selection[i];
            if (elt.constructor != cn_stairs) continue;

            if (elt.stairs_type == 'straight') {
                this.push_item_set(elt, 'vertices');
                var vertices = elt.vertices;
                for (var k in vertices) {
                    vertices[k] = cn_add(vertices[k], offset);
                }
            } else if (elt.stairs_type == 'round') {
                this.push_item_set(elt, 'center');
                elt.center = cn_add(ely.center, offset);
            }
            elt.build_borders();
        }
    }
}

