<!-- On doit déclarer le ng-content car on ne peut pas le déclarer plusieurs fois ;-) -->
<ng-template #leftContent>
  <ng-content select="left-content"></ng-content>
</ng-template>
<ng-template #rightContent>
  <ng-content select="right-content"></ng-content>
</ng-template>

<ng-container *ngIf="hideLeftPart">
  <ng-container *ngTemplateOutlet="rightContent"></ng-container>
</ng-container>

<ng-container *ngIf="!hideLeftPart">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 main-padding-top side-content px-0">
        <ng-container *ngTemplateOutlet="leftContent"></ng-container>
      </div>
      <div class="col main-padding-top main-content">
        <ng-container *ngTemplateOutlet="rightContent"></ng-container>
      </div>
    </div>
  </div>
</ng-container>
