import { Component, OnInit } from '@angular/core';
import { VersionCheckService } from '../shared/services/version-check.service';
import { FrontVersionApiService } from '../shared/services/front-version-api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-core',
  template: ` <app-layout></app-layout> `,
  styles: [],
})
export class CoreComponent {
  constructor() {}
}
