import { CompanyRights } from './company.model';

export const ROLE_USER = 'ROLE_USER';
export const ROLE_ADMIN = 'ROLE_ADMIN';

// TODO ASI À quoi sert cette enum ? Redondant avec le rôle sur la société ?
export enum Role {
  USER = 'USER',
  BUSINESS_MANAGER = 'BUSINESS_MANAGER',
  ADMIN = 'ADMIN',
}

export enum Perimeter {
  DPE = 'DPE',
  PAC = 'PAC',
}

export enum NotificationType {
  BUSINESS_NEW_PROJECT = 'BUSINESS_NEW_PROJECT',
  BUSINESS_VALIDATE_PROJECT = 'BUSINESS_VALIDATE_PROJECT',
  BUSINESS_VALIDATE_PROJECT_SUMMARY = 'BUSINESS_VALIDATE_PROJECT_SUMMARY',
  TECHNICIAN_VALID_PROJECT = 'TECHNICIAN_VALID_PROJECT',
}

export class User {
  constructor(
    public id?: string,
    public email?: string,
    public firstName?: string,
    public lastName?: string,
    public address?: string,
    public phone?: string,
    public authorities?: string[],
    public deletedDate?: string,
    public mfaEnabled?: boolean,
    // this is only for creating a user outside a company (that require to create the associated individual company)
    public perimeters?: Perimeter[],
    public rights?: CompanyRights[],
    public notifications?: NotificationType[],
    public version?: number,
  ) {}

  static isSuperAdmin(user: User): boolean {
    return user.authorities.includes(ROLE_ADMIN);
  }
}
