import { Pipe, PipeTransform } from '@angular/core';
import { PROJECT_STATUS, Project } from '../model/project.model';
import { AuthenticationStore } from 'src/app/core/auth/authentication.store';

@Pipe({
  name: 'isCreatorAndHasStatus',
})
export class IsCreatorAndHasStatusPipe implements PipeTransform {
  constructor(private authenticationStore: AuthenticationStore) {}

  transform(project: Project, status?: PROJECT_STATUS): unknown {
    return (
      (!status || project.projectStatus === status) &&
      project.createdBy?.id === this.authenticationStore.getCurrentUserSnapshot().id
    );
  }
}
