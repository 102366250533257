import { from } from 'nearest-color'

/**
 * Liste des couleurs telles que définies dans le référentiel CNBIM.
 * On utilise un suffixe numérique avec de pouvoir donner plusieurs codes hexa à la même couleur, afin d'être sûr de bien matcher la couleur la plus proche.
 *
 * Pour une meilleure précision, il faudrait ajouter des couleurs ici, ainsi que dans le référentiel CNBIM.
 */
const bbp_colors = {
    Blanc: '#FFFFFF',
    Gris1: '#d6d6d6',
    Gris2: '#808080',
    Gris3: '#212121',
    Noir: '#000000',
    Violet1: '#6C21AD',
    Violet2: '#7f00ff',
    Violet3: '#e3e4ff',
    Violet4: '#f0e3ff',
    Violet5: '#ffe3fe',
    Bleu1: '#00f',
    Bleu2: '#2F8EF5',
    Bleu3: '#5BD5FA',
    Bleu4: '#e3fff8',
    Bleu5: '#e3f3ff',
    Vert1: '#3DE0B2',
    Vert2: '#0EAD40',
    Vert3: '#008000',
    Vert4: '#9AFA5F',
    Vert5: '#9CE036',
    Vert6: '#DEFA78',
    Vert7: '#f1ffe3',
    Vert8: '#e3ffe7',
    Jaune1: '#FAF878',
    Jaune2: '#ff0',
    Jaune3: '#FAE443',
    Jaune4: '#fffbe3',
    Orange1: '#F9CE3F',
    Orange2: '#FAB639',
    Orange3: '#FFA500',
    Orange4: '#FAA756',
    Marron1: '#E09748',
    Marron2: '#AD6F37',
    Marron3: '#582900',
    Beige1: '#e1c699',
    Beige2: '#baa282',
    Beige3: '#dbbe97',
    Beige4: '#decbb1',
    Beige5: '#ffece3',
    Beige6: '#fff3e3',
    Rose1: '#fd6c9e',
    Rose2: '#d99886',
    Rose3: '#ffe3e3',
    Rose4: '#f4cdcd',
    Rouge1: '#f00',
    Rouge2: '#eb491c',
    Rouge3: '#b80f0f',

};

const nearest_bbp_color = from(bbp_colors);

export function nearest_bbp_color_label(hex) {
    if (!hex) return null;
    const color = nearest_bbp_color(hex);
    if (!color) return null;
    const colorNameSuffixed = color.name;
    return colorNameSuffixed.replace(/\d+$/, '')
}
