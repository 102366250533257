'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_handler_object
//***********************************************************************************
//***********************************************************************************

import { cn_add, cn_cart, cn_clone, cn_dot, cn_mul, cn_normal, cn_polar, cn_sub } from '../utils/cn_utilities';
import { cn_contour } from '../model/cn_contour';
import { cn_vertex } from '../model/cn_vertex';

export class cn_handler_object {
    constructor(width, height, position = null, angle = 0, anchor_position = null, anchor_normal = null) {
        if (position) this.center = cn_clone(position);
        else this.center = [0, 0];
        this.angle = angle;
        this.width = width;
        this.height = height;
        this.dx = [Math.cos(angle), Math.sin(angle)];
        this.dy = cn_normal(this.dx);
        this.vertices = [[0, 0], [0, 0], [0, 0], [0, 0]];
        this.local_vertices = null;
        this.contour = null;
        this.anchor_position = null;
        if (anchor_position) this.anchor_position = cn_sub(anchor_position, [0.5 * width, 0.5 * height]);
        this.anchor_normal = anchor_normal;
        this.element = null;
        this.update();
    }

    //*****************************************************************
    //*** Create object from polygon
    //*****************************************************************
    static from_polygon(vertices) {
        var object = new cn_handler_object();
        object.vertices = [];
        for (var i in vertices) {
            object.vertices.push(cn_clone(vertices[i]));
            object.center = cn_add(object.center, vertices[i]);
        }
        object.center = cn_mul(object.center, 1 / vertices.length);

        object.local_vertices = [];
        object.contour = new cn_contour();
        for (var i in object.vertices) {
            object.local_vertices.push(cn_sub(object.vertices[i], object.center));
            object.contour.vertices.push(new cn_vertex(object.local_vertices[i]));
        }
        object.contour.update();

        object.angle = 0;
        object.dx = [1, 0];
        object.dy = [0, 1];

        object.update();
        return object;
    }

    //*****************************************************************
    //*** transform
    //*****************************************************************
    local_to_global(p) {
        return cn_add(this.center, cn_add(cn_mul(this.dx, p[0]), cn_mul(this.dy, p[1])));
    }

    global_to_local(p) {
        var d = cn_sub(p, this.center);
        return [cn_dot(d, this.dx), cn_dot(d, this.dy)];
    }

    /**
     * Returns world position of anchor
     * @return {number[]}
     */
    get_world_anchor() {
        return this.local_to_global(this.anchor_position);
    }

    //*****************************************************************
    //*** does the shape contains the point ?
    //*****************************************************************
    contains(p) {
        if (this.contour) {
            var pp = this.global_to_local(p);
            return this.contour.contains(pp);
        }
        var d = cn_sub(p, this.center);
        if (Math.abs(cn_dot(d, this.dx)) > this.width * 0.5) return false;
        if (Math.abs(cn_dot(d, this.dy)) > this.height * 0.5) return false;
        return true;
    }

    //*****************************************************************
    //*** copy
    //*****************************************************************
    copy(other) {
        this.center = cn_clone(other.center);
        this.angle = other.angle;
        this.width = other.width;
        this.height = other.height;
        this.vertices = [];
        for (var i in other.vertices) {
            this.vertices.push(cn_clone(other.vertices[i]));
        }
        this.local_vertices = other.local_vertices;
        this.contour = other.contour;
        this.update();
    }

    //*****************************************************************
    //*** translate
    //*****************************************************************
    translate(d) {
        this.center[0] += d[0];
        this.center[1] += d[1];
        this.update();
    }

    //*****************************************************************
    //*** Rotate
    //*****************************************************************
    rotate(center, angle) {
        var pol = cn_polar(cn_sub(this.center, center));
        pol[1] += angle;
        this.center = cn_add(center, cn_cart(pol));
        this.angle += angle;
        this.update();
    }

    //*****************************************************************
    //*** Rotate
    //*****************************************************************
    rotate_local(local_center, angle) {
        this.rotate(this.local_to_global(local_center), angle);
    }

    //*****************************************************************
    //*** interpolate
    //*****************************************************************
    interpolate(t, o0, o1) {
        this.angle = o0.angle * (1 - t) + o1.angle * t;
        this.center = cn_add(cn_mul(o0.center, 1 - t), cn_mul(o1.center, t));
        this.update();
    }

    //*****************************************************************
    //*** place through anchor
    //*****************************************************************
    place_anchor(world_position, world_normal) {
        //*** First rotation */
        var angle1 = cn_polar(world_normal)[1];
        var current_normal = cn_add(cn_mul(this.dx, this.anchor_normal[0]), cn_mul(this.dy, this.anchor_normal[1]));
        var angle0 = cn_polar(current_normal)[1];
        if (angle0 != angle1)
            this.rotate(this.center, angle1 - angle0);

        //*** then translation */
        var current_anchor = this.local_to_global(this.anchor_position);
        var delta = cn_sub(world_position, current_anchor);
        this.translate(delta);
    }

    //*****************************************************************
    //*** Update position
    //*****************************************************************
    update() {
        var cx = Math.cos(this.angle);
        var cy = Math.sin(this.angle);
        this.dx = [cx, cy];
        this.dy = cn_normal(this.dx);
        if (this.local_vertices) {
            for (var n = 0; n < this.vertices.length; n++)
                this.vertices[n] = this.local_to_global(this.local_vertices[n]);
        } else {
            for (var n = 0; n < this.vertices.length; n++) {
                var p = cn_clone(this.center);
                if (n & 1) p = cn_add(p, cn_mul(this.dx, 0.5 * this.width));
                else p = cn_sub(p, cn_mul(this.dx, 0.5 * this.width));
                if (n & 2) p = cn_add(p, cn_mul(this.dy, 0.5 * this.height));
                else p = cn_sub(p, cn_mul(this.dy, 0.5 * this.height));
                this.vertices[n] = p;
            }
        }
    }
}

