'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_tool wall : a tool to build walls
//***********************************************************************************
//***********************************************************************************

import { cn_add, cn_cart, cn_mul } from '../utils/cn_utilities';
import { cn_opening } from '../model/cn_opening';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_opening_handler } from './cn_opening_handler';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_opening_type } from '../model/cn_opening_type';
import { cn_element } from '../model/cn_element';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_fence_opening_type } from '../model/cn_fence_opening_type';
import { cn_wall } from '../model/cn_wall';
import { cn_roof_dormer } from '../model/cn_roof_dormer';

export class cn_svg_tool_opening_creation extends cn_svg_tool_creation {
    constructor(map) {
        super(map);

        //*** Scene data
        this._ghost = new cn_opening(null);
        this._ghost.valid = false;

        this._categories = null;
        this._new_opening_handler = null;
        this.element_filter = element => {
            if (element.constructor == cn_opening) return true;
            if (element.constructor == cn_roof_dormer) return true;
            if (element.constructor != cn_wall) return false;
            return (!element.balcony);
        };
    }

    //***********************************************************************************
    //**** Selection callback
    //***********************************************************************************

    open_tool() {
        super.open_tool();
        const opening_types = this.get_opening_types();
        if (opening_types.indexOf(this._ghost.opening_type) < 0)
            this._ghost.opening_type = opening_types[0];
    }

    //***********************************************************************************
    //**** Returns all available opening types
    //***********************************************************************************
    get_opening_types() {
        var ot_list = [];
        if (this._map._storey.exterior)
            ot_list = ot_list.concat(this._scene.building.get_fence_opening_types());
        else {
            if (this._controller.get_element_filter('windows'))
                ot_list = ot_list.concat(this._scene.building.get_window_types());
            if (this._controller.get_element_filter('doors'))
                ot_list = ot_list.concat(this._scene.building.get_door_types());
        }
        return ot_list;
    }

    //***********************************************************************************
    //**** Returns current opening
    //***********************************************************************************
    get_current_opening_type() {
        return this._ghost.opening_type;
    }

    set_current_opening_type(ot) {
        this._ghost.opening_type = ot;
        this._new_opening_handler = null;
    }

    /**
     * Checks current beam type
     */
    check_current_element_type() {
        if (this._ghost.opening_type == null) return;

        var opening_types = this.get_opening_types();
        if (opening_types.indexOf(this._ghost.opening_type) >= 0) return;

        this._ghost.opening_type = (opening_types.length > 0) ? opening_types[0] : null;
    }

    /**
     * Select elements by opening type
     * @param {cn_opening_type | cn_fence_opening_type} ot
     */
    select_elements_by_type(ot) {
        this._initiate_edition(this._scene.get_openings().filter(op => op.opening_type == ot));
    }

    //***********************************************************************************
    //**** current categories
    //***********************************************************************************
    get_categories() {
        var lst = [];

        return lst.concat(this._categories);
    }

    //***********************************************************************************
    //**** Draws  specific svg for the tool. Returns svg string
    //***********************************************************************************
    draw(camera) {
        var html = super.draw(camera);
        if (this._focus_handler != this) return html;

        var measure_opening = null;

        //*** Check opening type */
        if (this._ghost.opening_type == null ||
            (this._ghost.opening_type.category == 'window' && !this._controller.get_element_filter('windows')) ||
            (this._ghost.opening_type.category == 'door' && !this._controller.get_element_filter('doors'))) {
            var opening_types = this.get_opening_types();
            this._ghost.opening_type = (opening_types.length > 0) ? opening_types[0] : null;
        }

        if (this._ghost.wall && this._ghost.opening_type) {
            var wall = this._ghost.wall;
            html += this._ghost.draw(camera, ['good']);
            measure_opening = this._ghost;

            //*** draw measures
            if (measure_opening) {
                var wall = measure_opening.wall;

                //*** draw measure before
                var opening_before = wall.opening_before(measure_opening.position);
                if (opening_before) {
                    var p0 = cn_add(wall.bounds.pmin, cn_mul(wall.bounds.direction, opening_before.position + opening_before.opening_type.width));
                    var p1 = cn_add(wall.bounds.pmin, cn_mul(wall.bounds.direction, measure_opening.position));
                    html += camera.draw_measure(p0, p1);
                } else {
                    var p0 = cn_add(wall.bounds.pmin, cn_mul(wall.bounds.direction, measure_opening.position));
                    var p1 = cn_add(wall.bounds.pmax, cn_mul(wall.bounds.direction, measure_opening.position));
                    html += camera.draw_measure(wall.shape[0], p0);
                    html += camera.draw_measure(p1, wall.shape[1]);
                }

                //*** draw measure after
                var opening_after = wall.opening_after(measure_opening.position + measure_opening.opening_type.width);
                if (opening_after) {
                    var p0 = cn_add(wall.bounds.pmin, cn_mul(wall.bounds.direction, measure_opening.position + measure_opening.opening_type.width));
                    var p1 = cn_add(wall.bounds.pmin, cn_mul(wall.bounds.direction, opening_after.position));
                    html += camera.draw_measure(p0, p1);
                } else {
                    var p0 = cn_add(wall.bounds.pmin, cn_mul(wall.bounds.direction, measure_opening.position + measure_opening.opening_type.width));
                    var p1 = cn_add(wall.bounds.pmax, cn_mul(wall.bounds.direction, measure_opening.position + measure_opening.opening_type.width));
                    html += camera.draw_measure(p0, wall.shape[3]);
                    html += camera.draw_measure(wall.shape[2], p1);
                }
            }
        }

        return html;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************

    clear_move() {
        this._ghost.wall = null;
        super.clear_move();
    }

    click(ev) {
        if (this._focus_handler == this)
            this._create_element(ev);
        else
            super.click(ev);
        return true;
    }

    drop(ev) {
        if (this._focus_handler == this)
            this._create_element(ev);
        else
            super.drop(ev);
        return true;
    }

    grab(ev) {
        if (this._focus_handler == this)
            return true;
        return super.grab(ev);
    }

    move(ev) {
        if (super.move(ev)) return true;
        this._update_ghost(ev);
        this._focus_handler = this;
        return true;
    }

    drag(ev) {
        if (this._focus_handler == this)
            this._update_ghost(ev);
        else
            super.drag(ev);
        return true;
    }

    //***************************************************************
    /**
     * Creates a new opening
     * @param {cn_mouse_event} ev
     */
    _create_element(ev) {
        this._update_ghost(ev);
        if (!this._ghost.wall) return;
        this.push_transaction('Création d\'ouvrant');
        var wall = this._ghost.wall;
        this.push_item_set(wall, 'openings', () => {
            wall.build_dependant();
        });

        const new_opening = this._ghost.clone();
        this._ghost.wall.openings.push(new_opening);
        this._ghost.wall.build_dependant();
        this._ghost.wall = null;
        this._scene.update();
        this._scene.update_deep();

        this.call('creation', [new_opening]);
        this._initiate_edition([new_opening]);
    }

    //***************************************************************
    /**
     * Update ghost depending on mouse position
     * @param {cn_mouse_event} ev
     */
    _update_ghost(ev) {
        this._ghost.wall = null;
        if (this._ghost.opening_type) {
            //*** Search for the closest wall under mouse
            this._ghost.wall = null;
            var distance = 0;
            for (var theta = 0; theta < 360; theta += 30) {
                const impact = this._scene.raytrace_walls(ev.mouse_world, cn_cart([1, theta * Math.PI / 180]), 10);
                if (impact) {
                    if (this._ghost.wall == null || impact.distance < distance) {
                        this._ghost.wall = impact.wall;
                        distance = impact.distance;
                    }
                }
            }
            //this._scene.find_wall(ev.mouse_world, ev.camera.snap_world_distance);

            if (this._ghost.wall == null || this._ghost.wall.balcony || this._ghost.wall.wall_type.free) {
                this._ghost.wall = null;
            }

            //*** Check if ghost can lie on that wall
            if (this._ghost.wall && !this._ghost.optimize_placement(ev.mouse_world, ev.camera.snap_world_distance)) {
                this._ghost.wall = null;
            }
        }
        return this._ghost.wall != null;
    }

    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        return this._scene.find_opening(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
    }

    /**
     * TODO : derivate in order to provide an edition handler
     * @param {Array<cn_opening>} elements
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(elements) {
        const edition_handler = new cn_opening_handler(elements, this._map, true);
        const obj = this;
        edition_handler.on('change', () => {
            obj._ghost.opening_start = elements[0].opening_start;
            obj._ghost.opening_inside = elements[0].opening_inside;
            obj._ghost.opening_position = elements[0].opening_position;
            return true;
        });
        return edition_handler;
    }

    /**
     * TODO : derivate in order to find siblings of an element
     * @param {cn_opening} element
     * @returns {Array<cn_element>}
     */
    _get_siblings(element) {
        const ot = element.opening_type;
        return this._scene.get_openings().filter(op => op.opening_type == ot);
    }
}

