//***********************************************************************************
//***********************************************************************************
//**** fh_view_elevation_map : 2D view for elevation
//***********************************************************************************
//***********************************************************************************

import {
    Color,
    DoubleSide,
    Face3,
    Geometry,
    ImageUtils,
    Line,
    LineBasicMaterial,
    Mesh,
    MeshBasicMaterial,
    Scene,
    Vector2,
    Vector3,
	TorusGeometry
} from 'three';
import {fh_view_ortho} from "./fh_view_ortho";
// @ts-ignore
import camera_frustrum from '../images/camera_frustrum.png';

//***********************************************************************************
//**** Class view
//***********************************************************************************

export class fh_view_elevation_map extends fh_view_ortho {
	//*****************************************************
	//*** Constructor
	constructor(div_id, scene, view_3d) {
		super(div_id, scene);

		this._view_3d = view_3d;

		this._widget = null;

		var map = new ImageUtils.loadTexture(camera_frustrum);

		this._plane_material = new MeshBasicMaterial();
		this._plane_material.map = map;
		this._plane_material.side = DoubleSide;
		this._plane_material.transparent = true;
		this._plane_material.depthWrite = false;
		this._plane_material.depthTest = false;
		this._plane_material.color = new Color(0x0000ff);
		this._plane_material.opacity = 0.5;

		this._far_plane_material = new MeshBasicMaterial();
		this._far_plane_material.map = map;
		this._far_plane_material.side = DoubleSide;
		this._far_plane_material.transparent = true;
		this._far_plane_material.depthWrite = false;
		this._far_plane_material.depthTest = false;
		this._far_plane_material.color = new Color(0x000000);
		this._far_plane_material.opacity = 0.5;

		this._bar_material = new LineBasicMaterial();
		this._bar_material.depthWrite = false;
		this._bar_material.depthTest = false;
		this._bar_material.color = new Color(0x0000ff);

		this._bar_material_selected = new LineBasicMaterial();
		this._bar_material_selected.depthWrite = false;
		this._bar_material_selected.depthTest = false;
		this._bar_material_selected.color = new Color(0xff0000);

		this._handle_material = new MeshBasicMaterial();
		this._handle_material.side = DoubleSide;
		this._handle_material.transparent = true;
		this._handle_material.depthWrite = false;
		this._handle_material.depthTest = false;
		this._handle_material.color = new Color("rgb(152, 168, 72)");
		this._handle_material.opacity = 0.5;

		this._handle_mouseover_material = new MeshBasicMaterial();
		this._handle_mouseover_material.side = DoubleSide;
		this._handle_mouseover_material.transparent = true;
		this._handle_mouseover_material.depthWrite = false;
		this._handle_mouseover_material.depthTest = false;
		this._handle_mouseover_material.color = new Color("rgb(90, 114, 193)");
		this._handle_mouseover_material.opacity = 0.5;

		this._torus = null;
		this._front_handle = null;
		this._back_handle = null;
		this._handles = [];

		this._elevation_angle=0;
		this._elevation_positions = [0,0];
		this._grab_angle = 0;

		//*** Widgres */
		this._render_angle = -1;
		this._render_positions = [-1,-1];
		this._render_scale = -1;
		this._elevation_scene = null;

		//*** Event management */
		this._mouseover_bar = -1;
		this._selected_bar = -1;

		this._show_2d_scene = false;
		this._allow_selection = false;
	}

    on_change() {
    }

	//*****************************************************
	//**** Elevation data
	//*****************************************************
	set_elevation_angle(angle) {
		this._elevation_angle=angle;
		this.refresh_rendering();
	}

	get_elevation_angle() {
		return this._elevation_angle;
	}

	set_elevation_position(index, position) {
		this._elevation_positions[index] = position;
		if (this._elevation_positions[1] < this._elevation_positions[0])
			this._elevation_positions[1] = this._elevation_positions[0];
		this.refresh_rendering();
	}

	get_elevation_position(index) {
		return this._elevation_positions[index];
	}

	get_elevation_dir() {
		var angle = (this._elevation_angle+90)*Math.PI/180;
		return new Vector3(Math.cos(angle),Math.sin(angle),0);
	}

	get_elevation_point(index) {
		var dir = this.get_elevation_dir();
		return dir.multiplyScalar(this._elevation_positions[index]);
	}

	//********************************************************
	//*** Update perspective camera
	update_camera()	{
		this._camera.updateProjectionMatrix();
	}

	//*****************************************************
	//**** geometry
	//*****************************************************
	update_elevation_geometry() {
		this._elevation_scene = new Scene();

		var angle = (this._elevation_angle+90)*Math.PI/180;
		var dir = new Vector3(Math.cos(angle),Math.sin(angle),0);
		var nor = new Vector3(Math.sin(angle),-Math.cos(angle),0);
		nor.multiplyScalar(this._scene._scene_radius*2);
		var nor2 = nor.clone();
		nor2.multiplyScalar(0.15);
		var dir2 = dir.clone();
		dir2.multiplyScalar(0.2*this._scene._scene_radius*2);

		this._handles = [];

		var geometry = new Geometry();
		for (var niter=0;niter<2;niter++)
		{
			if (this._elevation_positions[niter] > this._scene._scene_radius)
				this._elevation_positions[niter] = this._scene._scene_radius;
			if (this._elevation_positions[niter] < -this._scene._scene_radius)
				this._elevation_positions[niter] = -this._scene._scene_radius;
			var lambda = this._elevation_positions[niter] - dir.dot(this._scene._scene_center);

			var center = dir.clone();
			center.multiplyScalar(lambda);
			center.add(this._scene._scene_center);
			center.z = this._scene._scene_center.z +  this._scene._scene_radius - 0.2;

			var p0 = center.clone();
			p0.add(nor);
			geometry.vertices.push(p0);

			var p1 = center.clone();
			p1.sub(nor);
			geometry.vertices.push(p1);

			//*** Elevation bar */
			var line_geometry = new Geometry();
			line_geometry.vertices.push(p0.clone());
			line_geometry.vertices.push(p1.clone());

			this._elevation_scene.add(new Line( line_geometry, this._bar_material ));

			//*** Handle */
			var handle_geometry = new Geometry();

			if (niter == 0)
			{
				p0 = center.clone();
				handle_geometry.vertices.push(p0.clone());
				p0 = center.clone();
				p0.add(nor2);
				p0.sub(dir2);
				handle_geometry.vertices.push(p0.clone());
				p0 = center.clone();
				p0.sub(nor2);
				p0.sub(dir2);
				handle_geometry.vertices.push(p0.clone());
				handle_geometry.faces.push( new Face3(0,1,2));
			}
			else
			{
				p0 = center.clone();
				p0.add(nor2);
				handle_geometry.vertices.push(p0.clone());
				p0.sub(nor2);
				p0.sub(nor2);
				handle_geometry.vertices.push(p0.clone());
				p0.add(dir2);
				handle_geometry.vertices.push(p0.clone());
				p0.add(nor2);
				p0.add(nor2);
				handle_geometry.vertices.push(p0.clone());
				handle_geometry.faces.push( new Face3(0,1,2));
				handle_geometry.faces.push( new Face3(0,2,3));
			}
			
			const handle =  new Mesh( handle_geometry, this._handle_material);
			this._elevation_scene.add(handle);
			this._handles.push(handle);
		}

		geometry.faces.push( new Face3(0,1,2));
		geometry.faces.push( new Face3(2,1,3));

		var uv0 = new Vector2(0,0);
		var uv1 = new Vector2(0.9,0);
		geometry.faceVertexUvs[0].push( [uv0,uv0,uv1] );
		geometry.faceVertexUvs[0].push( [uv1,uv0,uv1] );

		//the face normals and vertex normals can be calculated automatically if not supplied above
		geometry.computeFaceNormals();
		geometry.computeVertexNormals();

		this._elevation_scene.add( new Mesh( geometry, this._plane_material) );

		//*** Add a torus for rotation */
		geometry = new TorusGeometry( this._scene._scene_radius *1.7, this._scene._scene_radius*0.15, 2, 64 );
		const torus = new Mesh(geometry, this._handle_material);
		torus.position.set(this._scene._scene_center.x,this._scene._scene_center.y,this._scene._scene_center.z);
		this._elevation_scene.add(torus);
		this._handles.push(torus);

	}

	//*****************************************************
	//**** Callbacks for navigation
	//*****************************************************

	//*****************************************************
	//*** Mouse operations
	manage_mouse_wheel(ev) {
		super.manage_mouse_wheel(ev);
		this._view_3d.call("view_change");
	}

	compute_mouse_angle(x,y) {
		const grab_position = this.read_mouse_position(x,y);
		grab_position.unproject(this._camera);
		grab_position.sub(this._scene._scene_center);
		grab_position.z = 0;
		grab_position.normalize();
		var angle = Math.acos(grab_position.x);
		if (grab_position.y<0) angle = -angle;
		return angle;
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_down(ev) {
		this._mouseover_bar = this._is_over_elevation_bar(ev);
		if (this._mouseover_bar>=0)
		{
			if (this._mouseover_bar == 2)
			{
				this._real_elevation_angle = this._elevation_angle;
				this._grab_angle = this.compute_mouse_angle(ev.clientX,ev.clientY);
			}

			this._selected_bar = this._mouseover_bar;
			this._handles[this._selected_bar].material = this._handle_mouseover_material;
			ev.preventDefault();
			ev.stopPropagation();
			this.refresh_rendering();
			return true;
		}
		if (this._handles[0]) this._handles[0].material = this._handle_material;
		if (this._handles[1]) this._handles[1].material = this._handle_material;
		if (this._handles[2]) this._handles[2].material = this._handle_material;
		return super.manage_mouse_down(ev);
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_move(ev) {

		//*** Dragging */
		if (ev.buttons)
		{
			if (this._selected_bar>=0)
			{
				var mouse_position = this.read_mouse_position(ev.clientX,ev.clientY);
				mouse_position.unproject(this._camera);
				if (this._selected_bar < 2)
				{
					var angle = (this._elevation_angle+90)*Math.PI/180;
					this.set_elevation_position(this._selected_bar,Math.cos(angle) * mouse_position.x + Math.sin(angle) * mouse_position.y);
				}
				else
				{
					const angle = this.compute_mouse_angle(ev.clientX,ev.clientY);
					while (this._grab_angle - angle > Math.PI) this._grab_angle -= 2*Math.PI;
					while (this._grab_angle - angle < -Math.PI) this._grab_angle += 2*Math.PI;
					this._real_elevation_angle += (angle - this._grab_angle) * 180 / Math.PI;
					this.set_elevation_angle(5*Math.round(this._real_elevation_angle/5));
					this._grab_angle = angle;
					this._view_3d.call("elevation_angle_change");
				}
				if (this.on_change) this.on_change();
				ev.preventDefault();
				ev.stopPropagation();
				this.refresh_rendering();
				this._view_3d.call("view_change");
				return true;
			}
			return super.manage_mouse_move(ev);
		}

		//*** Passive move */
		this._mouseover_bar = this._is_over_elevation_bar(ev);
		if (this._mouseover_bar>=0)
		{
			this._handles[this._mouseover_bar].material = this._handle_mouseover_material;
			ev.preventDefault();
			ev.stopPropagation();
			this.refresh_rendering();
			return true;
		}
		if (this._handles[0]) this._handles[0].material = this._handle_material;
		if (this._handles[1]) this._handles[1].material = this._handle_material;
		if (this._handles[2]) this._handles[2].material = this._handle_material;
		return super.manage_mouse_move(ev);
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_up(ev) {
		this._selected_bar = -1;
		if (this._handles[0]) this._handles[0].material = this._handle_material;
		if (this._handles[1]) this._handles[1].material = this._handle_material;
		if (this._handles[2]) this._handles[2].material = this._handle_material;
		return super.manage_mouse_up(ev);
	}

	//***********************************************************************************
	//**** returns trus if mouse is over the plane
	_is_over_elevation_bar(ev) {
		if (this._elevation_scene == null) return -1;
		var mouse_position = this.read_mouse_position(ev.clientX,ev.clientY);
		this.set_raycaster(mouse_position);
		var intersects = this._raycaster.intersectObjects(this._handles, true);
		if (intersects.length == 0) return -1;
		return this._handles.indexOf(intersects[0].object);
	}

	//*****************************************************
	//*** camera reset
	reset_camera () {
		if (this._scene._scene_center == null) return;

		var dir = this.get_elevation_dir();
		var radius = this._scene._scene_radius;
		for (var niter=0;niter<2;niter++)
		{
			var dst = 2*Math.abs(dir.dot(this._scene._scene_center) - this.get_elevation_position(niter));
			if (dst > radius) radius = dst;
		}

		this.start_animation();

		this._end_position.copy(this._scene._scene_center);
		this._end_position.z += this._scene._scene_radius;
		this._end_target.copy(this._scene._scene_center);
		this._end_scale = radius*2;

		this.refresh_rendering();
	}

	//***********************************************************************************
	//**** Geometry rendering
	render_geometry() {

		super.render_geometry();

		if (this._elevation_scene == null || this._render_angle != this._elevation_angle || this._render_positions[0] != this._elevation_positions[0] || this._render_positions[1] != this._elevation_positions[1] || this._render_scale != this._camera.scene_scale)
		{
			this._render_angle = this._elevation_angle;
			this._render_positions[0] = this._elevation_positions[0];
			this._render_positions[1] = this._elevation_positions[1];
			this._render_scale = this._camera.scene_scale;
			this.update_elevation_geometry();
		}
		if (this._elevation_scene)
			this._renderer.render(this._elevation_scene, this._camera);
	}
}
