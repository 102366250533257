import { cnx_size, cnx_sub } from '../cn_utilities';

import { cn_building } from '../../model/cn_building';
import { cn_facade_lineics } from '../../model/cn_facade_lineics';
import {
    cn_nomenclature,
    NOMENCLATURE_COLUMNS_ID_FACADE_AREA,
    NOMENCLATURE_COLUMNS_ID_FACADE_BORDER_ANGLE,
    NOMENCLATURE_COLUMNS_ID_FACADE_DOOR_LEDGE,
    NOMENCLATURE_COLUMNS_ID_FACADE_EMBRASURE,
    NOMENCLATURE_COLUMNS_ID_FACADE_FULL_AREA,
    NOMENCLATURE_COLUMNS_ID_FACADE_HIGH_LINE,
    NOMENCLATURE_COLUMNS_ID_FACADE_JOINING_ANGLE,
    NOMENCLATURE_COLUMNS_ID_FACADE_LOW_LINE,
    NOMENCLATURE_COLUMNS_ID_FACADE_OPENING_AREA,
    NOMENCLATURE_COLUMNS_ID_FACADE_WINDOW_LEDGE,
    NOMENCLATURE_COLUMNS_ID_FACING,
    NOMENCLATURE_COLUMNS_ID_FACING_ID
} from '../cn_nomenclature';
import { logger } from '../cn_logger';

/**
 * Builds facade nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_facade(building) {
    building.update_roofs();
    building.compute_altitudes();
    populateFacingAreas(building);

    const facadeMainFacings = (building.storeys || [])
        .flatMap(storey => storey.get_walls())
        .flatMap(w => w.facings || [])
        .filter(f => f?.support === 'facade');
    const facadeTrimmingFacings = (building.facing_trimmings || [])
        .filter(ft => ft.facing && ft.facing.support === 'facade')
        .map(ft => ft.facing);
    const usedFacingIds = Array.from(new Set([...facadeMainFacings, ...facadeTrimmingFacings].map(f => f.ID)));

    const output = [];

    const facadesLineics = usedFacingIds
        .map(id => cn_facade_lineics.build_for_facing(building, building.facing_types.find(f => f.ID === id))).flat();

    const facadeCol = new cn_nomenclature('Façade');
    output.push(facadeCol);
    facadeCol.values = facadesLineics.map(facadeLineics => facadeLineics.label);

    const facingCol = new cn_nomenclature('Revêtement').withId(NOMENCLATURE_COLUMNS_ID_FACING);
    output.push(facingCol);
    facingCol.values = facadesLineics.map(facadeLineics => facadeLineics.facing.name);

    const facingIdCol = new cn_nomenclature('ID').withId(NOMENCLATURE_COLUMNS_ID_FACING_ID).withVirtual(true);
    output.push(facingIdCol);
    facingIdCol.values = facadesLineics.map(facadeLineics => facadeLineics.facing.ID);

    const areaCol = new cn_nomenclature('Surface nette (hors ouvrant)').withId(NOMENCLATURE_COLUMNS_ID_FACADE_AREA).withNumberDefinition('m²', 2);
    output.push(areaCol);
    areaCol.values = facadesLineics.map(facadeLineics => facadeLineics.area);

    const fullAreaCol = new cn_nomenclature('Surface brute').withId(NOMENCLATURE_COLUMNS_ID_FACADE_FULL_AREA).withNumberDefinition('m²', 2);
    output.push(fullAreaCol);
    fullAreaCol.values = facadesLineics.map(facadeLineics => facadeLineics.full_area);

    // calcul des cornières d'angles
    const joiningAngleCol = new cn_nomenclature(`Baguette d'angle`).withId(NOMENCLATURE_COLUMNS_ID_FACADE_JOINING_ANGLE).withNumberDefinition('m', 2);
    joiningAngleCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.corner));
    output.push(joiningAngleCol);

    // calcul des longueurs d'arrêt latéral
    const borderAngleCol = new cn_nomenclature(`Profilé d'arrêt latéral`).withId(NOMENCLATURE_COLUMNS_ID_FACADE_BORDER_ANGLE).withNumberDefinition('m', 2);
    borderAngleCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.vertical));
    output.push(borderAngleCol);

    // calcul des longueurs d'arrêt haut
    const highLineCol = new cn_nomenclature('Profilé d\'arrêt haut').withId(NOMENCLATURE_COLUMNS_ID_FACADE_HIGH_LINE).withNumberDefinition('m', 2);
    highLineCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.upper));
    output.push(highLineCol);

    // calcul ligne basse
    const lowerLineCol = new cn_nomenclature('Rail de départ (hors ouvrant)').withId(NOMENCLATURE_COLUMNS_ID_FACADE_LOW_LINE).withNumberDefinition('m', 2);
    lowerLineCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.bearing));
    output.push(lowerLineCol);

    // calcul embrasures (tour d'ouvrant sans le bas)
    const embrasureCol = new cn_nomenclature('Embrasures').withId(NOMENCLATURE_COLUMNS_ID_FACADE_EMBRASURE).withNumberDefinition('m', 2);
    embrasureCol.values = facadesLineics.map(facadeLineics =>
        (sumAllVectorLengths(facadeLineics.upper_opening) + sumAllVectorLengths(facadeLineics.vertical_opening)),
    );
    output.push(embrasureCol);

    //*** surfaces de tableau */
    const openingAreaCol = new cn_nomenclature('Surface de tableau').withId(NOMENCLATURE_COLUMNS_ID_FACADE_OPENING_AREA).withNumberDefinition('m²', 2);
    output.push(openingAreaCol);
    openingAreaCol.values = facadesLineics.map(facadeLineics => facadeLineics.get_opening_depth_area());

    // calcul embrasures (tour d'ouvrant sans le bas)
    const doorLedgeCol = new cn_nomenclature('Appuis de porte').withId(NOMENCLATURE_COLUMNS_ID_FACADE_DOOR_LEDGE).withNumberDefinition('m', 2);
    doorLedgeCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.lower_door));
    output.push(doorLedgeCol);

    // calcul embrasures (tour d'ouvrant sans le bas)
    const windowLedgeCol = new cn_nomenclature('Appuis de fenêtre').withId(NOMENCLATURE_COLUMNS_ID_FACADE_WINDOW_LEDGE).withNumberDefinition('m', 2);
    windowLedgeCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.lower_opening));
    output.push(windowLedgeCol);

    logger.log('Used facings: ', get_used_facings_from_facade_nomenclature(output));
    return output;
}

/**
 * Returns the list of used facings, from the facade nomenclatures
 * @param {cn_nomenclature[]} nomenclatures
 * @return {{id: string, name: string, area: number}[]}
 */
export function get_used_facings_from_facade_nomenclature(nomenclatures) {
    const facade_list = [];
    const nomenclature_id = nomenclatures.find(nomenclature => nomenclature.id == NOMENCLATURE_COLUMNS_ID_FACING_ID);
    const nomenclature_name = nomenclatures.find(nomenclature => nomenclature.id == NOMENCLATURE_COLUMNS_ID_FACING);
    const nomenclature_area = nomenclatures.find(nomenclature => nomenclature.id == NOMENCLATURE_COLUMNS_ID_FACADE_FULL_AREA);
    if (!nomenclature_id || !nomenclature_name || !nomenclature_area) return facade_list;

    for (let i = 0; i < nomenclature_id.values.length; i++) {
        let facade = facade_list.find(facade => facade.id == nomenclature_id.values[i]);
        if (!facade) {
            facade = { id: nomenclature_id.values[i], name: nomenclature_name.values[i], area: 0 }
            facade_list.push(facade);
        }
        facade.area += nomenclature_area.values[i];
    }
    return facade_list;
}

/**
 * populate facing_areas for each wall
 * @param {cn_building} building
 */
function populateFacingAreas(building) {
    // we compute the facing areas for all walls of all storey
    building.storeys.forEach(storey => {
        storey.scene.storey = storey;
        storey.scene.update_deep();
        // We compute the storey volume
        if (!storey.exterior) storey.build_roof_volume(true);

        return storey.scene.walls.forEach(wall => {
            for (let side = 0; side < 2; side++) {
                wall.facing_areas[side] = 0;
                const facingPolygons = wall.build_facing_polygons(side, storey);
                facingPolygons
                    .filter(p => !p['facing_trimming'])
                    .forEach(p => wall.facing_areas[side] += p.get_area());
            }
        });
    });
}

/**
 * Sum all vectors
 * @param points{number[][]}: pairs of triplets (each pair represents 2 point forming a vector)
 */
function sumAllVectorLengths(points) {
    let length = 0;
    for (let i = 0; i < points.length; i += 2) {
        length += cnx_size(cnx_sub(points[i], points[i + 1]));
    }
    return length;
}
