'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Balconies nomenclature
//***********************************************************************************
//***********************************************************************************

import { cn_nomenclature, find_zone, push_cn_nomenclatures_zones } from '../cn_nomenclature';
import { cn_azimut, cn_dist, cn_mul } from '../cn_utilities';
import { cn_building } from '../../model/cn_building';
import { extension_instance } from '../../extension/cn_extension';
import { cn_zone } from '../../model/cn_zone';
import { cn_storey } from '../../model/cn_storey';

//***********************************************************************************
/**
 * Buils wall nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_balconies(building) {
    const output = [];
    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Niveau'));
    push_cn_nomenclatures_zones(output, true);
    output.push(new cn_nomenclature('Espace 1'));
    output.push(new cn_nomenclature('Espace 2'));
    output.push(new cn_nomenclature('Orientation').withNumberDefinition('°', 0));
    output.push(new cn_nomenclature('Longueur 1').withNumberDefinition('m', 3));
    output.push(new cn_nomenclature('Longueur 2').withNumberDefinition('m', 3));
    output.push(new cn_nomenclature('Hauteur').withNumberDefinition('m', 3));
    output.push(new cn_nomenclature('Epaisseur').withNumberDefinition('m', 3));
    output.push(new cn_nomenclature('Surface 1').withNumberDefinition('m²', 3));
    output.push(new cn_nomenclature('Surface 2').withNumberDefinition('m²', 3));

    const zones = building.zones;
    building.storeys.length && building.storeys.forEach((storey) => {
        populate_nomenclature_balconies(storey, output, zones);
    });

    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 /**
 * Builds wall nomenclatures for the given storey
 * @param {cn_storey} storey
 * @param {any[]} output
 * @param {{[property: string]: cn_zone[]}} zones
 */
function populate_nomenclature_balconies(storey, output, zones) {
    const roof_volume = storey.build_roof_volume();
    storey.scene.walls.filter(wall => wall.balcony).forEach((wall) => {
        const balcony_type = wall.wall_type;
        let k = 0;

        //*** name */
        output[k].values.push(balcony_type.get_label());
        k++

        //*** Type */
        output[k].values.push(balcony_type.get_category_label());
        k++

        //*** storey */
        let storey_name = storey.storey_index;
        output[k].values.push(storey_name);
        k++

        const s0 = (wall.get_flow_direction()) ? 0 : 1;
        const s1 = 1 - s0;

        //*** Zone */
        extension_instance.zone.get_zones_tools().forEach(tool_zone => {
            let zone_to_display = find_zone(zones[tool_zone.property], wall.spaces[s0].ID, storey.ID);
            if (!zone_to_display) {
                zone_to_display = find_zone(zones[tool_zone.property], wall.spaces[s1].ID, storey.ID);
            }
            output[k].values.push(zone_to_display);
            k++;
        });

        //*** Space 1 */
        output[k].values.push(wall.spaces[s0].get_name(storey));
        k++

        //*** Space 2 */
        output[k].values.push(wall.spaces[s1].get_name(storey));
        k++

        //*** orientation */
        var flow_normal = cn_mul(wall.bounds.normal, (s0 == 0) ? 1 : -1);
        output[k].values.push(cn_azimut(flow_normal, storey.building.compass_orientation));
        k++

        const length = [0, 0];
        for (var sside = 0; sside < 2; sside++) {
            const side = (sside == 0) ? s0 : s1;

            //*** Wall geometry */
            const l = cn_dist(wall.measure_points[side][0], wall.measure_points[side][1]);
            length[sside] = l;

            //*** Width  */
            output[k].values.push(l);
            k++
        }

        //*** height  */
        output[k].values.push(balcony_type.height);
        k++

        //*** thickness  */
        output[k].values.push(balcony_type.thickness);
        k++

        //*** full area  */
        output[k].values.push(length[0] * balcony_type.height);
        k++
        output[k].values.push(length[1] * balcony_type.height);
        k++
    });
}
