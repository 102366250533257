'use strict';

import { cn_camera, cn_contour, cn_element } from '..';
import { cn_space_of_elements_visitor } from '../utils/visitors/impl/cn_space_of_elements_visitor';

export class cn_layer extends cn_element {
    //*******************************************************
    /**
     * Constructor
     */
    constructor(name = '') {
        super();
        this.name = name;
        this.color = '';
        this.stripes = 'full';
        this.elements = [];
        this.elements_types = [];
        this.sections = [];
        this.lines = [];
        this.description = '';
    }

    //*******************************************************
    /**
     * Add element to layer
     * @param {string} id_element
     * @param {string} id_storey
     * @param {string} support
     */
    add_element(id_element, id_storey, support = 'work') {
        if (!this.has_element(id_element, id_storey, support)) {
            this.elements.push({ obj: id_element, storey: id_storey, support: support });
        }
    }

    //*******************************************************
    /**
     * Add element type to layer
     * @param {string} id_element_type
     */
    add_element_type(id_element_type) {
        if (!this.has_element_type(id_element_type)) {
            this.elements_types.push(id_element_type);
        }
    }

    //*******************************************************
    /**
     * Add section to layer
     * @param {cn_contour} shape
     * @param {string} id_storey
     * @param {string} support
     * @param {string} id_slab
     * @param {number} order
     */
    add_section(shape, id_storey, support = 'floor', id_slab, order = 1) {
        this.sections.push({ shape, storey: id_storey, support, obj: id_slab || '', order });
    }

    //*******************************************************
    /**
     * Add line to layer
     * @param {cn_contour} shape
     * @param {Array} line
     * @param {string} id_storey
     * @param {string} support
     * @param {string} obj
     * @return {object}
     * @param {number} order
     */
    add_line(shape, line, id_storey, support = 'floor', obj, order = 1) {
        const line_section = { shape, line, storey: id_storey, support, obj: obj || '', order };
        this.lines.push(line_section);
        return line_section;
    }

    //*******************************************************
    /**
     * Remove element from layer
     * @param {string} id_element
     * @param {string} id_storey
     * @param {string} support
     */
    remove_element(id_element, id_storey, support) {
        const el_index = this.elements.findIndex(el => el.obj === id_element && el.storey === id_storey && el.support === support);
        if (el_index >= 0) {
            this.elements.splice(el_index, 1);
        }
    }

    //*******************************************************
    /**
     * Remove element type from layer
     * @param {string} id_element_type
     */
    remove_element_type(id_element_type) {
        const el_index = this.elements_types.indexOf(id_element_type);
        if (el_index >= 0) {
            this.elements_types.splice(el_index, 1);
        }
    }

    //*******************************************************
    /**
     * Does layer has element
     * @param {string} id_element
     * @param {string} id_storey
     * @param {string} support
     */
    has_element(id_element, id_storey, support) {
        return this.elements.find(el => el.obj === id_element && el.storey === id_storey && el.support === support);
    }

    //*******************************************************
    /**
     * Does layer has element type
     * @param {string} id_element_type
     */
    has_element_type(id_element_type) {
        return this.elements_types.includes(id_element_type);
    }

    //*******************************************************
    /**
     * Draw layer
     * @param {cn_camera} camera
     * @param {Array<string>} extra
     * @returns {string} html
     */
    draw(camera, extra) {
        let html = ''
        return html;
    }

    /**
     * Draw all layer sections
     *
     * @param {cn_camera} camera
     * @param {string} storey
     * @param {Map} fill_refferer
     */
    draw_sections(camera, storey, fill_refferer) {
        let html = '';
        this.sections.filter(section => section.storey === storey).forEach(section => {
            const shape = section.shape;
            html += `<path  d="`;
            shape.inner_contour.forEach((contour, i) => {
                if (i === 0) {
                    html += 'M ';
                } else if (i === 1) {
                    html += 'L ';
                }
                const p = camera.world_to_screen(contour);
                html += `${p[0]} ${p[1]} `;
            });
            html += 'Z "';
            html += ` ${fill_refferer.get(shape.ID) || ''} />`
        });
        return html;
    }

    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize(building) {
        const json = {};
        json.ID = this.ID;
        json.name = this.name;
        json.elements = [...this.elements];
        json.elements_types = [...this.elements_types];
        json.color = this.color;
        json.stripes = this.stripes;
        json.spaces = [];
        json.description = this.description;
        json.sections = [...this.sections.map(section => {
            return {
                shape: section.shape.serialize(),
                storey: section.storey,
                support: section.support,
                obj: section.obj,
                order: section.order || 1
            }
        })];
        json.lines = [...this.lines.map(line => {
            return {
                shape: line.shape.serialize(),
                line: [...line.line],
                storey: line.storey,
                support: line.support,
                obj: line.obj,
                order: line.order || 1
            }
        })];
        json.spaces = this._generate_spaces(building);
        return json;
    }

    _generate_spaces(building) {
        const spaces = [];
        const impacted_storeys = this.elements.map(el => el.storey).concat(this.sections.map(section => section.storey))
            .concat(this.lines.map(line => line.storey));
        const impacted_elements = this.elements.map(el => {
            return { obj: el.obj, storey: el.storey }
        })
            .concat(this.sections.filter(section => !!section.obj).map(section => {
                return { obj: section.obj, storey: section.storey }
            }))
            .concat(this.lines.filter(line => !!line.obj).map(line => {
                return { obj: line.obj, storey: line.storey }
            }));
        building.storeys.filter(storey => impacted_storeys.includes(storey.ID))
            .forEach(storey => {
                const scene = storey.scene;
                const storey_impacted_elements = impacted_elements.filter(el => el.storey === storey.ID).map(el => el.obj);
                const visitor = new cn_space_of_elements_visitor(storey);
                [
                    ...scene.walls,
                    ...scene.slabs,
                    ...scene.stairs,
                    ...scene.object_instances,
                    ...scene.columns,
                    ...scene.beams,
                    ...scene.pipes,
                    ...scene.walls.reduce((a, wall) => a.concat(wall.openings), [])
                ].filter(el => storey_impacted_elements.includes(el.ID))
                    .forEach(el => el.accept_visitor(visitor));
                spaces.push(...visitor.spaces);
            });
        return spaces;
    }

    //*******************************************************
    /**
     * unserialize
     * @returns {object} layer object
     */
    static unserialize(json, storeys) {
        const result = new cn_layer(json.name);
        result.ID = json.ID;
        result.elements = [...(json.elements || [])];
        result.elements_types = [...(json.elements_types || [])];
        result.color = json.color || '';
        result.stripes = json.stripes || '';
        result.sections = [...(json.sections || [])];
        result.description = json.description;
        if (json.sections) {
            result.sections = [...json.sections.map(section => {
                return {
                    shape: cn_contour.unserialize(section.shape, storeys.find(storey => storey.ID === section.storey)),
                    storey: section.storey,
                    support: section.support,
                    obj: section.obj,
                    order: section.order || 1
                }
            })];
        } else {
            result.sections = [];
        }
        if (json.lines) {
            result.lines = [...json.lines.map(line => {
                return {
                    shape: cn_contour.unserialize(line.shape, storeys.find(storey => storey.ID === line.storey)),
                    line: [...line.line],
                    storey: line.storey,
                    support: line.support,
                    obj: line.obj,
                    order: line.order || 1
                }
            })];
        }
        return result;
    }
}
