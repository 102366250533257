'use strict';
//***********************************************************************************
//***********************************************************************************
import { cn_add } from '../utils/cn_utilities';
import { cn_pipe_handler } from './cn_pipe_handler';
import { cn_svg_tool_edition } from './cn_svg_tool_edition';
import { cn_pipe } from '../model/cn_pipe';
import { cn_svg_map } from './cn_svg_map';

/**
 * @class cn_svg_tool_pipe_edition
 * A class to manipulate pipe and columns

 */
export class cn_svg_tool_pipe_edition extends cn_svg_tool_edition {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} map
     */
    constructor(map) {
        super(map);
    }

    //***********************************************************************************
    //**** Selection callback
    //***********************************************************************************
    on_selection_change() {
        const obj = this;
        this._handlers = [];

        //*** We expect the selection to contain only openings */
        var sel = this._controller.get_selection();
        if (sel.length == 0 || sel.some(elt => elt.constructor != cn_pipe))
            return false;

        this._handlers.push(new cn_pipe_handler(sel, this._map));
        return true;
    }

    //***********************************************************************************
    //**** Group translation
    //***********************************************************************************
    translate(ev, offset) {
        var selection = this._controller.get_selection();
        for (var i in selection) {
            var elt = selection[i];
            if (elt.constructor == cn_pipe) {
                this.push_item_set(elt, 'vertices');
                for (var k in elt.vertices)
                    elt.vertices[k] = cn_add(elt.vertices[k], offset);
            }
        }
    }
}

