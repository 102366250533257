'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** A contour for roofs
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_vertex } from './cn_vertex';
import { cn_add, cn_box, cn_dist, cn_dot, cn_mul, cn_normal, cn_normalize, cn_sub } from '../utils/cn_utilities';
import { logger } from '../utils/cn_logger';

export class cn_roof_contour extends cn_element {
    constructor() {
        super();
        this.slab = null;
        this.lines = [];
        this.line_orientations = [];
        this.vertices = [];
        this.perimeter = 0;
        this.removable = false;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.lines = [];
        json.line_orientations = [];
        json.vertices = [];
        for (var i in this.lines)
            json.lines.push(this.lines[i].s_index);
        for (var i in this.line_orientations)
            json.line_orientations.push(this.line_orientations[i]);
        for (var i in this.vertices) {
            if (typeof (this.vertices[i].s_index) != 'undefined')
                json.vertices.push(this.vertices[i].s_index);
            else
                json.vertices.push(this.vertices[i].serialize());
        }
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json.lines) != 'object') return false;
        if (typeof (json.line_orientations) != 'object') return false;
        if (typeof (json.vertices) != 'object') return false;

        var contour = new cn_roof_contour();
        if (typeof (json.ID) == 'string') contour.ID = json.ID;
        for (var i in json.lines) {
            const line = scene.lines[json.lines[i]];
            if (line) {
                contour.lines.push(line);
            }
        }
        for (var i in json.line_orientations)
            contour.line_orientations.push(json.line_orientations[i]);
        for (var i in json.vertices) {
            if (typeof (json.vertices[i]) == 'number')
                contour.vertices.push(scene.vertices[json.vertices[i]]);
            else
                contour.vertices.push(new cn_vertex(json.vertices[i]));
        }
        return contour;
    }

    up_to_date_2d(date) {
        if (this.lines.find(line => !line.up_to_date_2d(date))) return false;
    }

    //***********************************************************************************
    //**** update a contour
    //***********************************************************************************
    update() {
        this.perimeter = 0;
        var directions = [];

        for (var i = 0; i < this.vertices.length; i++) {
            var v0 = this.vertices[i].position;
            var v1 = (i < this.vertices.length - 1) ? this.vertices[i + 1].position : this.vertices[0].position;
            this.perimeter += cn_dist(v0, v1);
            directions.push(cn_sub(v1, v0));
        }

        //*** Compute bounds */
        this.bounds_min = [0, 0];
        this.bounds_max = [0, 0];
        for (var ii = 0; ii < this.vertices.length; ii++) {
            var pr = this.vertices[ii].position;
            if (ii == 0) {
                this.bounds_min[0] = pr[0];
                this.bounds_min[1] = pr[1];
                this.bounds_max[0] = pr[0];
                this.bounds_max[1] = pr[1];
                continue;
            }
            if (pr[0] < this.bounds_min[0]) this.bounds_min[0] = pr[0];
            if (pr[1] < this.bounds_min[1]) this.bounds_min[1] = pr[1];
            if (pr[0] > this.bounds_max[0]) this.bounds_max[0] = pr[0];
            if (pr[1] > this.bounds_max[1]) this.bounds_max[1] = pr[1];
        }

        //*** Compute area and clockwise */
        this.area = 0;
        var v0 = this.vertices[this.vertices.length - 1].position;
        for (var j = 0; j < this.vertices.length; j++) {
            var v1 = this.vertices[j].position;
            this.area += (v0[0] + v1[0]) * (v1[1] - v0[1]);
            v0 = v1;
        }
        this.clockwise = (this.area < 0);
        this.area = Math.abs(this.area) * 0.5;

        if (this.vertices.length <= 2)
            this.clockwise = false;
    }

    //***********************************************************************************
    //**** Follow a contour
    //***********************************************************************************
    follow(vertex, line) {
        this.lines = [];
        this.line_orientations = [];
        this.vertices = [];
        while (true) {
            this.perimeter += cn_dist(line.vertices[0].position, line.vertices[1].position);
            this.lines.push(line);
            this.vertices.push(vertex);

            //*** search for next vertex,
            var new_vertex;
            if (vertex == line.vertices[0]) {
                this.line_orientations.push(true);
                new_vertex = line.vertices[1];
            } else {
                this.line_orientations.push(false);
                new_vertex = line.vertices[0];
            }

            vertex = new_vertex;

            //*** search for next line
            line = vertex.next_line(line);

            //*** end condition : back to start
            if (this.lines[0] == line && this.vertices[0] == vertex) break;
        }

        this.update();
    }

    //***********************************************************************************
    //**** Check a contour
    //***********************************************************************************
    check() {
        var vertex = this.vertices[this.vertices.length - 1];
        var line = this.lines[this.lines.length - 1];
        logger.log('checking contour', this);
        this.lines.forEach(l => logger.log('line', this.vertices.indexOf(l.vertices[0]), this.vertices.indexOf(l.vertices[1])));
        for (var i in this.lines) {
            //*** search for next vertex,
            var new_vertex;
            if (vertex == line.vertices[0])
                new_vertex = line.vertices[1];
            else
                new_vertex = line.vertices[0];

            vertex = new_vertex;
            if (vertex != this.vertices[i]) {
                logger.log('Checking contour : vertex error at ' + i + ' : ' + vertex + ' - ' + this.vertices[i]);
                return false;
            }
            //*** search for next line
            line = vertex.next_line(line);
            if (line != this.lines[i]) {
                logger.log('Checking contour : line error at ' + i + ' : ' + line + ' - ' + this.lines[i]);
                return false;
            }
        }

        return true;
    }

    //***********************************************************************************
    //**** Does contour contains this couple ?
    //***********************************************************************************
    contains_couple(vertex, line) {
        for (var i = 0; i < this.lines.length; i++) {
            if (this.lines[i] == line && this.vertices[i] == vertex) return true;
        }
        return false;
    }

    //***********************************************************************************
    //**** Does contour contains the point ?
    //***********************************************************************************
    contains(p) {
        var epsilon = 0.001;
        if (p[0] <= this.bounds_min[0] - epsilon) return false;
        if (p[1] <= this.bounds_min[1] - epsilon) return false;
        if (p[0] >= this.bounds_max[0] + epsilon) return false;
        if (p[1] >= this.bounds_max[1] + epsilon) return false;

        var nleft = 0;
        var nright = 0;
        var l = this.vertices.length;

        var sense = 0;
        var o = 0;
        for (; o < l; o++) {
            var v0 = this.vertices[o].position;
            if (v0[1] > p[1] || v0[1] < p[1]) break;
        }

        for (var i = 0; i < l; i++) {
            var v0 = this.vertices[(i + o) % l].position;
            var v1 = this.vertices[(i + o + 1) % l].position;
            if (v0[1] > p[1] && v1[1] > p[1]) continue;
            if (v0[1] < p[1] && v1[1] < p[1]) continue;
            if ((v0[1] < p[1] && v1[1] > p[1]) || (v0[1] > p[1] && v1[1] < p[1])) {
                var coef = (p[1] - v0[1]) / (v1[1] - v0[1]);
                var x = v0[0] * (1 - coef) + v1[0] * coef;
                if (x >= p[0]) nright++;
                else {
                    nleft++;
                }
                continue;
            }
            if (v0[1] == p[1] && v1[1] == p[1]) continue;
            var x = 0;
            var new_sense = 0;
            if (v1[1] == p[1]) {
                x = v1[0];
                new_sense = (v0[1] > p[1]) ? 1 : -1;
            } else {
                x = v0[0];
                new_sense = (v1[1] < p[1]) ? 1 : -1;
            }
            if (sense == new_sense) {
                sense = 0;
                continue;
            }
            if (sense == -new_sense)
                sense = 0;
            else
                sense = new_sense;
            if (x >= p[0]) nright++;
            else {
                nleft++;
            }
        }

        if (nleft & 1) return true;
        return false;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contained_by_box(b) {
        for (var i in this.vertices) {
            if (!b.contains_point(this.vertices[i].position)) return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** get box
    //***********************************************************************************
    get_bounding_box() {
        var box = new cn_box();
        for (var i in this.vertices)
            box.enlarge_point(this.vertices[i].position);
        return box;
    }

    //***********************************************************************************
    //**** Build 3d contour
    //***********************************************************************************
    build_3d_contour(z) {
        var vertices = [];
        for (var i in this.vertices)
            vertices.push([this.vertices[i].position[0], this.vertices[i].position[1], z]);
        return vertices;
    }

    //***********************************************************************************
    //**** raytrace
    //***********************************************************************************
    raytrace(origin, direction, max_distance) {
        var res = null;
        var vertices = this.vertices;
        var l = vertices.length;
        for (var i = 0; i < l; i++) {
            var v0 = vertices[i].position;
            var v1 = vertices[(i + 1) % l].position;
            var dir = cn_sub(v1, v0);
            var length = cn_normalize(dir);
            if (length < 0.001) continue;
            var nor = cn_normal(dir);
            var x = cn_dot(direction, nor);
            if (Math.abs(x) < 0.0001) continue;
            var lambda = cn_dot(nor, cn_sub(v0, origin)) / x;
            if (lambda <= 0.0001) continue;
            if (lambda >= max_distance) continue;
            var p = cn_add(origin, cn_mul(direction, lambda));
            var e = cn_dot(dir, cn_sub(p, v0));
            if (e < 0) continue;
            if (e > length) continue;
            res = { 'distance': lambda, 'point': cn_add(v0, cn_mul(dir, e)), 'contour': this, 'contour_position': e };
            max_distance = lambda;
        }
        return res;
    }

    //***********************************************************************************
    //**** Sets outside :
    //***********************************************************************************
    set_outside() {
        for (var i = 0; i < this.lines.length; i++) {
            var s = (this.line_orientations[i]) ? 0 : 1;
            this.lines[i].slabs[s] = null;
            this.lines[i].contours[s] = null;
        }
    }
}
