'use strict';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Abstract class for element types.
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_configuration } from './cn_configuration';
import { cn_element_type_visitor } from '../utils/visitors/cn_element_type_visitor';

export class cn_element_type extends cn_element {

    constructor() {
        super();
        /**
         * @type {string}
         */
        this.name;

        /**
         * @type {string}
         */
        this.class_name = '';
    }

    //***********************************************************************************
    /**
     * Clone
     * @returns {cn_element_type}
     */
    clone() {
        return new cn_element_type();
    }

    //***********************************************************************************
    /**
     * returns displayable label (e.g. "mur générique 30cm")
     * @returns {string}
     */
    get_label() {
        return '';
    }

    //***********************************************************************************
    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        return [];
    }

    //***********************************************************************************
    /**
     * returns displayable label for type (e.g. "type de mur")
     * @returns {string}
     */
    get_generic_label() {
        return '';
    }

    //***********************************************************************************
    /**
     * draw svg icon
     * @param {number} width
     * @param {number} height
     * @returns {string}
     */
    draw_svg_icon(width, height) {
        return '';
    }

    /**
     * Fill configuration. If input config is null, must return the configuration
     * @param config
     * @return {cn_configuration}
     */
    fill_configuration(config = null) {
        return new cn_configuration();
    }

    /**
     * Loads data from configuration and fills the element type
     * @param {object} configuration
     * @param {boolean} actual_change
     * @returns {boolean} true on success
     */
    load_configuration(configuration, actual_change = false) {
        return false;
    }

    model_keys() {
        return [];
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        throw Error('Not implemented : accept_visitor');
        // To be implémenter by subclasses
    }
}

