import { ProjectStep } from './project-step';

export class ProjectStage {
  name: string;
  steps: ProjectStep[];
  min: number;
  max: number;
  hideInMenu: boolean;

  constructor(name = '') {
    this.name = name;
  }

  setSteps(steps: ProjectStep[]): ProjectStage {
    this.steps = [...steps].sort((a, b) => a.num - b.num);
    if (this.steps.length) {
      this.min = this.steps[0].num;
      this.max = this.steps[this.steps.length - 1].num;
    }
    return this;
  }
}
