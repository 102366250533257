'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2023 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_roof_dormer :
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import {
    cnx_add,
    cnx_clone,
    cn_add,
    cn_box,
    cn_clone,
    cn_dist,
    cn_dot,
    cn_middle,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_project_segment,
    cn_sub,
    cnx_cross,
    cn_contour_contains,
    cn_intersect_line,
    cnx_mul
} from '../utils/cn_utilities';
import { cn_roof_contour } from './cn_roof_contour';
import { cn_pastille } from '../svg/cn_pastille';
import { fh_add, fh_box, fh_mul, fh_polygon, fh_solid } from '@enerbim/fh-3d-viewer';
import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';
import { cn_camera } from '../svg/cn_camera';
import { cn_vertex } from './cn_vertex';
import { CN_INNER, CN_MIDDLE, CN_OUTER, cn_wall } from './cn_wall';
import { cn_opening } from './cn_opening';

export class cn_roof_dormer extends cn_element {
    constructor(scene) {
        super(scene);
        //*** Constant data
        this.draw_priority = 2;

        //*** Serialized data
        this.position = [0, 0];
        this.width = 1.5;
        this.height = 1.5;
        this.slopes = 2;
        this.slope_angle = 0;
        this.overhang_front = 0;
        this.overhang_side = 0;

        //*** Wall data */
        this.wall_type = scene.building.get_wall_types()[0];
        this.wall_vertices = [];
        this.walls = [];
        for (var n = 0; n < 4; n++) {
            this.wall_vertices.push(new cn_vertex([0, 0]));
            if (n > 0) {
                const wall = new cn_wall(this.wall_vertices[n - 1], this.wall_vertices[n], this.wall_type, 1, scene);
                wall.dormer = this;
                this.walls.push(wall);
            }
        }

        this.opening_relative = true;
        this.opening_offset = 0.4;

        //*** volatile data
        this.valid = false;
        this.slab = null;
        this.vertices = [];
        this.vertices_overhang = [];
        this.update_date = 0;
        this.dx = [1, 0];
        this.dy = [0, 1];
        this.length = 1;
        this.actual_height = this.height;
        this.clipping_volumes = [null, null];
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.position = cnx_clone(this.position);
        json.width = this.width;
        json.height = this.height;
        json.slopes = this.slopes;
        json.slope_angle = this.slope_angle;
        json.overhang_front = this.overhang_front;
        json.overhang_side = this.overhang_side;
        json.wall_type = (this.wall_type) ? this.wall_type.ID : '';

        json.opening_relative = this.opening_relative;
        json.opening_offset = this.opening_offset;

        json.openings = [];
        this.walls.forEach(wall => {
            json.openings.push(wall.openings.map(op => op.serialize()));
        });
        json.facings = [];
        this.walls.forEach(wall => {
            json.facings.push(wall.facings.map(f => (f) ? { ID: f.ID } : null));
        });
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json) != 'object') return null;
        if (typeof (json.position) != 'object') return null;
        var dormer = new cn_roof_dormer(scene);

        if (typeof (json.ID) == 'string')
            dormer.ID = json.ID;

        dormer.position = cnx_clone(json.position);
        if (typeof (json.width) === 'number') dormer.width = json.width;
        if (typeof (json.height) === 'number') dormer.height = json.height;
        if (typeof (json.slopes) === 'number') dormer.slopes = json.slopes;
        if (typeof (json.slope_angle) === 'number') dormer.slope_angle = json.slope_angle;
        if (typeof (json.overhang_front) === 'number') dormer.overhang_front = json.overhang_front;
        if (typeof (json.overhang_side) === 'number') dormer.overhang_side = json.overhang_side;
        if (typeof (json.wall_type) === 'string') dormer.wall_type = scene.building.get_element_type(json.wall_type);
        if (!dormer.wall_type) dormer.wall_type = scene.building.get_wall_types()[0];
        if (typeof (json.opening_relative) === 'boolean') dormer.opening_relative = json.opening_relative;
        if (typeof (json.opening_offset) === 'number') dormer.opening_offset = json.opening_offset;

        if (typeof (json.openings) == 'object') {
            json.openings.forEach((opening_list, index) => {
                opening_list.forEach(op => {
                    const opening = cn_opening.unserialize(op, scene);
                    if (opening) {
                        opening.wall = dormer.walls[index];
                        dormer.walls[index].openings.push(opening);
                    }
                });
            });
        }

        if (typeof (json.facings) == 'object') {
            json.facings.forEach((facing_list, index) => {
                facing_list.forEach((facing, findex) => {
                    dormer.walls[index].facings[findex] = (facing && facing.ID) ? scene.building.facing_types.find(ft => ft.ID === facing.ID) : null
                });
            });
        }

        scene.roof_dormers.push(dormer);
        return dormer;
    }

    update(force = true) {
        if (!force && this.update_date > 0 && this.up_to_date(this.update_date, '')) {
            const slab = this.parent.find_slab(this.position);
            if (slab == this.slab && (slab == null || slab.up_to_date_3d(this.update_date))) return;
        }
        this.update_date = CN_CURRENT_DATE;
        this.valid = false;
        this.slab = this.parent.find_slab(this.position);
        this.vertices = [];
        this.vertices_overhang = [];
        if (this.slab && Math.abs(this.slab.slope) > 10) {
            this.dx = cnx_cross([0, 0, 1], this.slab.normal);
            cn_normalize(this.dx);
            this.dy = cn_normal(this.dx);
            const tan_s = Math.tan(this.slab.slope * Math.PI / 180);
            this.actual_height = this.height + this.slab.slab_type.thickness;
            if (this.slopes == 2) {
                this.length = this.actual_height / tan_s;
                const slope = (this.slope_angle <= 0) ? this.slab.slope : this.slope_angle;
                var hi = this.actual_height - Math.tan(slope * Math.PI / 180) * this.width / 2;
                this.length2 = hi / tan_s;
                this.half_width = this.actual_height / Math.tan(slope * Math.PI / 180)
            } else {
                const slope = (this.slope_angle >= this.slab.slope - 10) ? 0 : this.slope_angle;
                const tan_d = Math.tan(slope * Math.PI / 180);
                const h = this.actual_height * (1 + tan_d / (tan_s - tan_d));
                this.length = h / tan_s;
            }
            this.valid = true;
        } else {
            this.actual_height = this.height;
            this.length = this.height;
            this.length2 = this.height - this.width / 2;
            this.half_width = this.length;
        }

        const overhang_position = cn_sub(this.position, cn_mul(this.dy, this.overhang_front));
        const overhang_length = this.length - (this.width / 2 + this.overhang_side) * (this.length - this.length2) / (this.width / 2);
        if (this.slopes == 2) {
            this.vertices.push(cn_add(this.position, cn_mul(this.dy, this.length)));
            if (this.length2 > 0) {
                this.vertices.push(cn_add(this.position, cn_add(cn_mul(this.dx, this.width / 2), cn_mul(this.dy, this.length2))));
                this.vertices.push(cn_add(this.position, cn_mul(this.dx, this.width / 2)));
                this.vertices.push(cn_add(this.position, cn_mul(this.dx, -this.width / 2)));
                this.vertices.push(cn_add(this.position, cn_add(cn_mul(this.dx, -this.width / 2), cn_mul(this.dy, this.length2))));
                if (this.overhang_front > 0 || this.overhang_side > 0) {
                    this.vertices_overhang.push(this.vertices[0]);
                    this.vertices_overhang.push(cn_add(this.position, cn_add(cn_mul(this.dx, this.width / 2 + this.overhang_side), cn_mul(this.dy, overhang_length))));
                    this.vertices_overhang.push(cn_add(overhang_position, cn_mul(this.dx, this.width / 2 + this.overhang_side)));
                    this.vertices_overhang.push(cn_add(overhang_position, cn_mul(this.dx, -this.width / 2 - this.overhang_side)));
                    this.vertices_overhang.push(cn_add(this.position, cn_add(cn_mul(this.dx, -this.width / 2 - this.overhang_side), cn_mul(this.dy, overhang_length))));
                } else
                    this.vertices_overhang = this.vertices.map(v => cn_clone(v));
                this.wall_vertices[0].position = cn_clone(this.vertices[1]);
                this.wall_vertices[1].position = cn_clone(this.vertices[2]);
                this.wall_vertices[2].position = cn_clone(this.vertices[3]);
                this.wall_vertices[3].position = cn_clone(this.vertices[4]);
            } else {
                this.vertices.push(cn_add(this.position, cn_mul(this.dx, this.half_width)));
                this.vertices.push(cn_add(this.position, cn_mul(this.dx, -this.half_width)));

                if (this.overhang_front > 0 || this.overhang_side > 0) {
                    this.vertices_overhang.push(this.vertices[0]);
                    this.vertices_overhang.push(this.vertices[1]);
                    this.vertices_overhang.push(cn_sub(this.vertices[1], cn_mul(this.dy, this.overhang_front)));
                    this.vertices_overhang.push(cn_sub(this.vertices[2], cn_mul(this.dy, this.overhang_front)));
                    this.vertices_overhang.push(this.vertices[2]);
                } else
                    this.vertices_overhang = this.vertices.map(v => cn_clone(v));

                this.wall_vertices[0].position = cn_clone(this.vertices[1]);
                this.wall_vertices[1].position = cn_clone(this.vertices[1]);
                this.wall_vertices[2].position = cn_clone(this.vertices[2]);
                this.wall_vertices[3].position = cn_clone(this.vertices[2]);
            }
        } else {
            const p0 = cn_add(this.position, cn_mul(this.dx, -this.width / 2));
            const p1 = cn_add(this.position, cn_mul(this.dx, this.width / 2));
            this.vertices.push(cn_add(p0, cn_mul(this.dy, this.length)));
            this.vertices.push(cn_add(p1, cn_mul(this.dy, this.length)));
            this.vertices.push(p1);
            this.vertices.push(p0);

            if (this.overhang_front > 0 || this.overhang_side > 0) {
                const po0 = cn_add(overhang_position, cn_mul(this.dx, -this.width / 2 - this.overhang_side));
                const po1 = cn_add(overhang_position, cn_mul(this.dx, this.width / 2 + this.overhang_side));
                this.vertices_overhang.push(cn_add(po0, cn_mul(this.dy, this.length + this.overhang_front)));
                this.vertices_overhang.push(cn_add(po1, cn_mul(this.dy, this.length + this.overhang_front)));
                this.vertices_overhang.push(po1);
                this.vertices_overhang.push(po0);
            } else
                this.vertices_overhang = this.vertices.map(v => cn_clone(v));

            this.wall_vertices[0].position = cn_clone(this.vertices[1]);
            this.wall_vertices[1].position = cn_clone(this.vertices[2]);
            this.wall_vertices[2].position = cn_clone(this.vertices[3]);
            this.wall_vertices[3].position = cn_clone(this.vertices[0]);
        }

        if (this.valid) this.valid = !this.vertices.find(v => !this.slab.contains(v));

        //*** Compute main spaces */
        const outside_space = this.parent.storey.scene.spaces.find(sp => sp.outside);
        var main_space = outside_space;
        const footprint = this.build_3d_footprint(0);
        if (footprint) {
            var area = 0;
            this.parent.storey.scene.spaces.filter(sp => sp.has_roof && sp.inner_polygon).forEach(space => {
                const pg = space.inner_polygon.clone();
                pg.intersects(footprint);
                if (area < pg.get_area()) {
                    area = pg.get_area();
                    main_space = space;
                }
            });
        }

        this.wall_vertices.forEach(v => v.walls = []);
        this.walls.forEach(w => {
            w.vertices[0].walls.push(w);
            w.vertices[1].walls.push(w);
            w.wall_type = this.wall_type;
            w.spaces[0] = main_space;
            w.spaces[1] = outside_space;
        });
        this.walls.forEach(w => {
            w.build_self();
            if (w.bounds.length <= this.wall_type.thickness) w.valid = false;
        });
        this.walls.forEach(w => {
            w.build_bounds(0);
            w.build_bounds(1);
        });
        this.walls.forEach(w => w.build_dependant());

        this.clipping_volumes = [null, null];
    }

    local_to_world(x, y) {
        var p = cn_clone(this.position);
        if (x != 0) p = cn_add(p, cn_mul(this.dx, x));
        if (y != 0) p = cn_add(p, cn_mul(this.dy, y));
        return p;
    }

    /**
     * Draw svg
     * @param {cn_camera} camera
     * @param {Array<string>} add_classes
     * @returns
     */
    draw(camera, add_classes) {
        var html = '';

        if (this.vertices.length == 0) return html;

        if (!this.valid) html += `<g opacity="0.3">`;

        this.walls.forEach(w => {
            html += w.draw(camera);
            w.openings.forEach(op => html += op.draw(camera, []));
        });

        const draw_class = 'roof_dormer ' + add_classes.join(' ');

        html += camera.draw_path(this.vertices_overhang, draw_class, true);

        if (this.slopes == 2 && this.vertices.length)
            html += camera.draw_line(this.position, this.vertices[0], draw_class);

        if (!this.valid) html += '</g>';
        return html;
    }

    /**
     * Returns true if element contains the point
     * @param {number[]} point
     * @returns {boolean}
     */
    contains(point) {
        if (!this.get_bounding_box().contains_point(point)) return false;
        return cn_contour_contains(point, this.vertices);
    }

    /**
     * Computes bbounding box
     * @returns {cn_box}
     */
    get_bounding_box() {
        const box = new cn_box();
        this.vertices.forEach(v => box.enlarge_point(v));
        return box;
    }

    /**
     * Returns the footprint of the dormer, in the plane of its slab
     * @param {number} z
     * @returns {fh_polygon}
     */
    build_3d_footprint(z) {
        if (!this.valid) return null;
        const contour = this.vertices.map(v => cnx_clone(v, z + this.slab.compute_height(v)));
        const pg = new fh_polygon();
        pg.add_contour(contour);
        return pg;
    }

    build_3d_polygons(z, wall_axis = CN_OUTER, overhangs = false) {
        const polygons = [];
        if (!this.valid) return polygons;
        var h0 = 0;
        var h1 = 0;
        var h2 = 0;

        var half_width = this.width / 2;
        var delta_y = 0;
        if (wall_axis == CN_MIDDLE) {
            half_width -= 0.5 * this.wall_type.thickness;
            delta_y = 0.5 * this.wall_type.thickness;
        } else if (wall_axis == CN_INNER) {
            half_width -= this.wall_type.thickness;
            delta_y = this.wall_type.thickness;
        } else if (overhangs) {
            half_width += this.overhang_side;
            delta_y -= this.overhang_front
        }

        if (this.slopes == 2) {
            h0 = z + this.slab.compute_height(this.vertices[0]);
            h1 = z + this.slab.compute_height(this.vertices[1]);
            h2 = h1;
            const v0 = cnx_clone(this.vertices[0], h0);
            const v1 = cnx_clone(this.local_to_world(0, delta_y), h0);
            for (var niter = 0; niter < 2; niter++) {
                var contour = [];
                contour.push(v0);
                contour.push(v1);
                const v = (niter == 0) ? this.vertices[1] : this.vertices[this.vertices.length - 1];
                const dv = cn_sub(v, this.vertices[0]);
                cn_normalize(dv);
                var p = cn_intersect_line(v0, dv, v1, this.dx);
                if (cn_dist(p, v1) < half_width) {
                    contour.push(cnx_clone(p, z + this.slab.compute_height(p)));
                } else {
                    const w = (cn_dot(dv, this.dx) > 0) ? half_width : -half_width;
                    const v2 = this.local_to_world(w, delta_y);
                    p = cn_intersect_line(v0, dv, v2, this.dy);
                    const h = z + this.slab.compute_height(p);
                    contour.push(cnx_clone(v2, h));
                    contour.push(cnx_clone(p, h));
                }

                if (niter) contour.reverse();
                const pg = new fh_polygon();
                pg.add_contour(contour);
                polygons.push(pg);
            }
        } else {
            h1 = z + this.slab.compute_height(this.vertices[0]);
            h2 = z + this.actual_height + this.slab.compute_height(this.vertices[2]);
            const h = h2 + (h1 - h2) * delta_y / this.length;
            var contour = [];
            contour.push(cnx_clone(this.local_to_world(-half_width, delta_y), h));
            contour.push(cnx_clone(this.local_to_world(half_width, delta_y), h));
            contour.push(cnx_clone(this.local_to_world(half_width, this.length), h1));
            contour.push(cnx_clone(this.local_to_world(-half_width, this.length), h1));

            const pg = new fh_polygon();
            pg.add_contour(contour);
            polygons.push(pg);
        }
        polygons.forEach(pg => pg.compute_contours());

        return polygons;
    }

    build_3d_wall_polygons(z, axis = 2) {
        const polygons = [];
        if (!this.valid) return polygons;

        var h0 = 0;
        var h1 = 0;
        var h2 = 0;
        var h3 = 0;
        if (this.slopes == 2) {
            h0 = z + this.slab.compute_height(this.vertices[0]);
            h1 = z + this.slab.compute_height(this.vertices[1]);
            h2 = h1;
            h3 = h0;
        } else {
            h1 = z + this.slab.compute_height(this.vertices[0]);
            h2 = z + this.actual_height + this.slab.compute_height(this.vertices[2]);
            h3 = h2;
        }
        const h = z + this.slab.compute_height(this.position);
        var contour = [];
        contour.push(cnx_clone(this.wall_vertices[0].position, h1));
        contour.push(cnx_clone(this.wall_vertices[1].position, h2));
        contour.push(cnx_clone(this.wall_vertices[1].position, h));
        var pg = new fh_polygon();
        pg.add_contour(contour);
        polygons.push(pg);

        contour = [];
        contour.push(cnx_clone(this.wall_vertices[1].position, h2));
        contour.push(cnx_clone(this.wall_vertices[1].position, h));
        contour.push(cnx_clone(this.wall_vertices[2].position, h));
        contour.push(cnx_clone(this.wall_vertices[2].position, h2));
        contour.push(cnx_clone(this.position, h3));
        pg = new fh_polygon();
        pg.add_contour(contour);
        polygons.push(pg);

        contour = [];
        contour.push(cnx_clone(this.wall_vertices[2].position, h2));
        contour.push(cnx_clone(this.wall_vertices[2].position, h));
        contour.push(cnx_clone(this.wall_vertices[3].position, h1));
        pg = new fh_polygon();
        pg.add_contour(contour);
        polygons.push(pg);

        polygons.forEach(pg => pg.compute_contours());

        return polygons;
    }

    /**
     * Builds a solid for each wall of the dormer
     * @param {number} z_storey
     * @param {cn_wall} wall
     * @param {boolean} pierce_openings
     * @returns {fh_solid}
     */
    build_3d_walls(z_storey, wall, pierce_openings = true) {
        if (!this.valid || !wall.valid) return null;
        //*** ceiling planes */
        const z = z_storey + this.parent.storey.height;
        const planes = this.build_3d_polygons(z);
        var zmax = z;
        planes.forEach(plane => {
            plane.contour_vertices.forEach(v => {
                if (v[2] > zmax) zmax = v[2];
            });
        })

        const vertices = wall.build_footprint_contour().map(v => cnx_clone(v, z + this.slab.compute_height(v)));
        var footprint = new fh_polygon();
        footprint.add_contour(vertices);

        var zmin = zmax;
        vertices.forEach(v => {
            if (v[2] < zmin) zmin = v[2];
        });
        const solid = new fh_solid();
        solid.extrusion(footprint, [0, 0, zmax - zmin]);

        planes.forEach(p => solid.clip(p.get_point(), p.get_normal()));

        if (pierce_openings && wall.openings.length) {
            var dx = [wall.bounds.direction[0], wall.bounds.direction[1], 0];
            var dy = [-wall.bounds.direction[1], wall.bounds.direction[0], 0];
            var dz = [0, 0, 1];
            var p0 = [wall.bounds.pmin[0], wall.bounds.pmin[1], z_storey];
            const zr = (this.opening_relative) ? z + this.slab.compute_height(this.position) + this.opening_offset : 0;
            wall.openings.filter(op => op.valid).forEach(opening => {
                if (this.opening_relative) p0[2] = zr - opening.opening_type.z;
                var opening_solid = opening.opening_type.build_piercing_solid(fh_add(p0, fh_mul(dx, opening.position)), dx, dy, dz, this.wall_type.thickness + 0.02);
                solid.substracts(opening_solid);
            });
        }

        return solid;
    }

    /**
     * Returns (and lazy calculation) of the volume around the dormer.
     * @param {number} side
     * @returns [fh_solid]
     */
    get_clipping_volume(side) {
        if (this.clipping_volumes[side]) return this.clipping_volumes[side];

        const box = this.get_bounding_box();
        this.clipping_volumes[side] = new fh_solid();
        this.clipping_volumes[side].brick(cnx_clone(box.posmin), [box.size[0], 0, 0], [0, box.size[1], 0], [0, 0, this.parent.storey.height + this.parent.storey.roof_volume.get_bounding_box().size[2] + 2]);

        const z = this.parent.storey.height;
        const planes = this.build_3d_polygons(z);
        planes.forEach(p => this.clipping_volumes[side].clip(p.get_point(), p.get_normal()));
        const pos = cnx_clone(this.position, this.parent.storey.height + this.slab.compute_height(this.position));
        if (side) pos[2] += this.slab.slab_type.thickness;
        this.clipping_volumes[side].clip(pos, cnx_mul(this.slab.normal, -1));
        return this.clipping_volumes[side];
    }

    /** Returns the window height reference, basede on the storey altitude. */
    get_opening_height(opening) {
        if (!this.opening_relative) return 0;
        return this.parent.storey.height + this.slab.compute_height(this.position) + this.opening_offset - opening.opening_type.z;
    }

    /**
     * Builds thermal walls. For each polygon, the field 'space' is added to inform the matching space.
     */
    build_thermal_walls(space_polygons) {
        const footprint = this.build_3d_footprint(0);
        const matching_spaces = space_polygons.filter(s => s.clone().intersects(footprint).get_area() > 0.01);
        if (matching_spaces.length == 0) return [];

        var wall_polygons = [];
        const clipping = this.get_clipping_volume(0);
        const box = clipping.get_bounding_box();
        const voffset = [0, 0, box.position[2] + box.size[2]];

        const obj = this;

        function build_wall(p0, p1, space, wall) {
            const pg = new fh_polygon();
            pg.add_contour([p0, fh_add(p0, voffset), fh_add(p1, voffset), p1]);
            pg.compute_contours();
            clipping.intersects_polygon(pg);
            if (pg.get_area() < 0.001) return;
            pg['space'] = space['space'];
            pg['wall'] = wall;
            wall_polygons.push(pg);
            pg['openings'] = [];
            if (wall.openings.length) {
                const p0 = cn_sub(wall.vertices[0].position, cn_mul(pg.get_normal(), cn_dot(pg.get_normal(), cn_sub(wall.vertices[0].position, pg.get_point()))));
                const dir = wall.bounds.direction;
                wall.openings.filter(opening => opening.valid).forEach(opening => {
                    const h = obj.get_opening_height(opening);
                    const pgo = new fh_polygon();
                    const po0 = cnx_add(cnx_clone(p0, h + opening.opening_type.z), cnx_mul(dir, opening.position));
                    const po1 = cnx_add(po0, cn_mul(dir, opening.opening_type.width));
                    pgo.add_contour([po0, fh_add(po0, [0, 0, opening.opening_type.height]), fh_add(po1, [0, 0, opening.opening_type.height]), po1]);
                    pgo.compute_contours();
                    pgo.intersects(pg);
                    if (pgo.get_area() > 0.01) {
                        pgo['opening'] = opening;
                        pg['openings'].push(pgo);
                    }
                });
            }
        }

        this.walls.filter(w => w.valid).forEach(wall => {
            const v0 = cnx_clone(wall.shape[0]);
            const v1 = cnx_clone(wall.shape[3]);
            if (matching_spaces.length > 1) {
                matching_spaces.forEach(space => {
                    const space_vertices = space.intersect_segment(v0, v1);
                    for (var n = 0; n < space_vertices.length - 1; n += 2)
                        build_wall(space_vertices[n], space_vertices[n + 1], space, wall);
                });
            } else
                build_wall(v0, v1, matching_spaces[0], wall);
        });
        return wall_polygons;
    }
}

