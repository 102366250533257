import { ProjectStage } from './project-stage';

export class ProjectStepperConfig {
  type: 'DPE' | 'PAC';
  stages: ProjectStage[];

  constructor(type: 'DPE' | 'PAC', stages: ProjectStage[]) {
    this.type = type;
    this.stages = stages;
  }
}
