'use strict';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2023 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

class cn_logger {

    constructor() {
        this.active = true;
    }

    set_active(active) {
        this.active = active
    }

    log(...args) {
        if (this.active) {
            console.trace(...args);
        }
    }

}


export const logger = new cn_logger();
