//**************************************************************
//**************************************************************
/*
----- FHTAGN Project ---------------------------------------

Copyright(C) 2015-2019 EnerBIM

------------------------------------------------------------
*/
//**************************************************************
//**************************************************************
//***** solid operations
//**************************************************************
//**************************************************************

import {fh_matrix} from "./fh_matrix";
import {fh_add, fh_build_axis, fh_clone, fh_cross, fh_dot, fh_mul, fh_normalize, fh_sub} from "./fh_vector";
import {fh_polygon} from "./fh_polygon";
import earcut from "earcut";

export class fh_extruded_polygon
{
	constructor() {
		//*** polygon data
		this.polygon = null;
		this.direction = [0,0,0];
		this.color = [1,1,1,1];
		this.texture = "";
		this.bottom_plane = null;
		this.top_plane = null;
		this.matrix = new fh_matrix();
	}

	static build_extrusion(polygon, direction, color)
	{
		var ep = new fh_extruded_polygon();
		ep.polygon = polygon;
		ep.direction = direction;
		ep.color = color;
		return ep;
	}

	//*********************************************
	/** add brick */
	//*********************************************
	brick(origin, sx, sy, sz) {
		var dx = (typeof(sx)== 'number')?[sx,0,0]:sx;
		var dy = (typeof(sy)== 'number')?[0, sy,0]:sy;
		var dz = (typeof(sz)== 'number')?[0,0,sz]:sz;
		var nor = fh_cross(dx,dy);
		fh_normalize(nor);
		var pg = new fh_polygon(origin,nor);
		var ctr = [];
		ctr.push(origin);
		ctr.push(fh_add(origin,dx));
		ctr.push(fh_add(ctr[1],dy));
		ctr.push(fh_add(origin,dy));
		pg.add_contour(ctr);
		this.polygon = pg;
		this.direction = fh_clone(dz);
		this.bottom_plane = null;
		this.top_plane = null;
	}

	static build_brick(origin, sx, sy, sz, color) {
		var ep = new fh_extruded_polygon();
		ep.brick(origin,sx,sy,sz);
		ep.color = color;
		return ep;
	}

	//*********************************************
	/** add tube */
	//*********************************************
	tube(center, direction, x_dir, x_size, y_size) {
		var dz = fh_clone(direction);
		var dy = fh_cross(direction, x_dir);
		var dx = fh_cross(dy,direction);
		fh_normalize(dx);
		fh_normalize(dy);
		fh_normalize(dz);
		dx = fh_mul(dx,x_size*0.5);
		dy = fh_mul(dy,y_size*0.5);

		var pg = new fh_polygon(center,dz);
		var ctr = [];
		ctr.push(fh_sub(center,fh_add(dx,dy)));
		ctr.push(fh_add(center,fh_sub(dy,dx)));
		ctr.push(fh_add(center,fh_add(dx,dy)));
		ctr.push(fh_add(center,fh_sub(dx,dy)));
		pg.add_contour(ctr);
		this.polygon = pg;
		this.direction = fh_clone(direction);
		this.bottom_plane = null;
		this.top_plane = null;
	}

	//*********************************************
	/** build tesselation */
	//*********************************************
	tesselate(use_matrix = true) {
		var tesselation = {};
		tesselation.vertices = [];
		tesselation.triangles = [];
		tesselation.color = this.color;
		tesselation.texture = this.texture;
		if (this.polygon == null) return tesselation;

		var dx = [0,0,0];
		var dy = [0,0,0];
		fh_build_axis(this.polygon.get_normal(),dx,dy);

		var pgs = this.polygon.split();
		for (var n in pgs)
		{
			var vertices = [];
			var earcut_vertices = [];
			var earcut_contours = [];

			var pg = pgs[n];
			pg.compute_contours();

			var offset = 0;
			var vertex_offset = tesselation.vertices.length;

			var vertices = [];
			var earcut_vertices = [];
			var earcut_contours = [];
			for (var i =0;i<pg.contour_sizes.length;i++)
			{
				var sz = pg.contour_sizes[i];
				if (i < pg.contour_sizes.length-1)
					earcut_contours.push(offset+sz);
				for (var j=0;j<sz;j++)
				{
					var vtx = fh_clone(pg.contour_vertices[offset+j]);

					if (this.bottom_plane)
						tesselation.vertices.push(this.bottom_plane.project(vtx,this.direction));
					else
						tesselation.vertices.push(vtx);

					if (this.top_plane)
						tesselation.vertices.push(this.top_plane.project(vtx,this.direction));
					else
						tesselation.vertices.push(fh_add(vtx,this.direction));

					//*** side face
					var j1 = ((j+1)%sz);
					tesselation.triangles.push(vertex_offset + 2*offset + 2*j);
					tesselation.triangles.push(vertex_offset + 2*offset + 2*j1);
					tesselation.triangles.push(vertex_offset + 2*offset + 2*j1+1);

					tesselation.triangles.push(vertex_offset + 2*offset + 2*j);
					tesselation.triangles.push(vertex_offset + 2*offset + 2*j1+1);
					tesselation.triangles.push(vertex_offset + 2*offset + 2*j+1);

					earcut_vertices.push(fh_dot(vtx,dx));
					earcut_vertices.push(fh_dot(vtx,dy));
				}
				offset += sz;
			}
			var earcut_triangles = earcut(earcut_vertices,earcut_contours,2);

			//*** bottom face
			for (var i=0;i<earcut_triangles.length;i+=3)
			{
				tesselation.triangles.push(vertex_offset + 2*earcut_triangles[i]);
				tesselation.triangles.push(vertex_offset + 2*earcut_triangles[i+2]);
				tesselation.triangles.push(vertex_offset + 2*earcut_triangles[i+1]);
			}

			//*** top face
			for (var i=0;i<earcut_triangles.length;i+=3)
			{
				tesselation.triangles.push(vertex_offset + 2*earcut_triangles[i] +1);
				tesselation.triangles.push(vertex_offset + 2*earcut_triangles[i+1] +1);
				tesselation.triangles.push(vertex_offset + 2*earcut_triangles[i+2] +1);
			}
		}
		var vvv = [];
		for (var k in tesselation.vertices)
		{
			var p = (use_matrix)?this.matrix.transform_point(tesselation.vertices[k]):tesselation.vertices[k];
			vvv = vvv.concat(p);
		}
		tesselation.vertices = vvv;
		return tesselation;
	}
}
