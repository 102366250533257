import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRights } from '../model/company.model';

/**
 * Traduction des droits.
 */
@Pipe({ name: 'translateRights' })
export class TranslateRightsPipe implements PipeTransform {
  constructor() {}

  transform(enumValue: string): string {
    switch (enumValue) {
      case CompanyRights.ADMIN:
        return 'Administrateur';
      case CompanyRights.BUSINESS_MANAGER:
        return `Chargé d'affaires`;
      case CompanyRights.TECHNICIAN:
        return `Visiteur technique`;
      case CompanyRights.TECHNICIAN_EXPERIENCED:
        return `Visiteur technique confirmé`;
      default:
        return 'Aucun droit';
    }
  }
}
