'use strict';
import { cn_clone, cn_polygon_handler, cn_svg_map } from '..';
import { cn_slab_opening } from '../model/cn_slab_opening';
import { cn_vertex } from '../model/cn_vertex';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_edit_box } from './cn_edit_box';

export class cn_slab_opening_handler extends cn_edition_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_slab_opening>} slab_openings
     * @param {cn_svg_map} map
     */
    constructor(slab_openings, map) {
        super(slab_openings, map);

        this._map = map;
        var scene = map._scene;
        var transaction_manager = map._building.transaction_manager;

        this.slab_opening = (slab_openings.length == 1) ? slab_openings[0] : null;
        this._polygon_handler = null;
        if (this.slab_opening) {
            const polygon_handler = new cn_polygon_handler(this, this.slab_opening.contours[0].vertices.map(v => cn_clone(v.position)), true);
            this._handlers.push(polygon_handler);
            this._polygon_handler = polygon_handler;

            polygon_handler.snap_elements = scene.spaces;
            const lower_storey = map._storey.get_relative_storey(-1);
            if (lower_storey) polygon_handler.snap_elements = polygon_handler.snap_elements.concat(lower_storey.scene.stairs);

            var obj = this;
            obj._pending_changes = false;

            polygon_handler.on('change', () => {
                transaction_manager.push_transaction('Modification de trémie', obj.slab_opening.ID, () => {
                    scene.update();
                    scene.update_deep();
                });
                transaction_manager.push_item_set(obj.slab_opening.contours[0], 'vertices');

                var slab_opening_vertices = obj.slab_opening.contours[0].vertices;
                var handler_vertices = polygon_handler.vertices;

                while (slab_opening_vertices.length < handler_vertices.length)
                    slab_opening_vertices.push(new cn_vertex([0, 0]));

                if (slab_opening_vertices.length > handler_vertices.length)
                    slab_opening_vertices.splice(0, slab_opening_vertices.length - handler_vertices.length);

                for (var i = 0; i < handler_vertices.length; i++)
                    slab_opening_vertices[i] = new cn_vertex(handler_vertices[i]);

                obj._pending_changes = true;
            });
            polygon_handler.on('force_update', () => {
                obj._force_update();
            });
        }

        this._edit_box = new cn_edit_box(this, slab_openings, this._readOnly);
        if (!this._readOnly) {
            this._edit_box.add_lock_pastille(transaction_manager);
        }
        this._handlers.push(this._edit_box);
    }

    draw(camera) {
        if (this._polygon_handler) {
            this._polygon_handler.visible = this._polygon_handler.active = (!this.slab_opening.locked);
        }
        return super.draw(camera);
    }

    move(mouse_event) {
        if (this._pending_changes) this._force_update();
        return super.move(mouse_event);
    }

    drop(mouse_event) {
        if (this._pending_changes) this._force_update();
        return super.drop(mouse_event);
    }

    _force_update() {
        this._map._scene.update();
        this._map._scene.update_deep();
        this._map._storey.update_slabs();
        this._map.refresh();
        this._pending_changes = false;
    }
}

