'use strict';
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************

import { fh_polygon } from '@enerbim/fh-3d-viewer';
import { cn_comment_picture, cn_contour, cn_layer, getNumerotation, logger, setNumerotation, STOREY_EXTERIOR_LABEL } from '..';
import { cn_background_map } from '../model/cn_background_map';
import { cn_object_instance } from '../model/cn_object_instance';
import { cn_roof } from '../model/cn_roof';
import { cn_scene } from '../model/cn_scene';
import { generate_textures_per_element } from '../utils/cn_svg_patterns';
import { cn_add, cn_dist, cn_mul, cn_sub } from '../utils/cn_utilities';
import { cn_image_dir } from '../utils/image_dir';
import { cn_camera } from './cn_camera';
import { cn_event_manager } from './cn_event_manager';
import { cn_space_labelizer } from './cn_space_labelizer';
import { cn_svg_param_array, cn_svg_param_boolean, cn_svg_param_enum, cn_svg_param_group, cn_svg_param_object } from './cn_svg_param';

/**
 * @class cn_svg_configurator - A tool to produce svg for a map
 */
export class cn_svg_configurator extends cn_event_manager {
    /**
     *
     * @param {string} svg_container_id
     * @param {object} storey
     * @param {boolean} [roof=false]
     * @param {[number, number, number, number]} [padding=[0,0,0,0]]
     */
    constructor(svg_container_id, storey, roof = false, padding = [0, 0, 0, 0]) {
        super();
        var obj = this;

        this._svg_container_id = svg_container_id;

        //*** Scene data
        this._storey = storey;
        this._roof = roof;
        this._building = this._storey.building;
        this._scene = (roof) ? this._storey.roof : this._storey.scene;
        this._scene.update();
        this._scene.update_deep();

        this._space_labelizer = new cn_space_labelizer(storey);
        this._space_labelizer.move_button = false;

        this._padding = padding;
        this._camera = new cn_camera();
        this._camera.set_size(100, 100);
        this._camera.fit_box(this._scene.get_bounding_box());
        this._camera['storey'] = storey;
        this._camera_initialized = [-1, -1];

        this.previous_storey_opacity = 0.3;
        this.exterior_opacity = 0.3;

        this.draw_layers = [];
        this.draw_samplings = [];
        this.draw_zones = [];

        this._event_functions = [];

        function push_event_function(name, funct, capture) {
            obj._event_functions.push({ '_name': name, '_function': funct, '_capture': capture });
        }

        //*** We use a special event for svg event management.
        push_event_function('mousemove', ev => {
            ev.preventDefault();
            obj._mousemove(ev);
        }, false);

        push_event_function('mousedown', ev => {
            ev.preventDefault();
            obj._mousedown(ev);
        }, false);

        push_event_function('mouseup', ev => {
            ev.preventDefault();
            obj._mouseup(ev);
        }, false);

        push_event_function('wheel', ev => {
            ev.preventDefault();
            obj._mousewheel(ev);
        }, false);

        push_event_function('mouseenter', ev => {
            ev.preventDefault();
            obj._mouseenter();
        }, false);

        push_event_function('mouseleave', ev => {
            ev.preventDefault();
            obj._mouseleave();
        }, false);

        push_event_function('touchstart', ev => {
            ev.preventDefault();
            obj._touchstart(ev);
        }, true);

        push_event_function('touchend', ev => {
            ev.preventDefault();
            obj._touchend(ev);
        }, true);

        push_event_function('touchmove', ev => {
            ev.preventDefault();
            obj._touchmove(ev);
        }, true);

        push_event_function('contextmenu', ev => {
            ev.preventDefault();
            return false;
        }, false);

        this.start_listening();

        //*** Global settings */
        this._print_height = 200;
        this._resolution = 300;

        this._fixed_position = false;
        this._fixed_scale = false;
        this._scale = 0;

        this._camera_to_scale();

        //*** Display options */
        this._svg_params = this._get_params();

        //*** Build list of params by code */
        this._svg_params_by_code = [];

        function visit_params(lst) {
            for (var i in lst) {
                if (typeof (lst[i].value) != 'undefined')
                    obj._svg_params_by_code[lst[i].code] = lst[i].value;
                if (typeof (lst[i].children) != 'undefined')
                    visit_params(lst[i].children);
            }
        }

        visit_params(this._svg_params);

        //***************************************************************** */
        //*** Build list of object icon urls */
        this._url_b64 = {};
        var image_urls = [];
        var images_loaded = 0;

        //*** Calls event when all images are done */
        function check_loading_end() {
            images_loaded++;
            if (images_loaded < image_urls.length) return;
            obj._images_loaded = true;
            obj.call('images_loaded');
            // logger.log(obj._url_b64);
            obj.refresh();
        }

        //*** transform an image to b64 */
        function toBase64(img, id) {
            var reader = new FileReader();
            reader.onload = function (event) {
                obj._url_b64[id] = event.target.result;
                check_loading_end();
            };
            reader.readAsDataURL(img);
        }

        //*** reads an image url and transform to b64 */
        function get_b64_from_url(url, id) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.onerror = () => {
                logger.log('Network error.');
                check_loading_end();
            };
            xhr.onload = () => {
                if (xhr.status === 200) {
                    toBase64(xhr.response, id)
                } else {
                    logger.log('Loading error:' + xhr.statusText);
                    check_loading_end();
                }
            };
            xhr.send();
        }

        //*** Scan instances */
        if (!this._roof) {
            for (const objectInstance of this._scene.object_instances) {
                if (objectInstance.object.get_object_icon_id() == '') continue;
                var url = cn_object_instance.image_id_to_url(objectInstance.object.get_object_icon_id());
                if (typeof (this._url_b64[url]) != 'undefined') continue;
                image_urls.push([url, url]);
            }
            this._storey.markers.concat(this._storey.samplings).flatMap(marker => marker.pictures || []).forEach(picture => {
                const url = cn_comment_picture.image_id_to_url(picture.image_id);
                if (!this._url_b64[url]) {
                    image_urls.push([url, url]);
                }
            })
        } else {
            //*** Roof needs roof height icon */
            image_urls.push([cn_image_dir() + 'roof_height.png', 'roof_height']);
        }

        //*** Background map url */
        this._background_map_url = [];
        if (this._scene.constructor == cn_scene && typeof (cn_background_map.image_id_to_url) == 'function') {
            var background_scene = this._storey.background_maps;
            if (background_scene && background_scene.length) {
                background_scene.forEach(bs => {
                    if (bs.constructor == cn_background_map) {
                        let url = cn_background_map.image_id_to_url(bs.image_id);
                        image_urls.push([url, url]);
                        this._background_map_url.push(url)
                    }
                });
            }
        }

        this._push_space_textures(image_urls);

        //*** proceed to image loading */
        this._images_loaded = (image_urls.length == 0);
        for (var i = 0; i < image_urls.length; i++) {
            this._url_b64[image_urls[i][1]] = '';
            get_b64_from_url(image_urls[i][0], image_urls[i][1]);
        }

        this.refresh();
    }

    //***********************************************************************************
    //**** Are images already loaded ?
    //***********************************************************************************
    /**
     * @returns {Boolean}
     */
    are_images_loaded() {
        return this._images_loaded;
    }

    //***********************************************************************************
    //**** Mouse event listening
    //***********************************************************************************
    /**
     * @param {String} div_id
     */
    start_listening(div_id = '') {
        if (div_id != '')
            this._svg_container_id = div_id;

        var event_container = document.getElementById(this._svg_container_id);
        if (event_container) {
            for (var i in this._event_functions) {
                var ef = this._event_functions[i];
                event_container.addEventListener(ef._name, ef._function, ef._capture);
            }
        }
    }

    /**
     */
    stop_listening() {

        var event_container = document.getElementById(this._svg_container_id);
        if (event_container) {
            for (var i in this._event_functions) {
                var ef = this._event_functions[i];
                event_container.removeEventListener(ef._name, ef._function, ef._capture);
            }
        }
    }

    //***********************************************************************************
    //**** Global settings
    //***********************************************************************************

    //***********************************************************************************
    //**** print height (in mm)
    /**
     * @param {Number} v
     */
    set_print_height(v) {
        this._print_height = v;
        this._scale_to_camera()
    }

    /**
     * @returns {Number}
     */
    get_print_height() {
        return this._print_height;
    }

    //***********************************************************************************
    //**** resolution (in dpi)
    /**
     * @param {Number} v
     */
    set_resolution(v) {
        this._resolution = v;
    }

    /**
     * @returns {Number}
     */
    get_resolution() {
        return this._resolution;
    }

    _get_bounding_box() {
        const show_sampling = this.get_svg_param('show_sampling');
        const use_samplings = !!(show_sampling && show_sampling.length);
        const use_markers = this.get_svg_param('show_markers');
        const box = this._scene.get_bounding_box(use_markers, use_samplings, this._camera.screen_to_world_scale);
        if (!box.posmin) {
            box.posmin = [0, 0];
        }
        return box;
    }

    //***********************************************************************************
    //**** Fixed position
    /**
     * @param {Boolean} v
     */
    set_fixed_position(v) {
        this._fixed_position = v;
        const box = this._get_bounding_box();
        var world_center = cn_add(box.posmin, cn_mul(box.size, 0.5));
        var world_height = this._print_height * this._scale / 1000;
        this._camera.set_world_focus(world_center, world_height);
        this.call('camera_move');
    }

    /**
     * @returns {Boolean}
     */
    get_fixed_position() {
        return this._fixed_position;
    }

    //***********************************************************************************
    //**** Fixed scale
    /**
     * @param {Boolean} v
     */
    set_fixed_scale(v) {
        this._fixed_scale = v;
    }

    /**
     * @returns {Boolean}
     */
    get_fixed_scale() {
        return this._fixed_scale;
    }

    //***********************************************************************************
    //**** scale
    /**
     * @param {Number} v
     */
    set_scale(v) {
        this._scale = v;
        this._scale_to_camera();
    }

    /**
     * @returns {Number}
     */
    get_scale() {
        return this._scale;
    }

    //***********************************************************************************
    //**** camera position
    /**
     * @param {Array<Number>} v
     */
    set_camera_position(v) {
        this._camera.set_world_focus(v, this._print_height * this._scale / 1000);
    }

    /**
     * @returns {Array<Number>}
     */
    get_camera_position() {
        return this._camera.get_world_center();
    }

    //***********************************************************************************
    //**** options
    /**
     * @param {string} code
     * @param {any} value
     */
    set_svg_param(code, value) {
        if (typeof (this._svg_params_by_code[code]) != 'undefined')
            this._svg_params_by_code[code] = value;
    }

    /**
     * @param {string} code
     * @returns {any}
     */
    get_svg_param(code) {
        return this._svg_params_by_code[code];
    }

    /**
     * @returns {Array}
     */
    get_svg_params() {
        return this._svg_params;
    }

    /**
     * @returns {Object}
     */
    get_svg_params_by_code() {
        return this._svg_params_by_code;
    }

    //***********************************************************************************
    //**** Zoom methods
    //***********************************************************************************
    /**
     * @param {Boolean} zoomin
     * @returns {VoidFunction}
     */
    zoom(zoomin) {
        if (this._fixed_scale) return;
        var svg_container = document.getElementById(this._svg_container_id);
        if (!svg_container) return;

        var svg_rect = svg_container.getBoundingClientRect();
        var w = svg_rect.right - svg_rect.left - this._camera.padding[1] - this._camera.padding[3];
        var h = svg_rect.bottom - svg_rect.top - this._camera.padding[0] - this._camera.padding[2];
        var mouse_screen = [this._camera.padding[3] + 0.5 * w, this._camera.padding[0] + 0.5 * h];

        this._camera.wheel(mouse_screen, !zoomin);
        this._camera_to_scale();
        this.refresh();
    }

    //***********************************************************************************
    /**
     * camera center
     */
    center_camera(refresh = true) {
        if (this._fixed_scale) {
            const box = this._get_bounding_box();
            var world_center = cn_add(box.posmin, cn_mul(box.size, 0.5));
            this._camera.set_world_focus(world_center, this._print_height * this._scale / 1000);
        } else {
            this._camera.fit_box(this._get_bounding_box());
            this._camera_to_scale();
        }

        if (refresh) {
            this.call('camera_move');
            this.refresh();
        }
    }

    //***********************************************************************************
    /**
     * Close (reset color style)
     */
    close() {
        var render_style = this.get_svg_param('render');
        this.set_svg_param('render', 0);
        this.refresh();
        this.set_svg_param('render', render_style);
    }

    //***********************************************************************************
    /**
     * Refresh
     */
    refresh() {
        var svg_container = document.getElementById(this._svg_container_id);
        if (!svg_container) return;
        var svg_rect = svg_container.getBoundingClientRect();
        var html = this.render(svg_rect.right - svg_rect.left, svg_rect.bottom - svg_rect.top);
        svg_container.innerHTML = html;
    }

    //***********************************************************************************
    //**** Build style
    //***********************************************************************************
    _build_style(additionnalClasses) {
        var html = '';
        var render_style = this.get_svg_param('render');
        html += 'svg {font-family: Helvetica,Arial,sans-serif;font-size: 12px;}\n';

        if (render_style == 0)
            html += '.arrow_exp {stroke: rgb(136, 14, 206);fill: rgb(136, 14, 206);stroke-width: 1;}\n';
        else if (render_style == 1)
            html += '.arrow_exp {stroke: rgb(100, 100, 100);fill: rgb(100, 100, 100);stroke-width: 1;}\n';
        else
            html += '.arrow_exp {stroke: black;fill: black;stroke-width: 1;}\n';

        //*** Grid */
        if (render_style == 0) {
            html += '.grid_0.exp {stroke: rgb(200,200,255); stroke-width: 4}\n';
            html += '.grid_1.exp {stroke: rgb(200,200,255); stroke-width: 3}\n';
            html += '.grid_2.exp {stroke: rgb(200,200,255); stroke-width: 2}\n';
            html += '.grid_3.exp {stroke: rgb(200,200,255); stroke-width: 1}\n';
            html += '.grid_origin.exp {stroke: rgb(200,200,255); stroke-width: 4}\n';
        } else if (render_style == 1) {
            html += '.grid_0.exp {stroke: rgb(200,200,200); stroke-width: 4}\n';
            html += '.grid_1.exp {stroke: rgb(200,200,200); stroke-width: 3}\n';
            html += '.grid_2.exp {stroke: rgb(200,200,200); stroke-width: 2}\n';
            html += '.grid_3.exp {stroke: rgb(200,200,200); stroke-width: 1}\n';
            html += '.grid_origin.exp {stroke: rgb(100,100,100); stroke-width: 4}\n';
        } else {
            html += '.grid_0.exp {stroke: black; stroke-width: 1}\n';
            html += '.grid_1.exp {stroke: black; stroke-width: 1}\n';
            html += '.grid_2.exp {stroke: black; stroke-width: 1}\n';
            html += '.grid_3.exp {stroke: black; stroke-width: 1}\n';
            html += '.grid_origi.exp {stroke: black; stroke-width: 4}\n';
        }

        //*** Scale */
        html += '.scale_background.exp {stroke: white;fill: white;}\n';
        if (render_style < 2) {
            html += '.scale_line.exp {stroke: rgb(100,100,100);stroke-width: 4;}\n';
            html += '.scale_end_line.exp {stroke: rgb(100,100,100);stroke-width: 1;}\n';
            html += '.scale_text.exp {stroke: rgb(100,100,100);}\n';
        } else {
            html += '.scale_line.exp {stroke: black;stroke-width: 4;}\n';
            html += '.scale_end_line.exp {stroke: black;stroke-width: 1;}\n';
            html += '.scale_text.exp {stroke: black;}\n';
        }

        //*** Spaces */
        if (render_style === 0) {
            html += '.space.exp {stroke: none; fill: rgba(255, 255, 255, 0.5);}\n';
            html += '.exp.no_roof {fill: rgb(150,150,150);}\n';
            html += '.exp.outdoor {fill: rgb(161, 161, 204);}\n';
        } else if (render_style === 1) {
            html += '.space.exp {stroke: none; fill: rgb(150, 150, 150); opacity: 0.5;}\n';
            html += '.exp.no_roof {fill: rgb(220,220,220);}\n';
            html += '.exp.outdoor {fill: rgb(190, 190, 190);}\n';
        } else {
            html += '.space.exp {stroke: none; fill: none;  opacity: 1; filter: none}\n';
            html += '.exp.no_roof {fill: none;}\n';
            html += '.exp.outdoor {fill: none;}\n';
        }

        //*** Walls */
        if (render_style === 0) {
            html += '.wall.exp {stroke: rgb(150,150,150);fill: rgb(150,150,150);stroke-width: 1;opacity: 0.5;}\n';
            html += '.wall_line.exp {stroke: rgb(0,0,0); stroke-width: 2;}\n';
            html += '.wall_free.exp {stroke: rgb(0,0,100);stroke-width: 2;stroke-dasharray: 5,5;}\n';
        } else if (render_style === 1) {
            html += '.wall.exp {stroke: rgb(150,150,150);fill: rgb(150,150,150);stroke-width: 1;opacity: 0.5;}\n';
            html += '.wall_line.exp {stroke: rgb(0,0,0); stroke-width: 2;}\n';
            html += '.wall_free.exp {stroke: rgb(100,100,100);stroke-width: 2;stroke-dasharray: 5,5;}\n';
        } else {
            html += '.wall.exp {stroke: black;fill: black;stroke-width: 1;opacity: 1;}\n';
            html += '.wall_line.exp {stroke: black; stroke-width: 2;}\n';
            html += '.wall_free.exp {stroke: black;stroke-width: 2;stroke-dasharray: 5,5;}\n';
        }

        //*** Balconies */
        if (render_style === 0) {
            html += '.balcony.exp {stroke: rgb(150,150,150);fill: rgb(91, 175, 143);stroke-width: 1;opacity: 0.5;}\n';
            html += '.balcony_line.exp {stroke: rgb(0,0,0); stroke-width: 2;}\n';
            html += '.balcony_free.exp {stroke: rgb(0,0,100);stroke-width: 2;stroke-dasharray: 5,5;}\n';
        } else if (render_style === 1) {
            html += '.balcony.exp {stroke: rgb(150,150,150);fill: rgb(200,200,200);stroke-width: 1;opacity: 0.5;}\n';
            html += '.balcony_line.exp {stroke: rgb(0,0,0); stroke-width: 2;}\n';
            html += '.balcony_free.exp {stroke: rgb(100,100,100);stroke-width: 2;stroke-dasharray: 5,5;}\n';
        } else {
            html += '.balcony.exp {stroke: black;fill: black;stroke-width: 1;opacity: 1;}\n';
            html += '.balcony_line.exp {stroke: black; stroke-width: 2;}\n';
            html += '.balcony_free.exp {stroke: black;stroke-width: 2;stroke-dasharray: 5,5;}\n';
        }

        //*** Openings */
        if (render_style === 0) {
            html += '.opening_category_window.exp {stroke: blue;}\n';
            html += '.opening_category_door.exp {stroke: black;}\n';
            html += '.opening.exp {	stroke-width: 2;fill: rgb(230,230,230);}\n';
            html += '.opening_door.exp {stroke-width: 1;fill: none;}\n';
            html += '.opening_type_free.exp {stroke: black;stroke-width: 1;fill: rgb(200,200,200);}\n';
        } else if (render_style === 1) {
            html += '.opening_category_window.exp {stroke: black;}\n';
            html += '.opening_category_door.exp {stroke: black;}\n';
            html += '.opening.exp {	stroke-width: 2;fill: rgb(230,230,230);}\n';
            html += '.opening_door.exp {stroke-width: 1;fill: none;}\n';
            html += '.opening_type_free.exp {stroke: black;stroke-width: 1;fill: rgb(200,200,200);}\n';
        } else {
            html += '.opening_category_window.exp {stroke: black;}\n';
            html += '.opening_category_door.exp {stroke: black;}\n';
            html += '.opening.exp {	stroke-width: 2;fill: white;}\n';
            html += '.opening_door.exp {stroke-width: 1;fill: none;}\n';
            html += '.opening_type_free.exp {stroke: black;stroke-width: 1;fill: white;}\n';
        }

        //*** Slab openings */
        html += '.slab_opening.exp {stroke: black;stroke-width: 1;fill: none;}\n';

        //*** Stairs */
        if (render_style === 0) {
            html += '.stairs_background.exp {stroke: black;stroke-width: 1;fill: rgb(230,230,230);}\n';
            html += '.stairs_axis.exp {stroke: rgb(150,0,150);stroke-width: 2;fill: none;}\n';
            html += '.stairs_direction.exp {stroke: black;stroke-width: 2;fill: none;marker-end: url(#arrow_next_exp);}\n';
            html += '.stairs_steps.exp {stroke: black;stroke-width: 1;fill: none;}\n';
        } else if (render_style === 1) {
            html += '.stairs_background.exp {stroke: black;stroke-width: 1;fill: rgb(230,230,230);}\n';
            html += '.stairs_axis.exp {stroke: rgb(100,100,100);stroke-width: 2;fill: none;}\n';
            html += '.stairs_direction.exp {stroke: black;stroke-width: 2;fill: none;marker-end: url(#arrow_next_exp);}\n';
            html += '.stairs_steps.exp {stroke: black;stroke-width: 1;fill: none;}\n';
        } else {
            html += '.stairs_background.exp {stroke: black;stroke-width: 1;fill: white;}\n';
            html += '.stairs_axis.exp {stroke: black;stroke-width: 2;fill: none;}\n';
            html += '.stairs_direction.exp {stroke: black;stroke-width: 2;fill: none;marker-end: url(#arrow_next_exp);}\n';
            html += '.stairs_steps.exp {stroke: black;stroke-width: 1;fill: none;}\n';
        }

        //*** Beams */
        html += '.beam.exp {stroke: black;stroke-width: 1;}\n';

        //*** Columns */
        html += '.column.exp {stroke: black;stroke-width: 1;}\n';

        //*** Beam and column materials */
        if (render_style === 0) {
            html += '.beam_concrete.exp {stroke: black;fill: rgb(200,200,200);stroke-width: 1;}\n';
            html += '.beam_wood.exp {stroke: black;fill: rgb(150,125,100);stroke-width: 1;}\n';
            html += '.beam_steel.exp {stroke: black;fill: rgb(100,100,100);stroke-width: 1;}\n';
        } else if (render_style === 1) {
            html += '.beam_concrete.exp {stroke: black;fill: rgb(200,200,200);stroke-width: 1;}\n';
            html += '.beam_wood.exp {stroke: black;fill: rgb(125,125,125);stroke-width: 1;}\n';
            html += '.beam_steel.exp {stroke: black;fill: rgb(100,100,100);stroke-width: 1;}\n';
        } else {
            html += '.beam_concrete.exp {stroke: black;fill: black;stroke-width: 1;}\n';
            html += '.beam_wood.exp {stroke: black;fill: black;stroke-width: 1;}\n';
            html += '.beam_steel.exp {stroke: black;fill: black;stroke-width: 1;}\n';
        }

        //*** pipes */
        html += '.pipe.exp {stroke: black;stroke-width: 1;}\n';

        //*** Pipe materials */
        if (render_style === 0) {
            html += '.pipe_section_pvc.exp {stroke: rgb(100,100,100);stroke-width: 3;}\n';
            html += '.pipe_section_copper.exp {stroke: rgb(150,100,100);stroke-width: 3;}\n';
            html += '.pipe_section_per.exp {stroke: rgb(100,100,150);stroke-width: 3;}\n';
            html += '.pipe_section_other.exp {stroke: rgb(100,150,100);stroke-width: 3;}\n';
            html += '.pipe_fluid_water.exp {fill: rgb(0,0,255);}\n';
            html += '.pipe_fluid_waste.exp {fill: rgb(12,114,92);}\n';
            html += '.pipe_fluid_gaz.exp {fill: rgb(138,138,138);}\n';
            html += '.pipe_fluid_air.exp {fill: rgb(71,181,255);}\n';
            html += '.pipe_fluid_pvc.exp {fill: rgb(100,100,100);}\n';
            html += '.pipe_fluid_copper.exp {fill: rgb(150,100,100);}\n';
            html += '.pipe_fluid_per.exp {fill: rgb(100,100,150);}\n';
            html += '.pipe_fluid_other.exp {fill: rgb(100,150,100);}\n';
            html += '.pipe_pvc.exp {fill: rgb(100,100,100);}\n';
            html += '.pipe_copper.exp {fill: rgb(150,100,100);}\n';
            html += '.pipe_per.exp {fill: rgb(100,100,150);}\n';
            html += '.pipe_other.exp {fill: rgb(100,150,100);}\n';
            html += '.pipe_neutral.exp {stroke: rgb(150,150,150);}\n';
        } else if (render_style === 1) {
            html += '.pipe_section_pvc.exp {stroke: rgb(100,100,100);stroke-width: 3;}\n';
            html += '.pipe_section_copper.exp {stroke: rgb(115,115,115);stroke-width: 3;}\n';
            html += '.pipe_section_per.exp {stroke: rgb(106,106,106);stroke-width: 3;}\n';
            html += '.pipe_section_other.exp {stroke: rgb(130,130,130);stroke-width: 3;}\n';
            html += '.pipe_fluid_water.exp {fill: rgb(28,28,28);}\n';
            html += '.pipe_fluid_waste.exp {fill: rgb(81,81,81);}\n';
            html += '.pipe_fluid_gaz.exp {fill: rgb(138,138,138);}\n';
            html += '.pipe_fluid_air.exp {fill: rgb(156,156,156);}\n';
            html += '.pipe_fluid_pvc.exp {fill: rgb(100,100,100);}\n';
            html += '.pipe_fluid_copper.exp {fill: rgb(115,115,115);}\n';
            html += '.pipe_fluid_per.exp {fill: rgb(106,106,106);}\n';
            html += '.pipe_fluid_other.exp {fill: rgb(130,130,130);}\n';
            html += '.pipe_pvc.exp {fill: rgb(100,100,100);}\n';
            html += '.pipe_copper.exp {fill: rgb(115,115,115);}\n';
            html += '.pipe_per.exp {fill: rgb(106,106,106);}\n';
            html += '.pipe_other.exp {fill: rgb(130,130,130);}\n';
            html += '.pipe_neutral.exp {stroke: rgb(150,150,150);}\n';
        } else {
            html += '.pipe_section_pvc.exp {stroke: black;stroke-width: 3;}\n';
            html += '.pipe_section_copper.exp {stroke: black;stroke-width: 3;}\n';
            html += '.pipe_section_per.exp {stroke: black;stroke-width: 3;}\n';
            html += '.pipe_section_other.exp {stroke: black;stroke-width: 3;}\n';
            html += '.pipe_fluid_water.exp {fill: black;}\n';
            html += '.pipe_fluid_waste.exp {fill: black;}\n';
            html += '.pipe_fluid_gaz.exp {fill: black;}\n';
            html += '.pipe_fluid_air.exp {fill: black;}\n';
            html += '.pipe_fluid_pvc.exp {fill: black;}\n';
            html += '.pipe_fluid_copper.exp {fill: black;}\n';
            html += '.pipe_fluid_per.exp {fill: black;}\n';
            html += '.pipe_fluid_other.exp {fill: black;}\n';
            html += '.pipe_pvc.exp {fill: black;}\n';
            html += '.pipe_copper.exp {fill: black;}\n';
            html += '.pipe_per.exp {fill: black;}\n';
            html += '.pipe_other.exp {fill: black;}\n';
            html += '.pipe_neutral.exp {stroke: rgb(150,150,150);}\n';
        }

        //*** Roof lines */
        html += '.roof_line.exp {stroke: black;stroke-width: 2px;fill: none;}\n';
        html += '.roof_line.exp.fixed {stroke: black;stroke-width: 2px;fill: none;}\n';

        //*** Roof slabs */

        //*** Roof heights */
        html += '.roof_height_disk.exp {fill: rgb(200,200,200);stroke: none;}\n';
        html += '.roof_height_line.exp {fill: none;stroke: black;stroke-width: 3px;}\n';
        html += '.roof_height_text.exp {fill: black;text-anchor: middle;alignment-baseline: central;}\n';
        if (render_style === 0)
            html += '.roof_height_box.exp {fill: rgb(200,255,200);stroke: rgb(100,100,100);stroke-width: 2px;}\n';
        else if (render_style === 1)
            html += '.roof_height_box.exp {fill: rgb(200,200,200);stroke: rgb(100,100,100);stroke-width: 2px;}\n';
        else
            html += '.roof_height_box.exp {fill: white;stroke: black;stroke-width: 2px;}\n';


        //*** Texts */
        html += `.dimensionning_text {
            fill: black;
            font-weight: bold;
            text-anchor: middle;
            filter: url(#dimmensioning_text_background);
        }
        .dimensionning_text.dimensionning_text_selectable {
            filter: url(#dimmensioning_text_background);
        }`;

        html += '.space_label_name_box.exp {fill: rgb(255,255,255);stroke: none;opacity: 0.5;}\n';

        //*** Space labels and dimensionning lines */
        html += '.dimensionning_line.exp {stroke-width: 2;opacity: 0.5;marker-start: url(#arrow_prev_exp);marker-end: url(#arrow_next_exp);}\n';
        if (render_style === 0) {
            html += '.space_label_text.exp, .space_label_name.exp { fill: rgb(136, 14, 206); letter-spacing: 2px; font-weight: bold; text-align: center; }\n'
            html += '.space_label_background.exp {fill: rgb(201, 201, 201);stroke: rgb(69, 70, 69);stroke-width: 3px;}\n';
            html += '.dimensionning_line.exp {stroke: rgb(136, 14, 206);}\n';
            html += '.space_label_equipments_list.exp { fill: rgb(255,255,255); stroke: rgb(201, 201, 201); stroke-width: 3px;}\n';
        } else if (render_style === 1) {
            html += '.space_label_text.exp, .space_label_name.exp { fill: black; letter-spacing: 2px; font-weight: bold; text-align: center; }\n'
            html += '.space_label_background.exp {fill: white;stroke: rgb(100, 100, 100);stroke-width: 3px;}\n';
            html += '.dimensionning_line.exp {stroke: rgb(100, 100, 100);}\n';
            html += '.space_label_equipments_list.exp { fill: rgb(255,255,255); stroke: rgb(100, 100, 100); stroke-width: 3px;}\n';
        } else {
            html += '.space_label_text.exp, .space_label_name.exp { fill: black; letter-spacing: 2px; font-weight: bold; text-align: center; }\n'
            html += '.dimensionning_line.exp {stroke: black; opacity: 1;}\n';
            html += '.space_label_background.exp {fill: white;stroke: black;stroke-width: 3px;}\n';
            html += '.space_label_equipments_list.exp { fill: rgb(255,255,255); stroke: black; stroke-width: 3px;}\n';
        }

        //*** Markers */if (render_style == 0)
        if (render_style === 0) {
            html += '.marker_line.exp {stroke: rgb(0, 0, 150); fill: none;}\n';
            html += '.marker_arrow.exp {stroke: rgb(0, 0, 150); fill: rgb(0, 0, 150);}\n';
            html += '.marker_tail.exp {stroke: rgb(0, 0, 150); fill: rgb(0, 0, 150);}\n';
        } else if (render_style === 1) {
            html += '.marker_line.exp {stroke: rgb(100, 100, 100); fill: none;}\n';
            html += '.marker_arrow.exp {stroke: rgb(100, 100, 100); fill: rgb(100, 100, 100);}\n';
            html += '.marker_tail.exp {stroke: rgb(100, 100, 100); fill: rgb(100, 100, 100);}\n';
        } else {
            html += '.marker_line.exp {stroke: rgb(0, 0, 0); fill: none;}\n';
            html += '.marker_arrow.exp {stroke: rgb(0, 0, 0); fill: rgb(0, 0, 0);}\n';
            html += '.marker_tail.exp {stroke: rgb(0, 0, 0); fill: rgb(0, 0, 0);}\n';
        }

        html += '.sampling_text { stroke: #3345CC; }\n';
        html += '.sampling_amianted.exp, .sampling_text.sampling_amianted { stroke: #CC3333; }\n';
        html += '.exp.marker_arrow.sampling_amianted, .exp.marker_tail.sampling_amianted, .sampling_amianted_sampler { fill: #CC3333; }\n';
        html += '.exp.sampling_not_amianted, .sampling_text.sampling_not_amianted { stroke: #33CC45; }\n';
        html += '.exp.marker_sampler { stroke: #818181; fill: #818181}\n';
        html += '.exp.marker_arrow.sampling_not_amianted, .exp.marker_tail.sampling_not_amianted, .sampling_not_amianted_sampler { fill: #33CC45; }\n';
        html += '.sampling_no_result_sampler { fill: #3345CC; }\n';


        html += '.legend_background.exp {stroke: black; fill: white; stroke-width: 2px}\n';
        html += '.legend_title.exp {font-weight: bold; font-size: 12px; text-decoration: underline;}\n';
        html += '.legend_text.exp {font-weight: bold; font-size: 10px;}\n';
        html += '.legend_entry_text { font-size: 10px;}\n';
        html += '.legend_title_block { stroke: black; fill: rgb(223, 221, 221); stroke-width: 1px }\n';

        if (additionnalClasses) {
            additionnalClasses.forEach((value, id) => {
                html += value;
            });
        }

        return html;
    }

    //***********************************************************************************
    //**** Render. Returns a html to put in a svg.
    //***********************************************************************************
    /**
     * @param {Number} width
     * @param {Number} height
     */
    set_render_size(width, height) {

        var world_center = this._camera.get_world_center();
        var world_height = this._camera.get_world_height();

        this._svg_scale = height * (300 * 200 / 1000) / (this._resolution * this._print_height);
        this._camera.set_padding(this._padding[0], this._padding[1], this._padding[2], this._padding[3]);
        this._camera.set_size(width / this._svg_scale, height / this._svg_scale);
        this._camera.set_world_focus(world_center, world_height);
    }

    //***********************************************************************************
    //**** Render. Returns a html to put in a svg.
    //***********************************************************************************
    /**
     * @param {Number} width
     * @param {Number} height
     * @returns {string}
     */
    render(width, height, by_pass_camera_reset = false) {
        this.set_render_size(width, height);
        if (!by_pass_camera_reset && (this._camera_initialized[0] != width || this._camera_initialized[1] != height)) {
            this.center_camera(false);
            this._camera_initialized = [width, height];
        }
        let html = ``;

        html += `<defs>`;
        html += `<marker class="arrow_exp" id="arrow_next_exp" markerWidth="8" markerHeight="6" refX="7" refY="3" orient="auto">`;
        html += `<path d="M1,1 L1,5 L7,3 z" />`;
        html += `</marker>`;
        html += `<marker class="arrow_exp" id="arrow_prev_exp" markerWidth="8" markerHeight="6" refX="1" refY="3" orient="auto">`;
        html += `<path d="M7,1 L7,5 L1,3 z" />`;
        html += `</marker>`;
        html += `<filter id="inner-shadow">`;
        html += `<feFlood flood-color="rgb(0,0,0)"/>`;
        html += `<feComposite in2="SourceAlpha" operator="out"/>`;
        html += `<feGaussianBlur stdDeviation="50" result="blur"/>`;
        html += `<feComposite operator="atop" in2="SourceGraphic"/>`;
        html += `</filter>`;
        html += '<filter id=\'object_shadow\' x=\'-20%\' y=\'-20%\' width=\'140%\' height=\'140%\' filterUnits=\'objectBoundingBox\' primitiveUnits=\'userSpaceOnUse\' color-interpolation-filters=\'linearRGB\'>'
        html += '<feMorphology operator=\'dilate\' radius=\'1 1\' in=\'SourceAlpha\' result=\'morphology\'></feMorphology>'
        html += '<feFlood flood-color=\'#000000\' flood-opacity=\'1\' result=\'flood\'></feFlood>'
        html += '<feComposite in=\'flood\' in2=\'morphology\' operator=\'in\' result=\'composite\'></feComposite>'
        html += '<feMerge result=\'merge\'>'
        html += '<feMergeNode in=\'composite\' result=\'mergeNode\'></feMergeNode>'
        html += '<feMergeNode in=\'SourceGraphic\' result=\'mergeNode1\'></feMergeNode>'
        html += '</feMerge>'
        html += '</filter>';
        html += '<filter id=\'dimmensioning_text_background\' x=\'-15%\' y=\'-10%\' width=\'130%\' height=\'120%\'>';
        html += '   <feFlood flood-color=\'#FFFFFF\' flood-opacity=\'1\' result=\'bg\' />';
        html += '   <feGaussianBlur stdDeviation=\'2\'/>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 0 0 1\'/>';
        html += '   </feComponentTransfer>';
        html += '   <feComponentTransfer>';
        html += '       <feFuncA type=\'table\' tableValues=\'0 1 1 1 1 1 1 1\'/>'
        html += '   </feComponentTransfer>'
        html += '   <feComposite operator=\'over\' in=\'SourceGraphic\'/>'
        html += '</filter>';
        html += '<pattern id="pattern-wave-exp" width="12" height="8" patternUnits="userSpaceOnUse">';
        html += '   <path d="M0 3 C 4 0, 8 6, 12 3" style="stroke:#3470bf;fill:white;"/>';
        html += '</pattern>';

        this._building.facing_types.filter(facing => facing.support === 'floor' || facing.support === 'exterior').forEach(f => {
            html += `<defs><pattern id="pattern_texture_exp_${f.ID}" x="0" y="0" width="50" height="50" patternUnits="userSpaceOnUse" >`;
            html += `<filter id="pattern_texture_${f.ID}_flood">`;
            html += `<feFlood result="floodFill" x="0" y="0" width="50" height="50" flood-color="${f.color || '#FFFFFF'}" flood-opacity="1"/>`;
            html += '<feBlend in="SourceGraphic" in2="floodFill" mode="multiply" />';
            html += '</filter>';
            html += `<image xlink:href="${this._url_b64['texture_' + f.texture]}" x="0" y="0" width="50" height="50" preserveAspectRatio="xMidYMid meet"
            style="filter:url(#pattern_texture_${f.ID}_flood);"/>`;
            html += `</pattern></defs>`;
        });

        //*** Roof height pastille */
        if (this._roof && this.get_svg_param('show_roof_heights')) {
            html += `<image id="roof_height" xlink:href="${this._url_b64['roof_height']}" width="20" height="20" />`;
        }
        html += `</defs>`;

        const texturesRef = this._generate_textures();

        //*** Style */
        html += `<style>${this._build_style(texturesRef.textures)}</style>`;

        html += `<g pointer-events="none">`;

        //*** Background */
        html += `<rect x="0" y="0" width="${width}" height="${height}" style="stroke:none; fill: white;" />`;

        html += `<g transform="scale(${this._svg_scale} ${this._svg_scale})">`;

        //*** Draw grid */
        if (this._svg_params_by_code['show_grid']) {
            html += `<g>${this._camera.draw_grid(0, ['exp'])}</g>`;
        }

        //*** Draw background */
        html += this._draw_background();

        //*** Draw scene */
        if (this._roof) {
            html += this._draw_roof(this._camera);
        } else {
            html += this._draw_scene(this._camera, texturesRef);
        }

        //*** draw space labels */
        if (!this._roof && this._svg_params_by_code['space_labels']) {
            for (var key in this._space_labelizer.parameter_keys) {
                this._space_labelizer[key][0] = (this._svg_params_by_code[key] == true);
            }
            html += this._space_labelizer.draw(this._camera, ['exp']);
        }

        //*** Draw scale
        if (this._svg_params_by_code['show_scale']) {
            html += `<g>${this._camera.draw_scale(['exp'])}</g>`;
        }

        //*** Draw compass */
        if (this._svg_params_by_code['show_compass']) {
            html += `<g>${this._camera.draw_compass(this._building.compass_orientation)}</g>`;
        }

        html += this._draw_legend();

        //*** Background */
        html += `</g>`;

        html += `</g>`;

        return html;
    }

    _generate_textures() {
        let layers_slabs = [];
        const zpso_param = this.get_svg_param('show_zpso');
        const layer_param = this.get_svg_param('show_layer');
        const show_comment_application = !!(this.get_svg_param('show_comment_application') && this.get_svg_param('show_comment_application').length);
        const zone_to_draw = this.get_svg_param('show_zone') ? this.get_svg_param('show_zone').values : [];
        const show_space_facings = !!(this.get_svg_param('show_facing') && this.get_svg_param('show_facing').length);
        if (!(zpso_param && zpso_param.length) && !(layer_param && layer_param.length)) {
            this.draw_layers = [];
        } else {
            if (layer_param && layer_param.length) {
                this.draw_layers = this._building.layers.filter(layer => layer_param.includes(layer.ID));
                if (this.draw_layers.length) {
                    this.draw_layers.forEach(layer => {
                        layers_slabs.push(...this._storey.slabs.filter(slab => layer.elements.find(el => el.obj === slab.ID && el.storey === this._storey.ID) || layer.elements_types.includes(slab.slab_type.ID)));
                    });
                }
            } else if (zpso_param && zpso_param.length) {
                this.draw_layers = this._building.zpsos.filter(zpso => zpso_param.includes(zpso.ID));
                if (this.draw_layers.length) {
                    this.draw_layers.forEach(zpso => {
                        layers_slabs.push(...this._storey.slabs.filter(slab => zpso.elements.find(el => el.obj === slab.ID && el.storey === this._storey.ID) || zpso.elements_types.includes(slab.slab_type.ID)));
                    });
                }
            }
        }

        if (show_comment_application && this._storey && this._storey.markers) {
            this._storey.markers.forEach(marker => {
                layers_slabs.push(...this._storey.slabs.filter(slab => marker.element.ID === slab.ID));
            })
        }

        const splitted_sections = this._extract_splitted_polygons_from_layer_sections(this.draw_layers);
        const textures_per_element = this._collect_colorised_elements(this.draw_layers, show_comment_application, splitted_sections);
        const generation_textures = generate_textures_per_element(textures_per_element, this._camera._width.toString(), this._camera._height.toString(), 'exp-');

        if (zone_to_draw && zone_to_draw.length) {
            this.draw_zones = this._building.get_zones(this.get_svg_param('show_zone').property).filter(zone => zone_to_draw.includes(zone.ID));
            this.draw_zones.forEach((zone, i) => {
                zone.rooms.filter(room => room.storey === this._storey.ID).forEach(room => {
                    generation_textures.textures.set(room.space, `fill:${zone.color}; opacity:1;`);
                });
            });
        } else {
            this.draw_zones = [];
        }

        if (show_space_facings) {
            this._scene.spaces.forEach(space => {
                if (space.facings[0]) {
                    generation_textures.textures.set(space.ID, `fill:url(#pattern_texture_exp_${space.facings[0].ID}); opacity:1;`);
                }
            });
        }

        generation_textures.textures.forEach((value, id) => {
            generation_textures.textures.set(id, `.exp.${id} { ${value} }\n`);
        });

        generation_textures.textures.set('obj_exp_container', ' .exp.obj_exp_container { stroke:black; stroke-width: 1px; } \n');
        generation_textures.textures.set('wall_opaque', ' .exp.wall_opaque { opacity: 1;}\n');
        generation_textures.textures.set('pipe_opaque', ' .exp.pipe_path.pipe_opaque { opacity: 1; fill:rgb(150,150,150); }\n');
        generation_textures.textures.set('pipe_wave_pattern', ' .exp.pipe_section.pipe_wave_pattern { fill: url(#pattern-wave-exp); }\n');
        generation_textures.layers_slabs = layers_slabs;
        generation_textures.splitted_sections = splitted_sections;

        return generation_textures;
    }

    _draw_legend() {
        let html = ''
        if (this.draw_layers && this.draw_layers.length) {
            html += `<g>${this._camera.draw_layers_legend(this.draw_layers)}</g>`;
        }
        if (this.draw_zones && this.draw_zones.length) {
            html += `<g>${this._camera.draw_zones_legend(this.draw_zones)}</g>`;
        }
        return html;
    }

    //***********************************************************************************
    //**** render scene
    //***********************************************************************************
    _draw_scene(camera, texturesRef) {
        var scene = this._scene;
        const show_comment_application = !!(this.get_svg_param('show_comment_application') && this.get_svg_param('show_comment_application').length);
        const show_space_facings = !!(this.get_svg_param('show_facing') && this.get_svg_param('show_facing').length);
        let layers_slabs = texturesRef.layers_slabs;
        const oldNumerotationDisplay = getNumerotation();
        setNumerotation(this.get_svg_param('show_numerotation'));
        const zone_to_draw = this.get_svg_param('show_zone');
        var html = '';
        html += texturesRef.html;
        const textures_id_per_element = texturesRef.textures;

        const has_to_draw_zone = !!zone_to_draw && !!zone_to_draw.values && !!zone_to_draw.values.length;
        if (this.get_svg_param('show_spaces') || has_to_draw_zone || show_space_facings) {
            for (var i in scene.spaces)
                html += scene.spaces[i].draw(camera, ['exp',
                    has_to_draw_zone || show_space_facings ? scene.spaces[i].ID : '']);
        } else {
            this.draw_zones = [];
        }

        if (layers_slabs.length) {
            layers_slabs.forEach(slab => {
                html += slab.draw(camera, ['exp', slab.ID]);
            });
        }

        var show_outer_walls = this.get_svg_param('show_outer_walls');
        var show_inner_walls = this.get_svg_param('show_inner_walls');
        var show_balconies = this.get_svg_param('show_balconies');

        if (show_outer_walls || show_inner_walls || show_balconies) {
            var show_windows = this.get_svg_param('show_windows');
            var show_doors = this.get_svg_param('show_doors');
            for (var i in scene.walls) {
                var wall = scene.walls[i];

                if (wall.balcony) {
                    if (!show_balconies) continue;
                }
                {
                    if (!show_outer_walls && (wall.spaces[0].outside || wall.spaces[1].outside)) continue;
                    if (!show_inner_walls && (!wall.spaces[0].outside && !wall.spaces[1].outside)) continue;
                }
                html += wall.draw(camera, ['exp', 'exp-wall', wall.ID]);
                const openings = wall.openings.filter(opening => opening.valid);
                if (show_windows) {
                    openings.filter(opening => opening.opening_type.category === 'window').forEach(opening => {
                        html += opening.draw(camera, ['exp', opening.ID]);
                    });
                }
                if (show_doors) {
                    openings.filter(opening => opening.opening_type.category === 'door').forEach(opening => {
                        html += opening.draw(camera, ['exp', opening.ID]);
                    });
                }
            }
        }

        html += this._draw_layer_sections(texturesRef.splitted_sections, camera);

        if (this.get_svg_param('show_columns')) {
            for (var i in scene.columns)
                html += scene.columns[i].draw(camera, ['exp', scene.columns[i].ID]);
        }

        if (this.get_svg_param('show_slab_openings')) {
            for (var i in scene.slab_openings)
                html += scene.slab_openings[i].draw(camera, ['exp', scene.slab_openings[i].ID]);
        }

        if (this.get_svg_param('show_stairs')) {
            for (var i in scene.stairs)
                html += scene.stairs[i].draw(camera, ['exp', scene.stairs[i].ID]);
        }

        if (this.get_svg_param('show_objects')) {
            for (var i in scene.object_instances)
                html += scene.object_instances[i].draw(camera, ['exp', textures_id_per_element.has(scene.object_instances[i].ID) ? scene.object_instances[i].ID : ''], '', this._url_b64);
        }

        if (this.get_svg_param('show_beams')) {
            for (var i in scene.beams)
                html += scene.beams[i].draw(camera, ['exp', scene.beams[i].ID]);
        }

        if (this.get_svg_param('show_pipes')) {
            scene.pipes.forEach(pipe => {
                let had_style_defined = textures_id_per_element.has(pipe.ID);
                let section_class = [];
                if (this.draw_layers && this.draw_layers.length && !had_style_defined) {
                    section_class.push('pipe_opaque', 'pipe_wave_pattern');
                } else if (had_style_defined) {
                    section_class.push(pipe.ID);
                }
                html += pipe.draw(camera, ['exp', ...section_class]);
            });
        }

        //*** Draw space measures
        var show_outer_measures = this.get_svg_param('show_outer_measures');
        var show_inner_measures = this.get_svg_param('show_inner_measures');
        if (show_outer_measures || show_inner_measures) {
            for (var i in scene.spaces) {
                var space = scene.spaces[i];
                if (!show_outer_measures && space.outside) continue;
                if (!show_inner_measures && !space.outside) continue;
                html += space.measure.draw_measures(camera, ['exp']);
            }
        }

        //*** Draw opening measures */
        if (this.get_svg_param('show_opening_measures')) {
            for (var i in scene.walls) {
                var wall = scene.walls[i];
                for (var j in wall.openings) {
                    var opening = wall.openings[j];
                    if (!opening.valid) continue;
                    html += opening.draw_measures(camera, ['exp']);
                }
            }

        }

        //*** Draw space labels
        var show_space_label = this.get_svg_param('show_space_label');
        var show_space_area = this.get_svg_param('show_space_area');
        if (show_space_label || show_space_area) {
            for (var i in scene.spaces) {
                var space = scene.spaces[i];
                if (space.outside) continue;
                var v = camera.world_to_screen(space.center);
                if (show_space_label && show_space_area) v[1] -= 10;

                if (show_space_label) {
                    var space_name = space.get_name(camera.storey);
                    var tt = 'x=\'' + v[0] + '\' y=\'' + v[1] + '\'>' + space_name + '</text>';
                    html += '<text class=\'dimensionning_text exp\' ' + tt;
                    v[1] += 20;
                }

                if (show_space_area) {
                    var space_name = space.get_name(camera.storey);
                    var tt = 'x=\'' + v[0] + '\' y=\'' + v[1] + '\'>' + space.area.toFixed(2) + ' m²</text>';
                    html += '<text class=\'dimensionning_text exp\' ' + tt;
                }
            }
        }

        //*** Draw annotations */
        if (this.get_svg_param('show_markers')) {
            const displayed_marker_groups = ['', ...this._building.get_all_marker_groups()].filter(group => this.get_svg_param(`marker_group_${group}`))
            this._storey.markers.filter(marker => displayed_marker_groups.includes(marker.group)).forEach(marker => {
                html += marker.draw(camera, ['exp'], false, !!marker.pictures.length, this._url_b64);
            })
        }

        const samplingParam = this.get_svg_param('show_sampling');
        if (samplingParam && samplingParam.length) {
            const limit_zpso = this.get_svg_param('link_sampling_zpso') && this.get_svg_param('link_sampling_zpso').length;
            let zpsos_predicate = [];
            if (limit_zpso) {
                zpsos_predicate = this.draw_layers.map(zpso => zpso.name);
            }
            this.draw_samplings = this._storey.samplings.filter(sampling => samplingParam.includes(sampling.ID) && (!limit_zpso || zpsos_predicate.includes(sampling.zpso)));
            this.draw_samplings.forEach(sampling => {
                html += sampling.draw(camera, ['exp'], false, !!sampling.pictures.length, this._url_b64);
            });
        } else {
            this.draw_samplings = [];
        }

        setNumerotation(oldNumerotationDisplay);

        return html;
    }

    /**
     * Split sections in intersects and separates polygons
     *
     * @returns Array
     */
    _extract_splitted_polygons_from_layer_sections(draw_layers) {
        const splitted_polygons = [];
        if (draw_layers.length) {
            const sections_polygons = draw_layers.reduce((agg, layer) => agg.concat(layer.sections.filter(section => section.storey === this._storey.ID)
                .concat(layer.lines.filter(line => line.storey === this._storey.ID))
                .map(section => {
                    const polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
                    polygon.add_contour(section.shape.build_3d_contour(0, true));
                    polygon.compute_contours();
                    return { polygon, layer: layer };
                })), []);
            sections_polygons.forEach((section, index) => {
                const p = { polygon: section.polygon.clone(), layers: [section.layer] };
                const other_polygons = sections_polygons.slice(index + 1);
                splitted_polygons.forEach(pol => {
                    p.polygon.substracts(pol.polygon);
                    other_polygons.forEach(o => {
                        const op = o.polygon.clone()
                        op.substracts(pol.polygon)
                        return { polygon: op, layer: o.layer }
                    });
                });
                this._calculate_polygons_intersections(p, other_polygons, splitted_polygons, false);
            });
            sections_polygons.forEach((section, index) => {
                const l = section.polygon.clone();
                sections_polygons.forEach((s, i) => {
                    if (index !== i) {
                        l.substracts(s.polygon);
                    }
                });
                if (l.contour_sizes.length > 0) {
                    l.split().forEach(pol => {
                        splitted_polygons.push({ polygon: pol, layers: [section.layer] });
                    });
                }
            });
        }
        return splitted_polygons.map(pol => {
            return { contour: cn_contour.build_from_polygon(pol.polygon), layers: pol.layers }
        });
    }

    _calculate_polygons_intersections(p, others_polygons, result, intersection) {
        if (others_polygons.length > 0) {
            const next = others_polygons[0];
            const intersect = p.polygon.clone();
            intersect.intersects(next.polygon);
            if (intersect && intersect.contour_sizes.length > 0) {
                p.polygon.substracts(intersect);
                const new_p = { polygon: intersect, layers: [...p.layers, next.layer] };
                this._calculate_polygons_intersections(new_p, others_polygons.slice(1), result, true);
            }
            if (p.polygon.contour_sizes.length > 0) {
                this._calculate_polygons_intersections(p, others_polygons.slice(1), result, intersection);
            }
        } else if (intersection) {
            result.push(p);
        }
        return result
    }

    _draw_layer_sections(splitted_sections, camera) {
        let html = ''
        splitted_sections.forEach(section => {
            const shape = section.contour;
            shape.forEach((contour, i) => {
                if (i === 0) {
                    html += `<path d="`;
                }
                contour.inner_contour.forEach((contour, i) => {
                    if (i === 0) {
                        html += 'M ';
                    } else if (i === 1) {
                        html += 'L ';
                    }
                    const p = camera.world_to_screen(contour);
                    html += `${p[0]} ${p[1]} `;
                });
                if (i === shape.length - 1) {
                    html += 'Z "';
                    html += ` class="exp ${shape[0].ID}" />`;
                }
            });
        });
        return html;
    }

    /**
     * Reference all colors to applicate per element
     *
     * @param {cn_layer[]} draw_layers
     * @param {boolean} draw_comments_application
     * @param {Array} splitted_sections
     * @returns {Map}
     */
    _collect_colorised_elements(draw_layers, draw_comments_application, splitted_sections) {
        const colorsPerElement = new Map();
        if (draw_layers.length) {
            draw_layers.forEach(layer => {
                layer.elements.filter(el => el.storey === this._storey.ID).forEach(element => {
                    colorsPerElement.set(element.obj, [...(colorsPerElement.get(element.obj) || []),
                        { color: layer.color, texture: layer.stripes }]);
                });
                if (layer.elements_types && layer.elements_types.length) {
                    const elements_by_type = this._collect_elements_by_type(layer.elements_types);
                    layer.elements_types.reduce((a, b) => a.concat(elements_by_type.get(b)), []).forEach(element => {
                        colorsPerElement.set(element, [...(colorsPerElement.get(element) || []),
                            { color: layer.color, texture: layer.stripes }]);
                    });
                }
            });

            splitted_sections.forEach(section => {
                colorsPerElement.set(section.contour[0].ID, section.layers.map(layer => {
                    return { color: layer.color, texture: layer.stripes }
                }));
            });
        }

        if (draw_comments_application) {
            this._storey.markers.forEach(marker => {
                if (marker.element && !marker.shape && marker.color) {
                    colorsPerElement.set(marker.element.ID, [...(colorsPerElement.get(marker.element.ID) || []), { color: marker.color, texture: 'full' }]);
                }
            });
        }
        return colorsPerElement;
    }

    /**
     * Collect element by scope of elements types
     *
     * @param {Array<string>} scope
     */
    _collect_elements_by_type(scope) {
        const result = new Map();
        this._scene.walls.filter(wall => scope.includes(wall.wall_type.ID)).forEach(wall => result.set(wall.wall_type.ID, [...(result.get(wall.wall_type.ID) || []), wall.ID]));
        this._scene.walls.reduce((a, b) => a.concat(b.openings), []).filter(opening => scope.includes(opening.opening_type.ID)).forEach(opening => result.set(opening.opening_type.ID, [...(result.get(opening.opening_type.ID) || []), opening.ID]));
        this._scene.object_instances.filter(obj => scope.includes(obj.object.ID)).forEach(obj => result.set(obj.object.ID, [...(result.get(obj.object.ID) || []), obj.ID]));
        this._scene.beams.filter(beam => scope.includes(beam.element_type.ID)).forEach(beam => result.set(beam.element_type.ID, [...(result.get(beam.element_type.ID) || []), beam.ID]));
        this._scene.columns.filter(column => scope.includes(column.element_type.ID)).forEach(column => result.set(column.element_type.ID, [...(result.get(column.element_type.ID) || []), column.ID]));
        this._scene.pipes.filter(pipe => scope.includes(pipe.element_type.ID)).forEach(pipe => result.set(pipe.element_type.ID, [...(result.get(pipe.element_type.ID) || []), pipe.ID]));
        this._storey.slabs.filter(slab => scope.includes(slab.slab_type.ID)).forEach(slab => result.set(slab.slab_type.ID, [...(result.get(slab.slab_type.ID) || []), slab.ID]));
        return result;
    }


    //***********************************************************************************
    //**** render roof
    //***********************************************************************************
    _draw_roof(camera) {
        let scene = this._scene;

        let html = '';

        if (this.get_svg_param('show_spaces')) {
            for (var i in scene.spaces)
                html += scene.spaces[i].draw(camera, ['exp']);
        }

        var show_outer_lines = this.get_svg_param('show_outer_lines');
        var show_inner_lines = this.get_svg_param('show_inner_lines');
        var show_roof_slabs = this.get_svg_param('show_roof_slabs');

        if (show_outer_lines || show_inner_lines) {
            for (var i in scene.lines) {
                var line = scene.lines[i];
                var border = line.is_border();
                if (!show_outer_lines && border) continue;
                if (!show_inner_lines && !border) continue;
                html += scene.lines[i].draw(camera, ['exp']);
            }
        }

        if (show_roof_slabs) {
            for (var i in scene.slabs)
                html += scene.slabs[i].draw(camera, ['exp']);
        }

        if (this.get_svg_param('show_roof_openings')) {
            scene.openings.forEach(opening => {
                html += opening.draw(camera, ['exp']);
            });
        }

        //*** draw heights
        if (this.get_svg_param('show_roof_heights')) {
            var deltah = this._scene.storey.height;
            for (var i in this._scene.heights) {
                var height = this._scene.heights[i];
                html += height.draw_for_export(camera, deltah);
            }
        }

        return html;
    }

    //***********************************************************************************
    //**** render background scene
    //***********************************************************************************
    _draw_background_scene(scene, camera) {
        var html = '';
        for (var i in scene.walls)
            html += scene.walls[i].draw(camera, ['exp']);

        for (var i in scene.slab_openings)
            html += scene.slab_openings[i].draw(camera, ['exp']);

        for (var i in scene.stairs)
            html += scene.stairs[i].draw(camera, ['exp']);

        return html;
    }

    //***********************************************************************************
    //**** render background
    //***********************************************************************************
    _draw_background() {

        const draw_background = this._svg_params_by_code['show_background'];
        const draw_previous_storey = this._svg_params_by_code['show_previous_storey'];
        const draw_exterior = this._svg_params_by_code['show_exterior'];

        let html = '';

        //*** In case of roof, always draw storey below roof */
        if (this._scene.constructor == cn_roof) {
            html += '<g opacity=\'0.2\'>' + this._draw_background_scene(this._storey.scene, this._camera) + '</g>';
            return html;
        }

        // Dessiner l'étage inférieur
        if (draw_previous_storey) {
            const previous_storey = this._storey.get_previous_storey();
            if (previous_storey) {
                this._background_scenes = [previous_storey.scene];
                html += `<g opacity="${this.previous_storey_opacity}">` + this._draw_background_scene(this._background_scenes[0], this._camera) + '</g>';
            }
        }

        // Dessiner l'extérieur
        if (draw_exterior) {
            const exterior = this._building.find_exterior();
            if (exterior) {
                this._background_scenes = [exterior.scene];
                html += `<g opacity="${this.exterior_opacity}">` + this._draw_background_scene(this._background_scenes[0], this._camera) + '</g>';
            }
        }

        // Dessiner les fonds de carte
        const background_maps = draw_background ? this._storey.background_maps : [];

        if (this._storey.exterior) {
            const previous_storey = this._storey.get_previous_storey();
            if (previous_storey) {
                this._background_scenes = background_maps.concat(previous_storey.scene);
            }
        } else {
            this._background_scenes = background_maps;
        }

        if (this._background_scenes.length === 0) return html;

        this._background_scenes.forEach((bs, i) => {
            if (bs.background_opacity > 0) {
                bs.draw_numerotation = false;
                var b64 = this._url_b64[this._background_map_url[i]];
                if (typeof (b64) == 'string' && b64 != '')
                    html += `<g opacity="${bs.background_opacity}">` + bs.draw(this._camera, [], 1, b64) + `</g>`;
            } else if (this._storey.exterior) {
                html += `<g opacity="${this.exterior_opacity}">` + this._draw_background_scene(bs, this._camera) + '</g>';
            }
        });


        return html;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************

    _compute_mouse_position(ev) {

        var m = [0, 0];
        //*** Localized client position
        if (typeof (ev.clientX) == 'undefined') return m;
        m[0] = ev.clientX;
        m[1] = ev.clientY;
        var svg_container = document.getElementById(this._svg_container_id);
        if (svg_container) {
            var svg_rect = svg_container.getBoundingClientRect();
            m[0] -= svg_rect.left;
            m[1] -= svg_rect.top;
        }
        m[0] /= this._svg_scale;
        m[1] /= this._svg_scale;
        return m;
    }

    _mousedown(ev) {
        this._minimum_displacement = false;
        this._mousedown_position = this._compute_mouse_position(ev);
        this._mouse_position = this._compute_mouse_position(ev);
    }

    _mouseup(ev) {
    }

    _mousemove(ev) {
        if (this._fixed_position) return;

        var t = (new Date()).getTime();
        if (typeof (this._lastmove) != 'undefined' && t - this._lastmove < this._move_delay * 2)
            return;
        this._lastmove = t;

        if (ev.buttons == 0) return;

        var m = this._compute_mouse_position(ev);

        if (!this._minimum_displacement) {
            this._minimum_displacement = (cn_dist(this._mousedown_position, m) > 10);
            if (!this._minimum_displacement) return;
        }

        //*** process pan
        var delta = cn_sub(m, this._mouse_position);
        this._camera.pan(delta[0], delta[1]);
        this._mouse_position = m;
        this.call('camera_move');
        this.refresh();

        var delay = (new Date()).getTime() - t;
        this._move_delay = 0.9 * this._move_delay + 0.1 * delay;
        if (this._move_delay > 100) this._move_delay = 100;
    }

    _mousewheel(ev) {
        if (this._fixed_scale) return;
        var m = this._compute_mouse_position(ev);
        if (this._fixed_position)
            m = cn_mul(this._camera.get_screen_size(), 0.5);

        this._camera.wheel(m, ev.deltaY > 0.2);
        this._camera_to_scale();
        this.call('camera_move');
        this.refresh();
    }

    _mouseenter() {
    }

    _mouseleave() {
        this._minimum_displacement = false;
    }

    _touchstart(ev) {
        if (ev.targetTouches.length == 1) {
            this.grab_status = 0;
            this.mev = {};
            this.mev.buttons = 1;
            this.mev.which = 1;
            this.mev.clientX = ev.targetTouches[0].clientX;
            this.mev.clientY = ev.targetTouches[0].clientY;
            var obj = this;
            setTimeout(() => {
                if (obj.grab_status > 0) {
                    logger.log('too late for 1');
                    return;
                }
                if (obj.grab_status == 0) {
                    obj.grab_status = 1;
                    obj._mousedown(obj.mev);
                } else {
                    obj._mousedown(obj.mev);
                    obj._mouseup(obj.mev);
                }
            }, 500);
        } else {
            if (this.grab_status == 1) {
                logger.log('too late for 2');
                return;
            }
            logger.log('grab status was ', this.grab_status);
            this.grab_status = 2;
            this.mev = {};
            this.mev.buttons = 2;
            this.mev.which = 2;
            this.mev.clientX = 0.5 * (ev.targetTouches[0].clientX + ev.targetTouches[1].clientX);
            this.mev.clientY = 0.5 * (ev.targetTouches[0].clientY + ev.targetTouches[1].clientY);
            var v0 = [ev.targetTouches[0].clientX, ev.targetTouches[0].clientY];
            var v1 = [ev.targetTouches[1].clientX, ev.targetTouches[1].clientY];
            this.touch_distance = cn_dist(v0, v1);
            this._mousedown(this.mev);
        }
    }

    _touchend(ev) {
        if (this.grab_status > 0) {
            this._mouseup(this.mev);
        }
        this.grab_status = -1;
    }

    _touchmove(ev) {
        logger.log('target touches : ', ev.targetTouches.length);
        if (ev.targetTouches.length != this.grab_status)
            return;

        if (ev.targetTouches.length == 1) {
            this.mev.buttons = 1;
            this.mev.which = 1;
            this.mev.clientX = ev.targetTouches[0].clientX;
            this.mev.clientY = ev.targetTouches[0].clientY;
            this._mousemove(this.mev);
        } else {
            this.mev.buttons = 2;
            this.mev.which = 2;
            this.mev.clientX = 0.5 * (ev.targetTouches[0].clientX + ev.targetTouches[1].clientX);
            this.mev.clientY = 0.5 * (ev.targetTouches[0].clientY + ev.targetTouches[1].clientY);

            var v0 = [ev.targetTouches[0].clientX, ev.targetTouches[0].clientY];
            var v1 = [ev.targetTouches[1].clientX, ev.targetTouches[1].clientY];
            var distance = cn_dist(v0, v1);
            if (distance != this.touch_distance && !this._fixed_scale) {
                var ratio = this.touch_distance / distance;
                var m = this._compute_mouse_position(ev);
                if (this._fixed_position)
                    m = cn_mul(this._camera.get_screen_size(), 0.5);
                this._camera.wheel_ratio(m, ratio);
                this.touch_distance = distance;
            }

            this._mousemove(this.mev);
        }
    }

    _camera_to_scale() {
        var scale = this._scale;
        this._scale = Math.round(1000 * this._camera.get_world_height() / this._print_height);
        if (scale != this._scale)
            this.call('scale_change');
        this._scale_to_camera();
    }

    _scale_to_camera() {
        var world_center = this._camera.get_world_center();
        this._camera.set_world_focus(world_center, this._print_height * this._scale / 1000);
    }

    _get_params() {
        const result = []
        const render_group = new cn_svg_param_group('display_elements', 'Affichage');
        render_group.children.push(new cn_svg_param_enum('render', 'Rendu', 0, ['color', 'greyscale', 'black'], ['Couleur', 'Gris', 'Noir & blanc']));
        render_group.children.push(new cn_svg_param_boolean('show_grid', 'Grille', true));
        render_group.children.push(new cn_svg_param_boolean('show_scale', 'Echelle', true));
        render_group.children.push(new cn_svg_param_boolean('show_background', 'Fond de carte', false));
        render_group.children.push(new cn_svg_param_boolean('show_previous_storey', 'Étage inférieur', false));
        render_group.children.push(new cn_svg_param_boolean('show_exterior', STOREY_EXTERIOR_LABEL, false));
        render_group.children.push(new cn_svg_param_boolean('show_compass', 'Boussole', true));
        result.push(render_group);

        const elements_group = new cn_svg_param_group('display_elements', 'Eléments');
        if (!this._roof) {
            elements_group.children.push(new cn_svg_param_boolean('show_spaces', 'Pièces', true));
            elements_group.children.push(new cn_svg_param_boolean('show_outer_walls', 'Parois extérieures', true));
            elements_group.children.push(new cn_svg_param_boolean('show_inner_walls', 'Parois intérieures', true));
            elements_group.children.push(new cn_svg_param_boolean('show_balconies', 'Balcons', true));
            elements_group.children.push(new cn_svg_param_boolean('show_windows', 'Fenêtres', true));
            elements_group.children.push(new cn_svg_param_boolean('show_doors', 'Portes', true));
            elements_group.children.push(new cn_svg_param_boolean('show_stairs', 'Escaliers', true));
            elements_group.children.push(new cn_svg_param_boolean('show_slab_openings', 'Trémies', true));
            elements_group.children.push(new cn_svg_param_boolean('show_beams', 'Poutres', true));
            elements_group.children.push(new cn_svg_param_boolean('show_columns', 'Colonnes', true));
            elements_group.children.push(new cn_svg_param_boolean('show_pipes', 'Conduits', true));
            elements_group.children.push(new cn_svg_param_boolean('show_objects', 'Objets', true));

            const text_group = new cn_svg_param_group('display_elements', 'Texte');
            text_group.children.push(new cn_svg_param_boolean('show_numerotation', 'Numérotation des murs', false));
            result.push(text_group);
            const space_group = new cn_svg_param_boolean('space_labels', 'Etiquettes de pièce');
            for (var key in this._space_labelizer.parameter_keys) {
                space_group.children.push(new cn_svg_param_boolean(key, this._space_labelizer.parameter_keys[key], false));
            }
            result.push(space_group);
            const marker_group = new cn_svg_param_boolean('show_markers', 'Annotations', true);
            marker_group.children.push(new cn_svg_param_boolean('marker_group_', 'Groupe par défaut', true));
            for (let group of this._building.get_all_marker_groups()) {
                marker_group.children.push(new cn_svg_param_boolean('marker_group_' + group, group, false));
            }
            result.push(marker_group);
        } else {
            elements_group.children.push(new cn_svg_param_boolean('show_outer_lines', 'Bords de toiture', true));
            elements_group.children.push(new cn_svg_param_boolean('show_inner_lines', 'Lignes de toit', true));
            elements_group.children.push(new cn_svg_param_boolean('show_roof_slabs', 'Pans de toiture', true));
            elements_group.children.push(new cn_svg_param_boolean('show_roof_openings', 'Fenêtres de toit', true));
        }
        result.push(elements_group);

        const quotation_group = new cn_svg_param_group('display_elements', 'Cotes');
        if (!this._roof) {
            quotation_group.children.push(new cn_svg_param_boolean('show_outer_measures', 'Extérieures', false));
            quotation_group.children.push(new cn_svg_param_boolean('show_inner_measures', 'Intérieures', false));
            quotation_group.children.push(new cn_svg_param_boolean('show_opening_measures', 'Baies', false));
        }
        if (this._roof) {
            quotation_group.children.push(new cn_svg_param_boolean('show_roof_heights', 'Hauteurs sous toitures', false));
        }

        result.push(quotation_group);
        result.push(new cn_svg_param_array('show_zpso', 'Affichage ZPSO', []));
        result.push(new cn_svg_param_array('show_layer', 'Affichage Calques', null));
        result.push(new cn_svg_param_object('show_zone', 'Affichage Zones', null));
        result.push(new cn_svg_param_array('show_facing', 'Affichage Revêtements', null));
        result.push(new cn_svg_param_array('show_sampling', 'Affichage prélèvements', []));
        result.push(new cn_svg_param_array('link_sampling_zpso', 'Limiter aux ZPSO affichées', []));
        result.push(new cn_svg_param_array('show_comment_application', 'Affichage couleurs d\'application des commentaires', null));
        result.push(new cn_svg_param_object('title_block', 'Cartouche', {}));

        return result;
    }

    _push_space_textures(image_urls) {
        this._building.facing_types.filter(facing => facing.support === 'floor' || facing.support === 'exterior').forEach(f => {
            image_urls.push([cn_image_dir() + `texture_${f.texture}.jpg`, `texture_${f.texture}`]);
        });
    }
}

