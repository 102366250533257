import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectService } from '../services/project.service';
import { NotificationService } from '../services/notification.service';
import { DrawingStore } from '../store/drawing.store';
import { SESSION_STORAGE_KEY_LAST_BUILDING_BACKUPS } from '../constants/session-storage-key-constantes';
import { Backup } from '@enerbim/cnmap-angular-editor-lib/lib/model/backup.model';
import { BackupsStore } from '@enerbim/cnmap-angular-editor-lib';

@Injectable({
  providedIn: 'root',
})
export class BuildingGuard implements CanActivate, Resolve<unknown> {
  constructor(
    private projectService: ProjectService,
    private drawingStore: DrawingStore,
    private notificationService: NotificationService,
    private backupsStore: BackupsStore,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {
    const project = this.projectService.getCurrentProjectSnapshot();
    if (!project) {
      this.notificationService.error('Aucun projet chargé, impossible de charger le plan');
      return this.router.parseUrl('/projects');
    } else if (!project.drawingDetails && route.data.buildingRequired) {
      this.notificationService.error('Le projet ne contient aucun plan');
      return this.router.parseUrl('/projects');
    }

    return true;
  }

  /*
   * Warning !
   * this resolver is directly called inside the project.component since cnmap seems to have sde effect on router
   * the navigation is cancelled with a "Navigation ID is not equal to the current navigation id" error
   */
  resolve(): Observable<unknown> {
    const project = this.projectService.getCurrentProjectSnapshot();
    const existantBackupForProject = JSON.parse(
      sessionStorage.getItem(SESSION_STORAGE_KEY_LAST_BUILDING_BACKUPS) || '[]',
    ).filter(backup => backup?.drawingName?.includes(project.name));
    this.backupsStore.pushBackups(existantBackupForProject);
    return this.drawingStore.loadDrawing$(project);
  }
}
