import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Opening, Project, Slab, Wall } from '../../model/project.model';
import { cn_building, cn_slab, cn_storey, cn_to_bbp, cn_view, cn_wall } from '@enerbim/cnmap-editor';
import { fh_scene } from '@enerbim/fh-3d-viewer';
import { OverlayRef } from '@angular/cdk/overlay';
import {
  OVERLOOK_EXTERIEUR,
  OVERLOOK_FULL_GROUND,
  OVERLOOK_HEATED_LOCAL,
  OVERLOOK_UNHEATED_LOCAL,
  RENOVATION,
} from '../../constants/color-constantes';
import { NotificationService } from '../../services/notification.service';
import { BuildingStore } from '@enerbim/cnmap-angular-editor-lib';
import { SpaceRenovation } from '../../model/space-renovation.model';

@Component({
  selector: 'app-viewer-building-3d',
  templateUrl: './viewer-building-3d.component.html',
  styleUrls: ['./viewer-building-3d.component.scss'],
})
export class ViewerBuilding3dComponent implements AfterViewInit, OnDestroy {
  @Input() project: Project;
  @Input() resultPage: boolean;
  @Input() config: SpaceRenovation[];

  @Output() storeyChanged = new EventEmitter<string>();
  @Output() elementSelected = new EventEmitter<any>();

  storeyMenu: any[];

  /** Étage demandé (peut être null) */
  requestedStoreyIndex: number;

  /** Étage affiché = étage demandé, ou RDV par défaut */
  storeyIndex = 0;

  /** Est-ce que l'étage courant est la toiture ? */
  currentStoreyIsRoofLevel: boolean;

  view: cn_view;

  private json: any;
  private building: cn_building;
  private scene3d: fh_scene;
  private _controlsOverlayRef: OverlayRef;
  selectedStorey: any = { text: '' };
  storeysSorted: any[];

  constructor(private notificationService: NotificationService, private buildingStore: BuildingStore) {}

  ngAfterViewInit() {
    this.scene3d = new fh_scene();

    this.storeyMenu = [];
    const building = this.buildingStore.getCurrentBuildingSnapshot();
    if (!this.building || building.ID !== this.building.ID) {
      this.building = building;
      try {
        this.json = cn_to_bbp(this.building, { log_data: { sketchDrawingId: building.ID } });
        this.scene3d.load_json(this.json);
      } catch (e) {
        this.notificationService.error('Affichage de la 3D impossible. Veuillez contacter le support.');
      }

      for (let levelIndex = this.building.storeys.length; levelIndex >= 0; levelIndex--) {
        const storey = this.building.storeys.find(s => s.storey_index === levelIndex);
        const levelName = levelIndex === this.building.storeys.length ? 'Toiture' : storey.short_name;
        const entry: any = {
          text: levelName,
          levelIndex,
          selected: true,
          visible: true,
          handler: () => this.changeCurrentStorey(levelIndex),
        };
        this.storeyMenu.push(entry);
      }
    }

    this.requestedStoreyIndex = null;
    this.storeyIndex = this.building.storeys.length;
    this.storeysSorted = this.storeyMenu.slice().reverse();
    this.selectedStorey = this.storeysSorted[0];
    this.initFh3dView();
  }

  private initFh3dView() {
    if (!this.view) {
      this.view = new cn_view('fh_3d_container', 'fh_map_container', 0);
    }

    this.view.open(this.building);
    this.view.set_exterior_visibility(false);
    this.view.set_element_mouseover_color([0.1, 0.4, 0.8, 0.4]);
    this.view.set_element_selection_color([0.4, 0.4, 0.8, 0.5]);
    if (this.resultPage) {
      this.view.set_selection_mode(2);
      this.view.on('element_clicked', x => {
        if (x) {
          this.elementSelected.emit(x);
        }
      });
    } else {
      this.view.set_selection_mode(3);
      this.view.on('element_clicked', x => {
        if (x) {
          this.elementSelected.emit(x);
        }
      });
    }
    this.prepareRendering(this.building, this.config);
  }

  private onChangeCurrentStorey() {
    this.json &&
      this.json.objects &&
      this.json.objects.forEach(object => {
        const storey = object.storey;
        if (typeof storey === 'undefined') return;
        const visibility = storey <= this.storeyIndex;
        this.scene3d.set_product_visibility(visibility, object.ID);
      });
    this.currentStoreyIsRoofLevel = this.storeyIndex === this.building.storeys.length;
    this.view.set_current_storey(this.storeyIndex);
    this.storeyMenu.forEach(it => {
      it.selected = it.levelIndex === this.requestedStoreyIndex;
      it.visible = it.levelIndex === this.requestedStoreyIndex;
    });
  }

  ngOnDestroy() {
    if (this._controlsOverlayRef) {
      this._controlsOverlayRef.dispose();
      this._controlsOverlayRef = null;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.view && this.view.refresh_rendering();
  }

  resetView() {
    this.view && this.view.reset_camera();
  }

  zoom(zoomIn: boolean) {
    this.view && this.view.zoom(zoomIn);
  }

  changeCurrentStorey(storeyIndex: any) {
    this.requestedStoreyIndex = storeyIndex;
    this.storeyIndex = this.requestedStoreyIndex != null ? this.requestedStoreyIndex : this.building.storeys.length;
    this.selectedStorey = this.storeysSorted[this.storeyIndex];
    this.storeyChanged.emit(this.selectedStorey);
    this.onChangeCurrentStorey();
    this.prepareRendering(this.building, this.config);
  }

  t($event: TouchEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    $event.stopImmediatePropagation();
  }

  prepareRendering(building: cn_building, travaux: SpaceRenovation[]) {
    for (let i = 0; i < this.storeyMenu.length - 1; i++) {
      const walls = this.project.walls.filter(w => w.storey === i);
      const openings = this.project.openings.filter(w => w.storey === i);
      const slabs = this.project.slabs.filter(w => w.storey === i);
      const storey = building.find_storey_by_index(i);
      if (!this.resultPage) {
        // WALLS
        if (!this.currentStoreyIsRoofLevel) {
          walls.forEach(w => {
            const wall = storey.scene.walls.find(it => it.ID === w.id);
            this.renderElementOverlook(w, storey, wall);
          });
        }

        // SLABS
        slabs.forEach(s => {
          const slab = this.currentStoreyIsRoofLevel
            ? storey.roof?.slabs.find(it => it.ID === s.id)
            : storey.slabs.find(it => it.ID === s.id);
          this.renderElementOverlook(s, storey, slab);
        });
      } else {
        // TRAVAUX
        travaux.forEach((renovation: SpaceRenovation) => {
          const space = this.project.rooms.find(r => r.uid === renovation.spaceId && r.storey === i);
          if (space) {
            const of = openings.filter(w => w.roomIds.includes(space.id));
            const wf = walls.filter(w => w.roomIds.includes(space.id));

            wf.length &&
              wf.forEach(w => {
                const wall = storey.scene.walls.find(it => it.ID === w.id);
                if (wall) {
                  this.renderWallsWorks(renovation, storey, wall, w);
                  this.renderOpeningsWorks(renovation, storey, wall, of);
                }
              });

            const buildingSlabs = storey.slabs.filter(s => {
              return s.spaces.filter(sp => sp?.ID === space.id).length;
            });
            buildingSlabs.length &&
              buildingSlabs.forEach(s => {
                const slab = slabs.find(sl => sl.id === s.ID);
                slab ? this.renderSlabsWorks(renovation, storey, s, slab) : null;
              });
          }
        });
      }
    }
    this.view.refresh_rendering();
  }

  private renderElementOverlook(el: Wall | Slab, storey: cn_storey, element) {
    let color = null;
    if (el.overlook === 'EXTERIEUR') {
      color = OVERLOOK_EXTERIEUR;
    }
    if (el.overlook === 'HEATED_LOCAL') {
      color = OVERLOOK_HEATED_LOCAL;
    }
    if (el.overlook === 'UNHEATED_LOCAL') {
      color = OVERLOOK_UNHEATED_LOCAL;
    }
    if (el.overlook === 'FULL_GROUND') {
      color = OVERLOOK_FULL_GROUND;
    }
    this.view.set_element_color(storey, element, color);
  }

  private renderWallsWorks(renovation: SpaceRenovation, storey: cn_storey, cn_wall: cn_wall, wall: Wall) {
    let color = null;
    // Rénovation murs
    if (renovation.renovateWalls && !wall.renovatedAfter2000) {
      color = RENOVATION;
      this.view.set_element_color(storey, cn_wall, color);
    }
  }

  private renderOpeningsWorks(renovation: SpaceRenovation, storey: cn_storey, cn_wall: cn_wall, openings: Opening[]) {
    let color = null;
    // Rénovation openings
    if (renovation.renovateOpenings && cn_wall.openings.length) {
      color = RENOVATION;
      cn_wall.openings.forEach(o => {
        if (!openings.find(op => op.id === o.ID).renovatedAfter2000) {
          this.view.set_element_color(storey, o, color);
        }
      });
    }
  }

  private renderSlabsWorks(renovation: SpaceRenovation, storey: cn_storey, slab: cn_slab, slabFromProject: Slab) {
    let color = null;
    // Rénovation planchers
    if (renovation.renovateFloors && !slabFromProject.renovatedAfter2000) {
      color = RENOVATION;
      this.view.set_element_color(storey, slab, color);
    }
  }

  resetSelection() {
    this.view.clear_selection();
    this.prepareRendering(this.building, this.config);
  }
}
