import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Perimeter } from '../../model/user.model';
import { AuthorizationService } from '../../services/authorization.service';

@Directive({
  selector: '[appIsAuthByPerimeter]',
})
export class IsAuthByPerimeterDirective implements OnChanges {
  @Input() appIsAuthByPerimeter: Perimeter;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService,
  ) {}

  ngOnChanges(): void {
    this.viewContainer.clear();
    if (this.authorizationService.isAuthorizedByPerimeter(this.appIsAuthByPerimeter)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
