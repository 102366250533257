export const environment = {
  production: true,
  serviceWorker: true,
  apiUrl: '/api',
  managementUrl: '/management',
  magicPlanUrl: '/features-enerbim/cn-map/A_magicplan.php',
  magicPlanApiUser: 'EDF',
  appVersion: require('../../package.json').version,
  cnMapEditorVersion: require('../../package.json').dependencies['@enerbim/cnmap-editor'],
  cnMapAngularVersion: require('../../package.json').dependencies['@enerbim/cnmap-angular-editor-lib'],
  isDebug: false,
};
