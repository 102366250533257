import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgChartsModule } from 'ng2-charts';
import { environment } from '../../environments/environment';
import { CoreRoutingModule } from './core-routing.module';
import { CoreComponent } from './core.component';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { LayoutModule } from './layout/layout.module';
import { LoggedLayoutModule } from './logged-layout/logged-layout.module';
import { PaginatorFr } from './paginator.fr';

import localeFr from '@angular/common/locales/fr';
import { SharedModule } from '../shared/shared.module';
import { ObjectsHandler } from '../features/editor/handler/objects-handler';
import { EnerbimCommonsConfirmationModule, EnerbimCommonsNotificationModule } from '@enerbim/commons-lib';
import { DrawingPictureHandler } from '../features/editor/handler/drawing-picture-handler';
import { EditorHandler } from '../features/editor/handler/editor-handler';
import { LibraryHandler } from '../features/editor/handler/library-handler';
import {
  DefaultBuildingsTypesHandlerStatic,
  DefaultCustomizationHandler,
  DefaultDatasetsHandlerStatic,
  DefaultExportRapportZpsoHandlerStatic,
  DefaultExtensionsHandler,
  DefaultPresetsHandler,
  DefaultQuitEditorGuardHandler,
  DefaultSpacesHandlerStatic,
  DefaultSpacesObjectsSuggestionsHandler,
  DefaultZonesHandlerStatic,
  NomenclatureService,
  ObjectsService,
} from '@enerbim/cnmap-angular-editor-lib';
import { NgxImageCompressService } from 'ngx-image-compress';

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  declarations: [CoreComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreRoutingModule,
    HttpClientModule,
    LayoutModule,
    LoggedLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker,
      registrationStrategy: 'registerWithDelay:5000',
    }),
    NgChartsModule,
    SharedModule,

    // they are used outside the editor (in DPE workflow)
    EnerbimCommonsNotificationModule.forRoot(),
    EnerbimCommonsConfirmationModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { appearance: 'legacy' } },
    { provide: MatPaginatorIntl, useClass: PaginatorFr },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },

    // since CnStoreyComponent are used outside MapEditor (for the DPE workflow)
    // it is necessary to provide all services in the core.module
    // the forRoot method of MapEditorModule is not used to keep the core.module lighter
    NgxImageCompressService,
    {
      provide: 'IDrawingPictureHandler',
      useClass: DrawingPictureHandler,
    },
    {
      provide: 'IEditorHandler',
      useClass: EditorHandler,
    },
    {
      provide: 'ILibraryHandler',
      useClass: LibraryHandler,
    },
    {
      provide: 'IObjectsHandler',
      useClass: ObjectsHandler,
    },
    {
      provide: 'ICustomizationHandler',
      useClass: DefaultCustomizationHandler,
    },
    {
      provide: 'IExportRapportZpsoHandler',
      useClass: DefaultExportRapportZpsoHandlerStatic,
    },
    {
      provide: 'IPresetsHandler',
      useClass: DefaultPresetsHandler,
    },
    {
      provide: 'IQuitEditorGuardHandler',
      useClass: DefaultQuitEditorGuardHandler,
    },
    {
      provide: 'ISpacesHandler',
      useClass: DefaultSpacesHandlerStatic,
    },
    {
      provide: 'ISpacesObjectsSuggestionsHandler',
      useClass: DefaultSpacesObjectsSuggestionsHandler,
    },
    {
      provide: 'IZonesHandler',
      useClass: DefaultZonesHandlerStatic,
    },
    {
      provide: 'IBuildingTypesHandler',
      useClass: DefaultBuildingsTypesHandlerStatic,
    },
    {
      provide: 'IDatasetsHandler',
      useClass: DefaultDatasetsHandlerStatic,
    },
    {
      provide: 'IExtensionsHandler',
      useClass: DefaultExtensionsHandler,
    },
    NomenclatureService,
    ObjectsService,
  ],
  bootstrap: [CoreComponent],
})
export class CoreModule {}
