import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { DrawingPicture } from '@enerbim/cnmap-angular-editor-lib';
import { Project } from '../model/project.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { FileUtils } from '../utils/file.utils';
import { DrawingApiService } from '../services/drawing-api.service';

@Injectable({ providedIn: 'root' })
export class DrawingPictureStore {
  private readonly _drawingPictures$ = new BehaviorSubject<DrawingPicture[]>([]);
  readonly drawingPictures$ = this._drawingPictures$.asObservable();

  get drawingPicturesSnapshot(): DrawingPicture[] {
    return this._drawingPictures$.getValue();
  }

  constructor(private drawingApiService: DrawingApiService) {}

  initDrawingPictures(project?: Project): void {
    if (!project?.drawingPictures?.length) {
      this.setDrawingPictures([]);
      return;
    }

    const drawingPicturesWithBlob$$ = project.drawingPictures.map(bm =>
      this.drawingApiService.getDrawingPicture(project.id, bm.fileId).pipe(
        switchMap(blob => FileUtils.fileToDataURI(blob)),
        map(imageUrl => ({ ...bm, imageUrl })),
      ),
    );

    forkJoin(drawingPicturesWithBlob$$).subscribe(backgroundMaps => this.setDrawingPictures(backgroundMaps));
  }

  setDrawingPictures(drawingPictures: DrawingPicture[]): void {
    this._drawingPictures$.next(drawingPictures);
  }

  addDrawingPicture$(drawingPicture: DrawingPicture, file: File): Observable<string> {
    return FileUtils.fileToDataURI(file).pipe(
      tap(imageUrl => this.setDrawingPictures([...this.drawingPicturesSnapshot, { ...drawingPicture, imageUrl }])),
    );
  }

  removePicture(fileId: string): void {
    this.setDrawingPictures(this.drawingPicturesSnapshot.filter(bm => bm.fileId !== fileId));
  }
}
