'use strict';

import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';
import { cn_add, cn_azimut_label, cn_box, cn_clone, cn_dot, cn_middle, cn_mul, cn_normalize, cn_sub, cn_uuid } from '../utils/cn_utilities';
import { cn_building } from './cn_building';
import { cn_wall } from './cn_wall';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_facade : a facade of the building.
//***********************************************************************************
//***********************************************************************************

export class cn_facade {
    /**
     *
     * @param {Array<number>} point
     * @param {Array<number>} normal
     * @param {number} north
     */
    constructor(point, normal, north) {
        this.label = '';
        this.normal = cn_clone(normal);
        this.point = cn_clone(point);
        cn_normalize(this.normal);
        this.plane = cn_dot(point, normal);
        this.azimut = cn_azimut_label(this.normal, north);
        this.walls = [];
        this.wall_sides = [];
    }

    /**
     * Build all facades of a building
     * @param {cn_building} building
     * @returns {Array<cn_facade>}
     */
    static build_facades(building) {
        var facades = [];
        building.storeys.forEach(storey => {
            storey.get_walls().filter(w => w.is_facade()).forEach(wall => {
                for (var side = 0; side < 2; side++) {
                    if (!wall.is_facade(side)) continue;
                    const normal = (side) ? wall.bounds.normal : cn_mul(wall.bounds.normal, -1);
                    const point = cn_middle(wall.shape[side], wall.shape[3 - side]);
                    var facade = cn_facade.find_facade(point, normal, facades);
                    if (!facade) {
                        facade = new cn_facade(point, normal, building.compass_orientation);
                        facades.push(facade);
                    }
                    facade.walls.push(wall);
                    facade.wall_sides.push(side);
                }
            });
        });

        facades.sort((a, b) => a.plane - b.plane);
        var azimuts = {};
        facades.forEach(f => {
            if (typeof (azimuts[f.azimut]) == 'number')
                azimuts[f.azimut] = 1;
            else
                azimuts[f.azimut] = 0;
        });
        facades.forEach(f => {
            f.label = 'Façade ' + f.azimut;
            if (azimuts[f.azimut] > 0) {
                f.label += ' - ' + azimuts[f.azimut];
                azimuts[f.azimut]++;
            }
        });
        return facades;
    }

    /**
     * Finds the matching facade in a list of facades
     * @param {Array<number>} point
     * @param {Array<number>} normal
     * @param {Array<cn_facade>} facades
     * @returns {cn_facade}
     */
    static find_facade(point, normal, facades) {
        return facades.find(f => (cn_dot(f.normal, normal) > 0.95) && (Math.abs(cn_dot(point, f.normal) - f.plane) < 0.1));
    }

    /**
     * Find the facade that contains a given wall
     * @param {cn_wall} wall
     * @param {number} side
     * @param {Array<cn_facade>} facades
     * @returns {cn_facade}
     */
    static find_facade_by_wall(wall, side, facades) {
        for (var nf = 0; nf < facades.length; nf++) {
            const facade = facades[nf];
            const index = facade.walls.indexOf(wall);
            if (index < 0) continue;
            if (facade.wall_sides[index] == side) return facade;
        }
        return null;
    }
}

