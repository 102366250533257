import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CnSpinnerService } from '../../services/cn-spinner.service';

@Component({
  selector: 'app-cn-spinner',
  templateUrl: './cn-spinner.component.html',
  styleUrls: ['./cn-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CnSpinnerComponent {
  label$ = this.cnSpinnerService.label$;
  display$ = this.cnSpinnerService.displaySpinner$;

  constructor(private cnSpinnerService: CnSpinnerService) {}
}
