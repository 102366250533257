'use strict';
import { cn_area_context, cn_clone, cn_polygon_handler, cn_svg_map } from '..';
import { cn_area_trimming } from '../model/cn_area_trimming';
import { cn_vertex } from '../model/cn_vertex';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_edit_box } from './cn_edit_box';

export class cn_area_trimming_handler extends cn_edition_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_area_trimming>} area_trimmings
     * @param {cn_svg_map} map
     * @param {cn_area_context} map
     */
    constructor(area_trimmings, map, area_contex) {
        super(area_trimmings, map);
        this._area_context = area_contex;

        this._map = map;
        var scene = map._scene;
        var transaction_manager = map._building.transaction_manager;

        this.area_trimming = (area_trimmings.length == 1) ? area_trimmings[0] : null;
        this._polygon_handler = null;
        if (this.area_trimming) {
            const polygon_handler = new cn_polygon_handler(this, this.area_trimming.contours[0].vertices.map(v => cn_clone(v.position)), true);
            this._handlers.push(polygon_handler);
            this._polygon_handler = polygon_handler;

            polygon_handler.snap_elements = scene.spaces;
            const lower_storey = map._storey.get_relative_storey(-1);
            if (lower_storey) polygon_handler.snap_elements = polygon_handler.snap_elements.concat(lower_storey.scene.stairs);

            var obj = this;
            obj._pending_changes = false;

            polygon_handler.on('change', () => {
                transaction_manager.push_transaction('Modification de découpe suurfacique', obj.area_trimming.ID, () => {
                    scene.update();
                    scene.update_deep();
                });
                transaction_manager.push_item_set(obj.area_trimming.contours[0], 'vertices');

                var area_trimming_vertices = obj.area_trimming.contours[0].vertices;
                var handler_vertices = polygon_handler.vertices;

                while (area_trimming_vertices.length < handler_vertices.length)
                    area_trimming_vertices.push(new cn_vertex([0, 0]));

                if (area_trimming_vertices.length > handler_vertices.length)
                    area_trimming_vertices.splice(0, area_trimming_vertices.length - handler_vertices.length);

                for (var i = 0; i < handler_vertices.length; i++)
                    area_trimming_vertices[i] = new cn_vertex(handler_vertices[i]);

                obj._pending_changes = true;
            });
            polygon_handler.on('force_update', () => {
                obj._force_update();
            });
        }

        this._edit_box = new cn_edit_box(this, area_trimmings, this._readOnly);
        if (!this._readOnly) {
            this._edit_box.add_lock_pastille(transaction_manager);
        }
        this._handlers.push(this._edit_box);
    }

    draw(camera) {
        if (this._polygon_handler) {
            this._polygon_handler.visible = this._polygon_handler.active = (!this.area_trimming.locked);
        }
        return super.draw(camera);
    }

    move(mouse_event) {
        if (this._pending_changes) this._force_update();
        return super.move(mouse_event);
    }

    drop(mouse_event) {
        if (this._pending_changes) this._force_update();
        return super.drop(mouse_event);
    }

    _force_update() {
        this._area_context.update_deep(this._map._storey);
        this._map.refresh();
        this._pending_changes = false;
    }
}

