'use strict';

//***********************************************************************************
//***********************************************************************************
//**** cn_element_visitor : wall, slab, opening, stair, object_instance, beam and column, pipe
//***********************************************************************************
//***********************************************************************************

export class cn_element_visitor {

    visit_beam(cn_beam) {
    }

    visit_column(cn_column) {
    }

    visit_marker(cn_marker) {
    }

    visit_object(cn_object_instance) {
    }

    visit_opening(cn_opening) {
    }

    visit_pipe(cn_pipe) {
    }

    visit_sampling(cn_marker) {
    }

    visit_slab(cn_slab) {
    }

    visit_stairs(cn_stairs) {
    }

    visit_wall(cn_wall) {
    }

}
