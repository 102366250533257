'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Beam
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_box, cn_dot, cn_mul, cn_normal, cn_normalize, cn_sub } from '../utils/cn_utilities';
import { fh_clone, fh_cross, fh_extruded_polygon, fh_matrix, fh_mul, fh_normalize, fh_sub } from '@enerbim/fh-3d-viewer';
import { cn_storey } from './cn_storey';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { cn_scene } from './cn_scene';
import { cn_space } from './cn_space';
import { logger } from '../utils/cn_logger';

/**
 * @class beam
 */
export class cn_beam extends cn_element {
    constructor(element_type) {
        super();

        //*** Model data
        this.vertices = [[0, 0, 0], [1, 0, 0]];
        this.element_type = element_type;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.vertices = [fh_clone(this.vertices[0]), fh_clone(this.vertices[1])];
        json.element_type = this.element_type.ID;
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json.ID) != 'string') return false;
        if (typeof (json.vertices) != 'object') return false;
        if (typeof (json.element_type) != 'string') return false;

        var et = null;
        if (typeof (json.element_type) == 'string')
            et = scene.building.get_element_type(json.element_type);
        if (et == null) {
            logger.log('use default beam type');
            et = scene.building.get_beam_types()[0];
        }

        var beam = new cn_beam(et);
        beam.ID = json.ID;
        beam.vertices[0] = fh_clone(json.vertices[0]);
        beam.vertices[1] = fh_clone(json.vertices[1]);
        scene.beams.push(beam);
    }

    //***********************************************************************************
    //**** Draw
    //***********************************************************************************
    draw(camera, add_classes = [], fill = '') {
        var html = '';
        if (this.status < 0) return html;

        var mouseover = (add_classes.indexOf('mouseover') >= 0);
        var selected = (add_classes.indexOf('selected') >= 0);


        var p0 = camera.world_to_screen(this.vertices[0]);
        var p1 = camera.world_to_screen(this.vertices[1]);
        var th = 0.5 * camera.world_to_screen_scale * this.element_type.width;
        var normal = cn_normal(cn_sub(p1, p0));
        cn_normalize(normal);
        normal = cn_mul(normal, th);

        if (mouseover || selected) {
            html += `<path class="beam_highlight ${(selected) ? 'selected' : 'mouseover'} " d='`;
            html += 'M ' + (p0[0] - normal[0]) + ' ' + (p0[1] - normal[1]) + ' ';
            html += 'L ' + (p0[0] + normal[0]) + ' ' + (p0[1] + normal[1]) + ' ';
            html += 'L ' + (p1[0] + normal[0]) + ' ' + (p1[1] + normal[1]) + ' ';
            html += 'L ' + (p1[0] - normal[0]) + ' ' + (p1[1] - normal[1]) + ' ';
            html += 'Z\' />';
        }

        html += `<path class="beam_${this.element_type.material} beam ${add_classes.join(' ')}" ${fill} d='`;
        html += 'M ' + (p0[0] - normal[0]) + ' ' + (p0[1] - normal[1]) + ' ';
        html += 'L ' + (p0[0] + normal[0]) + ' ' + (p0[1] + normal[1]) + ' ';
        html += 'L ' + (p1[0] + normal[0]) + ' ' + (p1[1] + normal[1]) + ' ';
        html += 'L ' + (p1[0] - normal[0]) + ' ' + (p1[1] - normal[1]) + ' ';
        html += 'Z\' />';

        return html;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contains(point, tolerance = 0) {
        var d = cn_sub(point, this.vertices[0]);
        var dir = cn_sub(this.vertices[1], this.vertices[0]);
        var l = cn_normalize(dir);
        var x = cn_dot(d, dir);
        if (x < -tolerance) return false;
        if (x > l + tolerance) return false;
        var y = cn_dot(d, cn_normal(dir));
        return (Math.abs(y) < 0.5 * this.element_type.width * 0.5 + tolerance);
    }

    //***********************************************************************************
    //**** Build 3D solid
    //***********************************************************************************
    build_solid(h, h0, h1) {
        var v0 = fh_clone(this.vertices[0]);
        var v1 = fh_clone(this.vertices[1]);
        if (v0[2] == 0) v0[2] = h0;
        else v0[2] += h;
        if (v1[2] == 0) v1[2] = h1;
        else v1[2] += h;
        var dz = fh_sub(v1, v0);
        var length = fh_normalize(dz);
        var dx = fh_cross(dz, [0, 0, 1]);
        fh_normalize(dx);
        var dy = fh_cross(dz, dx);
        fh_normalize(dy);

        var ori = fh_clone(v0);
        ori = fh_sub(ori, fh_mul(dx, 0.5 * this.element_type.width));

        var solid = this.element_type.build_solid(length);
        var matrix = new fh_matrix();
        matrix.load_axis(ori, dx, dy, dz);
        solid.apply_matrix(matrix);
        return solid;
    }

    /**
     * Builds an extruded poilygon
     * @param {number} h : height of the floor
     * @param {cn_storey} storey
     * @returns {fh_extruded_polygon}
     */
    build_extruded_polygon(h, storey) {
        var vertices = [fh_clone(this.vertices[0]), fh_clone(this.vertices[1])];
        for (var k = 0; k < 2; k++) {
            if (vertices[k][2] != 0) {
                vertices[k][2] += h;
                continue;
            }
            vertices[k][2] = h + storey.height;
            if (storey.roof) {
                var hh = storey.roof.compute_height(this.vertices[k]);
                if (hh !== false)
                    vertices[k][2] += hh;
            }
        }

        var dz = fh_sub(vertices[1], vertices[0]);
        var length = fh_normalize(dz);
        var dx = fh_cross(dz, [0, 0, 1]);
        fh_normalize(dx);
        var dy = fh_cross(dz, dx);
        fh_normalize(dy);

        var ori = fh_clone(vertices[0]);
        ori = fh_sub(ori, fh_mul(dx, 0.5 * this.element_type.width));

        var extruded_polygon = fh_extruded_polygon.build_extrusion(this.element_type.build_footprint(), [0, 0, length], this.element_type.get_color());

        var matrix = new fh_matrix();
        matrix.load_axis(ori, dx, dy, dz);
        extruded_polygon.matrix = matrix;
        return extruded_polygon;
    }

    //***********************************************************************************
    //**** Vertex operation
    //***********************************************************************************
    /**
     * Performe a vertex operation
     * @param {function} operation : the function that transforms vertices
     */
    vertex_operation(operation) {
        operation(this.vertices[0]);
        operation(this.vertices[1]);
    }

    //***********************************************************************************
    /**
     * Returns bounding box
     * @returns {cn_box}
     */
    get_bounding_box() {
        var box = new cn_box();
        box.enlarge_point(this.vertices[0]);
        box.enlarge_point(this.vertices[1]);
        return box;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_beam(this);
    }

    /**
     * Returns all spaces linked to the beam.
     * @param {cn_scene} scene
     * @returns {cn_space[]}
     */
    get_spaces(scene) {
        const spaces = new Set();

        const space_one = scene.find_space(this.vertices[0]);
        spaces.add(space_one);
        const direction = cn_sub(this.vertices[1], this.vertices[0]);
        let max_distance = cn_normalize(direction);
        let next_wall = null;
        let start_vertex = this.vertices[0];
        do {
            const next_wall_projection = scene.raytrace(start_vertex, direction, max_distance, null, next_wall);
            if (next_wall_projection) {
                max_distance -= next_wall_projection.distance;
                next_wall = next_wall_projection.wall;
                start_vertex = next_wall_projection.point;
                spaces.add(next_wall.spaces[0]);
                spaces.add(next_wall.spaces[1]);
            } else {
                break;
            }
        } while (next_wall !== null)

        return Array.from(spaces);
    }
}

