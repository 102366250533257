'use strict';
import { cn_element_type_visitor } from '..';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//**** Stairs type
//***********************************************************************************
//***********************************************************************************
import { cn_element_type } from './cn_element_type';

export class cn_stairs_type extends cn_element_type {
    constructor() {
        super();
        this.width = 1;
        this.depth = 0;
        this.height = 0.25;
    }

    //***********************************************************************************
    /**
     * Clone
     * @returns {cn_stairs_type}
     */
    // @ts-ignore
    clone() {
        var c = new cn_stairs_type();
        c.width = this.width;
        c.depth = this.depth;
        c.height = this.height;
        return c;
    }

    //***********************************************************************************
    get_generic_label() {
        return 'Type d\'escalier';
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.class_name = 'cn_stairs_type';
        json.ID = this.ID;
        json.width = this.width;
        json.depth = this.depth;
        json.height = this.height;
        return json;
    }

    static unserialize(json) {
        if (typeof (json.ID) != 'string') return false;
        if (json.class_name != 'cn_stairs_type') return false;
        var st = new cn_stairs_type();
        st.ID = json.ID;
        if (typeof (json.width) == 'number')
            st.width = json.width;
        if (typeof (json.depth) == 'number')
            st.depth = json.depth;
        if (typeof (json.height) == 'number')
            st.height = json.height;

        return st;
    }

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_stairs_type(this);
    }
}

