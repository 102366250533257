'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Tool for 3D views
//***********************************************************************************
//***********************************************************************************

import { cn_event_manager } from './cn_event_manager';
import { cn_storey } from '../model/cn_storey';
import { cn_element } from '../model/cn_element';
import { cnx_clone } from '../utils/cn_utilities';
import { cn_3d_building } from '../model/cn_3d_building';
import { cn_storey_element } from '../model/cn_storey_element';

/**
 * A class to represent an 3D ray
 */
export class cn_3d_ray {
    /**
     * @param {Array<number>} origin
     * @param {Array<number>} direction
     */
    constructor(origin, direction) {
        this.origin = cnx_clone(origin);
        this.direction = cnx_clone(direction);
    }
}

/**
 * A class to represent an impact on the 3D scene
 */
export class cn_3d_impact {
    /**
     * @param {Array<number>} position
     * @param {Array<number>} normal
     * @param {cn_storey_element} storey_element
     */
    constructor(position, normal, storey_element = null, object = null) {
        this.position = cnx_clone(position);
        this.normal = cnx_clone(normal);
        this.storey_element = storey_element;
        this.object = object;
    }

    /**
     * Returns true if impact is on given element
     * @param {cn_storey} storey
     * @param {cn_element} element
     * @returns
     */
    matches(storey, element) {
        return (this.storey_element && this.storey_element.storey == storey && this.storey_element.element != element);
    }
}

/**
 * @class cn_view_base - Abstract base class to manage a 3D scene and 3d view
 */
export class cn_view_base extends cn_event_manager {

    //***********************************************************************************
    //**** Constructor
    //***********************************************************************************
    /**
     * Constructor
     * @param {string | HTMLElement} view : identifier of the div for the main view, or html element reference
     * @param {cn_event_manager} parent
     */
    constructor(view, parent = null) {
        super(parent);

        this._container_div = (typeof (view) == 'string') ? document.getElementById(view) : view;
        // this._container_div.innerHTML = "";
        this._3d_building = new cn_3d_building();
        this._scene_3d = this._3d_building._scene_3d;
        this._renderer = null;

        this._comments_visible = true;
    }

    /**
     * Returns the 3D object that matches the storey element
     * @param {cn_storey_element} storey_element
     * @returns {object}
     */
    get_3d_object(storey_element) {
        const obj = this._scene_3d._products.find(ob => ob.cnmap_element && (ob.cnmap_storey.ID == storey_element.storey.ID) && (ob.cnmap_element.ID == storey_element.element.ID));
        if (obj) return obj;
        return null;
    }

    /**
     * Sets selection mode on scene.
     * Default is 0 (no selection).
     * 1 = zone selection
     * 2 = space selection
     * 3 = element selection
     * @param {number} selmode
     */
    set_selection_mode(selmode) {

        var obj = this;

        function get_cnmap_element(id) {
            var object_3d = obj._scene_3d._objects_by_id[id];
            if (typeof (object_3d) == 'object' && obj && typeof (object_3d.cnmap_storey) == 'object' && typeof (object_3d.cnmap_element) == 'object') {
                return { storey: object_3d.cnmap_storey, element: object_3d.cnmap_element };
            }
            return null;
        }

        this._scene_3d.set_selection_mode(selmode);

        if (selmode == 2) this._scene_3d.set_current_flat('all');

        if (selmode != 0) {
            this._scene_3d._cb_selection_changed = ids => {
                obj.refresh_rendering();
                var elements = [];
                ids.forEach(id => {
                    var o = get_cnmap_element(id);
                    if (o) elements.push(o);
                });
                obj.call('selection_changed', elements);
            };
            this._scene_3d._cb_mouseover_changed = id => {
                obj.refresh_rendering();
                obj.call('mouseover_changed', get_cnmap_element(id));
            };

            // @ts-ignore
            // @ts-ignore
            // @ts-ignore
            // @ts-ignore
            this._scene_3d._selection_stealer = (id, ats) => obj.call('element_clicked', get_cnmap_element(id));
        }
    }

    /** clears the selection */
    clear_selection() {
        this._scene_3d.clear_selection();
    }

    /**
     * Sets an element color and opacity, for mouseover
     * @param {number[]} color r, g, b, a of the color, between 0 and 1.
     */
    set_element_mouseover_color(color) {
        this._scene_3d.set_material_color(this._scene_3d._object_highlight_material, color);
    }

    /**
     * Sets an element color and opacity, for mouseover
     * @param {number[]} color r, g, b, a of the color, between 0 and 1.
     */
    set_element_selection_color(color) {
        this._scene_3d.set_material_color(this._scene_3d._object_select_material, color);
    }

    /**
     * Sets a space color and opacity, for mouseover
     * @param {number[]} color r, g, b, a of the color, between 0 and 1.
     */
    set_space_mouseover_color(color) {
        this._scene_3d.set_material_color(this._scene_3d._space_highlight_material, color);
    }

    /**
     * Sets a space color and opacity, for selection
     * @param {number[]} color r, g, b, a of the color, between 0 and 1.
     */
    set_space_selection_color(color) {
        this._scene_3d.set_material_color(this._scene_3d._space_select_material, color);
    }

    /**
     * Sets a space color and opacity, when zone is selected, and space nor selected, nor highlighted
     * @param {number[]} color r, g, b, a of the color, between 0 and 1.
     */
    set_space_in_zone_color(color) {
        this._scene_3d.set_material_color(this._scene_3d._space_selectable_material, color);
    }

    /**
     * Sets an element color and opacity
     * @param {cn_storey} storey
     * @param {cn_element} element
     * @param {number[] | null} color r, g, b, a of the color, between 0 and 1.  If null, will restore the object's original color
     */
    set_element_color(storey, element, color = null) {
        this._scene_3d._products.forEach(p => {
            if (p.cnmap_storey == storey && p.cnmap_element == element) {
                this._scene_3d.set_product_color(p, color);
            }
        });
    }

    /**
     * gets an element's color
     * @param {cn_storey} storey
     * @param {cn_element} element
     * @return {number[] | null}  r, g, b, a of the color, between 0 and 1.  null, if color was not set
     */
    get_element_color(storey, element) {
        var res = null;
        this._scene_3d._products.forEach(p => {
            if (p.cnmap_storey == storey && p.cnmap_element == element) {
                res = this._scene_3d.get_product_color(p);
            }
        });
        return res;
    }

    /**
     * Sets an element visibility
     * @param {cn_storey_element} storey_element
     * @param {boolean} visibility
     */
    set_element_visibility(storey_element, visibility) {
        this._scene_3d._products.forEach(p => {
            if (p.cnmap_storey == storey_element.storey && p.cnmap_element == storey_element.element) {
                this._scene_3d.set_product_visibility(visibility, p.BIMID);
            }
        });
    }

    /**
     * Returns the element visible at a given point.
     * @param {number[]} screen_pos : screen position, in pixels
     * @return {cn_3d_impact}
     */
    get_screen_element(screen_pos) {
        var impact = this._renderer.get_screen_object(screen_pos);
        if (impact == null) return null;
        const cn_impact = new cn_3d_impact(impact.position, impact.normal, null, impact.object);
        // @ts-ignore
        if (typeof (impact.object.cnmap_storey) == 'object')
            cn_impact.storey_element = new cn_storey_element(impact.object.cnmap_element, impact.object.cnmap_storey);
        return cn_impact;
    }

    /**
     * Returns the element visible at a given point.
     * @param {number[]} screen_pos : screen position, in pixels
     * @return {Array<cn_3d_impact>}
     */
    get_screen_elements(screen_pos) {
        const cn_impacts = [];
        this._renderer.get_screen_objects(screen_pos).forEach(impact => {
            const cn_impact = new cn_3d_impact(impact.position, impact.normal);
            // @ts-ignore
            if (typeof (impact.object.cnmap_storey) == 'object')
                cn_impact.storey_element = new cn_storey_element(impact.object.cnmap_element, impact.object.cnmap_storey);
            cn_impacts.push(cn_impact);
        });
        return cn_impacts;
    }

    /**
     * Returns the ray in world corrdinates from a given point.
     * @param {number[]} screen_pos
     * @returns {cn_3d_ray}
     */
    get_screen_ray(screen_pos) {
        const ray = this._renderer.get_screen_ray(screen_pos);
        return new cn_3d_ray(ray.origin, ray.direction);
    }

    update_visibilities() {
    }

    set_draw_comments(value) {
        this._comments_visible = value;
        this.refresh_rendering();
    }

    //***********************************************************************************
    //**** refresh
    //***********************************************************************************
    /**
     * Refresh rendering
     */
    refresh_rendering() {
        this._renderer.refresh_rendering();
    }
}

