import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Resolve } from '@angular/router';

export interface ReferentialEntry {
  slug: string;
  label: string;
}

/**
 * Allow to modulate the influence of a referential entry based on built types
 * */
export interface ReferentialInfluence {
  slug: string;
  builtTypes: string[];
  gWeighting: number;
}

export interface GWeighting {
  gWeighting: number;
}

export interface MinMax {
  min: number;
  max: number;
}

export type IncomeColor = ReferentialEntry & { color: string; noneIdf: number[]; idf: number[] };
export type HeatingSystem = ReferentialEntry & { energy: string; unit: string };
export type LowTemperature = { climateArea: string; altitude: string; temperature: number };

export interface Referential {
  incomeColors: IncomeColor[];
  heatingSystems: HeatingSystem[];
  pacTypes: ReferentialEntry[];
  functions: ReferentialEntry[];
  supportSlabs: ReferentialEntry[];
  connectivities: ReferentialEntry[];
  climateAreas: ReferentialEntry[];
  altitudes: ReferentialEntry[];
  lowTemperatures: LowTemperature[];
  housingFloors: ReferentialEntry[];
  jointness: (ReferentialEntry & GWeighting)[];
  groundPositions: ReferentialEntry[];
  groundPositionInfluence: ReferentialInfluence[];
  builtTypes: (ReferentialEntry & MinMax)[];
  windowRatios: (ReferentialEntry & MinMax & GWeighting)[];
  ventilations: ReferentialEntry[];
  ventilationInfluence: ReferentialInfluence[];
  electricityTypes: ReferentialEntry[];
  electricitySubscriptions: ReferentialEntry[];
  renovationCategories: ReferentialEntry[];
  renovationBuildingElements: ReferentialEntry[];
  renovationInfluence: {
    renovationBuildingElement: string;
    renovationCategory: string;
    builtTypes: string[];
    gWeighting: number;
  }[];
  waterRegimes: MinMax[];
  tubeDiameters: (ReferentialEntry & MinMax)[];
  gridTypes: ReferentialEntry[];
}

export type BasicEntryName = Exclude<keyof Referential, 'renovation'>;

export const NO_RENOVATION_SLUG = 'NONE';

@Injectable({
  providedIn: 'root',
})
export class PacReferentialStore implements Resolve<Referential> {
  private readonly _referential$ = new BehaviorSubject<Referential>(null);

  constructor(private http: HttpClient) {}

  get referentialSnapshot(): Referential {
    return this._referential$.getValue();
  }

  resolve(): Observable<Referential> {
    return this.http
      .get<Referential>('/assets/datas/pac.referential.json')
      .pipe(tap(ref => this._referential$.next(ref)));
  }

  getRenovationEntries(): { categories: ReferentialEntry[]; buildingElements: ReferentialEntry[] } {
    return {
      categories: this.referentialSnapshot.renovationCategories,
      buildingElements: this.referentialSnapshot.renovationBuildingElements,
    };
  }

  /**
   *
   * @param climateArea
   * @param altitude
   * @return number: low temperature corresponding to the area
   * @return null: not enough info to find corresponding info
   * @return undefined: no low temperature registered to the corresponding area
   */
  getLowTemperature(climateArea: string, altitude: string): number | null | undefined {
    if (!climateArea || !altitude) {
      return null;
    }

    return this.referentialSnapshot.lowTemperatures.find(
      lt => lt.climateArea === climateArea && lt.altitude === altitude,
    )?.temperature;
  }

  isDoubleService(functionSlug: string): boolean {
    return functionSlug === 'DOUBLE_SERVICE';
  }

  isThreePhase(electricityType: string): boolean {
    return electricityType === 'TRI';
  }
}
