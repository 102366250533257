import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { QuitConfigurationConfirmModalComponent } from './components/quit-configuration-confirm-modal/quit-configuration-confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NotificationComponent } from './components/notification/notification.component';
import { MultisideContainerComponent } from './components/multiside-container/multiside-container.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateRightsPipe } from './pipes/translate-rights.pipe';
import { DisplayUserPipe } from './pipes/display-user.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { AbsolutePipe } from './pipes/absolute.pipe';
import { PrefixedNumberPipe } from './pipes/prefixed-number.pipe';
import { TagToTextPipe } from './pipes/tagToText.pipe';
import { LongPressDirective } from './directives/long-press/long-press.directive';
import { IsAuthByRightDirective } from './directives/is-auth/is-auth-by-right.directive';
import { ScrolledDirective } from './directives/scrolled/scrolled.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconComponent } from './components/icon/icon.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { ConfirmDialogComponent } from './components/confirmation/confirm-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CnSpinnerComponent } from './components/cn-spinner/cn-spinner.component';
import { DpeIconComponent } from './components/dpe/dpe-icon.component';
import { ViewerBuilding3dComponent } from './components/viewer-building-3d/viewer-building-3d.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AbstractProjectStepComponent } from './components/abstract/abstract-project-step.component';
import { MatInputModule } from '@angular/material/input';
import { ConfigStepSpecPipe } from './pipes/config-step-spec.pipe';
import { IncrementInputModule } from './components/increment-input/increment-input.module';
import { ConfigCountStepsPipe } from './pipes/config-count-steps.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateAuthorityPipe } from './pipes/translate-authority.pipe';
import { MatSelectModule } from '@angular/material/select';
import { AbbreviateFirstnamePipe } from './pipes/abreviate-firstname.pipe';
import { ArrayHasPipe } from './pipes/array-has.pipe';
import { IsAuthByPerimeterDirective } from './directives/is-auth/is-auth-by-perimeter.directive';
import { TranslateCompanyTypePipe } from './pipes/translate-company-type.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { CivilityPipe } from './pipes/civility.pipe';
import { AddedHeaterNamePipe } from './pipes/added-heater-name.pipe';
import { ArrayIncludesPipe } from './pipes/array-includes.pipe';
import { PacWaterVolumeOptionsPipe } from './pipes/pac-water-volume-options.pipe';
import { ArraySlicePipe } from './pipes/array-slice.pipe';
import { TrustUrlPipe } from './pipes/trust-url.pipe';
import { AuthorizationPipe } from './pipes/authorization.pipe';
import { IsCreatorAndHasStatusPipe } from './pipes/is-creator-and-has-status.pipe';

@NgModule({
  declarations: [
    CnSpinnerComponent,
    QuitConfigurationConfirmModalComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    IconComponent,
    MultisideContainerComponent,
    MarkdownComponent,
    FormContainerComponent,
    FileUploaderComponent,
    TranslateRightsPipe,
    TranslateAuthorityPipe,
    TranslateCompanyTypePipe,
    DisplayUserPipe,
    AddressPipe,
    AbsolutePipe,
    PrefixedNumberPipe,
    TagToTextPipe,
    LongPressDirective,
    IsAuthByRightDirective,
    IsAuthByPerimeterDirective,
    ScrolledDirective,
    TrustHtmlPipe,
    DpeIconComponent,
    ViewerBuilding3dComponent,
    AbstractProjectStepComponent,
    ConfigStepSpecPipe,
    ConfigCountStepsPipe,
    AbbreviateFirstnamePipe,
    ArrayHasPipe,
    FilterArrayPipe,
    CivilityPipe,
    AddedHeaterNamePipe,
    ArrayIncludesPipe,
    PacWaterVolumeOptionsPipe,
    ArraySlicePipe,
    TrustUrlPipe,
    AuthorizationPipe,
    IsCreatorAndHasStatusPipe,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    IncrementInputModule,
    MatTooltipModule,
    MatSelectModule,
  ],
  exports: [
    CnSpinnerComponent,
    QuitConfigurationConfirmModalComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    IconComponent,
    MultisideContainerComponent,
    MarkdownComponent,
    FormContainerComponent,
    FileUploaderComponent,
    TranslateRightsPipe,
    TranslateAuthorityPipe,
    DisplayUserPipe,
    AddressPipe,
    AbsolutePipe,
    PrefixedNumberPipe,
    TagToTextPipe,
    LongPressDirective,
    IsAuthByRightDirective,
    IsAuthByPerimeterDirective,
    ScrolledDirective,
    TrustHtmlPipe,
    DpeIconComponent,
    ViewerBuilding3dComponent,
    AbstractProjectStepComponent,
    ConfigStepSpecPipe,
    ConfigCountStepsPipe,
    AbbreviateFirstnamePipe,
    ArrayHasPipe,
    TranslateCompanyTypePipe,
    FilterArrayPipe,
    CivilityPipe,
    AddedHeaterNamePipe,
    ArrayIncludesPipe,
    PacWaterVolumeOptionsPipe,
    ArraySlicePipe,
    TrustUrlPipe,
    AuthorizationPipe,
    IsCreatorAndHasStatusPipe,
  ],
})
export class SharedModule {}
