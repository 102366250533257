import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from '../../shared/model/user.model';
import { Company } from '../../shared/model/company.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationStore {
  private currentUser$ = new BehaviorSubject<User | undefined>(undefined); // undefined => on ne sait pas encore si l'utilisateur est loggué ou non
  private currentUserCompanies$ = new BehaviorSubject<Company[] | undefined>(undefined); // undefined => on ne sait pas encore si l'utilisateur est loggué ou non

  get currentUserCompaniesSnapshot(): Company[] {
    return this.currentUserCompanies$.getValue();
  }

  setCurrentUser(user: User) {
    this.currentUser$.next(user);
  }

  unsetCurrentUser() {
    this.currentUser$.next(null); // null => on sait que l'utilisateur n'est pas loggué
  }

  getCurrentUser(): Observable<User> {
    return this.currentUser$.pipe(filter(user => user !== undefined));
  }

  getCurrentUserSnapshot(): User {
    return this.currentUser$.getValue();
  }

  getCurrentCompanies(): Observable<Company[]> {
    return this.currentUserCompanies$.pipe(filter(companies => companies !== undefined));
  }

  isSuperAdmin(): Observable<boolean> {
    return this.currentUser$.pipe(
      filter(user => user !== undefined),
      map(user => !!user && User.isSuperAdmin(user)),
    );
  }

  setCompanies(companies: Company[]) {
    this.currentUserCompanies$.next(companies);
  }
}
