import { Directive, ElementRef, EventEmitter, NgModule, OnDestroy, Output, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { CommonModule } from '@angular/common';

@Directive({
  selector: '[appScrolled]',
})
export class ScrolledDirective implements OnDestroy {
  @Output() scrollPosition: EventEmitter<number> = new EventEmitter<number>();

  private scrollEvent$;

  constructor(private renderer: Renderer2, private el: ElementRef) {
    this.scrollEvent$ = fromEvent(this.el.nativeElement, 'scroll').subscribe((e: any) => {
      if (e.target.scrollTop > 0) {
        this.renderer.addClass(this.el.nativeElement, 'scrolled');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'scrolled');
      }
      this.scrollPosition.emit(e.target.scrollTop);
    });
  }

  ngOnDestroy() {
    this.scrollEvent$.unsubscribe();
  }
}
