'use strict';

import { cn_dist } from './cn_utilities';
import { CN_MATERIAL_TYPE_FACING, CN_MATERIAL_TYPE_INSULATOR, CN_MATERIAL_TYPE_LIGHT_STRUCTURE } from '../model/cn_material';
import { CODES_BIM_PARAMETRES_ESPACES, CODES_BIM_PARAMETRES_OUVRAGES, ENUMS_PARAMETRES_OUVRAGES } from './cn_bbp_constants';
import { nearest_bbp_color_label } from './bbp_colors';

function truncateDecimals(number, digits = 2) {
    const multiplier = Math.pow(10, digits),
        adjustedNum = number * multiplier,
        truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

    return truncatedNum / multiplier;
}

export class BbpHelper {

    getSpaceParameters(space, storey) {
        const result = {};
        result[CODES_BIM_PARAMETRES_ESPACES.spacePerimeter] = truncateDecimals(space.perimeter);
        result[CODES_BIM_PARAMETRES_ESPACES.spaceHeight] = space.ceiling_height !== -1 ? space.ceiling_height : storey.height;
        if (space.is_heated_relevant()) {
            result[CODES_BIM_PARAMETRES_ESPACES.spaceHeated] = space.is_heated();
        }
        return result;
    }

    getWallParameters(wall, storey) {
        const result = {};
        if (!wall.balcony) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.wallThickness] = wall.wall_type.thickness;
            result[CODES_BIM_PARAMETRES_OUVRAGES.wallLength] = wall.bounds.length;
            result[CODES_BIM_PARAMETRES_OUVRAGES.wallHeight] = storey.height;
            const structure = wall.wall_type.layers.find(l => !!ENUMS_PARAMETRES_OUVRAGES.wallStructures[l.code])
            if (structure) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallStructure] = ENUMS_PARAMETRES_OUVRAGES.wallStructures[structure.code];
            }
            const uValue = wall.wall_type.get_U();
            if (uValue) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallUValue] = uValue;
            }
            const insulationMaterials = wall.wall_type.layers.filter(it => it.type === CN_MATERIAL_TYPE_INSULATOR)
            const insulationMaterial1 = insulationMaterials.length >= 1 ? insulationMaterials[0] : null;
            const insulationMaterial2 = insulationMaterials.length >= 2 ? insulationMaterials[1] : null;
            const insulationMaterial1Code = insulationMaterial1 ? insulationMaterial1.code : null;
            const insulationMaterial1Width = insulationMaterial1 ? insulationMaterial1.thickness : null;
            const insulationMaterial2Code = insulationMaterial2 ? insulationMaterial2.code : null;
            const insulationMaterial2Width = insulationMaterial2 ? insulationMaterial2.thickness : null;
            if (insulationMaterial1) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallInsulationMaterial] = ENUMS_PARAMETRES_OUVRAGES.wallInsulators[insulationMaterial1Code];
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallInsulationWidth] = insulationMaterial1Width * 1000; // Convert meters to millimeters !
            }
            if (insulationMaterial2) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallInsulationMaterial2] = ENUMS_PARAMETRES_OUVRAGES.wallInsulators[insulationMaterial2Code];
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallInsulationWidth2] = insulationMaterial2Width * 1000; // Convert meters to millimeters !
            }
            const facingMaterial = wall.wall_type.layers.find(it => it.type === CN_MATERIAL_TYPE_FACING);
            if (facingMaterial && facingMaterial.code) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallFacing] = ENUMS_PARAMETRES_OUVRAGES.wallFacings[facingMaterial.code];
            }
            const lightStructureMaterial = wall.wall_type.layers.find(it => it.type === CN_MATERIAL_TYPE_LIGHT_STRUCTURE);
            if (lightStructureMaterial && lightStructureMaterial.code) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.wallLightStructure] = ENUMS_PARAMETRES_OUVRAGES.wallLightStructures[lightStructureMaterial.code];
            }
        } else {
            result[CODES_BIM_PARAMETRES_OUVRAGES.balconyThickness] = wall.wall_type.thickness;
            result[CODES_BIM_PARAMETRES_OUVRAGES.wallLength] = wall.bounds.length;
            result[CODES_BIM_PARAMETRES_OUVRAGES.balconyHeight] = wall.wall_type.height;
        }
        return result;
    }

    getOpeningParameters(opening) {
        const result = {};
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingThickness] = opening.thickness;
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingWidth] = opening.opening_type.width;
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingHeight] = opening.opening_type.height;
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingShift] = opening.opening_type.z;
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingPanels] = opening.opening_type.panels;

        if (typeof (opening.opening_type.get_area) == 'function')
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingArea] = opening.opening_type.get_area();

        if (typeof (opening.opening_type.get_glazing_area) == 'function')
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingGlazingArea] = opening.opening_type.get_glazing_area();

        const openingFrame = ENUMS_PARAMETRES_OUVRAGES.openingFrames[opening.opening_type.frame]
        if (!!openingFrame) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingFrame] = openingFrame;
        }

        const openingGlazing = ENUMS_PARAMETRES_OUVRAGES.openingGlazings[opening.opening_type.glazing];
        if (!!openingGlazing) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingGlaze] = openingGlazing;
        }
        // Export glazing gaz only for double glazing
        if (opening.opening_type.glazing == 'double') {
            const glazing_gaz = opening.opening_type.glazing_gaz;
            let bbpGlazingGaz, bbpGlazingThickness;
            switch (glazing_gaz) {
                case 'air_6': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '6';
                    break;
                }
                case 'air_8': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '8';
                    break;
                }
                case 'air_12': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '12';
                    break;
                }
                case 'air_16': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '16';
                    break;
                }
                case 'argon_16': {
                    bbpGlazingGaz = 'Argon';
                    bbpGlazingThickness = '16';
                    break;
                }
            }
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingGlazingGaz] = bbpGlazingGaz;
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingGlazingThickness] = bbpGlazingThickness;
        }


        const openingType = ENUMS_PARAMETRES_OUVRAGES.openingTypes[opening.opening_type.opening];
        if (!!openingType) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingOpener] = openingType;
        }
        const openingTransom = ENUMS_PARAMETRES_OUVRAGES.openingTransoms[opening.opening_type.transom];
        if (!!openingTransom) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.openingTransom] = openingTransom;
        }
        if (opening.opening_type.category === 'window') {
            const openingSill = ENUMS_PARAMETRES_OUVRAGES.openingSills[opening.opening_type.sill];
            if (!!openingSill) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.openingSill] = openingSill;
            }
            const openingClosing = ENUMS_PARAMETRES_OUVRAGES.openingClosings[opening.opening_type.closing];
            if (!!openingClosing) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.openingClosing] = openingClosing;
            }
        }

        result[CODES_BIM_PARAMETRES_OUVRAGES.openingUw] = opening.opening_type.Uw;
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingSw] = opening.opening_type.Sw;
        result[CODES_BIM_PARAMETRES_OUVRAGES.openingTl] = opening.opening_type.Tl;

        return result;
    }

    getStairParameters(stair) {
        const result = {};
        result[CODES_BIM_PARAMETRES_OUVRAGES.stairHeight] = stair.stair_height;
        result[CODES_BIM_PARAMETRES_OUVRAGES.stairNumber] = stair.actual_stair_number;
        result[CODES_BIM_PARAMETRES_OUVRAGES.stairDepth] = stair.stair_depth;
        return result;
    }

    getBeamParameters(beam, storey) {
        const result = {};
        result[CODES_BIM_PARAMETRES_OUVRAGES.beamWidth] = beam.element_type.width * 1000;
        result[CODES_BIM_PARAMETRES_OUVRAGES.beamThickness] = beam.element_type.thickness * 1000;
        // BB
        result[CODES_BIM_PARAMETRES_OUVRAGES.beamBBWidth] = beam.element_type.width;
        if (beam.vertices) {
            let a = beam.vertices[0]
            let b = beam.vertices[1]
            const distance = truncateDecimals(Math.sqrt(Math.pow(cn_dist(a, b), 2) + Math.pow(a[2] - b[2], 2)));
            result[CODES_BIM_PARAMETRES_OUVRAGES.beamBBLength] = distance;
        }
        if (beam.height !== undefined && beam.height !== null) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.beamBBHeight] = beam.height ? truncateDecimals(beam.height) : truncateDecimals(storey.height);
        }
        const beamMaterial = ENUMS_PARAMETRES_OUVRAGES.beamMaterials[beam.element_type.material];
        if (!!beamMaterial) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.beamMaterial] = beamMaterial;
        }
        const beamShape = ENUMS_PARAMETRES_OUVRAGES.beamShapes[beam.element_type.shape];
        if (!!beamShape) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.beamShape] = beamShape;
        }
        return result;
    }

    getPipeParameters(pipe) {
        const result = {};
        result[CODES_BIM_PARAMETRES_OUVRAGES.pipeDiameter] = pipe.element_type.diameter;

        let a = pipe.vertices[0]
        let b = pipe.vertices[1]
        if (a[0] === b[0]) {
            // Vertical
            result[CODES_BIM_PARAMETRES_OUVRAGES.pipeLength] = truncateDecimals(Math.abs(a[2] - b[2]));
        } else {
            result[CODES_BIM_PARAMETRES_OUVRAGES.pipeLength] = truncateDecimals(Math.sqrt(Math.pow(cn_dist(a, b), 2) + Math.pow(a[2] - b[2], 2)));
        }

        const pipeMaterial = ENUMS_PARAMETRES_OUVRAGES.pipeMaterials[pipe.element_type.material];
        if (!!pipeMaterial) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.pipeMaterial] = pipeMaterial;
        }
        return result;
    }

    getSlabParameters(slab) {
        const result = {};
        const bb = slab.get_bounding_box();
        result[CODES_BIM_PARAMETRES_OUVRAGES.slabThickness] = slab.slab_type.thickness;
        result[CODES_BIM_PARAMETRES_OUVRAGES.slabWidth] = bb.size[0];
        result[CODES_BIM_PARAMETRES_OUVRAGES.slabLength] = bb.size[1];
        const structure = slab.slab_type.layers.find(l => !!ENUMS_PARAMETRES_OUVRAGES.slabStructures[l.code]);
        if (structure) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.slabStructure] = ENUMS_PARAMETRES_OUVRAGES.slabStructures[structure.code];
        }
        return result;
    }

    getRoofParameters(slab) {
        const result = {};
        //*** full area  */
        const polygon = slab.build_3d_polygon(0, true);
        result[CODES_BIM_PARAMETRES_OUVRAGES.roofArea] = polygon.get_area();
        result[CODES_BIM_PARAMETRES_OUVRAGES.roofThickness] = slab.slab_type.thickness;
        return result;
    }

    getObjectParameters(object) {
        const result = {};
        result[CODES_BIM_PARAMETRES_OUVRAGES.objectBBWidth] = object.size[0];
        result[CODES_BIM_PARAMETRES_OUVRAGES.objectBBLength] = object.size[1];
        result[CODES_BIM_PARAMETRES_OUVRAGES.objectBBHeight] = object.size[2];
        return result;
    }

    getWallFacingParameters(wall, side) {
        const result = {};
        const facing = wall.facings[side];
        if (facing) {
            if (wall.is_outdoor(side)) {
                result[CODES_BIM_PARAMETRES_OUVRAGES.exteriorWallFacing] = ENUMS_PARAMETRES_OUVRAGES.exteriorWallFacingTypes[facing.category];
            } else {
                result[CODES_BIM_PARAMETRES_OUVRAGES.interiorWallFacing] = ENUMS_PARAMETRES_OUVRAGES.interiorWallFacingTypes[facing.category];
            }
            result[CODES_BIM_PARAMETRES_OUVRAGES.building_color] = nearest_bbp_color_label(facing.color);
        }
        return result;
    }

    getFloorFacingParameters(space) {
        const result = {};
        const facing = space.facings[0];
        if (facing) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.floorFacing] = ENUMS_PARAMETRES_OUVRAGES.floorFacingTypes[facing.category];
            result[CODES_BIM_PARAMETRES_OUVRAGES.building_color] = nearest_bbp_color_label(facing.color);
        }
        return result;
    }

    getCeilingFacingParameters(space) {
        const result = {};
        const facing = space.facings[1];
        if (facing) {
            result[CODES_BIM_PARAMETRES_OUVRAGES.ceilingFacing] = ENUMS_PARAMETRES_OUVRAGES.ceilingFacingTypes[facing.category];
            result[CODES_BIM_PARAMETRES_OUVRAGES.building_color] = nearest_bbp_color_label(facing.color);
        }
        return result;
    }
}

