// @ts-check
'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_background_map : a background map to be displayed behind a scene
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_add, cn_box, cn_clone, cn_dot, cn_mul, cn_normal, cn_sub } from '../utils/cn_utilities';
import { to_image, to_world } from '../utils/cn_image_utils';

export class cn_background_map extends cn_element {
    constructor() {
        super();
        this.image_id = '';
        this.image_size = [0, 0];
        this.scale = 0; //**** world size of a pixel
        this.offset = [0, 0];
        this.orientation = 0;
        this.reference_points = [[0.6, 0.1], [0.8, 0.1]];
        this.background_opacity = 0.5;
        this.order = 1;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        const json = {};
        json.ID = this.ID;
        json.image_id = this.image_id;
        json.image_size = [this.image_size[0], this.image_size[1]];
        json.scale = this.scale;
        json.offset = [this.offset[0], this.offset[1]];
        json.orientation = this.orientation;
        json.reference_points = [cn_clone(this.reference_points[0]), cn_clone(this.reference_points[1])];
        json.background_opacity = this.background_opacity;
        json.order = this.order;
        return json;
    }

    static unserialize(json) {
        if (typeof (json) != 'object') return false;
        if (typeof (json.image_id) != 'string') return false;
        if (typeof (json.image_size) != 'object') return false;

        const background_map = new cn_background_map();
        if (typeof (json.ID) == 'string')
            background_map.ID = json.ID;
        background_map.image_id = json.image_id;
        background_map.image_size[0] = json.image_size[0];
        background_map.image_size[1] = json.image_size[1];
        if (typeof (json.scale) == 'number')
            background_map.scale = json.scale;
        if (typeof (json.order) == 'number')
            background_map.order = json.order;
        if (typeof (json.background_opacity) == 'number' && json.background_opacity >= 0 && json.background_opacity <= 1)
            background_map.background_opacity = json.background_opacity;
        if (typeof (json.offset) == 'object') {
            if (typeof (json.offset[0]) == 'number')
                background_map.offset[0] = json.offset[0];
            if (typeof (json.offset[1]) == 'number')
                background_map.offset[1] = json.offset[1];
        }
        if (typeof (json.orientation) == 'number')
            background_map.orientation = json.orientation;

        if (typeof (json.reference_points) == 'object') {
            for (var k = 0; k < 2; k++) {
                if (typeof (json.reference_points[k]) != 'object') continue;
                if (typeof (json.reference_points[k][0]) == 'number')
                    background_map.reference_points[k][0] = json.reference_points[k][0];
                if (typeof (json.reference_points[k][1]) == 'number')
                    background_map.reference_points[k][1] = json.reference_points[k][1];
            }
        }
        return background_map;
    }

    //***********************************************************************************
    //**** svg display
    //***********************************************************************************
    draw(camera, add_classes = [], opacity = 1, delegate_url = '') {
        var html = '';
        if (this.image_id == '') return html;
        var url = delegate_url;
        if (url == '') {
            if (typeof (cn_background_map['image_id_to_url']) != 'function') return html;
            url = cn_background_map['image_id_to_url'](this.image_id);
        }
        if (url == '') return html;

        var p0 = camera.world_to_screen(this.offset);
        var w = this.image_size[0] * this.scale * camera.world_to_screen_scale;
        var h = this.image_size[1] * this.scale * camera.world_to_screen_scale;

        html += '<g transform=\'translate(' + p0[0] + ',' + p0[1] + ') rotate(' + (-this.orientation) + ') translate(' + (-0.5 * w) + ',' + (-0.5 * h) + ') \'>';

        html += '<image xlink:href=\'' + url + '\' ';
        html += 'x=\'0\' ';
        html += 'y=\'0\' ';
        html += 'width=\'' + w + '\' ';
        html += 'height=\'' + h + '\' ';
        if (opacity != 1)
            html += 'opacity=\'' + opacity + '\' ';
        html += ' />';

        html += '</g>';

        return html;
    }

    //***********************************************************************************
    //**** simple draw display
    //***********************************************************************************
    simple_draw(camera, add_classes = []) {
        return this.draw(camera, add_classes);
    }

    to_world(p) {
        return to_world(p, this.orientation, this.offset, this.image_size, this.scale);
    }

    get_world_size() {
        return [this.image_size[0] * this.scale, this.image_size[1] * this.scale];
    }

    /**
     * @param {string} image_id
     * @return string
     */
    static image_id_to_url(image_id) {
        return undefined;
    }

    to_image(p) {
        return to_image(p, this.orientation, this.offset, this.image_size, this.scale);
    }

    contains(p) {
        var pop = this.to_image(p);
        if (pop[0] < 0 || pop[0] > 1) return false;
        if (pop[1] < 0 || pop[1] > 1) return false;
        return true;
    }

    get_bounding_box() {
        let box = new cn_box();
        let limits = [[0, 0], [0, 1], [1, 0], [1, 1]];
        for (let l in limits) {
            box.enlarge_point(this.to_world(limits[l]));
        }
        return box;
    }
}


