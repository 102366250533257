import { EmitterPowerSpecifications } from '../model/emitter-power-specifications';

export enum EMITTER_CODE_BIM {
  FONTE_2_LAMES = 'radiateur_a_eau_fonte_2_lames',
  FONTE_3_LAMES = 'radiateur_a_eau_fonte_3_lames',
  FONTE_4_LAMES = 'radiateur_a_eau_fonte_4_lames',
  FONTE_5_LAMES = 'radiateur_a_eau_fonte_5_lames',
  FONTE_6_LAMES = 'radiateur_a_eau_fonte_6_lames',
  ACIER_TYPE_10 = 'radiateur_a_eau_acier_10',
  ACIER_TYPE_11 = 'radiateur_a_eau_acier_11',
  ACIER_TYPE_20 = 'radiateur_a_eau_acier_20',
  ACIER_TYPE_21 = 'radiateur_a_eau_acier_21',
  ACIER_TYPE_22 = 'radiateur_a_eau_acier_22',
  ACIER_TYPE_33 = 'radiateur_a_eau_acier_33',
  PANNEAUX_10 = 'radiateur_a_eau_panneaux_10',
  PANNEAUX_11 = 'radiateur_a_eau_panneaux_11',
  PANNEAUX_20 = 'radiateur_a_eau_panneaux_20',
  PANNEAUX_21 = 'radiateur_a_eau_panneaux_21',
  PANNEAUX_22 = 'radiateur_a_eau_panneaux_22',
  LAMELLES = 'radiateur_a_eau_lamelles',
  ALUMINIUM = 'radiateur_a_eau_alu',
  NOURRICE_PLANCHER_CHAUFFANT = 'radiateur_a_eau_nourrice_plancher_chauffant',
  SECHE_SERVIETTE_EAU = 'radiateur_a_eau_seche_serviette',
  CONVECTEUR_ELECTRIQUE = 'radiateur_electrique_convecteur',
  SECHE_SERVIETTE_ELECTRIQUE = 'radiateur_electrique_seche_serviette',
}

export const ELECTRICAL_EMITTER = [EMITTER_CODE_BIM.CONVECTEUR_ELECTRIQUE, EMITTER_CODE_BIM.SECHE_SERVIETTE_ELECTRIQUE];

export const RAW_POWER_EMITTER = [...ELECTRICAL_EMITTER, EMITTER_CODE_BIM.SECHE_SERVIETTE_EAU];

export const EMITTER_LABELS = new Map([
  [EMITTER_CODE_BIM.FONTE_2_LAMES, 'Fonte 2 lames'],
  [EMITTER_CODE_BIM.FONTE_3_LAMES, 'Fonte 3 lames'],
  [EMITTER_CODE_BIM.FONTE_4_LAMES, 'Fonte 4 lames'],
  [EMITTER_CODE_BIM.FONTE_5_LAMES, 'Fonte 5 lames'],
  [EMITTER_CODE_BIM.FONTE_6_LAMES, 'Fonte 6 lames'],
  [EMITTER_CODE_BIM.ACIER_TYPE_10, 'Acier type 10'],
  [EMITTER_CODE_BIM.ACIER_TYPE_11, 'Acier type 11'],
  [EMITTER_CODE_BIM.ACIER_TYPE_20, 'Acier type 20'],
  [EMITTER_CODE_BIM.ACIER_TYPE_21, 'Acier type 21'],
  [EMITTER_CODE_BIM.ACIER_TYPE_22, 'Acier type 22'],
  [EMITTER_CODE_BIM.ACIER_TYPE_33, 'Acier type 33'],
  [EMITTER_CODE_BIM.PANNEAUX_10, 'Panneaux à tubes plats type 10'],
  [EMITTER_CODE_BIM.PANNEAUX_11, 'Panneaux à tubes plats type 11'],
  [EMITTER_CODE_BIM.PANNEAUX_20, 'Panneaux à tubes plats type 20'],
  [EMITTER_CODE_BIM.PANNEAUX_21, 'Panneaux à tubes plats type 21'],
  [EMITTER_CODE_BIM.PANNEAUX_22, 'Panneaux à tubes plats type 22'],
  [EMITTER_CODE_BIM.LAMELLES, 'À lamelles'],
  [EMITTER_CODE_BIM.ALUMINIUM, 'Aluminium'],
  [EMITTER_CODE_BIM.NOURRICE_PLANCHER_CHAUFFANT, 'Nourrice plancher chauffant'],
  [EMITTER_CODE_BIM.SECHE_SERVIETTE_EAU, 'Sèche serviette à eau'],
  [EMITTER_CODE_BIM.CONVECTEUR_ELECTRIQUE, 'Convecteur électrique'],
  [EMITTER_CODE_BIM.SECHE_SERVIETTE_ELECTRIQUE, 'Sèche serviette électrique'],
]);

export const EMITTER_SPECIFICATIONS = new Map([
  [EMITTER_CODE_BIM.FONTE_2_LAMES, new EmitterPowerSpecifications(0, 0.1709, 0, 1.29)],
  [EMITTER_CODE_BIM.FONTE_3_LAMES, new EmitterPowerSpecifications(-0.00036759, 0.25777, 0, 1.3)],
  [EMITTER_CODE_BIM.FONTE_4_LAMES, new EmitterPowerSpecifications(-0.00034257, 0.30564, 0, 1.33)],
  [EMITTER_CODE_BIM.FONTE_5_LAMES, new EmitterPowerSpecifications(0, 0.34483, 0, 1.33)],
  [EMITTER_CODE_BIM.FONTE_6_LAMES, new EmitterPowerSpecifications(-0.0006304, 0.43353, 0, 1.33)],
  [EMITTER_CODE_BIM.ACIER_TYPE_10, new EmitterPowerSpecifications(0, 0.0864, 0.6402, 1.2)],
  [EMITTER_CODE_BIM.ACIER_TYPE_11, new EmitterPowerSpecifications(0, 0.1264, 1.993, 1.35)],
  [EMITTER_CODE_BIM.ACIER_TYPE_20, new EmitterPowerSpecifications(0, 0.1377, 1.461, 1.25)],
  [EMITTER_CODE_BIM.ACIER_TYPE_21, new EmitterPowerSpecifications(-0.0006488, 0.2486, 0.6468, 1.265)],
  [EMITTER_CODE_BIM.ACIER_TYPE_22, new EmitterPowerSpecifications(-0.00117, 0.376, -0.3186, 1.28)],
  [EMITTER_CODE_BIM.ACIER_TYPE_33, new EmitterPowerSpecifications(-0.002277, 0.6162, -2.547, 1.3)],
  [EMITTER_CODE_BIM.PANNEAUX_10, new EmitterPowerSpecifications(0, 0.103995, 0.233125, 1.24)],
  [EMITTER_CODE_BIM.PANNEAUX_11, new EmitterPowerSpecifications(-0.000371318, 0.18476, 0.280458, 1.24)],
  [EMITTER_CODE_BIM.PANNEAUX_20, new EmitterPowerSpecifications(-0.000220132, 0.20334, 0.377093, 1.255)],
  [EMITTER_CODE_BIM.PANNEAUX_21, new EmitterPowerSpecifications(-0.000611921, 0.246786, 1.26078, 1.25)],
  [EMITTER_CODE_BIM.PANNEAUX_22, new EmitterPowerSpecifications(-0.000932001, 0.329535, 0.702686, 1.25)],
  [`${EMITTER_CODE_BIM.LAMELLES}_6.5`, new EmitterPowerSpecifications(0, 0.1717, 1.028, 1.27)],
  [`${EMITTER_CODE_BIM.LAMELLES}_9.5`, new EmitterPowerSpecifications(0, 0.2235, 1.604, 1.3)],
  [`${EMITTER_CODE_BIM.LAMELLES}_12`, new EmitterPowerSpecifications(0, 0.266, 1.672, 1.26)],
  [`${EMITTER_CODE_BIM.ALUMINIUM}_8`, new EmitterPowerSpecifications(-0.00035283, 0.27608, 0.25327, 1.29)],
  [`${EMITTER_CODE_BIM.ALUMINIUM}_9.5`, new EmitterPowerSpecifications(-0.002057, 0.53447, -7.6013, 1.34)],
  [`${EMITTER_CODE_BIM.ALUMINIUM}_14`, new EmitterPowerSpecifications(0.0031389, 0.082972, 4.2534, 1.3)],
]);
