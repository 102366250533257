import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  transform(
    value: Array<any>,
    filterFunction: (element: any, index?: number, source?: Array<any>) => boolean,
  ): Array<any> {
    return value.filter(filterFunction);
  }
}
