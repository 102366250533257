import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

export type IconTheme = 'angular-material-native' | 'mdi' | 'fa';

export class Icon {
  constructor(public name: string, public theme: IconTheme = 'angular-material-native') {}
}

/**
 * Affichage d'une icône parmi les 3 librairies suivantes :
 * - Angular Material
 * - Material Design
 * - Font Awesome
 *
 * Usage :
 *
 * - Angular Material
 *
 * `<lib-icon>home</lib-icon>`
 *
 * ou
 *
 * `<lib-icon iconName="home"></lib-icon>`
 *
 * ou
 *
 * `<lib-icon theme="angular-material-native" iconName="home"></lib-icon>`
 *
 * - Material Design
 *
 * `<lib-icon theme="mdi" iconName="home"></lib-icon>`
 *
 * - Font Awesome
 *
 * `<lib-icon theme="fa" iconName="home"></lib-icon>`
 */
@Component({
  selector: 'lib-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  /** Thème d'icône à utiliser */
  @Input() theme: IconTheme = 'angular-material-native';

  /** Nom de l'icône dans le thème */
  @Input() iconName: string;

  /** Classe à appliquer sur l'élément <mat-icon> généré */
  @Input() class: undefined | string | string[];

  @ViewChild('template', { static: true }) template;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
