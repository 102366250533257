import { Pipe, PipeTransform } from '@angular/core';
import { CompanyType } from '../model/company.model';

@Pipe({ name: 'translateCompanyType' })
export class TranslateCompanyTypePipe implements PipeTransform {
  transform(type: CompanyType, plural = false): string {
    switch (type) {
      case 'PRODUCTIVE':
        return 'Productive' + (plural ? 's' : '');
      case 'ADMINISTRATIVE':
        return 'Administrative' + (plural ? 's' : '');
      case 'INDIVIDUAL':
        return 'Individuelle' + (plural ? 's' : '');
    }
  }
}
