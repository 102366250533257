import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayIncludes',
})
export class ArrayIncludesPipe implements PipeTransform {
  transform(value: Array<any>, searchElement: any): boolean {
    return value.includes(searchElement);
  }
}
