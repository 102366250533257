'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_vertex : a vertex to draw walls
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_vertex } from './cn_vertex';
import {
    cn_add,
    cn_box,
    cn_clone,
    cn_dist,
    cn_dot, cn_mul,
    cn_normal,
    cn_normalize,
    cn_point_on_segment,
    cn_sub,
    cn_copy,
    cn_filter_contour,
    cn_clone_3d,
    cnx_add,
    cnx_dist,
    cnx_normalize,
    cnx_sub,
    cnx_dot,
    cnx_copy,
    cnx_mul
} from '../utils/cn_utilities';
import { fh_dist, fh_polygon } from '@enerbim/fh-3d-viewer';
import { logger } from '../utils/cn_logger';

export class cn_contour extends cn_element {
    constructor(vertices = [], parent = null) {
        super(parent);
        this.removable = false;
        this.space = null;
        this.walls = [];
        this.wall_orientations = [];
        this.vertices = vertices.map(v => new cn_vertex(v));
        this.perimeter = 0;
        this.inner_perimeter = 0;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.vertices = [];
        for (var i in this.vertices) {
            // @ts-ignore
            if (typeof (this.vertices[i].s_index) != 'undefined')
                // @ts-ignore
                json.vertices.push(this.vertices[i].s_index);
            else
                json.vertices.push(this.vertices[i].serialize());
        }
        return json;
    }

    static unserialize(json, scene, parent = null) {
        if (typeof (json.vertices) != 'object') return false;
        var contour = new cn_contour([], parent);
        if (typeof (json.ID) == 'string') contour.ID = json.ID;
        for (var i in json.vertices) {
            if (typeof (json.vertices[i]) == 'number')
                contour.vertices.push(scene.vertices[json.vertices[i]]);
            else
                contour.vertices.push(new cn_vertex(json.vertices[i], json.vertices[i].length === 3));
        }
        contour.update();
        return contour;
    }

    //***********************************************************************************
    //**** update a contour
    //***********************************************************************************
    update() {
        this.perimeter = 0;
        var directions = [];

        this.walls = [];
        this.wall_orientations = [];
        for (var i = 0; i < this.vertices.length; i++) {
            var v0 = this.vertices[i];
            var v1 = (i < this.vertices.length - 1) ? this.vertices[i + 1] : this.vertices[0];
            this.perimeter += cn_dist(v0.position, v1.position);
            directions.push(cn_sub(v1.position, v0.position));
            var w = v0.wall_to(v1);
            if (w) {
                this.walls.push(w);
                this.wall_orientations.push(w.vertices[0] == v0);
            }
        }

        //*** is the contour clockwise ?
        if (this.vertices.length <= 2)
            this.clockwise = false;
        else {
            var full_area = 0;
            // @ts-ignore
            var v0 = this.vertices[this.vertices.length - 1].position;
            for (var j = 0; j < this.vertices.length; j++) {
                // @ts-ignore
                var v1 = this.vertices[j].position;
                full_area += (v0[0] + v1[0]) * (v1[1] - v0[1]);
                v0 = v1;
            }
            this.clockwise = (full_area < 0);
        }

        this.bounds_min = [0, 0];
        this.bounds_max = [0, 0];
        for (var v = 0; v < this.vertices.length; v++) {
            var pr = this.vertices[v].position;
            if (v == 0) {
                this.bounds_min[0] = pr[0];
                this.bounds_min[1] = pr[1];
                this.bounds_max[0] = pr[0];
                this.bounds_max[1] = pr[1];
                continue;
            }
            if (pr[0] < this.bounds_min[0]) this.bounds_min[0] = pr[0];
            if (pr[1] < this.bounds_min[1]) this.bounds_min[1] = pr[1];
            if (pr[0] > this.bounds_max[0]) this.bounds_max[0] = pr[0];
            if (pr[1] > this.bounds_max[1]) this.bounds_max[1] = pr[1];
        }

        //*** build inner contour
        this.inner_contour = [];
        if (this.walls.length > 0) {
            for (var j = 0; j < this.walls.length; j++) {
                if (!this.walls[j].balcony || this.walls[j].spaces[0] == null || this.walls[j].spaces[0] != this.walls[j].spaces[1])
                    this.inner_contour = this.inner_contour.concat(this.walls[j].space_lines[(this.wall_orientations[j]) ? 0 : 1]);
            }
            this.inner_contour = cn_filter_contour(this.inner_contour, 0.001);
        } else {
            for (var v2 in this.vertices)
                this.inner_contour.push(this.vertices[v2].position.concat([]));
        }

        this.area = 0;
        this.inner_perimeter = 0;
        // @ts-ignore
        var v0 = this.inner_contour[this.inner_contour.length - 1];
        for (var j = 0; j < this.inner_contour.length; j++) {
            // @ts-ignore
            var v1 = this.inner_contour[j];
            this.area += (v0[0] + v1[0]) * (v1[1] - v0[1]);
            this.inner_perimeter += cn_dist(v0, v1);
            v0 = v1;
        }
        this.area = Math.abs(this.area) * 0.5;
    }

    //***********************************************************************************
    //**** Follow a contour
    //***********************************************************************************
    follow(vertex, wall) {
        this.walls = [];
        this.wall_orientations = [];
        this.vertices = [];
        while (true) {
            logger.log(`contour has ${this.vertices.length} vertices - vertex [${vertex.position[0]},${vertex.position[1]}] has walls `, vertex.walls.concat([]));
            this.perimeter += cn_dist(wall.vertices[0].position, wall.vertices[1].position);
            this.walls.push(wall);
            this.vertices.push(vertex);

            //*** search for next vertex,
            var new_vertex;
            if (vertex == wall.vertices[0]) {
                this.wall_orientations.push(true);
                new_vertex = wall.vertices[1];
            } else {
                this.wall_orientations.push(false);
                new_vertex = wall.vertices[0];
            }

            vertex = new_vertex;

            //*** search for next wall
            wall = vertex.next_wall(wall);

            //*** end condition : back to start
            if (this.walls[0] == wall && this.vertices[0] == vertex) break;

            if (this.vertices.length > 1000) {
                for (var i = 0; i < 100; i++) {
                    logger.log(`vertex ${i} : [${this.vertices[i].position[0]},${this.vertices[i].position[1]}] - ${this.vertices[i].ID}`);
                }
                console.error('infinite loop');
                break;
            }
        }

        this.update();
    }

    //***********************************************************************************
    //**** Check a contour
    //***********************************************************************************
    check() {
        var vertex = this.vertices[this.vertices.length - 1];
        var wall = this.walls[this.walls.length - 1];
        for (var i in this.walls) {
            //*** search for next vertex,
            var new_vertex;
            if (vertex == wall.vertices[0])
                new_vertex = wall.vertices[1];
            else
                new_vertex = wall.vertices[0];

            vertex = new_vertex;
            if (vertex != this.vertices[i]) {
                logger.log('Checking contour : vertex error at ' + i + ' : ' + vertex + ' - ' + this.vertices[i]);
                return false;
            }
            //*** search for next wall
            wall = vertex.next_wall(wall);
            if (wall != this.walls[i]) {
                logger.log('Checking contour : wall error at ' + i + ' : ' + wall + ' - ' + this.walls[i]);
                return false;
            }
        }

        return true;
    }

    //***********************************************************************************
    //**** Does contour contains this couple ?
    //***********************************************************************************
    contains_couple(vertex, wall) {
        for (var i = 0; i < this.walls.length; i++) {
            if (this.walls[i] == wall && this.vertices[i] == vertex) return true;
        }
        return false;
    }

    //***********************************************************************************
    //**** Does contour contains the point ?
    //***********************************************************************************
    contains(p, inner = false) {
        var epsilon = 0.001;
        if (typeof (this.bounds_min) != 'object') this.update();
        if (p[0] <= this.bounds_min[0] - epsilon) return false;
        if (p[1] <= this.bounds_min[1] - epsilon) return false;
        if (p[0] >= this.bounds_max[0] + epsilon) return false;
        if (p[1] >= this.bounds_max[1] + epsilon) return false;

        var l = (inner) ? this.inner_contour.length : this.vertices.length;

        //*** Simple case : close to one segment ?
        for (var i = 0; i < l; i++) {
            var v0 = (inner) ? this.inner_contour[i] : this.vertices[i].position;
            var v1 = (inner) ? this.inner_contour[(i + 1) % l] : this.vertices[(i + 1) % l].position;
            if (cn_point_on_segment(p, v0, v1, epsilon)) return this.clockwise;
        }

        var nleft = 0;
        // @ts-ignore
        var nright = 0;
        var sense = 0;
        var o = 0;
        for (; o < l; o++) {
            var v0 = (inner) ? this.inner_contour[o] : this.vertices[o].position;
            if (v0[1] > p[1] || v0[1] < p[1]) break;
        }

        for (var i = 0; i < l; i++) {
            var v0 = (inner) ? this.inner_contour[(i + o) % l] : this.vertices[(i + o) % l].position;
            var v1 = (inner) ? this.inner_contour[(i + o + 1) % l] : this.vertices[(i + o + 1) % l].position;
            if (v0[1] > p[1] && v1[1] > p[1]) continue;
            if (v0[1] < p[1] && v1[1] < p[1]) continue;
            if ((v0[1] < p[1] && v1[1] > p[1]) || (v0[1] > p[1] && v1[1] < p[1])) {
                var coef = (p[1] - v0[1]) / (v1[1] - v0[1]);
                var x = v0[0] * (1 - coef) + v1[0] * coef;

                // on border :
                if (Math.abs(x - p[0]) <= epsilon) return this.clockwise;
                if (x >= p[0]) nright++;
                else {
                    nleft++;
                }
                continue;
            }
            if (v0[1] == p[1] && v1[1] == p[1]) continue;
            var x = 0;
            var new_sense = 0;
            if (v1[1] == p[1]) {
                x = v1[0];
                new_sense = (v0[1] > p[1]) ? 1 : -1;
            } else {
                x = v0[0];
                new_sense = (v1[1] < p[1]) ? 1 : -1;
            }
            if (sense == new_sense) {
                sense = 0;
                continue;
            }
            if (sense == -new_sense)
                sense = 0;
            else
                sense = new_sense;

            if (Math.abs(x - p[0]) <= epsilon) return this.clockwise;
            if (x >= p[0]) nright++;
            else {
                nleft++;
            }
        }

        if (nleft & 1) return true;
        return false;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contained_by_box(b) {
        for (var i in this.vertices) {
            if (!b.contains_point(this.vertices[i].position)) return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** get box
    //***********************************************************************************
    get_bounding_box() {
        var box = new cn_box();
        this.vertices.forEach(v => box.enlarge_point(v.position));
        return box;
    }

    //***********************************************************************************
    //**** Build 3d contour
    //***********************************************************************************
    build_3d_contour(z, inner = false) {
        if (inner) {
            return this.inner_contour.map(v => cn_clone_3d(v, z));
        }

        if (this.walls.length > 0) {
            var vertices = [];
            for (var i in this.walls) {
                const id0 = (this.wall_orientations[i]) ? 0 : 1;
                vertices.push(cn_clone_3d(this.walls[i].vertex_position(id0), z));
                vertices.push(cn_clone_3d(this.walls[i].vertex_position(1 - id0), z));
                vertices.push(cn_clone_3d(this.walls[i].vertices[1 - id0].position, z));
            }
            return cn_filter_contour(vertices, 0.001);
        }

        return this.vertices.map(v => cn_clone_3d(v.position, z));
    }

    //***********************************************************************************
    //**** Build 3d contour
    //***********************************************************************************
    build_3d_polygon(z, inner = false) {
        var vertices = this.build_3d_contour(z, inner);
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        polygon.add_contour(vertices);
        polygon.compute_contours();
        return polygon;
    }

    //***********************************************************************************
    //**** Builds outer contour
    //***********************************************************************************
    build_outer_3d_contour(z) {
        var polygon = this.build_3d_polygon(z, true);
        for (var j = 0; j < this.walls.length; j++) {
            var pg = this.walls[j].build_3d_polygon(z);
            if (pg) polygon.unites(pg);
        }
        var full_offset = 0;
        for (var niter = 0; niter < 10; niter++) {
            polygon.compute_contours();
            if (polygon.contour_sizes.length <= 1) break;
            full_offset += 0.01;
            polygon.offset(0.01, 2);
        }
        if (full_offset > 0)
            polygon.offset(-full_offset, 2);

        if (polygon.contour_sizes.length == 0) return [];
        if (polygon.contour_sizes.length == 1) return polygon.contour_vertices;
        return polygon.contour_vertices.slice(0, polygon.contour_sizes[0]);
    }

    //***********************************************************************************
    //**** Build contours from polygon
    //***********************************************************************************
    static build_from_polygon(polygon) {
        var contours = [];
        polygon.compute_contours();
        var offset = 0;
        for (var i in polygon.contour_sizes) {
            var sz = polygon.contour_sizes[i];
            var contour = new cn_contour();
            contours.push(contour);
            for (var j = 0; j < sz; j++) {
                var vv = polygon.contour_vertices[offset + sz - j - 1];
                var vtx = new cn_vertex(vv);
                contour.vertices.push(vtx);
            }
            contour.update();
            offset += sz;
        }
        return contours;
    }

    //***********************************************************************************
    //**** raytrace
    //***********************************************************************************
    raytrace(origin, direction, max_distance, inner) {
        var res = null;
        var vertices = (inner) ? this.inner_contour : this.vertices;
        var l = vertices.length;
        for (var i = 0; i < l; i++) {
            var v0 = (inner) ? vertices[i] : vertices[i].position;
            var v1 = (inner) ? vertices[(i + 1) % l] : vertices[(i + 1) % l].position;
            var dir = cn_sub(v1, v0);
            var length = cn_normalize(dir);
            if (length < 0.001) continue;
            var nor = cn_normal(dir);
            var x = cn_dot(direction, nor);
            if (Math.abs(x) < 0.0001) continue;
            var lambda = cn_dot(nor, cn_sub(v0, origin)) / x;
            if (lambda <= 0.0001) continue;
            if (lambda >= max_distance) continue;
            var p = cn_add(origin, cn_mul(direction, lambda));
            var e = cn_dot(dir, cn_sub(p, v0));
            if (e < 0) continue;
            if (e > length) continue;
            res = {
                'distance': lambda,
                'point': cn_add(v0, cn_mul(dir, e)),
                'normal': nor,
                'contour': this,
                'contour_direction': dir,
                'contour_position': e,
                'wall': null,
                'wall_orientation': true
            };
            if (!inner && this.walls) {
                res.wall = this.walls[l];
                res.wall_orientation = this.wall_orientations[l];
            }
            max_distance = lambda;
        }
        return res;
    }

    //***********************************************************************************
    /**Reverse the orientation of the contour */
    reverse() {
        this.vertices = this.vertices.reverse();
    }


    //***********************************************************************************
    /**
     * Compute a point and its normal on the contour, from its abscissa.
     * @param {number} x
     * @param {number[]} point
     * @param {number[]} normal
     * @returns {boolean}
     */
    abscissa_to_point_and_normal(x, point, normal) {
        var perimeter = 0;
        const cl = this.inner_contour.length;
        for (var i = 0; i < cl; i++)
            perimeter += cnx_dist(this.inner_contour[i], this.inner_contour[(i + 1) % cl]);

        var ab = x;
        while (ab > 1) ab--;
        while (ab < 0) ab++;
        ab *= perimeter;

        var per = 0;
        for (var nv = 0; nv < cl; nv++) {
            var v0 = this.inner_contour[nv % cl];
            var v1 = this.inner_contour[(nv + 1) % cl];

            var dst = cnx_dist(v0, v1);
            per += dst;
            if (ab >= per) continue;
            var lambda = (ab - (per - dst)) / dst;

            var dir = cnx_sub(v1, v0);
            cnx_normalize(dir);
            if (!this.clockwise) dir = cn_mul(dir, -1);
            cnx_copy(cn_normal(dir), normal);
            cnx_copy(cnx_add(cnx_mul(v0, 1 - lambda), cnx_mul(v1, lambda)), point);
            return true;
        }
        // @ts-ignore
        return false;
    }

    //***********************************************************************************
    /**
     * Compute closest abscissa point on the contour
     * @param {number[]} point
     * @returns  {number}
     */
    abscissa_from_point(point) {

        var distance = -1;
        var cl = this.inner_contour.length;
        var cum_length = 0;
        var ab = 0;
        for (var i = 0; i < cl; i++) {
            var dst0 = cnx_dist(point, this.inner_contour[i]);
            var length = cnx_dist(this.inner_contour[i], this.inner_contour[(i + 1) % cl]);
            if (dst0 < distance || distance < 0) {
                distance = dst0;
                ab = cum_length;
            }
            cum_length += length;
        }

        cum_length = 0;
        for (var i = 0; i < cl; i++) {
            var p0 = this.inner_contour[i];
            var p1 = this.inner_contour[(i + 1) % cl];
            var dir = cnx_sub(p1, p0);
            var length = cnx_normalize(dir);
            if (length > 0.01) {
                const d = cnx_sub(point, p0);
                const x = cnx_dot(d, dir);
                if (x > 0 && x < length) {
                    const dst = Math.sqrt(cnx_dot(d, d) - x * x);
                    if (dst < distance) {
                        distance = dst;
                        ab = cum_length + x;
                    }
                }
            }
            cum_length += length;
        }
        return ab / cum_length;
    }
}
