'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Space class
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Space class
//***********************************************************************************
import { fh_polygon, fh_scene, fh_solid } from '@enerbim/fh-3d-viewer';
import * as cn_configuration_option from '../utils/cn_plugin_option';
import { cn_add, cn_box, cn_color_hexa_to_rgb, cn_dot, cn_mul, cn_normal, cn_normalize, cn_sub } from '../utils/cn_utilities';
import { cn_image_dir } from '../utils/image_dir';
import { cn_contour } from './cn_contour';
import { cn_element } from './cn_element';
import { cn_opening } from './cn_opening';
import { cn_space_measure } from './cn_space_measure';
import { cn_storey } from './cn_storey';
import { extension_instance } from '../extension/cn_extension';
import { CN_FACING_TRIMMING_PLACEMENT_FLOOR } from './cn_facing_trimming';
import { cn_building } from './cn_building';
import { cn_storey_element } from './cn_storey_element';
import { CODES_BIM_OUVRAGES, CODES_BIM_PARAMETRES_OUVRAGES } from '../utils/cn_bbp_constants';
import { cn_3d_building } from './cn_3d_building';
import { cn_bbp_geometry } from '../utils/cn_bbp_geometry';
import { generate_pattern_for_facing } from '../utils/cn_svg_patterns';
import { logger } from '../utils/cn_logger';

export const SPACE_EXTERIOR_LABEL = 'Extérieur';

export const EXTERIOR_SPACE_FACING_LIST = [
    { code: 'asphalt', label: 'Asphalte' },
    { code: 'concrete', label: 'Béton' },
    { code: 'gravel', label: 'Gravier' },
    { code: 'lawn', label: 'Pelouse' },
    { code: 'marble', label: 'Dallage' },
    { code: 'pavement', label: 'Pavement' }
];
/** Revêtements de sol */
export const INTERIOR_FLOOR_FACING_LIST = [
    { code: 'concrete', label: 'Béton' },
    { code: 'tiles', label: 'Carrelage' },
    { code: 'wood', label: 'Parquet massif' },
    { code: 'wood_tiles', label: 'Parquet flottant' },
    { code: 'carpet', label: 'Moquette' },
    { code: 'vinyl', label: 'Lino' }
];

/** Revêtements de plafond */
export const INTERIOR_CEILING_FACING_LIST = [
    { code: 'concrete', label: 'Béton' },
    { code: 'paint', label: 'Peinture' },
    { code: 'fibreglass', label: 'Fibre de verre' },
    { code: 'wood_wall', label: 'Lambris' }
];

export class cn_space extends cn_element {
    constructor(scene) {
        super(scene);
        this.scene = scene;
        this.removable = false;

        //*** Model data
        this.name = '';
        this.area = 0;
        this.plain_area = 0;
        this.perimeter = 0;
        this.outside = false;
        this.contours = [];
        this.diagonal_origin = 0;
        this.ceiling_height = -1;
        this.main_door = null;
        this.slab_offset = 0;
        this.declared_area = -1;
        this.declared_perimeter = -1;
        this.space_usage = '';
        this.label_position = [0, 0];
        this.heated = true;
        /** index 0 = floor facing, index 1 = ceiling facing */
        this.facings = [null, null];

        //*** volatile data
        this.draw_priority = 0;
        this.center = [0, 0];
        this.indoor = false;
        this.has_roof = true;
        this._area_for_label_position = -1;
        this.s_index = 0;

        this._full_facing_floor_polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
        this._facing_floor_polygons = [];

        //*** polygons to draw
        this.inner_polygon = null;
        this.polygons = [];

        //*** measure data */
        this.measure = new cn_space_measure(this);

        this._bounding_box = new cn_box();
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.name = this.name;
        json.outside = this.outside;
        json.contours = [];
        for (var i in this.contours)
            json.contours.push(this.contours[i].serialize());
        json.measure = this.measure.serialize();
        json.ceiling_height = this.ceiling_height;
        json.declared_area = this.declared_area;
        json.declared_perimeter = this.declared_perimeter;
        json.space_usage = this.space_usage;
        if (this.main_door) {
            json.main_door = this.main_door.serialize();
        }
        json.slab_offset = this.slab_offset;

        json.label_position = this.label_position;
        json.facings = this.facings.map(f => (f) ? { ID: f.ID } : null);
        json.heated = this.heated;
        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json.name) != 'string') return false;
        if (typeof (json.outside) != 'boolean') return false;
        if (typeof (json.contours) != 'object') return false;

        var space = new cn_space(scene);
        space.name = json.name;
        space.outside = json.outside;
        space.space_usage = json.space_usage;
        if (typeof (json.ID) == 'string')
            space.ID = json.ID;

        if (typeof (json.measure) == 'object')
            cn_space_measure.unserialize(this, json.measure);

        for (var i in json.contours) {
            var contour = cn_contour.unserialize(json.contours[i], scene);
            space.add_contour(contour);
        }

        var nv = 0;
        for (var nctr in space.contours)
            nv += space.contours[nctr].vertices.length;
        if (nv == 0) return null;

        if (typeof (json.ceiling_height) == 'number')
            space.ceiling_height = json.ceiling_height;

        if (typeof (json.declared_area) == 'number')
            space.declared_area = json.declared_area;

        if (typeof (json.declared_perimeter) == 'number')
            space.declared_perimeter = json.declared_perimeter;

        if (json.main_door) {
            space.main_door = cn_opening.unserialize(json.main_door, scene)
        }

        if (typeof (json.slab_offset) == 'number')
            space.slab_offset = json.slab_offset;


        if (typeof (json.label_position) == 'object')
            space.label_position = json.label_position;

        if (json.facings) {
            space.facings = json.facings.map(facing => facing && facing.ID ? scene.building.facing_types.find(ft => ft.ID === facing.ID) : null);
        } else {
            space.facings = [null, null];
        }

        if (typeof (json.heated) == 'boolean')
            space.heated = json.heated;

        scene.spaces.push(space);
        return space;
    }

    //***********************************************************************************
    //**** Build name
    //***********************************************************************************
    get_name(storey) {
        if (this.outside) return SPACE_EXTERIOR_LABEL;

        if (this.name == '') {
            return this.get_generic_name(storey);
        }

        if (!this.name.includes('#')) return extension_instance.space.get_space_name(this, storey);

        var name = this.name;
        if (name.includes('#st') && typeof (storey) != 'undefined') {
            name = name.replace('#st', storey.storey_index);
            if (!name.includes('#')) return name;
        }

        if (name.includes('#is')) {
            var index = 0;
            for (var i = 0; i < this.scene.spaces.length; i++) {
                if (this.scene.spaces[i] == this) break;
                if (this.scene.spaces[i].name == this.name) index++;
            }
            name = name.replace('#is', '' + index);
            if (!name.includes('#')) return name;
        }

        if (name.includes('#ib') && typeof (storey) != 'undefined') {
            var index = 0;
            for (var i = 0; i < this.scene.building.storeys.length; i++) {
                var st = this.scene.building.storeys[i];
                for (var i = 0; i < this.scene.spaces.length; i++) {
                    if (st == storey && this.scene.spaces[i] == this) break;
                    if (this.scene.spaces[i].name == this.name) index++;
                }
                if (st == storey) break;
            }
            name = name.replace('#ib', '' + index);
            if (!name.includes('#')) return name;
        }
        return name;
    }

    get_generic_name(storey) {
        let name = 'Espace ';
        if (storey) {
            if (storey && storey.exterior === true) {
                name += SPACE_EXTERIOR_LABEL.toLocaleLowerCase() + '.';
            } else {
                name += storey.storey_index + '.';
            }
        }
        var ind = this.scene.spaces.indexOf(this);
        name += ind;
        return name;
    }

    /**
     * Returns available ceiling height (HSP in french), i.e. ceiling height (or storey height if absent) minus slab_offset
     */
    get_real_ceiling_height() {
        return this.ceiling_height > 0 ? this.ceiling_height - this.slab_offset : this.scene.storey.height - this.slab_offset;
    }

    //***********************************************************************************
    /**
     * Returns declared area (if exists), or actual area
     */
    get_area() {
        if (this.declared_area > 0) return this.declared_area;
        return this.area;
    }

    //***********************************************************************************
    /**
     * Returns declared perimeter (if exists), or actual perimeter
     */
    get_perimeter() {
        if (this.declared_perimeter > 0) return this.declared_perimeter;
        return this.perimeter;
    }

    /**
     * returns the list of all facings (interior or exterior) that may be attributed to spaces.
     * Does not include 'no facing'.
     * @returns {object[]}
     */
    static get_all_facings() {
        let codes = [];
        let facing_list = [];
        EXTERIOR_SPACE_FACING_LIST.concat(INTERIOR_FLOOR_FACING_LIST).concat(INTERIOR_CEILING_FACING_LIST).forEach(f => {
            if (f.code != '' && codes.indexOf(f.code) < 0) {
                codes.push(f.code);
                facing_list.push(f);
            }
        });
        return facing_list;
    }

    /**
     * Returns true if heated is relevant (i.e. space is heatable)
     * @returns {boolean}
     */
    is_heated_relevant() {
        if (this.outside) return false;
        if (!this.has_roof) return false;
        if (!this.indoor) return false;
        return true;
    }

    /**
     * Returns true if heated
     * @returns {boolean}
     */
    is_heated() {
        if (this.outside) return false;
        if (!this.has_roof) return false;
        if (!this.indoor) return false;
        return this.heated;
    }

    /**
     * Copy characteristics (non geometrical) of another space
     * @param {cn_space} other_space
     */
    copy_parameters(other_space) {
        this.facings = other_space.facings.concat([]);
        this.slab_offset = other_space.slab_offset;
        this.ceiling_height = other_space.ceiling_height;
        this.heated = other_space.heated;
        this.space_usage = other_space.space_usage;
        Object.entries(this.scene.building.zones).forEach(([_, zones]) => {
            if (this.scene.storey) {
                zones.forEach(zone => {
                    if (zone.rooms.some(room => room.storey === this.scene.storey.ID && room.space == other_space.ID)) {
                        zone.add_room(this.ID, this.scene.storey.ID);
                    }
                });
            }
        });
    }

    /**
     * Collect all data from another space that will soon disapear
     * @param {cn_space} other_space
     */
    collect_space_data(other_space) {
        this.scene.object_instances.forEach(oi => {
            if (oi.virtual && oi.space == other_space)
                oi.space = this;
        });
    }

    //***********************************************************************************
    //**** update geometry
    //***********************************************************************************
    update() {
        this.area = 0;
        this.perimeter = 0;
        for (var i in this.contours) {
            this.contours[i].update();
            if (this.contours[i].clockwise)
                this.area += this.contours[i].area;
            else
                this.area -= this.contours[i].area;
            this.perimeter += this.contours[i].inner_perimeter;
        }
        this.area = Math.abs(this.area);

        if (Math.abs(this.area - this._area_for_label_position) > 0.1) {
            if (this._area_for_label_position >= 0)
                this.label_position = [0, 0];

            this._area_for_label_position = this.area;
        }

        this.measure.update();

        this._bounding_box = new cn_box();
        for (var i in this.contours)
            this._bounding_box.enlarge_box(this.contours[i].get_bounding_box());
    }

    update_deep() {
        if (!this.outside)
            this.center = this.find_best_center();

        //*** build this  polygon
        var polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
        for (var i = 0; i < this.contours.length; i++)
            polygon.add_contour(this.contours[i].build_3d_contour(0, true));
        polygon.compute_contours();

        this.inner_polygon = polygon.clone();

        //*** build slab opening polygon polygon
        this._remove_slab_opening(polygon, 0);

        this._full_facing_floor_polygon = polygon.clone();
        this._facing_floor_polygons = [];

        //*** remove storey's ignored area */
        if (this.scene && this.scene.storey && this.scene.storey.ignored_polygon)
            polygon.substracts(this.scene.storey.ignored_polygon);

        this.plain_area = polygon.get_area();

        var pgs = polygon.split();

        this.polygons = [];
        for (var i = 0; i < pgs.length; i++) {
            var ctr = cn_contour.build_from_polygon(pgs[i]);
            this.polygons.push(ctr);
        }
        if (!this.outside && this.contours.length && this.contours[0].walls.length) {
            // @ts-ignore
            const doors = this.contours.flatMap(contour => contour.walls.flatMap(wall => wall.openings.filter(o => o.opening_type.category === 'door')));
            const mainDoorStillExist = this.main_door && doors.map(door => door.ID).indexOf(this.main_door.ID) >= 0;
            if (mainDoorStillExist) {
                this.main_door = doors.find(door => door.ID === this.main_door.ID);
            } else if (doors.length > 0) {
                this.main_door = doors[0];
            } else if (doors.length === 0) {
                this.main_door = null;
            }
        }

        if (!this.outside)
            this._update_facing_trimmings();
    }

    _update_facing_trimmings() {

        this.scene.facing_trimmings.filter(ft => ft.placement == CN_FACING_TRIMMING_PLACEMENT_FLOOR).forEach(ft => {
            if (this._bounding_box.intersects(ft.get_bounding_box())) {
                const pg = ft._polygon.clone();
                pg.intersects(this._full_facing_floor_polygon);
                if (pg.get_area() > 0.01) {
                    if (this._facing_floor_polygons.length == 0) {
                        this._facing_floor_polygons = [this._full_facing_floor_polygon.clone()];
                    }
                    this._facing_floor_polygons.forEach(fpg => fpg.substracts(ft._polygon));
                    this._facing_floor_polygons.push(pg);
                    pg['facing_trimming'] = ft;
                }
            }
        });

    }

    /**
     * Returns the floor polygons of the space.
     * If a polygon comes from a facing trimming, it's facing trimming isin the 'facing_trimming' field of the polygon.
     * @returns {Array<fh_polygon>}
     */
    get_floor_facing_polygons() {
        return (this._facing_floor_polygons.length > 0) ? this._facing_floor_polygons : [this._full_facing_floor_polygon];
    }

    //***********************************************************************************
    //**** returns the clockwise contour
    //***********************************************************************************
    get_clockwise_contour() {
        for (var i in this.contours) {
            if (this.contours[i].clockwise) return this.contours[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** Draw the space in svg
    //***********************************************************************************
    draw(camera, add_classes = [], fill_color = '', allow_numerotation = true, allow_outside = false) {
        var html = '';
        if (this.outside && !allow_outside) return html;

        const is_export = add_classes.includes('exp');

        var draw_class = 'space';
        if (!this.has_roof && !this.facings[0]) draw_class += ' no_roof';
        if (!this.indoor && !is_export) draw_class += ' outdoor';

        if (add_classes)
            draw_class += ' ' + add_classes.filter(c => c != 'selected' && c != 'mouseover').join(' ');

        html += this._draw_path(camera, draw_class, fill_color, is_export);

        if (add_classes.includes('selected') || add_classes.includes('mouseover')) {
            if (add_classes.includes('selected')) draw_class += ' selected';
            if (add_classes.includes('mouseover')) draw_class += ' mouseover';
            html += this._draw_path(camera, draw_class, fill_color, is_export, true);
        }

        html += this.draw_numerotation(camera, allow_numerotation)
        return html;
    }

    _draw_path(camera, draw_class, fill_color = '', is_export = false, only_contour = false) {
        var html = '';
        const polygons = (this._facing_floor_polygons.length > 0 && !only_contour) ? this._facing_floor_polygons : [this._full_facing_floor_polygon];
        polygons.forEach(polygon => {
            polygon.compute_contours();
            const facing = (polygon['facing_trimming']) ? polygon['facing_trimming'].facing : this.facings[0];
            var fc = `fill="none"`;

            if (!only_contour) {
                fc = fill_color;
                if (camera.show_facings && !is_export && fill_color == '' && facing && draw_class.indexOf('mouseover') < 0 && draw_class.indexOf('selected') < 0) {
                    var sz = camera.world_to_screen_scale.toFixed(0);
                    sz = camera.world_to_screen_scale.toFixed(0);
                    var pt = camera.world_to_screen([0, 0]);
                    var ptx = pt[0].toFixed(0);
                    var pty = pt[1].toFixed(0);
                    const facing_pattern_and_style = generate_pattern_for_facing(facing.texture, facing.color, sz, sz, ptx, pty, facing.ID);
                    html += facing_pattern_and_style.pattern;
                    fc = facing_pattern_and_style.style;
                }
            }
            html += `<path class="${draw_class}" ${fc} d="`;
            var offset = 0;
            for (var nctr = 0; nctr < polygon.contour_sizes.length; nctr++) {
                const sz = polygon.contour_sizes[nctr];
                for (var j = 0; j < sz; j++) {
                    if (j == 0) html += 'M ';
                    else if (j == 1) html += 'L ';
                    var p = camera.world_to_screen(polygon.contour_vertices[offset + j]);
                    html += '' + p[0] + ' ' + p[1] + ' ';
                }
                html += 'Z ';
                offset += sz;
            }
            html += `" fill-rule="evenodd" />`;
        });
        return html;
    }

    draw_numerotation(camera, allow_numerotation) {
        let html = '';
        if (cn_configuration_option.getNumerotation() && allow_numerotation) {
            if (this.main_door) {
                let num = { prefix: '', index: '' };
                const contourWithMainDoor = this.contours.find(contour => contour.walls.find(wall => wall.openings.find(opening =>
                    opening.ID === this.main_door.ID)));
                [...this.contours].sort((a, b) => {
                    if (a === contourWithMainDoor) {
                        return -1;
                    } else if (b === contourWithMainDoor) {
                        return 1;
                    } else {
                        return b.area - a.area;
                    }
                }).forEach((contour, ic) => {
                    num.index = '';
                    let walls = this._generateWallsForNumerotation(contour);
                    if (walls.length) {
                        if (ic === 0) {
                            const mainDoorWallIndex = walls.findIndex(numberWall => numberWall.find(wall => wall.wall.openings.find(opening =>
                                opening.ID === this.main_door.ID)));
                            if (mainDoorWallIndex >= 0) {
                                walls = [...walls.slice(mainDoorWallIndex), ...walls.slice(0, mainDoorWallIndex)];
                            }
                        } else {
                            const wallsXVertices = walls.reduce((a, stackWall) => a.concat(stackWall.map(numberedWall => numberedWall.wall.vertices[0].position[0])), []);
                            const wallsYVertices = walls.reduce((a, stackWall) => a.concat(stackWall.map(numberedWall => numberedWall.wall.vertices[0].position[1])), []);
                            const topLeftWallIndex = walls.findIndex(stackWall => stackWall.find(numberedWall =>
                                numberedWall.wall.vertices[0].position[0] === Math.min(...wallsXVertices)
                                && numberedWall.wall.vertices[0].position[1] === Math.max(...wallsYVertices)));
                            if (topLeftWallIndex >= 0) {
                                walls = [...walls.slice(topLeftWallIndex), ...walls.slice(0, topLeftWallIndex)];
                            }
                        }
                        walls.forEach((stackWalls, i) => {
                            num = this._getNextIndex(num.prefix, num.index);
                            html += this._addNumerotationLabel(camera, stackWalls[0].pInner0, stackWalls[stackWalls.length - 1].pInner1, num.prefix, num.index);
                            const next_cyclic_stack = walls[(i + 1) % walls.length];
                            if (stackWalls[stackWalls.length - 1].wall === next_cyclic_stack[0].wall) {
                                num = this._getNextIndex(num.prefix, num.index);
                                html += this._addNumerotationLabel(camera, stackWalls[stackWalls.length - 1].pReturn0, stackWalls[stackWalls.length - 1].pReturn1, num.prefix, num.index);
                            }
                        });
                        num.prefix = String.fromCharCode((num.prefix.charCodeAt(0) || 64) + 1);
                    }
                });
            }
        }
        return html;
    }

    _addNumerotationLabel(camera, p0, p1, numerotationPrefix, numerotationIndex) {
        let v0 = camera.world_to_screen(p0);
        let v1 = camera.world_to_screen(p1);
        let offset_direction = cn_normal(cn_sub(p0, p1));
        let o = [offset_direction[0], -offset_direction[1]];
        cn_normalize(o);
        const off = 30;
        o = cn_mul(o, off);
        v0 = cn_add(v0, o);
        v1 = cn_add(v1, o);
        return `<text class='dimensionning_text' x='${(v0[0] + v1[0]) * 0.5}' y='${(v0[1] + v1[1]) * 0.5}'>${numerotationPrefix + numerotationIndex}</text>`;
    }

    _generateWallsForNumerotation(contour) {
        const numberedWalls = [];
        const wallsOrientations = contour.wall_orientations;
        contour.walls.forEach((wall, i) => {
            if (!wall.wall_type.free) {
                const numberedWall = {
                    wall: wall,
                    orientation: wallsOrientations[i],
                    pInner0: wallsOrientations[i] ? wall.shape[0] : wall.shape[2],
                    pInner1: wallsOrientations[i] ? wall.shape[3] : wall.shape[1],
                    pReturn0: wallsOrientations[i] === contour.clockwise ? wall.shape[3] : wall.shape[1],
                    pReturn1: wallsOrientations[i] === contour.clockwise ? wall.shape[2] : wall.shape[0],
                }
                numberedWalls.push(numberedWall);
            }
        });
        const result = [];
        let current_stack = [];

        if (numberedWalls.length) {
            if (!contour.clockwise) {
                numberedWalls.reverse();
            }
            numberedWalls.forEach((wall, i) => {
                const next_wall = numberedWalls[i + 1];
                current_stack.push(wall);
                if (!next_wall || !this._isWallsContinuous(wall, next_wall)) {
                    result.push([...current_stack]);
                    current_stack = [];
                }
            });

            const last_stack = result[result.length - 1]
            if (this._isWallsContinuous(last_stack[last_stack.length - 1], result[0][0])) {
                result[result.length - 1].push(...result[0]);
                result.splice(0, 1);
            }
        }
        return result;
    }

    _isWallsContinuous(wall, next_wall) {
        const normal = cn_normal(cn_sub(wall.pInner1, wall.pInner0));
        let result = false;
        if (cn_normalize(normal) >= 0.01) {
            const x0 = cn_dot(normal, cn_sub(next_wall.pInner0, wall.pInner0));
            const x1 = cn_dot(normal, cn_sub(next_wall.pInner1, wall.pInner0));
            result = Math.abs(x0) <= 0.01 && Math.abs(x1) <= 0.01
        }
        return result;
    }

    _getNextIndex(prefix, index) {
        let asciiCodeIndex = (index.charCodeAt(0) || 64) + 1;
        let px = prefix;
        if (asciiCodeIndex > 90) {
            asciiCodeIndex = 65;
            px = String.fromCharCode((px.charCodeAt(0) || 64) + 1);
        }
        const idx = String.fromCharCode(asciiCodeIndex);
        return { prefix: px, index: idx };
    }

    draw_main_door_selected_space(camera) {
        let result = '';
        if (this.main_door) {
            const halfDoorR = Number(+this.main_door.position + 0.5 * this.main_door.opening_type.width);
            const p1 = this.main_door.wall.vertices[0].position;
            const pDoor = cn_add(
                p1,
                cn_mul(this.main_door.wall.bounds.direction, halfDoorR)
            );
            const v0 = camera.world_to_screen(pDoor);
            let offset_direction = cn_normal(cn_sub(p1, pDoor));
            let o = [offset_direction[0], -offset_direction[1]];
            cn_normalize(o);
            const off = 20;
            o = cn_mul(o, off);
            const [x, y] = cn_add(v0, o);
            result = `<image xlink:href='${cn_image_dir()}main_door.svg' x='${x}' y='${y}' width='20px' height='20px' />`;
        }
        return result;
    }

    //***********************************************************************************
    //**** Contains one point
    //***********************************************************************************
    contains(p, inner = false) {
        if (this.outside) {
            for (var i in this.contours) {
                if (this.contours[i].contains(p, inner)) return false;
            }
            return true;
        }

        if (!this._bounding_box.contains_point(p)) return false;

        for (var i in this.contours) {
            if (this.contours[i].area < 0.01) continue;
            var res = this.contours[i].contains(p, inner);
            if (this.contours[i].clockwise != res)
                return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** Contains one point
    //***********************************************************************************
    contains_segment(p0, p1, inner = false) {
        if (!this.contains(p0, inner))
            return false;
        if (!this.contains(p1, inner))
            return false;
        var dir = cn_sub(p1, p0);
        var max_distance = cn_normalize(dir);
        if (max_distance < 0.001)
            return true;

        if (this.raytrace(cn_add(p0, cn_mul(dir, 0.001)), dir, max_distance - 0.002, inner))
            return false;
        return true;
    }

    //***********************************************************************************
    //**** Contains
    //***********************************************************************************
    contained_by_box(box) {
        for (var i in this.contours) {
            if (!this.contours[i].contained_by_box(box))
                return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** get box
    //***********************************************************************************
    get_bounding_box() {
        return this._bounding_box;
    }

    //***********************************************************************************
    //**** Removes a contour
    //***********************************************************************************
    remove_contour(contour) {
        var index = this.contours.indexOf(contour);
        if (index < 0) return;
        this.contours.splice(index, 1);
        contour.space = null;
    }

    //***********************************************************************************
    //**** Adds a contour
    //***********************************************************************************
    add_contour(contour) {
        if (contour.space) {
            var index = contour.space.contours.indexOf(contour);
            if (index >= 0)
                contour.space.contours.splice(index, 1);
        }

        contour.space = this;
        for (var i = 0; i < contour.walls.length; i++) {
            var side = (contour.wall_orientations[i]) ? 0 : 1;

            contour.walls[i].spaces[side] = this;
            var ct = contour.walls[i].contours[side];
            if (ct && ct.space && ct != contour)
                ct.space.remove_contour(ct);
            contour.walls[i].contours[side] = contour;
        }
        this.contours.push(contour);
    }

    //***********************************************************************************
    //**** Build a polygon that forms the slab of the space
    //***********************************************************************************
    build_slab_polygon(z, inner = false, use_slab_openings = true) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.contours) {
            polygon.add_contour(this.contours[i].build_3d_contour(z, inner));
        }

        if (use_slab_openings)
            this._remove_slab_opening(polygon, z);

        return polygon;
    }

    //***********************************************************************************
    //**** Build a polygon that forms the outer contour of the space
    //***********************************************************************************
    build_outer_polygon(z, use_slab_openings = true) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.contours) {
            polygon.add_contour(this.contours[i].build_3d_contour(z));
        }

        /** Add wall footprints */
        polygon.offset(0.01);
        this.contours.forEach(contour => {
            contour.walls.forEach(wall => {
                const pg = wall.build_footprint(z);
                pg.offset(0.01);
                polygon.unites(pg);
            });
        });
        polygon.offset(-0.01);

        //*** Remove slab openings */
        if (use_slab_openings)
            this._remove_slab_opening(polygon, z);

        return polygon;
    }

    //***********************************************************************************
    //**** Build a polygon that forms the inner contour of the space
    //***********************************************************************************
    build_inner_polygon(z, use_slab_openings = true) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.contours) {
            polygon.add_contour(this.contours[i].build_3d_contour(z, true));
        }
        if (use_slab_openings)
            this._remove_slab_opening(polygon, z);

        return polygon;
    }

    /**
     * Internal : removes slab openings (of the storey and from storey below stairs)
     * @param {fh_polygon} polygon
     */
    _remove_slab_opening(polygon, z) {
        if (this.scene && this.scene.slab_openings) {
            this.scene.slab_openings.forEach(so => {
                var slab_polygon = so.build_3d_polygon(z);
                polygon.substracts(slab_polygon);
            });
        }

        if (this.scene && this.scene.storey) {
            const storey_below = this.scene.storey.get_previous_storey();
            if (storey_below) {
                storey_below.scene.stairs.forEach(st => {
                    const pg = st.build_3d_slab_opening(z);
                    if (pg) polygon.substracts(pg);
                });
            }
        }
    }

    /**
     * Returns a solid that matches the inner volume of the space.
     * This requires that storeys' roof volume was computed.
     * z = 0 for the storey.
     * @param {cn_storey} storey
     * @param {boolean} thermal_roof_volume
     * @returns {fh_solid}
     */
    build_solid(storey, thermal_roof_volume = false) {
        var footprint = this.build_inner_polygon(this.slab_offset, false);
        var solid = new fh_solid();

        const roof_volume = (thermal_roof_volume) ? storey.roof_thermal_volume : storey.roof_volume;
        var h = 0;
        if (!this.has_roof || !roof_volume)
            h = storey.height - this.slab_offset;
        else {
            const bb = roof_volume.get_bounding_box();
            h = bb.position[2] + bb.size[2] - this.slab_offset;
        }
        if (h < 0.01) h = 0.01;
        solid.extrusion(footprint, [0, 0, h])
        if (this.has_roof && roof_volume)
            solid.intersects(roof_volume);
        return solid;
    }

    //***********************************************************************************
    //**** raytrace
    //***********************************************************************************
    raytrace(origin, direction, max_distance, inner) {
        var res = null;
        var max_d = max_distance;
        for (var i in this.contours) {
            var new_res = this.contours[i].raytrace(origin, direction, max_d, inner);
            if (new_res == null) continue;
            res = new_res;
            max_d = res.distance;
        }
        return res;
    }

    //***********************************************************************************
    //**** find best space center
    //***********************************************************************************
    find_best_center() {
        var display_res = false;

        var box = this.get_bounding_box();

        //*** first check the center */
        var c = cn_add(box.posmin, cn_mul(box.size, 0.5));
        if (this.contains(c, true)) {
            return c;
        }

        //*** We make a grid on bounding box of approx 1000 samples.
        var area = box.size[0] * box.size[1];
        if (area <= 0) return [0, 0];
        var a = Math.sqrt(area / 1000);

        box.posmin = cn_sub(box.posmin, [2 * a, 2 * a]);
        box.size = cn_add(box.size, [4 * a, 4 * a]);

        var nx = 1 + Math.round(box.size[0] / a);
        var ny = 1 + Math.round(box.size[1] / a);
        var grid = new Array(nx * ny);
        grid.fill(false);

        //*** Draw contours on that grid
        for (var ctr in this.contours) {
            var contour = this.contours[ctr];
            var p0 = contour.vertices[contour.vertices.length - 1].position;
            for (var v = 0; v < contour.vertices.length; v++) {
                var p1 = contour.vertices[v].position;
                if (p1[1] == p0[1]) {
                    p0 = p1;
                    continue;
                }
                var pp0, pp1, sense;
                if (p1[1] > p0[1]) {
                    pp0 = p0;
                    pp1 = p1;
                    sense = true;
                } else {
                    pp0 = p1;
                    pp1 = p0;
                    sense = false;
                }

                var j0 = Math.ceil((pp0[1] - box.posmin[1]) / a);
                var j1 = Math.floor((pp1[1] - box.posmin[1]) / a);
                var coef = (pp1[0] - pp0[0]) / (pp1[1] - pp0[1]);
                for (var j = j0; j <= j1; j++) {
                    var y = box.posmin[1] + a * j;
                    var x = pp0[0] + (y - pp0[1]) * coef;
                    var i0 = Math.floor((x - box.posmin[0]) / a);
                    if (!sense) i0++;
                    if (i0 >= 0 && i0 < nx)
                        grid[i0 + j * nx] = !grid[i0 + j * nx];
                }
                p0 = p1;
            }
        }

        function console_filter() {
            for (var j = 0; j < ny; j++) {
                var xx = '';
                for (var i = 0; i < nx; i++)
                    xx += (grid[i + j * nx]) ? '#' : ' ';
                xx += ' ' + j;
                logger.log(xx);
            }
        }

        if (display_res)
            console_filter();

        //*** Fill using even odd rule
        for (var j = 0; j < ny; j++) {
            var cnt = 0;
            for (var i = 0; i < nx; i++)
                if (grid[i + j * nx]) cnt++;
            if (cnt & 1) continue;

            var val = false;
            for (var i = 0; i < nx; i++) {
                if (grid[i + j * nx])
                    val = !val;
                else
                    grid[i + j * nx] = val;
            }
        }

        if (display_res)
            console_filter();

        //*** Apply median filter until all image is blank
        var sz = 3;
        var threshold = 9;
        var imax = Math.floor(nx / 2);
        var jmax = Math.floor(ny / 2);
        for (var niter = 0; niter < 30; niter++) {
            if (display_res)
                logger.log('Iteration  ' + niter + ' seuil ' + threshold);
            var ngrid = new Array(nx * ny);
            ngrid.fill(false);
            var ok = false;
            for (var j = 1; j < ny - 1; j++) {
                for (var i = 1; i < nx - 1; i++) {
                    var n = 0;
                    for (var ki = 0; ki < sz; ki++) {
                        for (var kj = 0; kj < sz; kj++)
                            if (grid[i - 1 + ki + (j - 1 + kj) * nx]) n++;
                    }
                    if (n < threshold) continue;

                    ngrid[i + j * nx] = true;
                    ok = true;
                    imax = i;
                    jmax = j;
                }
            }

            //*** If image is blank, we first try to reduce threshold
            if (!ok) {
                threshold--;
                if (threshold < 5) break;
                continue;
            }

            grid = ngrid;
            if (display_res)
                console_filter();
        }

        if (display_res)
            console_filter();

        return [box.posmin[0] + a * imax, box.posmin[1] + a * jmax];
    }

    //***********************************************************************************
    /**Reverse the orientation of the space */
    reverse() {
        for (var i in this.contours)
            this.contours[i].reverse();
    }

    /**
     * Update the 3D geometry of floor spacings
     * @param {cn_3d_building} building_3d
     * @param {cn_storey} storey
     */
    update_3d_floor_facings(building_3d, storey) {
        if (this.outside) return;
        const space_id = cn_building.create_unique_id(storey, this);
        let facing_objects = building_3d.get_3d_objects_by_bim_code(CODES_BIM_OUVRAGES.facing_floor);
        let obsolete_objects = facing_objects.filter(ob => ob.json_object.SPACE == space_id);

        const zoffset = storey.altitude + this.slab_offset + 0.001;
        const facing_polygons = (this._facing_floor_polygons.length > 0) ? this._facing_floor_polygons : [this._full_facing_floor_polygon];
        facing_polygons.forEach(facing_pg => {
            facing_pg.compute_tesselation();

            const new_id = cn_building.create_unique_id(storey, this, 'floor_facing', facing_pg['facing_trimming']);

            //*** Maybe that object already exists ? */
            const existing_object = obsolete_objects.find(ob => ob.BIMID == new_id);
            if (existing_object) {
                logger.log('updating object', new_id);
                //*** In that case, remove from obsolete objects */
                const index = obsolete_objects.indexOf(existing_object);
                obsolete_objects.splice(index, 1);

                //*** Update the object */
                const tess_vertices = facing_pg.tesselation_vertices.map(v => [v[0], v[1], v[2] + zoffset]);
                const tess_trianges = facing_pg.tesselation_triangles.concat([]);
                fh_scene.update_mesh_geometry(existing_object._meshes[0], tess_vertices, tess_trianges);
            } else {
                logger.log('new object', new_id);
                //**** Build new object */
                const bbp_facing_floor = this._build_3d_floor_facing(facing_pg, storey, true);
                const storey_element = (facing_pg['facing_trimming']) ? new cn_storey_element(facing_pg['facing_trimming'], storey) : null;
                building_3d.add_element(storey_element, bbp_facing_floor);
            }
        });

        logger.log('remove objects : ', obsolete_objects.map(f => f.BIMID));
        building_3d.remove_objects(obsolete_objects);
    }

    build_3d_floor_facings(storey, cnmap_pointers = false) {
        const bbp_objects = [];
        if (this.outside) return [];
        const facing_polygons = (this._facing_floor_polygons.length > 0) ? this._facing_floor_polygons : [this._full_facing_floor_polygon];
        facing_polygons.forEach(facing_pg => {
            bbp_objects.push(this._build_3d_floor_facing(facing_pg, storey, cnmap_pointers));
        });
        return bbp_objects;
    }

    _build_3d_floor_facing(facing_pg, storey, cnmap_pointers = false) {
        //**** Build new object */
        const bbp_facing_floor = {};
        bbp_facing_floor.ID = cn_building.create_unique_id(storey, this, 'floor_facing', facing_pg['facing_trimming']);
        bbp_facing_floor.Name = 'Revêtement de sol';
        bbp_facing_floor.Code_BIM = CODES_BIM_OUVRAGES.facing_floor;
        bbp_facing_floor.SPACE = cn_building.create_unique_id(storey, this);
        bbp_facing_floor.storey = storey.storey_index;
        bbp_facing_floor[CODES_BIM_PARAMETRES_OUVRAGES.facing_surface] = facing_pg.get_area();

        bbp_facing_floor.geometries = [];
        const geometry = cn_bbp_geometry.from_polygon(facing_pg, storey.altitude + this.slab_offset + 0.001);
        bbp_facing_floor.geometries.push(geometry);
        const space_facing = (facing_pg['facing_trimming']) ? facing_pg['facing_trimming'].facing : this.facings[0];
        geometry.texture = space_facing ? cn_image_dir() + 'texture_' + space_facing.texture + '.jpg' : '';
        const facing_rgb_color = cn_color_hexa_to_rgb(space_facing && space_facing.color ? space_facing.color : '#FFFFFF');
        geometry.color = [...facing_rgb_color, 0.5]

        if (cnmap_pointers) {
            bbp_facing_floor.cnmap_storey = storey;
            bbp_facing_floor.cnmap_element = (facing_pg['facing_trimming']) ? facing_pg['facing_trimming'] : this;
        }
        return bbp_facing_floor;
    }

    /**
     * Update the 3D texture of main floor spacing
     * @param {cn_3d_building} building_3d
     * @param {cn_storey} storey
     */
    update_3d_floor_facing_texture(building_3d, storey) {
        if (this.outside) return;
        const id = (storey.exterior) ? cn_building.create_unique_id(storey, this) : cn_building.create_unique_id(storey, this, 'floor_facing', undefined);
        const facing_object = building_3d.get_3d_object_by_bimid(id);
        if (facing_object) {
            const space_facing = this.facings[0];
            const texture = space_facing ? cn_image_dir() + 'texture_' + space_facing.texture + '.jpg' : '';
            const color = [...cn_color_hexa_to_rgb(space_facing && space_facing.color ? space_facing.color : '#FFFFFF'), (storey.exterior) ? 1 : 0.5];
            fh_scene.update_mesh_color(facing_object._meshes[0], color, texture);
        }
    }

    reset_properties() {
        this.name = '';
        this.space_usage = '';
        this.declared_area = -1;
        this.declared_perimeter = -1;
        this.ceiling_height = -1;
        this.main_door = null;
        this.slab_offset = 0;
        this.space_usage = '';
        this.label_position = [0, 0];
        this.heated = true;
    }
}

