'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_stairs :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** cn_flat_part : a flat (or not) part of a stairs.
//***********************************************************************************

import {
    cn_add,
    cn_box,
    cn_cart,
    cn_clone, cn_dist,
    cn_dot, cn_middle,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_sub,
    cn_is_rectangle,
    cnx_clone
} from '../utils/cn_utilities';
import { cn_element } from './cn_element';
import { cn_contour } from './cn_contour';
import { cn_vertex } from './cn_vertex';
import { fh_add, fh_mul, fh_polygon, fh_sub } from '@enerbim/fh-3d-viewer';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { cn_space } from './cn_space';
import { cn_storey } from './cn_storey';
import { logger } from '../utils/cn_logger';

export class cn_flat_part {
    constructor(flat) {
        this.flat = flat;
        this.vertices = [];
    }

    draw_highlight(camera, mouseover = false) {
        var html = '';
        var draw_class = (mouseover) ? 'mouseover' : 'selected';
        html += '<g opacity=\'0.5\'><path class=\'stairs_flat_part ' + draw_class + '\' d=\'';

        var vertices = [];
        vertices.push(camera.world_to_screen(this.vertices[0]));
        vertices.push(camera.world_to_screen(this.vertices[1]));
        vertices.push(camera.world_to_screen(this.vertices[3]));
        vertices.push(camera.world_to_screen(this.vertices[2]));

        for (var i = 0; i < vertices.length; i++) {
            if (i == 0)
                html += 'M';
            else if (i == 1)
                html += 'L';
            html += ' ' + vertices[i][0] + ' ' + vertices[i][1];
        }

        html += ' Z\' /></g>';
        return html;
    }

    contains(p) {
        var vertices = [];
        vertices.push(this.vertices[0]);
        vertices.push(this.vertices[2]);
        vertices.push(this.vertices[3]);
        vertices.push(this.vertices[1]);
        for (var k = 0; k < 4; k++) {
            var v0 = vertices[k];
            var v1 = vertices[(k + 1) % 4];
            var dir = cn_normal(cn_sub(v1, v0));
            if (cn_dot(dir, cn_sub(p, v0)) > 0) return false;
        }
        return true;
    }
}

//***********************************************************************************
//**** cn_stairs :
//***********************************************************************************
export class cn_stairs extends cn_element {
    constructor(scene) {
        super();
        this.scene = scene;

        this.stairs_type = 'straight';

        //*** Model data for straight stairs
        this.vertices = [];
        this.flat_parts = [];

        //*** Model data for round stairs
        this.center = [0, 0];
        this.radius = 0;
        this.angles = [0, 0];

        //*** Common model data */
        this.stair_width = 1;
        this.stair_height = 0.2;
        this.stair_depth = 0;
        this.stair_number = 0;
        this.axis = 0;
        this.space = null;
        this.slab_opening = [];
        this.height = 0;

        //*** volatile data
        this.valid = false;
        this.left_vertices = [];
        this.right_vertices = [];
        this.contour = null;
        this.actual_stair_height = 0;
        this.actual_stair_number = 0;
        this.actual_stair_depth = 0;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;

        json.stairs_type = this.stairs_type;

        if (json.stairs_type == 'straight') {
            json.vertices = [];
            for (var i in this.vertices)
                json.vertices.push(cn_clone(this.vertices[i]));
            json.flat_parts = [];
            for (var i in this.flat_parts)
                json.flat_parts.push(this.flat_parts[i].flat);
        } else if (json.stairs_type == 'round') {
            json.center = cn_clone(this.center);
            json.radius = this.radius;
            json.angles = cn_clone(this.angles);
        }

        json.stair_width = this.stair_width;
        json.stair_height = this.stair_height;
        json.stair_depth = this.stair_depth;
        json.stair_number = this.stair_number;
        json.axis = this.axis;
        if (json.space)
            json.space = this.space.ID;
        json.slab_opening = this.slab_opening.map(cn_clone);
        json.height = this.height;

        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json) != 'object') return false;

        var stairs = new cn_stairs(scene);

        if (typeof (json.ID) == 'string') stairs.ID = json.ID;

        if (typeof (json.stairs_type) == 'string')
            stairs.stairs_type = json.stairs_type;

        if (stairs.stairs_type == 'straight') {
            for (var i in json.vertices)
                stairs.vertices.push(cn_clone(json.vertices[i]));
            if (typeof (json.flat_parts) == 'object') {
                for (var i in json.flat_parts)
                    stairs.flat_parts.push(new cn_flat_part(json.flat_parts[i]));
            }
        } else if (stairs.stairs_type == 'round') {
            stairs.center = cn_clone(json.center);
            stairs.radius = json.radius;
            stairs.angles = cn_clone(json.angles);
        }

        if (typeof (json.stair_width) == 'number')
            stairs.stair_width = json.stair_width;
        if (typeof (json.stair_height) == 'number')
            stairs.stair_height = json.stair_height;
        if (typeof (json.stair_depth) == 'number')
            stairs.stair_depth = json.stair_depth;
        if (typeof (json.stair_number) == 'number')
            stairs.stair_number = json.stair_number;
        if (typeof (json.axis) == 'number' && (json.axis == 0 || json.axis == 1 || json.axis == 2))
            stairs.axis = json.axis;

        if (typeof (json.space) == 'string')
            stairs.space = scene.get_space(json.space);

        if (typeof (json.slab_opening) == 'object')
            stairs.slab_opening = json.slab_opening.map(cn_clone);

        if (typeof (json.height) == 'number')
            stairs.height = json.height;

        stairs.build_borders();
        scene.stairs.push(stairs);

        return stairs;
    }

    //***********************************************************************************
    //**** get stair parameter
    //***********************************************************************************
    get_stair_parameter() {
        if (this.stair_height > 0)
            return { key: 'stair_height', value: this.stair_height };
        else if (this.stair_depth > 0)
            return { key: 'stair_depth', value: this.stair_depth };
        else if (this.stair_number > 0)
            return { key: 'stair_number', value: this.stair_number };
        logger.log('WE SHOULD NOT BE HERE');
        return { key: 'stair_height', value: 0.2 };
    }

    set_stair_parameter(v) {
        this.stair_height = 0;
        this.stair_depth = 0;
        this.stair_number = 0;
        this[v.key] = v.value;
    }

    //***********************************************************************************
    /**
     * Performs a rotation operation
     * @param {number[]} center : center of ritation
     * @param {number} angle : rotation angle, in radians
     * @param {function} rotation_function : fnction that transforms a 2D point
     */
    perform_rotation(center, angle, rotation_function) {
        this.vertex_operation(rotation_function);
        if (this.stairs_type == 'round') {
            for (var i in this.angles)
                this.angles[i] += angle;
        }
    }

    //***********************************************************************************
    /**
     * Vertex operation : transform all vertices
     * @param {function} operation : vertex operator
     */
    vertex_operation(operation) {

        if (this.stairs_type == 'straight') {
            for (var i in this.vertices)
                operation(this.vertices[i]);
        } else if (this.stairs_type == 'round') {
            operation(this.center);
        }
    }

    //***********************************************************************************
    /**
     * flip operation : transform all vertices
     * @param {number[]} center : center of flip
     * @param {boolean} horizontal : true for horizontal flip, vertical otherwise
     * @param {function} operation : vertex operator
     */
    perform_flip(center, horizontal, operation) {
        if (this.stairs_type == 'straight') {
            for (var i in this.vertices)
                operation(this.vertices[i]);
            this.axis = 2 - this.axis;
        } else if (this.stairs_type == 'round') {
            operation(this.center);
            for (var i in this.angles) {
                if (horizontal)
                    this.angles[i] = Math.PI - this.angles[i];
                else
                    this.angles[i] = -this.angles[i];
            }
        }
    }

    //***********************************************************************************
    //**** contains a point
    //***********************************************************************************
    contains(p) {
        if (this.contour == null) return false;
        return this.contour.contains(p);
    }

    contained_by_box(box) {
        if (this.contour == null) return false;
        return this.contour.contained_by_box(box);
    }

    //***********************************************************************************
    /**
     * Returns bounding box
     * @returns {cn_box}
     */
    get_bounding_box() {
        var box = new cn_box();
        if (this.contour)
            box.enlarge_box(this.contour.get_bounding_box());
        return box;
    }

    //***********************************************************************************
    //**** Build borders
    //***********************************************************************************

    build_valid_borders() {
        if (this.build_borders()) return true;
        var vmax = this.stair_width;
        var vmin = 0.5;
        if (vmin >= vmax) return false;
        this.stair_width = vmin;
        if (!this.build_borders()) {
            this.stair_width = vmax;
            return false;
        }
        while (vmax - vmin > 0.01) {
            this.stair_width = 0.5 * (vmax + vmin);
            if (this.build_borders())
                vmin = this.stair_width;
            else
                vmax = this.stair_width;
        }
        this.stair_width = vmin;
        return this.build_borders();
    }

    build_borders() {
        this.valid = false;
        var res = false;
        if (this.stairs_type == 'straight')
            res = this._build_borders_straight();
        else if (this.stairs_type == 'round')
            res = this._build_borders_round();
        if (res && this.slab_opening.length == 0)
            this.build_slab_opening();
        return res;
    }

    _build_borders_straight() {
        if (this.vertices.length < 2) return false;

        //*** filter vertices to have at least 1cm between each. */
        const new_vertices = [this.vertices[0]];
        for (var i = 1; i < this.vertices.length; i++) {
            if (cn_dist(new_vertices[new_vertices.length - 1], this.vertices[i]) > 0.01)
                new_vertices.push(this.vertices[i]);
        }
        this.vertices = new_vertices;
        if (this.vertices.length < 2) return false;

        if (cn_is_rectangle(this.vertices)) {
            var d0 = cn_sub(this.vertices[1], this.vertices[0]);
            var d1 = cn_sub(this.vertices[2], this.vertices[1]);
            if (cn_dot(d1, cn_normal(d0)) > 0)
                this.axis = 2;
            else
                this.axis = 0;
        }

        this.left_vertices = [];
        this.right_vertices = [];
        for (var i = 0; i < this.vertices.length; i++) {
            var normal;
            if (i == 0) {
                dir = cn_sub(this.vertices[1], this.vertices[0]);
                normal = cn_normal(dir);
                var length = cn_normalize(normal);
                if (length < 0.001) return false;
            } else if (i == this.vertices.length - 1) {
                dir = cn_sub(this.vertices[this.vertices.length - 1], this.vertices[this.vertices.length - 2]);
                normal = cn_normal(dir);
                var length = cn_normalize(normal);
                if (length < 0.001) return false;
            } else {
                var nor0 = cn_normal(cn_sub(this.vertices[i], this.vertices[i - 1]));
                cn_normalize(nor0);
                var nor1 = cn_normal(cn_sub(this.vertices[i + 1], this.vertices[i]));
                cn_normalize(nor1);
                normal = cn_add(nor0, nor1);
                var x = cn_dot(normal, nor0);
                if (Math.abs(x) < 0.001) return false;
                normal = cn_mul(normal, 1 / x);
            }

            normal = cn_mul(normal, this.stair_width);
            if (this.axis == 0) {
                this.left_vertices.push(cn_clone(this.vertices[i]));
                this.right_vertices.push(cn_sub(this.vertices[i], normal));
            } else if (this.axis == 1) {
                this.left_vertices.push(cn_add(this.vertices[i], cn_mul(normal, 0.5)));
                this.right_vertices.push(cn_sub(this.vertices[i], cn_mul(normal, 0.5)));
            } else {
                this.left_vertices.push(cn_add(this.vertices[i], normal));
                this.right_vertices.push(cn_clone(this.vertices[i]));
            }

            if (i == 0) continue;

            var dir = cn_sub(this.vertices[i], this.vertices[i - 1]);
            if (cn_normalize(dir) < 0.001) return false;
            var x = cn_dot(dir, cn_sub(this.left_vertices[i], this.left_vertices[i - 1]));
            if (x < 0.001) return false;
            x = cn_dot(dir, cn_sub(this.right_vertices[i], this.right_vertices[i - 1]));
            if (x < 0.001) return false;
        }
        this.valid = true;

        this.contour = new cn_contour();
        for (var i = 0; i < this.left_vertices.length; i++)
            this.contour.vertices.push(new cn_vertex(this.left_vertices[i]));
        for (var i = 0; i < this.right_vertices.length; i++)
            this.contour.vertices.push(new cn_vertex(this.right_vertices[this.right_vertices.length - 1 - i]));
        this.contour.update();

        this.build_flat_parts();
        return true;
    }

    /**
     * @return {boolean}
     */
    _build_borders_round() {

        //*** Get full height
        var full_height = this._get_full_height();

        var valid_length = Math.abs(this.angles[0] - this.angles[1]) * this.radius;

        //*** Compute stair angle */
        var nb_steps = this.stair_number;
        if (nb_steps == 0) {
            if (this.stair_height > 0)
                nb_steps = Math.round(full_height / this.stair_height);
            else if (this.stair_depth > 0)
                nb_steps = Math.round(valid_length / this.stair_depth);
            if (nb_steps == 0)
                nb_steps = 18;
        }
        var stair_height = full_height / nb_steps;
        this.actual_stair_height = stair_height;
        this.actual_stair_number = nb_steps;
        this.actual_stair_depth = valid_length / nb_steps;
        this.stair_angle = Math.abs(this.angles[0] - this.angles[1]) / nb_steps;

        this.left_vertices = [];
        this.right_vertices = [];
        var sense = (this.angles[1] > this.angles[0]) ? 1 : -1;
        var left_radius = this.radius;
        var right_radius = this.radius - this.stair_width;
        if (right_radius < 0) right_radius = 0;
        for (var i = 0; i <= nb_steps; i++) {
            var alpha = this.angles[0] + this.stair_angle * sense * i;
            this.left_vertices.push(cn_add(this.center, cn_cart([left_radius, alpha])));
            this.right_vertices.push(cn_add(this.center, cn_cart([right_radius, alpha])));
        }
        this.vertices = this.left_vertices;

        this.contour = new cn_contour();
        for (var i = 0; i < this.left_vertices.length; i++)
            this.contour.vertices.push(new cn_vertex(this.left_vertices[i]));
        for (var i = 0; i < this.right_vertices.length; i++)
            this.contour.vertices.push(new cn_vertex(this.right_vertices[this.right_vertices.length - 1 - i]));
        this.contour.update();

        this.valid = true;
        return true;
    }

    build_slab_opening() {
        if (!this.valid || !this.contour) {
            this.slab_opening = [];
            return;
        }

        //*** Round stairs */
        if (this.stairs_type != 'straight') {
            this.slab_opening = [];
            const dx = cn_cart([this.radius, this.angles[1]]);
            const dy = cn_mul(cn_normal(dx), (this.angles[0] > this.angles[1]) ? -1 : 1);
            this.slab_opening.push(cn_clone(this.center));
            this.slab_opening.push(cn_add(this.center, dx));
            this.slab_opening.push(cn_add(this.center, cn_sub(dx, dy)));
            this.slab_opening.push(cn_sub(this.center, cn_add(dx, dy)));
            this.slab_opening.push(cn_sub(this.center, cn_sub(dx, dy)));
            this.slab_opening.push(cn_add(this.center, dy));
        }
        //*** U stairs */
        else if (cn_is_rectangle(this.vertices)) {
            this.slab_opening = this.vertices.map(cn_clone);
        }
        //*** other cases */
        else {
            this.slab_opening = this.contour.inner_contour.concat([]);
            if (!this.contour.clockwise) this.slab_opening.reverse();
        }
    }

    /**
     * Translate a slab opening
     * @param {Array<number>} translation
     */
    translate_slab_opening(translation) {
        this.slab_opening = this.slab_opening.map(v => cn_add(v, translation));
    }

    //***********************************************************************************
    //**** set flat parts for rectangle shapeBuild
    //***********************************************************************************
    set_rectangle_flat_parts() {
        if (!this.valid) return;
        if (this.stairs_type != 'straight') return;
        for (var i = 0; i < this.flat_parts.length; i++)
            this.flat_parts[i].flat = (i != 1 && i != this.flat_parts.length - 2);
    }

    //***********************************************************************************
    //**** Build flat parts
    //***********************************************************************************
    build_flat_parts() {

        if (!this.valid) return;

        if (this.flat_parts.length > 3 * (this.vertices.length - 1))
            this.flat_parts.splice(3 * (this.vertices.length - 1));
        else {
            while (this.flat_parts.length < 3 * (this.vertices.length - 1))
                this.flat_parts.push(new cn_flat_part(false));
        }

        for (var i = 0; i < this.vertices.length - 1; i++) {
            var dir = cn_sub(this.vertices[i + 1], this.vertices[i]);
            var length = cn_normalize(dir);
            if (length < 0.01) {
                this.flat_parts[3 * i].valid = false;
                this.flat_parts[3 * i + 1].valid = false;
                this.flat_parts[3 * i + 2].valid = false;
                continue;
            }

            //*** build flat part before
            var x0left = cn_dot(dir, this.left_vertices[i]);
            var x0right = cn_dot(dir, this.right_vertices[i]);
            var x0 = 0;

            var flat_part = this.flat_parts[3 * i];
            flat_part.valid = (Math.abs(x0left - x0right) > 0.01);
            flat_part.vertices = [];
            flat_part.vertices.push(cn_clone(this.left_vertices[i]));
            flat_part.vertices.push(cn_clone(this.right_vertices[i]));
            if (x0left > x0right) {
                x0 = x0left;
                flat_part.vertices.push(cn_clone(this.left_vertices[i]));
                flat_part.vertices.push(cn_add(this.right_vertices[i], cn_mul(dir, x0left - x0right)));
            } else {
                x0 = x0right;
                flat_part.vertices.push(cn_add(this.left_vertices[i], cn_mul(dir, x0right - x0left)));
                flat_part.vertices.push(cn_clone(this.right_vertices[i]));
            }

            //*** build flat part after
            var x1left = cn_dot(dir, this.left_vertices[i + 1]);
            var x1right = cn_dot(dir, this.right_vertices[i + 1]);
            var x1 = 0;

            flat_part = this.flat_parts[3 * i + 2];
            flat_part.valid = (Math.abs(x1left - x1right) > 0.01);
            flat_part.vertices = [];
            if (x1left > x1right) {
                x1 = x1right;
                flat_part.vertices.push(cn_add(this.left_vertices[i + 1], cn_mul(dir, x1right - x1left)));
                flat_part.vertices.push(cn_clone(this.right_vertices[i + 1]));
            } else {
                x1 = x1left;
                flat_part.vertices.push(cn_clone(this.left_vertices[i + 1]));
                flat_part.vertices.push(cn_add(this.right_vertices[i + 1], cn_mul(dir, x1left - x1right)));
            }
            flat_part.vertices.push(cn_clone(this.left_vertices[i + 1]));
            flat_part.vertices.push(cn_clone(this.right_vertices[i + 1]));

            //*** build flat part along
            flat_part = this.flat_parts[3 * i + 1];
            flat_part.valid = (Math.abs(x1 - x0) > 0.01);
            flat_part.vertices = [];
            flat_part.vertices.push(cn_clone(this.flat_parts[3 * i].vertices[2]));
            flat_part.vertices.push(cn_clone(this.flat_parts[3 * i].vertices[3]));
            flat_part.vertices.push(cn_clone(this.flat_parts[3 * i + 2].vertices[0]));
            flat_part.vertices.push(cn_clone(this.flat_parts[3 * i + 2].vertices[1]));
        }

        this.compute_stairs();
    }

    //***********************************************************************************
    //**** Find flat part
    //***********************************************************************************
    find_flat_part(p) {
        if (!this.valid) return null;
        if (this.stairs_type != 'straight') return null;
        if (this.vertices.length <= 2) return null;

        for (var i in this.flat_parts) {
            if (this.flat_parts[i].contains(p))
                return this.flat_parts[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** search for space
    //***********************************************************************************
    update_space() {
        this.space = null;
        if (!this.valid) return;
        const p0 = this.left_vertices[0];
        const p1 = this.right_vertices[0];
        const sp0 = this.scene.find_space(cn_add(cn_mul(p0, 0.1), cn_mul(p1, 0.9)), true);
        const sp1 = this.scene.find_space(cn_add(cn_mul(p0, 0.9), cn_mul(p1, 0.1)), true);
        if (sp0 == sp1) this.space = sp0;
    }

    /**
     * Returns the upper space (where stairs arrive) in upper storey.
     * @param {cn_storey} upper_storey
     * @returns {cn_space}
     */
    get_upper_space(upper_storey) {
        if (!upper_storey) return null;
        if (!this.valid) return null;
        return upper_storey.scene.find_space(cn_middle(this.left_vertices[this.left_vertices.length - 1], this.right_vertices[this.right_vertices.length - 1]), true);
    }

    //***********************************************************************************
    //**** deep update
    update_deep() {
        this.update_space();
        this.build_borders();
    }

    //***********************************************************************************
    //**** Draw the wall in svg
    //***********************************************************************************
    draw(camera, add_classes = [], fill = '') {
        if (!this.valid) return '';

        var html = '';
        if (this.stairs_type == 'straight' || this.stairs_type == 'round')
            html = this._draw(camera, add_classes, fill);
        else
            return '';

        if (this.space == null || this.status < 0)
            html = '<g opacity=\'0.2\'>' + html + '</g>';
        return html;
    }

    _draw(camera, add_classes, fill) {
        var contour = [];
        var main_list = [];
        for (var i = 0; i < this.vertices.length; i++) {
            contour.push(camera.world_to_screen(this.left_vertices[i]));
            main_list.push(camera.world_to_screen(this.vertices[i]));
        }
        for (var i = 0; i < this.vertices.length; i++)
            contour.push(camera.world_to_screen(this.right_vertices[this.vertices.length - 1 - i]));

        var html = '';

        var mouseover = (add_classes.indexOf('mouseover') >= 0);
        var selected = (add_classes.indexOf('selected') >= 0);
        var exp = (add_classes.indexOf('exp') >= 0) ? 'exp' : '';

        var draw_class = 'stairs';
        var extra_classes = '';

        var contour_path = '\'';
        for (var i = 0; i < contour.length; i++) {
            if (i == 0)
                contour_path += 'M';
            else if (i == 1)
                contour_path += 'L';
            contour_path += ' ' + contour[i][0] + ' ' + contour[i][1];
        }
        contour_path += ' Z\'';

        //*** draw highlight */
        if (mouseover || selected) {
            html += `<path class="stairs_highlight ${(selected) ? 'selected' : 'mouseover'}" ${fill} d=${contour_path} />`;
        }

        //*** draw background
        html += `<path class="stairs_background ${exp}" ${fill} d=${contour_path} `;
        /*if (mouseover)
            html += "filter='url(#mouseover_shadow)'";
        else if (selected)
            html += "filter='url(#selection_shadow)'";*/
        html += ' />';

        //*** Draw axis
        if (this.stairs_type == 'straight') {
            html += '<path class=\'stairs_axis ' + exp + '\' d=\'';
            for (var i = 0; i < main_list.length; i++) {
                if (i == 0)
                    html += 'M';
                else if (i == 1)
                    html += 'L';
                html += ' ' + main_list[i][0] + ' ' + main_list[i][1];
            }
            html += '\' />';
        }

        //*** draw flat parts
        if (this.stairs_type == 'straight') {
            for (var n = 0; n < this.flat_parts.length; n++) {
                var flat_part = this.flat_parts[n];
                if (!flat_part.valid) continue;
                if (flat_part.flat) continue;
                html += `<path class="stairs_steps ${exp}" d="`;
                for (var i = 0; i < flat_part.vertices.length; i++) {
                    if (i == 0)
                        html += 'M';
                    else if (i == 1)
                        html += 'L';

                    var ii = (i < 2) ? 1 - i : i;
                    var pt = camera.world_to_screen(flat_part.vertices[ii]);
                    html += ' ' + pt[0] + ' ' + pt[1];
                }
                html += ` Z" />`;

                if (flat_part.flat) continue;
                var left_x = cn_dist(flat_part.vertices[0], flat_part.vertices[2]);
                var right_x = cn_dist(flat_part.vertices[1], flat_part.vertices[3]);
                var max_x = (left_x > right_x) ? left_x : right_x;
                var nb_stairs = flat_part.nb_steps;//Math.ceil(max_x/0.2);
                if (nb_stairs < 1) nb_stairs = 1;
                var delta = 1 / nb_stairs;
                for (var s = 1; s < nb_stairs; s++) {
                    var pp0 = camera.world_to_screen(cn_add(cn_mul(flat_part.vertices[0], 1 - s * delta), cn_mul(flat_part.vertices[2], s * delta)));
                    var pp1 = camera.world_to_screen(cn_add(cn_mul(flat_part.vertices[1], 1 - s * delta), cn_mul(flat_part.vertices[3], s * delta)));
                    html += '<line class=\'stairs_steps ' + exp + '\' x1=\'' + pp0[0] + '\' y1=\'' + pp0[1] + '\' x2=\'' + pp1[0] + '\' y2=\'' + pp1[1] + '\' />';
                }
            }
        }

        //*** draw steps
        if (this.stairs_type == 'round') {
            for (var s = 1; s < this.vertices.length - 1; s++) {
                var pp0 = camera.world_to_screen(this.left_vertices[s]);
                var pp1 = camera.world_to_screen(this.right_vertices[s]);
                html += `<line class="stairs_steps ${exp}" x1="${pp0[0]}" y1="${pp0[1]}" x2="${pp1[0]}" y2="${pp1[1]}" />`;
            }
        }

        //*** draw arrow
        var p0 = cn_middle(this.left_vertices[0], this.right_vertices[0]);
        var p1 = cn_middle(this.left_vertices[1], this.right_vertices[1]);
        var dir = cn_sub(p1, p0);
        cn_normalize(dir);
        p1 = camera.world_to_screen(cn_add(p0, dir));
        p0 = camera.world_to_screen(p0);
        html += '<line class=\'stairs_direction ' + exp + '\' x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' />';

        //*** draw slab opening */
        if (this.height <= 0 && this.slab_opening.length > 2 && exp == '') {
            html += '<path class=\'stairs_slab_opening\' d=\'';

            for (var i = 0; i < this.slab_opening.length; i++) {
                if (i == 0)
                    html += 'M';
                else if (i == 1)
                    html += 'L';

                const slo = camera.world_to_screen(this.slab_opening[i]);
                html += ' ' + slo[0] + ' ' + slo[1];
            }
            html += ' Z\' />';
        }

        //*** draw highlight
        if (mouseover)
            html += '<path class=\'stairs_contour mouseover\' d=' + contour_path + ' />';

        if (selected)
            html += '<path class=\'stairs_contour selected\' d=' + contour_path + ' />';

        return html;
    }

    //***********************************************************************************
    //**** Draw highlights
    //***********************************************************************************

    draw_highlight(camera, mouseover = false) {
        if (!this.valid) return '';
        var html = '';

        var left_list = [];
        var right_list = [];
        for (var i = 0; i < this.vertices.length; i++) {
            left_list.push(camera.world_to_screen(this.left_vertices[i]));
            right_list.push(camera.world_to_screen(this.right_vertices[i]));
        }
        var draw_class = (mouseover) ? 'line_mouseover' : 'line_selected';
        html += '<path class=\'' + draw_class + '\' d=\'';

        for (var i = 0; i < left_list.length; i++) {
            if (i == 0)
                html += 'M';
            else if (i == 1)
                html += 'L';
            html += ' ' + left_list[i][0] + ' ' + left_list[i][1];
        }

        for (var i = right_list.length - 1; i >= 0; i--) {
            html += 'L';
            html += ' ' + right_list[i][0] + ' ' + right_list[i][1];
        }

        html += ' Z\' />';

        return html;
    }

    //***********************************************************************************
    //**** Compute steps detail
    //***********************************************************************************
    compute_stairs() {
        if (!this.valid) return;

        //*** Get full height
        var full_height = this._get_full_height();

        //*** Compute valid length
        var valid_length = 0;
        for (var n = 0; n < this.flat_parts.length; n++) {
            var fp = this.flat_parts[n];
            fp.nb_steps = 0;
            fp.valid_length = 0;
            if (!fp.valid) continue;
            if (fp.flat) continue;
            var l0 = cn_dist(fp.vertices[0], fp.vertices[2]);
            var l1 = cn_dist(fp.vertices[1], fp.vertices[3]);
            var l = (l0 > l1) ? l0 : l1;
            valid_length += l;
            fp.valid_length = l;
        }

        //*** Compute steps geometry
        var nb_steps = this.stair_number;
        if (nb_steps == 0) {
            if (this.stair_height > 0)
                nb_steps = Math.round(full_height / this.stair_height);
            else if (this.stair_depth > 0)
                nb_steps = Math.round(valid_length / this.stair_depth);
            if (nb_steps == 0)
                nb_steps = 18;
        }
        var stair_height = full_height / nb_steps;
        this.actual_stair_height = stair_height;
        this.actual_stair_number = nb_steps;
        this.actual_stair_depth = valid_length / nb_steps;

        //*** start each actually flat part with a step
        var previous_flat = false;
        for (var n = 0; n < this.flat_parts.length; n++) {
            var fp = this.flat_parts[n];
            if (!fp.valid) continue;
            if (fp.flat && !previous_flat) {
                fp.nb_steps = 1;
                nb_steps--;
                if (nb_steps == 0) break;
            }

            previous_flat = fp.flat;
        }

        //*** Distribute steps along flat parts
        var nb_remaining_steps = nb_steps;
        var longest_fp = null;
        var longest_fp_steps = 0;
        for (var n = 0; n < this.flat_parts.length; n++) {
            var fp = this.flat_parts[n];
            if (!fp.valid || fp.flat) continue;

            //*** number of steps depend on valid length
            fp.nb_steps = Math.round(nb_steps * fp.valid_length / valid_length);

            //*** remember the longest stairway, it will gather extra steps
            if (fp.valid_length > longest_fp_steps) {
                longest_fp_steps = fp.valid_length;
                longest_fp = fp;
            }

            //*** end if not enough steps
            if (fp.nb_steps >= nb_remaining_steps) {
                fp.nb_steps = nb_remaining_steps;
                nb_remaining_steps = 0;
                break;
            }
            nb_remaining_steps -= fp.nb_steps;
        }
        if (nb_remaining_steps != 0 && longest_fp)
            longest_fp.nb_steps += nb_remaining_steps;
    }

    //***********************************************************************************
    /**
     * Builds footprint of the stairs
     * @param {number} h height
     * @returns {fh_polygon} retrns footprint or null if not valid
     */
    build_footprint(h) {
        if (!this.valid) return null;

        var pg = new fh_polygon([0, 0, h], [0, 0, 1]);

        var contour = [];
        for (var i = 0; i < this.vertices.length; i++) {
            var p = cn_clone(this.left_vertices[i]);
            p.push(h);
            contour.push(p);
        }
        for (var i = this.vertices.length - 1; i >= 0; i--) {
            var p = cn_clone(this.right_vertices[i]);
            p.push(h);
            contour.push(p);
        }
        pg.add_contour(contour);
        return pg;
    }

    //***********************************************************************************
    //**** To 3D ; return a list of polygons.
    //***********************************************************************************
    build_polygons(h0, h1) {
        if (!this.valid) return [];

        var hh0 = h0;
        if (this.space) hh0 += this.space.slab_offset;
        var hh1 = hh0 + this._get_full_height();

        if (this.stairs_type == 'straight')
            return this._build_polygons_straight(hh0, hh1);

        if (this.stairs_type == 'round')
            return this._build_polygons_round(hh0, hh1);
        return [];
    }

    _build_polygons_straight(h0, h1) {
        var stair_height = this.actual_stair_height;

        //*** build polygons
        var h = h0;
        var polygons = [];
        var one_step = [0, 0, stair_height];
        for (var n = 0; n < this.flat_parts.length; n++) {
            var fp = this.flat_parts[n];
            if (fp.nb_steps == 0) {
                var pg = new fh_polygon([fp.vertices[0][0], fp.vertices[0][1], h], [0, 0, 1]);
                var ctr = [];
                ctr.push([fp.vertices[0][0], fp.vertices[0][1], h]);
                ctr.push([fp.vertices[2][0], fp.vertices[2][1], h]);
                ctr.push([fp.vertices[3][0], fp.vertices[3][1], h]);
                ctr.push([fp.vertices[1][0], fp.vertices[1][1], h]);
                pg.add_contour(ctr);
                polygons.push(pg);

                var ctr2 = [];
                for (var k = 0; k < 4; k++)
                    ctr2.push(fh_sub(ctr[k], one_step));

                pg = new fh_polygon(ctr2[0], [0, 0, -1]);
                pg.add_contour(ctr2);
                polygons.push(pg);

                pg = new fh_polygon();
                pg.add_contour([ctr[0], ctr2[0], ctr2[1], ctr[1]]);
                polygons.push(pg);

                pg = new fh_polygon();
                pg.add_contour([ctr[2], ctr2[2], ctr2[3], ctr[3]]);
                polygons.push(pg);

                if (n == this.flat_parts.length - 1) {
                    pg = new fh_polygon();
                    pg.add_contour([ctr[1], ctr[2], ctr2[2], ctr2[1]]);
                    polygons.push(pg);
                }

                continue;
            }
            var hh = h + stair_height * fp.nb_steps;
            var p0 = [fp.vertices[0][0], fp.vertices[0][1], h];
            var p1 = [fp.vertices[1][0], fp.vertices[1][1], h];
            var d0 = fh_mul(fh_sub([fp.vertices[2][0], fp.vertices[2][1], hh], p0), 1 / fp.nb_steps);
            var d1 = fh_mul(fh_sub([fp.vertices[3][0], fp.vertices[3][1], hh], p1), 1 / fp.nb_steps);

            var left_ramp = [fh_sub(p0, one_step)];
            var right_ramp = [fh_sub(p1, one_step)];

            for (var k = 0; k < fp.nb_steps; k++) {
                var pp0 = fh_add(p0, one_step);
                var pp1 = fh_add(p1, one_step);

                left_ramp.push(p0);
                left_ramp.push(pp0);
                right_ramp.push(p1);
                right_ramp.push(pp1);

                var pg = new fh_polygon();
                var ctr = [];
                ctr.push(p0);
                ctr.push(pp0);
                ctr.push(pp1);
                ctr.push(p1);
                pg.add_contour(ctr);
                polygons.push(pg);

                p0 = fh_add(p0, d0);
                p1 = fh_add(p1, d1);

                var pg2 = new fh_polygon(p0, [0, 0, 1]);
                var ctr2 = [];
                ctr2.push(ctr[1]);
                ctr2.push(p0);
                ctr2.push(p1);
                ctr2.push(ctr[2]);
                pg2.add_contour(ctr2);
                polygons.push(pg2);
            }

            left_ramp.push(p0);
            left_ramp.push(fh_sub(p0, one_step));
            left_ramp.reverse();
            var pg = new fh_polygon();
            pg.add_contour(left_ramp);
            polygons.push(pg);

            right_ramp.push(p1);
            right_ramp.push(fh_sub(p1, one_step));
            pg = new fh_polygon();
            pg.add_contour(right_ramp);
            polygons.push(pg);

            if (Math.abs(cn_dist(fp.vertices[0], fp.vertices[2]) - cn_dist(fp.vertices[1], fp.vertices[3])) < 0.01) {
                pg = new fh_polygon();
                var ctr = [];
                ctr.push(right_ramp[right_ramp.length - 1]);
                ctr.push(right_ramp[0]);
                ctr.push(left_ramp[left_ramp.length - 1]);
                ctr.push(left_ramp[0]);
                pg.add_contour(ctr);
                polygons.push(pg);
            }

            h = hh;
        }

        return polygons;
    }


    _build_polygons_round(h0, h1) {
        var polygons = [];

        var delta_alpha = this.stair_angle;
        if (this.angles[1] < this.angles[0]) delta_alpha *= -1;
        var r0 = this.radius;
        var r1 = this.radius - this.stair_width;
        if (r1 < 0) r1 = 0;

        for (var n = 0; n < this.actual_stair_number; n++) {
            var a0 = this.angles[0] + delta_alpha * n;
            var a1 = this.angles[0] + delta_alpha * (n + 1);
            var z0 = h0 + this.actual_stair_height * n;
            var z1 = h0 + this.actual_stair_height * (n + 1);
            if (n > 0) z0 -= this.actual_stair_height * 0.5;

            var vertices_0 = [];
            vertices_0.push(cn_add(this.center, cn_cart([r0, a0])));
            vertices_0.push(cn_add(this.center, cn_cart([r1, a0])));
            vertices_0.push(cn_add(this.center, cn_cart([r1, a1])));
            vertices_0.push(cn_add(this.center, cn_cart([r0, a1])));
            var vertices_1 = [];
            for (var k = 0; k < vertices_0.length; k++) {
                vertices_0[k].push(z1);
                vertices_1.push(fh_sub(vertices_0[k], [0, 0, this.actual_stair_height]));
            }
            if (n > 0) {
                vertices_1[0][2] -= 0.5 * this.actual_stair_height;
                vertices_1[1][2] -= 0.5 * this.actual_stair_height;
                vertices_1[2][2] += 0.5 * this.actual_stair_height;
                vertices_1[3][2] += 0.5 * this.actual_stair_height;
            }

            var pg = new fh_polygon();
            pg.add_contour(vertices_0);
            polygons.push(pg);

            pg = new fh_polygon();
            pg.add_contour([vertices_1[0], vertices_1[1], vertices_1[2]]);
            polygons.push(pg);

            pg = new fh_polygon();
            pg.add_contour([vertices_1[0], vertices_1[2], vertices_1[3]]);
            polygons.push(pg);

            for (var k = 0; k < 4; k++) {
                var k1 = ((k + 1) % 4);
                pg = new fh_polygon();
                pg.add_contour([vertices_0[k], vertices_1[k], vertices_1[k1], vertices_0[k1]]);
                polygons.push(pg);
            }
        }
        return polygons;
    }

    _get_full_height() {

        if (!this.scene.storey) return 2.8;

        if (this.height > 0) return this.height;

        //*** initialize height with storey height */
        var hh = this.scene.storey.height + 0.3;

        //*** add this space slab offset */
        if (this.space) hh -= this.space.slab_offset;

        //*** check upper storey */
        var index = this.scene.building.storeys.indexOf(this.scene.storey);
        if (index >= 0 && index < this.scene.building.storeys.length - 1) {
            //*** find scene of upper storey */
            var sc = this.scene.building.storeys[index + 1].scene;

            //*** in which space ends the stairs ? */
            var last_point = [0, 0]
            if (this.stairs_type == 'straight')
                last_point = cn_middle(this.left_vertices[this.left_vertices.length - 1], this.right_vertices[this.right_vertices.length - 1]);
            else if (this.stairs_type == 'round') {
                const radius = (this.radius > this.stair_width) ? this.radius - this.stair_width * 0.5 : this.radius * 0.5;
                last_point = cn_add(this.center, cn_cart([radius, this.angles[1]]));
            }
            var sp = sc.find_space(last_point);

            //*** Add this space's slab offset */
            if (sp) hh += sp.slab_offset;
        }
        return hh;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_stairs(this);
    }

    /**
     * Builds slab opening
     * @param {number} z
     * @returns {fh_polygon}
     */
    build_3d_slab_opening(z) {
        if (this.height > 0 || !this.valid) return null;
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        polygon.add_contour(this.slab_opening.map(cnx_clone));
        polygon.compute_contours();
        return polygon;
    }

}

