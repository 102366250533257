'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2021 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

import { cn_space } from './cn_space';
import { cn_storey } from './cn_storey';
import { cn_area_context } from './cn_area_context';
import { cn_camera } from '../svg/cn_camera';

//***********************************************************************************
//***********************************************************************************
//**** cn_space_area : an item held by the storey, that contains all area information for a given space
//***********************************************************************************
//***********************************************************************************

export class cn_space_area {
    //***********************************************************************************
    /**
     * Constructor
     *
     * @param {cn_space} space
     * @param {cn_storey} storey
     * @param {cn_area_context} area_context
     */
    constructor(space, storey, area_context) {

        /** Back pointer */
        this.storey = storey;

        /** space */
        this.space = space;

        /** area context */
        this.area_context = area_context;

        /** Max sub level */
        this.sub_level = 0;

        //*** floor data */
        this.floor_area = 0;  //*** Area of floor, without slab openings, indoor */
        this.floor_polygon = null;

        //** list of area info for each sub level */
        this.sub_areas = [];
        this.sub_polygons = [];
    }

    //***********************************************************************************
    /**
     * Serialize method
     * @returns {object}
     */
    serialize() {
        var json = {};
        json.space = this.space.ID;
        json.area_context = this.area_context.label;
        json.sub_level = this.sub_level;
        json.floor_area = this.floor_area;
        json.sub_areas = this.sub_areas.concat([]);
        return json;
    }

    //***********************************************************************************
    /**
     * Unserialize method
     * @param {object} json
     * @param {cn_storey} storey
     * @returns {cn_space_area}
     */
    static unserialize(json, storey) {
        var space = storey.scene.spaces.find(sp => sp.ID == json.space);
        if (!space) return null;
        var area_context = storey.building.area_contexts.find(ac => ac.label == json.area_context);
        if (!area_context) return null;
        let space_area = new cn_space_area(space, storey, area_context);
        if (typeof (json.sub_level) == 'number' && json.sub_level < area_context.sub_labels.length)
            space_area.sub_level = json.sub_level;
        storey.space_areas.push(space_area);
        return space_area;
    }

    //***********************************************************************************
    /**
     * Draw method
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        var html = '';
        for (var i = 0; i < this.sub_polygons.length; i++) {
            var draw_class = 'space_area_' + this.area_context.regulation.color_code;
            if (this.area_context.sub_labels.length) draw_class += '_' + i;
            html += camera.draw_polygon(this.sub_polygons[i], draw_class);
        }
        return html;
    }
}
