//***********************************************************************************
//***********************************************************************************
//**** Matrix utilities
//***********************************************************************************
//***********************************************************************************

export class fh_matrix{
	//***********************************************************************************
	//**** Constructor
	//***********************************************************************************
	constructor() {
		this.load_identity();
	}

	//***********************************************************************************
	//**** clone
	//***********************************************************************************
	clone() {
		var m = new fh_matrix();
		for (var k=0;k<16;k++)
			m.values[k] = this.values[k];
		return m;
	}

	//***********************************************************************************
	//**** Set to identity
	//***********************************************************************************
	load_identity() {
		this.values=[1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1];
	}


	//***********************************************************************************
	//**** Multiplies by other matrix
	//***********************************************************************************
	multiplies(matrix) {
		var tmp = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
		for (var k = 0; k < 16; k++) tmp[k] = this.values[k];
		for (var j = 0; j < 4; j++)
		{
			for (var i = 0; i < 4; i++)
			{
				this.values[i + 4 * j] = 0;
				for (var k = 0; k < 4; k++)
					this.values[i + 4 * j] += tmp[k * 4 + i] * matrix.values[j * 4 + k];
			}
		}
	}

	//***********************************************************************************
	//**** Load with various transformations
	//***********************************************************************************
	load_axis(origin, dx, dy, dz) {
		this.load_identity();
		for (var k=0;k<3;k++)
		{
			this.values[k] = dx[k];
			this.values[k+4] = dy[k];
			this.values[k+8] = dz[k];
			this.values[k+12] = origin[k];
		}
	}

	load_translation(v) {
		this.load_identity();
		this.values[12] = v[0];
		this.values[13] = v[1];
		this.values[14] = v[2];
	}

	load_rotation(axis, angle) {
		this.load_identity();
		var ii = ((axis + 1) % 3);
		var jj = ((axis + 2) % 3);
		var cc = Math.cos(angle);
		var ss = Math.sin(angle);
		this.values[ii + 4 * ii] = cc;
		this.values[ii + 4 * jj] = -ss;
		this.values[jj + 4 * ii] = ss
		this.values[jj + 4 * jj] = cc;
	}

	load_scaling(dx, dy, dz) {
		this.load_identity();
		this.values[0] = dx;
		this.values[5] = dy;
		this.values[10] = dz;
	}

	//***********************************************************************************
	//**** Apply various transformations
	//***********************************************************************************
	translate(v)
	{
		var m = new fh_matrix();
		m.load_translation(v);
		this.multiplies(m);
	}

	rotate(axis, angle)
	{
		var m = new fh_matrix();
		m.load_rotation(axis,angle);
		this.multiplies(m);
	}

	scale(dx, dy, dz)
	{
		var m = new fh_matrix();
		m.load_scaling(dx, dy, dz);
		this.multiplies(m);
	}

	//***********************************************************************************
	//**** apply to a point
	//***********************************************************************************
	transform_point(p) {
		var np = [0,0,0];
		var z = (p.length > 2)?p[2]:0;
		for (var k=0;k<3;k++)
			np[k] = this.values[k] * p[0] + this.values[k+4] * p[1] + this.values[k+8] * z + this.values[k+12];
		return np;
	}

	//***********************************************************************************
	//**** apply to a vector
	//***********************************************************************************
	transform_vector(p) {
		var z = (p.length > 2)?p[2]:0;
		var np = [0,0,0];
		for (var k=0;k<3;k++)
			np[k] = this.values[k] * p[0] + this.values[k+4] * p[1] + this.values[k+8] * z;
		return np;
	}

	reverse_transform_point(p)
	{
		var z = (p.length > 2)?p[2]:0;
		var np = [0,0,0];
		const pp = [p[0]-this.values[12],p[1]-this.values[13],z-this.values[14]];
		for (var k=0;k<3;k++)
			np[k] = this.values[k*4] * pp[0] + this.values[k*4+1] * pp[1] + this.values[k*4+2] * pp[2];
		return np;
	}
	
	reverse_transform_vector(p)
	{
		var z = (p.length > 2)?p[2]:0;
		var np = [0,0,0];
		for (var k=0;k<3;k++)
			np[k] = this.values[k*4] * p[0] + this.values[k*4+1] * p[1] + this.values[k*4+2] * z;
		return np;
	}
}
