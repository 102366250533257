<ng-template #template>
  <!-- Icône Angular Material -->
  <mat-icon *ngIf="!theme || theme == 'angular-material-native'" [ngClass]="class">
    <ng-container *ngIf="iconName">{{ iconName }}</ng-container>
    <ng-content *ngIf="!iconName"></ng-content>
  </mat-icon>
  <!-- Icône Font Awesome  -->
  <mat-icon *ngIf="theme == 'fa'" [ngClass]="class" [fontSet]="'fa'" [fontIcon]="'fa-' + iconName"></mat-icon>
  <!-- Icône Material Design -->
  <mat-icon *ngIf="theme == 'mdi'" class="mdi {{ 'mdi-' + iconName }}" [ngClass]="class"></mat-icon>
</ng-template>
