'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_selection  : A SVG tool to manipulate walls and openings
//***********************************************************************************
//***********************************************************************************

import { cn_balcony_type } from '../model/cn_balcony_type';
import { cn_fence_type } from '../model/cn_fence_type';
import { cn_space } from '../model/cn_space';
import { cn_vertex } from '../model/cn_vertex';
import { cn_wall } from '../model/cn_wall';
import { cn_wall_type } from '../model/cn_wall_type';
import { logger } from '../utils/cn_logger';
import { cn_add, cn_clone, cn_dist, cn_dot, cn_mul, cn_normalize, cn_sub } from '../utils/cn_utilities';
import { cn_edit_box } from './cn_edit_box';
import { cn_notification_input, cn_number_input, SEVERITY_NOTIFICATION_ERROR } from './cn_inputs';
import { cn_pastille } from './cn_pastille';
import { cn_snap } from './cn_snap';
import { cn_space_handler } from './cn_space_handler';
import { cn_svg_tool_edition } from './cn_svg_tool_edition';
import { cn_type_pastille } from './cn_type_pastille';

export const MIN_WALL_WIDTH = 0.1;

//***********************************************************************************
//**** Wall edition
//***********************************************************************************

const SPLIT_THRESHOLD = Math.sin(20 * Math.PI / 180);

export class cn_svg_tool_wall_edition extends cn_svg_tool_edition {
    constructor(map, filters = ['wall', 'balcony', 'fence']) {
        super(map);

        this._initial_filters = filters;
        this._filters = ['wall', 'balcony', 'fence'];

        this._mouse_position = [0, 0];

        this._selected_walls = [];
        this._selected_vertices = [];

        this._need_update_implied_selection = true;

        this._svg = '';

        this._drag_code = 0;
        this.first_drag = false;

        this._selected_space = null;
        this._mouseover_space = false;
        this._mouse_wall_measures = null;
        this._pending_changes = null;

        this._space_diagonals = false;

        this._edit_box = null;
    }

    //***********************************************************************************
    //**** Selection callback
    //***********************************************************************************
    on_selection_change() {
        this._mouseover_measure = null;

        this._edit_box = null;
        this._selected_walls = [];
        this._selected_vertices = [];
        this._mouseover_element = null;
        this._implied_vertices = [];
        this._implied_walls = [];
        this._implied_contours = [];
        this._mouse_wall_measures = null;
        this._selection_wall_measures = null;
        this._need_update_implied_selection = true;
        this._space_diagonals = false;

        //*** end if selection is not excluusively walls and vertices */
        var selection = this._controller.get_selection();
        if (selection.length == 0) return false;

        //*** selection made only of spaces ? */
        if (!selection.some(elt => elt.constructor != cn_space)) {
            this._edit_box = new cn_space_handler(selection, this.get_svg_map());
            this._edit_box.on('diagonals_mode', (v) => {
                this._space_diagonals = v;
                this._map.refresh();
            });
            return true;
        }

        if (selection.some(elt => elt.constructor != cn_wall && elt.constructor != cn_vertex))
            return false;

        var coherent = true;
        var wall_type_constructor = null;
        for (var i in selection) {
            if (selection[i].constructor == cn_wall) {
                if (this.check_filters(selection[i])) {
                    this._selected_walls.push(selection[i]);
                    if (wall_type_constructor == null)
                        wall_type_constructor = selection[i].wall_type.constructor;
                    else if (wall_type_constructor != selection[i].wall_type.constructor)
                        coherent = false;
                }
            } else if (selection[i].constructor == cn_vertex)
                this._selected_vertices.push(selection[i]);
        }
        this._mouseover_element = null;

        const removable_vertices = this._selected_vertices.filter(v => v.walls.length == 2);

        //*** Create edit box */
        if (this._selected_walls.length > 0) {
            const map = this.get_svg_map();
            this._edit_box = new cn_edit_box(this, this._selected_walls, this._map.get_readonly());

            if (!this._map.get_readonly()) {

                //*** axis pastille */
                const axis_pastille = new cn_pastille([0, 0], 'switch_wall_axis.png');
                this._edit_box.add_pastille(axis_pastille);
                axis_pastille.svg_class = 'pastille_background white';
                axis_pastille.title = 'Modifier l\'axe des murs';
                axis_pastille.clicked = () => {
                    this.set_selection_wall_axis((1 + this._selected_walls[0].axis) % 3);
                }

                //*** Type pastille */
                if (coherent) {
                    var transaction_name = (wall_type_constructor == cn_wall_type) ? 'Type de mur' : (wall_type_constructor == cn_balcony_type) ? 'Type de balcon' : 'Type de clôture';
                    const type_pastille = new cn_type_pastille(this._selected_walls, 'wall_type', () => {
                        if (wall_type_constructor == cn_wall_type)
                            return map._building.get_wall_types();
                        else if (wall_type_constructor == cn_balcony_type)
                            return map._building.get_balcony_types();
                        else if (wall_type_constructor == cn_fence_type)
                            return map._building.get_fence_types();
                    }, transaction_name, map);
                    type_pastille.title = 'Modifier le type de mur';
                    this._edit_box.add_pastille(type_pastille);

                    this._edit_box.add_lock_pastille(this._map._building.transaction_manager);

                }
            }

            this._edit_box.add_select_siblings_pastille('wall_type');
            this._edit_box.add_information_pastille('wall_type');

        } else if (removable_vertices.length > 0) {
            this._edit_box = new cn_edit_box(this, removable_vertices, this._map.get_readonly());
        }
        return true;
    }

    //***********************************************************************************
    //**** filters
    //***********************************************************************************
    check_filters(wall) {
        if (wall.balcony)
            return (this._filters.indexOf('balcony') >= 0);
        if (wall.fence)
            return (this._filters.indexOf('fence') >= 0);
        return (this._filters.indexOf('wall') >= 0);
    }

    //***********************************************************************************
    //**** Clear selection
    //***********************************************************************************
    clear_selection() {
    }

    //***********************************************************************************
    //**** Open / Close tool
    //***********************************************************************************
    open_tool() {
        super.open_tool();
        this.clear_selection();
    }

    close_tool() {
        super.close_tool();
        this.clear_selection();
        this._scene.update_deep();
    }

    /**
     * Sets space diaglonal mode
     * @param {boolean} v
     */
    set_space_diagonals(v) {
        this._space_diagonals = v;
    }

    /**
     * returns space diaglonal mode
     * @returns {boolean}
     */
    get_space_diagonals() {
        return this._space_diagonals;
    }

    //***********************************************************************************
    //**** Specific selection methods
    //***********************************************************************************
    //*** Returns selection
    get_selected_walls() {
        const selection = this._controller.get_selection();
        const sel = [];
        for (let i in selection) {
            if (selection[i].constructor === cn_wall) {
                sel.push(selection[i]);
            }
        }
        this._selected_walls = sel;
        return this._selected_walls;
    }

    //*** Returns selected vertices
    get_selected_vertices() {
        return this._selected_vertices;
    }

    //*** Returns all selected wall types
    get_selected_wall_types() {
        var wall_types = [];
        for (var i in this._selected_walls) {
            var ot = this._selected_walls[i].wall_type;
            if (wall_types.indexOf(ot) < 0)
                wall_types.push(ot);
        }
        return wall_types;
    }

    //*** Select all inner and / or outer walls
    select_walls(inner_walls, outer_walls) {
        this.clear_selection();
        this._selection = [];
        for (var i in this._scene.walls) {
            if (this._scene.walls[i].balcony) continue;
            if (this._scene.walls[i].fence) continue;
            if (inner_walls && outer_walls)
                this._selection.push(this._scene.walls[i]);
            else if (inner_walls) {
                if (this._scene.walls[i].spaces[0].has_roof && this._scene.walls[i].spaces[1].has_roof)
                    this._selection.push(this._scene.walls[i]);
            } else if (outer_walls) {
                if (this._scene.walls[i].spaces[0].has_roof != this._scene.walls[i].spaces[1].has_roof)
                    this._selection.push(this._scene.walls[i]);
            }
        }
        this.call('selection_change');
    }

    //*** Select all balconies
    select_balconies() {
        this.clear_selection();
        this._selection = [];
        for (var i in this._scene.walls) {
            if (!this._scene.walls[i].balcony) continue;
            this._selection.push(this._scene.walls[i]);
        }
        this.call('selection_change');
    }

    //*** Set wall types for selection
    set_selection_wall_type(wt) {
        var walls = this.get_selected_walls();
        if (walls.length == 0) return;

        var scene = this._scene;
        var balcony = (wt.constructor == cn_balcony_type);

        var transaction_name = (balcony) ? 'Modification du type de balcon' : 'Modification du type de mur';
        this.push_transaction(transaction_name, '', () => {
            scene.full_update();
        });

        for (var i in walls) {
            this.push_item_set(walls[i], ['balcony', 'wall_type']);
            walls[i].balcony = balcony;
            walls[i].wall_type = wt;
        }
        this._scene.update();
        this._scene.update_deep();
    }

    //*** return axis list for selection
    get_current_axis_list() {
        var axis_list = [];
        var walls = this.get_selected_walls();
        for (var i in walls) {
            var axis = walls[i].axis;
            if (axis_list.indexOf(axis) < 0)
                axis_list.push(axis);
        }

        return axis_list;
    }

    //*** Set wall axis for selection
    set_selection_wall_axis(axis) {
        var walls = this.get_selected_walls();
        if (walls.length == 0) return;

        var scene = this._scene;
        this.push_transaction('Modification de l\'axe des murs', '', () => {
            scene.full_update();
        });

        for (var i in walls) {
            this.push_item_set(walls[i], 'axis');
            walls[i].axis = axis;
        }
        this._scene.update();
        this._scene.update_deep();
    }

    //*** walled when some walls are updated
    update_walls() {
        this._scene.update();
        this._scene.update_deep();
    }

    //***********************************************************************************
    //**** Draws  specific svg for the tool. Returns svg string
    //***********************************************************************************
    draw(camera) {
        //*** What are the walls implied in selection due to vertex selection ?
        this.update_implied_selection();

        var html = '';

        //*** draw mouse wall measures */
        if (this._mouse_wall_measures) {
            for (var i in this._mouse_wall_measures.measures) {
                var mwm = this._mouse_wall_measures.measures[i];
                html += camera.draw_measure(mwm[0], mwm[1], null, false, 0);
            }
        }

        var selection = this._controller.get_selection();
        var selection_delegates = this._controller.get_selection_delegates();
        var can_translate = this.can_translate();

        //*** draw selection wall measures */
        if (this._selection_wall_measures && selection.indexOf(this._selection_wall_measures.wall) >= 0) {
            this.update_wall_measures(this._selection_wall_measures);
            for (var i in this._selection_wall_measures.measures) {
                var mwm = this._selection_wall_measures.measures[i];
                if (can_translate) {
                    var selected = (this._mouseover_selection_measure && this._mouseover_selection_measure.wall == this._selection_wall_measures.wall && this._mouseover_selection_measure.index == i);
                    html += camera.draw_measure(mwm[0], mwm[1], mwm[2], selected, 0);

                } else
                    html += camera.draw_measure(mwm[0], mwm[1], null, false, 0);
            }
        }

        //*** Draw measures of implied walls
        for (var i in this._implied_walls) {
            var w = this._implied_walls[i];
            if (w._flexible && can_translate) {
                var selected = (this._mouseover_measure && this._mouseover_measure.wall == w);
                if (typeof (w._selectable_measure) == 'undefined')
                    w._selectable_measure = [[0, 0], [0, 0]];
                html += camera.draw_measure(w.measure_points[0][0], w.measure_points[0][1], w._selectable_measure[0], selected && this._mouseover_measure.side == 0);
                html += camera.draw_measure(w.measure_points[1][0], w.measure_points[1][1], w._selectable_measure[1], selected && this._mouseover_measure.side == 1);
            } else {
                html += camera.draw_measure(w.measure_points[0][0], w.measure_points[0][1]);
                html += camera.draw_measure(w.measure_points[1][0], w.measure_points[1][1]);
            }
        }

        //*** Draw measures of selected spaces
        for (var i in selection) {
            if (selection[i].constructor != cn_space) continue;
            this._selected_space = selection[i];
            if (!this._selected_space.outside && this._space_diagonals) {
                html += selection[i].measure.draw(camera);
                html += this._selected_space.draw_main_door_selected_space(camera);
            }
        }


        //*** Draw angles of selected vertex
        //if (this._selected_vertices.length == 1 && this._selected_walls.length == 0 && this._selected_vertices[0].walls.length > 1)
        if (this._implied_vertices.length < 10) {
            this._implied_vertices.forEach(v => {
                html += v.draw_angles(camera);
            });
        }

        //*** draw vertex move */
        if (this.can_translate_vertex()) {
            var classes = 'move_arrow';
            if (this._dragged_element == selection[0] || this._mouseover_element == selection[0])
                classes += ' selected';

            html += camera.draw_move_arrow(selection[0].get_delegate_position(selection_delegates[0]), classes);
        }

        html += this._svg;

        if (this._edit_box) html += this._edit_box.draw(camera);
        return html;
    }

    //***********************************************************************************
    //**** update implied elements
    //***********************************************************************************
    update_implied_selection(force = false) {
        if (!force && !this._need_update_implied_selection) return;
        this._need_update_implied_selection = false;

        this._implied_vertices = [];
        this._implied_vertices = this._implied_vertices.concat(this._selected_vertices);

        for (var i in this._selected_walls) {
            var wall = this._selected_walls[i];
            if (!this._implied_vertices.includes(wall.vertices[0]))
                this._implied_vertices.push(wall.vertices[0]);
            if (!this._implied_vertices.includes(wall.vertices[1]))
                this._implied_vertices.push(wall.vertices[1]);
        }

        this._implied_walls = [];
        this._implied_contours = [];
        for (var i in this._implied_vertices) {
            var vertex = this._implied_vertices[i];
            for (var j in vertex.walls) {
                var wall = vertex.walls[j];
                if (!this._implied_walls.includes(wall)) {
                    this._implied_walls.push(wall);
                    wall['_flexible'] = !this._implied_vertices.includes(wall.other_vertex(vertex));
                }
                for (var side = 0; side < 2; side++) {
                    var contour = wall.contours[side];
                    if (!this._implied_contours.includes(contour))
                        this._implied_contours.push(contour);
                }
            }
        }

        this._flexible_vertices = [];
        for (var i in this._implied_vertices) {
            var vertex = this._implied_vertices[i];
            vertex._flexible_walls = [];
            vertex._fixed_walls = [];
            var flexible = [];
            for (var j in vertex.walls) {
                var wall = vertex.walls[j];
                if (this._implied_vertices.includes(wall.vertices[0]) && this._implied_vertices.includes(wall.vertices[1])) {
                    vertex._fixed_walls.push(wall);
                    continue;
                }
                if (vertex == wall.vertices[0])
                    flexible.push(cn_sub(wall.vertices[1].position, wall.vertices[0].position));
                else
                    flexible.push(cn_sub(wall.vertices[0].position, wall.vertices[1].position));
                vertex._flexible_walls.push(wall);
            }
            if (flexible.length == 0) continue;
            this._flexible_vertices.push(vertex);
            vertex._flexible_directions = flexible;
        }
    }

    //***********************************************************************************
    //**** update selection
    //***********************************************************************************
    update_selection(clear_selection) {

        //*** Clear selection if needed
        if (clear_selection) {
            this._selection = [];
            this._selected_vertices = [];
            this._selected_walls = [];
            this._selected_spaces = [];
            this._selected_openings = [];
        }
        this._need_update_implied_selection = true;

        //*** Add mouseover element to selection
        if (this._mouseover_element) {
            var index = this._selection.indexOf(this._mouseover_element);
            if (index >= 0)
                this._selection.splice(index, 1);
            else
                this._selection.push(this._mouseover_element);
            this._need_update_implied_selection = true;
            this._mouseover_element = null;
        }

        this.call('selection_change');
    }

    //***********************************************************************************
    //**** Measure click
    //***********************************************************************************

    _call_measure_click(v) {
        const obj = this;
        const input = new cn_number_input('Nouvelle longueur', v, 'm', 2, MIN_WALL_WIDTH);
        input.callback = () => {
            if (!this.set_current_measure(input.value)) {
                this.call('notification_input', new cn_notification_input('Opération impossible', SEVERITY_NOTIFICATION_ERROR));
            }
            this._map.refresh();
        }
        this.call('number_input', input);
    }

    set_current_measure(v1) {
        var scene = this._scene;

        if (this.can_translate()) {
            //*** Typical measure changed */
            if (this._mouseover_measure) {
                var wall = this._mouseover_measure.wall;
                var side = this._mouseover_measure.side;
                this._mouseover_measure = null;
                var v = cn_dist(wall.measure_points[side][0], wall.measure_points[side][1]);

                var direction = [0, 0];
                if (this._implied_vertices.includes(wall.vertices[0]))
                    direction = cn_sub(wall.vertices[0].position, wall.vertices[1].position);
                else
                    direction = cn_sub(wall.vertices[1].position, wall.vertices[0].position);

                cn_normalize(direction);

                if (this.can_translate_walls()) {
                    direction = cn_mul(direction, v1 - v);
                    var dd = this.get_selected_walls()[0].bounds.direction;
                    var ps = cn_dot(dd, direction);
                    direction = cn_sub(direction, cn_mul(dd, ps));
                    var rres = this.apply_wall_translation(direction, 0, 'Modification de longueur de mur');
                    scene.update();
                    scene.update_deep();
                    return rres;
                }

                var vertex = this._implied_vertices[0];
                var old_position = cn_clone(vertex.position);

                var multiplicator = 1;
                var res = false;
                for (var niter = 0; niter < 100; niter++) {
                    var dst = multiplicator * (v1 - v);
                    if (Math.abs(dst) < 0.0001) {
                        res = true;
                        break;
                    }
                    var delta = cn_mul(direction, dst);

                    //*** move implied vertices
                    vertex.position[0] += delta[0];
                    vertex.position[1] += delta[1];
                    scene.update();

                    //*** Check changes
                    if (!scene.check_changes(vertex.walls)) {
                        vertex.position[0] -= delta[0];
                        vertex.position[1] -= delta[1];
                        multiplicator *= 0.5;
                        continue;
                    }

                    multiplicator = 1;
                    v = cn_dist(wall.measure_points[side][0], wall.measure_points[side][1]);
                }
                var new_position = cn_clone(vertex.position);
                vertex.position[0] = old_position[0];
                vertex.position[1] = old_position[1];
                if (!res) return false;

                this.push_transaction('Modification de longueur de mur', '', () => {
                    scene.full_update();
                });

                this.push_item_set(vertex, 'position');
                vertex.position[0] = new_position[0];
                vertex.position[1] = new_position[1];

                scene.update();
                scene.update_deep();
                return true;
            }

            //*** wall measure clicked ?
            else if (this._mouseover_selection_measure) {
                // @ts-ignore
                var wall = this._mouseover_selection_measure.wall;
                var index = this._mouseover_selection_measure.index;
                if (this._selection_wall_measures && this._selection_wall_measures.wall == wall && this._selection_wall_measures.measures[index]) {
                    var direction = cn_sub(this._selection_wall_measures.measures[index][0], this._selection_wall_measures.measures[index][1]);
                    var dist = cn_normalize(direction);

                    var bres = this.apply_wall_translation(cn_mul(direction, v1 - dist), 0, 'Modification de longueur de mur');
                    scene.update();
                    scene.update_deep();
                    return bres;
                }
                return false;
            }
        }

        //*** space measure changed */
        if (this._selected_space && !this._selected_space.outside && this._space_diagonals && this._mouseover_space) {
            this._mouseover_space = false;

            //*** Save vertices positions */
            var vertices = this._selected_space.measure.get_implied_vertices();
            for (var k in vertices)
                vertices[k].old_position = cn_clone(vertices[k].position);

            //*** Apply new measure */
            var xres = this._selected_space.measure.set_measure(v1);

            //*** Check if changes are valid */
            if (xres) xres &= scene.check_changes(this._selected_space.measure.get_implied_walls());

            //*** If not, restore old position */
            if (!xres) {
                for (var k in vertices)
                    vertices[k].position = cn_clone(vertices[k].old_position);
                this._scene.update();
                return false;
            }

            //*** Manage undo redo */
            this.push_transaction('Dimensions de pièce', '', () => {
                scene.full_update();
            });
            for (var k in vertices) {
                var new_pos = cn_clone(vertices[k].position);
                vertices[k].position = cn_clone(vertices[k].old_position);
                this.push_item_set(vertices[k], 'position');
                vertices[k].position = new_pos;
            }
        }
        this._scene.update();
        this._scene.update_deep();
        return true;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************
    clear_move() {
        this._mouseover_measure = null;
        this._mouseover_selection_measure = null;
        this._mouseover_space = false;
        this._mouse_wall_measures = [];
        this._mouseover_element = null;
        if (this._edit_box) this._edit_box.clear_move();
    }

    click(ev) {
        var obj = this;
        this._svg = '';

        if (this._edit_box && this._edit_box.click(ev)) return true;

        this.update_mouseover(ev);

        if (this._map.get_readonly()) {
            return false;
        }

        //*** measure clicked ?
        if (this._mouseover_measure) {
            var wall = this._mouseover_measure.wall;
            var side = this._mouseover_measure.side;
            var v = cn_dist(wall.measure_points[side][0], wall.measure_points[side][1]);
            this._call_measure_click(v);
            return true;
        }

        //*** wall measure clicked ?
        if (this._mouseover_selection_measure) {
            //@ts-ignore
            var wall = this._mouseover_selection_measure.wall;
            var index = this._mouseover_selection_measure.index;
            if (this._selection_wall_measures && this._selection_wall_measures.wall == wall && this._selection_wall_measures.measures[index]) {
                var v = cn_dist(this._selection_wall_measures.measures[index][0], this._selection_wall_measures.measures[index][1]);
                this._call_measure_click(v);
                return true;
            }
        }

        //*** mouseover space clicked ?
        if (this._selected_space && !this._selected_space.outside && this._space_diagonals && this._mouseover_space) {
            if (this._selected_space.measure.diagonal_origin_selected()) {
                this.push_transaction(`Origine des diagonales d'une pièce`);
                this.push_item_set(this._selected_space.measure, 'diagonal_origin');
                this._selected_space.measure.click();
                return true;
            }

            var dist = this._selected_space.measure.click();
            if (typeof (dist) == 'number')
                this._call_measure_click(dist);
            return true;
        }

        this._selection_wall_measures = this._mouse_wall_measures;

        return false;
    }

    grab(ev) {

        if (this._edit_box && this._edit_box.grab(ev)) return true;

        //if (!this.move(ev)) return false;
        this.update_mouseover(ev);
        this._dragged_element = this._mouseover_element;
        if (this._dragged_element == null) return false;
        if (this._selected_vertices.indexOf(this._dragged_element) < 0 && this._selected_walls.indexOf(this._dragged_element) < 0) return false;
        this._mouse_position = cn_clone(ev.mouse_world);
        this._drag_code++;
        this.first_drag = true;
        return true;
    }

    drop(ev) {
        this._svg = '';
        if (this._edit_box && this._edit_box.drop(ev)) return true;

        this._dragged_element = null;
        //*** Manage end of drag
        this.finalize_pending_changes();

        return true;
    }

    move(ev) {
        this.clear_move();
        if (this._edit_box && this._edit_box.move(ev)) return true;
        return this.update_mouseover(ev);
    }

    drag(ev) {
        if (this._edit_box && this._edit_box.drag(ev)) return true;
        this.clear_move();
        var scene = this._scene;
        //this.push_transaction("Déplacement de murs",scene.ID + this._drag_code,() => {scene.update();scene.update_deep();});

        if (this._dragged_element == null) return false;

        this.drag_selection(ev);

        return true;
    }

    start_translation(ev) {
        if (this._initial_filters.indexOf('wall') < 0) return;
        this._mouse_position = cn_clone(ev.mouse_world);
        this._dragged_element = this._controller.find_element(ev.mouse_world, ev.camera.snap_world_distance);
    }

    translate(ev, offset) {
        if (this._initial_filters.indexOf('wall') < 0) return;
        this.drag_selection(ev);
    }

    finalize_translation(ev) {
        if (this._initial_filters.indexOf('wall') < 0) return;
        this._svg = '';
    }

    //***********************************************************************************
    //**** Finalize pending changes
    //***********************************************************************************
    finalize_pending_changes() {
        if (this._pending_changes === null) return;
        if (typeof (this._pending_changes) != 'object') {
            this._pending_changes = null;
            this._scene.unlock_delegates();
            this._scene.full_update();
            this._map.refresh();
            return;
        }

        var scene = this._scene;
        var obj = this;

        if (this._pending_changes['operation'] == 'merge_vertices') {
            const vertex = this._pending_changes['vertex'];
            const v1 = this._pending_changes['vertex_1'];
            const v = this._pending_changes['vertex'];
            this.push_item_set(v, ['position'], () => {
                if (scene.vertices.indexOf(v) >= 0)
                    scene.merge_vertices(v1, v);
                else
                    scene.unmerge_vertices(v1, v);

                scene.full_update();
            });

            var wall_delegate = (this._pending_changes['wall_delegate']) ? this._pending_changes['wall_delegate'] : null;
            scene.merge_vertices(v1, v);
            scene.full_update();
            this._controller.select_element(v1, true, wall_delegate);
            if (this._svg_parent)
                this._svg_parent._selection_change();
        }
        if (this._pending_changes['operation'] == 'split_wall') {
            //@ts-ignore
            function split_walls_with_undo(w, v) {

                var w1 = scene.split_wall(w, v);
                this.push_item_set(v, ['position'], () => {
                    if (scene.walls.indexOf(w1) >= 0)
                        scene.merge_wall(w, w1);
                    else
                        scene.split_wall(w, v, w1);

                    scene.full_update();
                });

                scene.full_update();
            }

            split_walls_with_undo(this._pending_changes['wall'], this._pending_changes['vertex']);
        }
        this._map.refresh();
        this._pending_changes = null;
    }

    //***********************************************************************************
    //**** Drag selection
    //***********************************************************************************
    drag_selection(ev) {
        var delta = cn_sub(ev.mouse_world, this._mouse_position);
        var actual_delta = cn_clone(delta);

        var scene = this._scene;
        this.update_implied_selection();

        //*** One single vertex selected : try to snap on existing walls
        var selection = this._controller.get_selection();
        if (selection.length == 1 && selection[0].constructor == cn_vertex) {
            var vertex = selection[0];

            if (vertex.walls.some(w => w.locked)) return false;

            //*** First drag : we create the transaction */
            if (this.first_drag) {
                this.first_drag = false;
                this.push_transaction('Déplacement de sommet', scene.ID + this._drag_code, () => {
                    scene.full_update();
                });

                var wall_delegate = this._controller.get_selection_delegates()[0];

                //*** if vertex is single */
                if (vertex.walls.length == 1) {
                    //*** the wall may end up with a delegate */
                    this.push_item_set(vertex.walls[0], 'delegates');
                }
                //*** if the moved vertex is a delegate, we detach the vertex */
                else if (wall_delegate) {
                    this.push_item_set(wall_delegate, ['delegates', 'vertices']);
                    this.push_item_set(scene, ['vertices'], () => {
                        scene.full_update(true);
                    });
                    const wid = wall_delegate.vertices.indexOf(vertex);
                    const new_vertex = new cn_vertex(wall_delegate.delegates[wid].position);
                    scene.vertices.push(new_vertex);
                    wall_delegate.delegates[wid] = null;
                    wall_delegate.vertices[wid] = new_vertex;
                    scene.full_update(true);
                    this._controller.select_element(new_vertex);
                    this.on_selection_change();
                    vertex = new_vertex;
                }
            }

            //*** Move the vertex */
            return this._move_vertex(ev, vertex);
        }

        //*** move walls
        else if (this.can_translate_walls()) {
            var x = cn_dot(actual_delta, this._dragged_element.bounds.normal);
            actual_delta = cn_mul(this._dragged_element.bounds.normal, x);

            if (this.apply_wall_translation(actual_delta, ev.camera.snap_world_distance, 'Déplacement de murs'))
                this._mouse_position = cn_add(this._mouse_position, actual_delta);
            this._pending_changes = true;
            return true;
        }

        return true;
    }

    //***********************************************************************************
    //**** Moves one  vertex
    //***********************************************************************************
    _move_vertex(ev, vertex) {
        var freedoms_before = 0;

        if (vertex.walls.length == 1)
            return this._move_single_vertex(ev, vertex);

        var snap = new cn_snap(ev.mouse_world, ev.camera.snap_world_distance, null, ev.camera);

        //*** Try to find a snap on existing vertices */
        for (var i in this._scene.vertices) {
            var vtx = this._scene.vertices[i];
            if (vtx == vertex) continue;

            if (!snap.check_point(vtx.position)) continue;

            var actual_delta = cn_sub(snap.position, vertex.position);
            if (!this.apply_vertex_translation(actual_delta, vtx.walls))
                return false;

            this._svg = snap.svg;
            this._mouse_position = cn_add(this._mouse_position, actual_delta);
            this._pending_changes = { operation: 'merge_vertices', vertex: vertex, vertex_1: vtx };
            logger.log('move point : snap on existing vertex');
            return true;
        }

        //*** Try to find a snap on existing walls */
        for (var i in this._scene.walls) {
            var wall = this._scene.walls[i];
            if (wall.vertices[0] == vertex) continue;
            if (wall.vertices[1] == vertex) continue;

            if (!snap.check_segment(wall.vertex_position(0), wall.vertex_position(1), true)) continue;
            if (snap.freedoms == 0 && !snap.center) return false;

            actual_delta = cn_sub(snap.position, vertex.position);
            if (!this.apply_vertex_translation(actual_delta, [wall]))
                return false;

            this._svg = snap.svg;
            this._mouse_position = cn_add(this._mouse_position, actual_delta);
            this._pending_changes = { operation: 'split_wall', wall: wall, vertex: vertex };
            logger.log('move point : snap on existing wall');
            return true;
        }

        //*** Snap on other vertices, to check if current vertex is alined with 2 other vertices */
        var freedoms_before = snap.freedoms;
        var other_vertex_positions = [];
        for (var i in vertex.walls)
            other_vertex_positions.push(vertex.walls[i].other_vertex_position(vertex));

        if (vertex.walls.length >= 2) {
            for (var ni = 0; ni < other_vertex_positions.length; ni++) {
                var v0 = other_vertex_positions[ni];
                for (var j = ni + 1; j < other_vertex_positions.length; j++) {
                    var v1 = other_vertex_positions[j];
                    if (snap.check_segment(v0, v1, true)) break;
                }
                if (snap.freedoms < 2) break;
            }
        }
        if (freedoms_before != snap.freedoms) {
            logger.log(`move point : snap on aligned vertices : ${freedoms_before} to ${snap.freedoms}`);
            freedoms_before = snap.freedoms;
        }

        //*** Snap angles with connected walls */
        var other_vertices = [];
        for (var i in vertex.walls)
            other_vertices.push(vertex.walls[i].other_vertex(vertex));

        var freedoms_before = snap.freedoms;
        for (var ni = 0; ni < other_vertices.length; ni++) {
            var vtx = other_vertices[ni];
            snap.previous_point = other_vertex_positions[ni];
            for (var j = 0; j < vtx.walls.length; j++) {
                var w = vtx.walls[j];
                if (this._implied_walls.indexOf(w) >= 0) continue;
                snap.check_angles(w.vertex_position(0), w.vertex_position(1), 45);
                if (snap.freedoms == 0) break;
            }
            if (snap.freedoms == 0) break;
        }
        if (freedoms_before != snap.freedoms) {
            logger.log(`move point : snap on angles : ${freedoms_before} to ${snap.freedoms}`);
            freedoms_before = snap.freedoms;
        }

        actual_delta = cn_sub(snap.position, vertex.position);
        this._svg = snap.svg;
        if (this.apply_vertex_translation(actual_delta))
            this._mouse_position = cn_add(this._mouse_position, actual_delta);
        this._pending_changes = true;
        return true;
    }

    //***********************************************************************************
    //**** Moves one single vertex
    //***********************************************************************************
    _move_single_vertex(ev, vertex) {

        var start_wall = vertex.walls[0];
        var wid = start_wall.vertices.indexOf(vertex);
        var snap = new cn_snap(ev.mouse_world, ev.camera.snap_world_distance, start_wall.vertex_position(1 - wid), ev.camera);

        for (var i in this._scene.walls) {
            const wall = this._scene.walls[i];
            if (start_wall == wall) continue;
            snap.check_wall_delegates(start_wall.axis, start_wall.wall_type.thickness, wall);
            snap.check_wall(wall, true);
        }

        const old_delegate = start_wall.delegates[wid];
        //const old_vertex = start_wall.vertices[wid];
        start_wall.delegates[wid] = snap.delegate;
        const actual_delta = cn_sub(snap.position, vertex.position);
        var avoided_walls = [];
        if (snap.vertex)
            avoided_walls = snap.vertex.walls;
        else if (snap.wall)
            avoided_walls = [snap.wall];
        const res = this.apply_vertex_translation(actual_delta, avoided_walls);
        //start_wall.vertices[wid] = old_vertex;
        //if (this._scene.vertices.indexOf(old_vertex) < 0) this._scene.vertices.push(old_vertex);

        if (!res) {
            start_wall.delegates[wid] = old_delegate;
            return false;
        }

        this._svg = snap.svg;
        this._mouse_position = cn_add(this._mouse_position, actual_delta);

        if (snap.vertex) {
            this._pending_changes = { operation: 'merge_vertices', vertex: vertex, vertex_1: snap.vertex, wall_delegate: (snap.delegate) ? start_wall : null };
        } else if (snap.wall) {
            this._pending_changes = { operation: 'split_wall', vertex: vertex, wall: snap.wall };
        } else
            this._pending_changes = true;

        return true;
    }

    //***********************************************************************************
    //**** Update mouseover elements
    //***********************************************************************************
    update_mouseover(ev) {
        this.clear_move();

        this._mouse_position = cn_clone(ev.mouse_world);

        //*** mouseover priority on selected vertex */
        var can_translate_vertex = this.can_translate_vertex();
        if (can_translate_vertex) {
            var vtx = this._controller.get_selection()[0];
            if (vtx.contains(ev.mouse_world, ev.camera.snap_world_distance * 3)) {
                this._mouseover_element = vtx;
                return true;
            }
        }

        this._mouseover_element = this._controller.find_element(ev.mouse_world, ev.camera.snap_world_distance);

        //*** orthogonal measure from wall  */
        if (this._mouseover_element && this._mouseover_element.constructor == cn_wall) {
            this._mouse_wall_measures = {};
            this._mouse_wall_measures.wall = this._mouseover_element;
            this._mouse_wall_measures.point = cn_clone(ev.mouse_world);
            this.update_wall_measures(this._mouse_wall_measures);
        }

        //*** mouseover priority on selected wall */
        var can_translate_walls = this.can_translate_walls();
        if (can_translate_walls) {
            // @ts-ignore
            var wall = (this._mouseover_element && this._mouseover_element.constructor == cn_wall) ? this._mouseover_element : null;
            if (wall && this._controller.get_selection().indexOf(wall) >= 0) {
                return true;
            }
        }

        //** If translation is allowed, mouse over a translation measure ? */
        if (can_translate_walls || can_translate_vertex) {
            //*** Maybe mouse over a measure ?
            for (var i in this._implied_walls) {
                //@ts-ignore
                var wall = this._implied_walls[i];
                if (!wall['_flexible']) continue;
                for (var side = 0; side < 2; side++) {
                    if (Math.abs(wall['_selectable_measure'][side][0] - ev.mouse_screen[0]) > 30) continue;
                    if (Math.abs(wall['_selectable_measure'][side][1] - ev.mouse_screen[1]) > 10) continue;
                    this._mouseover_measure = { 'wall': wall, 'side': side };
                    return true;
                }
            }
            //*** maybe mouse over a selection */
            if (this._selection_wall_measures) {
                for (var i in this._selection_wall_measures.measures) {
                    var mwm = this._selection_wall_measures.measures[i];
                    if (Math.abs(mwm[2][0] - ev.mouse_screen[0]) > 30) continue;
                    if (Math.abs(mwm[2][1] - ev.mouse_screen[1]) > 10) continue;
                    this._mouseover_selection_measure = { wall: this._selection_wall_measures.wall, index: i };
                    return true;
                }
            }
        }

        //*** maybe mouse over space measure ? */
        if (this._selected_space && !this._selected_space.outside && this._space_diagonals)
            this._mouseover_space = this._selected_space.measure.is_mouseover(ev.mouse_world, ev.mouse_screen, ev.camera);

        return this._mouseover_space;
    }

    //***********************************************************************************
    //**** Compute wall measures
    //***********************************************************************************

    update_wall_measures(wm) {
        wm.measures = [];
        var wall = wm.wall;
        var position = cn_dot(wall.bounds.direction, cn_sub(wm.point, wall.vertices[0].position));
        var p = cn_add(wall.vertices[0].position, cn_mul(wall.bounds.direction, position));
        for (var niter = 0; niter < 2; niter++) {
            var direction = (niter == 0) ? cn_mul(wall.bounds.normal, -1) : wall.bounds.normal;
            var xx = (niter == 0) ? wall.bounds.y0 - 0.001 : wall.bounds.y1 + 0.001;
            var origin = cn_add(p, cn_mul(wall.bounds.normal, xx));
            var impact = wall.spaces[niter].raytrace(origin, direction, 1000, true);
            if (impact == null) continue;
            xx = (niter == 0) ? wall.bounds.y0 : wall.bounds.y1;
            origin = cn_add(p, cn_mul(wall.bounds.normal, xx));
            wm.measures.push([origin, impact.point, [0, 0]]);
        }
    }

    //***********************************************************************************
    //**** Apply translation to current selection
    //***********************************************************************************
    apply_raw_translation(actual_delta, avoided_walls = []) {

        //*** move implied vertices
        for (var ni = 0; ni < this._implied_vertices.length; ni++) {
            var vertex = this._implied_vertices[ni];
            this.push_item_set(vertex, 'position');
            vertex.old_position = [vertex.position[0], vertex.position[1]];

            vertex.position[0] += actual_delta[0];
            vertex.position[1] += actual_delta[1];
        }

        this._scene.update();

        //*** Check changes
        if (this._scene.check_changes(this._implied_walls, avoided_walls))
            return true;

        //*** restore vertices
        for (var i in this._implied_vertices) {
            var vertex = this._implied_vertices[i];
            vertex.position[0] = vertex.old_position[0];
            vertex.position[1] = vertex.old_position[1];
        }
        this._scene.update();
        return false;
    }

    //***********************************************************************************
    /**
     * Returns true if translation is allowed with given selection
     * @returns {boolean}
     */
    can_translate() {
        if (this.can_translate_vertex()) return true;
        if (this.can_translate_walls()) return true;
        return false;
    }

    //***********************************************************************************
    /**
     * Returns true if translation is allowed with given selection
     * @returns {cn_vertex} returns selected vertex
     */
    can_translate_vertex() {
        var selection = this._controller.get_selection();
        if (selection.length != 1) return null;
        if (selection[0].constructor != cn_vertex) return null;
        if (selection[0].walls.some(w => w.locked)) return null;
        return selection[0];
    }

    //***********************************************************************************
    /**
     * Returns true if translation is allowed with given selection
     * @returns {boolean}
     */
    can_translate_walls() {
        if (this._implied_walls.some(w => w.locked)) return false;

        var selected_walls = this.get_selected_walls();
        if (selected_walls.length == 0) return false;
        for (var i in this._scene.walls) this._scene.walls[i].selected = false;
        for (var i in selected_walls) selected_walls[i].selected = true;

        for (var ni = 0; ni < this._implied_vertices.length; ni++) {
            var vertex = this._implied_vertices[ni];
            var single_direction = true;
            var direction = null;
            var non_selected_walls = false;
            for (var nw in vertex.walls) {
                if (!vertex.walls[nw].selected) {
                    non_selected_walls = true;
                    continue;
                }
                if (direction == null)
                    direction = cn_clone(vertex.walls[nw].bounds.direction);
                else if (Math.abs(cn_dot(direction, vertex.walls[nw].bounds.direction)) < 0.99)
                    single_direction = false;
            }
            if (non_selected_walls && !single_direction) return false;
            vertex.non_selected_walls = non_selected_walls;
        }
        return true;
    }

    //***********************************************************************************
    //**** Apply translation to current selection
    //***********************************************************************************
    apply_wall_translation(actual_delta, snap_distance, transaction_name, display_log = false) {

        if (!this.can_translate_walls()) return false;

        var normalized_delta = cn_clone(actual_delta);
        var delta_size = cn_normalize(normalized_delta);
        var max_distance = delta_size + snap_distance;

        //*** First step : we compute max translation distance, without changing the certex structure */
        var snap_distances = [];
        for (var i = 0; i < this._implied_vertices.length; i++) {
            //*** we filter the vertices that should move */
            var vertex = this._implied_vertices[i];
            if (!vertex.non_selected_walls) continue;

            /** the vertex will slide in potentially 2 directions :
             * - sliding direction : along a wall, reducing the wall size
             * - second sliding direction : along a wall, extending the wall
             */
            vertex.sliding_wall = null;
            vertex.second_sliding_wall = null;
            var best_direction = SPLIT_THRESHOLD;
            var best_second_direction = -SPLIT_THRESHOLD;
            for (var nw in vertex.walls) {
                //*** we care only for the walls that will move */
                if (!vertex.walls[nw].selected) continue;
                var wdir = vertex.walls[nw].direction_from(vertex);
                for (var niter = 0; niter < 2; niter++) {
                    var w = (niter == 0) ? vertex.next_wall(vertex.walls[nw]) : vertex.previous_wall(vertex.walls[nw]);
                    if (w.selected) continue;
                    var dir = w.direction_from(vertex);
                    var dot = cn_dot(dir, normalized_delta);

                    //*** we slide by extending a wall */
                    if (dot < 0) {
                        if (dot < best_second_direction) {
                            vertex.second_sliding_wall = w;
                            best_second_direction = dot;
                        }
                        continue;
                    }

                    //*** we are blocked by a wall, not orthogonal enough to the sliding direction */
                    if (dot < SPLIT_THRESHOLD) {
                        if (cn_dot(dir, wdir) > 0) {
                            logger.log('slide prohibited ! ');
                            return false;
                        }
                        continue;
                    }

                    //*** we have then identified the one wall selected walls will slide on */
                    if (dot > best_direction) {
                        vertex.sliding_wall = w;
                        best_direction = dot;
                    }

                    //*** the end of this wall is a snap step, wether it's the sliding wall or not. */
                    var dist = dot * w.bounds.length;
                    if (dist < max_distance) max_distance = dist;
                    if (Math.abs(delta_size - dist) < snap_distance)
                        snap_distances.push(dist);
                }
            }
        }

        //*** Adjust delta */
        var original_delta = delta_size;
        var closest = snap_distance + 1;
        var new_delta = delta_size;
        for (var ki in snap_distances) {
            if (snap_distances[ki] >= max_distance) continue;
            var x = Math.abs(delta_size - snap_distances[ki]);
            if (x >= closest) continue;
            closest = x;
            new_delta = snap_distances[ki];
        }
        delta_size = new_delta;
        if (delta_size > max_distance) delta_size = max_distance;

        logger.log('distances', original_delta, max_distance, snap_distances, delta_size);
        if (delta_size < 0.001) return false;

        var delta = cn_mul(normalized_delta, delta_size);

        //*** prepare changes inside a transaction */
        var scene = this._scene;
        var previous_name = this._map._building.transaction_manager.get_current_transaction_name();
        var previous_code = this._map._building.transaction_manager.get_current_transaction_code();
        var new_name = transaction_name;
        var new_code = '' + this._drag_code;
        this.push_transaction(transaction_name, '', () => {
            scene.full_update();
        });
        var structural_change = false;

        //*** apply transformation */
        var vertices_to_adjust = [];
        for (var i = 0; i < this._implied_vertices.length; i++) {
            var vertex = this._implied_vertices[i];
            this.push_item_set(vertex, 'position');

            for (var j in vertex.walls) {
                this.push_item_set(vertex.walls[j], 'delegates');
            }

            //*** Simple case : all walls on the vertex are selected. */
            if (!vertex.non_selected_walls) {
                vertex.position[0] += delta[0];
                vertex.position[1] += delta[1];
                vertex.visit_delegates(delegate => {
                    delegate.position[0] += delta[0];
                    delegate.position[1] += delta[1];
                });
                continue;
            }

            //*** identify walls that need to keep the old vertex */
            var sw = vertex.sliding_wall;
            if (!sw) sw = vertex.second_sliding_wall;
            var left_walls = [];
            var non_sliding_walls = [];
            for (var j in vertex.walls) {
                if (vertex.walls[j].selected) continue;
                if (vertex.walls[j] == vertex.sliding_wall) continue;
                non_sliding_walls.push(vertex.walls[j]);
                if (sw && Math.abs(cn_dot(sw.bounds.direction, vertex.walls[j].bounds.direction)) > 0.999) continue;
                left_walls.push(vertex.walls[j]);
            }

            //*** vertex offset */
            var vertex_offset = (sw) ? cn_mul(sw.bounds.direction, delta_size / cn_dot(normalized_delta, sw.bounds.direction)) : delta;

            var old_position = cn_clone(vertex.position);
            vertex.position[0] += vertex_offset[0];
            vertex.position[1] += vertex_offset[1];

            var new_vertex = null;
            if (left_walls.length > 0) {
                new_vertex = new cn_vertex(old_position);
                new_vertex.walls = non_sliding_walls;
                var inspiration_wall = null;
                vertex.walls.forEach(w => {
                    if (w.selected || w == vertex.sliding_wall || w == vertex.non_sliding_wall) {
                        if (inspiration_wall == null || inspiration_wall.wall_type.thickness < w.wall_type.thickness)
                            inspiration_wall = w;
                    }
                });

                // @ts-ignore
                this._scene.split_vertex(vertex, new_vertex, inspiration_wall.wall_type, this._map._building.transaction_manager);
                structural_change = true;
            }

            vertex.visit_delegates(delegate => {
                delegate.position[0] += vertex_offset[0];
                delegate.position[1] += vertex_offset[1];
            });
            vertices_to_adjust.push(vertex);
            if (new_vertex) vertices_to_adjust.push(new_vertex);
        }

        //*** maybe some walls need to reduced to one vertex */
        for (var ki in this._scene.walls) {
            var w = this._scene.walls[ki];
            if (cn_dist(w.vertices[0].position, w.vertices[1].position) < 0.001) {
                this._scene.merge_vertices(w.vertices[0], w.vertices[1], this._map._building.transaction_manager);
                structural_change = true;
            }
        }

        vertices_to_adjust.forEach(vertex => {
            if (this._scene.vertices.indexOf(vertex) >= 0)
                vertex.adjust_to_delegates();
        });

        //*** check if changes are coherent */
        logger.log('Checking changes');
        if (!this._scene.check_vertices() || !this._scene.check_walls()) {
            logger.log('Changes not accepted');
            this._map._building.transaction_manager.perform_undo();
            this._map._building.transaction_manager.clear_future_transactions();
            return false;
        }
        logger.log('Changes accepted');

        //*** special case if structural changes : need to rebuild spaces */
        if (structural_change) {
            this._scene.full_update();
            this.update_implied_selection(true);
        }

        if (previous_name == new_name && previous_code == new_code)
            this._map._building.transaction_manager.merge_current_transaction();
        this._map._building.transaction_manager.set_current_transaction_code(new_code);

        this._map._building.transaction_manager.set_current_transaction_callback(() => {
            scene.full_update();
        });

        this._pending_changes = true;
        return delta;
    }

    //***********************************************************************************
    //**** Apply translation to current selection
    //***********************************************************************************
    apply_vertex_translation(actual_delta, avoided_walls = []) {

        //*** move implied vertices
        var del_list = [];
        var del_positions = [];
        for (var i in this._implied_vertices) {
            var vertex = this._implied_vertices[i];
            this.push_item_set(vertex, 'position');
            vertex.old_position = [vertex.position[0], vertex.position[1]];
            vertex.position[0] += actual_delta[0];
            vertex.position[1] += actual_delta[1];
            vertex.visit_delegates(del => {
                del_list.push(del);
                del_positions.push(cn_clone(del.position));
                del.position[0] += actual_delta[0];
                del.position[1] += actual_delta[1];
            });
        }
        this._scene.update();

        //*** Check changes
        if (this._scene.check_changes(this._implied_walls, avoided_walls, true))
            return true;

        //*** restore vertices
        for (var i in this._implied_vertices) {
            var vertex = this._implied_vertices[i];
            vertex.position[0] = vertex.old_position[0];
            vertex.position[1] = vertex.old_position[1];
        }
        for (var i in del_list)
            del_list[i].position = del_positions[i];

        this._scene.update();
        return false;
    }

    //***********************************************************************************
    //**** Apply translation to current selection
    //***********************************************************************************
    apply_translation(actual_delta, avoided_walls = []) {

        //*** move implied vertices
        var selected_walls = this.get_selected_walls();
        var normalized_delta = cn_clone(actual_delta);
        var delta_size = cn_normalize(normalized_delta);

        var new_vertices = [];
        for (var i = 0; i < this._implied_vertices.length; i++) {
            var vertex = this._implied_vertices[i];
            this.push_item_set(vertex, 'position');
            vertex.old_position = [vertex.position[0], vertex.position[1]];

            var direction = null;
            var single_direction = true;
            var buid_new_vertex = true;
            for (var nw in vertex.walls) {
                if (selected_walls.indexOf(vertex.walls[nw]) >= 0) continue;
                var wall_dir = vertex.walls[nw].bounds.direction;
                if (vertex.walls[nw].vertices[1] == vertex) wall_dir = cn_mul(wall_dir, -1);
                if (cn_dot(wall_dir, normalized_delta) > 0.3) buid_new_vertex = false;

                if (direction == null) {
                    direction = wall_dir;
                    continue;
                }
                if (single_direction && Math.abs(cn_dot(direction, wall_dir)) >= 0.99)
                    continue;

                single_direction = false;
                if (!buid_new_vertex) break;
            }
            if (single_direction && direction && Math.abs(cn_dot(direction, normalized_delta)) > 0.1) {
                var d = cn_mul(direction, delta_size / (cn_dot(direction, normalized_delta)));
                vertex.position[0] += d[0];
                vertex.position[1] += d[1];
                continue;
            } else if (single_direction && direction == null) {
                vertex.position[0] += actual_delta[0];
                vertex.position[1] += actual_delta[1];
                continue;
            } else if (buid_new_vertex) {
                var vtx = new cn_vertex(cn_add(vertex.position, actual_delta));
                vtx['referent'] = vertex;
                new_vertices.push(vtx);
                continue;
            }

            for (var j = 0; j <= i; j++) {
                var vertex = this._implied_vertices[j];
                vertex.position[0] = vertex.old_position[0];
                vertex.position[1] = vertex.old_position[1];
            }
            return false;
        }

        for (var ki in new_vertices) {
            var new_vertex = new_vertices[ki];
            var vertex = new_vertex['referent'];
            var new_walls = [];
            for (var nw in vertex.walls) {
                if (selected_walls.indexOf(vertex.walls[nw]) >= 0)
                    new_walls.push(vertex.walls[nw]);
            }

            this._scene.split_vertex(vertex, new_vertex);
        }
        this.update_implied_selection(true);
        this._scene.update();

        //*** Check changes
        if (this._scene.check_changes(this._implied_walls))
            return true;

        //*** restore vertices
        for (var ki in this._implied_vertices) {
            var vertex = this._implied_vertices[ki];
            vertex.position[0] = vertex.old_position[0];
            vertex.position[1] = vertex.old_position[1];
        }
        this._scene.update();
        return false;
    }

    //***********************************************************************************
    //**** remove selection
    //***********************************************************************************
    remove_selection() {
        var first_time = true;
        var scene = this._scene;

        //*** then remove walls
        for (var i in this._selection) {
            if (this._selection[i].constructor != cn_wall) continue;
            var wall = this._selection[i];
            if (first_time) {
                const transaction_name = (wall.balcony) ? 'Suppression de balcon' : 'Suppression de mur';
                this.push_transaction('Suppression de mur', '', () => {
                    scene.full_update();
                });
                first_time = false;
            }

            this.push_item_set(wall, [], w => {
                if (scene.walls.indexOf(w) >= 0)
                    scene.remove_wall(w);
                else
                    scene.insert_wall(w);
            });
            this._scene.remove_wall(wall);
        }
        scene.full_update();
        this._selection = [];
        this._mouseover_element = null;
        this.clear_selection();
        this.call('selection_change');
    }
}

