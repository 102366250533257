'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_vertex : a vertex to draw walls
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { CN_INNER, cn_wall, CN_MIDDLE, CN_OUTER } from './cn_wall';
import { cn_camera } from '../svg/cn_camera';
import {
    cn_clone,
    cn_mul,
    cn_polar,
    cn_sub,
    cn_dist,
    cn_cart,
    cn_normalize,
    DATATION,
    cn_dot,
    cn_normal,
    cn_add,
    cn_compute_intersection_position,
    cn_box
} from '../utils/cn_utilities';
import { logger } from '../utils/cn_logger';

export class cn_sector {
    constructor(w) {
        this.wall = w;
        this.angle = 0;
    }
}

function sector_by_angle(s0, s1) {
    if (s0.angle < s1.angle) return -1;
    return 1;
}

export class cn_vertex extends cn_element {
    constructor(p, is_3d = false) {
        super();
        this.position = [p[0], p[1]];
        if (is_3d) {
            if (p.length >= 3) this.position.push(p[2]);
            else this.position.push(0);
        }
        this.walls = [];
        this.angles = [];
        this.delegates = [];
        this.date = DATATION;
        this.draw_priority = 10;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        return [...this.position];
    }

    static unserialize(json, scene) {
        if (typeof (json) != 'object') return false;
        var vertex = new cn_vertex(json, json.length === 3);
        scene.vertices.push(vertex);
        vertex.parent = scene;
        return vertex;
    }

    //***********************************************************************************
    //**** Draw the vertex in svg
    //***********************************************************************************
    draw(camera, add_classes, mouseover_delegate = null, selection_delegate = null) {
        var html = '';
        var p = camera.world_to_screen(this.position);
        var draw_class = 'vertex';
        if (this.walls.some(w => w.locked)) draw_class += ' locked';
        var radius = 3;
        var is_selected = false;
        var is_mouseover = false;
        if (add_classes && add_classes.length) {
            add_classes.forEach(ac => {
                if (ac == 'selected')
                    is_selected = true;
                else if (ac == 'mouseover')
                    is_mouseover = true;
                else
                    draw_class += ' ' + ac;
            });
        }

        //** draw vertex */
        var dc = draw_class;
        var radius = 3;
        if (is_selected && selection_delegate == null) {
            dc += ' selected';
            radius = 5;
        }
        if (is_mouseover && mouseover_delegate == null) {
            dc += ' mouseover';
            radius = 5;
        }
        html += '<circle class=\'' + dc + '\' cx=\'' + p[0] + '\' cy=\'' + p[1] + '\' r=\'' + radius + '\' />';

        //*** draw delegates */
        if (this.delegates.length > 0) {
            this.walls.forEach(w => {
                var index = w.vertices.indexOf(this);
                if (w.delegates[index]) {
                    p = camera.world_to_screen(w.delegates[index].position);
                    dc = draw_class;
                    radius = 3;
                    if (is_selected && selection_delegate == w) {
                        dc += ' selected';
                        radius = 5;
                    }
                    if (is_mouseover && mouseover_delegate == w) {
                        dc += ' mouseover';
                        radius = 5;
                    }
                    html += '<circle class=\'' + dc + '\' cx=\'' + p[0] + '\' cy=\'' + p[1] + '\' r=\'' + radius + '\' />';
                }
            });
        }
        return html;
    }

    //******************************************************
    /**
     * Draws angles of the vertex
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw_angles(camera) {

        var sectors = [];

        //*** compute sector angles
        for (var i in this.walls) {
            var w = this.walls[i];
            var sector = new cn_sector(w);
            var direction = cn_sub(w.vertex_position(0), w.vertex_position(1));
            if (w.vertices[0] != this) direction = cn_mul(direction, -1);
            var pol = cn_polar(direction);
            sector.angle = pol[1];
            sectors.push(sector);
        }

        //*** sort sectors by angle
        sectors.sort(sector_by_angle);
        const l = sectors.length;

        var html = '';
        for (var ni = 0; ni < l; ni++) {
            var a0 = sectors[ni].angle;
            var a1 = sectors[(ni + 1) % l].angle;
            html += camera.draw_svg_angle(this.position, cn_cart([1, a0]), cn_cart([1, a1]));
        }
        return html;
    }

    //******************************************************
    //*** update sectors
    //******************************************************
    update() {
        var sectors = [];

        //*** compute sector angles
        for (var i in this.walls) {
            var w = this.walls[i];
            var sector = new cn_sector(w);
            //*** for direction, we use actual vertex positions, and not delegates, for stability questions */
            var direction = cn_sub(w.vertices[0].position, w.vertices[1].position);
            if (w.vertices[0] != this) direction = cn_mul(direction, -1);
            var pol = cn_polar(direction);
            sector.angle = pol[1];
            sectors.push(sector);
        }

        //*** sort sectors by angle
        sectors.sort(sector_by_angle);

        this.walls = [];
        this.angles = [];
        for (var i in sectors) {
            this.walls.push(sectors[i].wall);
            this.angles.push(sectors[i].angle);
        }

        this.removable = (this.walls.length == 2);
    }

    //******************************************************
    //*** get next wall
    //******************************************************
    next_wall(wall, avoid_balcony = false, avoid_delegate = false) {
        if (this.walls.length == 1) return wall;
        var index = this.walls.indexOf(wall);
        if (index < 0) return null;
        for (var niter = 0; niter < this.walls.length - 1; niter++) {
            index++;
            if (index >= this.walls.length) index = 0;
            if (avoid_balcony && wall.balcony != this.walls[index].balcony) continue;
            if (avoid_delegate) {
                const di = this.walls[index].vertices.indexOf(this);
                if (this.walls[index].delegates[di]) continue;
            }
            return this.walls[index];
        }
        return wall;
    }

    previous_wall(wall, avoid_balcony = false, avoid_delegate = false) {
        if (this.walls.length == 1) return wall;
        var index = this.walls.indexOf(wall);
        if (index < 0) return null;
        for (var niter = 0; niter < this.walls.length - 1; niter++) {
            index--;
            if (index < 0) index = this.walls.length - 1;
            if (avoid_balcony && wall.balcony != this.walls[index].balcony) continue;
            if (avoid_delegate) {
                const di = this.walls[index].vertices.indexOf(this);
                if (this.walls[index].delegates[di]) continue;
            }
            return this.walls[index];
        }
        return wall;
    }

    //******************************************************
    //*** get wall to
    //******************************************************
    wall_to(vertex) {
        for (var i in this.walls) {
            if (this.walls[i].vertices[0] == this && this.walls[i].vertices[1] == vertex)
                return this.walls[i];
            if (this.walls[i].vertices[1] == this && this.walls[i].vertices[0] == vertex)
                return this.walls[i];
        }
        return null;
    }

    //******************************************************
    //*** Compute angle from wall to next
    //******************************************************
    angle_between(wall0, wall1) {
        var i0 = this.walls.indexOf(wall0);
        if (i0 < 0) return 0;
        var i1 = this.walls.indexOf(wall1);
        if (i1 < 0) return 0;
        var angle = this.angles[i1] - this.angles[i0];
        while (angle < 0) angle += 2 * Math.PI;
        return angle;
    }

    /**
     * Returns true if vertex (or one delegate of the vertex) is close to input point.
     * @param {number[]} point
     * @param {number} tolerance
     * @returns {boolean}
     */
    contains(point, tolerance) {
        if (cn_dist(this.position, point) <= tolerance)
            return true;
        for (var nd = 0; nd < this.delegates.length; nd++) {
            if (cn_dist(this.delegates[nd], point) <= tolerance)
                return true;
        }
        return false;
    }

    /**
     * returns wall containing the delegate closest to position, or null
     * @param {number[]} position
     * @return {cn_wall}
     */
    find_delegate_wall(position) {
        if (this.delegates.length == 0) return null;
        var best_dist = cn_dist(position, this.position);
        var best_delegate = null;
        for (var i in this.walls) {
            var index = this.walls[i].vertices.indexOf(this);
            if (this.walls[i].delegates[index]) {
                const dist = cn_dist(position, this.walls[i].delegates[index].position);
                if (dist < best_dist) {
                    best_dist = dist;
                    best_delegate = this.walls[i];
                }
            }
        }
        return best_delegate;
    }

    /**
     * Returns the position of the vertex, as beared by given wall (use delegates)
     * @param {cn_wall} wall
     * @returns {number[]}
     */
    get_delegate_position(wall) {
        if (wall == null) return this.position;
        var index = wall.vertices.indexOf(this);
        if (index < 0 || wall.delegates[index] == null) return this.position;
        return wall.delegates[index].position;
    }

    /**
     * Visit delegates of the vertex. The visitor is a function that will be called with :
     * - the cn_wall_delegate
     * - the wall it belongs to
     * - the index of the vertex in the wall
     * @param {function} visitor
     */
    visit_delegates(visitor) {
        this.walls.forEach(w => {
            const wid = (w.vertices[0] == this) ? 0 : 1;
            const del = (w.vertices[0] == this) ? w.delegates[0] : w.delegates[1];
            if (w.delegates[wid]) visitor(w.delegates[wid], w, wid);
        });
    }

    /** move vertex position so that it optimizes the positions of the delegates */
    adjust_to_delegates() {
        var best_dir = null;
        var min_dist = 0;
        var max_dist = 1;
        var delegates = [];
        var sliding_walls = [];
        var impossible = false;
        this.walls.forEach(w => {
            const wid = w.vertices.indexOf(this);
            const dir = cn_sub(w.vertex_position(wid), w.vertex_position(1 - wid));
            if (w.delegates[wid]) {
                delegates.push(w);
            } else {
                const dist = cn_normalize(dir);
                if (best_dir == null) {
                    best_dir = dir;
                    min_dist = (dist > 0.1) ? 0.1 - dist : 0;
                    sliding_walls.push(w);
                } else if (cn_dist(dir, best_dir) < -0.999) {
                    max_dist = (dist > 0.1) ? dist - 0.1 : 0;
                    sliding_walls.push(w);
                } else impossible = true;
            }
        });
        if (impossible || best_dir == null || delegates.length == 0 || min_dist >= max_dist) return false;

        //*** if vertex is on two aligned walls */
        if (sliding_walls.length > 1) {
            if (sliding_walls.length != 2) return false;

            var x_accum = 0;
            delegates.forEach(w => {
                const wid = w.vertices.indexOf(this);
                x_accum += cn_dot(best_dir, cn_sub(w.vertex_position(wid), this.position));
            });
            x_accum /= delegates.length;
            logger.log('aligned !!! ', x_accum, max_dist, min_dist);
            if (x_accum > max_dist) x_accum = max_dist;
            if (x_accum < min_dist) x_accum = min_dist;
            this.position = cn_add(this.position, cn_mul(best_dir, xmin));
            return true;
        }

        //***  if vertex is at the end of one wall */
        var xmin = 0;
        delegates.forEach(w => {
            const wid = w.vertices.indexOf(this);
            w.build_self();
            if (Math.abs(cn_dot(best_dir, w.bounds.direction)) < 0.9) {
                for (var k = 0; k < 2; k++) {
                    const x = cn_dot(best_dir, cn_sub(w.shape[2 * wid + k], this.position));
                    logger.log('x : ', xmin);
                    if (x > xmin) xmin = x;
                }
            }
        });
        if (xmin > max_dist) xmin = max_dist;
        logger.log('adjust : ', xmin);
        this.position = cn_add(this.position, cn_mul(best_dir, xmin));
        return true;
    }

    //******************************************************
    /**
     * Returns an object containing basic vertex element on walls
     * @returns {object}
     */
    display_walls() {
        var obj = {};
        obj.position = cn_clone(this.position);
        obj.position.push(this.ID);
        for (var i in this.walls) {
            var w = this.walls[i];
            var vtx0 = cn_clone(w.vertices[0].position);
            vtx0.push(w.vertices[0].ID);
            var vtx1 = cn_clone(w.vertices[1].position);
            vtx1.push(w.vertices[1].ID);
            obj['wall_' + i] = [vtx0, vtx1, w.ID];
        }
        return obj;
    }

    /**
     * Performs a vertex operation
     * @param {function} operation
     */
    vertex_operation(operation) {
        operation(this.position);
        this.walls.forEach(w => {
            const wid = (w.vertices[0] == this) ? 0 : 1;
            if (w.delegates[wid]) operation(w.delegates[wid].position);
        });
    }

    /**
     * Returns bounding box
     * @returns {cn_box}
     */
    get_bounding_box() {
        const box = new cn_box();
        box.enlarge_point(this.position);
        return box;
    }

    /**
     * Returns true if at least one wall is locked
     * @returns {boolean}
     */
    is_locked() {
        return this.walls.some(w => w.locked);
    }

    /**
     * returns true if wall geometry hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_geometry(date) {
        return !this.walls.some(w => !w.up_to_date_vertex_geometry(date));
    }
}

