import { cn_object } from '@enerbim/cnmap-editor';

export const SLAB_CODE_BIM = 'dalle';
export const INTERIOR_UNITY_CODE_BIM = 'equipements_divers_electriques_pac_int';
export const SWITCH_BOARD_CODE_BIM = 'equipements_divers_electriques_tableau_electrique';

export const AUXILIARIES_EQUIPEMENTS_LIST = [SLAB_CODE_BIM, INTERIOR_UNITY_CODE_BIM, SWITCH_BOARD_CODE_BIM];

const AUXILIARIES_EQUIPEMENTS_WORDING_TRANSPONDER = new Map([
  [SLAB_CODE_BIM, 'dalle (extérieure)'],
  [INTERIOR_UNITY_CODE_BIM, 'unité intérieure'],
  [SWITCH_BOARD_CODE_BIM, 'tableau électrique'],
]);

export class AuxiliariesEquipments {
  equipments = new Map<string, cn_object>();

  isAllPresent(): boolean {
    return AUXILIARIES_EQUIPEMENTS_LIST.every(codeBim => this.equipments.has(codeBim));
  }

  getErrorMessage(): string {
    let result = '';
    const missingElements = [];
    AUXILIARIES_EQUIPEMENTS_LIST.forEach(codeBim => {
      if (!this.equipments.has(codeBim)) {
        missingElements.push(AUXILIARIES_EQUIPEMENTS_WORDING_TRANSPONDER.get(codeBim));
      }
    });
    if (missingElements.length) {
      if (missingElements.length === 1) {
        result = `L'équipement suivant n'a pas été positionné dans la maquette : `;
      } else {
        result = `Les équipements suivants n'ont pas été positionnés dans la maquette : `;
      }
      result += missingElements.join(', ') + '.';
    }
    return result;
  }
}
