import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return [value.address, value.postcode, value.city].filter(field => !!field).join(' ');
  }
}
