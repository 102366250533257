'use strict';
// @ts-ignore
import { cn_clone, cn_contour, cn_polygon_handler, cn_vertex } from '..';
import { cn_layer } from '../model/cn_layer';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_layer  : Layer Manager
//***********************************************************************************
//***********************************************************************************
import { cn_camera } from './cn_camera';
import { cn_svg_map } from './cn_svg_map';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';

export const SUPPORT_WALL = 'wall';
export const SUPPORT_OTHER = 'other';
export const SUPPORT_FLOOR = 'floor';
export const SUPPORT_CEIL = 'ceil';

export const SUPPORTS_LABELS = [
    { support: SUPPORT_FLOOR, label: 'Sol' },
    { support: SUPPORT_WALL, label: 'Mur' },
    { support: SUPPORT_OTHER, label: 'Autre' },
    { support: SUPPORT_CEIL, label: 'Plafond' }
]

/**
 */
export class cn_svg_tool_layer extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} svg_map
     * @param {boolean} roofs : true if we are dealing with roof slabs
     */
    constructor(svg_map, roofs) {
        super(svg_map);
        this._type_wording = 'un calque';
        this._roofs = roofs;
        this._svg = '';
        this._old_slabs = [];
        this.mode = 'full';
        this.support = SUPPORT_WALL;
        this.current_layer = null;
        this._section_handler = null;
        this._line_handler = null;
        this.current_shape_edition = null;
        this.current_line_edition = null;
        this.current_layer_edition = null;
        this._stop_propagation = false;
        this.section_mouseover = null;
        this.line_mouseover = null;
        this._is_creating_section = false;
        this._is_creating_line = false;
        this.initialize_creation_handler();
    }


    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
        super.open_tool();
        if (!this._roofs) {
            this._map._storey.update_slabs();
            this._old_slabs = this._scene.slabs;
            this._scene.slabs = this._map._storey.slabs;
        }
    }

    //***********************************************************************************
    /**
     * Close tool
     */
    close_tool() {
        super.close_tool();
        if (!this._roofs) {
            this._scene.slabs = this._old_slabs;
        }
    }

    //***********************************************************************************
    /**
     * SVG rendering
     * @param {cn_camera} camera
     * @returns {string} svg rendered
     */
    // @ts-ignore
    draw(camera) {
        return super.draw(camera);
    }

    _generate_rectangle_vertex_from_line(vertices) {
        let result = [];
        const [[ax, ay], [bx, by]] = vertices;
        const d = Math.hypot(ax - bx, ay - by);
        if (d > 0) {
            const dx = 2 * (by - ay) / (d * 40);
            const dy = 2 * (bx - ax) / (d * 40);
            const a1 = [ax - dx, ay + dy];
            const a2 = [ax + dx, ay - dy];
            const b1 = [bx - dx, by + dy];
            const b2 = [bx + dx, by - dy];
            result = [a1, b1, b2, a2];
        }
        return result;
    }

    _draw_rectangle(camera, handler) {
        let html = ''
        const [a1, b1, b2, a2] = this._generate_rectangle_vertex_from_line(handler.vertices).map(m => camera.world_to_screen(m));
        if (a1 && b1 && b2 && a2) {
            html += `<path class="virtual_path_for_zpso_line" d="M ${a1[0]} ${a1[1]} L ${b1[0]} ${b1[1]} L ${b2[0]} ${b2[1]} L ${a2[0]} ${a2[1]} Z" />`;
        }
        return html;
    }

    /**
     * Set tool mode : full or section (only for floors and ceils)
     *
     * @param {string} mode
     */
    select_mode(mode) {
        this.mode = mode;
        this.initialize_creation_handler();
        this._section_handler.active = false;
        this._line_handler.active = false;
        this._focus_handler = null;
        if (this.mode === 'section') {
            this._section_handler.active = !!this.current_layer;
        } else if (this.mode === 'line') {
            this._line_handler.active = !!this.current_layer;
        }
        this.unset_edit_handler();
        this.refresh();
    }

    unset_edit_handler() {
        if (this._edit_handler) this.remove_handler(this._edit_handler);
        this._edit_handler = null;
        this.current_line_edition = null;
        this.current_shape_edition = null;
        this.current_layer_edition = null;
    }

    initialize_creation_handler() {
        this._handlers = [];
        this._is_creating_section = false;
        this._is_creating_line = false;
        this._section_handler = cn_polygon_handler.create_rectangle(this, 1);
        this._section_handler.display_measures = false;
        this._section_handler.on('end_creation', () => {
            const shape = new cn_contour();
            this.push_transaction('Ajout d\'une section à ' + this._type_wording);
            this.push_item_set(this.current_layer, 'sections');
            shape.vertices = this._section_handler.vertices.map(v => new cn_vertex(v));
            shape.update();
            const el = this._scene.find_slab(shape.vertices[0].position);
            this.current_layer.add_section(shape, this._scene.storey.ID, this.support, el ? el.ID : '', this.get_shape_order());
            this.initialize_section_edit_handler(shape);
        });
        this._line_handler = cn_polygon_handler.create_open_polygon(this, 0, true, true);
        this._line_handler.display_measures = false;
        this._line_handler.on('end_creation', () => {
            this.push_transaction('Ajout d\'une ligne à ' + this._type_wording);
            this.push_item_set(this.current_layer, 'lines');
            const line_sections = []
            this._line_handler.vertices.forEach((vertex, index) => {
                if (index < this._line_handler.vertices.length - 1) {
                    const shape = new cn_contour();
                    const line = [vertex, this._line_handler.vertices[index + 1]];
                    shape.vertices = this._generate_rectangle_vertex_from_line(line).map(v => new cn_vertex(v));
                    shape.update();
                    const el = this._scene.find_slab(line[0]);
                    line_sections.push(this.current_layer.add_line(shape, line, this._scene.storey.ID, this.support, el ? el.ID : '', this.get_shape_order()));
                }
            })
            this.initialize_line_edit_handler(line_sections[line_sections.length - 1]);
        });
        this._handlers.push(this._section_handler, this._line_handler);
    }

    initialize_line_edit_handler(line_section, layer = null) {
        this.initialize_creation_handler();
        this._section_handler.active = this.mode === 'section';
        this._line_handler.active = this.mode === 'line';
        this._section_handler.visible = false;
        this._line_handler.visible = false;
        this.current_line_edition = line_section.shape.ID;
        this.current_layer_edition = layer || this.current_layer;
        // @ts-ignore
        this._edit_handler = new cn_polygon_handler(this, line_section.line.map(v => cn_clone(v)), true, false);
        this._edit_handler.display_measures = false;
        this._edit_handler['_elements'] = [this.current_line_edition];
        // @ts-ignore
        this._edit_handler.on_delete = () => {
            this.push_transaction('Suppression d\'une line', line_section.shape.ID, () => this.initialize_line_edit_handler(line_section, this.current_layer_edition));
            this.push_item_set(this.current_layer_edition, 'lines');
            this.current_layer_edition.lines.splice(this.current_layer_edition.lines.findIndex(line => line.shape.ID === line_section.shape.ID), 1);
            this._stop_propagation = true;
            this.unset_edit_handler();
        }
        this._edit_handler.on('change', () => {
            this.push_transaction('Modification de line', line_section.shape.ID, () => {
                line_section.shape.vertices = this._generate_rectangle_vertex_from_line(line_section.line).map(v => new cn_vertex(v));
                line_section.shape.update();
            });
            this.push_item_set(line_section.shape, 'vertices');
            this.push_item_set(line_section, 'line');
            // @ts-ignore
            line_section.line = this._edit_handler.vertices;
            // @ts-ignore
            line_section.shape.vertices = this._generate_rectangle_vertex_from_line(this._edit_handler.vertices).map(v => new cn_vertex(v));
            line_section.shape.update();
        });
        this._edit_handler.override_drawing = (camera, html) => {
            let res = html;
            if (this._edit_handler.vertices.length === 2) {
                res += this._draw_rectangle(camera, this._edit_handler);
            }
            return res;
        };
        this._handlers.push(this._edit_handler);
    }

    initialize_section_edit_handler(shape, layer = null) {
        this.initialize_creation_handler();
        this._section_handler.active = this.mode === 'section';
        this._line_handler.active = this.mode === 'line';
        this._section_handler.visible = false;
        this._line_handler.visible = false;
        this.current_shape_edition = shape.ID;
        this.current_layer_edition = layer || this.current_layer;
        // @ts-ignore
        this._edit_handler = new cn_polygon_handler(this, shape.vertices.map(v => cn_clone(v.position)), true);
        this._edit_handler.display_measures = false;
        this._edit_handler['_elements'] = [this.current_shape_edition];
        // @ts-ignore
        this._edit_handler.on_delete = () => {
            this.push_transaction('Suppression d\'une section', shape.ID, () => this.initialize_section_edit_handler(shape, this.current_layer_edition));
            this.push_item_set(this.current_layer_edition, 'sections');
            this.current_layer_edition.sections.splice(this.current_layer_edition.sections.findIndex(section => section.shape.ID === shape.ID), 1);
            this._stop_propagation = true;
            this.unset_edit_handler();
        }
        // @ts-ignore
        this._edit_handler.snap_elements = this._scene.spaces;
        this._edit_handler.on('change', () => {
            this.push_transaction('Modification de section', shape.ID, () => {
                this._scene.update();
                this._scene.update_deep();
            });
            this.push_item_set(shape, 'vertices');

            const shape_vertices = shape.vertices;
            // @ts-ignore
            const handler_vertices = this._edit_handler.vertices;

            while (shape_vertices.length < handler_vertices.length) {
                shape_vertices.push(new cn_vertex([0, 0]));
            }

            if (shape_vertices.length > handler_vertices.length) {
                shape_vertices.splice(0, shape_vertices.length - handler_vertices.length);
            }

            for (let i = 0; i < handler_vertices.length; i++) {
                shape_vertices[i] = new cn_vertex(handler_vertices[i]);
            }

            shape.update();
        });
        this._handlers.push(this._edit_handler);
    }


    select_layer(layer) {
        this.current_layer = layer;
        this._section_handler.active = !!layer && this.mode === 'section';
        this._line_handler.active = !!layer && this.mode === 'line';
    }

    unset_current_layer() {
        this.current_layer = null;
    }

    /**
     * Create new layer
     *
     * @param { string } name
     * @param { string } color
     * @param { string } stripes
     * @param { object } specific_params
     * @returns {cn_layer}
     */
    create(name, color, stripes, specific_params = {}) {
        this.push_transaction('Création d\'un calque');
        this.push_item_set(this._building, 'layers');
        const result = this._building.add_layer(name, color, stripes);
        return result
    }

    /**
     * Update name, color or stripes values
     *
     * @param {cn_layer} layer
     * @param {cn_layer} new_values
     */
    modify(layer, new_values) {
        this.push_transaction('Modification d\'' + this._type_wording);
        this.push_item_set(this, 'current_layer');
        layer.name = new_values.name;
        layer.color = new_values.color;
        layer.stripes = new_values.stripes;
    }

    /**
     * Add element type to layer
     *
     * @param {string} element_type_id
     * @param {cn_layer} layer
     */
    add_element_type_to_layer(element_type_id, layer) {
        this.push_transaction('Ajout d\'un type d\'élément au calque');
        this.push_item_set(layer, 'elements_types');
        layer.add_element_type(element_type_id);
    }

    /**
     * Remove element type from layer
     *
     * @param {string} element_type_id
     * @param {cn_layer} layer
     */
    remove_element_type_from_layer(element_type_id, layer) {
        this.push_transaction('Suppression d\'un type d\'élément du calque');
        this.push_item_set(layer, 'elements_types');
        layer.remove_element_type(element_type_id);
    }


    /**
     * Delete list of layers
     *
     * @param {cn_layer[]} layers
     */
    delete(layers) {
        this.push_transaction('Suppression de calque');
        this.push_item_set(this._building, 'layers');
        layers.forEach(layer => {
            if (this.current_layer && layer.ID === this.current_layer.ID) {
                this.unset_current_layer();
            }
            const index = this.get_layers_ref().findIndex(z => z.ID === layer.ID);
            if (index >= 0) {
                this.get_layers_ref().splice(index, 1);
            }
        });
    }


    //***********************************************************************************
    /**
     * click
     * @returns {boolean}
     */
    click(ev) {
        if (this.current_layer) {
            if (!this._stop_propagation && !this._is_creating_section && !this._is_creating_line
                && ((this.section_mouseover && this.section_mouseover.section.shape.ID !== this.current_shape_edition)
                    || (this.line_mouseover && this.line_mouseover.line.shape.ID !== this.current_line_edition))) {
                if (this.section_mouseover) {
                    this.initialize_section_edit_handler(this.section_mouseover.section.shape, this.section_mouseover.layer);
                } else if (this.line_mouseover) {
                    this.initialize_line_edit_handler(this.line_mouseover.line, this.line_mouseover.layer);
                }
            } else if (!super.click(ev) && this.mode === 'full' && !this._stop_propagation) {
                const position = ev.mouse_world;
                const tolerance = ev.camera.snap_world_distance
                let element = null;
                if (!this._roofs) {
                    if (this.support === SUPPORT_WALL) {
                        element = this._search_element_on_wall_support(element, position, tolerance);
                    } else if (this.support === SUPPORT_OTHER) {
                        element = this._search_element_on_other_support(element, position, tolerance);
                    } else {
                        const el = this._scene.find_slab(position);
                        if (el) {
                            element = { el: el, support: this.support };
                        }
                    }
                } else {
                    [
                        this._scene.roof.find_opening,
                        this._scene.roof.find_slab
                    ].forEach(searchFunction => {
                        if (!element) {
                            const el = searchFunction.apply(this._scene, [position, tolerance]);
                            if (el) {
                                element = { el: el, support: SUPPORT_WALL };
                            }
                        }
                    });
                }
                if (element) {
                    // @ts-ignore
                    if (this.current_layer.has_element(element.el.ID, this._scene.storey.ID, this.support)) {
                        this.push_transaction('Suppression d\'élement d\'' + this._type_wording);
                        this.push_item_set(this.current_layer, 'elements');
                        // @ts-ignore
                        this.current_layer.remove_element(element.el.ID, this._scene.storey.ID, this.support);
                    } else {
                        this.push_transaction('Ajout d\'élement à ' + this._type_wording);
                        this.push_item_set(this.current_layer, 'elements');
                        // @ts-ignore
                        this.current_layer.add_element(element.el.ID, this._scene.storey.ID, this.support);
                    }
                }
            }
        }
        this._stop_propagation = false;
        return true;
    }

    _search_element_on_other_support(element, position, tolerance) {

    }

    _find_line_mouseover(ev) {
        return this._storey.scene.draw_layers.reduce((a, b) => a.concat(b.lines.map(line => {
            return { line: line, layer: b };
        })), [])
            .filter(agg => agg.line.storey === this._scene.storey.ID)
            .reverse()
            .sort((a, b) => b.line.order - a.line.order)
            .find(agg => agg.line.shape.contains(ev.mouse_world, ev.camera.snap_world_distance));
    }

    _find_section_mouseover(ev) {
        return this._storey.scene.draw_layers.reduce((a, b) => a.concat(b.sections.map(section => {
            return { section: section, layer: b };
        })), [])
            .filter(agg => agg.section.storey === this._scene.storey.ID)
            .reverse()
            .sort((a, b) => b.section.order - a.section.order)
            .find(agg => agg.section.shape.contains(ev.mouse_world, ev.camera.snap_world_distance));
    }

    _search_element_on_wall_support(element, position, tolerance) {
        [
            this._scene.find_object_instance,
            this._scene.find_beam,
            this._scene.find_opening,
            this._scene.find_column,
            this._scene.find_stairs,
            this._scene.find_pipe,
            this._scene.find_wall,
            this._scene.find_slab
        ].forEach(searchFunction => {
            if (!element) {
                const el = searchFunction.apply(this._scene, [position, tolerance]);
                if (el) {
                    element = { el: el, support: SUPPORT_WALL };
                }
            }
        });
        return element;
    }

    /**
     * move
     *
     * @param {object} ev
     * @returns {boolean}
     */
    move(ev) {
        this.section_mouseover = this._find_section_mouseover(ev);
        this.line_mouseover = this._find_line_mouseover(ev);
        if (this.section_mouseover && this.line_mouseover) {
            if (this.section_mouseover.section.order > this.line_mouseover.line.order) {
                this.line_mouseover = null;
            } else {
                this.section_mouseover = null;
            }
        }
        const creation_visible = !this.section_mouseover && !this.line_mouseover;
        this._is_creating_section = this._section_handler.is_creating();
        this._is_creating_line = this._line_handler.is_creating();
        this._section_handler.visible = creation_visible || this._section_handler.is_creating();
        this._line_handler.visible = creation_visible || this._line_handler.is_creating();
        const result = super.move(ev) || this.mode === 'full';
        return result;
    }

    /**
     * grab
     *
     * @param {object} ev
     * @returns {boolean}
     */
    grab(ev) {
        const result = super.grab(ev);
        return result;
    }

    drag(ev) {
        const result = super.drag(ev);
        return result;
    }

    is_creating() {
        return true;
    }

    _find_other_element() {
        return this.current_shape_edition || this.current_line_edition;
    }


    get_layers_ref() {
        return this._building.layers;
    }

    get_shape_order() {
        const result = Math.max(...this.get_layers_ref().flatMap(layer => layer.sections.map(sec => sec.order))
            .concat(this.get_layers_ref().flatMap(layer => layer.lines.map(l => l.order)), 0)) + 1;
        return result || 1;
    }

}
