import { ElementLibrary, ILibraryHandler } from '@enerbim/cnmap-angular-editor-lib';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class LibraryHandler implements ILibraryHandler {
  isLibraryDisplayed(): boolean {
    return false;
  }

  getLibraries(): Observable<ElementLibrary[]> {
    return of([]);
  }

  addItemIntoLibraries(): Observable<boolean> {
    return of(true);
  }

  navigateToLibraries(): void {}
}
