import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationStore } from './authentication.store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../shared/services/notification.service';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/model/user.model';
import { isServerUnavailableError } from '../http-error.service';
import { UserService } from '../../shared/services/user.service';
import { CompanyService } from '../../shared/services/company.service';
import { AuthenticationResponse } from 'src/app/shared/model/authentication-response';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private authenticationStore: AuthenticationStore,
    private notificationService: NotificationService,
    private userService: UserService,
    private companyService: CompanyService,
  ) {}

  login(value: { email: string; password: string; rememberMe?: boolean }): Observable<AuthenticationResponse> {
    return this.http
      .post<AuthenticationResponse>(`${environment.apiUrl}/authenticate`, {
        username: value.email.trim(),
        password: value.password,
        rememberMe: value.rememberMe == true,
      })
      .pipe(
        switchMap(authResponse => {
          if (!authResponse.mfa) {
            return this.queryAndSetCurrentUser().pipe(map(user => ({ authResponse, user })));
          } else {
            return of({ authResponse, user: null });
          }
        }),
        map(({ authResponse }) => authResponse),
      );
  }

  completeProfil(value: { email: string; password: string; rememberMe?: boolean; mfaToken: string }): Observable<User> {
    return this.http
      .post<AuthenticationResponse>(`${environment.apiUrl}/authenticate-mfa`, {
        username: value.email.trim(),
        password: value.password,
        rememberMe: value.rememberMe == true,
        mfaToken: value.mfaToken,
      })
      .pipe(switchMap(() => this.queryAndSetCurrentUser()));
  }

  logout(): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/logout`, {}).pipe(tap(() => this.unsetCurrentUser()));
  }

  /**
   * Vérifie si l'utilisateur est authentifié et renseigne l'utilisateur courant dans le store le cas échéant.
   */
  queryAndSetCurrentUser(): Observable<User> {
    return forkJoin([this.userService.getCurrentUser(), this.companyService.reloadCompaniesForUser$()]).pipe(
      tap(([user]) => this.authenticationStore.setCurrentUser(user)),
      catchError(err => {
        // En cas d'erreur (ex : token invalide), sauf service indisponible, on logout
        if (!isServerUnavailableError(err)) {
          this.notificationService.dismiss(); // Enlève la notification d'erreur 401 dans ce cas
          return this.logout().pipe(map(() => null));
        } else {
          return of(null);
        }
      }),
    );
  }

  private unsetCurrentUser() {
    this.authenticationStore.unsetCurrentUser();
  }
}
