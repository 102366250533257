'use strict';

import { cn_element } from './cn_element';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************


//***********************************************************************************
/**
 * @class cn_facing
 * Class to modelize one layer of a facing.
 */
export class cn_facing_layer extends cn_element {
    //***********************************************************************************
    /**
     * Constructor
     */
    constructor() {
        super();
        this.category = '';
        this.name = '';
        this.thickness = 0.03;

    }

    //***********************************************************************************
    /**
     * serialize
     * @returns {object}
     */
    serialize() {
        const json = {};
        json.category = this.category;
        json.name = this.name;
        json.thickness = this.thickness;
        return json;
    }

    //***********************************************************************************
    /**
     * unserialize
     * @param {object} json
     * @returns {cn_facing_layer | null}
     */
    static unserialize(json) {
        const result = new cn_facing_layer();
        result.category = json.category;
        result.name = json.name;
        result.thickness = json.thickness;
        return result;
    }

    get_label() {
        let result = this.category + ' ' + (this.thickness * 100).toFixed(0) + 'cm';
        if (this.name.toLocaleLowerCase() !== this.category.toLocaleLowerCase()) {
            result += ' (' + this.name + ')';
        }
        return result;
    }

}

