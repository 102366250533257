'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_tool  : basic class for creation
//***********************************************************************************
//***********************************************************************************

import { cn_svg_tool } from './cn_svg_tool';
import { cn_svg_map } from './cn_svg_map';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_element } from '../model/cn_element';
import { cn_event_handler } from './cn_event_handler';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_camera } from './cn_camera';

class cn_other_handler extends cn_event_handler {
    constructor(creation_tool) {
        super();
        this._creation_tool = creation_tool;
        this._other = null;
    }

    /**
     * Drawing
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        var html = '';

        if (this._other) {
            if (camera.is_3d() && this._creation_tool._other_object) {
                html += this._creation_tool._other_object.geometries[0].draw_svg(camera, 'outline_3d mouseover');
            } else {
                if (camera.is_3d()) camera.storey = this._creation_tool._other_storey;
                html += this._other.draw(camera, ['mouseover']);
            }
        }

        return html;
    }

    clear_move() {
        this._other = null;
    };

    move(ev) {
        this._other = this._creation_tool._find_other_element(ev);
        if (this._other && this._creation_tool._edition_handler && this._creation_tool._edition_handler._elements.length == 1 && this._creation_tool._edition_handler._elements[0] == this._other)
            this._other = null;
        return !!this._other;
    }

    click(ev) {
        if (this._other) {
            let sel = [this._other];
            if (ev.ctrlKey && this._creation_tool._edition_handler)
                sel = sel.concat(this._creation_tool._edition_handler._elements);
            this._creation_tool._initiate_edition(sel);
            this._other = null;
            return true;
        }
        return false;
    }
}

export class cn_svg_tool_creation extends cn_svg_tool {
    //*****************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} svg_map
     */
    constructor(svg_map) {
        super(svg_map);
        this.name = '';
        this._svg_parent = null;
        this._edition_handler = null;
        this._other_handler = new cn_other_handler(this);
        this._other_object = null;
        this._other_storey = null;
        this._handlers.push(this._other_handler);
    }

    //*****************************************************************
    //*** Must return 'true' if wa are in modal creation.
    //*****************************************************************
    is_creating() {
        return false;
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
        super.open_tool();
        if (this._map)
            this._map.refresh_main_and_tool();
        if (this._view_overlay)
            this._view_overlay.refresh_rendering();
    }

    close_tool() {
        super.close_tool();
        this._terminate_edition();
        if (this._map)
            this._map.refresh_main_and_tool();
        if (this._view_overlay)
            this._view_overlay.refresh_rendering();
    }

    //***********************************************************************************
    //**** Drawing
    //***********************************************************************************
    /**
     * Drawing
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        if (this.is_creating() && this._edition_handler) this._terminate_edition();

        this._other_handler.active = this._other_handler.visible = !this.is_creating();

        return super.draw(camera);
    }

    //***********************************************************************************
    //**** Edition alements
    //***********************************************************************************
    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        return null;
    }

    /**
     * TODO : derivate in order to provide an edition handler
     * @param {Array<cn_element>} elements
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(elements) {
        return null;
    }

    /**
     * TODO : derivate in order to find siblings of an element
     * @param {cn_element} element
     * @returns {Array<cn_element>}
     */
    _get_siblings(element) {
        return [];
    }

    /**
     * Initiate edition of a series of elements
     * @param {Array<cn_element>} elements
     */
    _initiate_edition(elements) {
        this._terminate_edition();
        if (this._controller)
            this._controller.select_element_list(elements);
        if (elements.length == 0) return;
        this._edition_handler = this._build_edition_handler(elements);
        if (this._edition_handler == null) return;
        this._handlers.push(this._edition_handler);

        const obj = this;

        this._edition_handler.on('select_siblings', () => {
            obj._initiate_edition(obj._get_siblings(elements[0]));
            return true;
        });
    }

    //*** Force termination of edition */
    _terminate_edition() {
        if (this._edition_handler) {
            const index = this._handlers.indexOf(this._edition_handler);
            if (index >= 0) this._handlers.splice(index, 1);
            this._edition_handler = null;
        }

        if (this._controller) this._controller.clear_selection();
    }
}

