//***********************************************************************************
//***********************************************************************************
//**** fh_clipping_plane : manipulation of a clipping plane
//***********************************************************************************
//***********************************************************************************

import {
    Object3D,
	Plane,
	Vector3
// @ts-ignore
} from 'three';
import { fh_handle } from './fh_handle';
import { fh_polar } from './fh_vector.js';
import { fh_scene } from './fh_scene';

//***********************************************************************************
//**** Handle
//***********************************************************************************

export class fh_clipping_plane extends Object3D {
	//*****************************************************
	/**
	 * Constructor
	 * @param {Vector3} position
	 * @param {Vector3} normal
	 * @param {fh_scene} scene
	 */
	constructor(position, normal, scene) {
		super();

		this.active = false;
		this.visible = false;

		this._scene = scene;
		this._plane_object0 = new Object3D();
		// @ts-ignore
		this.add(this._plane_object0);

		//*** Azimut handle */
		const rot0_handle = new fh_handle("circle",5);
		rot0_handle.on("move",(p) => {
			this._plane_object0.rotateZ(p);
			this._update_geometry();
		});
		// @ts-ignore
		this.add(rot0_handle);

		this._plane_object1 = new Object3D();
		this._plane_object0.add(this._plane_object1);

		//*** Inclination angdle */
		const rot1_handle = new fh_handle("circle",5);
		rot1_handle.on("move",(p) => {
			this._plane_object1.rotateX(p);
			this._update_geometry();
		});
		// @ts-ignore
		rot1_handle.rotation.set(0,Math.PI/2,0,"XYZ");
		this._plane_object0.add(rot1_handle);

		//*** shift handle */
		const plane_handle = new fh_handle("square",5);
		plane_handle.on("move",(p) => this._update_position(p));
		this._plane_object1.add(plane_handle);

		//*** plane depth handle */
		const z_handle = new fh_handle("arrow",8);
		z_handle.on("move",(p) => this._update_position(p));
		this._plane_object1.add(z_handle);

		// @ts-ignore
		this.position.copy(position);

		const pol = fh_polar([normal.x,normal.y,normal.z]);
		this._plane_object0.rotateZ(pol[2]-0.5*Math.PI);
		this._plane_object1.rotateX(pol[1]-0.5*Math.PI);

		this.visible = false;
		this.active = false;
		this._update_geometry();
	}

	/**
	 * Serialization 
	 * @returns {any}
	 */
	serialize() {
		const json = {};
		// @ts-ignore
		json.position = [this.position.x,this.position.y,this.position.z];
		json.normal = [-this.plane.normal.x,-this.plane.normal.y,-this.plane.normal.z];
		json.active = this.active;
		return json;
	}

	/**
	 * Unserialize
	 * @param {any} json 
	 * @param {fh_scene} scene 
	 * @returns {fh_clipping_plane}
	 */
	static unserialize(json, scene)
	{
		// @ts-ignore
		const clipping_plane = new fh_clipping_plane(new Vector3(json.position[0],json.position[1],json.position[2]),new Vector3(json.normal[0],json.normal[1],json.normal[2]),scene);
		clipping_plane.active = json.active;
		return clipping_plane;
	}

	/**
	 * Update clipping plane position
	 * @param {Vector3} p
	 * @returns
	 */
	_update_position(p) {
		// @ts-ignore
		const new_position = this.position.clone().add(p);

		//*** Check that plane remains in the bounding box */
		if (new_position.distanceTo(this._scene._scene_center) > this._scene._scene_radius) return;
		// @ts-ignore
		this.position.add(p);
		this._update_geometry();
	}

	/**
	 * Update geometry
	 */
	_update_geometry() {
		/** Update matrices */
		super.updateMatrix();
		super.updateMatrixWorld(true);
		this._plane_object0.updateMatrix();
		this._plane_object0.updateMatrixWorld(true);
		this._plane_object1.updateMatrix();
		this._plane_object1.updateMatrixWorld(true);

		//*** Update clipping plane */
		const pos = new Vector3(0,0,0).applyMatrix4(this._plane_object1.matrixWorld);
		const nor = new Vector3(0,0,-1).applyMatrix4(this._plane_object1.matrixWorld).sub(pos);
		nor.normalize();
		this.plane = new Plane(nor,-nor.dot(pos));
	}
}
