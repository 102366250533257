'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_scene controller
//***********************************************************************************
//***********************************************************************************

import { cn_space } from '../model/cn_space';
import { cn_vertex } from '../model/cn_vertex';
import { cn_wall } from '../model/cn_wall';
import { cn_column } from '../model/cn_column';
import { cn_beam } from '../model/cn_beam';
import { cn_object_instance } from '../model/cn_object_instance';
import { cn_stairs } from '../model/cn_stairs';
import { cn_slab_opening } from '../model/cn_slab_opening';
import { cn_opening } from '../model/cn_opening';
import { cn_marker } from '../model/cn_marker';
import { cn_pipe } from '../model/cn_pipe';
import { cn_element } from '../model/cn_element';
import { cn_sampling, logger } from '..';
import { cn_clipboard } from './cn_clipboard';

export class cn_scene_controller {
    constructor(scene, storey) {
        var obj = this;

        //*** Scene data
        this._scene = scene;
        this._storey = storey;

        //*** Selection data
        this._mouseover = null;
        this._selection = [];

        this._mouseover_delegate = null;
        this._selection_delegates = [];

        //*** filter data
        this._element_names = ['outer_walls', 'inner_walls', 'balconies', 'fences', 'windows', 'doors', 'slab_openings', 'stairs', 'objects', 'beams', 'columns', 'pipes', 'markers', 'samplings'];
        this._element_labels = ['Murs ext.', 'Murs int.', 'Balcons', 'Clôtures', 'Fenêtres', 'Portes', 'Trémies', 'Escaliers', 'Objets', 'Poutres', 'Poteaux', 'Conduits', 'Annotations', 'Prélèvements'];
        this._element_filter = [];
        for (var i in this._element_names)
            this._element_filter[this._element_names[i]] = true;
        this._element_count = [];
        for (var i in this._element_names)
            this._element_count[this._element_names[i]] = 0;
        this.update_element_count();
    }

    //***********************************************************************************
    //**** Selection methods
    //***********************************************************************************
    get_selection() {
        return this._selection;
    }

    get_selection_delegates() {
        return this._selection_delegates;
    }

    /**
     * Returns true if element is selected
     * @param {cn_element} elt
     * @returns {boolean}
     */
    is_selected(elt) {
        return this._selection.indexOf(elt) >= 0;
    }

    select_element(elt, clear_selection = true, delegate = null) {
        if (clear_selection)
            this.clear_selection();
        if (this._selection.indexOf(elt) < 0) {
            this._selection.push(elt);
            this._selection_delegates.push(delegate);
        }
    }

    select_element_list(elements) {
        this.clear_selection();
        elements.forEach(e => this.select_element(e, false));
    }

    unselect_element(elt) {
        var index = this._selection.indexOf(elt);
        if (index >= 0)
            this._selection.splice(index, 1);
    }

    get_mouseover() {
        return this._mouseover;
    }

    get_mouseover_delegate() {
        return this._mouseover_delegate;
    }

    set_mouseover(elt, delegate = null) {
        this._mouseover = elt;
        this._mouseover_delegate = delegate;
    }

    clear_selection() {
        this._mouseover = null;
        this._selection = [];
        this._mouseover_delegate = null;
        this._selection_delegates = [];
    }

    //***********************************************************************************
    //**** element data
    //***********************************************************************************
    get_element_names() {
        return this._element_names;
    }

    get_element_label(name) {
        return this._element_labels[this._element_names.indexOf(name)];
    }

    get_element_count(name) {
        return this._element_count[name];
    }

    get_element_filter(name) {
        if (name != 'vertices')
            return this._element_filter[name];
        return (this.get_element_filter('inner_walls') || this.get_element_filter('outer_walls') || this.get_element_filter('balconies') || this.get_element_filter('fences'));
    }

    set_element_filter(name, value) {
        if (name == 'vertices') return;
        if (name == 'spaces') return;
        this._element_filter[name] = value;

        var new_status = (value) ? 0 : -1;
        var elts = this.get_elements(name);
        for (var i in elts) {
            elts[i].status = new_status;
        }

        //*** deselected disabled elements
        if (!value) {
            var new_selection = [];
            for (var i in this._selection) {
                var sname = this.find_element_name(this._selection[i]);
                if (name != sname)
                    new_selection.push(this._selection[i]);
            }
            this._selection = new_selection;
        }
    }

    //*** checks if a given element passes the filters
    check_filter(elt) {

        //*** specific treatment for vertices
        if (elt.constructor == cn_vertex) {
            for (var i in elt.walls) {
                if (this.get_element_filter(this.find_element_name(elt.walls[i])))
                    return true;
            }
            return false;
        }

        if (elt.constructor == cn_space)
            return true;

        return this.get_element_filter(this.find_element_name(elt));
    }

    //*** return all elements with a given name
    get_elements(name) {
        var elts = [];

        //*** vertices
        if (name == 'vertices')
            elts = elts.concat(this._scene.vertices);

        //*** spaces
        else if (name == 'spaces')
            elts = elts.concat(this._scene.spaces);

        //*** Walls
        else if (name == 'inner_walls' || name == 'outer_walls' || name == 'balconies' || name == 'fences') {
            for (var i in this._scene.walls) {
                var wall = this._scene.walls[i];
                if (this.find_element_name(wall) == name)
                    elts.push(wall);
            }
        }

        //*** openings
        else if (name == 'windows' || name == 'doors') {
            for (var i in this._scene.walls) {
                var wall = this._scene.walls[i];
                for (var j in wall.openings) {
                    var opening = wall.openings[j];
                    if (this.find_element_name(opening) == name)
                        elts.push(opening);
                }
            }
        }

        //*** slab openings
        else if (name == 'slab_openings')
            elts = elts.concat(this._scene.slab_openings);

        //*** slab openings
        else if (name == 'stairs')
            elts = elts.concat(this._scene.stairs);

        //*** slab openings
        else if (name == 'objects')
            elts = elts.concat(this._scene.object_instances);

        //*** beams
        else if (name == 'beams')
            elts = elts.concat(this._scene.beams);

        //*** columns
        else if (name == 'columns')
            elts = elts.concat(this._scene.columns);

        //*** pipes
        else if (name == 'pipes')
            elts = elts.concat(this._scene.pipes);

        //*** markers
        else if (name == 'markers')
            elts = elts.concat(this._storey.markers);

        //*** samplings
        else if (name == 'samplings')
            elts = elts.concat(this._storey.samplings);

        return elts;
    }

    //***********************************************************************************
    /**
     * Returns element under mouse
     * @param {number[]} pt
     * @param {number} tolerance
     * @returns {cn_element}
     */
    find_element(pt, tolerance = 0) {
        //*** Maybe mouse over a  marker ?
        if (this._scene.draw_samplings) {
            const sampling = this._storey.find_sampling(pt, tolerance);
            if (sampling && sampling.selectable) {
                return sampling;
            }
        }
        if (this._scene.draw_comments) {
            const marker = this._storey.find_marker(pt, tolerance);
            if (marker && marker.selectable) {
                return marker;
            }
        }

        //*** Maybe mouse over a vertex ?
        var vertex = this._scene.find_vertex(pt, tolerance);
        if (vertex && vertex.selectable && this.check_filter(vertex))
            return vertex;

        //*** Maybe mouse over an opening ?
        var opening = this._scene.find_opening(pt, tolerance);
        if (opening && opening.selectable && this.check_filter(opening))
            return opening;

        //*** Maybe mouse over an object instance ?
        var object = this._scene.find_object_instance(pt, tolerance);
        if (object && object.selectable && this.check_filter(object))
            return object;

        //*** Maybe mouse over a column ?
        var column = this._scene.find_column(pt, tolerance);
        if (column && column.selectable && this.check_filter(column))
            return column;

        //*** Maybe mouse over a beam ?
        var beam = this._scene.find_beam(pt, tolerance);
        if (beam && beam.selectable && this.check_filter(beam))
            return beam;

        //*** Maybe mouse over a pipe ?
        var pipe = this._scene.find_pipe(pt, tolerance);
        if (pipe && pipe.selectable && this.check_filter(pipe))
            return pipe;

        //*** Maybe mouse over a wall ?
        var wall = this._scene.find_wall(pt, tolerance);
        if (wall && wall.selectable && this.check_filter(wall))
            return wall;

        //*** Maybe mouse over a  stairs ?
        var stairs = this._scene.find_stairs(pt, tolerance);
        if (stairs && stairs.selectable && this.check_filter(stairs))
            return stairs;

        //*** Maybe mouse over a space ?
        var space = this._scene.find_space(pt, true);
        if (space && space.selectable && this.check_filter(space))
            return space;

        return null;
    }

    //***********************************************************************************
    //**** Updates mouseover element
    //***********************************************************************************
    find_mouseover(pt, tolerance) {
        const elt = this.find_element(pt, tolerance);
        // @ts-ignore
        const delegate = (elt && elt.constructor == cn_vertex) ? elt.find_delegate_wall(pt) : null;
        this.set_mouseover(elt, delegate);
    }

    //***********************************************************************************
    //**** Area selection
    //***********************************************************************************
    area_select(box, clear_selection) {
        if (clear_selection)
            this.clear_selection();

        //*** Check openings
        for (var i in this._scene.walls) {
            var wall = this._scene.walls[i];

            if (this.get_element_filter(this.find_element_name(wall)) &&
                this._selection.indexOf(wall) < 0 &&
                box.contains_point(wall.vertices[0].position) &&
                box.contains_point(wall.vertices[1].position)) {
                this.select_element(wall, false);
            }

            for (var j in wall.openings) {
                var opening = wall.openings[j];
                if (this.get_element_filter(this.find_element_name(opening)) &&
                    this._selection.indexOf(opening) < 0 &&
                    opening.contained_by_box(box)) {
                    this.select_element(opening, false);
                }
            }
        }

        if (this.get_element_filter('slab_openings')) {
            for (var i in this._scene.slab_openings) {
                var slab_openings = this._scene.slab_openings[i];
                if (this._selection.indexOf(slab_openings) >= 0) continue;

                if (!slab_openings.contained_by_box(box)) continue;

                this.select_element(slab_openings, false);
            }
        }

        if (this.get_element_filter('stairs')) {
            for (var i in this._scene.stairs) {
                var stairs = this._scene.stairs[i];

                if (this._selection.indexOf(stairs) >= 0) continue;

                if (!stairs.contained_by_box(box)) continue;

                this.select_element(stairs, false);
            }
        }

        if (this.get_element_filter('objects')) {
            for (var i in this._scene.object_instances) {
                var instance = this._scene.object_instances[i];

                if (this._selection.indexOf(instance) >= 0) continue;

                if (!box.contains_point(instance.position)) continue;

                this.select_element(instance, false);
            }
        }

        if (this.get_element_filter('beams')) {
            for (var i in this._scene.beams) {
                var beam = this._scene.beams[i];

                if (this._selection.indexOf(beam) >= 0) continue;

                if (!box.contains_point(beam.vertices[0])) continue;
                if (!box.contains_point(beam.vertices[1])) continue;

                this.select_element(beam, false);
            }
        }

        if (this.get_element_filter('columns')) {
            for (var i in this._scene.columns) {
                var column = this._scene.columns[i];

                if (this._selection.indexOf(column) >= 0) continue;

                if (!box.contains_point(column.position)) continue;

                this.select_element(column, false);
            }
        }

        if (this.get_element_filter('pipes')) {
            for (var i in this._scene.pipes) {
                var pipe = this._scene.pipes[i];

                if (this._selection.indexOf(pipe) >= 0) continue;

                if (!box.contains_point(pipe.vertices[0])) continue;
                if (!box.contains_point(pipe.vertices[1])) continue;

                this.select_element(pipe, false);
            }
        }

        if (this.get_element_filter('markers')) {
            for (var i in this._storey.markers) {
                var marker = this._storey.markers[i];

                if (this._selection.indexOf(marker) >= 0) continue;

                if (!box.contains_point(marker.position)) continue;

                this.select_element(marker, false);
            }
        }
    }

    //***********************************************************************************
    //**** Area selection
    //***********************************************************************************
    select_all() {
        this.clear_selection();

        //*** Check openings
        for (var i in this._scene.walls) {
            var wall = this._scene.walls[i];

            if (this.get_element_filter(this.find_element_name(wall)))
                this.select_element(wall, false);

            for (var j in wall.openings) {
                var opening = wall.openings[j];
                if (this.get_element_filter(this.find_element_name(opening)))
                    this.select_element(opening, false);
            }
        }

        if (this.get_element_filter('slab_openings')) {
            for (var i in this._scene.slab_openings)
                this.select_element(this._scene.slab_openings[i], false);
        }

        if (this.get_element_filter('stairs')) {
            for (var i in this._scene.stairs)
                this.select_element(this._scene.stairs[i], false);
        }

        if (this.get_element_filter('objects')) {
            for (var i in this._scene.object_instances)
                this.select_element(this._scene.object_instances[i], false);
        }

        if (this.get_element_filter('beams')) {
            for (var i in this._scene.beams)
                this.select_element(this._scene.beams[i], false);
        }

        if (this.get_element_filter('columns')) {
            for (var i in this._scene.columns)
                this.select_element(this._scene.columns[i], false);
        }

        if (this.get_element_filter('pipes')) {
            for (var i in this._scene.pipes)
                this.select_element(this._scene.pipes[i], false);
        }

        if (this.get_element_filter('markers')) {
            for (var i in this._storey.markers)
                this.select_element(this._storey.markers[i], false);
        }

        if (this.get_element_filter('samplings')) {
            this._storey.samplings.forEach(sampling => {
                this.select_element(sampling, false);
            })
        }
    }

    //***********************************************************************************
    //*** Select all elements with a given name
    //***********************************************************************************
    select_elements(name) {
        if (!this.get_element_filter(name)) return;
        this.clear_selection();
        var selection = this.get_elements(name);
        for (var i in selection)
            this.select_element(selection[i], false);
    }

    //***********************************************************************************
    //**** Returns element name
    //***********************************************************************************
    find_element_name(elt) {
        var ctr = elt.constructor;
        if (ctr == cn_vertex)
            return 'vertices';

        if (ctr == cn_wall) {
            if (this._storey.exterior)
                return 'fences';
            if (elt.balcony) {
                return 'balconies';
            } else if (elt.spaces[0] == null || elt.spaces[1] == null) {
                logger.log('warning');
                return 'inner_walls';
            } else if (elt.spaces[0].has_roof && elt.spaces[1].has_roof) {
                return 'inner_walls';
            } else {
                return 'outer_walls';
            }
        }

        if (ctr == cn_opening) {
            if (elt.opening_type.category == 'window')
                return 'windows';
            if (elt.opening_type.category == 'door')
                return 'doors';
        }

        if (ctr == cn_slab_opening)
            return 'slab_openings';

        if (ctr == cn_stairs)
            return 'stairs';

        if (ctr == cn_object_instance)
            return 'objects';

        if (ctr == cn_space)
            return 'spaces';

        if (ctr == cn_beam)
            return 'beams';

        if (ctr == cn_column)
            return 'columns';

        if (ctr == cn_pipe)
            return 'pipes';

        if (ctr == cn_marker)
            return 'markers';

        if (ctr == cn_sampling)
            return 'samplings';

        return '';
    }

    //***********************************************************************************
    //**** Update element count
    //***********************************************************************************
    update_element_count() {
        for (var i in this._element_count)
            this._element_count[i] = 0;

        for (var i in this._scene.walls) {
            var wall = this._scene.walls[i];
            this._element_count[this.find_element_name(wall)]++;
            for (var j in wall.openings) {
                this._element_count[this.find_element_name(wall.openings[j])]++;
            }
        }

        this._element_count['slab_openings'] += this._scene.slab_openings.length;
        this._element_count['stairs'] = this._scene.stairs.length;
        this._element_count['objects'] = this._scene.object_instances.length;
        this._element_count['beams'] = this._scene.beams.length;
        this._element_count['columns'] = this._scene.columns.length;
        this._element_count['pipes'] = this._scene.pipes.length;
        this._element_count['markers'] = this._storey.markers.length;

        var elts = [];

        return elts;
    }

    //***********************************************************************************
    /**
     * Copy selection in building clipboard
     * @returns {boolean}
     */
    copy_selection() {
        //*** what do we have to copy ? */
        if (!this.can_copy()) return false;
        this._make_clipboard();
        return true;
    }

    //***********************************************************************************
    /**
     * Cut selection in building clipboard
     * @returns {boolean}
     */
    cut_selection() {
        if (!this.can_cut()) return false;
        this._make_clipboard();
        return true;
    }

    _make_clipboard() {
        const clipboard = new cn_clipboard(this._scene, this._selection);
        this._scene.building.clipboard.splice(0, 0, clipboard);
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to copy
     * @returns {boolean}
     */
    can_copy() {
        for (var i in this._selection) {
            var sel = this._selection[i];
            if (sel.constructor == cn_space) continue;
            if (sel.constructor == cn_vertex) continue;
            if (sel.constructor == cn_opening) continue;
            if (sel.constructor == cn_sampling) continue;
            if (sel.constructor == cn_marker) continue;
            return true;
        }
        return false;
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to cut
     * @returns {boolean}
     */
    can_cut() {
        return this._selection.some(element => ![cn_space, cn_vertex, cn_opening, cn_sampling, cn_marker].includes(element.constructor)
            && element.removable);
    }

    //*******************************************************
    /**
     * Returns 'true' if there is something to delete
     * @returns {boolean}
     */
    can_delete() {
        for (var i in this._selection) {
            if (this._selection[i].removable) return true;
        }
        return false;
    }

}

