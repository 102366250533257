'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

import { cn_building } from '../../model/cn_building';
import {
    CLOSING_LIST,
    code_to_label,
    FRAME_LIST,
    GLAZING_GAZ_LIST,
    GLAZING_LIST,
    OPENING_LIST,
    SILL_LIST,
    TRANSOM_LIST,
    VERTICAL_OPENING_LIST,
} from '../../model/cn_opening_type';
import { cn_nomenclature } from '../cn_nomenclature';

//***********************************************************************************
//***********************************************************************************
/**
 * Buils openings's types  nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_openings_types(building) {
    const output = [];

    output.push(new cn_nomenclature('Nom'));
    output.push(new cn_nomenclature('Catégorie'));
    output.push(new cn_nomenclature('Largeur').withNumberDefinition('cm', 2));
    output.push(new cn_nomenclature('Hauteur').withNumberDefinition('cm', 2));
    output.push(new cn_nomenclature('Décalage').withNumberDefinition('cm', 2));
    output.push(new cn_nomenclature('Surface').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Surface vitrée').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Nombre d\'occurence').withNumberDefinition('', 0));
    output.push(new cn_nomenclature('Surface totale').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Surface vitrée totale').withNumberDefinition('m²', 2));
    output.push(new cn_nomenclature('Menuiserie'));
    output.push(new cn_nomenclature('Vitrage'));
    output.push(new cn_nomenclature('Gaz'));
    output.push(new cn_nomenclature('Ouverture'));
    output.push(new cn_nomenclature('Type'));
    output.push(new cn_nomenclature('Panneaux'));
    output.push(new cn_nomenclature('Imposte'));
    output.push(new cn_nomenclature('Allège'));
    output.push(new cn_nomenclature('Fermeture'));

    const openings = building.storeys.flatMap(st => st.scene.walls.flatMap(w => w.openings));

    building.get_opening_types().forEach((opening_type, i) => {
        const opening_type_area = opening_type.get_area();
        const opening_type_glazing_area = opening_type.get_glazing_area();
        const nb_openings_of_type = openings.filter(op => op.opening_type.ID === opening_type.ID).length;

        let nomenclature_entry_index = 0;
        output[nomenclature_entry_index].values[i] = opening_type.get_label();
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type.category === 'window' ? 'Fenetre' : 'Porte';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type.width;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type.height;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type.z;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type_area;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type_glazing_area;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = nb_openings_of_type;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = nb_openings_of_type * opening_type_area;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = nb_openings_of_type * opening_type_glazing_area;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = code_to_label(opening_type.frame, FRAME_LIST) || '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = code_to_label(opening_type.glazing, GLAZING_LIST) || '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = (opening_type.glazing == 'double') ? code_to_label(opening_type.glazing_gaz, GLAZING_GAZ_LIST) || '' : '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = code_to_label(opening_type.opening, OPENING_LIST) || '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = (opening_type.opening == 'vertical') ? code_to_label(opening_type.vertical_opening, VERTICAL_OPENING_LIST) || '' : '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = opening_type.panels;
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = code_to_label(opening_type.transom, TRANSOM_LIST) || '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = code_to_label(opening_type.sill, SILL_LIST) || '';
        nomenclature_entry_index++;
        output[nomenclature_entry_index].values[i] = code_to_label(opening_type.closing, CLOSING_LIST) || '';
    });

    return output;
}
