import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { AuthenticationStore } from '../auth/authentication.store';
import { NotificationService } from '../../shared/services/notification.service';
import { ROLE_ADMIN } from '../../shared/model/user.model';
import { CompanyRights } from '../../shared/model/company.model';
import { CompanyService } from '../../shared/services/company.service';
import { AuthorizationService } from '../../shared/services/authorization.service';

@Component({
  selector: 'app-logged-layout',
  templateUrl: './logged-layout.component.html',
  styleUrls: ['./logged-layout.component.scss'],
  // there is still children components relying on the default ChangeDetectionStrategy
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedLayoutComponent implements OnInit, OnDestroy {
  readonly CompanyRights = CompanyRights;

  backLink: string = null;
  pageTitle: any;

  readonly SUPER_ADMIN = ROLE_ADMIN;
  readonly user$ = this.authenticationStore.getCurrentUser();
  readonly nbCompanies$ = this.companyService
    .getCurrentUserCompaniesForAdmin({ includeIndividual: this.authorizationService.isSuperAdmin() })
    .pipe(map(companies => companies.length));

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private authenticationStore: AuthenticationStore,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this._destroy$),
        filter(e => e instanceof NavigationEnd),
        tap(() => this._updatePageTitleAndBackLink()),
      )
      .subscribe();
    this._updatePageTitleAndBackLink();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  logOut() {
    this.authenticationService
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/login']);
        this.notificationService.notify('Vous avez été déconnecté.');
      });
  }

  private _updatePageTitleAndBackLink(): void {
    let currentRoute = this.route.snapshot;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    while (currentRoute && currentRoute.data.pageTitle === undefined) {
      currentRoute = currentRoute.parent;
    }
    this.pageTitle = currentRoute?.data.pageTitle || '';
    this.backLink = currentRoute?.data.backLink || '';
    this.cdr.markForCheck();
  }
}
