'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_tool  : basic class for tool
//***********************************************************************************
//***********************************************************************************

import { cn_element } from '../model/cn_element';
import { cn_storey } from '../model/cn_storey';
import { cn_add, cn_dist, cn_dot, cn_normal, cn_normalize, cn_size, cn_sub } from '../utils/cn_utilities';
import { cn_svg_map } from './cn_svg_map';
import { cn_event_handler } from './cn_event_handler';

export class cn_svg_tool extends cn_event_handler {
    /**
     *
     * @param {cn_svg_map} svg_map
     */
    constructor(svg_map = null) {
        super(svg_map);

        this.area_selection = false;

        this._map = svg_map;
        this._scene = (svg_map) ? svg_map._scene : null;
        this._storey = (svg_map) ? svg_map._scene.storey : null;
        this._building = (svg_map) ? svg_map._scene.storey.building : null;
        this._controller = (svg_map) ? svg_map._controller : null;
        this._background_scene = (svg_map) ? svg_map._background_scene : null;
        this.is_transient = false;

        this._view_overlay = null;

        this.element_filter = null;
    }

    get_svg_map() {
        return this._map;
    }

    //***********************************************************************************
    //**** transaction refresh
    //***********************************************************************************
    transaction_refresh() {
    }

    push_transaction(transaction_name, transaction_code = '', callback = null) {
        this._building.transaction_manager.push_transaction(transaction_name, transaction_code, callback);
    }

    push_item_set(item, params, callback = null) {
        this._building.transaction_manager.push_item_set(item, params, callback);
    }

    /**Called when selection change. Must return true to become the current tool. */
    on_selection_change() {
        return false;
    }

    /**
     * Intended for 3d compatible tools. Must return true if the tool can edit the element
     * @param {{element: cn_element, storey: cn_storey}} storey_element
     * @returns {boolean}
     */
    can_edit(storey_element) {
        return false;
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
    }

    //***********************************************************************************
    /**
     * Close tool
     */
    close_tool() {
        // Cancels potential paste in progress
        this.call('paste_end');
        this.call('close');
    }

    refresh() {
        if (this._map) this._map.refresh();
        if (this._view_overlay) this._view_overlay.refresh();
    }

    //***********************************************************************************
    //**** Find mouseover vertex
    //***********************************************************************************

    find_mouseover_vertex(camera, p, vertices = null) {
        if (vertices == null) vertices = this._scene.vertices;
        var mouseover_vertex = null;
        var min_distance = 0;

        for (var i in vertices) {
            if (Math.abs(p[0] - vertices[i].position[0]) > camera.snap_world_distance) continue;
            if (Math.abs(p[1] - vertices[i].position[1]) > camera.snap_world_distance) continue;
            var dst = cn_dist(p, vertices[i].position);
            if (mouseover_vertex && dst >= min_distance) continue;
            mouseover_vertex = vertices[i];
            min_distance = dst;
        }
        return mouseover_vertex;
    }

    //***********************************************************************************
    //**** Find mouseover vertex
    //***********************************************************************************

    find_mouseover_wall(camera, p, walls = null) {
        if (walls == null)
            return this._scene.find_wall(p, camera.snap_world_distance);

        if (typeof (walls) != 'object') return null;

        for (var i in walls) {
            if (walls[i].contains(p, camera.snap_world_distance))
                return walls[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** Find mouseover line
    //***********************************************************************************

    find_mouseover_line(camera, p, lines = null) {
        if (lines == null)
            lines = this._scene.lines;
        if (typeof (lines) != 'object') return null;

        for (var i in lines) {
            if (lines[i].intersects(p, camera.screen_to_world_scale * 10) >= 0)
                return lines[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** Find mouseover vertex
    //***********************************************************************************

    find_mouseover_slab(camera, p, slabs = null) {
        if (slabs == null) slabs = this._scene.slabs;

        for (var i in slabs) {
            if (slabs[i].spaces[0] == null && slabs[i].spaces[1] == null) continue;
            if (slabs[i].contains(p))
                return slabs[i];
        }
        return null;
    }

    //***********************************************************************************
    //**** Check broken line
    //***********************************************************************************
    check_vertex_parallel(vertex, dir, forget_wall = null) {
        if (vertex == null) return true;
        for (var i in vertex.walls) {
            if (forget_wall && forget_wall == vertex.walls[i]) continue;
            var vtx = vertex.walls[i].other_vertex(vertex);
            var pdir = cn_sub(vtx.position, vertex.position);
            cn_normalize(pdir);
            if (cn_dist(dir, pdir) < 0.1) return false;
        }
        return true;
    }

    check_wall_parallel(wall, dir) {
        if (wall == null) return true;
        var wall_dir = cn_sub(wall.vertices[1].position, wall.vertices[0].position);
        cn_normalize(wall_dir);
        var wall_normal = cn_normal(wall_dir);
        if (Math.abs(cn_dot(dir, wall_normal)) < 0.1) return false;
        return true;
    }

    check_broken_line(walls) {
        var sz = walls.length;
        if (sz < 2) return true;

        //*** check no backward
        var dir0 = cn_sub(walls[sz - 1].vertices[1].position, walls[sz - 1].vertices[0].position);
        var dir1 = cn_sub(walls[sz - 2].vertices[1].position, walls[sz - 2].vertices[0].position);
        if (cn_size(cn_add(dir1, dir0)) < 0.1) return false;
        return true;
    }

    //***********************************************************************************
    //**** Copy / paste
    //***********************************************************************************
    copy_selection() {
        return false;
    };

    cut_selection() {
        return false;
    };

    paste_clipboard() {
        return false;
    };
}

