'use strict';

import { cn_extension_feature } from './cn_extension_feature';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** zone extension model
//***********************************************************************************
//***********************************************************************************


export class cn_zone_extension extends cn_extension_feature {

    /**
     * Tool zones extension
     * Ajoute d'autres types de zones que les zones par défaut (usage) :
     * format { property: <string de la propriété dans le building>, label: <string de label IHM>, icon: <string de ref de l'icone mdi> }
     *
     */
    constructor() {
        super(false);

        /**
         * Types de zones de base
         * @type {{ property: string, label_singular: string, label_plural: string, label_plural_short: string, icon: string }[]}
         */
        this.zones_tools_base = [
            { property: 'lot', label_singular: 'Lot', label_plural: 'Lots', label_plural_short: 'Lots', icon: 'land-plots-circle-variant' },
            { property: 'thermal', label_singular: 'Zone thermique', label_plural: 'Zones thermiques', label_plural_short: 'Thermiques', icon: 'thermometer' },
            { property: 'usage', label_singular: 'Zone d\'usage', label_plural: `Zones d'usage`, label_plural_short: 'Usages', icon: 'view-dashboard-outline' },
        ];

        /**
         * Types de zones supplémentaires
         * @type {{ property: string, label_singular: string, label_plural: string, label_plural_short: string, icon: string }[]}
         */
        this.zones_tools_addition = [];
    }

    /**
     * Returns zoning used by default :
     * - when instanciating zoning tool without defining zone type
     * - on building elements nomenclatures
     * - on space numerotation
     * - in BBP & IFC export
     *
     * Shall never return null.
     *
     * @return {{ property: string, label_singular: string, label_plural: string, label_plural_short: string, icon: string }}
     */
    get_default_zone_tool() {
        return this.zones_tools_base[0];
    }

    /**
     * @return {{ property: string, label_singular: string, label_plural: string, label_plural_short: string, icon: string }[]}
     */
    get_zones_tools() {
        return [
            ...this.zones_tools_base,
            ...this.zones_tools_addition,
        ];
    }

}


