'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_space  : Manipulation of space
//***********************************************************************************
//***********************************************************************************

import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_pastille } from './cn_pastille';
import { cn_add, cn_clone, cn_dist, cn_mul, cn_size, cn_sub } from '../utils/cn_utilities';
import { cn_background_map } from '../model/cn_background_map';
import { cn_camera } from './cn_camera';
import { cn_handler_rotation } from './cn_handler_rotation';
import { cn_background_map_selection_input, cn_number_input, cn_question_input, SEVERITY_QUESTION_WARNING } from './cn_inputs';
import { cn_edit_box } from './cn_edit_box';

export class cn_svg_tool_background_map extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     * @param map
     */
    constructor(map) {
        super(map);
        this.cn_svg_bm = null;
        this._camera = new cn_camera();

        this._mouse_position = [0, 0];

        this._box_size = [80, 20];

        this._mouseover = -1;
        this._selection = -1;
        this._move_handles = [];
        this._current_camera_scale = 0;

        this._edited_map = null;
        this._rotation_handle = null;
        this._edit_box = null;

        this._grabid = 0;
        this._mouseover_map = null;

        this._width_measure = [[0, 0], [0, 0]];
        this._height_measure = [[0, 0], [0, 0]];
        this._scale_measure = [[0, 0], [0, 0]];

    }

    //***********************************************************************************
    //**** transaction refresh
    //***********************************************************************************
    transaction_refresh() {
        super.transaction_refresh();
        this._terminate_edition();
    }

    _terminate_edition() {
        super._terminate_edition();
        this.set_edited_map(null);
    }

    //***********************************************************************************
    /**
     * sets edited map
     * @param {cn_background_map} map
     */
    set_edited_map(map) {
        if (map == this._edited_map) return;
        this._edited_map = map;
        this._rotation_handle = null;
        if (this._edited_map == null) return;

        this._rotation_handle = new cn_handler_rotation(this._edited_map.offset, this._edited_map.orientation, 1, 0.55 * cn_size(this._edited_map.get_world_size()), true);
        this._rotation_handle.on('change', () => {
            this.push_transaction('Rotation du fond de carte', '' + this._grabid + this._edited_map.ID);
            this.push_item_set(this._edited_map, 'orientation');
            this._edited_map.orientation = this._rotation_handle.angle;
            this._map.refresh_background_and_overlay();
            this._map.refresh_tool();
            this.call('orientation_change', this._edited_map.orientation);
        });
        this._rotation_handle._angle_callback = () => {
            const input = new cn_number_input('Rotation du fond de carte', this._edited_map.orientation);
            input.min_value = -180;
            input.max_value = 180;
            input.decimals = 1;
            input.unit = '°';
            while (input.value > 180) input.value -= 360;
            while (input.value < -180) input.value += 360;
            const all_background_maps = this._building.storeys.flatMap(it => it.background_maps);
            if (all_background_maps.length > 1) {
                input.checkbox_label = 'Appliquer à toutes les images';
                input.checked_hides_value = false;
            }
            input.callback = () => {
                this.push_transaction('Rotation du fond de carte');
                if (input.checkbox_status) {
                    all_background_maps.forEach(it => {
                        this.push_item_set(it, 'orientation');
                        it.orientation = input.value;
                    });
                } else {
                    this.push_item_set(this._edited_map, 'orientation');
                    this._edited_map.orientation = input.value;
                }
                this._map.refresh_background_and_overlay();
                this._map.refresh_tool();
                this.call('orientation_change', this._edited_map.orientation);
            };
            this.call('number_input', input);
        };

        //*** Edit box */
        this._edit_box = new cn_edit_box(this, [map], this._map.get_readonly());

        //*** Opacity button */
        const opacity_pastille = new cn_pastille([0, 0], 'opacity.svg');
        this._edit_box.add_pastille(opacity_pastille);
        opacity_pastille.svg_class = 'pastille_background white';
        opacity_pastille.title = 'Opacité';
        opacity_pastille.clicked = () => {
            const input = new cn_number_input('Opacité', this._edited_map.background_opacity * 100, '%', 0, 0, 100);
            input.callback = () => {
                this.push_transaction('Opacité du fond de carte', this._edited_map.ID);
                this.push_item_set(this._edited_map, 'background_opacity');
                this._edited_map.background_opacity = input.value / 100;
                this._map.refresh();
            };
            input.slider = true;
            this._map.call('number_input', input);
        };

        //*** Copy settings button */
        // -> displayed only if there are others background maps used in building (unused background maps are not taken into account)
        const other_maps = this._building.maps.filter(it => it.ID != this._edited_map.ID && this._building.is_used_background_map(it));
        if (other_maps.length && !this._map.get_readonly()) {
            const copy_settings_pastille = new cn_pastille([0, 0], 'cog_sync.svg');
            this._edit_box.add_pastille(copy_settings_pastille);
            copy_settings_pastille.svg_class = 'pastille_background white';
            copy_settings_pastille.title = 'Reprendre la mise en forme d\'un autre fond de carte';
            copy_settings_pastille.clicked = () => {

                const input = new cn_background_map_selection_input('Sélection du fond de carte de référence', other_maps);
                input.content = 'Sélectionnez le fond de carte dont vous souhaitez reprendre la mise en forme';
                input.callback = () => {
                    if (input.selection) {
                        this.push_transaction('Mise en forme fond de carte', this._edited_map.ID);
                        this.push_item_set(this._edited_map, 'orientation');
                        this.push_item_set(this._edited_map, 'scale');
                        this.push_item_set(this._edited_map, 'offset');
                        this._edited_map.orientation = input.selection.orientation;
                        this._edited_map.scale = input.selection.scale;
                        this._edited_map.offset = input.selection.offset;
                        this._map.refresh();
                    }
                };

                const others_maps_orientations = new Set(other_maps.map(it => it.orientation));
                const others_maps_scales = new Set(other_maps.map(it => it.scale));
                const others_maps_offsets = new Set(other_maps.map(it => it.offset));
                if (others_maps_orientations.size == 1 && others_maps_scales.size == 1 && others_maps_offsets.size == 1) {
                    // if only one other background map setting, apply it with user confirmation
                    const inputConfirm = new cn_question_input(other_maps.length > 1 ? 'Voulez-vous reprendre la mise en forme des autres fonds de carte' : 'Voulez-vous reprendre la mise en forme de l\'autre fond de carte');
                    inputConfirm.severity = SEVERITY_QUESTION_WARNING;
                    inputConfirm.callback = () => {
                        input.selection = other_maps[0];
                        input.callback();
                    }
                    this._map.call('question_input', inputConfirm);
                } else {
                    this._map.call('background_map_selection_input', input);
                }
            };
        }

        this._map.refresh();
    }

    /**
     * returns edited map
     * @return {cn_background_map}
     */
    get_edited_map() {
        return this._edited_map;
    }

    /**
     * Build a new background map
     * @param {string} image_id
     * @param {number} width
     * @param {number} height
     * @return {cn_background_map}
     */
    new_background_map(image_id, width, height) {
        this.push_transaction('Nouveau fond de carte');
        this.push_item_set(this._map._building, 'maps');
        const background_map = this._map._building.new_background_map(image_id, width, height);

        this.push_item_set(this._map._storey, 'background_maps');
        this._map._storey.background_maps.push(background_map);
        this.set_edited_map(background_map);
        this._map.refresh();
        return background_map;
    }

    /**
     * Returns the list of all building maps that are not on this storey
     *
     * @return {cn_background_map[]}
     */
    get_other_maps() {
        const storey_maps = this._map._storey.background_maps;
        const others = [];
        this._map._building.maps.forEach(it => {
            if (storey_maps.find(bm => bm.ID === it.ID) === undefined) {
                others.push(it);
            }
        })
        return others;
    }

    /**
     * Adds an existing background map to the current storey
     * @param {cn_background_map} map
     * @returns
     */
    insert_background_map(map) {
        if (this._map._storey.background_maps.indexOf(map) >= 0) return;

        this.push_transaction('Ajout de fond de carte');
        this.push_item_set(this._map._storey, 'background_maps');
        this._map._storey.background_maps.push(map);

        this.set_edited_map(map);
        this._map.refresh();
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    // open_tool() {
    // }

    draw(camera) {
        var html = '';

        //*** Draw contour of mouseover map */
        if (this._mouseover_map) {
            var pos = camera.world_to_screen(this._mouseover_map.offset);
            var sz = cn_mul(this._mouseover_map.get_world_size(), camera.world_to_screen_scale);
            html += `<rect class="map_contour_mouseover" x="${-sz[0] * 0.5}" y="${-sz[1] * 0.5}" width="${sz[0]}" height="${sz[1]}" transform="translate(${pos[0]},${pos[1]}) rotate(${-this._mouseover_map.orientation})" />`;
        }

        this._update_move_handles(camera);
        if (this._edited_map) {
            //*** draw rotation handle */
            this._rotation_handle.center = cn_clone(this._edited_map.offset);
            this._rotation_handle.angle = this._edited_map.orientation;
            this._rotation_handle.world_radius = 0.55 * cn_size(this._edited_map.get_world_size());

            html += this._rotation_handle.draw(camera);

            //*** Draw contour of edtied map */
            var pos = camera.world_to_screen(this._edited_map.offset);
            var sz = cn_mul(this._edited_map.get_world_size(), camera.world_to_screen_scale);
            html += `<rect class="map_contour_selected" x="${-sz[0] * 0.5}" y="${-sz[1] * 0.5}" width="${sz[0]}" height="${sz[1]}" transform="translate(${pos[0]},${pos[1]}) rotate(${-this._edited_map.orientation})" />`;

            //*** Draw reference line
            var rp0 = this._edited_map.to_world(this._edited_map.reference_points[0]);
            var rp1 = this._edited_map.to_world(this._edited_map.reference_points[1]);
            var p0 = camera.world_to_screen(rp0);
            var p1 = camera.world_to_screen(rp1);

            const p00 = this._edited_map.to_world([0, 0]);
            const p10 = this._edited_map.to_world([1, 0]);
            const p01 = this._edited_map.to_world([0, 1]);
            const p11 = this._edited_map.to_world([1, 1]);

            let xtraclass = (this._mouseover == 2) ? ' line_selected mouseover' : (this._selection == 2) ? ' line_selected selected' : '';
            html += '<line class=\'map_reference_line' + xtraclass + '\' x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' />';
            xtraclass = (this._mouseover == 0) ? ' line_selected mouseover' : (this._selection == 0) ? ' line_selected selected' : '';
            html += '<circle class=\'map_reference_point' + xtraclass + '\' cx=\'' + p0[0] + '\' cy=\'' + p0[1] + '\' r=\'5\'/>';
            xtraclass = (this._mouseover == 1) ? ' line_selected mouseover' : (this._selection == 1) ? ' line_selected selected' : '';
            html += '<circle class=\'map_reference_point' + xtraclass + '\' cx=\'' + p1[0] + '\' cy=\'' + p1[1] + '\' r=\'5\'/>';

            //*** Draw move handles
            this._move_handles.forEach(mh => {
                mh.position = this._edited_map.to_world(mh['map_position']);
                html += mh.draw(camera);
            });

            if (this._mouseover == 2) html += camera.draw_line_move_arrow(rp0, rp1);
            if (this._mouseover == 7) html += camera.draw_line_move_arrow(p00, p10);
            if (this._mouseover == 8) html += camera.draw_line_move_arrow(p10, p11);
            if (this._mouseover == 9) html += camera.draw_line_move_arrow(p11, p01);
            if (this._mouseover == 10) html += camera.draw_line_move_arrow(p01, p00);

            html += camera.draw_measure(p00, p10, this._width_measure, this._mouseover == 4);
            html += camera.draw_measure(p01, p00, this._height_measure, this._mouseover == 5);
            html += camera.draw_measure(rp0, rp1, this._scale_measure, this._mouseover == 6, 0, [], '', true);

            //*** draw edito box */
            html += this._edit_box.draw(camera);
        }

        return html;
    }

    //***********************************************************************************
    //**** Update offset buttons
    //***********************************************************************************
    _update_move_handles(camera) {
        if (this._edited_map == null) {
            this._move_handles = [];
            this._current_camera_scale = 0;
            return;
        }

        //*** camera scale didn't change ? */
        if (this._move_handles.length > 0 && this._current_camera_scale == camera.world_to_screen_scale) return;

        this._current_camera_scale = camera.world_to_screen_scale;

        var image_size = this._edited_map.get_world_size();
        var canvas_size = camera.get_world_size();
        var nx = 1 + Math.floor(2 * image_size[0] / canvas_size[0]);
        if (nx < 1) nx = 1;
        var ny = 1 + Math.floor(2 * image_size[1] / canvas_size[1]);
        if (ny < 1) ny = 1;

        var dx = 1 / nx;
        var dy = 1 / ny;

        this._move_handles = [];
        for (var i = 0; i < nx; i++) {
            var x = dx * (0.5 + i);
            for (var j = 0; j < ny; j++) {
                var y = dy * (0.5 + j);
                const proj = camera.world_to_screen(this._edited_map.to_world([x, y]));
                if (!proj || proj[0] < 0 || proj[1] < 0 || proj[0] > camera._width || proj[1] > camera._height) continue;
                var h = new cn_pastille([0, 0], 'arrow_all_white.svg');
                h['map_position'] = [x, y];
                this._move_handles.push(h);
            }
        }
    }

    //*****************************************************************
    //*** Clear move data
    //*****************************************************************
    clear_move() {
        this._mouseover = -1;
        this._mouseover_map = null;
        for (var k = 0; k < this._move_handles.length; k++) {
            this._move_handles[k].mouseover = false;
        }
        if (this._rotation_handle) this._rotation_handle.clear_move();
        if (this._edit_box) this._edit_box.clear_move();
    }

    //***********************************************************************************
    /**
     * click management
     */
    click(ev) {
        if (this._edit_box && this._edit_box.click(ev))
            return true;

        if (this._rotation_handle && this._rotation_handle.click(ev))
            return true;

        if (this._selection >= 4 && this._selection <= 6 && this._edited_map) {
            const input = new cn_number_input();
            const world_size = this._edited_map.get_world_size();
            if (this._selection == 4) {
                input.label = 'Largeur de la carte';
                input.value = world_size[0];
            } else if (this._selection == 5) {
                input.label = 'Longueur de la carte';
                input.value = world_size[1];
            } else {
                input.label = 'Longueur de la référence';
                const rp0 = this._edited_map.to_world(this._edited_map.reference_points[0]);
                const rp1 = this._edited_map.to_world(this._edited_map.reference_points[1]);
                input.value = cn_dist(rp0, rp1);
            }
            const initial_value = input.value;
            input.unit = 'm';
            input.decimals = 2;
            input.min_value = 0.1;
            input.max_value = 10000;
            const all_background_maps = this._building.storeys.flatMap(it => it.background_maps);
            if (all_background_maps.length > 1) {
                input.checkbox_label = 'Appliquer l\'échelle à toutes les images';
                input.checked_hides_value = false;
            }
            input.callback = () => {
                const coef = input.value / initial_value;
                if (world_size[0] * coef > 0.01 && world_size[1] * coef > 0.01) {
                    const new_scale = this._edited_map.scale * coef;
                    this.push_transaction('Dimensions du fond de carte');
                    if (input.checkbox_status) {
                        all_background_maps.forEach(it => {
                            this.push_item_set(it, 'scale');
                            it.scale = new_scale;
                        });
                    } else {
                        this.push_item_set(this._edited_map, 'scale');
                        this._edited_map.scale = new_scale;
                    }
                    this._current_camera_scale = -1;
                    this._map.refresh_background_and_overlay();
                    this._map.refresh_tool();
                }
            }

            this.call('number_input', input);
        }
        return true;
    }

    //***********************************************************************************
    /**
     * passive move management
     */
    move(ev) {
        this.clear_move();
        if (this._edit_box && this._edit_box.move(ev))
            return true;

        this._mouseover = this._find_mouseover(ev)
        if (this._mouseover < 0 && this._rotation_handle) this._rotation_handle.move(ev);
        return true;
    }

    //***********************************************************************************
    /**
     * Grab
     */
    grab(ev) {
        if (this._edit_box && this._edit_box.grab(ev))
            return true;

        this._grabid++;
        this._selection = -1;
        this.clear_move();
        this._mouseover = this._find_mouseover(ev);
        if (this._mouseover_map) {
            this.set_edited_map(this._mouseover_map);
            this.call('selection_change');
            return true;
        } else if (this._mouseover >= 0) {
            this._mouse_position = cn_clone(ev.mouse_world);
            this._selection = this._mouseover;
            if (this._selection == 11 && this._rotation_handle)
                this._rotation_handle.grab(ev);

            return true;
        } else
            return false;
    }

    drop(ev) {
        if (this._edit_box && this._edit_box.drop(ev))
            return true;

        if (this._selection >= 0) {
            if (this._selection == 11 && this._rotation_handle)
                return this._rotation_handle.drop(ev);
            return true;
        }

        return false;
    }

    drag(ev) {
        if (this._edit_box && this._edit_box.drag(ev))
            return true;

        if (this._selection >= 0 && this._edited_map) {
            var offset = cn_sub(ev.mouse_world, this._mouse_position);

            //*** Move the map */
            if (this._selection == 3) {
                this.push_transaction('Déplacement du fond de carte', this._edited_map.ID + this._grabid);
                this.push_item_set(this._edited_map, 'offset');
                this._edited_map.offset = cn_add(this._edited_map.offset, offset);
                this._map.refresh_background_and_overlay();
            }
            //*** move some of the reference points */
            else if (this._selection <= 2) {
                this.push_transaction('Déplacement des points de référence', this._edited_map.ID + this._grabid);
                this.push_item_set(this._edited_map, 'reference_points');
                var p0 = this._edited_map.to_world(this._edited_map.reference_points[0]);
                var p1 = this._edited_map.to_world(this._edited_map.reference_points[1]);
                if (this._selection != 1) p0 = cn_add(p0, offset);
                if (this._selection != 0) p1 = cn_add(p1, offset);
                this._edited_map.reference_points[0] = this._edited_map.to_image(p0);
                this._edited_map.reference_points[1] = this._edited_map.to_image(p1);
            }
            //*** resize the map */
            else if (this._selection >= 7 && this._selection <= 10) {
                var coef = 1;
                var offset = [0, 0]
                const img = this._edited_map.to_image(ev.mouse_world);
                if (this._selection == 7) {
                    coef = 1 - img[1];
                    offset = this._edited_map.to_world([0.5, 0.5 + 0.5 * img[1]]);
                } else if (this._selection == 8) {
                    coef = img[0];
                    offset = this._edited_map.to_world([0.5 + 0.5 * (img[0] - 1), 0.5]);
                } else if (this._selection == 9) {
                    coef = img[1];
                    offset = this._edited_map.to_world([0.5, 0.5 + 0.5 * (img[1] - 1)]);
                } else if (this._selection == 10) {
                    coef = 1 - img[0];
                    offset = this._edited_map.to_world([0.5 + 0.5 * img[0], 0.5]);
                }
                coef *= this._edited_map.scale;
                if (coef * this._edited_map.image_size[0] > 0.01 && coef * this._edited_map.image_size[1] > 0.01) {
                    this.push_transaction('Dimensions du fond de carte', this._edited_map.ID + this._grabid);
                    this.push_item_set(this._edited_map, ['scale', 'offset']);
                    this._edited_map.scale = coef;
                    this._edited_map.offset = offset;
                }
                this._map.refresh_background_and_overlay();
            } else if (this._selection == 11 && this._rotation_handle)
                return this._rotation_handle.drag(ev);

            this._mouse_position = cn_clone(ev.mouse_world);
            return true;
        }
        return true;
    }

    //***********************************************************************************
    //**** Check what is under mouse
    //***********************************************************************************
    _find_mouseover(ev) {

        function mouseover_measure(measure) {
            if (Math.abs(measure[0] - ev.mouse_screen[0]) > 30) return false;
            if (Math.abs(measure[1] - ev.mouse_screen[1]) > 10) return false;
            return true;
        }

        if (this._edited_map) {
            var p0 = this._edited_map.to_world(this._edited_map.reference_points[0]);
            var p1 = this._edited_map.to_world(this._edited_map.reference_points[1]);
            var p00 = this._edited_map.to_world([0, 0]);
            var p10 = this._edited_map.to_world([1, 0]);
            var p01 = this._edited_map.to_world([0, 1]);
            var p11 = this._edited_map.to_world([1, 1]);

            //*** mouse on one of the reference points ? */
            if (ev.camera.show_measures) {
                //*** mouse on one of the measures ?  */
                if (mouseover_measure(this._width_measure)) return 4;
                if (mouseover_measure(this._height_measure)) return 5;
            }

            if (ev.camera.on_vertex(ev.mouse_world, p0)) return 0;
            if (ev.camera.on_vertex(ev.mouse_world, p1)) return 1;
            if (mouseover_measure(this._scale_measure)) return 6;

            //*** mouse on one of the move handles ? */
            for (var k = 0; k < this._move_handles.length; k++) {
                this._move_handles[k].mouseover = this._move_handles[k].contains(ev.mouse_world, ev.camera);
                if (this._move_handles[k].mouseover) return 3;
            }

            //*** Mouse on one of the edges ? */
            if (ev.camera.on_edge(ev.mouse_world, p0, p1)) return 2;
            if (ev.camera.on_edge(ev.mouse_world, p00, p10)) return 7;
            if (ev.camera.on_edge(ev.mouse_world, p10, p11)) return 8;
            if (ev.camera.on_edge(ev.mouse_world, p11, p01)) return 9;
            if (ev.camera.on_edge(ev.mouse_world, p01, p00)) return 10;

            if (this._rotation_handle && this._rotation_handle.move(ev)) return 11;
        }

        if ((this._edited_map == null || !this._edited_map.contains(ev.mouse_world)) &&
            !this._map._storey.draw_previous_storey && !this._map._storey.draw_exterior) {
            for (var i in this._map._storey.background_maps) {
                if (!this._map._storey.background_maps[i].contains(ev.mouse_world)) continue;
                this._mouseover_map = this._map._storey.background_maps[i];
                return -1;
            }
        }

        return -1;
    }

}

