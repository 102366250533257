import { Pipe, PipeTransform } from '@angular/core';
import { AdditionnalHeater } from '../model/additionnal-heater.model';

@Pipe({
  name: 'addedHeaterName',
})
export class AddedHeaterNamePipe implements PipeTransform {
  transform(heater: AdditionnalHeater): string {
    return AdditionnalHeater.getHeaterName(heater);
  }
}
