'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Rotation handler
//***********************************************************************************
//***********************************************************************************

import { cn_event_handler } from './cn_event_handler';
import { cn_mouse_event } from './cn_mouse_event';
import {
    cn_add,
    cn_cart,
    cn_clone,
    cn_dot,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_polar,
    cn_sub,
    cnx_add,
    cnx_clone,
    cnx_cross,
    cnx_mul,
    cnx_normalize,
    cnx_sub
} from '../utils/cn_utilities';
import { cn_camera } from './cn_camera';

export class cn_handler_rotation extends cn_event_handler {
    //*****************************************************************
    /**
     * Constructor
     * @param {number[]} center : center of rotation
     * @param {number} angle : current angle
     * @param {number} snap_angle : angle snap, in deegrees
     * @param {number} world_radius : radius, in world metrics. If omitted or <= 0, will used screen radius.
     * @param {boolean} handle_full_circle : If true, allows the user to handle any point of the circle, and displays an angle pastille.
     * @param {function} angle_callback : if defined, called when click on the angle pastille
     */
    constructor(center, angle, snap_angle = 5, world_radius = 0, handle_full_circle = false, angle_callback = null) {
        super();
        this.center = cn_clone(center);
        this.angle = angle;
        this.world_radius = world_radius;
        this.handle_full_circle = handle_full_circle;

        this._snap_segments = [];

        this.snap_angle = snap_angle;

        this._mouseover = false;
        this._mouseover_pastille = false;
        this._grabbed = false;
        this._grab_center = false;
        this._grab_local = [0, 0];
        this._grab_mouse = [0, 0];
        this._radius = 80;
        this.altitude = 0;

        this.plane_normal = [0, 0, 1];

        this._angle_callback = angle_callback;

        this._best_segment = null;
    }

    //*****************************************************************
    /**
     * Draw the handler
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        var html = '';

        //*** Draw rotation handle */
        const world_center = cnx_clone(this.center, this.altitude);
        var p = camera.world_to_screen(world_center);
        if (!p) return html;

        var add_classes = (this._mouseover) ? ' mouseover' : ' selected';

        //*** Draw rotation circle */
        const world_radius = (this.world_radius > 0) ? this.world_radius : this._radius / camera.world_to_screen_scale;
        const screen_radius = world_radius * camera.world_to_screen_scale;
        ;
        var circle_class = (this.handle_full_circle) ? 'paste_circle' : 'disk_handle_circle';
        if (this.handle_full_circle && this._mouseover) circle_class += ' mouseover';
        if (camera.is_3d()) {
            html += camera.draw_3d_circle(world_center, world_radius, this.plane_normal, circle_class);
            var dx = [1, 0, 0];
            var dy = [0, 1, 0];
            if (this.plane_normal[2] < 0.99) {
                dy = cnx_cross(this.plane_normal, dx);
                cnx_normalize(dy);
                dx = cnx_cross(dy, this.plane_normal);
            }
            const cart = cn_cart([1, this.angle * Math.PI / 180]);
            this._angle_position = cnx_add(world_center, cnx_add(cnx_mul(dx, cart[0] * world_radius), cnx_mul(dy, cart[1] * world_radius)));
        } else {
            if (screen_radius < 2 * (camera._width + camera._height))
                html += '<circle class=\'' + circle_class + '\' cx=\'' + p[0] + '\' cy=\'' + p[1] + '\' r=\'' + screen_radius + '\'/>';
            this._angle_position = cnx_clone(cn_add(this.center, cn_mul(cn_cart([1, this.angle * Math.PI / 180]), world_radius)), this.altitude)
        }

        //*** Draw handle */
        var pa = camera.world_to_screen(this._angle_position);
        if (pa) {
            if (!this.handle_full_circle) {
                html += '<line class=\'disk_handle_line' + add_classes + '\' x1=\'' + p[0] + '\' y1=\'' + p[1] + '\' x2=\'' + pa[0] + '\' y2=\'' + pa[1] + '\' />';
                html += '<circle class=\'handle_vertex' + add_classes + '\' cx=\'' + pa[0] + '\' cy=\'' + pa[1] + '\' r=\'5\'/>';
            } else {
                const mouseover = (this._mouseover_pastille) ? ' mouseover' : '';
                html += '<circle class=\'angle_label_fill' + mouseover + '\' cx=\'' + pa[0] + '\' cy=\'' + pa[1] + '\' r=\'20\' />';
                var aa = this.angle;
                while (aa > 180) aa -= 360;
                while (aa < -180) aa += 360;
                html += '<text class=\'angle_label_text\' x=\'' + pa[0] + '\' y=\'' + pa[1] + '\'>' + aa.toFixed(1) + ' °</text>';
            }
        }
        this._find_snap_angle(this.angle);
        if (this._best_segment) {
            var sc0 = camera.world_to_screen(cnx_clone(this._best_segment[0], this.altitude));
            var sc1 = camera.world_to_screen(cnx_clone(this._best_segment[1], this.altitude));
            if (sc0 && sc1) {
                var dir = cn_sub(this._best_segment[1], this._best_segment[0]);

                html += '<line class=\'snap_parallel\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
                var ortho = cn_normal(dir);
                cn_normalize(ortho);
                var x = cn_dot(cn_sub(this._best_segment[0], this.center), ortho);
                sc0 = camera.world_to_screen(cnx_clone(this.center, this.altitude));
                sc1 = camera.world_to_screen(cnx_clone(cn_add(this.center, cn_mul(ortho, x)), this.altitude));
                if (sc0 && sc1)
                    html += '<line class=\'snap_parallel\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
            }
        }
        return html;
    }

    //*****************************************************************
    /**
     * Clear move data
     */
    clear_move() {
        this._mouseover = false;
        this._mouseover_pastille = false;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    click(ev) {
        this.move(ev);
        if (this._mouseover_pastille) {
            this._angle_callback();
            return true;
        }
        return false;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    move(ev) {
        this._manage_3d(ev);
        this.clear_move();

        if (this.handle_full_circle) {
            if (this._angle_callback)
                this._mouseover_pastille = ev.camera.on_vertex(ev.mouse_world, this._angle_position, 20);

            const world_radius = (this.world_radius > 0) ? this.world_radius : this._radius / ev.camera.world_to_screen_scale;
            const center = cnx_clone(this.center, this.altitude);
            var d = cnx_sub(ev.mouse_world, center);
            cnx_normalize(d);
            d = cnx_add(center, cnx_mul(d, world_radius));
            this._mouseover = ev.camera.on_vertex(ev.mouse_world, d);
            return this._mouseover || this._mouseover_pastille;
        } else {
            //*** Mouse over the angle ?  */
            if (ev.camera.on_vertex(ev.mouse_world, this._angle_position)) {
                this._mouseover = true;
                return true;
            }
        }

        return false;
    }

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    grab(ev) {
        this._manage_3d(ev);
        this._grab_start = cn_clone(ev.mouse_world);
        this._grab_angle = this.angle;
        return this.move(ev);
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    drag(ev) {
        this._manage_3d(ev);

        //*** Move angle */
        if (!this._mouseover) return false;

        var orientation = 0;
        if (this.handle_full_circle) {
            var angle0 = cn_polar(cn_sub(this._grab_start, this.center))[1];
            var angle1 = cn_polar(cn_sub(ev.mouse_world, this.center))[1];
            this._grab_angle += (angle1 - angle0) * 180 / Math.PI;
            orientation = this._grab_angle;
            this._grab_start = cn_clone(ev.mouse_world);
        } else {
            var delta = cn_polar(cn_sub(ev.mouse_world, this.center));
            orientation = delta[1] * 180 / Math.PI;
        }

        this._best_segment = null;
        this.angle = this._find_snap_angle(orientation);
        this.call('change');
        return true;
    }

    _manage_3d(mouse_event) {
        if (!mouse_event.camera.is_3d()) return;
        mouse_event.move_to_plane([this.center[0], this.center[1], this.altitude], this.plane_normal);
    }

    _find_snap_angle(orientation) {
        this._best_segment = null;
        var threshold = this.snap_angle;
        var snap_angle = 0;
        const ssa = [90, -90, 0, 180];
        for (var niter = 0; niter < 4; niter++) {
            for (var ns = 0; ns < this._snap_segments.length; ns++) {
                var a = cn_polar(cn_sub(this._snap_segments[ns][0], this._snap_segments[ns][1]))[1] * 180 / Math.PI - ssa[niter];
                var da = orientation - a;
                while (da > 180) da -= 180;
                while (da <= -180) da += 180;

                var tt = Math.abs(da);
                if (tt >= threshold) continue;

                this._best_segment = this._snap_segments[ns];
                threshold = tt;
                snap_angle = orientation - da;
            }
            if (this._best_segment) return snap_angle;
        }
        return this.snap_angle * Math.round(orientation / this.snap_angle);
    }
}

