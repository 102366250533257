import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Project } from '../model/project.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {
  public resourceUrl = environment.apiUrl + '/projects';

  constructor(private http: HttpClient) {}

  // PROJECT
  getProject(projectId: string): Observable<Project> {
    return this.http.get<Project>(`${this.resourceUrl}/${projectId}`);
  }

  getAllProjects(params: HttpParams) {
    return this.http.get<Project[]>(this.resourceUrl, { params, observe: 'response' });
  }

  upsertProject(project: Project) {
    return this.http.post<Project>(this.resourceUrl, project);
  }

  deleteProject(projectId: string) {
    return this.http.delete<Project>(`${this.resourceUrl}/${projectId}`);
  }

  updatePinnedStatus(projectId: string, pinned: boolean) {
    return this.http.post(`${this.resourceUrl}/${projectId}/pinned`, { pinned });
  }

  askForSupport(projectId: String, comment: String): Observable<any> {
    return this.http.post(`${this.resourceUrl}/${projectId}/support`, { comment: comment });
  }
}
