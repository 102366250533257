'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_ifc converter :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** List projects
//***********************************************************************************

import { cn_opening } from '../../model/cn_opening';
import { cn_opening_type } from '../../model/cn_opening_type';
import { cn_dist, cn_dot, cn_sub } from '../cn_utilities';
import { CN_MIDDLE, cn_wall } from '../../model/cn_wall';
import { cn_vertex } from '../../model/cn_vertex';
import { cn_material } from '../../model/cn_material';
import { cn_wall_type } from '../../model/cn_wall_type';
import { cn_building } from '../../model/cn_building';
import { logger } from '../cn_logger';

//***********************************************************************************
//**** Read
//***********************************************************************************

export function cn_from_magicplan(mp_root) {
    try {
        var reader = new cn_magicplan_reader(mp_root);
        return reader.read();
    } catch (err) {
        console.error(err);
        return null;
    }
}

//***********************************************************************************
//**** Internal class
//***********************************************************************************

class cn_magicplan_reader {
    constructor(mp_root) {
        this._root = mp_root;
    }

    //***********************************************************************************
    //**** Main function
    //***********************************************************************************
    read() {
        var building = new cn_building();
        this.building = building;
        var mp_plan = this._root.getElementsByTagName('plan')[0];
        var mp_storeys = [];
        for (var i = 0; i < mp_plan.childNodes.length; i++) {
            if (mp_plan.childNodes[i].tagName == 'floor')
                mp_storeys.push(mp_plan.childNodes[i]);
        }

        if (mp_storeys.length == 0) throw 'No storeys in file';
        for (var i = 0; i < mp_storeys.length - 1; i++)
            building.duplicate_storey(building.storeys[0].ID);

        //**** Wall types
        var interior_wall_type = new cn_wall_type();
        interior_wall_type.thickness = parseFloat(mp_plan.getAttribute('interiorWallWidth'));
        interior_wall_type.category = 'generic';
        interior_wall_type.layers = [new cn_material('Inconnu', 'unknown', interior_wall_type.thickness)];
        building.add_element_type(interior_wall_type);

        var exterior_wall_type = new cn_wall_type();
        exterior_wall_type.thickness = parseFloat(mp_plan.getAttribute('exteriorWallWidth'));
        exterior_wall_type.category = 'generic';
        exterior_wall_type.layers = [new cn_material('Inconnu', 'unknown', exterior_wall_type.thickness)];
        building.add_element_type(exterior_wall_type);

        for (var s = 0; s < mp_storeys.length; s++) {
            logger.log('reading storey ' + s, mp_storeys[s]);
            var storey = building.storeys[s];
            var scene = storey.scene;

            //*** scan symbol instances
            var symbol_instances = mp_storeys[s].getElementsByTagName('symbolInstance');
            var instance_hash = [];
            for (var nsi = 0; nsi < symbol_instances.length; nsi++) {
                var id = symbol_instances[nsi].getAttribute('id');
                if (id == '' || id == null) continue;
                var symbol = symbol_instances[nsi].getAttribute('symbol');
                if (symbol == '' || symbol == null) continue;
                var symbol_instance = {};
                symbol_instance.symbol = symbol;
                symbol_instance.id = id;
                var values = symbol_instances[nsi].getElementsByTagName('value');
                for (var nv = 0; nv < values.length; nv++) {
                    var key = values[nv].getAttribute('key');
                    if (key == 'wallItemHeight')
                        symbol_instance.height = parseFloat(values[nv].textContent);
                    else if (key == 'wallItemDistanceToFloor')
                        symbol_instance.z = parseFloat(values[nv].textContent);
                }
                instance_hash[id] = symbol_instance;
            }

            //*** Scan exploded data
            var explodeds = mp_storeys[s].getElementsByTagName('exploded');
            if (explodeds.length == 0)
                throw 'No exploded data in storey ' + s;
            var exploded = explodeds[0];
            var walls = exploded.getElementsByTagName('wall');
            logger.log(walls);

            //*** Build walls and vertices
            for (var nw = 0; nw < walls.length; nw++) {
                var w = walls[nw];
                var vertices = [null, null];
                for (var k = 0; k < 2; k++) {
                    var point = w.childNodes[k];
                    var pnt = [parseFloat(point.getAttribute('x')), -parseFloat(point.getAttribute('y'))];
                    for (var v in scene.vertices) {
                        if (cn_dist(pnt, scene.vertices[v].position) > 0.01) continue;
                        vertices[k] = scene.vertices[v];
                        break;
                    }
                    if (vertices[k] == null) {
                        vertices[k] = new cn_vertex(pnt);
                        scene.vertices.push(vertices[k]);
                    }
                }
                if (vertices[0] != vertices[1]) {
                    var wall = new cn_wall(vertices[0], vertices[1], interior_wall_type, CN_MIDDLE, scene);
                    scene.walls.push(wall);
                }
            }

            //*** Build contours
            scene.full_update(true);

            //*** Fix walls
            for (var iw in scene.walls) {
                if (scene.walls[iw].spaces[0].outside || scene.walls[iw].spaces[1].outside)
                    scene.walls[iw].wall_type = exterior_wall_type;
            }

            //*** Build doors
            var doors = exploded.getElementsByTagName('door');
            logger.log('Parsing ' + doors.length + ' doors');
            for (var nd = 0; nd < doors.length; nd++) {
                this.add_opening('door', doors[nd], instance_hash, scene);
            }

            //*** Build windows
            var windows = exploded.getElementsByTagName('window');
            logger.log('Parsing ' + windows.length + ' windows');
            for (var nd = 0; nd < windows.length; nd++) {
                this.add_opening('window', windows[nd], instance_hash, scene);

            }
        }
        return building;
    }

    //***********************************************************************************
    //**** create an opening
    //***********************************************************************************
    add_opening(category, opening, instance_hash, scene) {
        var symbol_instance_id = opening.getAttribute('symbolInstance');
        if (typeof (instance_hash[symbol_instance_id]) == 'undefined') return;

        var symbol_instance = instance_hash[symbol_instance_id];
        if (typeof (symbol_instance.height) == 'undefined') {
            var hh = parseFloat(opening.getAttribute('height'));
            if (hh) symbol_instance.height = hh;
        }

        var v0 = [parseFloat(opening.getAttribute('x1')), -parseFloat(opening.getAttribute('y1'))];
        var v1 = [parseFloat(opening.getAttribute('x2')), -parseFloat(opening.getAttribute('y2'))];

        //*** search for wall under vertices
        var wall = null;
        var x0, x1;
        for (var i in scene.walls) {
            var w = scene.walls[i];
            var d0 = cn_sub(v0, w.vertices[0].position);
            if (Math.abs(cn_dot(d0, w.bounds.normal)) > 0.08) continue;
            x0 = cn_dot(d0, w.bounds.direction);
            if (x0 < 0 || x0 >= w.bounds.length) continue;

            var d1 = cn_sub(v1, w.vertices[0].position);
            if (Math.abs(cn_dot(d1, w.bounds.normal)) > 0.08) continue;
            x1 = cn_dot(d1, w.bounds.direction);
            if (x1 < 0 || x1 >= w.bounds.length) continue;

            wall = w;
            break;
        }
        if (wall == null) return;
        var width = Math.abs(x1 - x0);
        if (width < 0.1)
            return;

        var element_types = (category == 'door') ? this.building.get_door_types() : this.building.get_window_types();
        var element_type = null;
        for (var i in element_types) {
            if (typeof (element_types[i]['magicplan']) == 'undefined') continue;
            if (element_types[i]['magicplan'] != symbol_instance.id) continue;
            if (Math.abs(element_types[i]['width'] - width) > 0.01) continue;
            element_type = element_types[i];
        }

        if (element_type == null) {
            element_type = new cn_opening_type();
            element_type.category = category;
            element_type.width = width;
            element_type['magicplan'] = symbol_instance.id;
            element_type.panels = 1;
            var mp_type = symbol_instance.symbol;

            if (category == 'door') {
                element_type.z = 0;
                element_type.height = 2;
                element_type.name = 'Porte ' + symbol_instance.id;
            } else
                element_type.name = 'Fenêtre ' + symbol_instance.id;

            if (typeof (symbol_instance.height) == 'number')
                element_type.height = symbol_instance.height;
            if (typeof (symbol_instance.z) == 'number')
                element_type.z = symbol_instance.z;
            this.building.add_element_type(element_type);

            //***** Magic plan doors
            if (mp_type == 'doorbypass') {
                element_type.opening = 'sliding';
                element_type.panels = 2;
            } else if (mp_type == 'doordoublefolding') {
                element_type.panels = 3;
            } else if (mp_type == 'doordoublehinged') {
                element_type.panels = 2;
            } else if (mp_type == 'doordoublesliding') {
                element_type.opening = 'sliding';
                element_type.panels = 2;
            } else if (mp_type == 'doorfolding') {
                element_type.panels = 2;
            } else if (mp_type == 'doorfrench') {
                element_type.frame = 'alu';
                element_type.glazing = 'double';
                element_type.panels = 2;
            } else if (mp_type == 'doorgarage') {
                element_type.opening = 'vertical';
            } else if (mp_type == 'doorglass') {
                element_type.frame = 'alu';
                element_type.glazing = 'double';
            } else if (mp_type == 'doorhinged') {
                element_type.glazing = 'none';
            } else if (mp_type == 'dooropening') {
                element_type.opening = 'none';
            } else if (mp_type == 'doorpocket') {
                element_type.opening = 'sliding';
            } else if (mp_type == 'doorslidingglass') {
                element_type.frame = 'alu';
                element_type.opening = 'sliding';
                element_type.panels = 2;
            } else if (mp_type == 'doorswing') {
            }

            //***** Magic plan windows
            else if (mp_type == 'windowawning') {
                element_type.opening = 'vertical';
                element_type.panels = 2;
            } else if (mp_type == 'windowbay') {
                element_type.panels = 3;
            } else if (mp_type == 'windowbow') {
                element_type.panels = 3;
            } else if (mp_type == 'windowcasement') {
                element_type.panels = 1;
                element_type.opening = 'french';
            } else if (mp_type == 'windowfixed') {
                element_type.panels = 1;
                element_type.opening = 'none';
            } else if (mp_type == 'windowfrench') {
                element_type.panels = 2;
                element_type.opening = 'french';
            } else if (mp_type == 'windowhopper') {
                element_type.panels = 2;
                element_type.opening = 'vertical';
            } else if (mp_type == 'windowhung') {
                element_type.panels = 2;
                element_type.opening = 'vertical';
            } else if (mp_type == 'windowsliding') {
                element_type.panels = 2;
                element_type.opening = 'sliding';
            } else
                logger.log('untreated magicplan type : ', mp_type);
        }

        var opening0 = new cn_opening(element_type);
        opening0.wall = wall;
        opening0.position = (x0 < x1) ? x0 : x1;
        wall.openings.push(opening0);
    }
}


