// @ts-nocheck
'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_tool markers : a tool to create markers in 3D
//***********************************************************************************
//***********************************************************************************

import { cn_marker } from '../model/cn_marker';
import { cn_contour } from '../model/cn_contour';
import { cnx_clone } from '../utils/cn_utilities';
import { cn_camera } from './cn_camera';
import { cn_storey } from '../model/cn_storey';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_polygon_handler } from './cn_polygon_handler';
import { cn_marker_handler } from './cn_marker_handler';
import { cn_freeform_handler } from './cn_freeform_handler';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_roof } from '../model/cn_roof';
import { CN_CURRENT_DATE } from '../utils/cn_transaction_manager';
import { logger } from '../utils/cn_logger';

export class cn_svg_tool_marker_creation extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     */
    constructor(map) {
        super(map);

        /**
         * Marker mode :
         * 0 - simple marker
         * 1 - marker on rectangular zone
         */
        this._marker_mode = 0;
        this._edition_handler = null;
        this._handler = null;
        this._ghost = null;
        this._display_creation = false;
        this._grabbed_edition = false;

        this._draw_head = true;
        this._draw_line = true;
        this._draw_tail = true;

        this._type = '';

        this._force_freeform = false;

        //*** Parent defined validator */
        // @ts-ignore
        this.check_marker = () => true;

        this._last_creation = null;

        //this.accept_all_events = true;
        this.element_filter = element => element.constructor == cn_marker;
    }

    //***********************************************************************************
    /**
     * Finds another marker under the mouse.
     * TODO: derivate this in order to manage marker derivates
     * @param {cn_mouse_event} mouse_event
     */
    _find_marker(mouse_event) {
        if (this._view_overlay) {
            var elt = this._view_overlay.find_mouse_element(mouse_event);
            if (!elt) return false;
            return (elt.element instanceof cn_marker);
        }
        if (this._map) {
            return this._map._storey.find_marker(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
        }
        return false;
    }

    set_type(type) {
        this._type = type;
        if (this._ghost) this._ghost.set_type(type);
    }

    //***********************************************************************************
    /**
     * Builds a new marker
     * TODO: derivate this in order to manage marker derivates
     * @param {cn_storey} storey
     * @returns {cn_marker}
     */
    _build_marker(storey) {
        const marker = new cn_marker(storey, this._type);
        marker.label = 'Nouvelle annotation';
        return marker;
    }

    //***********************************************************************************
    /**
     * inserts a new marker (with transaction management)
     * TODO: derivate this in order to manage marker derivates
     */
    _insert_marker(marker) {
        this.push_transaction('Création d\'annotation');
        this.push_item_set(marker.storey, 'markers');
        marker.set_date(['shape'], CN_CURRENT_DATE);
        marker.storey.markers.push(marker);
    }

    set_force_freeform(is_active) {
        this._force_freeform = is_active;
    }

    //***********************************************************************************
    //**** Open tool
    //***********************************************************************************
    open_tool() {
        super.open_tool();
        this._initialize_creation();
        if (this._scene && this._scene.constructor !== cn_roof) {
            this._map._storey.update_slabs();
            this._old_slabs = this._scene.slabs;
            this._scene.slabs = this._map._storey.slabs;
        }
    }

    close_tool() {
        if (this._scene && this._scene.constructor !== cn_roof) {
            this._scene.slabs = this._old_slabs;
        }
        super.close_tool();
    }

    //***********************************************************************************
    /**
     * Returns current market mode :
     * - 0 : simple marker
     * - 1 : area marker
     * @returns {number}
     */
    get_marker_mode() {
        return this._marker_mode;
    }

    //***********************************************************************************
    /**
     * Sets current market mode :
     * - 0 : simple marker
     * - 1 : area marker
     * - 2 : polygon marker
     * @param {number} mode
     */
    set_marker_mode(mode) {
        if (this._marker_mode == mode) return;
        this._marker_mode = mode;
        this._initialize_creation();
    }

    //*****************************************************************
    //*** Must return 'true' if wa are in modal creation.
    //*****************************************************************
    is_creating() {
        return (this._handler && this._handler.is_creating());
    }

    //***********************************************************************************
    //**** transaction refresh
    //***********************************************************************************
    transaction_refresh() {
        this._terminate_edition();
    }

    //***********************************************************************************
    /**
     * Draws  specific svg for the tool. Returns svg string
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        if (this._handler) this._handler.visible = (this._focus_handler == this._handler);
        var html = super.draw(camera);

        if (this._ghost && this._ghost.element && this._handler && this._handler.visible) {
            html += '<g opacity=\'' + 0.5 + '\'>' + this._ghost.draw(camera, [''], true) + '</g>';
            //html += this._ghost.element.draw(camera, ["mouseover"])
        }

        return html;
    }

    //***********************************************************************************
    /** clear move effects
     */
    clear_move() {
        if (this._ghost)
            this._ghost.element = null;
        super.clear_move();
    }

    //***************************************************************
    /**
     * Initialize creation, depending on mode
     */
    _initialize_creation() {
        if (this._handler) {
            const index = this._handlers.indexOf(this._handler);
            if (index >= 0) this._handlers.splice(index, 1);
        }
        this._ghost = null;
        if (this._marker_mode == 0)
            this._initialize_freeform_handler();
        else
            this._initialize_shape_handler();
    }

    _allow_freeform_marker() {
        return true;
    }

    //***************************************************************
    _initialize_shape_handler() {
        if (this._marker_mode == 1)
            this._handler = cn_polygon_handler.create_rectangle(this, 1);
        else
            this._handler = cn_polygon_handler.create_closed_polygon(this);
        this._handler.display_measures = false;
        this._handlers.unshift(this._handler);
        this._ghost = null;
        const obj = this;

        //*** event filter */
        this._handler.allow_creation = mouse_event => !obj._find_marker(mouse_event)

        //*** Callback upon creation */
        this._handler.on('start_creation', () => {
            obj._terminate_edition();
        });

        //*** Callback upon creation */
        this._handler.on('end_creation', mouse_event => {

            if (obj._handler == null) return;

            const is_3d = mouse_event.camera.is_3d();

            var new_marker = obj._build_marker(obj._handler.creation_storey);

            new_marker.shape = new cn_contour([], new_marker);
            new_marker.set_shape_3d(obj._handler.vertices, is_3d);
            new_marker.update();

            new_marker.get_scene().update_deep();

            if (obj._view_overlay) {
                obj._view_overlay.refresh_3d();
            }

            obj._initialize_creation();
            obj._marker_created(new_marker);
        });

        //*** Callback upon change */
        this._handler.check_change = () => {

            //*** As soon as creation has started, we stop editing previous marker */
            if (obj._handler && obj._handler.is_creating())
                obj._terminate_edition();

            return true;
        };
    }

    //***************************************************************
    _initialize_freeform_handler() {
        this._handler = new cn_freeform_handler(50, 20);
        this._handlers.unshift(this._handler);

        this._ghost = null;
        var obj = this;

        //*** event filter */
        this._handler.allow_creation = mouse_event => {
            return (!obj._find_marker(mouse_event));
        }

        this._handler.allow_freeform = () => {
            return this._allow_freeform_marker() && (!this._type || this._type === 'eraser');
        }

        //*** Callback upon creation */
        this._handler.on('start_creation', () => {
            obj._terminate_edition();
        });

        this._handler.on('change', mouse_event => {

            // @ts-ignore
            if (obj._handler.cursor && obj._handler.vertices.length <= 1) {
                if (!obj._ghost || obj._ghost.storey != obj._handler.creation_storey)
                    obj._ghost = obj._build_marker(obj._handler.creation_storey);

                //*** check filter */
                obj._ghost.place_from_mouse(mouse_event);
                if (obj._ghost.element && !obj.check_marker(obj._ghost)) {
                    obj._ghost.element = null;
                    obj._ghost.position = cnx_clone(mouse_event.mouse_world);
                    if (obj._ghost.config.head || obj._ghost.config.line) {
                        obj._ghost.normal = [0.707, -0.707, 0];
                    } else {
                        obj._ghost.normal = [0, 0, 0];
                    }
                }
            } else {
                obj._ghost = null;
            }
        });

        //*** Callback upon creation */
        this._handler.on('end_creation', mouse_event => {
            obj._ghost = null;

            if (obj._handler.vertices.length <= 0) {
                obj._initialize_creation();
                return;
            }

            var new_marker = obj._build_marker(obj._handler.creation_storey);

            if (obj._handler.vertices.length == 1) {
                if (obj._force_freeform) {
                    obj._initialize_creation();
                    return;
                }
                new_marker.place_from_mouse(mouse_event);
                if (!obj._check_new_marker_creation(new_marker)) {
                    obj._initialize_creation();
                    return;
                }
            } else {
                const is_3d = mouse_event.camera.is_3d();
                new_marker.shape = new cn_contour([], new_marker);
                new_marker.set_shape_3d(obj._handler.vertices, is_3d);
            }

            new_marker.get_scene().update_deep();

            obj._initialize_creation();
            obj._marker_created(new_marker);
        });
    }

    /**
     * Called when a new marker is created
     * @param {cn_marker} marker
     */
    _marker_created(marker) {
        this._insert_marker(marker);
        this._initiate_edition([marker]);
        const input = this._edition_handler.build_marker_input(marker, false);
        this.refresh();
        if (this._view_overlay)
            this._view_overlay.refresh_3d();
        this.call('marker_input', input);
    }

    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        if (this._map)
            return this._map._storey.find_marker(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
        if (this._view_overlay) {
            const storey_element = this._view_overlay.find_mouse_element(mouse_event);
            if (!storey_element) return null;
            if (this.element_filter(storey_element.element)) {
                logger.log('other element');
                return storey_element.element;
            }
        }
        return null;
    }

    _check_new_marker_creation(new_marker) {
        return true;
    }

    //***********************************************************************************
    /**
     * Builds a new marker handler.
     * TODO: derivate this in order to manage marker derivates
     * @param {Array<cn_marker>} markers
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(markers) {
        const handler = new cn_marker_handler(markers, (this._map) ? this._map : this._view_overlay, true);

        const obj = this;
        if (obj._view_overlay) {
            handler.on('shape_changed', () => {
                obj._view_overlay.refresh_3d();
            });
        }

        return handler;
    }

}

