import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySlice',
})
export class ArraySlicePipe implements PipeTransform {
  transform(array: Array<any>, start: number, end: number): Array<any> {
    return array.slice(start, end);
  }
}
