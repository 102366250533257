//***********************************************************************************
//***********************************************************************************
//**** Handle : manipulation of 3D
//***********************************************************************************
//***********************************************************************************

import {
    AmbientLight,
    BackSide,
	BufferGeometry,
    Box3,
    Color,
	ConeGeometry,
    DirectionalLight,
    DoubleSide,
    EdgesGeometry,
    Face3,
    FrontSide,
    Geometry,
    LineBasicMaterial,
    LineSegments,
    Matrix4,
	Line,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
	PlaneGeometry,
	Plane,
    PointLight,
    Scene,
    Vector2,
    Vector3,
    ImageUtils,
	RepeatWrapping,
	TextureLoader,
	SphereGeometry,
	BoxGeometry,
	OctahedronGeometry,
	Raycaster
} from 'three';
import {dummy_target, fh_view} from "./fh_view";
import {fh_sub, fh_add, fh_dot, fh_normalize, fh_mul, fh_clone, fh_cross} from "./fh_vector";

//***********************************************************************************
//**** Handle
//***********************************************************************************

export class fh_handle extends Object3D {
	//*****************************************************
	//*** Constructor
	constructor(shape, size) {
		super();

		this._events = [];

		//*** Materials */
		const material = new MeshBasicMaterial( { color: 0x0000ff, transparent:true, opacity: 0.4, side: DoubleSide} );
		const mouseover_material = new MeshBasicMaterial( { color: 0xff660a, depthTest: false, depthWrite: false, transparent:true, opacity: 0.4, side: DoubleSide } );
		
		const line_material = new LineBasicMaterial({color: 0x0000ff});
		const line_highlight_material = new LineBasicMaterial({color: 0xff660a});

		//*** build or load geometry */
		this._shape = shape;
		if (shape == "square")
		{
			const mesh = new Mesh(new PlaneGeometry(size,size),material);
			mesh._material = material;
			mesh._highlight_material = mouseover_material;
			// @ts-ignore
			this.add(mesh);
			
			const geometry = new BufferGeometry().setFromPoints([new Vector3(-size/2,-size/2,0),new Vector3(-size/2,size/2,0),new Vector3(size/2,size/2,0),new Vector3(size/2,-size/2,0),new Vector3(-size/2,-size/2,0)]);
			const line = new Line( geometry, line_material);
			line._material = line_material;
			line._highlight_material = line_highlight_material;
			// @ts-ignore
			this.add(line);
			
		}
		else if (shape == "arrow")
		{
			const geometry = new BufferGeometry().setFromPoints([new Vector3(0,0,0),new Vector3(0,0,size)]);
			const line = new Line( geometry, line_material);
			line._material = line_material;
			line._highlight_material = line_highlight_material;
			// @ts-ignore
			this.add(line);
			
			const mesh = new Mesh(new ConeGeometry(size/20,size/8,8),material);
			mesh.position.set(0,0,size);
			mesh.rotation.set(Math.PI/2,0,0,"XYZ");
			mesh._material = material;
			mesh._highlight_material = mouseover_material;
			// @ts-ignore
			this.add(mesh);
			
		}
		else if (shape == "circle" || shape == "half_circle")
		{
			const points = [];
			const thetamax = (shape == "half_circle")?180:360;
			for (var theta = 0; theta <= thetamax;theta +=5)
			{
				const a = theta * Math.PI/180;
				const p = new Vector3(size*Math.cos(a),size*Math.sin(a),0);
				points.push(p);
				if ((theta%15) == 0)
				{
					var sz0 = size * 0.95;
					if ((theta%90) == 0)
						sz0 = size * 0.80;
					else if ((theta%45) == 0)
						sz0 = size * 0.90;
					
					const p0 = new Vector3(sz0*Math.cos(a),sz0*Math.sin(a),0);
					const geo = new BufferGeometry().setFromPoints([p0,p]);
					const lne = new Line( geo, line_material);
					lne._material = line_material;
					lne._highlight_material = line_highlight_material;
					// @ts-ignore
					this.add(lne);
				}
			}
			const geometry = new BufferGeometry().setFromPoints(points);
			const line = new Line( geometry, line_material);
			line._material = line_material;
			line._highlight_material = line_highlight_material;
			// @ts-ignore
			this.add(line);
			this._angle = 0;
		}
		
		this._liberties = 0;
		this._liberty_direction = [0,0,0];
		this._position = [0,0,0];
	}

	//***********************************************************************************
	/**
	 * Registers an event on a given function
	 * @param {string} ev
	 * @param {function} fun
	 */
	on(ev, fun) {
		if (typeof(this._events[ev]) == 'undefined')
			this._events[ev] = [fun];
		else
			this._events[ev].push(fun);
	}

	//***********************************************************************************
	/**
	 * Unregisters an event for all functions (fun = null), or for one given function
	 * @param {string} ev
	 * @param {function} fun
	 */
	unbind(ev, fun = null) {
		if (typeof(this._events[ev]) == 'undefined') return;
		if (fun)
		{
			var index = this._events[ev].indexOf(fun);
			if (index >= 0) this._events[ev].splice(index,1);
		}
		else
			this._events[ev] = [];
	}

	//***********************************************************************************
	/**
	 * Calls an event with given arguments
	 * @param {string} ev
	 * @param {any} arg
	 */
	call(ev, arg = null) {
		var funs = this._events[ev];
		if (typeof(funs) != 'object') return;
		for (var j in funs)
			funs[j](arg);
	}

	/**
	 * Highlights a handle
	 * @param {boolean} value 
	 */
	highlight(value) {
		// @ts-ignore
		this.children.forEach(c => c.material = (value)?c._highlight_material:c._material);
	}
 
	//*****************************************************
	/**
	 * Update modification / view range
	 * @param {number[]} position 
	 */
	set_position(position) {
		this._position = fh_clone(position);
	}

	get_position() {
		return this._position;
	}

	/**
	 * Sets the degree of liberty. Default is 0 (cannot move)
	 * - If 1, can move along 'direction'.
	 * - If 2, can move on the plane with normal 'direction'.
	 * @param {number} nb_liberties 
	 * @param {number[]} direction 
	 */
	set_liberties(nb_liberties, direction) {
		this._liberties = nb_liberties;
		this._liberty_direction = fh_clone(direction);
		fh_normalize(this._liberty_direction);
	}

	grab_from_ray(ray) {
		// @ts-ignore
		const pos = new Vector3(0,0,0).applyMatrix4(this.matrixWorld);
		// @ts-ignore
		const nor = new Vector3(0,0,1).applyMatrix4(this.matrixWorld);
		nor.sub(pos);
		nor.normalize();

		if (this._shape == "square")
		{
			const x = ray.distanceToPlane(new Plane(nor,-pos.dot(nor)));
			this._point = ray.origin.clone().add(ray.direction.clone().multiplyScalar(x));
		}
		else if (this._shape == "arrow")
		{
			const dir = nor.clone().cross(ray.direction);
			dir.cross(nor);
			dir.normalize();
			const x = ray.distanceToPlane(new Plane(dir,-pos.dot(dir)));
			const point = ray.origin.clone().add(ray.direction.clone().multiplyScalar(x));
			point.sub(pos);
			const d = point.dot(nor);
			this._point = pos.add(nor.multiplyScalar(d));
		}
		else if (this._shape == "circle" || this._shape == "half_circle")
		{
			const x = ray.distanceToPlane(new Plane(nor,-pos.dot(nor)));
			const point = ray.origin.clone().add(ray.direction.clone().multiplyScalar(x)).sub(pos);
			point.normalize();
			
			// @ts-ignore
			const dx = (Math.abs(nor.z)>0.9)?new Vector3(1,0,0).cross(nor):new Vector3(0,0,1).cross(nor);
			dx.normalize();
			// @ts-ignore
			const dy = nor.clone().cross(dx);
			dy.normalize();

			var theta = Math.acos(point.dot(dx));
			if (Math.asin(point.dot(dy))<0) theta = -theta;

			while (theta - this._angle > Math.PI) theta -= 2 * Math.PI;
			while (theta - this._angle < -Math.PI) theta += 2 * Math.PI;
			this._angle = theta;

			console.log("grab",dx,dy,theta);
		}
	}

	move_from_ray(ray) {
		if (this._shape == "square" || this._shape == "arrow")
		{
			const previous = this._point;
			this.grab_from_ray(ray);
			this.call("move",this._point.clone().sub(previous));
		}
		else if (this._shape == "circle" || this._shape == "half_circle")
		{
			const snap = 5 * Math.PI / 180;
			const previous = this._angle;
			this.grab_from_ray(ray);
			const delta = Math.round((this._angle - previous)/snap) * snap;
			console.log("rotation",previous,this._angle + 0,(this._angle - previous)/snap,Math.round((this._angle - previous)/snap));
			this._angle = previous + delta;
			this.call("move",delta);
		}
	}
}
