import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'increment-input',
  templateUrl: './increment-input.component.html',
  styleUrls: ['./increment-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => IncrementInputComponent), multi: true }],
})
export class IncrementInputComponent implements ControlValueAccessor, OnDestroy {
  control = new FormControl();

  @Input() step = 1;
  @Input() min = 0;
  @Input() max = Infinity;
  @Input() readonly = false;

  private readonly _destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  incrementValue(step: number = 1): void {
    this.control.setValue(this._roundToStep(this.control.value + step));
  }

  registerOnChange(fn: (value: number) => void): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {}

  writeValue(value: number): void {
    this.control.setValue(value, { emitEvent: false });
    this.cdr.markForCheck();
  }

  private _roundToStep(value: number): number {
    const nbDecimalsOfStep = -Math.floor(Math.log10(this.step));
    const pow10 = Math.pow(10, nbDecimalsOfStep);
    return Math.round(value * pow10) / pow10;
  }
}
