'use strict';
//***********************************************************************************
//***********************************************************************************
//**** Editor for markers
//***********************************************************************************
//***********************************************************************************

import { cn_svg_tool_marker_edition } from '..';
import { cn_sampling } from '../model/cn_sampling';
import { cn_svg_map } from './cn_svg_map';
import { cn_marker } from '../model/cn_marker';
import { cn_marker_handler } from './cn_marker_handler';

export class cn_svg_tool_sampling_edition extends cn_svg_tool_marker_edition {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} map
     */
    constructor(map) {
        super(map);
        this._exclusive_samplings = [];
        this._marker_class = cn_sampling;
    }

    set_sampling_content(sampling) {
        if (this._handler) {
            this._handler.set_marker_content(sampling);
            if (sampling.zpso) {
                this._handler._marker['zpso'] = sampling.zpso;
            }
            if (sampling.control_result) {
                this._handler._marker['control_result'] = sampling.control_result;
            }
            return true;
        }
        return false;
    }

    drag(ev) {
        if (this._handler) {
            const sel = this._controller.get_selection();
            const allow_relocate = !this._exclusive_samplings.length ||
                (sel.length && sel.every(s => s.constructor === this._marker_class && this._exclusive_samplings.includes(s.label)));
            return this._handler.drag(ev, allow_relocate);
        }
        return false;
    }

    //***********************************************************************************
    /**
     * Builds a new marker handler.
     * TODO: derivate this in order to manage marker derivates
     * @param {Array<cn_marker>} markers
     * @returns {cn_marker_handler}
     */
    _build_handler(markers) {
        var handler = super._build_handler(markers);
        handler.transaction_name_shape_change = 'Modification de la zone de prélèvement';
        handler.transaction_name_arrow_change = 'Déplacement d\'un prélèvement';
        handler.transaction_name_tail_change = 'Déplacement de l\'étiquette d\'un prélèvement';
        handler.transaction_name_label_change = 'Modification du texte d\'un prélèvement';

        return handler;
    }

    set_exclusive_samlings(samplings) {
        this._exclusive_samplings = samplings;
    }
}

