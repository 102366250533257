import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayHas',
})
export class ArrayHasPipe implements PipeTransform {
  transform(array: Array<any>, criteria: Function): boolean {
    return !!array.find(el => criteria.call(this, el));
  }
}
