import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStepperConfig } from '../model/project-stepper/project-stepper-config';

@Pipe({
  name: 'configCountSteps',
})
export class ConfigCountStepsPipe implements PipeTransform {
  transform(config: ProjectStepperConfig): number {
    return config.stages.flatMap(stage => stage.steps).length;
  }
}
