import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '../shared/services/notification.service';

/**
 * Indique si une erreur est une erreur HTTP de type "serveur indisponible"
 */
export const isServerUnavailableError: (error: any) => boolean = error =>
  error && (error.status === 0 || error.status === 502 || error.status === 503 || error.status === 504);

@Injectable({ providedIn: 'root' })
export class HttpErrorService {
  public defaultShowLoginActionOnUnauthorizedError = true;

  constructor(private notificationService: NotificationService, private router: Router) {}

  /**
   * À partir d'une erreur HTTP, notifie une erreur à l'utilisateur avec :
   * - le message de l'exception si le backend en spécifie un,
   * - sinon un message standard en fonction du statut de l'erreur.
   */
  handleError(errorResponse: HttpErrorResponse): Observable<any> {
    try {
      const error = errorResponse.error;
      if (error.message) {
        // Message spécifique
        this.notificationService.error(error.message);
      } else if (errorResponse.status === 403 && error.detail) {
        // Message spécifique (cas de l'erreur 403)
        this.notificationService.error(error.detail);
      } else if (errorResponse.status === 409) {
        this.notificationService
          .errorWithAction(
            `Un autre utilisateur a enregistré ses modifications. Merci de bien vouloir recharger votre page`,
            'Recharger',
          )
          .subscribe(result => result && window.location.reload());
      } else {
        this.notifyErrorFromHttpStatusAndUrl(errorResponse.status);
      }
    } catch (e) {
      this.notifyErrorFromHttpStatusAndUrl(errorResponse.status);
    }
    return throwError(errorResponse);
  }

  /**
   * À partir d'une erreur HTTP, notifie une erreur à l'utilisateur avec un message standard en fonction du statut de l'erreur.
   */
  notifyErrorFromHttpStatusAndUrl(status: number) {
    if (status === 400) {
      this.notificationService.error(`Requête incorrecte. Réessayez ou contactez le support.`);
    } else if (status === 401) {
      if (this.defaultShowLoginActionOnUnauthorizedError) {
        this.notificationService
          .errorWithAction(`Votre session a expiré.`, `Cliquez ici pour vous authentifier.`)
          .subscribe(result => {
            if (result === true) this.router.navigate([`/login`]);
          });
      } else {
        this.notificationService.error(`Votre session a expiré. Veuillez vous authentifier.`);
      }
    } else if (status === 403) {
      this.notificationService.error(`Vous n'avez pas les permissions suffisantes pour effectuer cette requête`);
    } else if (status === 404) {
      this.notificationService.error(`Objet non trouvé.`);
    } else if (status === 413) {
      this.notificationService.error(
        'Le fichier envoyer est trop volumineux (essayez avec un fichier de moins de 10mo)',
      );
    } else if (status === 500) {
      this.notificationService.error(`Erreur serveur. Contactez le support.`);
    } else if (status === 0 || status === 502 || status === 503 || status === 504) {
      this.notificationService.error(`Service indisponible. Réessayez plus tard ou contactez le support.`);
    } else if (status === 408 || status === 599) {
      this.notificationService.error(`Erreur réseau. Réessayez plus tard ou contactez le support.`);
    } else {
      this.notificationService.error(`Erreur ${status}. Merci de contacter le support.`);
    }
  }
}
