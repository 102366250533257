'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2023 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************
import { cn_transaction_manager } from '../utils/cn_transaction_manager';
import { CODE_BIM_INCONNU } from '../utils/cn_bbp_constants';

//***********************************************************************************
//***********************************************************************************
//**** cn_building_data : All administrative data for a cn_building
//***********************************************************************************
//***********************************************************************************

export class cn_building_data {
    //***********************************************************************************
    /**
     * Constructor
     *
     */
    constructor() {

        //** address */
        this.address = '';
        this.city = '';
        this.zip = '';
        this.country = 'France';

        //*** localization */
        this.longitude = 0;
        this.latitude = 0;
        this.altitude = 0;

        //*** typology */
        this.construction_date = 0; // year
        this.typology = CODE_BIM_INCONNU; // building type code
        this.building_area = 0;

        //*** administrative */
        this.extension_administrative = {}

        //*** energy */
        this.extension_energy = {}
    }

    //***********************************************************************************
    /**
     * Serialize method
     * @returns {object}
     */
    serialize() {
        const json = {};

        json.address = this.address;
        json.city = this.city;
        json.zip = this.zip;
        json.country = this.country;

        json.longitude = this.longitude;
        json.latitude = this.latitude;
        json.altitude = this.altitude;

        json.construction_date = this.construction_date;
        json.typology = this.typology;

        json.extension_administrative = this.extension_administrative;
        json.extension_energy = this.extension_energy;

        return json;
    }

    //***********************************************************************************
    /**
     * Unserialize method
     * @param {object} json
     * @returns {cn_building_data}
     */
    static unserialize(json) {
        const data = new cn_building_data();
        if (typeof (json.address) == 'string') data.address = json.address;
        if (typeof (json.city) == 'string') data.city = json.city;
        if (typeof (json.zip) == 'string') data.zip = json.zip;
        if (typeof (json.country) == 'string') data.country = json.country;

        if (typeof (json.longitude) == 'number') data.longitude = json.longitude;
        if (typeof (json.latitude) == 'number') data.latitude = json.latitude;
        if (typeof (json.altitude) == 'number') data.altitude = json.altitude;

        if (typeof (json.construction_date) == 'number') data.construction_date = json.construction_date;
        if (typeof (json.typology) == 'string') data.typology = json.typology;

        if (typeof (json.extension_administrative) == 'object') data.extension_administrative = json.extension_administrative;
        if (typeof (json.extension_energy) == 'object') data.extension_energy = json.extension_energy;

        return data;
    }

    /**
     * Partial update building data.
     * Only non-null fields are updated.
     * @param {Partial<cn_building_data>} update
     * @param {boolean} initial default false, set true at first call at deserialization, then already defined values are not overwritten, and transaction is not logged.
     * @param {cn_transaction_manager} transaction_manager
     */
    update(update, initial, transaction_manager) {
        const transationInfos = [
            //** address */
            { fields: ['address'], label: 'Infos bâtiment : adresse' },
            { fields: ['city'], label: 'Infos bâtiment : ville' },
            { fields: ['zip'], label: 'Infos bâtiment : code postal' },
            //*** localization */
            { fields: ['longitude', 'latitude', 'altitude'], label: 'Infos bâtiment : coordonnées' },
            //*** typology */
            { fields: ['construction_date'], label: 'Infos bâtiment : année de construction' },
            { fields: ['typology'], label: 'Infos bâtiment : typologie' },
            { fields: ['building_area'], label: 'Infos bâtiment : surface déclarée' },
            //*** administrative */
            { fields: ['extension_administrative'], label: 'Infos bâtiment : données administratives' },
            //*** energy */
            { fields: ['extension_energy'], label: 'Infos bâtiment : données énergétiques' },
        ];
        transationInfos.forEach(it => {
            const mustUpdate = it.fields.some(field => {
                const currentValue = this[field];
                const updateValue = update[field];
                if (!currentValue && !updateValue) {
                    return false; // prevent falsy replacement cases (null vs '' or 0)
                }
                if (typeof (currentValue) == 'object' && typeof (updateValue) == 'object') {
                    // objets case
                    const currentValues = Object.values(currentValue);
                    const updateValues = Object.values(updateValue);
                    const hasCurrentValue = currentValues.some(it => !!it);
                    const hasUpdateValue = updateValues.some(it => !!it);
                    if (!hasCurrentValue && !hasUpdateValue) {
                        return false; // prevent null replacement cases ({} vs {a: null})
                    }
                    return JSON.stringify(updateValue) !== JSON.stringify(currentValue) && (!initial || !hasCurrentValue);
                } else {
                    // basic case
                    return updateValue !== currentValue && (!initial || !currentValue);
                }
            });
            if (mustUpdate) {
                if (!initial) {
                    transaction_manager.push_transaction(it.label, it.label);
                    transaction_manager.push_item_set(this, it.fields);
                }
                it.fields.forEach(field => {
                    this[field] = update[field];
                })
            }
        })
    }

}
