import { Component, Input, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { MarkdownService } from '../../services/markdown.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export class MarkdownViewModel {
  constructor(public pageTitle: string, public markdownAsHtml: string) {}
}

@Component({
  selector: 'lib-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
})
export class MarkdownComponent implements OnInit {
  viewModel$: Observable<MarkdownViewModel>;

  /** Classe CSS sur le conteneur principal */
  @Input() containerClass = '';

  /** Titre de la page (peut être fourni en input ou en route data) */
  @Input() pageTitle: string;

  /** Titre de la page (peut être fourni en input ou en route data) */
  @Input() markdownAsHtml: string;

  constructor(private markdownService: MarkdownService, private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.pageTitle && this.markdownAsHtml) {
      this.viewModel$ = of(new MarkdownViewModel(this.pageTitle, this.markdownAsHtml));
    } else {
      this.viewModel$ = this.route.data.pipe(
        switchMap(data =>
          this.markdownService.getMarkdownFile(data.markdownFile).pipe(
            map(text => {
              const markdownAsHtml = this.markdownService.convertToHTML(text);
              return new MarkdownViewModel(data.pageTitle, markdownAsHtml);
            }),
          ),
        ),
      );
    }
  }
}
