import { CnmapObjectUtils, GenericObject, IObjectsHandler } from '@enerbim/cnmap-angular-editor-lib';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { CODE_BIM_INCONNU } from '@enerbim/cnmap-editor';
import { Injectable } from '@angular/core';
import { ParamObjectPanelBehavior } from '@enerbim/cnmap-angular-editor-lib';
import { ParametersGroup, ProductType } from '@enerbim/commons-lib';

@Injectable()
export class ObjectsHandler implements IObjectsHandler {
  private readonly _parametersGroups$ = this.http
    .get<ParametersGroup[]>(`assets/datas/objects/parameters-groups.json`)
    .pipe(shareReplay(1));

  private readonly _productsTypes$ = this.http.get<ProductType[]>(`assets/datas/objects/products-types.json`).pipe(
    map(types =>
      types.some(it => it.codeBim == CODE_BIM_INCONNU) ? types : types.concat([CnmapObjectUtils.productTypeUnknown]),
    ),
    shareReplay(1),
  );

  private readonly _standardObjects$ = this.http
    .get<GenericObject[]>(`assets/datas/objects/standard-objects.json`)
    .pipe(
      shareReplay(1),
      map(standardObjects => standardObjects.map(obj => Object.assign(new GenericObject(), obj))),
    );

  constructor(private http: HttpClient) {}

  getParamObjectPanelBehavior(): ParamObjectPanelBehavior {
    return ParamObjectPanelBehavior.EXPAND_FIRST;
  }

  getDimensionsLabels(): string[] {
    return [
      'Largeur', // axe X
      'Épaisseur', // axe Y
      'Hauteur', // axe Z
    ];
  }

  nameCompletionCallback(): Function {
    return function () {
      if (this.width && this.depth && this.height) {
        return this.name.replace(/ \d+\.?\d*x\d+\.?\d*x\d+\.?\d*$|$/, ` ${this.width}x${this.depth}x${this.height}`);
      } else {
        return this.name;
      }
    };
  }

  /**
   * Renvoie les groupes de paramètres pour les équipements.
   */
  getObjectsParametersGroups(): Observable<ParametersGroup[]> {
    return this._parametersGroups$;
  }

  /**
   * Renvoie les types produits pour les équipements.
   */
  getObjectsProductsTypes(): Observable<ProductType[]> {
    return this._productsTypes$;
  }

  /**
   * Renvoie les équipements standards.
   */
  getGenericObjects(): Observable<GenericObject[]> {
    return this._standardObjects$;
  }
}
