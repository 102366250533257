'use strict';

import { abstract_cn_space_numerotation_extension } from './abstract_cn_space_numerotation_extension';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** space extension model for acenv specifications
//***********************************************************************************
//***********************************************************************************

export class cn_space_extension_default extends abstract_cn_space_numerotation_extension {

    constructor() {
        super();
        this.SERIALIZATION_DATA_PROP = 'extension_spaces_numerotation'
    }

}

