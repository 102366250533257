/**
 * Group a list by a key.
 * @param list
 * @param getKey
 */
export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
  return list.reduce((previous, currentItem) => {
    const key = getKey(currentItem);
    if (!previous[key]) previous[key] = [];
    previous[key].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}

/**
 * Associates a list by a key.
 * @param list
 * @param getKey
 */
export function associateBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T> {
  return list.reduce((previous, currentItem) => {
    const key = getKey(currentItem);
    previous[key] = currentItem;
    return previous;
  }, {} as Record<K, T>);
}

function orZero(n: number) {
  return n || 0;
}
export function sum(a: number, b: number) {
  return orZero(a) + orZero(b);
}
export function max(a: number, b: number) {
  return a > b ? a : b;
}
export function min(a: number, b: number) {
  return a < b ? a : b;
}

export function on(field: string, dir = 1, otherCompare?: (a, b) => number): (a: any, b: any) => number {
  const accessor = (obj: any) => field.split('.').reduce((subObj, segment) => subObj[segment], obj);
  return (a, b) => {
    const valueA = accessor(a);
    const valueB = accessor(b);
    if (valueA > valueB) {
      return dir;
    } else if (valueA < valueB) {
      return -dir;
    } else if (otherCompare) {
      return otherCompare(a, b);
    } else {
      return 0;
    }
  };
}

export function merge<T>(a: T[], b: T[], identity: (a: T, b: T) => boolean, mergeItem: (a: T, b: T) => T) {
  return (a || []).map(aItem => {
    const existing = (b || []).find(bItem => identity(aItem, bItem));
    if (existing) {
      return mergeItem(aItem, existing);
    } else {
      return aItem;
    }
  });
}
