'use strict';
import { cn_event_handler } from './cn_event_handler';
import { cn_element } from '../model/cn_element';
import { cn_edit_box } from './cn_edit_box';
import { cn_event_manager } from './cn_event_manager';

export class cn_edition_handler extends cn_event_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_element>} elements
     * @param {cn_event_manager} parent
     */
    constructor(elements, parent) {
        super(parent);
        this._elements = elements;
        // @ts-ignore
        this._readOnly = typeof parent.get_readonly === 'function' ? parent.get_readonly() : false;
        this._edit_box = new cn_edit_box(this, elements, this._readOnly)
    }
}

