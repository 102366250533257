import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRights } from '../model/company.model';
import { AuthorizationService } from '../services/authorization.service';

@Pipe({
  name: 'authorization',
})
export class AuthorizationPipe implements PipeTransform {
  constructor(private authorizationService: AuthorizationService) {}

  transform(right: CompanyRights, companyId?: string): boolean {
    return this.authorizationService.isAuthorizedByRole(right, companyId);
  }
}
