import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PicturePrevizualizerStore {
  private currentPictureUrl = new BehaviorSubject<string>('');

  constructor() {}

  getCurrentPictureUrl$(): Observable<string> {
    return this.currentPictureUrl.asObservable();
  }

  setCurrentPictureUrl(url: string): void {
    this.currentPictureUrl.next(url);
  }

  reset(): void {
    this.currentPictureUrl.next('');
  }
}
