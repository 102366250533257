'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_tool objects : a tool to manipulate objects
//***********************************************************************************
//***********************************************************************************

import { cn_clone } from '../utils/cn_utilities';
import { cn_roof_opening } from '../model/cn_roof_opening';
import { cn_svg_map } from './cn_svg_map';
import { cn_roof_opening_type } from '../model/cn_roof_opening_type';
import { cn_camera } from './cn_camera';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_roof_opening_handler } from './cn_roof_opening_handler';
import { cn_element } from '../model/cn_element';
import { cn_edition_handler } from './cn_edition_handler';


export class cn_svg_tool_roof_opening_creation extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} map
     */
    constructor(map) {
        super(map);

        //*** Scene data
        this._ghost = new cn_roof_opening(null, this._scene);
        this.element_filter = element => {
            return element.constructor == cn_roof_opening;
        };
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
        super.open_tool();
        const opening_types = this.get_opening_types();
        if (opening_types.indexOf(this._ghost.opening_type) < 0)
            this._ghost.opening_type = opening_types[0];
    }

    //***********************************************************************************
    /**
     * Returns all available opening types
     * @returns {cn_roof_opening_type[]}
     */
    get_opening_types() {
        return this._scene.building.get_roof_opening_types();
    }

    //***********************************************************************************
    /**
     * Returns current opening type
     * @returns {cn_roof_opening_type}
     */
    get_current_opening_type() {
        return this._ghost.opening_type;
    }

    //***********************************************************************************
    /**
     * Sets element type for future instanciations
     * @param {cn_roof_opening_type} ot
     */
    set_current_opening_type(ot) {
        this._ghost.opening_type = ot;
    }

    /**
     * Select elements by opening type
     * @param {cn_roof_opening_type} ot
     */
    select_elements_by_type(ot) {
        this._initiate_edition(this._scene.openings.filter(op => op.opening_type == ot));
    }

    //***********************************************************************************
    /**
     * Draws  specific svg for the tool. Returns svg string
     * @param {cn_camera} camera
     * @returns {string}
     */
    draw(camera) {
        var html = super.draw(camera);
        if (this._focus_handler != this) return html;

        if (this._ghost.opening_type == null) {
            var opening_types = this.get_opening_types();
            this._ghost.opening_type = (opening_types.length > 0) ? opening_types[0] : null;
        }

        if (this._ghost.slab && this._ghost.opening_type)
            html += '<g opacity=\'0.5\'>' + this._ghost.draw(camera, ['good']) + '</g>';

        return html;
    }

    //***********************************************************************************
    /**
     * Clear passive move effects
     */
    clear_move() {
        this._ghost.slab = null;
        super.clear_move();
    }

    click(ev) {
        if (this._focus_handler == this && this._other == null)
            this._create_element(ev);
        else
            super.click(ev);
        return true;
    }

    drop(ev) {
        if (this._focus_handler == this && this._other == null)
            this._create_element(ev);
        else
            super.drop(ev);
        return true;
    }

    grab(ev) {
        if (this._focus_handler == this) return true;
        if (super.grab(ev)) return true;
        return true;
    }

    move(ev) {
        if (super.move(ev)) return true;
        this._update_ghost(ev);
        this._focus_handler = this;
        return true;
    }

    drag(ev) {
        if (this._focus_handler == this)
            this._update_ghost(ev);
        else
            super.drag(ev);
        return true;
    }

    /**
     * Creates a new opening
     * @param {cn_mouse_event} ev
     */
    _create_element(ev) {
        var scene = this._scene;
        this.push_transaction('Création d\'ouvrant de toiture');
        this.push_item_set(this._scene, 'openings', () => {
            scene.update();
        });

        var new_opening = this._ghost;
        this._ghost = new cn_roof_opening(new_opening.opening_type, this._scene);
        this._scene.openings.push(new_opening);
        this._scene.update();
        this.call('creation', [new_opening]);
        this._initiate_edition([new_opening]);
    }

    //***************************************************************
    /**
     * Update ghost depending on mouse position
     * @param {cn_mouse_event} ev
     */
    _update_ghost(ev) {
        this._other = this._scene.find_roof_opening(ev.mouse_world, ev.camera.snap_world_distance);
        if (this._other) return;
        this._ghost.slab = this._scene.find_slab(ev.mouse_world, ev.camera.snap_world_distance);
        this._ghost.position = cn_clone(ev.mouse_world);
    }

    /**
     * TODO : derivate in order to allow edition of other element in the process of creation
     * @param {cn_mouse_event} mouse_event
     * @returns {cn_element}
     */
    _find_other_element(mouse_event) {
        return this._scene.find_roof_opening(mouse_event.mouse_world, mouse_event.camera.snap_world_distance);
    }

    /**
     * TODO : derivate in order to provide an edition handler
     * @param {Array<cn_roof_opening>} elements
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(elements) {
        return new cn_roof_opening_handler(elements, this._map, true);
    }

    /**
     * TODO : derivate in order to find siblings of an element
     * @param {cn_roof_opening} element
     * @returns {Array<cn_element>}
     */
    _get_siblings(element) {
        const ot = element.opening_type;
        return this._scene.openings.filter(op => op.opening_type == ot);
    }
}

