import { DrawingPicture } from '@enerbim/cnmap-angular-editor-lib';
import { Perimeter, User } from './user.model';
import { PacConfig } from './pac-config.model';
import { RenovationOption } from './renovation-option.model';
import { AdditionnalHeater } from './additionnal-heater.model';

export type PROJECT_STATUS = 'ONGOING' | 'TO_VALIDATE' | 'VALID' | 'FINISHED' | 'CLASSIFIED';

export class Client {
  constructor(
    public civility?: 'M' | 'Mme',
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public phone?: string,
    public revenuFiscal?: number,
    public nbPersonneFoyer?: string,
  ) {}
}

export class Address {
  constructor(public address?: string, public postcode?: string, public city?: string) {}
}

export class WeatherStation {
  constructor(
    public id?: number,
    public name?: string,
    public zip?: string,
    public longitude?: number,
    public latitude?: number,
    public weatherDataId?: number,
    public weatherDataStation?: string,
  ) {}
}

export class Room {
  constructor(
    public uid: string,
    public id: string,
    public name: string,
    public storey: number,
    public area: number,
    public heatingTransmitters?: string[],
    public temperature?: string,
    public note?: string,
    public impactedByWorks?: boolean,
    public workingTypeList?: string[],
    public technicalConstraints?: boolean,
    public idProject = null,
  ) {
    this.heatingTransmitters = [];
  }
}

export class Wall {
  constructor(
    public uid: string,
    public id: string,
    public storey: number,
    public roomIds: string[],
    public overlook?: string,
    public area?: number,
    public renovatedBefore2000?: boolean,
    public renovatedAfter2000?: boolean,
    public technicalConstraints?: boolean,
    public impactedByWorks?: boolean,
    public idProject = null,
  ) {}
}
export class Opening {
  constructor(
    public uid: string,
    public id: string,
    public storey: number,
    public wallId: string,
    public roomIds: string[],
    public renovatedBefore2000?: boolean,
    public renovatedAfter2000?: boolean,
    public technicalConstraints?: boolean,
    public idProject = null,
  ) {}
}

export class Slab {
  constructor(
    public uid: string,
    public id: string,
    public storey: number,
    public area: number,
    public overlook?: string,
    public renovatedBefore2000?: boolean,
    public renovatedAfter2000?: boolean,
    public technicalConstraints?: boolean,
    public idProject = null,
  ) {}
}

export class Storey {
  constructor(
    public uid: string,
    public id: string,
    public index: number,
    public area: number,
    public name: string,
    public wallsIds: string[],
    public openingsIds: string[],
    public idProject = null,
  ) {}
}

export class Drawing {
  constructor(
    public id?: string,
    public name?: string,
    public description?: string,
    public createdBy?: User,
    public createdDate?: string,
    public lastUpdatedBy?: User,
    public lastUpdatedDate?: string,
    public deletedDate?: string,
    public version?: number,
  ) {}
}

export class DrawingContent {
  constructor(public json?: any, public version?: number) {}
}

export class GeneralInformation {
  constructor(
    public localisation?: string, // code postal
    public housingType?: HousingType,
    public nbFloor?: string,
    public floorSituation?: string,
    public constructionYear?: string,
    public heatingMode?: string,
    public heatingTransmitter?: string,
    public globalTemperature?: string,
    public ventilationMode?: string,
  ) {
    this.globalTemperature = '20';
  }
}

export class HandlingSpec {
  constructor(
    public distance?: number,
    public groundNature?: string,
    public slope?: string,
    public stairs?: string,
    public doorDimensions?: string,
    public movedByClient?: string,
  ) {}
}

export class Handling {
  constructor(public interior?: HandlingSpec, public exterior?: HandlingSpec, public comment?: string) {}
}

export class PacSizing {
  constructor(
    // Project info
    public id?: string,
    public heatingSystemToChange?: string,
    public pacType?: string,
    public pacFunction?: string,
    public supportSlab?: string,
    public insideHeight?: number,
    public pacConnected?: string,
    public heaterOutputDiameter?: string,
    public heaterInputDiameter?: string,
    public gridType?: 'MONOTUBE' | 'BITUBE',
    public externalHotWaterTank?: boolean,
    // Localisation
    public climateArea?: string,
    public altitude?: string,
    // house information
    public housingFloor?: string,
    public jointness?: string,
    public groundPosition?: string,
    public buildType?: string,
    public wallThickNess?: string,
    public ventilation?: string,
    // pictures
    public pictures?: { [pictureKey: string]: string /*file Id reference*/ },
    // renovation
    public renovationByBuildingElement?: { [buildingElement: string]: string /*category slug*/ },
    // energy information
    public temperature?: number, // could be merged with GeneralInformation.globalTemperature
    public consumptionPrimaryEnergy?: number,
    public consumptionElectricity?: number,
    public electricityType?: string,
    public electricitySubscription?: string,
    // validation
    public generalInformationUpdatedAt?: string, // date iso
    public studyCompleteValidatedAt?: string, // date iso
    // configuration
    public g?: number,
    public commentOnG?: string,
    public configurationValidatedAt?: string, // date iso
    // calculation
    public waterRegimeMax?: number,
    public heatLossAtValidation?: number, // in W
    public excludedRooms?: string[],
    public calculationValidatedAt?: string, // date iso
    // proposition
    public proposedProduct?: PacConfig,
    public propositionComment?: string,
    public propositionValidatedAt?: string, // date iso
    // Custom changes outside map
    public addedHeaters?: AdditionnalHeater[],
    public handling?: Handling,
    public version?: number,
  ) {
    this.temperature = temperature || 19;
    this.renovationByBuildingElement = renovationByBuildingElement || {};
    this.addedHeaters = [];
  }
}

export class Project {
  constructor(
    // technical information
    public type?: Perimeter,
    public companyId?: string,
    public companyName?: string,
    public localisation?: string,
    public id?: string,
    public name?: string,
    public pinned?: boolean,
    public imageUrl?: string, // URL de l'image du projet, renseigné par les pages si besoin de l'afficher

    // Pac
    public pacSizing?: PacSizing,
    public generalInformation?: GeneralInformation,
    public rooms?: Room[],
    public walls?: Wall[],
    public openings?: Opening[],
    public slabs?: Slab[],
    public storeys?: Storey[],
    public lastFilledStep?: number,
    public description?: string,
    public address?: Address,
    public weatherStation?: WeatherStation,
    public client?: Client,
    public imageFileId?: string,
    public drawingPictures?: DrawingPicture[],
    public createdBy?: User,
    public createdDate?: string,
    public drawingsCount?: number,
    public drawingDetails?: Drawing,
    public deletedDate?: string,
    public lastUpdatedDate?: string,
    public lastUpdatedBy?: User,
    public typeList?: string[],
    public projectStatus?: PROJECT_STATUS,
    public preview?: string,
    public configuration?: ProjectConfiguration,
    public calculation?: RenovationOption,
    public version?: number,
  ) {
    this.pacSizing = new PacSizing();

    this.client = new Client();
    this.address = new Address();
    this.typeList = [];
    this.generalInformation = new GeneralInformation();
    this.lastFilledStep = 0;
    this.rooms = [];
    this.slabs = [];
    this.openings = [];
    this.walls = [];
    this.storeys = [];
  }
}

export enum HousingType {
  MAISON = 'MAISON',
  APPARTEMENT = 'APPARTEMENT',
}

export enum RenovationDate {
  NON_RENOVE = 'Non rénové',
  BEFORE_2000 = 'Rénovation < 2000',
  AFTER_2000 = 'Rénovation > 2000',
}

export class ProjectConfiguration {
  constructor(
    public finished: boolean = false,
    public constructionYearConfiguration: any,
    public rolesConfiguration: any,
    public conditionsMursConfiguration: any,
    public conditionsPlanchersConfiguration: any,
    public conditionsPlafondsConfiguration: any,
    public chauffageConfiguration: any,
    public temperatureConfiguration: any,
    public ventilationConfiguration: any,
    public renovationsFenetresConfiguration: any,
    public renovationsMursConfiguration: any,
    public renovationsPlanchersConfiguration: any,
    public renovationsPlafondsConfiguration: any,
  ) {}
}
