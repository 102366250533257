'use strict';

import { cn_svg_configurator, cn_svg_param_array, cn_svg_param_boolean, cn_svg_param_enum, cn_svg_param_group } from '..';
import { cn_svg_param_object } from './cn_svg_param';

//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************

/**
 * @class cn_svg_configurator - A tool to produce svg for a map
 */
export class cn_svg_configurator_zpso_report extends cn_svg_configurator {
    /**
     *
     * @param {string} svg_container_id
     * @param {object} storey
     * @param {boolean} [roof=false]
     */
    constructor(svg_container_id, storey, roof = false) {
        super(svg_container_id, storey, roof, [0, 0, 0, 270]);
    }

    _get_params() {
        const result = []
        const render_group = new cn_svg_param_group('display_elements', 'Affichage');
        render_group.children.push(new cn_svg_param_enum('render', 'Rendu', 0, ['color', 'greyscale', 'black'], ['Couleur', 'Gris', 'Noir & blanc']));
        render_group.children.push(new cn_svg_param_boolean('show_grid', 'Grille', false));
        render_group.children.push(new cn_svg_param_boolean('show_scale', 'Echelle', false));
        render_group.children.push(new cn_svg_param_boolean('show_background', 'Fond de carte', true));
        render_group.children.push(new cn_svg_param_boolean('show_compass', 'Boussole', true));
        result.push(render_group);

        const elements_group = new cn_svg_param_group('display_elements', 'Eléments');
        if (!this._roof) {
            elements_group.children.push(new cn_svg_param_boolean('show_spaces', 'Pièces', true));
            elements_group.children.push(new cn_svg_param_boolean('show_outer_walls', 'Parois extérieures', true));
            elements_group.children.push(new cn_svg_param_boolean('show_inner_walls', 'Parois intérieures', true));
            elements_group.children.push(new cn_svg_param_boolean('show_balconies', 'Balcons', true));
            elements_group.children.push(new cn_svg_param_boolean('show_windows', 'Fenêtres', true));
            elements_group.children.push(new cn_svg_param_boolean('show_doors', 'Portes', true));
            elements_group.children.push(new cn_svg_param_boolean('show_stairs', 'Escaliers', true));
            elements_group.children.push(new cn_svg_param_boolean('show_slab_openings', 'Trémies', true));
            elements_group.children.push(new cn_svg_param_boolean('show_beams', 'Poutres', true));
            elements_group.children.push(new cn_svg_param_boolean('show_columns', 'Colonnes', true));
            elements_group.children.push(new cn_svg_param_boolean('show_objects', 'Objets', true));
            elements_group.children.push(new cn_svg_param_boolean('show_pipes', 'Conduits', true));

            const text_group = new cn_svg_param_group('display_elements', 'Texte');
            text_group.children.push(new cn_svg_param_boolean('show_space_label', 'Nom des pièces', true));
            text_group.children.push(new cn_svg_param_boolean('show_space_area', 'Surface des pièces', false));
            text_group.children.push(new cn_svg_param_boolean('show_numerotation', 'Numérotation des murs', false));
            result.push(text_group);
            const marker_group = new cn_svg_param_boolean('show_markers', 'Annotations', true);
            marker_group.children.push(new cn_svg_param_boolean('marker_group_', 'Groupe par défaut', true));
            for (let group of this._building.get_all_marker_groups()) {
                marker_group.children.push(new cn_svg_param_boolean('marker_group_' + group, group, false));
            }
            result.push(marker_group);
        } else {
            elements_group.children.push(new cn_svg_param_boolean('show_outer_lines', 'Bords de toiture', true));
            elements_group.children.push(new cn_svg_param_boolean('show_inner_lines', 'Lignes de toit', true));
            elements_group.children.push(new cn_svg_param_boolean('show_roof_slabs', 'Pans de toiture', true));
            elements_group.children.push(new cn_svg_param_boolean('show_roof_openings', 'Fenêtres de toit', true));
        }
        result.push(elements_group);

        result.push(new cn_svg_param_array('show_zpso', 'Affichage ZPSO', []));
        result.push(new cn_svg_param_array('show_zone', 'Affichage Zones', null));
        result.push(new cn_svg_param_array('show_sampling', 'Affichage prélèvements', null));
        result.push(new cn_svg_param_array('show_facing', 'Affichage Revêtements', null));
        result.push(new cn_svg_param_array('link_sampling_zpso', 'Limiter aux ZPSO affichées', []));
        result.push(new cn_svg_param_array('show_comment_application', 'Affichage couleurs d\'application des commentaires', []));
        result.push(new cn_svg_param_object('title_block', 'Cartouche', {}));

        return result;
    }

    _draw_legend() {
        const title_bloc_specs = this.get_svg_param('title_block');
        return `<g>${this._camera.draw_rapport_zpso_legend(this.draw_layers, this.draw_samplings, this.draw_zones, title_bloc_specs)}</g>`;
    }

}

