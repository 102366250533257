'use strict';

import { cn_space } from '../../model/cn_space';
import { cn_storey } from '../../model/cn_storey';
import { cn_zone } from '../../model/cn_zone';
import { cn_pastille } from '../../svg/cn_pastille';
import { cn_svg_map } from '../../svg/cn_svg_map';
import { cn_transaction_manager } from '../../utils/cn_transaction_manager';
import { extension_instance } from '../cn_extension';
import { cn_space_extension } from '../cn_space_extension';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** space extension model for acenv specifications
//***********************************************************************************
//***********************************************************************************

export class abstract_cn_space_numerotation_extension extends cn_space_extension {
    constructor() {
        super(true);
        this.SERIALIZATION_DATA_PROP = '';
    }

    /**
     * Add index to name if name is repeated in scope (storey or zone)
     *
     * @param { cn_space } space
     * @param { cn_storey } storey
     * @returns { string }
     */
    get_space_name(space, storey) {
        const default_zoning_type =
            extension_instance.zone.get_default_zone_tool().property;
        if (storey && storey.building) {
            const impacted_zone = storey.building
                .get_zones(default_zoning_type)
                .find((zone) =>
                    zone.rooms.find(
                        (room) =>
                            room.storey === storey.ID && room.space === space.ID
                    )
                );
            const impacted_zone_id = impacted_zone ? impacted_zone.ID : null;
            let associated_spaces_id = [];
            if (impacted_zone) {
                associated_spaces_id = impacted_zone.rooms
                    .filter((room) => room.storey === storey.ID)
                    .map((room) => room.space);
            } else {
                const spaces_in_zone = storey.building
                    .get_zones(default_zoning_type)
                    .reduce(
                        (agg, zone) =>
                            agg.concat(
                                zone.rooms
                                    .filter((room) => room.storey === storey.ID)
                                    .map((room) => room.space)
                            ),
                        []
                    );
                associated_spaces_id = storey.scene.spaces
                    .filter((sp) => !spaces_in_zone.includes(sp.ID))
                    .map((sp) => sp.ID);
            }
            const associated_scoped_spaces_id = storey.scene.spaces
                .filter(
                    (sp) =>
                        associated_spaces_id.includes(sp.ID) &&
                        space.name === sp.name
                )
                .map((sp) => sp.ID);
            const space_extension_referential =
                extension_instance.data[this.SERIALIZATION_DATA_PROP] || [];
            let space_numerotation = space_extension_referential.find(
                (ref) =>
                    ref.name === space.name &&
                    ref.storey === storey.ID &&
                    ref.zone === impacted_zone_id
            );
            let current_index = {};
            if (!!space_numerotation) {
                space_numerotation.values = space_numerotation.values.filter(
                    (v) => associated_scoped_spaces_id.includes(v.id)
                );
                current_index = space_numerotation.values.find(
                    (v) => v.id === space.ID
                );
                if (!current_index) {
                    const max_value = Math.max(
                        ...space_numerotation.values.map((v) => v.index),
                        -1
                    );
                    current_index = { id: space.ID, index: max_value + 1 };
                    space_numerotation.values.push(current_index);
                }
            } else {
                current_index = { id: space.ID, index: 0 };
                space_numerotation = {
                    name: space.name,
                    storey: space.scene.storey.ID,
                    zone: impacted_zone_id,
                    numerotation: 1,
                    prefix: '',
                    values: [],
                };
                space_extension_referential.push(space_numerotation);
                space_numerotation.values.push(current_index);
            }
            extension_instance.data[this.SERIALIZATION_DATA_PROP] =
                space_extension_referential;

            const index = space_numerotation.numerotation + current_index.index;
            const prefix = space_numerotation.prefix;

            if (space_numerotation.values.length > 1 || index !== 1 || prefix) {
                return `${space.name} ${prefix}${index}`;
            } else {
                return space.name;
            }
        } else {
            return space.get_generic_name(storey);
        }
    }

    /**
     * Add space numerotation input to change increment and/or prefix for scope (name / zone / storey)
     *
     * @param { cn_space } space
     * @param { cn_svg_map } map
     * @returns { cn_pastille[] }
     */
    space_handler_option(space, map) {
        const result = [];
        const pastille = new cn_pastille([0, 0], 'numeric.svg');
        pastille.svg_class = 'pastille_background white';
        pastille.title = 'Numérotation';
        const self = this;
        pastille.clicked = () => {
            const input_ext = new cn_space_numerotation_input();
            const default_zoning_type =
                extension_instance.zone.get_default_zone_tool().property;
            const impacted_zone = space.scene.storey.building
                .get_zones(default_zoning_type)
                .find((zone) =>
                    zone.rooms.find(
                        (room) =>
                            room.storey === space.scene.storey.ID &&
                            room.space === space.ID
                    )
                );
            const impacted_zone_id = impacted_zone ? impacted_zone.ID : null;
            const space_extension_referential =
                extension_instance.data[self.SERIALIZATION_DATA_PROP] || [];
            const space_numerotation = space_extension_referential.find(
                (ref) =>
                    ref.name === space.name &&
                    ref.storey === space.scene.storey.ID &&
                    ref.zone === impacted_zone_id
            );
            const transaction_manager =
                space.scene.storey.building.transaction_manager;
            const values_index = space_numerotation
                ? space_numerotation.values.findIndex((v) => v.id === space.ID)
                : -1;
            input_ext.name = space.name;
            input_ext.numerotation = space_numerotation
                ? space_numerotation.numerotation
                : -1;
            input_ext.prefix = space_numerotation
                ? space_numerotation.prefix
                : '';
            input_ext.value =
                values_index >= 0
                    ? space_numerotation.values[values_index].index
                    : -1;
            input_ext.callback = () => {
                if (values_index >= 0) {
                    const new_numerotation = input_ext.numerotation;
                    const new_prefix = input_ext.prefix;
                    const new_value = input_ext.value;
                    transaction_manager.push_transaction(
                        'Valeur de numérotation'
                    );
                    transaction_manager.push_item_set(space_numerotation, [
                        'numerotation',
                        'prefix',
                    ]);
                    transaction_manager.push_item_set(
                        space_numerotation.values[values_index],
                        'index'
                    );
                    space_numerotation.numerotation = new_numerotation;
                    space_numerotation.prefix = new_prefix;
                    space_numerotation.values[values_index].index = new_value;
                    map.refresh();
                }
            };
            map.call('space_numerotation_input', input_ext);
        };
        result.push(pastille);
        return result;
    }

    /**
     * If space is last in zone, move space numerotation data to the new scope
     *
     * @param { cn_space } space
     * @param { cn_zone } old_zone
     * @param { cn_zone } new_zone
     */
    on_change_zone(space, old_zone, new_zone) {
        const impacted_zone_id = old_zone ? old_zone.ID : null;
        const space_extension_referential =
            extension_instance.data[this.SERIALIZATION_DATA_PROP] || [];
        const space_numerotation = space_extension_referential.find(
            (ref) =>
                ref.name === space.name &&
                ref.storey === space.scene.storey.ID &&
                ref.zone === impacted_zone_id
        );
        if (space_numerotation) {
            const transaction_manager =
                space.scene.storey.building.transaction_manager;
            let associated_spaces_id = [];
            if (old_zone) {
                associated_spaces_id = old_zone.rooms
                    .filter((room) => room.storey === space.scene.storey.ID)
                    .map((room) => room.space);
            } else {
                const default_zoning_type =
                    extension_instance.zone.get_default_zone_tool().property;
                const spaces_in_zone = space.scene.storey.building
                    .get_zones(default_zoning_type)
                    .reduce(
                        (agg, zone) =>
                            agg.concat(
                                zone.rooms
                                    .filter(
                                        (room) =>
                                            room.storey ===
                                            space.scene.storey.ID
                                    )
                                    .map((room) => room.space)
                            ),
                        []
                    );
                associated_spaces_id = space.scene.spaces
                    .filter((sp) => !spaces_in_zone.includes(sp.ID))
                    .map((sp) => sp.ID);
            }
            const spaces_with_same_name = space.scene.spaces.filter(
                (sp) =>
                    associated_spaces_id.includes(sp.ID) &&
                    space.name === sp.name
            );
            if (spaces_with_same_name.length === 1) {
                transaction_manager.push_item_set(space_numerotation, 'zone');
                space_numerotation.zone = new_zone ? new_zone.ID : null;
            }
        }
    }

    /**
     * Duplicate spaces numerotation from old storey to new storey
     *
     * @param { cn_storey } old_storey
     * @param { cn_storey } new_storey
     */
    on_duplicate_storey(old_storey, new_storey) {
        const default_zoning_type =
            extension_instance.zone.get_default_zone_tool().property;
        const space_extension_referential =
            extension_instance.data[this.SERIALIZATION_DATA_PROP] || [];
        const storey_numerotations = space_extension_referential.filter(
            (sn) => sn.storey === old_storey.ID
        );
        if (storey_numerotations.length) {
            const transaction_manager = old_storey.building.transaction_manager;
            transaction_manager.push_item_set(
                extension_instance.data,
                this.SERIALIZATION_DATA_PROP
            );
            storey_numerotations.forEach((sn) => {
                let new_target_zone = null;
                if (sn.zone) {
                    const old_zone_name = old_storey.building
                        .get_zones(default_zoning_type)
                        .find((zone) => zone.ID === sn.zone).name;
                    const new_zone = old_storey.building
                        .get_zones(default_zoning_type)
                        .find(
                            (zone) =>
                                zone.name === old_zone_name &&
                                zone.main_storey === new_storey.ID
                        );
                    new_target_zone = new_zone ? new_zone.ID : null;
                }
                extension_instance.data[this.SERIALIZATION_DATA_PROP].push({
                    name: sn.name,
                    storey: new_storey.ID,
                    zone: new_target_zone,
                    numerotation: sn.numerotation,
                    prefix: sn.prefix,
                    values: [...sn.values],
                });
            });
        }
    }

    /**
     * Remove zone scope for all spaces numerotations
     *
     * @param { cn_zone } zone
     * @param { cn_transaction_manager } transaction_manager
     */
    on_delete_zone(zone, transaction_manager) {
        const space_extension_referential =
            extension_instance.data[this.SERIALIZATION_DATA_PROP] || [];
        const zones_numerotations = space_extension_referential.filter(
            (sn) => sn.zone === zone.ID
        );
        if (zones_numerotations.length) {
            zones_numerotations.forEach((zn) => {
                transaction_manager.push_item_set(zn, 'zone');
                zn.zone = null;
            });
        }
    }
}

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_space_numerotation_input
 * A class used to request numerotation and prefix for space name
 */
export class cn_space_numerotation_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} name - space name (i)
     * @param {number} value - space numerotation value
     * @param {number} numerotation - space name numerotation start (i/o)
     * @param {string} prefix - space name prefix (i/o)
     * @param {function} cb - callback
     */
    constructor(
        name = '',
        value = 1,
        numerotation = 1,
        prefix = '',
        cb = null
    ) {
        this.name = name;
        this.value = value;
        this.numerotation = numerotation;
        this.prefix = prefix;
        this.callback = cb;
    }
}
