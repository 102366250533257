'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2023 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************


import { cn_element } from './cn_element';
import { cn_storey } from './cn_storey';

//***********************************************************************************
//***********************************************************************************
/**
 * A class to manipulate an element along with its storey
 */
//***********************************************************************************
//***********************************************************************************

export class cn_storey_element {
    /**
     * Constructor
     * @param {cn_element} element
     * @param {cn_storey} storey
     */
    constructor(element, storey) {
        this.storey = storey;
        this.element = element;
    }

    /**
     * Comparison
     * @param {cn_storey_element|cn_element} e1
     * @param {cn_storey|null} e2
     * @returns
     */
    equals(e1, e2 = null) {
        if (e1 && e2)
            return this.storey == e2 && this.element == e1;
        if (!e1) {
            console.error('Element is null on equals', e1)
        }
        // @ts-ignore
        return e1 && this.storey == e1.storey && this.element == e1.element;
    }
}
