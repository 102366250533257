'use strict';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_svg_map } from './cn_svg_map';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_add, cn_clone, cn_dist, cn_dot, cn_mul, cn_sub } from '../utils/cn_utilities';
import { cn_pastille } from './cn_pastille';
import { cn_type_pastille } from './cn_type_pastille';
import { cn_edit_box } from './cn_edit_box';
import { cn_number_input, cn_number_list_input } from './cn_inputs';
import { cn_roof_dormer } from '../model/cn_roof_dormer';

export class cn_roof_dormer_handler extends cn_edition_handler {
    //***********************************************************************************
    /**
     * Constructor
     * @param {Array<cn_roof_dormer>} roof_dormers
     * @param {cn_svg_map} map
     * @param {boolean} creation
     */
    constructor(roof_dormers, map, creation = false) {
        super(roof_dormers, map);
        const obj = this;

        this._map = map;
        this._scene = this._map._scene;
        this._transaction_manager = this._map._building.transaction_manager;

        this._roof_dormers = roof_dormers;
        this._roof_dormer = (roof_dormers.length == 1) ? roof_dormers[0] : null;

        this._dormer_mouseover = -1;
        this._previous_mouse = [0, 0];

        //*** Edit box for mass selection */
        const edit_box = new cn_edit_box(this, roof_dormers, creation);
        this._handlers.push(edit_box);

        //*** dimensions pastille */
        const dimensions_pastille = new cn_pastille([0, 0], 'tape_measure.svg');
        edit_box.add_pastille(dimensions_pastille);
        dimensions_pastille.svg_class = 'pastille_background white';
        dimensions_pastille.title = 'Dimensions';
        dimensions_pastille.clicked = () => {
            const input_list = [];

            const input_width = new cn_number_input('Largeur', this._roof_dormers[0].width, 'm', 2, 0.5, 10);
            if (this._roof_dormers.some(s => s.width != input_width.value))
                input_width.label = 'Largeur (variable)';
            input_list.push(input_width);

            const input_height = new cn_number_input('Hauteur', this._roof_dormers[0].height, 'm', 2, 0.5, 10);
            if (this._roof_dormers.some(s => s.height != input_height.value))
                input_height.label = 'Hauteur (variable)';
            input_list.push(input_height);

            const input_overhang_front = new cn_number_input('Débord avant', this._roof_dormers[0].overhang_front, 'm', 2, 0, 10);
            if (this._roof_dormers.some(s => s.overhang_front != input_overhang_front.value))
                input_overhang_front.label = 'Débord avant (variable)';
            input_list.push(input_overhang_front);

            const input_overhang_side = new cn_number_input('Débord latéral', this._roof_dormers[0].overhang_side, 'm', 2, 0, 10);
            if (this._roof_dormers.some(s => s.overhang_side != input_overhang_side.value))
                input_overhang_side.label = 'Débord latéral (variable)';
            input_list.push(input_overhang_side);

            const input = new cn_number_list_input('Dimensions', input_list);
            input.callback = () => {
                obj._transaction_manager.push_transaction('Dimensions de chien assis');
                this._roof_dormers.forEach(roof_dormer => {
                    obj._transaction_manager.push_item_set(roof_dormer, ['width', 'height', 'overhang_front', 'overhang_side']);
                    roof_dormer.width = input_width.value;
                    roof_dormer.height = input_height.value;
                    roof_dormer.overhang_front = input_overhang_front.value;
                    roof_dormer.overhang_side = input_overhang_side.value;
                    roof_dormer.update();
                });
                map.call('roof_update');
                map.refresh();
            };
            map.call('number_list_input', input);
        }

        //*** Angle pastille (only if all dormers are similar in terms of slopes) */
        if (!this._roof_dormers.some(s => s.slopes != this._roof_dormers[0].slopes)) {
            const slope = this._roof_dormers[0].slopes;
            const angle_pastille = new cn_pastille([0, 0], 'tool_roof_slopes.png');
            edit_box.add_pastille(angle_pastille);
            angle_pastille.svg_class = 'pastille_background white';
            angle_pastille.title = 'Pente';
            angle_pastille.clicked = () => {
                const check = this._roof_dormers[0].slope_angle <= 0;
                const auto_label = (slope == 2) ? 'Idem toiture' : '';
                const input = new cn_number_input('Pente', this._roof_dormers[0].slope_angle, '°', 0, (slope == 2) ? 0 : -70, 70, auto_label, check);
                if (this._roof_dormers.some(s => s.slope_angle != input.value))
                    input.label = 'Pente (variable)';
                input.callback = () => {
                    obj._transaction_manager.push_transaction('Pente de chien assis');
                    const new_value = (slope == 2 && input.checkbox_status) ? 0 : input.value;
                    this._roof_dormers.forEach(roof_dormer => {
                        obj._transaction_manager.push_item_set(roof_dormer, 'slope_angle');
                        roof_dormer.slope_angle = new_value;
                        roof_dormer.update();
                    });
                    map.call('roof_update');
                    map.refresh();
                };
                map.call('number_input', input);
            }
        }

        //*** Opening height */
        const opening_height_pastille = new cn_pastille([0, 0], 'tool_windows.png');
        edit_box.add_pastille(opening_height_pastille);
        opening_height_pastille.svg_class = 'pastille_background white';
        opening_height_pastille.title = 'Hauteur des baies';
        opening_height_pastille.clicked = () => {
            const first_dormer_height = this._roof_dormers[0].opening_offset;
            const check = this._roof_dormers[0].opening_relative;
            const input = new cn_number_input('Hauteur des baies', first_dormer_height, 'm', 2, 0, 10, 'Hauteur relative au sol', !check);
            if (this._roof_dormers.some(r => r.opening_relative !== check || r.opening_offset != first_dormer_height)) input.label += ' (variable)';
            input.callback = () => {
                obj._transaction_manager.push_transaction('Hauteur des baies des chiens assis');
                this._roof_dormers.forEach(roof_dormer => {
                    obj._transaction_manager.push_item_set(roof_dormer, ['opening_offset', 'opening_relative']);
                    roof_dormer.opening_offset = input.value;
                    roof_dormer.opening_relative = !input.checkbox_status;
                });
                map.call('roof_update');
                map.refresh();
            }
            map.call('number_input', input);
        }
        //*** roof type pastille */
        const type_pastille = new cn_type_pastille(this._roof_dormers, 'wall_type', () => {
            return map._building.get_wall_types().filter(wt => !wt.free);
        }, 'Type de mur', map);
        type_pastille.title = 'Modifier le type de mur';
        edit_box.add_pastille(type_pastille);

        edit_box.add_lock_pastille(obj._transaction_manager);

        edit_box.add_select_siblings_pastille('slopes');
        this.transaction_increment = 0;
    }

    //***********************************************************************************
    //**** Draws
    //***********************************************************************************
    draw(camera, draw_measures = true) {

        var html = super.draw(camera);

        if (this._roof_dormer) {
            var draw_extras = ['selected'];
            if (this._dormer_mouseover == 0) draw_extras.push('mouseover');
            html += this._roof_dormer.draw(camera, draw_extras);

            this._length_control = camera.world_to_screen(cn_add(this._roof_dormer.position, cn_mul(this._roof_dormer.dy, this._roof_dormer.length)));
            html += camera.draw_move_arrow_screen(this._length_control, (this._dormer_mouseover == 1) ? 'mouseover' : '');

            const p0 = camera.world_to_screen(cn_add(this._roof_dormer.position, cn_mul(this._roof_dormer.dx, this._roof_dormer.width / 2)));
            const p1 = camera.world_to_screen(cn_sub(this._roof_dormer.position, cn_mul(this._roof_dormer.dx, this._roof_dormer.width / 2)));
            this._width_controls = [p0, p1];
            this._width_controls.forEach(wc => html += camera.draw_move_arrow_screen(wc, (this._dormer_mouseover == 2) ? 'mouseover' : ''));

        }
        return html;
    }

    //***********************************************************************************
    //**** clear move effects
    //***********************************************************************************
    clear_move() {
        this._dormer_mouseover = -1;
        super.clear_move();
    }

    grab(mouse_event) {
        this._previous_mouse = cn_clone(mouse_event.mouse_world);
        if (!this.active) return false;
        if (this._focus_handler == this) return true;
        return super.grab(mouse_event);
    }

    drag(mouse_event) {
        if (this._focus_handler == this) {
            if (this._dormer_mouseover == 0) {
                const new_position = cn_add(this._roof_dormer.position, cn_sub(mouse_event.mouse_world, this._previous_mouse));
                if (this._roof_dormer.parent.find_slab(new_position)) {
                    this._transaction_manager.push_transaction('Déplacement de chien assis', this._roof_dormer.ID);
                    this._transaction_manager.push_item_set(this._roof_dormer, 'position', function (item) {
                        item.update();
                    });
                    this._roof_dormer.position = cn_clone(new_position);
                    this._roof_dormer.update();
                    this._map.call('roof_update');
                }
            } else if (this._dormer_mouseover == 1) {
                const l = cn_dot(this._roof_dormer.dy, cn_sub(mouse_event.mouse_world, this._roof_dormer.position));
                const h = this._roof_dormer.height * l / this._roof_dormer.length;
                if (h > 0.5) {
                    this._transaction_manager.push_transaction('Hauteur de chien assis', this._roof_dormer.ID);
                    this._transaction_manager.push_item_set(this._roof_dormer, 'height', function (item) {
                        item.update();
                    });
                    this._roof_dormer.height = h;
                    this._roof_dormer.update();
                    this._map.call('roof_update');
                }
            } else if (this._dormer_mouseover == 2) {
                const w = Math.abs(cn_dot(this._roof_dormer.dx, cn_sub(mouse_event.mouse_world, this._roof_dormer.position)));
                if (w > 0.5) {
                    this._transaction_manager.push_transaction('Largeur de chien assis', this._roof_dormer.ID);
                    this._transaction_manager.push_item_set(this._roof_dormer, 'width', function (item) {
                        item.update();
                    });
                    this._roof_dormer.width = w * 2;
                    this._roof_dormer.update();
                    this._map.call('roof_update');
                }
            }
            this._previous_mouse = cn_clone(mouse_event.mouse_world);
            return true;
        }
        return super.drag(mouse_event);
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
    move(mouse_event) {
        if (!this.active) return false;
        this.clear_move();
        if (this._roof_dormer) {
            if (this._length_control && cn_dist(mouse_event.mouse_screen, this._length_control) < 20)
                this._dormer_mouseover = 1;
            else if (this._width_controls && this._width_controls.find(wc => cn_dist(mouse_event.mouse_screen, wc) < 20))
                this._dormer_mouseover = 2;
            else if (this._roof_dormer.contains(mouse_event.mouse_world))
                this._dormer_mouseover = 0;

            if (this._dormer_mouseover >= 0) {
                this._focus_handler = this;
                return true;
            }
        }
        return super.move(mouse_event);
    }
}

