import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Drawing } from '../model/project.model';
import { Observable } from 'rxjs';
import { DrawingPicture } from '@enerbim/cnmap-angular-editor-lib';

@Injectable({
  providedIn: 'root',
})
export class DrawingApiService {
  public resourceUrl = environment.apiUrl + '/projects';

  constructor(private http: HttpClient) {}

  upsert(projectId: string, drawing: Drawing): Observable<Drawing> {
    return this.http.post<Drawing>(`${this.resourceUrl}/${projectId}/drawing`, drawing);
  }

  getContent(projectId: string, drawingId: string): Observable<object> {
    return this.http.get<object>(`${this.resourceUrl}/${projectId}/drawing/${drawingId}`, {});
  }

  getVersion(projectId: string, drawingId: string): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/${projectId}/drawing/${drawingId}/version`, {});
  }

  updateContent(projectId: string, drawingId: string, version: number, json: string): Observable<void> {
    return this.http.post<void>(`${this.resourceUrl}/${projectId}/drawing/${drawingId}?version=${version}`, json);
  }

  deleteContent(projectId: string, drawingId: string): Observable<Drawing> {
    return this.http.delete<Drawing>(`${this.resourceUrl}/${projectId}/drawing/${drawingId}/content`);
  }

  getDrawingPictureUrl(projectId: string, imageFileId: string): string {
    return `${this.resourceUrl}/${projectId}/drawing-picture/${imageFileId}`;
  }

  getDrawingPicture(projectId: string, imageFileId: string): Observable<Blob> {
    return this.http.get(this.getDrawingPictureUrl(projectId, imageFileId), {
      responseType: 'blob',
    });
  }

  renameDrawingPicture(projectId: string, imageFileId: string, newName: string): Observable<void> {
    return this.http.post<void>(this.getDrawingPictureUrl(projectId, imageFileId), newName);
  }

  uploadDrawingPicture(projectId: string, formData: FormData): Observable<HttpEvent<DrawingPicture>> {
    return this.http.post<DrawingPicture>(`${this.resourceUrl}/${projectId}/drawing-picture`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  deleteDrawingPicture(projectId: string, imageFileId: string): Observable<any> {
    return this.http.delete<any>(this.getDrawingPictureUrl(projectId, imageFileId));
  }

  uploadContentWithProgressReport(projectId: string, drawingId: string, formData: FormData): Observable<any> {
    return this.http.post(`${this.resourceUrl}/${projectId}/drawing/${drawingId}?ignoreVersion=true`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
