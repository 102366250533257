import { ProjectStage } from '../model/project-stepper/project-stage';
import { ProjectStep, StepStatus } from '../model/project-stepper/project-step';
import { ProjectStepperConfig } from '../model/project-stepper/project-stepper-config';
import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { PacLogicService } from 'src/app/shared/services/pac-logic.service';
import { ProjectService } from './project.service';
import { Perimeter } from '../model/user.model';

export enum DpeSteps {
  CLIENT_INFO = 1,
  HOUSING_INFO,
  HEATING_VENTILATION_INFO,
  MAP,
  CONFIGURATION_ROOM,
  CONFIGURATION_WALL,
  VALIDATION,
  SIMULATION,
  RESULTS,
}

export enum PacSteps {
  CLIENT_INFO = 1,
  PROJECT_INFO,
  LOCATION_INFO,
  HOUSING_INFO,
  RENOVATION_INFO,
  ENERGY_INFO,
  HANDLING_INFO,
  TECHNICAL_VISIT_AND_PICTURES,
  MAP,
  VALIDATION,
  CONFIGURATION,
  CALCULATION,
  PROPOSITION,
}

@Injectable({ providedIn: 'root' })
export class WorkflowService {
  constructor(
    private pacLogicService: PacLogicService,
    private authorizationService: AuthorizationService,
    private projectService: ProjectService,
  ) {}

  getCurrentConfig(): ProjectStepperConfig {
    const currentProjectSnapshot = this.projectService.getCurrentProjectSnapshot();
    switch (currentProjectSnapshot.type) {
      case Perimeter.DPE:
        return this._dpeConfig();
      case Perimeter.PAC:
        return this._pacConfig();
    }
  }

  stepToGoTo(config: ProjectStepperConfig): number {
    const projectStepByNum = new Map<number, ProjectStep>();
    for (let stage of config.stages) {
      for (let step of stage.steps) {
        projectStepByNum.set(step.num, step);
      }
    }

    const project = this.projectService.getCurrentProjectSnapshot();
    for (let i = (project.lastFilledStep || 0) + 1; i > 0; i--) {
      const status = projectStepByNum.get(i)?.getCompletionStatus(project);
      if (status && status !== StepStatus.LOCKED) {
        return i;
      }
    }
    return 1;
  }

  private _dpeConfig(): ProjectStepperConfig {
    const generalData = new ProjectStage('Informations générales');
    const customerData = new ProjectStep(DpeSteps.CLIENT_INFO, 'Informations client', true);
    const housingData = new ProjectStep(DpeSteps.HOUSING_INFO, 'Informations logement', true);
    const heatingData = new ProjectStep(DpeSteps.HEATING_VENTILATION_INFO, 'Mode de chauffage et ventilation', true);
    generalData.setSteps([customerData, housingData, heatingData]);
    const importPlan = new ProjectStage('Plan du logement').setSteps([new ProjectStep(DpeSteps.MAP, '', true, true)]);
    const configPlan = new ProjectStage('Configuration');
    const configRoom = new ProjectStep(DpeSteps.CONFIGURATION_ROOM, 'Pièce par pièce', true);
    const configLimit = new ProjectStep(DpeSteps.CONFIGURATION_WALL, 'Paroi par paroi', true);
    configPlan.setSteps([configRoom, configLimit]);
    const validation = new ProjectStage('Validation').setSteps([new ProjectStep(DpeSteps.VALIDATION, '', true)]);
    const simulation = new ProjectStage('Simulation').setSteps([
      new ProjectStep(DpeSteps.SIMULATION, '', false, true, true),
    ]);

    const results = new ProjectStage('Résultats').setSteps([new ProjectStep(DpeSteps.RESULTS, '', true, true, true)]);
    results.hideInMenu = true;
    return new ProjectStepperConfig('DPE', [generalData, importPlan, configPlan, validation, simulation, results]);
  }

  private _pacConfig(): ProjectStepperConfig {
    const generalData = new ProjectStage('Informations générales');
    const customerData = new ProjectStep(PacSteps.CLIENT_INFO, 'Informations client', true, false, false, project =>
      this.pacLogicService.clientInfoStatus(project),
    );
    const projectData = new ProjectStep(PacSteps.PROJECT_INFO, 'Informations projet', true, false, false, project =>
      this.pacLogicService.projectInfoStatus(project),
    );
    const localisationData = new ProjectStep(
      PacSteps.LOCATION_INFO,
      'Informations de localisation',
      true,
      false,
      false,
      project => this.pacLogicService.locationInfoStatus(project),
    );
    const housingData = new ProjectStep(PacSteps.HOUSING_INFO, 'Informations logement', true, false, false, project =>
      this.pacLogicService.housingInfoStatus(project),
    );
    const renovationData = new ProjectStep(
      PacSteps.RENOVATION_INFO,
      'Informations de rénovation',
      true,
      false,
      false,
      project => this.pacLogicService.renovationInfoStatus(project),
    );
    const energyData = new ProjectStep(PacSteps.ENERGY_INFO, 'Énergie', true, false, false, project =>
      this.pacLogicService.energyInfoStatus(project),
    );
    const handlingData = new ProjectStep(
      PacSteps.HANDLING_INFO,
      'Sécurité et manutention',
      true,
      false,
      false,
      project => this.pacLogicService.handlingInfoStatus(project),
    );
    const technicalVisitAndPictures = new ProjectStep(
      PacSteps.TECHNICAL_VISIT_AND_PICTURES,
      'Visite technique et photos',
      true,
      false,
      false,
      project => this.pacLogicService.handlingTechnicalVisitAndPhotosStatus(project),
    );
    generalData.setSteps([
      customerData,
      projectData,
      localisationData,
      housingData,
      renovationData,
      energyData,
      handlingData,
      technicalVisitAndPictures,
    ]);
    const importPlan = new ProjectStage('Plan du logement').setSteps([
      new ProjectStep(PacSteps.MAP, '', true, true, false, project => this.pacLogicService.mapStatus(project)),
    ]);
    const validation = new ProjectStage('Validation').setSteps([
      new ProjectStep(PacSteps.VALIDATION, '', true, true, false, project =>
        this.pacLogicService.validationStatus(project),
      ),
    ]);
    const configPlan = new ProjectStage('Configuration').setSteps([
      new ProjectStep(PacSteps.CONFIGURATION, '', true, false, false, project =>
        this.pacLogicService.configurationStatus(project),
      ),
    ]);
    const calcul = new ProjectStage('Calcul').setSteps([
      new ProjectStep(
        PacSteps.CALCULATION,
        '',
        true,
        false,
        false,
        project => this.pacLogicService.calculationStatus(project),
        true,
      ),
    ]);
    const proposition = new ProjectStage('Proposition').setSteps([
      new ProjectStep(PacSteps.PROPOSITION, '', true, true, false, project =>
        this.pacLogicService.propositionStatus(project),
      ),
    ]);
    return new ProjectStepperConfig('PAC', [generalData, importPlan, validation, configPlan, calcul, proposition]);
  }
}
