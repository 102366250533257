'use strict';

import { cn_config_extension } from './cn_config_extension';
import { cn_data_extension } from './cn_data_extension';
import { cn_space_extension } from './cn_space_extension';
import { cn_zone_extension } from './cn_zone_extension';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** extension singleton instance
//***********************************************************************************
//***********************************************************************************

class cn_extension {

    constructor() {
        this.space = null;
        this.data = null;
        this.zone = null;
        this.config = null;
        this.features = [];
    }

}

export const extension_instance = new cn_extension();
extension_instance.space = new cn_space_extension();
extension_instance.zone = new cn_zone_extension();
extension_instance.data = new cn_data_extension();
extension_instance.config = new cn_config_extension();

