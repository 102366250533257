'use strict';
//***********************************************************************************
//***********************************************************************************
//**** A handler to create / modify a 3D line
//***********************************************************************************
//***********************************************************************************

import { cn_event_handler } from './cn_event_handler';
import { cn_mouse_event } from './cn_mouse_event';
import { cn_add, cn_clone, cn_dist, cn_mul, cn_sub, cnx_clone } from '../utils/cn_utilities';
import { cn_snap } from './cn_snap';
import { cn_image_dir } from '../utils/image_dir';
import { cn_camera } from './cn_camera';
import { cn_scene } from '../model/cn_scene';

/**
 * @class cn_3d_line_handler
 * A handler to create / modify a 3D line
 * Used for beams.
 *
 * Available events :
 * - "start_creation" : called when first point of the shape is created
 * - "create" : called when last point of the shape is created
 * - "change" : called when shape changes
 * - "height_click" : called when one of the heights is clicked
 */
export class cn_3d_line_handler extends cn_event_handler {
    /**
     *
     * @param {cn_event_handler} parent
     * @param {Array<Array<number>>} vertices
     * @param {cn_scene} scene
     */
    constructor(parent, vertices, scene = null) {
        super(parent);
        this.vertices = vertices.map(vertex => cnx_clone(vertex));

        this._scene = scene;

        //*** Settings */
        this.force_inside_space = false;

        this._creation = (this.vertices.length == 0);

        this._mouse_position = [0, 0];
        this._drag_mouse = [0, 0];
        this._mouseover_box = -1;
        this._mouseover_vertex = -1;
        this._mouseover_line = false;

        this._snap_svg = '';

        this.on_height_click = null;
        this.on_start_creation = null;


        this._box_size = [40, 20];
        this._box_position = [[0, 0], [0, 0]];

        this.ghost = null;

        this.allow_creation = null;
        this.automatic_height = 0;
        this.default_height = 0;
        this.default_height1 = 0;
        this.vertical_line = (this._creation) ? false : cn_dist(this.vertices[0], this.vertices[1]) < 0.1;

        this._do_draw = true;

        this.snap_elements = [];
    }

    //*****************************************************************
    //*** is polygon currently creating ?
    //*****************************************************************
    is_creating() {
        return (this.vertices.length == 1);
    }

    //*****************************************************************
    //*** Draw the handler
    //*****************************************************************
    draw(camera) {
        var html = '';
        if (!this._do_draw) return html;

        var g = (this.ghost) ? camera.world_to_screen(this.ghost) : null;

        //*** In case we are going to create */
        if (this.vertices.length == 0) {
            if (g)
                html += '<circle class=\'handle_vertex selected\' cx=\'' + g[0] + '\' cy=\'' + g[1] + '\' r=\'5\'/>';
            return html;
        }

        //*** In case we are creating */
        if (this.vertices.length == 1) {
            var p = camera.world_to_screen(this.vertices[0]);
            html += '<circle class=\'handle_vertex\' cx=\'' + p[0] + '\' cy=\'' + p[1] + '\' r=\'5\'/>';

            if (g) {
                html += '<circle class=\'handle_vertex selected\' cx=\'' + g[0] + '\' cy=\'' + g[1] + '\' r=\'5\'/>';
                html += '<line class=\'handle_outline\' x1=\'' + p[0] + '\' y1=\'' + p[1] + '\' x2=\'' + g[0] + '\' y2=\'' + g[1] + '\' />';
            }
            return html;
        }

        if (this.vertices.length != 2) return html;

        //*** Maybe we are going to create another line ? */
        if (this._creation && g)
            html += '<circle class=\'handle_vertex\' cx=\'' + g[0] + '\' cy=\'' + g[1] + '\' r=\'5\'/>';

        var pp = [camera.world_to_screen(this.vertices[0]), camera.world_to_screen(this.vertices[1])];

        //*** Draw line */
        var line_classes = 'handle_line';
        if (this._mouseover_line) line_classes += ' mouseover';
        else line_classes += ' selected';
        html += '<line class=\'' + line_classes + '\' x1=\'' + pp[0][0] + '\' y1=\'' + pp[0][1] + '\' x2=\'' + pp[1][0] + '\' y2=\'' + pp[1][1] + '\' />';

        if (this._mouseover_line)
            html += camera.draw_line_move_arrow(this.vertices[0], this.vertices[1], 'mouseover')

        //*** Draw vertices */
        for (var i = 0; i < 2; i++) {
            var box_class = 'roof_height_box';
            if (this._mouseover_box == i) box_class += ' mouseover';
            var p = pp[i];

            var box_sense = (this.vertices[1][0] > this.vertices[0][0]) ? 1 : -1;
            if (i == 0) box_sense *= -1;

            this._box_position[i] = cn_add(this.vertices[i], cn_mul([40, 40], camera.screen_to_world_scale * box_sense));
            var tp = camera.world_to_screen(this._box_position[i]);

            //*** Draw line between height and box */
            html += '<line class=\'roof_height_line\' x1=\'' + p[0] + '\' y1=\'' + p[1] + '\' x2=\'' + tp[0] + '\' y2=\'' + tp[1] + '\' />';

            //*** draw box */
            console.debug(i, this.vertices[i][2], this.automatic_height)
            var txt = this.vertices[i][2] != this.automatic_height ? this.vertices[i][2].toFixed(2) : 'auto';
            html += camera.draw_text_box(this._box_position[i], this._box_size[0], this._box_size[1], txt, box_class, 'roof_height_text');

            //*** draw height icon */
            var r = 10;
            var classes = 'roof_height_disk custom';
            if (this._mouseover_vertex == i) classes += ' mouseover';
            else classes += ' selected';
            html += '<circle class=\'' + classes + '\' cx=\'' + p[0] + '\' cy=\'' + p[1] + '\' r=\'' + r + '\'/>';
            html += '<image xlink:href=\'' + cn_image_dir() + 'roof_height.png\' x=\'' + (p[0] - r) + '\' y=\'' + (p[1] - r) + '\' width=\'' + (r * 2) + '\' height=\'' + (r * 2) + '\' />';

            html += camera.draw_move_arrow(this.vertices[i], (this._mouseover_vertex >= 0 && (this.vertical_line || this._mouseover_vertex == i)) ? 'mouseover' : 'selected');
        }

        html += this._snap_svg;
        return html;
    }

    //*****************************************************************
    //*** Clear move data
    //*****************************************************************
    clear_move() {
        this._mouseover_vertex = -1;
        this._mouseover_box = -1;
        this._mouseover_line = false;
        this.ghost = null;
    }

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    move(ev) {
        this.clear_move();
        if (!this._check(ev.mouse_world, ev.camera)) return false;

        if (this.vertices.length == 2) {
            //*** Mouse over a vertex ? */
            for (var i = 0; i < 2; i++) {
                if (!ev.camera.on_vertex(ev.mouse_world, this.vertices[i]))
                    continue;
                this._mouseover_vertex = i;
                return true;
            }

            //*** Mouse over a box ? */
            var mp = ev.camera.world_to_screen(ev.mouse_world);
            for (var i = 0; i < 2; i++) {
                var xx = cn_sub(mp, ev.camera.world_to_screen(this._box_position[i]));
                if (Math.abs(xx[0]) < this._box_size[0] / 2 && Math.abs(xx[1]) < this._box_size[1] / 2) {
                    this._mouseover_box = i;
                    return true;
                }
            }

            //*** Mouse over the line ? */
            this._mouseover_line = (ev.camera.on_edge(ev.mouse_world, this.vertices[0], this.vertices[1]));
            if (this._mouseover_line) return true;
        }

        if (this._creation) {
            this.ghost = this.find_valid_position(ev.mouse_world, ev.camera);
            return true;
        }

        return false;
    }

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    grab(ev) {
        if (!this._check(ev.mouse_world, ev.camera)) return false;
        this._drag_mouse = cn_clone(ev.mouse_world);
        return this.move(ev);
    }

    /**
     * Manage a click
     * @param {cn_mouse_event} ev
     * @returns {boolean}
     */
    click(ev) {
        if (this._mouseover_box >= 0) {
            //*** Check that mouse is still over the box */
            var mouse_position = ev.camera.world_to_screen(ev.mouse_world);
            var xx = cn_sub(mouse_position, ev.camera.world_to_screen(this._box_position[this._mouseover_box]));
            if (Math.abs(xx[0]) < this._box_size[0] / 2 && Math.abs(xx[1]) < this._box_size[1] / 2) {
                this.call('height_click', this._mouseover_box);
            }
            return true;
        }
        return true;
    }

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} ev
     * @returns {boolean}
     */
    drop(ev) {
        if (!this._check(ev.mouse_world, ev.camera)) return false;

        //**** Add one vertex */
        if (this._mouseover_vertex < 0 && !this._mouseover_line && this.ghost) {
            if (this.vertices.length == 2 && this._creation)
                this.vertices = [];

            if (this.vertices.length < 2) {
                this.vertices.push([this.ghost[0], this.ghost[1], this.default_height]);
                if (this.vertical_line) {
                    this.call('start_creation');
                    this.vertices.push([this.ghost[0], this.ghost[1], this.default_height1]);
                }
                if (this.vertices.length == 1)
                    this.call('start_creation');
                else
                    this.call('create');
            }
            return true;
        }

        return true;
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    drag(ev) {
        if (!this._check(ev.mouse_world, ev.camera)) return false;

        //**** Move one vertex */
        if (this._mouseover_vertex >= 0) {
            var p = this.find_valid_position(ev.mouse_world, ev.camera);
            if (p == null) return true;
            this.vertices[this._mouseover_vertex][0] = p[0];
            this.vertices[this._mouseover_vertex][1] = p[1];
            if (this.vertical_line) {
                this.vertices[1 - this._mouseover_vertex][0] = p[0];
                this.vertices[1 - this._mouseover_vertex][1] = p[1];
            }
            this.call('change');
            return true;
        }

        //*** Move from box */
        if (this._mouseover_box >= 0) {
            return true;
        }

        //*** Move line */
        if (this._mouseover_line) {
            var delta = cn_sub(ev.mouse_world, this._drag_mouse);
            for (var i = 0; i < 2; i++) {
                this.vertices[i][0] += delta[0];
                this.vertices[i][1] += delta[1];
            }
            this.call('change');
            this._drag_mouse = cn_clone(ev.mouse_world);
            return true;
        }

        if (this._creation)
            return this.move(ev);

        return true;
    }

    //*****************************************************************
    /**
     * Find valid position
     * @param {number[]} mouse_world_position
     * @param {cn_camera} camera
     * @returns {number[]} Snapped position, or null
     */
    find_valid_position(mouse_world_position, camera) {
        if (this._scene && this.force_inside_space) {
            var space = this._scene.find_space(mouse_world_position);
            if (space == null || space.outside) return null;
        }

        this._snap_svg = '';
        var snap = new cn_snap(mouse_world_position, camera.snap_world_distance, null, camera);
        snap.snap_elements = this.snap_elements;
        snap.snap_point();
        snap.check_grid();
        this._snap_svg = snap.svg;
        return cn_clone(snap.position);
    }

    //*****************************************************************
    //*** check new position
    //*****************************************************************
    _check(mouse_world_position, camera) {
        this._do_draw = true;
        if (!this._creation) return true;
        if (this.is_creating()) return true;
        if (this.allow_creation)
            if (!this.allow_creation(mouse_world_position, camera)) {
                this._do_draw = false;
                return false;
            }
        return true;
    }
}

