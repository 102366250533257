//***********************************************************************************
//***********************************************************************************
//**** fh_view_ortho : 2D view
//***********************************************************************************
//***********************************************************************************

import {Box3, OrthographicCamera, Vector3} from 'three';
import {dummy_target, fh_view} from "./fh_view";

//***********************************************************************************
//**** Class view
//***********************************************************************************

export class fh_view_ortho extends fh_view {
	//*****************************************************
	//*** Constructor
	constructor(div_id, scene,svg=false) {
		super(div_id, scene,svg);

		this._camera = new OrthographicCamera(this._container.clientWidth / -2, this._container.clientWidth / 2, this._container.clientHeight / 2, this._container.clientHeight / -2, 0.1, 10000);
		this._camera.scene_scale = 1000.0;
		this._view = "0";
		this._current_storey = 0;

		this._camera.position.x = 0;
		this._camera.position.y = 0;
		this._camera.position.z = 10;
		this._camera.up.set(0,1,0);
		this._camera.lookAt(new Vector3(0,0,0));

		//*** Storey callback : called when storey changed from the view
		this._cb_current_storey_changed = function(storey_index, storey_name) {};

		this._perspective = false;
	}

	//********************************************************
	//*** Update perspective camera
	update_camera()	{
		this._camera.updateProjectionMatrix();
	}


	//***********************************************************************************
	//**** Set current storey
	//***********************************************************************************
	set_current_storey(v) {

		var view_layer = v;
		if (typeof(v) == 'string')
		{
			view_layer = this._scene._storeys.indexOf(v);
			if (view_layer < 0) return false;
		}
		else if (typeof(v) != 'number')
			return false;
		if (this._current_storey == view_layer) return false;

		this._view = "" + view_layer;
		this._current_storey = view_layer;
		this.refresh_rendering();
		return true;
	}

	get_current_storey() {
		return this._current_storey;
	}

	//*****************************************************
	//**** Direct scaling
	//*****************************************************

	get_vertical_world_height() {
		return this._camera.scene_scale;
	}

	set_vertical_world_height(value) {
		this._camera.scene_scale = value;
		this._camera.zoom = this._render_height / this._camera.scene_scale;
		this.update_camera();
		this.call("view_change");
		this.refresh_rendering();
	}

	//*****************************************************
	//**** Callbacks for navigation
	//*****************************************************

	//*****************************************************
	//*** Window resize callback
	manage_window_resize() {
		super.manage_window_resize();
		// le container parent n'est pas exactement égal au canvas (probablement un problème d'arrondi), donc si on trouve une différence de moins de 2px, on ne resize pas
		//if (Math.abs((this._render_width - this._container.clientWidth)) < 2 && Math.abs((this._render_height - this._container.clientHeight) < 2))
		//	return;

		if (typeof(this._camera) != 'object') return;
		this._camera.left = this._render_width / -2;
		this._camera.right = this._render_width / 2;
		this._camera.top = this._render_height / 2;
		this._camera.bottom = this._render_height / -2;
		this._camera.zoom = this._render_height / this._camera.scene_scale;
		this.update_camera();
	}

	//********************************************************
	//*** Mouse wheel management
	manage_mouse_wheel(ev) {
		if (ev.preventDefault)
			ev.preventDefault();
		if (ev.stopPropagation)
			ev.stopPropagation();

		var mouse = this.read_mouse_position(ev.clientX,ev.clientY);
		var forward = (ev.deltaY > 0.2);

		this.do_pan(-mouse.x, -mouse.y);
		if (forward)
			this._camera.scene_scale *= 1.1 ;
		else
			this._camera.scene_scale /= 1.1 ;
		this._camera.zoom = this._render_height / this._camera.scene_scale;
		this.do_pan(mouse.x, mouse.y);

		this.update_camera();
		this.call("view_change");
		this.refresh_rendering();
	}

	//********************************************************
	//*** orth pan
	do_pan(dx, dy) {
		var vx = new Vector3();
		vx.crossVectors(this._camera.getWorldDirection(dummy_target), this._camera.up);
		vx.normalize();
		var vy = new Vector3();
		vy.crossVectors(vx, this._camera.getWorldDirection(dummy_target));

		var rx = 0.5 * (this._render_width/this._camera.zoom);
		var ry = 0.5 * (this._render_height/this._camera.zoom);

		vx.multiplyScalar(rx * -dx);
		vy.multiplyScalar(ry * -dy);

		vx.add(vy);
		this._camera.position.add(vx);
		this._camera.updateProjectionMatrix();
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_move(ev) {
		super.manage_mouse_move(ev);
		if (this._mouse_operation < 0) return;

		this.do_pan(this._dxm,this._dym);

		this.call("view_change");
		this.refresh_rendering();
	}

	//*****************************************************
	//*** start animation
	start_animation() {
		this._animation = true;
		this._animation_start = new Date();
		this._animation_duration = 1000;

		this._start_position.copy(this._camera.position);

		this._start_target.copy(this._camera.position);
		this._start_target.z -= this._scene._scene_radius;
		var angle = Math.PI*this._scene._storey_orientation/180;
		this._camera.up.set(-Math.sin(angle), Math.cos(angle), 0);
		this._start_scale = this._camera.scene_scale;
	}

	//*****************************************************
	//*** start animation
	perform_animation() {
		if (!this._animation) return;

		var d1 = new Date();
		var t = (d1.getTime() - this._animation_start.getTime()) / this._animation_duration;
		if (t > 1 || !this._camera_animation)
		{
			this._animation = false;
			t = 1;
		}
		var position0 = this._start_position.clone();
		var position1 = this._end_position.clone();
		position0.multiplyScalar(1-t);
		position1.multiplyScalar(t);
		position0.add(position1);
		this._camera.position.copy(position0);

		var target0 = this._start_target.clone();
		var target1 = this._end_target.clone();
		target0.multiplyScalar(1-t);
		target1.multiplyScalar(t);
		target0.add(target1);
		this._camera.lookAt(target0);

		this._camera.scene_scale = this._start_scale * (1-t) + this._end_scale * t;
		this._camera.zoom =this._render_height / this._camera.scene_scale;

		this.update_camera();
	}

	//*****************************************************
	//*** camera reset
	reset_camera () {
		if (this._scene._scene_center == null) return;

		this.start_animation();

		this._end_position.copy(this._scene._scene_center);
		this._end_position.z += this._scene._scene_radius;
		this._end_target.copy(this._scene._scene_center);
		this._end_scale = this._scene._scene_radius*2;

		this.refresh_rendering();
	}

	//***********************************************************************************
	//**** zoom on selection
	zoom_on_selection() {
		if (this._scene._selection.length != 1) return;

		var bb = new Box3().setFromObject(this._scene._selection[0]);
		if (!this._perspective) bb.min.z = bb.max.z;
		var center = bb.min.clone();
		center.add(bb.max);
		center.multiplyScalar(0.5);

		var size = bb.min.distanceTo(bb.max);
		if (size < 1) size = 1;

		this.start_animation();

		var max_storey = this._scene._selection[0].max_storey;
		if (this._scene._selection[0].bim_type == "flat")
		{
			for (var i in this._scene._selection[0].children)
			{
				var ms = this._scene._selection[0].children[i].max_storey;
				if (typeof(ms) != 'number') continue;
				if (ms > max_storey) max_storey = ms;
			}
		}
		this.set_current_storey(max_storey);
		this._cb_current_storey_changed(max_storey,this._scene._storeys[max_storey]);

		this._end_position.copy(center);
		this._end_position.z += this._scene._scene_radius;
		this._end_target.copy(center);
		this._end_scale = size*2;

		this.refresh_rendering();
	}
}
