import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncrementInputComponent } from './increment-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [IncrementInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatIconModule, MatInputModule],
  exports: [IncrementInputComponent],
})
export class IncrementInputModule {}
