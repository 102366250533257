'use strict';

export const CODE_BIM_INCONNU = 'inconnu';

// Types d'espaces
export const CODE_BIM_FLAT = 'FLAT';
export const CODE_BIM_SPACE = 'SPACE';

// Paramètres des espaces
export const CODES_BIM_PARAMETRES_ESPACES = {
    spacePerimeter: 'perimetre',
    spaceHeight: 'hauteur_sous_plafond',
    spaceHeated: 'chauffe',
    spaceCarrezArea: 'surface_carrez_piece',
    spaceLivingArea: 'shab',
    spaceNetUsableArea: 'surface_utile_nette',
    spaceRawUsableArea: 'surface_utile_piece',
}

// Types d'ouvrages
export const CODES_BIM_OUVRAGES = {
    facing_ceiling: 'revetement_de_plafond',
    facing_floor: 'revetement_de_sol',
    facing_wall: 'revetement_de_mur',
    facing_facade: 'revetement_de_facade',
    facing_roof: 'revetement_de_toiture',
    roof: 'charpente',
    dalle: 'dalle',
    garde_corps: 'garde_corps',
    mur: 'mur',
    escalier: 'escalier',
    poteau: 'poteau',
    poutres: 'poutres',
    reseaux_interieurs: 'reseaux_interieurs',
    voirie: 'voirie',
    cours: 'cours',
    chemin_pieton: 'chemin_pieton',
    pelouse: 'pelouse',
    clotures: 'clotures',
    element_vegetal: 'element_vegetal',
    fenetre: 'fenetre',
    fenetre_de_toit: 'fenetre_de_toit',
    porte: 'porte',
    lanterneau: 'lanterneau',
};

// Paramètres des ouvrages
export const CODES_BIM_PARAMETRES_OUVRAGES = {

    // Revêtements
    /**
     * Id BBP du mur sur lequel est posé un revêtement de mur
     */
    wall_facing: 'mur_revetement_de_mur',
    /**
     * Surface d'un revêtement
     */
    facing_surface: 'surface_revetement',
    interiorWallFacing: 'type_revetement_de_mur',
    exteriorWallFacing: 'exterior_wall_facing',
    floorFacing: 'type_revetement_de_sol',
    ceilingFacing: 'type_revetement_de_plafond',
    // Parois
    wallStructure: 'materiaux_structure',
    wallThickness: 'epaisseur_parois',
    wallLength: 'longueur_parois',
    wallHeight: 'hauteur_parois',
    wallUValue: 'coefficient_u_parois',
    wallInsulationWidth: 'epaisseur_isolant',
    wallInsulationWidth2: 'epaisseur_isolant_2',
    wallInsulationMaterial: 'materiaux_isolant',
    wallInsulationMaterial2: 'materiaux_isolant_2',
    wallFacing: 'parement_exterieur',
    wallLightStructure: 'parement_interieur',
    // Ouvrants
    openingThickness: 'largeur_menuiseries',
    openingHeight: 'hauteur_menuiseries',
    openingWidth: 'longueur_menuiseries',
    openingShift: 'decalage_menuiseries',
    openingArea: 'surface_menuiseries',
    openingGlazingArea: 'surface_vitree_menuiseries',
    openingFrame: 'materiau_menuiseries',
    openingGlaze: 'vitrage_menuiseries',
    openingGlazingGaz: 'lame_air_gaz_menuiseries',
    openingGlazingThickness: 'lame_air_epaisseur_menuiseries',
    openingOpener: 'type_ouverture_menuiseries',
    openingPanels: 'nombre_panneaux',
    openingTransom: 'imposte_menuiseries',
    openingSill: 'allege_fenetre',
    openingClosing: 'volet_fenetre',
    openingUw: 'uw_menuiseries',
    openingSw: 'facteur_solaire_menuiseries',
    openingTl: 'tl_menuiseries',
    // Balcons
    balconyThickness: 'largeur_menuiseries',
    balconyHeight: 'hauteur_menuiseries',
    // Poutres
    beamWidth: 'largeur_poutre',
    beamThickness: 'longueur_poutre',
    beamBBWidth: 'largeur_bb_structure',
    beamBBLength: 'longueur_bb_structure',
    beamBBHeight: 'hauteur_bb_structure',
    beamMaterial: 'materiaux_poutre',
    beamShape: 'forme_poutre',
    // Conduits
    pipeLength: 'longueur',
    pipeDiameter: 'diametre',
    pipeMaterial: 'reseau_materiau',
    // Escaliers
    stairHeight: 'hauteur_contre_marche_escalier',
    stairNumber: 'nombre_de_marches_escalier',
    stairDepth: 'profondeur_marche',
    // Toiture
    roofArea: 'surface_charpente',
    roofThickness: 'epaisseur_charpente',
    // Équipements
    objectBBHeight: 'hauteur_bb_equipements',
    objectBBWidth: 'largeur_bb_equipements',
    objectBBLength: 'longueur_bb_equipements',
    // Bâti
    /**
     * Couleur d'un élément du bâti
     */
    building_color: 'couleur_elements_du_bati',
    slabStructure: 'materiaux_structure',
    slabThickness: 'epaisseur_parois',
    slabWidth: 'largeur_parois',
    slabLength: 'longueur_parois',
}

// Énumérations de valeurs pour les paramètres des ouvrages
export const ENUMS_PARAMETRES_OUVRAGES = {
    interiorWallFacingTypes: {
        concrete: 'Brut',
        brick: 'Brut',
        paint: 'Peinture',
        fibreglass: 'Papier peint',
        wall_tiles: 'Carrelage',
        wood_wall: 'Lambris',
        carpet: 'Moquette',
    },
    exteriorWallFacingTypes: {
        concrete: 'Brut',
        brick: 'Brut',
        paint: 'Enduit',
        wood_facade: 'Bardage bois',
        alu_facade: 'Bardage métal',
    },
    floorFacingTypes: {
        concrete: 'Béton',
        tiles: 'Carrelage',
        wood: 'Parquet massif',
        wood_tiles: 'Parquet flottant',
        carpet: 'Moquette',
        vinyl: 'Lino',
    },
    ceilingFacingTypes: {
        concrete: 'Béton',
        paint: 'Peinture',
        fibreglass: 'Fibre de verre',
        wood_wall: 'Lambris',
    },
    wallStructures: {
        concrete: 'Béton',
        block: 'Parpaing',
        brick: 'Brique',
        stone: 'Pierre',
        wood: 'Bois'
    },
    wallInsulators: {
        insulator: 'Isolant minéral',
        wood_fiber: 'Isolant végétal',
        styrofoam: 'Isolant synthétique',
        air_layer: 'Lame d\'air',
    },
    wallFacings: {
        cement: 'Enduit',
        tiles: 'Bardage',
        ventilated_tiles: 'Bardage ventilé'
    },
    wallLightStructures: {
        gypsum: 'Plâtre'
    },
    openingFrames: {
        alu: 'Aluminium',
        pvc: 'PVC',
        wood: 'Bois',
        steel: 'Acier'
    },
    openingGlazings: {
        single: 'Simple',
        double: 'Double standard',
        triple: 'Triple'
    },
    openingTypes: {
        sliding: 'Coulissante',
        french: 'Battante',
        vertical: 'A guillotine'
    },
    openingTransoms: {
        none: 'Aucun',
        rectangle: 'Rectangulaire',
        round: 'Arrondi',
        triangle: 'Triangulaire'
    },
    openingSills: {
        none: 'Aucun',
        glazed: 'Vitrée',
        opaque: 'Opaque'
    },
    openingClosings: {
        none: 'Aucune',
        rolling: 'Roulant',
        swing: 'Battant',
        blind: 'Store'
    },
    beamMaterials: {
        concrete: 'béton',
        wood: 'bois',
        steel: 'métal'
    },
    beamShapes: {
        rectangle: 'Rectangulaire',
        circle: 'Circulaire'
    },
    pipeMaterials: {
        pvc: 'PVC',
        copper: 'Cuivre',
        per: 'PER',
        other: 'Autre',
    },
    slabStructures: {
        concrete: 'Béton',
        block: 'Parpaing',
        brick: 'Brique',
        stone: 'Pierre',
        wood: 'Bois'
    },
}

// Types de datasets
export const CODE_BIM_OBSERVATION = 'observation'
export const CODE_BIM_COMMENTAIRE = 'commentaire'
export const CODE_BIM_ANOMALIE = 'anomalie'
export const CODE_BIM_PRELEVEMENT_AMIANTE = 'diagnostic_action_amiante'

// Paramètres des datasets
export const CODES_BIM_PARAMETRES_DATASET_COMMENTAIRE = {
    titre: 'description',
    description: 'commentaire',
}

export const CODES_BIM_PARAMETRES_PRELEVEMENT_AMIANTE = {
    type_conclusion: 'type_conclusion',
}
