'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_walls : a SVG tool to build walls
//***********************************************************************************
//***********************************************************************************

import { cn_wall, CN_WALL_COSINE_SHAPE_THRESHOLD } from '../model/cn_wall';
import { cn_vertex } from '../model/cn_vertex';
import { cn_add, cn_cart, cn_clone, cn_dist, cn_dot, cn_flip, cn_intersect_line, cn_middle, cn_mul, cn_normal, cn_normalize, cn_polar, cn_sub } from '../utils/cn_utilities';
import { cn_snap } from './cn_snap';
import { cn_pastille } from './cn_pastille';
import { cn_svg_tool_creation } from './cn_svg_tool_creation';
import { cn_polygon_handler } from './cn_polygon_handler';
import { cn_wall_type } from '../model/cn_wall_type';
import { cn_fence_type } from '../model/cn_fence_type';
import { cn_balcony_type } from '../model/cn_balcony_type';
import { cn_edition_handler } from './cn_edition_handler';
import { cn_camera, cn_element, extension_instance, logger } from '..';
import { cn_wall_handler } from './cn_wall_handler';
import { cn_measure } from './cn_measure';

export const CN_CREATION_MODE_RECTANGLE = 0;
export const CN_CREATION_MODE_POLYLINE = 1;

//***********************************************************************************
//**** Internal calss to manage wall measures
//***********************************************************************************
class cn_tmp_wall_measure {
    constructor(parent, wall) {
        this._parent = parent;

        this._wall = wall;
        this._original_positions = [cn_clone(wall.vertices[0].position), cn_clone(wall.vertices[1].position)];
        this._max_distance = 0;

        this._lock = -1;

        this._measures = [new cn_measure(parent), new cn_measure(parent)];
        this._measures[0].set_measure = (value, d) => {
            this.set_measure(0, value)
        };
        this._measures[1].set_measure = (value, d) => {
            this.set_measure(1, value)
        };
    }

    /**
     * Sets the wall, if different from curent one
     * @param {cn_wall} wall
     * @returns
     */
    set_wall(wall) {
        if (wall == this._wall) return;
        this._wall = wall;
        this._original_positions = [cn_clone(wall.vertices[0].position), cn_clone(wall.vertices[1].position)];
    }

    /**
     *
     */
    fix_wall(max_distance) {
        this._original_positions = [cn_clone(this._wall.vertices[0].position), cn_clone(this._wall.vertices[1].position)];
        this._max_distance = max_distance;
    }

    /**
     * Sets selectable
     * @param {boolean} selectable
     */
    set_selectable(selectable) {
        this._measures.forEach(m => m.selectable = (selectable) ? 0 : -1);
    }

    /**
     * Draw method
     * @param {cn_camera} camera
     * @returns
     */
    draw(camera, selectable) {
        this._measures[0].vertices[0] = this._wall.measure_points[0][0];
        this._measures[0].vertices[1] = this._wall.measure_points[0][1];
        this._measures[1].vertices[0] = this._wall.measure_points[1][0];
        this._measures[1].vertices[1] = this._wall.measure_points[1][1];
        return this._measures[0].draw_with_highlight(camera, this._lock == 0) + this._measures[1].draw_with_highlight(camera, this._lock == 1);
    }

    clear_move() {
        this._measures.forEach(m => m.clear_move());
    }

    move(ev) {
        if (this._measures[0].move(ev)) return true;
        if (this._measures[1].move(ev)) return true;
        return false;
    }

    click(ev) {
        return this._measures.some(m => m.click(ev));
    }

    grab(ev) {
        return this._measures.some(m => m.grab(ev));
    }

    set_measure(side, value) {
        const offset = value - this._measures[side]._position[2];
        const position = cn_add(this._wall.vertices[1].position, cn_mul(this._wall.bounds.direction, offset));
        if (!this._parent.set_vertex_position(this._wall.vertices[1], position)) return;
        this._lock = side;
        this._original_positions[1] = cn_clone(position);
    }
}

//***********************************************************************************
//**** cn_svg_tool_walls : a SVG tool to build walls
//***********************************************************************************
export class cn_svg_tool_wall_creation extends cn_svg_tool_creation {
    constructor(map, filters = ['wall', 'balcony'], default_mode = 0) {
        super(map);

        this._mode = default_mode;
        this._current_point = [0, 0];

        this._filters = filters;
        this._current_wall_type = null;
        if (filters.indexOf('wall') >= 0)
            this._current_wall_type = this._scene.building.get_wall_types()[0];
        if (filters.indexOf('balcony') >= 0)
            this._current_wall_type = this._scene.building.get_balcony_types()[0];
        if (filters.indexOf('fence') >= 0)
            this._current_wall_type = this._scene.building.get_fence_types()[0];
        this._current_axis = extension_instance.config.default_axis_wall;
        this.clear_selection();

        this._check_creation = new cn_pastille([0, 0], 'check_white.svg');
        this._check_creation.svg_class = 'pastille_check';

        this._undo_last_wall = new cn_pastille([0, 0], 'close_white.svg');
        this._undo_last_wall.svg_class = 'pastille_undo';

        this._mouseover_previous_point = false;
        this._selected_previous_point = false;
        this._mouseover_delegate = null;

        this._back_scene = null;

        this._split_wall_mode = false;
        this._handler = null;

        this._visible = true;

        this.element_filter = element => {
            return element.constructor == cn_wall || element.constructor == cn_vertex
        };
        this._wall_measures = [];

        this._max_distance = 0;
    }

    //***********************************************************************************
    //**** Refresh transaction
    //***********************************************************************************
    transaction_refresh() {
        this.clear_selection();
    }

    is_creating() {
        if (this._handler) return this._handler.is_creating();
        return this._tmp_vertices.length > 1;
    }

    //***********************************************************************************
    //**** element types
    //***********************************************************************************
    get_element_types() {
        var lst = [];
        if (this._filters.indexOf('wall') >= 0)
            lst = lst.concat(this._scene.building.get_wall_types());
        if (this._filters.indexOf('balcony') >= 0)
            lst = lst.concat(this._scene.building.get_balcony_types());
        if (this._filters.indexOf('fence') >= 0)
            lst = lst.concat(this._scene.building.get_fence_types());
        return lst;
    }

    //***********************************************************************************
    //**** filters
    //***********************************************************************************
    set_filters(filters) {
        this._filters = filters;
        var element_types = this.get_element_types();
        var et = (element_types.length > 0) ? element_types[0] : null;
        this.set_current_wall_type(et);
    }

    //***********************************************************************************
    //**** current mode
    //***********************************************************************************
    /**
     * Returns the current creation mode. Can be :
     * - 0 : rectangle
     * - 1 : polyline
     * @returns {number}
     */
    get_mode() {
        return this._mode;
    }

    /**
     * Sets the current creation mode.
     * @param {number} mode
     */
    set_mode(mode) {
        if (mode == this._mode) return;
        this._mode = mode;
        this.clear_selection();
    }

    /**
     * Sets / unsets split mode
     * @param {boolean} v
     * @returns
     */
    set_split_wall_mode(v) {
        if (this._split_wall_mode == v) return;
        this._split_wall_mode = v;
        this.clear_selection();
        this._terminate_edition();
    }

    /**
     * Returns current split mode
     * @returns {boolean}
     */
    get_split_wall_mode() {
        return this._split_wall_mode;
    }

    //***********************************************************************************
    //**** Returns current wall
    //***********************************************************************************
    get_current_wall_type() {
        return this._current_wall_type;
    }

    set_current_wall_type(wt) {
        if (this._current_wall_type == wt) return;
        this._current_wall_type = wt;
        this.clear_selection();
        this._terminate_edition();
    }

    /**
     * Checks if current wall type exists.
     * If not, will use the first wall type available.
     */
    check_current_element_type() {
        var element_types = this.get_element_types();
        if (element_types.indexOf(this._current_wall_type) >= 0) return;
        var et = (element_types.length > 0) ? element_types[0] : null;
        this.set_current_wall_type(et);
    }

    /**
     * Select elements by wall type
     * @param {cn_wall_type | cn_balcony_type | cn_fence_type} wt
     */
    select_elements_by_type(wt) {
        this._initiate_edition(this._scene.walls.filter(w => w.wall_type == wt));
    }

    //***********************************************************************************
    //**** Returns current axis
    //***********************************************************************************
    get_current_axis() {
        return this._current_axis;
    }

    set_current_axis(axis) {
        this._current_axis = axis;
        this._terminate_edition();
    }

    //***********************************************************************************
    //**** Open tool
    //***********************************************************************************
    open_tool() {
        super.open_tool();
        this.clear_selection();
    }

    close_tool() {
        super.close_tool();
        this.clear_selection();
    }

    //***********************************************************************************
    //**** Clear selection
    //***********************************************************************************
    clear_selection() {
        this._can_close_contour = false;
        this._mouseover_vertex = null;
        this._mouseover_delegate = null;
        this._mouseover_wall = null;
        this._tmp_vertices = [];
        this._tmp_walls = [];
        this._dummy_start_vertices = [];
        this._dummy_start_walls = [];
        this._dummy_end_vertices = [];
        this._dummy_end_walls = [];
        this._vertex_mode = 0;
        this._snap_svg = '';
        if (this._mode == 0)
            this.initiate_rectangle();
        else
            this._handler = null;
        this._scene.update_vertices();
    }

    //***********************************************************************************
    //**** Draws  specific svg for the tool. Returns svg string
    //***********************************************************************************
    draw(camera) {
        var html = super.draw(camera);

        if (!this._visible && !this.is_creating()) return html;

        var nb_walls = this._tmp_walls.length;
        if (this._vertex_mode < 0)
            nb_walls--;

        var vertices = [];
        vertices = vertices.concat(this._tmp_vertices);
        vertices = vertices.concat(this._dummy_start_vertices);
        vertices = vertices.concat(this._dummy_end_vertices);

        if (this._tmp_walls.length && this._mouseover_vertex && this._tmp_vertices.indexOf(this._mouseover_vertex) >= 0)
            this._tmp_walls[this._tmp_walls.length - 1].vertices[1] = this._mouseover_vertex;

        var walls = [];
        walls = walls.concat(this._tmp_walls);
        if (this._vertex_mode < 0)
            walls.splice(this._tmp_walls.length - 1, 1);
        walls = walls.concat(this._dummy_start_walls);
        walls = walls.concat(this._dummy_end_walls);

        for (var i in vertices)
            vertices[i].walls = [];
        for (var i in walls) {
            var w = walls[i];
            w.vertices[0].walls.push(w);
            w.vertices[1].walls.push(w);
        }
        for (var i in vertices)
            vertices[i].update();

        this._scene.update_walls(walls, false);

        //*** Measures */
        while (this._wall_measures.length < nb_walls) this._wall_measures.push(new cn_tmp_wall_measure(this, this._tmp_walls[this._wall_measures.length]));
        this._wall_measures.splice(nb_walls, this._wall_measures.length - nb_walls);
        for (var ni = 0; ni < nb_walls; ni++) {
            var w = this._tmp_walls[ni];
            html += w.draw(camera);
            this._wall_measures[ni].set_wall(w);
            this._wall_measures[ni].set_selectable(this._mode == CN_CREATION_MODE_POLYLINE && ni == this._tmp_walls.length - 2)
            html += this._wall_measures[ni].draw(camera);
        }

        html += this._snap_svg;

        if (this._vertex_mode >= 0 && (this._mode == 1 || this._split_wall_mode)) {
            var p = camera.world_to_screen(this._current_point);
            let css_class = 'move_arrow_cursor';
            if (this._vertex_mode == 1) {
                css_class = 'move_arrow_cursor_one';
            } else if (this._vertex_mode == 2) {
                css_class = 'move_arrow_cursor_two';
            } else if (this._vertex_mode == 3) {
                css_class = 'move_arrow_cursor_three';
            }

            if (!this._split_wall_mode || (this._split_wall_mode && this._vertex_mode !== 1)) {
                html += `<circle class="${css_class}" cx="${p[0]}" cy="${p[1]}" r="3" />`;
                html += camera.draw_move_arrow(this._current_point, 'move_arrow');
            }
            if (this._split_wall_mode && this._vertex_mode === 1) {
                html += camera.draw_scissor(this._current_point);
            }
        }

        //*** draw angle
        if (this._tmp_walls.length > 0 && this._mode == 1) {
            for (var k = 0; k < 2; k++) {
                var vertex = this._tmp_walls[this._tmp_walls.length - 1].vertices[k];
                html += vertex.draw_angles(camera);
            }

            var classes = 'move_arrow';
            if (this._mouseover_previous_point)
                classes += ' mouseover';

            html += camera.draw_move_arrow(this._tmp_walls[this._tmp_walls.length - 1].vertex_position(0), classes);
        }

        //*** draw check pastille
        this._check_creation.visible = (this._tmp_walls.length > 1 && this._mode == 1);
        this._undo_last_wall.visible = this._tmp_walls.length > 1 && this._mode == 1;
        if (this._check_creation.visible) {
            let pt = [0, 0]
            pt = cn_add(pt, cn_mul(this._tmp_walls[this._tmp_walls.length - 2].vertices[0].position, 0.75));
            pt = cn_add(pt, cn_mul(this._tmp_walls[this._tmp_walls.length - 1].vertices[0].position, 0.25));
            this._check_creation.position = pt;
            html += this._check_creation.draw(camera);
        }

        if (this._undo_last_wall.visible) {
            let pt = [0, 0]
            pt = cn_add(pt, cn_mul(this._tmp_walls[this._tmp_walls.length - 2].vertices[0].position, 0.25));
            pt = cn_add(pt, cn_mul(this._tmp_walls[this._tmp_walls.length - 1].vertices[0].position, 0.75));
            this._undo_last_wall.position = pt;
            html += this._undo_last_wall.draw(camera);
        }

        //*** we call the handler drawing method, but we don't use it */
        if (this._handler) this._handler.draw(camera);

        //*** restore walls and vertices */
        if (this._tmp_walls.length) {
            this._tmp_walls[this._tmp_walls.length - 1].vertices[1] = this._tmp_vertices[this._tmp_vertices.length - 1];

            for (var i in vertices)
                vertices[i].walls = [];
            for (var i in walls) {
                var w = walls[i];
                w.vertices[0].walls.push(w);
                w.vertices[1].walls.push(w);
            }
            for (var i in vertices)
                vertices[i].update();

            this._scene.update_walls(walls, false);
        }

        return html;
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************
    clear_move() {
        this._visible = false;
        this._wall_measures.forEach(w => w.clear_move());
        super.clear_move();
    }

    click(ev) {
        if (super.click(ev)) return true;

        if (this._wall_measures.some(wm => wm.click(ev)))
            return true;

        if (this._handler && !this._split_wall_mode)
            return this._handler.drop(ev);

        //*** Split wall?
        if (this._split_wall_mode) {
            this.split_wall(ev);
            return true;
        } else {
            //*** maybe end of creation by checking ?
            if (this._check_creation.visible) {
                this._check_creation.mouseover = this._check_creation.contains(ev.mouse_world, ev.camera);
                if (this._check_creation.mouseover) {
                    this._can_close_contour = true;
                    this.close_contour(true);
                    return true;
                }
            }

            if (this._undo_last_wall.visible) {
                this._undo_last_wall.mouseover = this._undo_last_wall.contains(ev.mouse_world, ev.camera);
                if (this._undo_last_wall.mouseover) {
                    this.remove_last_wall();
                    this.move(ev);
                    return true;
                }
            }

            return this.drop(ev);
        }
    }

    grab(ev) {
        if (super.grab(ev)) return true;

        if (this._wall_measures.some(wm => wm.grab(ev)))
            return true;

        if (this._split_wall_mode) return true;

        if (this._handler && !this._split_wall_mode)
            return this._handler.grab(ev);

        if (this._mouseover_previous_point) {
            this.remove_last_wall();
            this.move(ev);
        }
        return true;
    }

    drop(ev) {
        if (super.drop(ev)) return true;

        if (this._split_wall_mode) return true;

        if (this._handler && !this._split_wall_mode)
            return this._handler.drop(ev);

        this.update_contour(ev, false);

        //*** we initiate a contour
        if (this._tmp_walls.length == 0) {
            this.initiate_contour();
        } else if (this._can_close_contour) {
            this.close_contour();
            return true;
        } else if (this._vertex_mode >= 0)
            this.resume_contour();

        this.update_contour(ev, false);
        return true;
    }

    move(ev) {
        if (super.move(ev)) return true;

        if (this._wall_measures.some(wm => wm.move(ev))) {
            this._vertex_mode = -1;
            return true;
        }

        this._visible = true;
        if (this._handler && !this._split_wall_mode)
            return this._handler.move(ev);

        this.update_contour(ev, false);
        return true;
    }

    drag(ev) {
        if (super.drag(ev)) return true;

        if (this._handler)
            return this._handler.drag(ev);

        this.update_contour(ev, true);
        return true;
    }

    keydown(ev) {
        let result = false;
        if (ev.key === 'Escape' || ev.key === 'Backspace') {
            if (this._split_wall_mode) {
                this.set_split_wall_mode(false);
                this.call('end_split_mode');
                result = true;
            } else if (this._handler && this._handler.is_creating()) {
                this.clear_selection();
                result = true;
            } else if (!this._handler && this._tmp_walls && this._tmp_walls.length) {
                this.remove_last_wall();
                this.move(ev);
                result = true;
            }
        }
        return result;
    }

    //***********************************************************************************
    //**** remove last wall
    //***********************************************************************************
    remove_last_wall() {
        this._tmp_walls.splice(this._tmp_walls.length - 1, 1);
        if (this._tmp_walls.length == 0)
            this._tmp_vertices = [];
        else
            this._tmp_vertices.splice(this._tmp_vertices.length - 1, 1);
    }

    //***********************************************************************************
    //**** Initiate a contour
    //***********************************************************************************
    initiate_contour() {
        this.is_transient = true;
        var v0 = null;
        this._start_vertex = null;
        this._start_space = null;
        this._start_wall = null;
        this._current_space = null;
        if (this._mouseover_vertex) {
            this._start_vertex = this._mouseover_vertex;
            v0 = new cn_vertex(this._start_vertex.position);
            this._tmp_vertices.push(v0);
            this.build_dummy_data_from_vertex(v0, this._start_vertex, this._dummy_start_vertices, this._dummy_start_walls);
        } else if (this._mouseover_wall) {
            this._start_wall = this._mouseover_wall;
            v0 = new cn_vertex(this._current_point);
            this._start_vertex = v0;
            this._tmp_vertices.push(v0);
            this.build_dummy_data_from_wall(v0, this._start_wall, this._dummy_start_vertices, this._dummy_start_walls);
        } else {
            this._start_space = this._scene.find_space(this._current_point);
            if (this._start_space == null) return false;
            this._current_space = this._start_space;
            v0 = new cn_vertex(this._current_point);
            this._tmp_vertices.push(v0);
        }

        var v1 = new cn_vertex(this._current_point);
        this._tmp_vertices.push(v1);
        const ww = new cn_wall(v0, v1, this._current_wall_type, this._current_axis, this._scene);
        if (this._mouseover_delegate && this._mouseover_vertex)
            ww.delegates[0] = this._mouseover_delegate;
        this._tmp_walls.push(ww);
        this._can_close_contour = false;
        this._terminate_edition();
    }

    //***********************************************************************************
    //**** Resume contour
    //***********************************************************************************
    resume_contour() {
        this.is_transient = true;
        if (this._current_space == null) {
            this._current_space = this._scene.find_space(this._current_point);
            if (this._current_space == null) return false;
        }
        var v0 = new cn_vertex(this._current_point);
        var wt = this._tmp_walls[0].wall_type;
        this._tmp_walls[this._tmp_walls.length - 1].build_self();

        this._tmp_walls.push(new cn_wall(this._tmp_vertices[this._tmp_vertices.length - 1], v0, wt, this._current_axis, this._scene));
        this._tmp_vertices.push(v0);

        while (this._wall_measures.length < this._tmp_walls.length)
            this._wall_measures.push(new cn_tmp_wall_measure(this, this._tmp_walls[this._wall_measures.length]));
        this._wall_measures[this._tmp_walls.length - 2].fix_wall(this._max_distance);
    }

    //***********************************************************************************
    //**** Close contour
    //***********************************************************************************
    close_contour(force_end = false) {
        this.is_transient = false;
        if (force_end)
            this._mouseover_vertex = this._tmp_walls[this._tmp_walls.length - 1].vertices[0];

        for (var i in this._tmp_vertices)
            this._tmp_vertices[i].walls = [];

        //*** can we decide in which space we play ?
        if (this._current_space == null) {
            this._current_space = this._scene.find_space(cn_middle(this._current_point, this._tmp_walls[0].vertices[0].position));
            if (this._current_space == null) return false;
            logger.log('We are in space ' + this._current_space.name, this._current_point, this._tmp_walls[0].vertices[0].position);
        }

        //*** replace start vertex
        if (this._start_vertex && this._start_vertex != this._tmp_vertices[0]) {
            this._tmp_vertices[0] = this._start_vertex;
            this._tmp_walls[0].vertices[0] = this._start_vertex;
        }

        //*** do we end on an existing vertex ? if yes we remove it from the new wall.
        if (this._mouseover_vertex) {
            this._tmp_vertices.splice(this._tmp_vertices.length - 1, 1);
            var last_wall = this._tmp_walls[this._tmp_walls.length - 1];
            last_wall.vertices[1] = this._mouseover_vertex;

            //*** maybe we doubled the last vertex ?
            if (last_wall.vertices[0] == last_wall.vertices[1])
                this._tmp_walls.splice(this._tmp_walls.length - 1, 1);
            else if (this._mouseover_delegate)
                last_wall.delegates[1] = this._mouseover_delegate;
        }
        if (this._tmp_walls.length == 0) return false;

        //*** Start transaction now
        var scene = this._scene;
        if (this._tmp_walls[0].balcony)
            this.push_transaction('Création de balcon', '', () => {
                scene.full_update(true);
            });
        else
            this.push_transaction('Création de mur', '', () => {
                scene.full_update(true);
            });

        function add_or_remove_wall(w) {
            if (scene.walls.indexOf(w) >= 0)
                scene.remove_wall(w);
            else
                scene.insert_wall(w);
        }

        //*** maybe split start wall
        var new_start_wall = null;
        if (this._start_wall) {
            var start_wall = this._start_wall;
            var start_vertex = this._tmp_vertices[0];
            logger.log('split  start wall');
            var new_start_wall = scene.split_wall(start_wall, start_vertex);

            this.push_item_set(start_wall, [], () => {
                if (scene.walls.indexOf(new_start_wall) >= 0)
                    scene.merge_wall(start_wall, new_start_wall);
                else
                    scene.split_wall(start_wall, start_vertex, new_start_wall);
            });
        }

        //*** maybe split end wall
        if (this._mouseover_wall) {
            if (this._mouseover_wall == this._start_wall) {
                var dir = cn_sub(this._start_wall.vertices[1].position, this._start_wall.vertices[0].position);
                cn_normalize(dir);
                var d = cn_sub(this._tmp_vertices[this._tmp_vertices.length - 1].position, this._start_wall.vertices[1].position);
                var dist = cn_dot(d, dir);
                if (Math.abs(dist) < 0.01) {
                    this._mouseover_wall = null;
                    this._mouseover_vertex = this._tmp_vertices[0];
                    this._mouseover_delegate = null;
                    this._tmp_vertices[this._tmp_vertices.length - 1] = this._mouseover_vertex;
                    this._tmp_walls[this._tmp_walls.length - 1].vertices[1] = this._mouseover_vertex;
                } else if (cn_dot(d, dir) > 0)
                    this._mouseover_wall = new_start_wall;
            }
            if (this._mouseover_wall) {
                var end_wall = this._mouseover_wall;
                var end_vertex = this._tmp_vertices[this._tmp_vertices.length - 1];
                logger.log('split end wall');
                var new_end_wall = scene.split_wall(end_wall, end_vertex);
                if (this._tmp_walls.indexOf(end_wall) >= 0) this._tmp_walls.push(new_end_wall);
                this.push_item_set(end_wall, [], () => {
                    if (scene.walls.indexOf(new_end_wall) >= 0)
                        scene.merge_wall(end_wall, new_end_wall);
                    else
                        scene.split_wall(end_wall, end_vertex, new_end_wall);
                });
            }
        }

        const new_walls = this._tmp_walls;
        for (var i in this._tmp_walls) {
            logger.log('insert wall');
            this._scene.insert_wall(this._tmp_walls[i]);
            this.push_item_set(this._tmp_walls[i], [], add_or_remove_wall);
        }
        this._scene.full_update();
        this.clear_selection();
        this.call('creation');
        this._initiate_edition(new_walls);
    }

    //***********************************************************************************
    //**** update contour
    //***********************************************************************************
    set_vertex_position(vertex, position) {
        vertex.position = position;
        this._map.refresh_tool();
        return true;
    }

    /**
     * Adjust a tmp wall where one measure is fixed.
     * Input can be :
     * - Either one point that belongs to the ucoming wall axis
     * - Either the direction of the upcoming wall
     * @param {cn_tmp_wall_measure} wall_measure
     * @param {number} vertex_index
     * @param {number[]} point
     * @param {number[]} direction
     */
    adjust_vertex(wall_measure, vertex_index, point = null, direction = null) {
        //*** Initiate vertex position to original position */
        wall_measure._wall.vertices[vertex_index].position = wall_measure._original_positions[vertex_index];

        //*** Nothing more to do if no constraint */
        if (!point && !direction) return true;
        if (wall_measure._lock < 0) return true;

        //*** check which side is locked */
        const l0 = (wall_measure._lock == 1 - vertex_index);

        //*** Compute direction (that ends on interest vertex) */
        const D = (vertex_index == 1) ? wall_measure._wall.bounds.direction : cn_mul(wall_measure._wall.bounds.direction, -1);
        if (direction && !(Math.abs(cn_dot(direction, D)) < CN_WALL_COSINE_SHAPE_THRESHOLD)) return true;
        const N = cn_normal(D);

        //*** V1 is the original position of the vertex */
        const V1 = wall_measure._original_positions[vertex_index];

        //*** y gives hint of the side of the wall the adjustment is to be done */
        const y = (point) ? cn_dot(N, cn_sub(point, V1)) : cn_dot(N, direction);
        const ymin = (vertex_index == 1) ? wall_measure._wall.bounds.y0 : -wall_measure._wall.bounds.y1;
        const ymax = (vertex_index == 1) ? wall_measure._wall.bounds.y1 : -wall_measure._wall.bounds.y0;

        //*** upcoming wall is nearly parallel to the locked wall. We cannot compute */
        if (point && y <= ymax && y >= ymin) return false;

        //*** s0 is true if lower side is seen by the new point */
        const s0 = (y < 0);

        //*** e is the distance to be added to the fixed point of the measure, orthognally to the upcoming wall */
        var e = (s0) ? -ymin : ymax;
        if (s0 != l0) e = ymax - ymin - e;

        //*** if e is 0, (the locked side is the wall axis) no adjustment needs to be done */
        if (e == 0) {
            wall_measure._wall.vertices[vertex_index].position = V1;
            return true;
        }

        //*** P0 is the end of the locked side measure. We must transform the wall so that this point doesn't move */
        const P0 = cn_add(V1, cn_mul(N, l0 ? ymin : ymax));

        //*** if point is defined, compute wall direction */
        if (point) {
            //*** dx, dy is an axis system that starts at P0. */
            const dx = cn_sub(P0, point);
            cn_normalize(dx);
            var dy = cn_normal(dx);
            if ((cn_dot(dy, D) < 0) == (s0 == l0)) dy = cn_mul(dy, -1);

            //*** a little trigonometry... */
            const sina = e / cn_dist(P0, point);
            const cosa = Math.sqrt(1 - sina * sina);
            const Pp0 = cn_add(P0, cn_mul(dy, e / cosa));
            direction = cn_sub(Pp0, point);
            cn_normalize(direction);
            if (!(Math.abs(cn_dot(direction, D)) < CN_WALL_COSINE_SHAPE_THRESHOLD)) return true;
        }
        //*** if wall direction is defined, compute point */
        else {
            var dy = cn_normal(direction);
            if ((cn_dot(dy, D) < 0) == (s0 == l0)) dy = cn_mul(dy, -1);
            point = cn_add(P0, cn_mul(dy, e));
        }

        //*** new vertex is intersection betwen current locked side and upcoming wall axis */
        const V = cn_intersect_line(point, direction, V1, D);
        if (!V) return false;

        //*** Maybe the offset is not allowed, for there is an intersection with other walls */
        if (vertex_index == 1 && cn_dist(wall_measure._original_positions[0], V) > wall_measure._max_distance) {
            return false;
        }

        wall_measure._wall.vertices[vertex_index].position = V;
        return true;
    }

    //***********************************************************************************
    //**** update contour
    //***********************************************************************************

    update_contour(ev, dragging) {
        this.is_transient = true;
        if (this._current_wall_type == null) return false;
        this._snap_svg = '';
        this._vertex_mode = 0;
        this._can_close_contour = false;
        this._mouseover_vertex = null;
        this._mouseover_delegate = null;
        this._mouseover_wall = null;
        this._mouseover_delegate = null;
        this._dummy_end_vertices = [];
        this._dummy_end_walls = [];
        this._mouseover_previous_point = false;
        this._check_creation.mouseover = false;
        this._check_creation.mouseover = false;
        /** Check if we are over creation checkbox */
        if (!dragging) {
            if (this._check_creation.visible) {
                this._check_creation.mouseover = this._check_creation.contains(ev.mouse_world, ev.camera);
                if (this._check_creation.mouseover) {
                    this._vertex_mode = -1;
                    return true;
                }
            }
            if (this._undo_last_wall.visible) {
                this._undo_last_wall.mouseover = this._undo_last_wall.contains(ev.mouse_world, ev.camera);
                if (this._undo_last_wall.mouseover) {
                    this._vertex_mode = -1;
                    return true;
                }
            }
        }

        var active_wall = null;
        var nb_tmp_walls = this._tmp_walls.length;
        if (nb_tmp_walls > 0)
            active_wall = this._tmp_walls[nb_tmp_walls - 1];

        var previous_point = (active_wall) ? active_wall.vertex_position(0) : null;

        var range = this._current_wall_type.thickness;
        if (ev.camera.snap_world_distance > range)
            range = ev.camera.snap_world_distance;
        var snap = new cn_snap(ev.mouse_world, range, previous_point, ev.camera);
        snap.draw_svg_angles = false;

        logger.log(`Snap position start : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`)

        //*** Try to find if current wall being drawed intersects an existing wall
        if (active_wall) {
            const aw0 = active_wall.vertex_position(0);
            var direction = cn_sub(ev.mouse_world, aw0);
            var max_distance_init = cn_normalize(direction);
            var max_distance = max_distance_init + 100;
            if (max_distance_init > 0.01) {
                var impact = null;
                if (nb_tmp_walls == 1)
                    impact = this._scene.raytrace(aw0, direction, max_distance, this._start_vertex, this._start_wall);
                else
                    impact = this._scene.raytrace(aw0, direction, max_distance);
                if (impact) max_distance = impact.distance;

                for (var ni = 0; ni < nb_tmp_walls - 2; ni++) {
                    var rr = this._tmp_walls[ni].raytrace(aw0, direction, max_distance);
                    if (rr) {
                        impact = rr;
                        max_distance = impact.distance;
                    }
                }

                if (impact && max_distance < max_distance_init) {
                    snap.start_position = impact.point;
                    snap.position = impact.point;
                    snap.check_wall_delegates(this._current_axis, this._current_wall_type.thickness, impact.wall);
                    snap.check_wall(impact.wall);
                }
            }
            this._max_distance = max_distance;
        }

        logger.log(`Snap position -2 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`)
        //*** find wall under the mouse
        if (snap.wall == null) {
            var display_log = false;
            logger.log('snap on delegates');
            for (var i in this._scene.walls) {
                const wall = this._scene.walls[i];
                logger.log(`wall : [${wall.vertices[0].position[0]},${wall.vertices[0].position[1]}] - [${wall.vertices[1].position[0]},${wall.vertices[1].position[1]}]`);
                snap.check_wall_delegates(this._current_axis, this._current_wall_type.thickness, wall, display_log);
                snap.check_wall(wall, true, display_log);
            }
        }

        logger.log(`Snap position -1 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`)

        //*** Try to snap on previous walls
        if (snap.freedoms == 2) {
            for (var ni = 0; ni < nb_tmp_walls - 1; ni++) {
                if (ni > 0 || this._wall_measures[0]._lock < 0)
                    snap.check_wall_delegates(this._current_axis, this._current_wall_type.thickness, this._tmp_walls[ni]);
                snap.check_wall(this._tmp_walls[ni], true);
            }
        }
        logger.log(`Snap position 0 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`)

        snap.range = ev.camera.snap_world_distance;

        //*** snap on angles with starting wall
        if (snap.freedoms > 0 && nb_tmp_walls > 0 && this._tmp_walls[0].vertices[0].walls.length > 1) {
            var v0 = this._tmp_walls[0].vertex_position(0);
            for (var i in this._tmp_walls[0].vertices[0].walls) {
                //*** snap from previous point
                var w = this._tmp_walls[0].vertices[0].walls[i];
                if (w == this._tmp_walls[0]) continue;
                var v1 = cn_add(v0, w.bounds.direction);
                snap.check_angles(v0, v1, 45);
                if (snap.freedoms == 0) break;
            }
        }

        logger.log(`Snap position 1 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`)

        //*** snap on angles with previous wall
        if (snap.freedoms > 0 && nb_tmp_walls > 1) {
            //*** snap from previous point
            var vv0 = this._tmp_walls[nb_tmp_walls - 2].vertex_position(0);
            var vv1 = this._tmp_walls[nb_tmp_walls - 2].vertex_position(1);
            snap.check_angles(vv0, vv1, 45);
        }

        logger.log(`Snap position 2 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`);

        //*** snap on angles with first wall
        if (snap.freedoms > 0 && nb_tmp_walls > 2) {
            //*** snap from previous point
            const v0 = this._tmp_walls[0].vertex_position(0);
            const y = cn_dot(this._tmp_walls[0].bounds.normal, cn_sub(snap.position, v0));
            if (Math.abs(y) > 0.1) {
                const delta_angle = (y > 0) ? 45 : -45;
                const pol = cn_polar(this._tmp_walls[0].bounds.direction);
                for (var k = 0; k < 3; k++) {
                    pol[1] += Math.PI * delta_angle / 180;
                    const direction = cn_cart(pol);
                    logger.log('Adjusting on start wall');
                    this.adjust_vertex(this._wall_measures[0], 0, null, direction);
                    if (snap.check_line(this._tmp_walls[0].vertex_position(0), direction)) {
                        logger.log('Adjusting on start wall OK !!!', snap.freedoms)
                        break;
                    }
                    this.adjust_vertex(this._wall_measures[0], 0);
                }
            }
        }

        logger.log(`Snap position 3 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`);

        //*** snap on angles with surrounding walls */
        if (snap.freedoms == 2 && nb_tmp_walls > 0) {
            for (var theta = 0; theta < 360; theta += 10) {
                const impact = this._scene.raytrace(snap.position, cn_cart([1, theta * Math.PI / 180]), 100);
                if (impact && impact.wall) {
                    const p0 = impact.wall.vertex_position(0);
                    const p1 = impact.wall.vertex_position(1);
                    snap.check_parallel(p0, p1);
                    snap.check_orthogonal(p0, p1);
                }
            }
        }

        //*** Snap with neighbouring walls */
        if (snap.freedoms > 0 && nb_tmp_walls > 0) {
            const dir = cn_sub(snap.position, previous_point);
            cn_normalize(dir);
            const normal = cn_normal(dir);
            const axis = active_wall.axis;
            const thickness = active_wall.wall_type.thickness;
            var dirt = cn_mul(dir, thickness);
            var dir_off = cn_mul(dir, range);

            for (var side = 0; side < 2; side++) {
                cn_flip(normal);
                var p00 = cn_clone(snap.position);
                var x0 = 0;
                if (axis == 0)
                    x0 = -0.5;
                else if (axis == 1) {
                    if (side == 0) x0--;
                } else if (axis == 2) {
                    if (side == 1) x0--;
                }
                var x1 = x0 + 1;
                const p0 = cn_add(p00, cn_mul(dirt, x0));
                const p1 = cn_add(p0, dirt);
                const i0 = this._scene.raytrace_bounds(cn_sub(p1, dir_off), normal, 10);
                const i1 = this._scene.raytrace_bounds(cn_add(p1, dir_off), normal, 10);
                if (!i0 && !i1) continue;
                var wall = null;
                if (i0 && i1) {
                    if (i0.wall == i1.wall) continue;
                    wall = (i0.distance < i1.distance) ? i0.wall : i1.wall;
                } else if (i0)
                    wall = i0.wall;
                else
                    wall = i1.wall;

                const index = (cn_dot(dir, wall.bounds.direction) < 0) ? 0 : 1;
                const wside = (cn_dot(normal, wall.bounds.normal) < 0) ? 1 : 0;
                const pw = wall.shape[(wside == 0) ? 3 * index : 1 + index];

                const snap_direction = (snap.freedoms == 2) ? dir : snap.direction;
                const prj = cn_intersect_line(snap.position, snap_direction, pw, normal);
                if (prj) {
                    const xx = (i0 && wall == i0.wall) ? x1 : x0;
                    const new_pos = cn_sub(prj, cn_mul(dirt, xx));
                    if (cn_dist(new_pos, snap.start_position) < snap.range) {
                        snap.freedoms--;
                        snap.position = new_pos;
                        snap.direction = normal;
                        snap._add_snap_line(prj, pw);
                        if (xx == x1)
                            snap._add_snap_line(cn_sub(prj, dirt), cn_sub(pw, dirt));
                        else
                            snap._add_snap_line(cn_add(prj, dirt), cn_add(pw, dirt));
                        if (snap.freedoms == 0) break;
                    }
                }
            }
        }

        //*** find background wall under mouse
        if (snap.freedoms > 0 && this._back_scene) {
            for (var i in this._back_scene.walls) {
                if (snap.check_wall(this._back_scene.walls[i], false)) {
                    this._vertex_mode = 3;
                    break;
                }
            }
        }

        //*** snap on grid
        if (snap.freedoms > 0) {
            snap.check_grid();
        }

        //*** snap on verticals / horizontals */
        if (previous_point) {
            snap.check_line(previous_point, [1, 0]);
            snap.check_line(previous_point, [0, 1]);
        }

        this._snap_svg = snap.svg;

        this._current_point = snap.position;
        logger.log(`Snap position 4 : ${snap.position[0]} ${snap.position[1]} ${snap.freedoms} ${snap.distance}`);

        if (snap.vertex) {
            this._mouseover_vertex = snap.vertex;
            this._mouseover_delegate = snap.delegate;
            this._vertex_mode = 2;

            //*** special ending case if doubled the last point
            if (nb_tmp_walls > 0 && this._mouseover_vertex == this._tmp_walls[nb_tmp_walls - 1].vertices[0]) {
                this._vertex_mode = -1;
                this._mouseover_previous_point = true;
                return true;
            }
        } else if (snap.wall) {
            this._mouseover_wall = snap.wall;
            this._vertex_mode = 1;
        }

        //*** end if first vertex of new wall
        if (nb_tmp_walls == 0) return true;

        var vtx = this._tmp_vertices[this._tmp_vertices.length - 1];
        vtx.position = [this._current_point[0], this._current_point[1]];
        logger.log(`Vertex position : ${vtx.position[0]} ${vtx.position[1]}`)

        var prev_vertex = this._tmp_walls[nb_tmp_walls - 1].vertices[0];
        var dir = cn_sub(this._current_point, prev_vertex.position);
        if (cn_normalize(dir) < 0.1) {
            this._vertex_mode = -1;
            this._mouseover_previous_point = true;
            return true;
        }

        //*** check start
        if (nb_tmp_walls == 1) {
            if (!this.check_vertex_parallel(this._start_vertex, dir, this._tmp_walls[0]) || !this.check_wall_parallel(this._start_wall, dir)) {
                this._vertex_mode = -1;
                return true;
            }
        }

        //*** check end
        if (!this.check_vertex_parallel(this._mouseover_vertex, cn_mul(dir, -1)) || !this.check_wall_parallel(this._mouseover_wall, dir)) {
            this._vertex_mode = -1;
            return true;
        }

        //*** check broken lineHeight
        if (!this.check_broken_line(this._tmp_walls)) {
            this._vertex_mode = -1;
            return true;
        }

        //*** move current wall end to mouse position
        this._can_close_contour = (this._mouseover_vertex != null || this._mouseover_wall != null);

        if (nb_tmp_walls - 2 >= 0 && nb_tmp_walls - 2 < this._wall_measures.length && this._wall_measures[nb_tmp_walls - 2]._lock >= 0) {
            this.adjust_vertex(this._wall_measures[nb_tmp_walls - 2], 1, this._current_point, null);
        }

        if (this._wall_measures.length && this._wall_measures[0]._lock >= 0 && this._dummy_start_vertices.length == 0) {
            if (this._can_close_contour && this._mouseover_vertex == this._tmp_vertices[0]) {
                this.adjust_vertex(this._wall_measures[0], 0, this._tmp_vertices[this._tmp_vertices.length - 2].position, null);
            } else
                this.adjust_vertex(this._wall_measures[0], 0, null, null);
        }

        this._tmp_walls[nb_tmp_walls - 1].delegates[1] = null;

        //*** Build dummy end data
        if (this._can_close_contour) {
            //*** if we end of an old vertex
            if (this._mouseover_vertex) // && this._tmp_vertices.indexOf(this._mouseover_vertex) < 0)
            {
                if (this._mouseover_delegate)
                    this._tmp_walls[nb_tmp_walls - 1].delegates[1] = this._mouseover_delegate;
                else if (this._tmp_vertices.indexOf(this._mouseover_vertex) < 0)
                    this.build_dummy_data_from_vertex(vtx, this._mouseover_vertex, this._dummy_end_vertices, this._dummy_end_walls);
            }

            //*** if we end on an old wall
            if (this._mouseover_wall) // && this._tmp_walls.indexOf(this._mouseover_wall) < 0)
                this.build_dummy_data_from_wall(vtx, this._mouseover_wall, this._dummy_end_vertices, this._dummy_end_walls);

        }

        return true;
    }

    //***********************************************************************************
    //**** Split a wall
    //***********************************************************************************
    split_wall(ev) {
        this._start_wall = this._mouseover_wall;
        let range = this._current_wall_type.thickness;
        if (ev.camera.snap_world_distance > range) {
            range = ev.camera.snap_world_distance;
        }

        const snap = new cn_snap(ev.mouse_world, range, null, ev.camera);
        const scene = this._scene;

        //*** find wall under the mouse
        if (snap.wall == null) {
            for (let i in this._scene.walls) {
                if (snap.check_wall(this._scene.walls[i]))
                    break;
            }
        }
        //*** Créer un vertex pour la découpe de mur
        if (snap.freedoms === 1) {
            if (this._start_wall) {
                const start_wall = this._start_wall;
                const start_vertex = new cn_vertex(this._current_point);

                const new_start_wall = scene.split_wall(start_wall, start_vertex);

                //*** Start transaction now
                this.push_transaction('Découpe de mur', '', () => {
                    scene.full_update(true);
                });

                //*** Transaction
                this.push_item_set(start_wall, [], () => {
                    if (scene.walls.indexOf(new_start_wall) >= 0)
                        scene.merge_wall(start_wall, new_start_wall);
                    else
                        scene.split_wall(start_wall, start_vertex, new_start_wall);
                });
                scene.full_update();
            }
            this.call('creation');
        }
    }

    //***********************************************************************************
    //**** Build dummy data
    //***********************************************************************************
    build_dummy_data_from_vertex(v0, vertex, dummy_vertices, dumy_walls) {
        v0.position = cn_clone(vertex.position);

        for (var i in vertex.walls) {
            var w = vertex.walls[i];
            var dummy_wall = null;
            if (w.vertices[0] == vertex) {
                var vv = new cn_vertex(w.vertices[1].position);
                dummy_vertices.push(vv);
                dummy_wall = new cn_wall(v0, vv, w.wall_type, w.axis, this._scene);
            } else {
                var vv = new cn_vertex(w.vertices[0].position);
                dummy_vertices.push(vv);
                dummy_wall = new cn_wall(vv, v0, w.wall_type, w.axis, this._scene);
            }
            dummy_wall.delegates = w.delegates.map(dlg => (dlg) ? dlg.clone() : null);
            dumy_walls.push(dummy_wall);
        }
    }

    build_dummy_data_from_wall(v0, wall, dummy_vertices, dumy_walls) {
        dummy_vertices.push(new cn_vertex(wall.vertices[0].position));
        dummy_vertices.push(new cn_vertex(wall.vertices[1].position));
        dumy_walls.push(new cn_wall(dummy_vertices[0], v0, wall.wall_type, wall.axis, this._scene));
        dumy_walls.push(new cn_wall(v0, dummy_vertices[1], wall.wall_type, wall.axis, this._scene));
    }

    //***********************************************************************************
    //**** Find mouseover vertex
    //***********************************************************************************

    find_mouseover_vertex(camera, mouse) {
        var vertices = this._scene.vertices.concat(this._tmp_vertices);
        if (this._tmp_vertices.length > 0)
            vertices.splice(vertices.length - 1, 1);
        return super.find_mouseover_vertex(camera, mouse, vertices);
    }

    //***********************************************************************************
    //**** Find mouseover wall
    //***********************************************************************************

    find_mouseover_wall(camera, mouse) {
        var walls = this._scene.walls.concat(this._tmp_walls);
        if (this._tmp_walls.length > 0)
            walls.splice(walls.length - 1, 1);
        return super.find_mouseover_wall(camera, mouse, walls);
    }

    //***********************************************************************************
    /**
     * Initiate creation by rectangle.
     */
    initiate_rectangle() {
        var obj = this;
        var scene = obj._scene;
        this._handler = cn_polygon_handler.create_rectangle(this, 5);
        this._handler.accept_outside = true;
        this._handler.snap_elements = scene.walls;
        this._handler.display_measures = false;

        const prec = obj._current_wall_type.thickness;

        //*** Event on creation start */
        this._handler.on('start_creation', () => {
            obj._terminate_edition();
        });

        //*** reorder the list of vertices until first vertex is not on awall */
        function reorder_list(vertices_org) {
            var vertices = vertices_org.concat([]);
            const f = vertices[0];
            vertices.splice(0, 1);
            vertices.push(f);
            vertices.reverse();
            for (var i = 0; i < vertices.length; i++) {
                if (scene.find_wall(vertices[0], prec) == null) return vertices;
                var vts = vertices[0];
                vertices.splice(0, 1);
                vertices.push(vts);
            }
            return null;
        }

        //***************************************************************/
        /** Method to cut rectangle walls
         * This method assumes that the first point does not lie on an existing wall.
         * It will build tmp walls and vertices until either loop to the first point, either blocked by an existing wall.
         * If blocked, return value will be 'false' so that the same function is called once again in the other sense.
         *
         *                          V0---------------V1
         *                           |                |
         *                           |                |
         *                           |                x
         *                           |
         *                           |
         *                           V3-------x       V2
         *
         * Example above :
         * - First call on [v0,v1,v2,v3] -> blocked between v1 and v2
         * - Second call on [v0,v3,v2,v1] -> blocked between v3 and v2.
         *
         * If not blocked, one single call on [v0,v1,v2,v3]
         *
         */
        function follow_path(vertices, reverse = false, perform = false) {

            function add_wall(v0, v1) {
                var wall = (reverse) ? new cn_wall(v1, v0, obj._current_wall_type, obj._current_axis, obj._scene) : new cn_wall(v0, v1, obj._current_wall_type, obj._current_axis, obj._scene);
                obj._tmp_walls.push(wall);
            }

            //*** First vertex is either a new one, eihter the first vertex (second call to 'follow_path') */
            let start_vertex = null;
            if (obj._tmp_vertices.length > 0) {
                start_vertex = obj._tmp_vertices[0];
            } else {
                start_vertex = new cn_vertex(vertices[0]);
                obj._tmp_vertices.push(start_vertex);
            }

            //*** Loop on vertices */
            var last_vertex = start_vertex;
            for (var index = 0; index < vertices.length; index++) {
                //*** next point geometry */
                var next_point = (index < vertices.length - 1) ? vertices[index + 1] : vertices[0];
                var dir = cn_sub(next_point, last_vertex.position);
                var length = cn_normalize(dir);

                //*** Is there an impact along the ray ? */
                var impact = scene.raytrace(last_vertex.position, dir, length, false);
                var ww = null;
                var e = 0;

                if (impact) {
                    ww = impact.wall;
                    e = cn_dot(cn_sub(impact.point, ww.vertices[0].position), ww.bounds.direction);
                }
                //*** maybe the end point is already on a wall, and not detected by raytracing */
                else {
                    ww = scene.find_wall(next_point, prec);
                    if (ww) {
                        var wd = ww.bounds.direction;
                        if (cn_dot(wd, dir) < 0) wd = cn_mul(wd, -1);
                        if (cn_dist(wd, dir) > 0.1) {
                            e = cn_dot(cn_sub(next_point, ww.vertices[0].position), ww.bounds.direction);
                        } else {
                            if (cn_dot(ww.bounds.direction, dir) > 0)
                                e = 0;
                            else
                                e = ww.bounds.length;
                        }
                    }
                }

                //*** Is last point on an existing wall ? */
                if (ww) {
                    var next_vertex = null;
                    if (e < prec) {
                        next_vertex = ww.vertices[0];
                        add_wall(last_vertex, ww.vertices[0]);
                    } else if (e > ww.bounds.length - prec) {
                        next_vertex = ww.vertices[1];
                        add_wall(last_vertex, ww.vertices[1]);
                    } else {
                        next_vertex = new cn_vertex(cn_add(ww.vertices[0].position, cn_mul(ww.bounds.direction, e)));
                        if (perform) {
                            var new_wall = scene.split_wall(ww, next_vertex);

                            obj.push_item_set(ww, [], () => {
                                if (scene.walls.indexOf(new_wall) >= 0)
                                    scene.merge_wall(ww, new_wall);
                                else
                                    scene.split_wall(ww, next_vertex, new_wall);
                            });

                            ww.build_self();
                            new_wall.build_self();
                        } else
                            obj._tmp_vertices.push(next_vertex);
                        add_wall(last_vertex, next_vertex);
                    }

                    if (Math.abs(dir[0]) < Math.abs(dir[1]))
                        last_vertex.position[0] = next_vertex.position[0];
                    else
                        last_vertex.position[1] = next_vertex.position[1];

                    return false;
                }

                //*** next point has no obstacle */
                // @ts-ignore
                var next_vertex = (index == vertices.length - 1) ? start_vertex : new cn_vertex(vertices[index + 1]);
                add_wall(last_vertex, next_vertex);
                if (index < vertices.length - 1) {
                    obj._tmp_vertices.push(next_vertex);
                    last_vertex = next_vertex;
                } else
                    return true;
            }
            return true;
        }

        function check_path(vertices, perform) {
            var old_vertices = obj._tmp_vertices;
            var old_walls = obj._tmp_walls;

            obj._tmp_vertices = [];
            obj._tmp_walls = [];
            if (!follow_path(vertices, false, false)) {
                if (vertices.length == 4)
                    follow_path([vertices[0], vertices[3], vertices[2], vertices[1]], true, false);
                else
                    follow_path([vertices[0], vertices[4], vertices[3], vertices[2], vertices[1]], true, false);
            }

            //*** If any wall is not consequent, reset and return false */
            if (obj._tmp_walls.some(wall => cn_dist(wall.vertices[0].position, wall.vertices[1].position) < 0.1)) {
                obj._tmp_vertices = old_vertices;
                obj._tmp_walls = old_walls;
                return false;
            }
            if (perform) {
                obj._tmp_vertices = [];
                obj._tmp_walls = [];
                if (!follow_path(vertices, false, true)) {
                    if (vertices.length == 4)
                        follow_path([vertices[0], vertices[3], vertices[2], vertices[1]], true, true);
                    else
                        follow_path([vertices[0], vertices[4], vertices[3], vertices[2], vertices[1]], true, true);
                }
            }
            return true;
        }

        //***************************************************************/
        //*** Callback upon wall creation */
        this._handler.on_change = () => {

            //*** Reorder vertex list to have the first vertex not on an existing wall */
            var vertices = reorder_list(obj._handler.vertices);
            if (vertices == null) {
                obj.initiate_rectangle();
                return;
            }

            //*** Clear vertex/wall connexions established for display */
            scene.update_vertices();

            //*** Undo redo management */
            const transaction_name = (obj._current_wall_type.balcony) ? 'Création de balcons' : 'Création de murs';
            obj.push_transaction(transaction_name, '', () => {
                scene.full_update(true);
            });

            //*** Compute new wall */
            check_path(vertices, true);

            obj.push_item_set(scene, ['vertices', 'walls']);
            scene.vertices = scene.vertices.concat(obj._tmp_vertices);
            scene.walls = scene.walls.concat(obj._tmp_walls);
            const new_walls = obj._tmp_walls;
            obj._tmp_vertices = [];
            obj._tmp_walls = [];

            //*** Rebuild spaces */
            scene.full_update(true);

            //*** New rectancular wall */
            obj.initiate_rectangle();
            obj.call('creation');
            obj._initiate_edition(new_walls);
        };

        //***************************************************************/
        //*** Callback upon wall drawing */
        this._handler.check_change = () => {

            //*** Reorder vertex list to have the first vertex not on an existing wall */
            var vertices = reorder_list(obj._handler.vertices);
            if (vertices == null) return false;

            //*** Update tmp walls and vertices */
            check_path(vertices, false);

            return true;
        };
    }

    /**
     * TODO : derivate in order to provide an edition handler
     * @param {Array<cn_wall>} elements
     * @returns {cn_edition_handler}
     */
    _build_edition_handler(elements) {
        const edition_handler = new cn_wall_handler(elements, this._map, true);
        return edition_handler;
    }

    /**
     * TODO : derivate in order to find siblings of an element
     * @param {cn_wall} element
     * @returns {Array<cn_element>}
     */
    _get_siblings(element) {
        const ot = element.wall_type;
        return this._scene.walls.filter(op => op.wall_type == ot);
    }
}

