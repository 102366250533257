<div
  class="full d-flex sticky-top bg-white text-primary justify-content-between shadowed header"
  *ngIf="user$ | async as user">
  <div class="d-flex w-25">
    <button
      class="my-0 text-secondary border-0 bg-transparent d-flex align-items-center text-h2-heading"
      routerLink="/projects">
      <img src="../../../assets/favicon/android-chrome-192x192.png" alt="logo EDF" class="mx-4" />
    </button>
    <button
      class="my-0 mx-2 text-secondary border-0 bg-transparent py-3 d-flex align-items-center text-h2-heading"
      [routerLink]="backLink"
      *ngIf="backLink">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </div>
  <div class="d-flex align-items-center">
    <div class="text-h2-heading text-secondary" *ngIf="pageTitle">{{ pageTitle }}</div>
  </div>
  <div class="d-flex w-25 justify-content-end">
    <button
      class="text-secondary border-0 bg-transparent mx-2 d-flex align-items-center text-h2-heading"
      routerLink="/changelog">
      <mat-icon class="iconUser">info</mat-icon>
    </button>
    <button
      [matMenuTriggerFor]="menu"
      class="m-0 text-white bg-secondary border-0 p-2 d-flex align-items-center text-h2-heading menu-profile-btn">
      <mat-icon class="iconUser mx-2">person_outline</mat-icon>
      <div class="text-regular text-left w-100">
        Bonjour
        <br />
        <strong>{{ user.firstName }} {{ user.lastName }}</strong>
      </div>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="subMenu">
      <button mat-menu-item routerLink="/profile">
        <mat-icon>person</mat-icon>
        <span>Mon compte</span>
      </button>
      <button mat-menu-item *appIsAuthByRight="SUPER_ADMIN" routerLink="/admin/users">
        <mat-icon>manage_accounts</mat-icon>
        <span>Gestion utilisateurs</span>
      </button>
      <button mat-menu-item *appIsAuthByRight="SUPER_ADMIN" routerLink="/pac-catalogue">
        <mat-icon>bolt</mat-icon>
        <span>Gestion des PAC</span>
      </button>
      <ng-container *ngIf="nbCompanies$ | async as nbCompanies">
        <button mat-menu-item *appIsAuthByRight="CompanyRights.ADMIN" routerLink="/companies">
          <mat-icon>badge</mat-icon>
          <span [ngPlural]="nbCompanies">
            <ng-template ngPluralCase="=1">Ma société</ng-template>
            <ng-template ngPluralCase="other">Mes sociétés</ng-template>
          </span>
        </button>
      </ng-container>
      <button mat-menu-item routerLink="/monitoring" *appIsAuthByRight="SUPER_ADMIN">
        <mat-icon>bar_chart_4_bars</mat-icon>
        <span>Supervision</span>
      </button>
      <button mat-menu-item (click)="logOut()">
        <mat-icon>lock</mat-icon>
        <span>Se déconnecter</span>
      </button>
    </mat-menu>
  </div>
</div>

<router-outlet></router-outlet>
