'use strict';
import { cn_camera } from '../svg/cn_camera';
import { logger } from '../utils/cn_logger';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Space class
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Internal : measure points
//***********************************************************************************
import {
    cn_add, cn_cart,
    cn_clone,
    cn_dist,
    cn_dot,
    cn_mul,
    cn_normal,
    cn_normalize, cn_polar,
    cn_size,
    cn_sub
} from '../utils/cn_utilities';
import { cn_image_dir } from '../utils/image_dir';

export class space_measure_point {
    constructor() {
        this.p0 = [0, 0];
        this.p1 = [0, 0];
        this.walls = [];
        this.wall_orientations = [];
        this.position = [0, 0];
        this.selected = false;
        this.length = 0;
        this.locked = false;
        this.vertices = [null, null]
    }

    add_wall(wall, wall_orientation) {
        const side = (wall_orientation) ? 0 : 1;
        if (this.walls.length == 0) {
            this.vertices[0] = wall.vertices[side];
            this.p0 = cn_clone(wall.measure_points[side][0]);
        }

        this.p1 = cn_clone(wall.measure_points[side][1]);
        this.vertices[1] = wall.vertices[1 - side];

        this.walls.push(wall);
        this.wall_orientations.push(wall_orientation);
    }

    merge(mp) {
        var normal = cn_normal(cn_sub(this.p1, this.p0));
        if (cn_normalize(normal) < 0.01) return false;

        var x0 = cn_dot(normal, cn_sub(mp.p0, this.p0));
        var x1 = cn_dot(normal, cn_sub(mp.p1, this.p0));
        if (Math.abs(x0) > 0.01 || Math.abs(x1) > 0.01) return false;

        for (var i in mp.walls) {
            this.add_wall(mp.walls[i], mp.wall_orientations[i]);
        }
        return true;
    }

    /**
     *
     * @param {cn_camera} camera
     * @param {Array<string>} extra_classes
     * @returns
     */
    draw(camera, extra_classes = []) {
        this.length = cn_dist(this.p0, this.p1);
        return camera.draw_measure(this.p0, this.p1, (this.locked) ? null : this.position, this.selected && !this.locked, true, extra_classes);
    }

    update() {

        var side = (this.wall_orientations[0]) ? 0 : 1;
        this.p0 = cn_clone(this.walls[0].measure_points[side][0]);

        var k = this.walls.length - 1;
        side = (this.wall_orientations[k]) ? 0 : 1;
        this.p1 = cn_clone(this.walls[k].measure_points[side][1]);
    }
}

class space_diagonal {
    constructor() {
        this.length = 0;
        this.position = [0, 0];
        this.selected = false;
        this.locked = false;
    }
}

const DIAGONAL_TOLERANCE = 0.0005;

//***********************************************************************************
//**** Space class
//***********************************************************************************
export class cn_space_measure {
    constructor(space) {
        this.space = space;

        //*** Model data
        this.diagonal_origin = 0;

        //*** measure points */
        this.measure_points = [];
        this.diagonal_point = [0, 0];
        this.diagonals = [];

        this.radius = 20;

        this._selected_origin = -1;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.diagonal_origin = this.diagonal_origin;
        return json;
    }

    static unserialize(json, space) {
        space.measure = new cn_space_measure(space);

        if (typeof (json.diagonal_origin) == 'number')
            space.measure.diagonal_origin = json.diagonal_origin;
    }

    //***********************************************************************************
    //**** update geometry
    //***********************************************************************************
    update() {

        var contours = [];
        if (this.space.outside)
            contours = this.space.contours;
        else {
            var cc_ctr = this.space.get_clockwise_contour();
            if (cc_ctr) contours.push(cc_ctr);
        }
        this.measure_points = [];
        if (contours.length == 0) return;

        for (var ctr in contours) {
            var contour = contours[ctr];
            var last_measure_point = null;

            for (var i in contour.walls) {
                var mp = new space_measure_point();
                mp.add_wall(contour.walls[i], contour.wall_orientations[i]);
                if (last_measure_point && last_measure_point.merge(mp)) continue;
                last_measure_point = mp;
                this.measure_points.push(mp);
            }
            if (this.measure_points.length > 1 && this.measure_points[this.measure_points.length - 1].merge(this.measure_points[0]))
                this.measure_points.splice(0, 1);
        }
    }

    //***********************************************************************************
    //**** draw
    //***********************************************************************************
    draw_measures(camera, extra_classes = []) {
        var html = '';
        if (this.measure_points.length == 0) return html;


        //*** draw measure points */
        for (var i in this.measure_points)
            html += this.measure_points[i].draw(camera, extra_classes);

        return html;
    }

    //***********************************************************************************
    //**** draw
    //***********************************************************************************
    draw(camera) {
        var html = '';

        var sz = this.measure_points.length;
        if (sz == 0 || !camera.show_space_measure) return html;

        //*** compute locks */
        var first_lock = -1;
        var last_lock = -1;
        for (var i = 1; i < this.measure_points.length; i++) {
            if (this.measure_points[(i + this.diagonal_origin) % sz].vertices[0].is_locked()) {
                if (first_lock < 0) first_lock = i;
                last_lock = i;
            }
        }
        if (first_lock == 1) first_lock = 0;


        //*** draw measure points */
        for (var i = 0; i < this.measure_points.length; i++) {
            this.measure_points[i].locked = (i >= first_lock && i < last_lock);
            html += this.measure_points[(i + this.diagonal_origin) % sz].draw(camera);
        }

        var mp0 = this.measure_points[this.diagonal_origin % sz];
        var p0 = mp0.p0;
        if (cn_dist(p0, this.diagonal_point) > 0.01 || this.diagonals.length != sz - 3) {
            this.diagonal_point = p0;
            this.diagonals = [];
            for (var i = 0; i < sz - 3; i++)
                this.diagonals.push(new space_diagonal());
        }
        for (var i = 2; i <= sz - 2; i++) {
            var p = this.measure_points[(i + this.diagonal_origin) % sz].p0;
            var diag = this.diagonals[i - 2];
            diag.length = cn_dist(p, p0);
            diag.locked = (i >= first_lock && i <= last_lock);
            html += camera.draw_measure(p0, p, (diag.locked) ? null : diag.position, diag.selected && !diag.locked, false);
        }

        //*** Draw shape of first wall */
        for (var k in mp0.walls)
            html += mp0.walls[k].draw_shape(camera, 'space_measure_origin ghost');

        //*** Draw position of diagonal origin */
        var pp = camera.world_to_screen(p0);
        html += '<circle class=\'space_measure_origin\' cx=\'' + pp[0] + '\' cy=\'' + pp[1] + '\' r=\'' + this.radius + '\'/>';
        html += '<image xlink:href=\'' + cn_image_dir() + 'lock.svg\' x=\'' + (pp[0] - this.radius) + '\' y=\'' + (pp[1] - this.radius) + '\' width=\'' + (this.radius * 2) + '\' height=\'' + (this.radius * 2) + '\' preserveAspectRatio=\'xMidYMid meet\'/>';

        //*** Draw ghost of next diagonal origin */
        if (this._selected_origin >= 0) {
            pp = camera.world_to_screen(this.measure_points[this._selected_origin % sz].p0);
            html += '<circle class=\'space_measure_origin ghost\' cx=\'' + pp[0] + '\' cy=\'' + pp[1] + '\' r=\'' + this.radius + '\'/>'
            html += '<image xlink:href=\'' + cn_image_dir() + 'lock.svg\' x=\'' + (pp[0] - this.radius) + '\' y=\'' + (pp[1] - this.radius) + '\' width=\'' + (this.radius * 2) + '\' height=\'' + (this.radius * 2) + '\' preserveAspectRatio=\'xMidYMid meet\' opacity=\'0.5\' />';
        }

        return html;
    }

    //***********************************************************************************
    //**** Check selection
    //***********************************************************************************
    is_mouseover(world_point, screen_point, camera) {
        //*** clear */
        for (var i = 0; i < this.measure_points.length; i++)
            this.measure_points[i].selected = false;
        for (var i = 0; i < this.diagonals.length; i++)
            this.diagonals[i].selected = false;

        this._selected_measure = -1;
        this._selected_diagonal = -1;
        this._selected_origin = -1;

        var tolerance = 10;
        for (var i = 0; i < this.measure_points.length; i++) {
            if (this.measure_points[i].locked) continue;
            if (cn_dist(this.measure_points[i].position, screen_point) > tolerance) continue;
            this.measure_points[i].selected = true;
            this._selected_measure = i;
            return true;
        }
        for (var i = 0; i < this.diagonals.length; i++) {
            if (this.diagonals[i].locked) continue;
            if (cn_dist(this.diagonals[i].position, screen_point) > tolerance) continue;
            this.diagonals[i].selected = true;
            this._selected_diagonal = i;
            return true;
        }

        var tolerance = camera.screen_to_world_scale * this.radius;
        for (var i = 0; i < this.measure_points.length; i++) {
            if (cn_dist(this.measure_points[i].p0, world_point) > tolerance) continue;
            this._selected_origin = i;
            return true;
        }

        return false;
    }

    //***********************************************************************************
    //**** Info on selection
    //***********************************************************************************
    diagonal_origin_selected() {
        return (this._selected_origin >= 0);
    }

    //***********************************************************************************
    //**** Click management
    //***********************************************************************************
    click() {
        if (this._selected_measure >= 0) {
            if (this._selected_measure >= this.measure_points.length) return false;
            var mp = this.measure_points[this._selected_measure];
            return cn_dist(mp.p0, mp.p1);
        }

        if (this._selected_diagonal >= 0) {
            if (this._selected_diagonal >= this.diagonals.length) return false;
            return this.diagonals[this._selected_diagonal].length;
        }

        if (this._selected_origin >= 0) {
            this.diagonal_origin = this._selected_origin;
            return true;
        }
        return false;
    }

    //***********************************************************************************
    //**** Return impiled vertices
    //***********************************************************************************
    get_implied_vertices() {
        var vertices = [];
        for (var i in this.measure_points) {
            var mp = this.measure_points[i];
            for (var k in mp.walls) {
                if (mp.wall_orientations[k])
                    vertices.push(mp.walls[k].vertices[0]);
                else
                    vertices.push(mp.walls[k].vertices[1]);
            }
        }
        return vertices;
    }

    get_implied_walls() {
        var walls = [];
        for (var i in this.measure_points)
            walls = walls.concat(this.measure_points[i].walls);
        return walls;
    }

    //***********************************************************************************
    //**** set measure value
    //***********************************************************************************
    set_measure(v, keep_all_lengths = true) {
        var scene = this.space.scene;
        var last_measure = 0;
        var last_diagonal = 0;
        if (this._selected_measure >= 0) {
            if (this._selected_measure >= this.measure_points.length) return false;
            this.measure_points[this._selected_measure].length = v;
            last_measure = this._selected_measure;
            last_diagonal = this._selected_measure - 1;
            if (last_diagonal >= this.diagonals.length)
                last_diagonal = this.diagonals.length - 1;
        } else if (this._selected_diagonal >= this.diagonals.length) return false;
        else {
            this.diagonals[this._selected_diagonal].length = v;
            last_diagonal = this._selected_diagonal;
            last_measure = this._selected_diagonal + 1;
        }

        if (keep_all_lengths) {
            last_measure = this.measure_points.length - 1;
            last_diagonal = this.diagonals.length - 1;
        }

        var sz = this.measure_points.length;

        var niter = 0;
        for (niter = 0; niter < 500; niter++) {
            var p0 = this.measure_points[this.diagonal_origin % sz].p0;
            var change = false;
            for (var i = 0; i <= last_measure; i++) {
                if (i == sz - 1) break;

                var mp = this.measure_points[(i + this.diagonal_origin) % sz];
                var l0 = cn_dist(mp.p0, mp.p1);
                var checked_0 = (Math.abs(l0 - mp.length) < DIAGONAL_TOLERANCE);
                var id = i - 1;
                var checked_1 = true;
                var diagonal = -1;
                if (id >= 0 && id <= last_diagonal) {
                    var dst = cn_dist(p0, mp.p1);
                    diagonal = this.diagonals[id].length;
                    checked_1 = (Math.abs(diagonal - dst) < DIAGONAL_TOLERANCE);
                } else if (i == sz - 2 && last_measure == sz - 1) {
                    var mp1 = this.measure_points[(i + 1 + this.diagonal_origin) % sz];
                    var dst = cn_dist(mp1.p0, mp1.p1);
                    diagonal = mp1.length;
                    checked_1 = (Math.abs(diagonal - dst) < DIAGONAL_TOLERANCE);
                }
                if (checked_0 && checked_1) continue;

                var offset;
                if (diagonal > 0) {
                    var pol = cn_polar(cn_sub(mp.p0, p0));
                    var cosacosb = (diagonal * diagonal - pol[0] * pol[0] - mp.length * mp.length) / (2 * pol[0] * mp.length);
                    if (Math.abs(cosacosb) > 1) return false;
                    var amb = Math.acos(cosacosb);
                    var alpha0 = amb + pol[1];
                    var alpha1 = -amb + pol[1];
                    var d0 = cn_cart([mp.length, alpha0]);
                    var d1 = cn_cart([mp.length, alpha1]);
                    var dir = cn_sub(mp.p1, mp.p0);
                    var dd = (cn_dot(dir, d0) > cn_dot(dir, d1)) ? d0 : d1;

                    offset = cn_sub(cn_add(mp.p0, dd), mp.p1);
                } else {
                    var dir = cn_sub(mp.p1, mp.p0);
                    cn_normalize(dir);
                    offset = cn_mul(dir, mp.length - l0);
                }

                //*** Apply offset */
                var actual_length = 0;
                for (var k = 0; k < mp.walls.length; k++)
                    actual_length += mp.walls[k].bounds.length;
                var current_length = 0;
                for (var k = 0; k < mp.walls.length; k++) {
                    current_length += mp.walls[k].bounds.length;
                    var off = cn_mul(offset, current_length / actual_length);
                    if (mp.wall_orientations[k])
                        mp.walls[k].vertices[1].position = cn_add(mp.walls[k].vertices[1].position, off);
                    else
                        mp.walls[k].vertices[0].position = cn_add(mp.walls[k].vertices[0].position, off);
                }
                change = true;
                logger.log('Point : ' + i + ' offset : ' + cn_size(offset) + ' diagonal ' + diagonal);
                break;
            }
            if (!change) break;
            scene.update_vertices();
            scene.update_walls();
            for (var k = 0; k < sz; k++)
                this.measure_points[k].update();
        }
        logger.log('ended in ' + niter + ' iterations');
        return true;
    }
}

