'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_opening : a window or a door
//***********************************************************************************
//***********************************************************************************

import { cn_element } from './cn_element';
import { cn_add, cn_box, cn_clone, cn_dot, cn_middle, cn_mul, cn_normal, cn_normalize, cn_sub } from '../utils/cn_utilities';
import { fh_cross, fh_matrix, fh_mul, fh_polygon } from '@enerbim/fh-3d-viewer';
import { cn_camera } from '../svg/cn_camera';
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';

export class cn_opening extends cn_element {
    constructor(opening_type) {
        super();
        this.wall = null;
        this.position = 0;
        this.opening_type = opening_type;
        this.valid = true;
        this.draw_priority = 10;
        this.opening_start = true;
        this.opening_inside = true;
        this.opening_position = 1;

        this.thickness = 0.06;
    }

    //***********************************************************************************
    //**** Clone
    //***********************************************************************************
    clone() {
        var c = new cn_opening(this.opening_type);
        c.wall = this.wall;
        c.position = this.position;
        c.valid = this.valid;
        c.draw_priority = this.draw_priority;
        c.opening_start = this.opening_start;
        c.opening_inside = this.opening_inside;
        c.opening_position = this.opening_position;

        return c;
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.position = this.position;
        json.opening_type = this.opening_type.ID;
        json.opening_start = this.opening_start;
        json.opening_inside = this.opening_inside;
        json.opening_position = this.opening_position;

        return json;
    }

    static unserialize(json, scene) {
        if (typeof (json) != 'object') return false;
        if (typeof (json.position) != 'number') return false;

        var ot = null;

        if (typeof (json.opening_type) == 'string')
            ot = scene.building.get_element_type(json.opening_type);

        if (ot == null) {
            var category = 'window';
            if (typeof (json.opening_type) == 'object' && typeof (json.opening_type.category) == 'string')
                category = json.opening_type.category;
            if (category == 'window')
                ot = scene.building.get_window_types()[0];
            else
                ot = scene.building.get_door_types()[0];
        }

        var opening = new cn_opening(ot);
        if (typeof (json.ID) == 'string') opening.ID = json.ID;
        opening.position = json.position;

        if (typeof (json.opening_start) == 'boolean')
            opening.opening_start = json.opening_start;

        if (typeof (json.opening_inside) == 'boolean')
            opening.opening_inside = json.opening_inside;

        if (typeof (json.opening_position) == 'number')
            opening.opening_position = json.opening_position;

        return opening;
    }

    //***********************************************************************************
    //**** Draw the opening in svg
    //***********************************************************************************
    draw(camera, add_classes, fill = '') {
        var html = '';
        if (this.wall == null) return html;
        var v0 = cn_mul(this.wall.bounds.direction, this.position);
        var v1 = cn_mul(this.wall.bounds.direction, this.position + this.opening_type.width);
        var p0 = camera.world_to_screen(cn_add(this.wall.bounds.pmin, v0));
        var p1 = camera.world_to_screen(cn_add(this.wall.bounds.pmin, v1));
        var p2 = camera.world_to_screen(cn_add(this.wall.bounds.pmax, v1));
        var p3 = camera.world_to_screen(cn_add(this.wall.bounds.pmax, v0));

        if (this.status < 0)
            html += '<g opacity=\'0.3\'>';

        if (add_classes.indexOf('mouseover') >= 0 || add_classes.indexOf('selected') >= 0) {
            const highlight = (add_classes.indexOf('selected') >= 0) ? 'selected' : 'mouseover';
            html += '<path class=\'opening_highlight ' + highlight + '\' d=\'';
            html += ' M ' + p0[0] + ' ' + p0[1];
            html += ' L ' + p1[0] + ' ' + p1[1];
            html += ' ' + p2[0] + ' ' + p2[1];
            html += ' ' + p3[0] + ' ' + p3[1] + 'Z\' />';
        }

        var draw_class = 'opening opening_category_' + this.opening_type.category;
        if (add_classes)
            draw_class += ' ' + add_classes.join(' ');
        html += '<path class=\'' + draw_class + '\' ' + fill + ' d=\'';
        html += ' M ' + p0[0] + ' ' + p0[1];
        html += ' L ' + p1[0] + ' ' + p1[1];
        html += ' ' + p2[0] + ' ' + p2[1];
        html += ' ' + p3[0] + ' ' + p3[1] + 'Z\' />';

        if (this.opening_type.free) {
            if (this.status < 0)
                html += '</g>';
            return html;
        }

        //*** Compute opening axis
        var c0, c1;
        var thickness = this.thickness * camera.world_to_screen_scale;
        var wall_thickness = this.wall.wall_type.thickness * camera.world_to_screen_scale;
        var x = 0.5;
        if (this.opening_position != 1) {
            var x = 0.5 * thickness / wall_thickness;
            if (this.opening_position == 2) x = 1 - x;
        }
        c0 = cn_add(cn_mul(p0, 1 - x), cn_mul(p3, x));
        c1 = cn_add(cn_mul(p1, 1 - x), cn_mul(p2, x));

        if (!this.opening_start) {
            var c = c1;
            c1 = c0;
            c0 = c;
        }

        //*** Draw opening position
        var direction = cn_sub(c1, c0);
        var normal = cn_normal(direction);
        cn_normalize(normal);
        normal = fh_mul(normal, 0.5 * thickness);

        html += '<path class=\'' + draw_class + '\' ' + fill + ' d=\'';
        html += ' M ' + (c0[0] - normal[0]) + ' ' + (c0[1] - normal[1]);
        html += ' L ' + (c1[0] - normal[0]) + ' ' + (c1[1] - normal[1]);
        html += ' ' + (c1[0] + normal[0]) + ' ' + (c1[1] + normal[1]);
        html += ' ' + (c0[0] + normal[0]) + ' ' + (c0[1] + normal[1]) + 'Z\' />';

        var nb_panels = parseInt(this.opening_type.panels);
        var vertices = [];
        vertices.push(c0);
        if (nb_panels > 0) {
            var panel_ratios = this.opening_type.compute_panel_widths();
            var panel_accum = 0;
            panel_ratios.forEach(p => panel_accum += p);

            var dd = cn_sub(c1, c0);
            var x = 0;
            for (var n = 0; n < nb_panels - 1; n++) {
                x += panel_ratios[n] / panel_accum;
                vertices.push(cn_add(c0, cn_mul(dd, x)));
            }
        }
        vertices.push(c1);

        var extra = (add_classes.indexOf('exp') >= 0) ? 'exp' : '';

        if (this.opening_type.opening == 'french') {
            var angle = Math.PI * 0.2;
            if (this.opening_type.category == 'door')
                angle = Math.PI * 0.5;
            if (!this.opening_inside) angle *= -1;

            for (var k = 0; k < nb_panels; k++) {
                if (k == 0)
                    html += this._draw_panel_swing(vertices[k], vertices[k + 1], thickness, angle, extra, fill);
                else
                    html += this._draw_panel_swing(vertices[k + 1], vertices[k], thickness, -angle, extra, fill);
            }
        } else if (this.opening_type.opening == 'none') {
            for (var k = 0; k < nb_panels; k++)
                html += this._draw_panel(vertices[k], vertices[k + 1], thickness, extra, fill);
        } else if (this.opening_type.opening == 'sliding' || (this.opening_type.opening == 'vertical' && this.opening_type.vertical_opening == 'sash')) {
            if (nb_panels == 1)
                html += this._draw_panel(c0, c1, thickness, extra, fill);
            else {
                var dt = cn_normal(cn_sub(c1, c0));
                cn_normalize(dt);
                if (this.opening_type.opening == 'sliding') {
                    dt = cn_mul(dt, thickness * 0.5);
                    for (var k = 0; k < nb_panels; k++) {
                        html += this._draw_panel(cn_add(vertices[k], dt), cn_add(vertices[k + 1], dt), thickness, extra, fill);
                        dt = cn_mul(dt, -1);
                    }
                } else {
                    var dt0 = cn_mul(dt, 0.5 * nb_panels * thickness - 0.5 * thickness);
                    dt = cn_mul(dt, thickness);
                    for (var k = 0; k < nb_panels; k++) {
                        html += this._draw_panel(cn_add(c0, dt0), cn_add(c1, dt0), thickness, extra, fill);
                        dt0 = cn_sub(dt0, dt);
                    }
                }
            }
        } else if (this.opening_type.opening == 'vertical') {
            var dt = cn_normal(cn_sub(c1, c0));
            cn_normalize(dt);
            var length = (this.opening_type.vertical_opening == 'tilting') ? 0.8 : 0.4;
            length *= camera.world_to_screen_scale;
            if (this.opening_type.vertical_opening != 'tilting') {
                const mult = (!this.opening_inside) ? -1 : 1;
                const dt0 = cn_mul(dt, length * 0.5 * mult);
                html += this._draw_panel(cn_add(c0, dt0), cn_add(c1, dt0), length, extra + ' ghost');
            } else
                html += this._draw_panel(c0, c1, length, extra + ' ghost');
        }

        if (this.status < 0)
            html += '</g>';

        return html;
    }

    _draw_panel_swing(p0, p1, thickness, angle, extra = '', fill = '') {
        var dx = cn_sub(p1, p0);
        var l = cn_normalize(dx);
        var dy = cn_normal(dx);
        var dd = cn_add(cn_mul(dx, Math.cos(angle)), cn_mul(dy, Math.sin(angle)));
        var dt = cn_normal(dd);
        var p;

        var html = '';
        html += '<path class=\'opening opening_category_' + this.opening_type.category + ' ' + extra + '\' ' + fill + ' d=\'';
        p = cn_add(p0, cn_mul(dt, thickness * 0.5));
        html += ' M ' + p[0] + ' ' + p[1];
        p = cn_add(p, cn_mul(dd, l));
        html += ' L ' + p[0] + ' ' + p[1];
        p = cn_sub(p, cn_mul(dt, thickness));
        html += ' ' + p[0] + ' ' + p[1];
        p = cn_sub(p, cn_mul(dd, l));
        html += ' ' + p[0] + ' ' + p[1];
        html += ' Z\' />';

        html += '<path class=\'opening_door opening_category_' + this.opening_type.category + ' ' + extra + '\' ' + fill + ' d=\'';
        html += ' M ' + p1[0] + ' ' + p1[1];
        var lambda = l * (1 - cn_dot(dx, dd)) / cn_dot(dy, dd);
        p = cn_add(p1, cn_mul(dy, lambda));
        html += ' Q ' + p[0] + ' ' + p[1];
        p = cn_add(p0, cn_mul(dd, l));
        html += ' ' + p[0] + ' ' + p[1];
        html += '\' />';
        return html;
    }

    _draw_panel(p0, p1, thickness, extra = '', fill = '') {
        var dt = cn_normal(cn_sub(p1, p0));
        cn_normalize(dt);
        dt = cn_mul(dt, thickness * 0.5);
        var p;
        var html = '';
        html += '<path class=\'opening opening_category_' + this.opening_type.category + ' ' + extra + '\' ' + fill + ' d=\'';
        p = cn_add(p0, dt);
        html += ' M ' + p[0] + ' ' + p[1];
        p = cn_add(p1, dt);
        html += ' L ' + p[0] + ' ' + p[1];
        p = cn_sub(p1, dt);
        html += ' ' + p[0] + ' ' + p[1];
        p = cn_sub(p0, dt);
        html += ' ' + p[0] + ' ' + p[1];
        html += ' Z\' />';

        return html;
    }

    //***********************************************************************************
    /**
     * Draws in svg the measures of the opening (width, height, z)
     * @param {cn_camera} camera
     * @param {string[]} add_classes
     * @returns {string} html string
     */
    draw_measures(camera, add_classes) {
        var html = '';
        if (!this.wall || !this.valid) return html;

        var p0_0 = cn_add(this.wall.bounds.pmin, cn_mul(this.wall.bounds.direction, this.position));
        var p0_1 = cn_add(this.wall.bounds.pmin, cn_mul(this.wall.bounds.direction, this.position + this.opening_type.width));
        var nn = cn_mul(this.wall.bounds.normal, this.wall.wall_type.thickness);
        var p1_0 = cn_add(p0_0, nn);
        var p1_1 = cn_add(p0_1, nn);
        var p0, p1;
        if (this.wall.spaces[1].outside) {
            p0 = p1_1;
            p1 = p1_0;
        } else {
            p0 = p0_0;
            p1 = p0_1;
        }

        var txt = '';
        txt += (this.opening_type.width * 100).toFixed(0) + ' x ' + (this.opening_type.height * 100).toFixed(0);
        if (this.opening_type.z > 0) txt += ' z=' + (this.opening_type.z * 100).toFixed(0);

        html = camera.draw_measure(p0, p1, null, false, 2, add_classes, txt);

        var op_prec = null;
        var op_next = null;
        var op_found = false;
        for (var i in this.wall.openings) {
            var op = this.wall.openings[i];
            if (!op.valid) continue;
            if (op == this) {
                op_found = true;
                continue;
            }
            if (op_found) {
                op_next = op;
                break;
            }
            op_prec = op;
        }
        var pprec_0 = this.wall.shape[0];
        var pprec_1 = this.wall.shape[1];
        if (op_prec) {
            pprec_0 = cn_add(this.wall.bounds.pmin, cn_mul(this.wall.bounds.direction, op_prec.position + op_prec.opening_type.width));
            pprec_1 = cn_add(pprec_0, nn);
        }
        html += camera.draw_measure(pprec_0, p0_0, null, false, 2, add_classes);
        html += camera.draw_measure(p1_0, pprec_1, null, false, 2, add_classes);

        if (op_next == null) {
            html += camera.draw_measure(p0_1, this.wall.shape[3], null, false, 2, add_classes);
            html += camera.draw_measure(this.wall.shape[2], p1_1, null, false, 2, add_classes);
        }

        return html;
    }

    //***********************************************************************************
    //**** Remove an opening
    //***********************************************************************************

    remove() {
        if (this.wall == null) return;
        var index = this.wall.openings.indexOf(this);
        if (index >= 0) this.wall.openings.splice(index, 1);
    }

    //***********************************************************************************
    //**** checks if wall position is ok
    //***********************************************************************************

    check_wall() {
        if (this.wall == null) return false;

        var x0 = this.position;
        var x1 = x0 + this.opening_type.width;
        if (x0 < this.wall.opening_area[0] - 0.001) return false;
        if (x1 > this.wall.opening_area[1] + 0.001) return false;

        for (var i in this.wall.openings) {
            var opening = this.wall.openings[i];
            if (opening == this) continue;
            if (!opening.valid) continue;
            if (x1 <= opening.position + 0.0001) return true;
            if (x0 >= opening.position + opening.opening_type.width - 0.0001) continue;
            return false;
        }
        return true;
    }

    /**
     * Sets best place for an opening, depending on a 3D point. Allows snap for precision.
     * Returns false if no proper position.
     * @param {number[]} position
     * @param {number} precision
     * @returns {boolean}
     */
    optimize_placement(position, precision) {
        //*** initial position of the opening */
        var x = cn_dot(cn_sub(position, this.wall.vertices[0].position), this.wall.bounds.direction) - 0.5 * this.opening_type.width;
        if (x < 0) x = 0;
        else if (x + this.opening_type.width > this.wall.bounds.length) x = this.wall.bounds.length;
        //*** Build list of allowed position ranges, depending on wall limits and other openings */
        var positions = [];
        positions.push(this.wall.opening_area[0]);
        for (var i in this.wall.openings) {
            var opening = this.wall.openings[i];
            if (opening.valid && opening != this) {
                positions.push(opening.position);
                positions.push(opening.position + opening.opening_type.width);
            }
        }
        positions.push(this.wall.opening_area[1]);

        //*** find proper place */
        for (var n = 0; n < positions.length; n += 2) {
            //*** wrong if opening off limits */
            if (x < positions[n] - precision) return false;

            //*** iterate if opening if further than this */
            if (x + this.opening_type.width > positions[n + 1] + precision) continue;

            //*** not enough space for the opening : false */
            if (positions[n + 1] - positions[n] < this.opening_type.width + 0.0001) return false;

            //*** try snapping */
            var x0 = Math.abs(x - positions[n]);
            var x1 = Math.abs(x + this.opening_type.width - positions[n + 1]);
            if (x0 < precision && x0 < x1)
                x = positions[n];
            else if (x1 < precision)
                x = positions[n + 1] - this.opening_type.width;

            //*** Apply position */
            this.position = x;
            return true;
        }
        return false;
    }

    /**
     * Returns actual position of the opening
     * @returns {Array<number>}
     */
    get_position() {
        return cn_add(this.wall.vertex_position(0), cn_mul(this.wall.bounds.direction, this.position));
    }

    //******************************************************
    //**** Build 3D footprint
    //******************************************************
    build_footprint(z) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);

        var v0 = cn_mul(this.wall.bounds.direction, this.position);
        var v1 = cn_mul(this.wall.bounds.direction, this.position + this.opening_type.width);
        var p0 = cn_add(this.wall.bounds.pmin, v0);
        var p1 = cn_add(this.wall.bounds.pmin, v1);
        var p2 = cn_add(this.wall.bounds.pmax, v1);
        var p3 = cn_add(this.wall.bounds.pmax, v0);
        p0.push(z);
        p1.push(z);
        p2.push(z);
        p3.push(z);
        polygon.add_contour([p0, p1, p2, p3]);

        return polygon;
    }

    //******************************************************
    //*** Build 3D geometry
    //******************************************************
    build_extruded_polygons(z) {
        if (this.wall == null || !this.valid) return [];
        var extruded_polygons = this.opening_type.build_extruded_polygons();

        var matrix = this.build_3d_matrix(z);
        for (var k = 0; k < extruded_polygons.length; k++)
            extruded_polygons[k].matrix = matrix;
        return extruded_polygons;
    }

    //******************************************************
    //*** Build 3D geometry
    //******************************************************
    build_3d_matrix(z) {
        var p = cn_add(this.wall.bounds.pmin, cn_mul(this.wall.bounds.direction, this.position));
        var delta = 0;
        if (this.opening_position == 0)
            delta = this.thickness;
        else if (this.opening_position == 1)
            delta = 0.5 * this.wall.wall_type.thickness + 0.5 * this.thickness;
        else
            delta = this.wall.wall_type.thickness;

        if (!this.opening_start) {
            delta -= this.thickness;
            p = cn_add(p, cn_mul(this.wall.bounds.direction, this.opening_type.width));
        }

        p = cn_add(p, cn_mul(this.wall.bounds.normal, delta));

        var dx = [this.wall.bounds.direction[0], this.wall.bounds.direction[1], 0];
        if (!this.opening_start) dx = fh_mul(dx, -1);

        var dy = [0, 0, 1];
        var dz = fh_cross(dx, dy);
        var origin = [p[0], p[1], z + this.opening_type.z];

        var matrix = new fh_matrix();
        matrix.load_axis(origin, dx, dy, dz);
        return matrix;
    }

    //***********************************************************************************
    //**** Contained by box ?
    //***********************************************************************************
    contained_by_box(box) {
        if (this.wall == null) return false;
        var v0 = cn_mul(this.wall.bounds.direction, this.position);
        var v1 = cn_mul(this.wall.bounds.direction, this.position + this.opening_type.width);
        var p = cn_add(this.wall.bounds.pmin, v0);
        if (!box.contains_point(p)) return false;
        p = cn_add(this.wall.bounds.pmin, v1);
        if (!box.contains_point(p)) return false;
        p = cn_add(this.wall.bounds.pmax, v1);
        if (!box.contains_point(p)) return false;
        p = cn_add(this.wall.bounds.pmax, v0);
        if (!box.contains_point(p)) return false;
        return true;
    }

    //***********************************************************************************
    //***Reverse the wall */
    reverse() {
        this.opening_position = 2 - this.opening_position;
        //this.opening_start = !this.opening_start;
        this.opening_inside = !this.opening_inside;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_opening(this);
    }

    get_bounding_box() {
        const box = new cn_box();
        const p0 = this.wall.bounds.pmin;
        const dir = this.wall.bounds.direction;
        box.enlarge_point(cn_add(p0, cn_mul(dir, this.position)));
        box.enlarge_point(cn_add(p0, cn_mul(dir, this.position + this.opening_type.width)));
        return box;
    }

    /**
     * Returns the depth of the opening, from exterior side.
     * Cannot return a negative value.
     * @returns {number}
     */
    get_outside_depth() {
        if (this.opening_type.free) return this.wall.wall_type.thickness;
        if (this.opening_position == 1) return Math.max(0, 0.5 * (this.wall.wall_type.thickness - this.thickness));
        if ((this.opening_position == 0) != this.wall.get_flow_direction()) return 0;
        return Math.max(0, this.wall.wall_type.thickness - this.thickness);
    }
}

