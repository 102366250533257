'use strict';

import { abstract_cn_space_numerotation_extension } from './abstract_cn_space_numerotation_extension';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** space extension model for acenv specifications
//***********************************************************************************
//***********************************************************************************

export class cn_space_extension_acenv extends abstract_cn_space_numerotation_extension {

    constructor() {
        super();
        this.SERIALIZATION_DATA_PROP = 'ac_env_spaces_numerotation'
    }

    get_space_name(space, storey) {
        const base_space_name = super.get_space_name(space, storey);
        if (storey && storey.building) {
            return `${(space.get_generic_name(storey))} (${base_space_name})`;
        }
        return base_space_name;
    }

}

