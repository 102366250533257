"use strict";

import {Vector3, Vector2} from 'three';

const STATE = {NONE: -1, ROTATE: 0, ZOOM: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_ZOOM_PAN: 4};

export class TouchController {
    constructor(view) {
        this._view = view;
        this._movePrev = new Vector2();
        this._moveCurr = new Vector2();

        this._touchZoomDistanceStart = 0;
        this._touchZoomDistanceEnd = 0;

        this._panStart = new Vector2();
        this._panEnd = new Vector2();
        this._state = STATE.NONE;
    }



    touchStart(event) {
        // event.preventDefault();
        event.stopPropagation();
        switch (event.touches.length) {

            case 1:
                this._state = STATE.TOUCH_ROTATE;
                event.clientX = event.touches[0].clientX;
                event.clientY = event.touches[0].clientY;
                this._moveCurr.copy( new Vector2(event.clientX, event.clientY) );
                this._movePrev.copy( this._moveCurr );
                event.buttons=0;
                this._view.manage_mouse_move(event);
                event.buttons=1;
                this._view.manage_mouse_down(event);
                break;

            default: // 2 or more
                this._state = STATE.TOUCH_ZOOM_PAN;
                let dx = event.touches[0].clientX - event.touches[1].clientX;
                let dy = event.touches[0].clientY - event.touches[1].clientY;
                this._touchZoomDistanceEnd = this._touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);
                let x = (event.touches[0].clientX + event.touches[1].clientX) / 2;
                let y = (event.touches[0].clientY + event.touches[1].clientY) / 2;
                this._panStart.copy(new Vector2(x, y));
                this._panEnd.copy(this._panStart);
                event.clientX = event.touches[0].clientX;
                event.clientY = event.touches[0].clientY;
                event.buttons=0;
                this._view.manage_mouse_move(event);
                event.buttons=2;
                this._view.manage_mouse_down(event);

                break;

        }

    }

    touchMove(event) {
        // event.preventDefault();
        event.stopPropagation();
        let touch = event.touches[ 0 ];
        if (event.touches.length == 1 && !this.rightClick) {
            event.clientX = touch.clientX;
            event.clientY = touch.clientY;
            event.buttons=1;
            this._movePrev.copy( this._moveCurr );
            this._moveCurr.copy( new Vector2(event.clientX, event.clientY) );
            this._view.manage_mouse_move(event);
        } else {
            let touch1 = event.touches[ 1 ] || touch;
            var dx = touch.clientX - touch1.clientX;
            var dy = touch.clientY - touch1.clientY;
            this._touchZoomDistanceEnd = Math.sqrt( dx * dx + dy * dy );

            var x = ( touch.clientX + touch1.clientX ) / 2;
            var y = ( touch.clientY + touch1.clientY ) / 2;
            this._panEnd.copy(new Vector2(x, y));
            event.buttons=2;
            event.clientX = touch.clientX;
            event.clientY = touch.clientY;
            this._view.manage_mouse_move(event);
            this._panStart.copy(this._panEnd);
            //zoom
            let factor = (this._touchZoomDistanceStart / this._touchZoomDistanceEnd) ;
            console.log('factor', factor, );
            this._touchZoomDistanceStart = this._touchZoomDistanceEnd;
            if (this._view.do_zoom) {
                this._view.do_zoom(event.clientX, event.clientY, 1 - factor);
            }
        }
    }

    touchEnd(event) {
        if (this._state === STATE.TOUCH_ROTATE) {
            event.clientX = this._moveCurr.x;
            event.clientY = this._moveCurr.y;
            this._view.manage_mouse_up(event);
        } else {
            event.clientX = this._panEnd.x;
            event.clientY = this._panEnd.y;
            this._view.manage_mouse_up(event);
        }
        this._state = STATE.NONE;
    }



    useCurrentZoom() {

    }

    getCurrentPosition() {

    }

    mouseDown(ev) {
        this.rightClick = ev.button === 2;
    }

    mouseUp(ev) {
        this.rightClick = false;
    }
}
