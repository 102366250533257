import { cn_element_visitor } from '../cn_element_visitor';

//***********************************************************************************
//***********************************************************************************
//**** cn_cut_selection_elements_visitor : visitor to
//**** propagate selection on cut operation
//***********************************************************************************
//***********************************************************************************

export class cn_cut_selection_elements_visitor extends cn_element_visitor {

    /**
     *  Constructor
     *
     */
    constructor(clipboard) {
        super();
        this.clipboard = clipboard;
        this.clipboard.extra_data = {}
        this.clipboard.extra_data.markers = [];
        this.clipboard.extra_data.samplings = [];
        this.added_elements = [];
    }

    visit_wall(cn_wall) {
        cn_wall.openings.forEach(opening => {
            this.added_elements.push(opening);
        });
    }

    visit_opening(cn_opening) {

    }

    visit_slab(cn_slab) {

    }

    visit_beam(cn_beam) {

    }

    visit_column(cn_column) {

    }

    visit_object(cn_object_instance) {

    }

    visit_stairs(cn_stairs) {

    }

    visit_pipe(cn_pipe) {

    }

    visit_marker(cn_marker) {
        this.clipboard.extra_data.markers.push(cn_marker.serialize());
    }

    visit_sampling(cn_sampling) {
        this.clipboard.extra_data.samplings.push(cn_sampling.serialize());
    }

}
