<app-picture-previzualizer></app-picture-previzualizer>
<router-outlet id="main-content" *ngIf="correctOrientation"></router-outlet>

<div *ngIf="!correctOrientation" class="change-orientation-message container-fluid">
  <div class="row justify-content-center p-md-5">
    <div class="col-12 mt-5">
      <img src="assets/favicon/android-chrome-192x192.png" class="mx-auto d-block" />
    </div>
    <h1 class="col-12 text-center">Diag energie 3D</h1>
  </div>
  <div class="row mt-5 justify-content-center text-center">
    <div class="col-sm-9 col-md-7 col-lg-5">
      <h1>Merci de tourner votre tablette en mode paysage pour pouvoir utiliser l'application.</h1>
    </div>
  </div>
</div>

<div class="layout-check-version-backdrop" *ngIf="isVersionOutdated$ | async">
  <div class="layout-check-version-modal">
    <span>Une nouvelle version est disponible ! Merci de rafraichir la page</span>
    <button
      class="c-pointer rounded-button d-flex justify-content-center align-items-center py-2 px-3 border-0 text-white bg-primary"
      (click)="refreshLocation()">
      <ng-container *ngIf="!updateVersionSubscription; else getNewVersion">Rafraîchir</ng-container>
      <ng-template #getNewVersion>
        Récupération de la version
        <mat-spinner color="accent" class="ml-2" [diameter]="20" *ngIf="updateVersionSubscription"></mat-spinner>
      </ng-template>
    </button>
  </div>
</div>

<app-cn-spinner></app-cn-spinner>
