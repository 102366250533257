import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutComponent } from './layout.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PicturePrevizualizerComponent } from './picture-previzualizer/picture-previzualizer.component';

@NgModule({
  declarations: [LayoutComponent, PicturePrevizualizerComponent],
  imports: [CommonModule, RouterModule, SharedModule, MatButtonModule, MatProgressSpinnerModule],
  exports: [LayoutComponent],
})
export class LayoutModule {}
