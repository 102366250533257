import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-quit-editor-confirm-modal-component',
  templateUrl: './quit-configuration-confirm-modal.component.html',
  styleUrls: ['./quit-configuration-confirm-modal.component.scss'],
})
export class QuitConfigurationConfirmModalComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<QuitConfigurationConfirmModalComponent>) {}

  quitWithoutSaving() {
    this.dialogRef.close('quit_without_saving');
  }

  quitWithSaving() {
    this.dialogRef.close('quit_with_saving');
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  ngOnInit(): void {}
}
