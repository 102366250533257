import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User } from '../model/user.model';

@Injectable({ providedIn: 'root' })
export class UserService {
  public resourceUrl = environment.apiUrl + '/account';

  constructor(private http: HttpClient) {}

  updateAccount(account: User): Observable<User> {
    return this.http.post<User>(this.resourceUrl, account);
  }

  updatePassword(newPassword: string, currentPassword: string): Observable<any> {
    return this.http.post(`${this.resourceUrl}/change-password`, { currentPassword, newPassword });
  }

  resetPasswordInit(email: string): Observable<any> {
    return this.http.post(`${this.resourceUrl}/reset-password/init`, email);
  }

  resetPasswordFinish(key: string, newPassword: string): Observable<any> {
    return this.http.post(`${this.resourceUrl}/reset-password/finish`, { key, newPassword });
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(this.resourceUrl);
  }
}
