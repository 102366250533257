//***********************************************************************************
//***********************************************************************************
//**** fh_view_subjective : 3D subjective view
//***********************************************************************************
//***********************************************************************************

import {Box3, DoubleSide, ImageUtils, Matrix4, Mesh, MeshBasicMaterial, PlaneGeometry, Scene, Vector3} from 'three';
import {cartesian_to_polar, dummy_target, polar_to_cartesian} from "./fh_view";
import {fh_view_perspective} from "./fh_view_perspective";
import {fh_view_map} from "./fh_view_map";
// @ts-ignore
import groundTile from '../images/ground_tile.png';

//***********************************************************************************
//**** Class view
//***********************************************************************************

export class fh_view_subjective extends fh_view_perspective {
	//*****************************************************
	//*** Constructor
	constructor(div_id, map_div_id, scene) {
		super(div_id, scene);

		this._camera.fov=90;
		this._camera.far = 5;
		this._viewer_height = 1.6;
		this._door_visibility = false;
		this._tile_spacing = 0.5;
		this._tile_radius = 1;
		this._tile_image = groundTile;

		this._ground = null;
		//this._ground_tile = new CircleGeometry(this._tile_radius, 32);
		this._ground_tile = new PlaneGeometry(this._tile_radius, this._tile_radius, 1,1);

		var map = new ImageUtils.loadTexture(this._tile_image);

		this._ground_material = new MeshBasicMaterial();
		this._ground_material.map = map;
		this._ground_material.side = DoubleSide;
		this._ground_material.color.r = 0;
		this._ground_material.color.g = 1;
		this._ground_material.color.b = 1;
		this._ground_material.transparent=true;
		this._ground_material.opacity=1;

		this._ground_material_mouseover = new MeshBasicMaterial();
		this._ground_material_mouseover.map = map;
		this._ground_material_mouseover.side = DoubleSide;
		this._ground_material_mouseover.color.r = 1;
		this._ground_material_mouseover.color.g = 1;
		this._ground_material_mouseover.color.b = 0;
		this._ground_material_mouseover.transparent=true;
		this._ground_material_mouseover.opacity=0.8;

		this._current_tile = null;
		this._current_space = null;

		this._ground = new Scene();
		this._ground_tile = new Mesh(this._ground_tile, this._ground_material);
		this._ground.add(this._ground_tile);
		this._ground_tile_position = new Vector3(0,0,0);


		/*var tmp = document.createElement('div');
		tmp.className="map_window";
		tmp.id='map_window';
		this._container.appendChild(tmp);*/
		this._view_2d = new fh_view_map(map_div_id,scene,this);
        this.secondary_views.push(this._view_2d);

		this._cb_current_storey_changed = null;
		this._cb_current_space_changed = null;

		this._mouse_event_stealer = null;
		this._chrono = new Date();
		this._chrono = false;
        this.update_scene();
	}

	//*****************************************************
	//**** Serialisation
	//*****************************************************
	serialize() {
		var json = super.serialize();
		json.type = "subjective";
		json.viewer_height = this._viewer_height;
		json.door_visibility = this._door_visibility;
		return json;
	}

	unserialize(json) {
		if (json.type != "subjective") return;
		if (typeof(json.viewer_height) != 'undefined')
			this._viewer_height = json.viewer_height;
		if (typeof(json.door_visibility) != 'undefined')
			this.set_door_visibility(json.door_visibility);

		/*this._camera.position.set(json.position[0],json.position[1],json.position[2]);
		this._camera.up.set(json.upaxis[0],json.upaxis[1],json.upaxis[2]);
		var target = fh_add(json.position,json.direction);
		this._camera.lookAt(new Vector3(target[0],target[1],target[2]));
		this._camera.fov = json.fov;*/
		this.go_to_position(new Vector3(json.position[0],json.position[1],json.position[2]-this._viewer_height),new Vector3(json.direction[0],json.direction[1],json.direction[2]));
		this._end_fov = json.fov;
	}

	//*****************************************************
	//**** animation mode
	//*****************************************************
	set_animation_mode(v) {
		super.set_animation_mode(v);
		this._view_2d.set_animation_mode(v);
	}

	//*****************************************************
	//**** mouse event management. Default is true;
	//*****************************************************
	set_mouse_management(v) {
		super.set_mouse_management(v);
		this._view_2d.set_mouse_management(v);
	}

	//*****************************************************
	//**** Export map
	//*****************************************************
	map_to_image_url(w,h) {

		return this._view_2d.to_image_url(w,h);
	}

	//********************************************************
	//*** Update perspective camera
	update_camera()	{
		super.update_camera();
	}

	set_current_storey(v) {
		this._view_2d.set_current_storey(v);

		var best_tile = null;
		var best_distance = 0;
		for (var i in this._scene._spaces)
		{
			var space = this._scene._spaces[i];
			if (space.storey != this._view_2d._current_storey) continue;
			var distance = this._camera.position.distanceTo(space._best_position);
			if (best_tile && best_distance < distance) continue;
			best_tile = space._best_position;
			best_distance = distance;
		}
		if (best_tile)
			this.go_to_position(best_tile);
	}

	get_current_storey() {
		return this._view_2d.get_current_storey();
	}

	set_door_visibility(v)
	{
		this._door_visibility = v;
		this._scene.set_product_type_visibility(v,"porte");
	}

	get_door_visibility()
	{
		return this._door_visibility;
	}

	//*****************************************************
	//**** Callbacks for navigation
	//*****************************************************

	//*****************************************************
	//*** Window resize callback
	manage_window_resize() {
		super.manage_window_resize();
	}

	//********************************************************
	//*** Mouse wheel management
	manage_mouse_wheel(ev) {
		ev.preventDefault();

		if (ev.deltaY > 0.2)
		{
			if (this._camera.fov < 90)
				this._camera.fov++;
		}
		else
		{
			if (this._camera.fov > 1)
				this._camera.fov--;
		}

		//*** update camera matrix
		this.update_camera();
		this.call("view_change");
		this.refresh_rendering();
	}

	//*****************************************************
	//*** Pan operation
	do_pan(dx, dy) {

		//*****************************************
		//*** vertical rotation
		var world_direction = this._camera.getWorldDirection(dummy_target).clone();

		//*** change world direction. We will apply the same rotation to position
		var polar_direction = cartesian_to_polar(world_direction);

		polar_direction.y -= dy;
		if (polar_direction.y > 1.57) polar_direction.y = 1.57;
		else if (polar_direction.y < -1.57) polar_direction.y = -1.57;
		polar_direction.x += dx;
		world_direction = polar_to_cartesian(polar_direction);

		world_direction.add(this._camera.position);

		this._camera.lookAt(world_direction);
		this._camera.updateProjectionMatrix();
	}

	//*****************************************************
	//*** ground tile
	set_ground_tile(tile) {
		if (this._current_tile == tile) return;

		if (this._current_tile)
			this._current_tile.material = this._ground_material;

		this._current_tile = tile;

		if (this._current_tile)
			this._current_tile.material = this._ground_material_mouseover;

		this.refresh_rendering();
	}

	//*****************************************************
	//*** go to a given tile
	go_to_position(position, direction=null)
	{
		if (!this._animation) this.start_animation();

		this._end_fov = 90;

		this._end_position.copy(position);
		this._end_position.z += this._viewer_height;
		this._end_target.copy(this._end_position);
		if (direction)
			this._end_target.add(direction);
		else
			this._end_target.add(this._camera.getWorldDirection(dummy_target));

		var storey_before = this._view_2d._current_storey;
		this._view_2d.move_camera(position);
		if (this._cb_current_storey_changed && storey_before != this._view_2d._current_storey)
			this._cb_current_storey_changed(this._view_2d._current_storey);
		if (this._cb_current_space_changed && this._current_space != this._view_2d._current_space)
		{
			this._current_space = this._view_2d._current_space;
			this._cb_current_space_changed(this._current_space);

			//*** Set visible objects only for those who are in current space */
			var nb_visible = 0;
			var nb_invisible = 0;
			for (var i in this._scene._products)
			{
				var product = this._scene._products[i];
				if (typeof(product.json_object) != 'object')  continue;
				var sp = product.json_object.SPACE;
				if (typeof(sp) != 'string' || sp == "" || sp == "Inconnu") continue;
				if (product.json_object.Code_BIM == "WALL") console.log(sp,product.json_object);
				product.visible = (sp == this._current_space.BIMID);
				if (product.visible) nb_visible++;
				else nb_invisible++;
			}
			console.log("product visibility : " + nb_visible + " visible / " + nb_invisible + " / " + this._scene._products.length);
		}

		this._mouse_operation = -1;

		this.refresh_rendering();
	}

	//*****************************************************
	//*** Set view height
	set_viewer_height(viewer_height)
	{
		if (this._viewer_height == viewer_height) return;

		this.start_animation();
		this._end_target.copy(this._start_target);
		this._end_target.z += viewer_height - this._viewer_height;
		this._end_position.copy(this._start_position);
		this._end_position.z += viewer_height - this._viewer_height;
		this._viewer_height = viewer_height;
		this.refresh_rendering();
	}

	//*****************************************************
	//*** Mouse up
	manage_mouse_down(ev) {
		if (this._mouse_event_stealer && this._mouse_event_stealer("mousedown",ev))
			return;
		super.manage_mouse_down(ev);
	}

	//*****************************************************
	//*** Mouse up
	manage_mouse_up(ev) {
		if (this._mouse_event_stealer && this._mouse_event_stealer("mouseup",ev))
			return;
		if (Math.abs(ev.clientX-this._last_mouse_down[0]) + Math.abs(ev.clientY-this._last_mouse_down[1]) < 10)
		{
			this._next_tile_position = this.find_floor_hit(this.read_mouse_position(ev.clientX, ev.clientY));
			if (this._next_tile_position)
				this.go_to_position(this._next_tile_position);
		}

		this._next_tile_position = null;
		super.manage_mouse_up(ev);
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_move(ev) {
		ev.preventDefault();
		if (this._mouse_event_stealer && this._mouse_event_stealer("mousemove",ev))
			return;
		var new_mouse = this.read_mouse_position(ev.clientX, ev.clientY);
		this._dxm = new_mouse.x - this._mouse_position.x;
		this._dym = new_mouse.y - this._mouse_position.y;
		this._mouse_position = new_mouse;

		//*** Mouseover management
		if (this._mouse_operation < 0)
		{
			this._next_tile_position = this.find_floor_hit(this._mouse_position);
			this.refresh_rendering();
			return true;
		}

		//*** compute angular displacements
		var scale = this._camera.fov * Math.PI / (180 * this._container.clientHeight);
		var dx = scale * this._dxm * this._container.clientWidth;
		var dy = scale * this._dym * this._container.clientHeight;

		this.do_pan(dx,dy);
		this._camera.updateProjectionMatrix();
		this.update_camera();
		this.call("view_change");
		this.refresh_rendering();
	}

	//*****************************************************
	//*** Mouse leave
	manage_mouse_leave(ev) {
		if (this._mouse_event_stealer && this._mouse_event_stealer("mouseleave",ev))
			return;
		ev.preventDefault();
		this._next_tile_position = null;
		this._scene.set_mouseover_object(null);
		this.refresh_rendering();
	}

	//*****************************************************
	//*** Find floor hit
	find_floor_hit(mouse_position) {
		this.set_raycaster(mouse_position);
		if (this._raycaster.ray.direction.z >= 0) return null;
		var intersects = this._raycaster.intersectObjects(this._scene._products, true);
		if (intersects.length == 0) return null;

		var normal = intersects[0].object.localToWorld(new Vector3(0,0,0));
		normal.sub(intersects[0].object.localToWorld(intersects[0].face.normal));
		normal.normalize();
		if (Math.abs(normal.z) < 0.99) return null;
		return intersects[0].point;
	}

	//*****************************************************
	//*** update scene
	update_scene() {
		super.update_scene();
		var direction = new Vector3(0,0,-1);
		var box = new Box3();
		for (var n in this._scene._spaces)
		{
			var space = this._scene._spaces[n];
			box.setFromObject(space);
			var jmin = Math.floor(box.min.y)-1;
			var jmax = Math.ceil(box.max.y)+1;
			var imin = Math.floor(box.min.x)-1;
			var imax = Math.ceil(box.max.x)+1;
			var origin = new Vector3(0,0,box.min.z+1);
			var pattern_map={};
			var nj = -1;
			for (var j = jmin;j<=jmax;j+=this._tile_spacing)
			{
				nj++;
				pattern_map[nj] = {};
				var ni = -1;
				for (var i = imin;i<=imax;i+=this._tile_spacing)
				{
					ni++;
					origin.x = i;
					origin.y = j;
					this._raycaster.set(origin,direction);
					if (this._raycaster.intersectObject(space,true).length == 0) continue;
					pattern_map[nj][ni]  =new Vector3(i,j,box.min.z);
				}
			}

			var prevcell = [];
			for (var xj in pattern_map)
			{
				var j = parseInt(xj);
				for (var xi in pattern_map[j])
				{
					var i = parseInt(xi);
					var cell = pattern_map[j][i];
					if (typeof(cell) != 'object') continue;
					cell._neighbours = [];
					var c;
					if (typeof(pattern_map[j-1]) == 'object')
					{
						c = pattern_map[j-1][i];
						if (typeof(c) == 'object') cell._neighbours.push(c);
					}
					if (typeof(pattern_map[j+1]) == 'object')
					{
						c = pattern_map[j+1][i];
						if (typeof(c) == 'object') cell._neighbours.push(c);
					}
					c = pattern_map[j][i-1];
					if (typeof(c) == 'object') cell._neighbours.push(c);
					c = pattern_map[j][i+1];
					if (typeof(c) == 'object') cell._neighbours.push(c);

					if (cell._neighbours.length != 4)
					{
						cell._order = 0;
						prevcell.push(cell);
					}
				}
			}
			var best_cell = null;
			while (true)
			{
				var nextcell = [];
				// @ts-ignore
                for (var i in prevcell)
				{
					var cell = prevcell[i];
					// @ts-ignore
                    for (var j in cell._neighbours)
					{
						var c = cell._neighbours[j];
						if (typeof(c._order) == 'number') continue;
						c._order = cell._order+1;
						if (best_cell == null || c._order > best_cell._order)
							best_cell = c;
						nextcell.push(c);
					}
				}
				if (nextcell.length ==0) break;
				prevcell = [];
				// @ts-ignore
                for (var i in nextcell)
					prevcell.push(nextcell[i]);
			}
			if (best_cell)
				space._best_position = best_cell;
			else
				space._best_position = new Vector3((box.min.x+box.max.x)*0.5,(box.min.y+box.max.y)*0.5,box.min.z);
		}
	}

	//*****************************************************
	//*** camera reset
	reset_camera (space = null) {
		if (space == null)
		{
			if (this._scene._spaces.length == 0) return;
			space = this._scene._spaces[0];
			this._view_2d.reset_camera();
		}

		if (typeof(space._best_position) != 'object') return;
		this.go_to_position(space._best_position);
	}

	//*****************************************************
	//*** camera reset
	zoom_on_element (space, position) {
	}

	//***********************************************************************************
	//**** zoom on selection
	zoom_on_selection() {
		if (this._scene._selection.length != 1) return;

		var bb = new Box3().setFromObject(this._scene._selection[0]);
		var center = bb.min.clone();
		center.add(bb.max);
		center.multiplyScalar(0.5);

		var size = bb.min.distanceTo(bb.max);
		if (size < 1) size = 1;

		this.start_animation();

		var z = bb.max.z;
		if (this._scene._selection[0].bim_type == "flat" || this._scene._selection[0].bim_type == "space")
			z -= 0.5;

		this._end_clipping_plane  = (z-this._scene._bounding_box.min.z) / (this._scene._bounding_box.max.z -  this._scene._bounding_box.min.z) - 0.05;

		var dir = center.clone();
		dir.sub(this._camera.position);
		dir.z = 0;
		dir.normalize();
		dir.multiplyScalar(size*2);
		dir.z = -size*2;

		this._end_target.copy(center);
		this._end_position.copy(center);
		this._end_position.sub(dir);

		this.refresh_rendering();
	}

	//***********************************************************************************
	//**** Geometry rendering
	render_geometry() {
		super.render_geometry();
		if (this._next_tile_position)
		{
			this._ground_tile.position.set(0,0,0);
			this._ground_tile.rotation.set(0,0,0);
			this._ground_tile.scale.set(1,1,1);
			this._ground_tile.updateMatrix();
			var matrix = new Matrix4();
			matrix.makeTranslation(this._next_tile_position.x,this._next_tile_position.y,this._next_tile_position.z + 0.05);
			this._ground_tile.applyMatrix(matrix);

			this._renderer.render(this._ground, this._camera);
		}
		this._view_2d.refresh_rendering();
	}
}
