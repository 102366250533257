import { cn_element_type } from './cn_element_type';
import { cn_material } from './cn_material';

export class cn_element_type_layer_material extends cn_element_type {

    constructor() {
        super();
        this.layers = [new cn_material('Inconnu', 'unknown', 0.3)];
    }

}
