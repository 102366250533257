import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { PicturePrevizualizerStore } from 'src/app/shared/store/picture-previzualizer.store';

@Component({
  selector: 'app-picture-previzualizer',
  templateUrl: './picture-previzualizer.component.html',
  styleUrls: ['./picture-previzualizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PicturePrevizualizerComponent implements OnInit {
  currentPictureUrl$: Observable<string>;

  constructor(private picturePrevizualizeStore: PicturePrevizualizerStore) {}

  ngOnInit(): void {
    this.currentPictureUrl$ = this.picturePrevizualizeStore.getCurrentPictureUrl$();
  }

  closePicture(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.picturePrevizualizeStore.reset();
  }
}
