import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DrawingPicture, IDrawingPictureHandler } from '@enerbim/cnmap-angular-editor-lib';
import { cn_background_map } from '@enerbim/cnmap-editor';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, toArray } from 'rxjs/operators';
import { ProjectService } from 'src/app/shared/services/project.service';
import { HttpErrorService } from '../../../core/http-error.service';
import { DrawingApiService } from '../../../shared/services/drawing-api.service';
import { DrawingPictureStore } from '../../../shared/store/drawing-picture.store';

@Injectable()
export class DrawingPictureHandler implements IDrawingPictureHandler {
  constructor(
    private projectService: ProjectService,
    private httpErrorService: HttpErrorService,
    private drawingPictureStore: DrawingPictureStore,
    private drawingApiService: DrawingApiService,
  ) {
    cn_background_map.image_id_to_url = fileId =>
      this.drawingPictureStore.drawingPicturesSnapshot.find(it => it.fileId == fileId)?.imageUrl;
  }

  deletePictures(filesIds: string[]): Observable<any> {
    return from(filesIds).pipe(
      concatMap(fileId => this.deletePicture(fileId)),
      toArray(),
    );
  }

  getDrawingPictures(): Observable<DrawingPicture[]> {
    return this.drawingPictureStore.drawingPictures$;
  }

  deletePicture(fileId: string): Observable<any> {
    const projectId = this.projectService.getCurrentProjectSnapshot().id;
    return this.drawingApiService
      .deleteDrawingPicture(projectId, fileId)
      .pipe(tap(() => this.drawingPictureStore.removePicture(fileId)));
  }

  renamePicture(fileId: string, fileName: string): Observable<any> {
    const projectId = this.projectService.getCurrentProjectSnapshot().id;
    return this.drawingApiService
      .renameDrawingPicture(projectId, fileId, fileName)
      .pipe(
        tap(() =>
          this.drawingPictureStore.setDrawingPictures(
            this.drawingPictureStore.drawingPicturesSnapshot.map(dp =>
              dp.fileId === fileId ? { ...dp, fileName } : dp,
            ),
          ),
        ),
      );
  }

  uploadDrawingPictureFunction(formData: FormData): Observable<HttpEvent<any>> {
    return this.drawingApiService
      .uploadDrawingPicture(this.projectService.getCurrentProjectSnapshot().id, formData)
      .pipe(
        catchError(err => {
          // todo: ASI manage offline scenario
          return throwError(err);
        }),
        switchMap(ev =>
          ev.type === HttpEventType.Response
            ? this.drawingPictureStore.addDrawingPicture$(ev.body, formData.get('file') as File).pipe(map(() => ev))
            : of(ev),
        ),
        catchError(err => this.httpErrorService.handleError(err)),
      );
  }
}
