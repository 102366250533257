'use strict';
import { cn_beam } from '../model/cn_beam';
import { cn_column } from '../model/cn_column';
import { cn_pipe } from '../model/cn_pipe';
import { cn_roof_height } from '../model/cn_roof_height';
import { cn_roof_line } from '../model/cn_roof_line';
import { cn_roof_opening } from '../model/cn_roof_opening';
//***********************************************************************************
//***********************************************************************************
//**** cn_map : base class for a cn map svg canvas
//***********************************************************************************
//***********************************************************************************
import { cn_scene } from '../model/cn_scene';
import { cn_slab_opening } from '../model/cn_slab_opening';
import { cn_space } from '../model/cn_space';
import { cn_stairs } from '../model/cn_stairs';
import { cn_vertex } from '../model/cn_vertex';
import { CN_INNER, CN_OUTER, cn_wall, cn_wall_delegate } from '../model/cn_wall';
import { logger } from '../utils/cn_logger';
import {
    cn_add,
    cn_cart,
    cn_clone,
    cn_dist,
    cn_dot,
    cn_intersect_line,
    cn_middle,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_point_on_segment,
    cn_polar,
    cn_project_line,
    cn_project_segment,
    cn_rotate,
    cn_sub
} from '../utils/cn_utilities';
import { cn_camera } from './cn_camera';

export class cn_snap {
    constructor(start_position, range, previous_point, camera) {
        this.start_position = cn_clone(start_position);
        this.range = range;
        this.previous_point = previous_point;
        this.camera = camera;

        this.position = this.start_position; 	//*** current position of snapped point
        this.distance = this.range; 	//*** distance to start position
        this.freedoms = 2; 		//*** number of freedom degrees remaining
        this.direction = null;	//*** if 1 degree freedom, direction of axe
        this.vertex = null;		//*** snapped vertex
        this.wall = null;			//*** snapped wall
        this.contour = null;			//*** snapped contour
        this.contour_vertex = null;	//*** snapped contour vertex
        this.delegate = null;

        this.space = null;
        this.snap_elements = [];

        this.draw_svg_angles = true;
        this.svg = '';		//*** svg code to draw extras
    }

    //*****************************************************************
    //*** SNAP on a wall
    //*****************************************************************
    check_wall(wall, store_element = true, display_log = false) {
        const v0 = wall.vertex_position(0);
        const v1 = wall.vertex_position(1);
        const d0 = cn_sub(this.start_position, v0);
        var y = cn_dot(d0, wall.bounds.normal);
        if (display_log) logger.log(`y = ${y} - range : ${wall.bounds.y0} ${wall.bounds.y1} ${wall.bounds.normal}  ${wall.bounds.direction}`);
        if (y > wall.bounds.y1 + this.camera.snap_world_distance) return false;
        if (y < wall.bounds.y0 - this.camera.snap_world_distance) return false;

        var x = cn_dot(d0, wall.bounds.direction);
        const b = (y > 0) ? wall.bounds.y1 : -wall.bounds.y0;
        if (y > 0 && b > 0) y /= b;
        if (y > 1) y = 1;
        else if (y < -1) y = -1;
        var x0 = (y > 0) ? cn_dot(cn_sub(wall.shape[1], v0), wall.bounds.direction) : cn_dot(cn_sub(wall.shape[0], v0), wall.bounds.direction);
        x0 *= Math.abs(y);

        var x1 = (y > 0) ? cn_dot(cn_sub(wall.shape[2], v0), wall.bounds.direction) : cn_dot(cn_sub(wall.shape[3], v0), wall.bounds.direction);
        x1 *= Math.abs(y);
        x1 += wall.bounds.length * (1 - Math.abs(y));

        if (display_log) logger.log(`x = ${x} - range : ${x0} ${x1} - snap dst : ${this.distance} cam snap ${this.camera.snap_world_distance}`);
        if (x + this.camera.snap_world_distance < x0) return false;
        if (x - this.camera.snap_world_distance > x1) return false;

        if (x < this.distance || x > wall.bounds.length - this.distance) {
            if (display_log) logger.log('on vertex');
            const vertex = (x < this.distance) ? wall.vertices[0] : wall.vertices[1];
            const dis = cn_dist(this.start_position, vertex.position) - 0.01;
            if (this.freedoms == 0 && dis >= this.distance) return false;

            this.freedoms = 0;
            this.position = vertex.position;
            this.distance = dis;
            if (store_element) {
                this.vertex = vertex;
                this.delegate = null;
                this.wall = wall;
            }
            var sc0 = this.camera.world_to_screen(this.position);
            const sz = 5 * this.camera.snap_world_distance;
            this.svg = '<line class=\'snap_wall large\' x1=\'' + (sc0[0] - sz) + '\' y1=\'' + sc0[1] + '\' x2=\'' + (sc0[0] + sz) + '\' y2=\'' + sc0[1] + '\' />';
            this.svg += '<line class=\'snap_wall large\' x1=\'' + sc0[0] + '\' y1=\'' + (sc0[1] - sz) + '\' x2=\'' + sc0[0] + '\' y2=\'' + (sc0[1] + sz) + '\' />';
            return true;
        }
        if (display_log) logger.log('on wall');

        if (this.freedoms != 2) return false;

        this.freedoms = 1;
        this.position = cn_add(v0, cn_mul(wall.bounds.direction, x));
        this.direction = wall.bounds.direction;
        this.distance = cn_dist(this.position, this.start_position);
        if (display_log) logger.log(`on wall position = ${this.position} - v0 = ${v0} v1 = ${v1}`);
        if (store_element) {
            this.vertex = null;
            this.delegate = null;
            this.wall = wall;
        }
        var sc0 = this.camera.world_to_screen(v0);
        var sc1 = this.camera.world_to_screen(wall.vertex_position(1));
        this.svg = '<line class=\'snap_wall\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
        return true;

        //if (this.freedoms == 0) return false;
        var res = wall.intersects(this.start_position, this.distance);
        if (res < 0) return false;
        if (this.freedoms == 2) {
            if (res == 2) {
                this.freedoms = 1;
                this.position = wall.project(this.start_position);
                this.distance = cn_dist(this.position, this.start_position);
                this.direction = cn_sub(wall.vertices[1].position, wall.vertices[0].position);
                cn_normalize(this.direction);
                if (store_element)
                    this.wall = wall;
                var sc0 = this.camera.world_to_screen(wall.vertices[0].position);
                var sc1 = this.camera.world_to_screen(wall.vertices[1].position);
                this.svg += '<line class=\'snap_wall\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
                return true;
            }

            this.freedoms = 0;
            this.position = wall.vertices[res].position;
            this.distance = cn_dist(this.position, this.start_position);
            if (store_element) {
                this.vertex = wall.vertices[res];
                this.wall = wall;
            }
            return true;
        }

        if (res == 2) {
            var direction = cn_sub(wall.vertices[1].position, wall.vertices[0].position);
            var length = cn_normalize(direction);
            var this_normal = cn_normal(this.direction);
            var x = cn_dot(this_normal, direction);
            if (Math.abs(x) < 0.01) return false;
            var z = cn_dot(this_normal, cn_sub(this.position, wall.vertices[0].position)) / x;
            if (z < 0) return false;
            if (z > length) return false;
            var p = cn_add(wall.vertices[0].position, cn_mul(direction, z));
            var distance = cn_dist(this.start_position, p);
            if (distance >= this.distance) return false;
            this.freedoms = 0;
            this.position = p;
            this.distance = distance;
            var sc0 = this.camera.world_to_screen(wall.vertices[0].position);
            var sc1 = this.camera.world_to_screen(wall.vertices[1].position);
            this.svg += '<line class=\'snap_wall\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\'/>';
            return true;
        }
        var pp = wall.vertices[res].position;
        if (Math.abs(cn_dot(cn_normal(this.direction), cn_sub(pp, this.position))) > 0.01) return false;
        pp = cn_project_line(p, this.position, this.direction);
        var distance = cn_dist(pp, this.start_position);
        if (distance >= this.range) return false;
        this.freedoms = 0;
        this.position = pp;
        this.distance = distance;
        return true;
    }

    /**
     * Check a list of walls, try to find delegates.
     * @param {cn_wall} wall
     * @returns
     */
    check_wall_delegates(axis, thickness, wall, display_log = false) {
        const thin_wall = (wall.wall_type.free || wall.wall_type.thickness == 0);
        if (thin_wall) return false;

        const incidence = (this.previous_point) ? cn_sub(this.start_position, this.previous_point) : null;
        if (incidence) cn_normalize(incidence);

        const w0 = wall.vertex_position(0);
        const w1 = wall.vertex_position(0);


        //*** which vertex can we delegate ? */
        var best_limit = 2 * wall.wall_type.thickness;
        var vertex_index = -1;
        for (var nv = 0; nv < 2; nv++) {
            var x = cn_dot(wall.bounds.direction, cn_sub(this.start_position, wall.vertices[nv].position));
            if (Math.abs(x) > best_limit) continue;
            vertex_index = nv;
            best_limit = Math.abs(x);
        }
        if (vertex_index < 0) return false;

        //*** we forbid to add a delegate on a delegate end */
        if (wall.delegates[vertex_index]) return false;

        const vertex = wall.vertices[vertex_index];

        for (var side = 0; side < 4; side++) {
            //*** check if there already exists a delegate on this side for this vertex
            if (wall.bears_delegate(side, vertex_index)) continue;

            //*** Compute points of the side to explore */
            var v0 = [0, 0];
            var v1 = [0, 0];
            if (side < 2) {
                //*** side lines */
                v0 = (side == 0) ? wall.shape[0] : wall.shape[2];
                v1 = (side == 0) ? wall.shape[3] : wall.shape[1];
            } else {
                //*** top lines */
                if (side - 2 != vertex_index) continue;

                //*** we already avoid a top line if vertex already has something on this side */
                if (vertex.walls.some(w => {
                    if (w != wall) {
                        const vi = w.vertices.indexOf(vertex);
                        if (w.delegates[vi] == null) return true;
                        if (w.delegates[vi].wall == wall && w.delegates[vi].side == side) return true;
                    }
                    return false;
                })) continue;

                v0 = (side == 2) ? wall.shape[1] : wall.shape[3];
                v1 = (side == 2) ? wall.shape[0] : wall.shape[2];
            }

            if (display_log) logger.log(`   side : [${v0[0]},${v0[1]}] - [${v1[0]},${v1[1]}]`);

            //*** we start side exploration */
            var direction = cn_sub(v1, v0);
            var length = cn_normalize(direction);

            //*** must be proper orientation */
            if (incidence && cn_dot(incidence, cn_normal(direction)) < 0.01) continue;

            //*** check if point is on the side */
            const vp = cn_sub(this.start_position, v0);
            const y = cn_dot(cn_normal(direction), vp);
            if (Math.abs(y) > this.camera.snap_world_distance) continue;

            //*** check that position is appropriate regarding the vertex */
            var xmin = 0;
            var xmax = length;
            if (side < 2) {
                const xb = cn_dot(cn_sub(wall.vertices[vertex_index].position, v0), direction);
                if (side == vertex_index) xmax = xb + wall.wall_type.thickness;
                else xmin = xb - wall.wall_type.thickness;
            }

            //*** check position along the side */
            var x = cn_dot(direction, vp);
            if (display_log) logger.log(`x= ${x} ${length - x} - y = ${y} - xmin = ${xmin} - xmax = ${xmax}`);
            if (x + 0.1 < xmin || x - 0.1 > xmax) continue;

            //*** in case of incidence, check snap on bounds */
            var d0 = 0.5 * thickness;
            var d1 = -0.5 * thickness;
            if (axis == CN_OUTER) {
                d0 = 0;
                d1 = -thickness;
            } else if (axis == CN_INNER) {
                d0 = thickness;
                d1 = 0;
            }

            var x0 = -1;
            var x0_start = true;

            //*** snap position to beginning of side */
            if (side >= 2 || side == vertex_index) {
                x0_start = true;
                if (incidence)
                    x0 = compute_impact_thickness(cn_mul(direction, -1), v0, this.previous_point, d0, display_log);
                else
                    x0 = -d1;
                if (display_log) logger.log('x0 = ', x0);
            }

            //*** snap position to end of side */
            if (side >= 2 || side != vertex_index) {
                var x1 = -1;
                if (incidence)
                    x1 = compute_impact_thickness(direction, v1, this.previous_point, d1);
                else
                    x1 = d0;
                if (x1 >= 0) {
                    x1 = length - x1;
                    if (display_log) logger.log('x1 = ', x1);
                    if (x0 < 0 || Math.abs(x - x1) < Math.abs(x - x0)) {
                        x0 = x1;
                        x0_start = false;
                    }
                }
            }

            if (x0 >= 0) {
                const apply = (x0_start) ? x < x0 + this.camera.snap_world_distance : x > x0 - this.camera.snap_world_distance;
                if (apply) x = x0;
            }

            const pos = cn_add(v0, cn_mul(direction, x));
            const dist = cn_dist(pos, this.start_position);
            if (this.freedoms == 0 && dist >= this.distance) continue;
            this.position = pos;
            this.distance = dist;
            this.wall = null;
            this.vertex = vertex;
            this.delegate = new cn_wall_delegate(this.position, wall, side);
            this.delegate.locked = true;
            this.freedoms = 0;
            var dir = (incidence) ? cn_sub(this.position, this.previous_point) : cn_normal(direction);
            cn_normalize(dir);
            var ps = cn_dot(direction, cn_normal(dir));
            if (!incidence) ps *= -1;
            var sc0 = this.camera.world_to_screen(cn_add(this.position, cn_mul(direction, d0 / ps)));
            var sc1 = this.camera.world_to_screen(cn_add(this.position, cn_mul(direction, d1 / ps)));
            this.svg = '<line class=\'snap_wall large\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';

            if (!incidence) {
                const dd = cn_mul(dir, 5 * this.camera.snap_world_distance);
                var sc00 = this.camera.world_to_screen(cn_sub(cn_add(this.position, cn_mul(direction, d0 / ps)), dd));
                var sc11 = this.camera.world_to_screen(cn_sub(cn_add(this.position, cn_mul(direction, d1 / ps)), dd));
                this.svg += '<line class=\'snap_wall\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc00[0] + '\' y2=\'' + sc00[1] + '\' />';
                this.svg += '<line class=\'snap_wall\' x1=\'' + sc1[0] + '\' y1=\'' + sc1[1] + '\' x2=\'' + sc11[0] + '\' y2=\'' + sc11[1] + '\' />';
            }

            if (display_log) logger.log('delegate vertex snap', this.delegate, this.svg);

            // @ts-ignore
            if (!incidence) this.delegate.direction = dir;
        }

        return (this.freedoms < 2)
    }

    //*****************************************************************
    //*** SNAP on a point
    //*****************************************************************
    check_point(p) {
        if (this.freedoms != 2) return false;

        var dst = cn_dist(p, this.start_position);
        if (dst >= this.distance) return false;

        this.freedoms = 0;
        this.position = p;
        this.distance = dst;
        return true;
    }

    //*****************************************************************
    //*** SNAP on a segment
    //*****************************************************************
    check_segment(p0, p1, check_center = false) {
        if (this.freedoms == 0) return false;

        var dir = cn_sub(p1, p0);
        var length = cn_normalize(dir);
        if (length < this.range) {
            var dst = cn_dist(p0, this.start_position);
            if (dst < this.distance) {
                this.freedoms = 0;
                this.position = p0;
                this.distance = dst;
                return true;
            }
            return false;
        }

        var nor = cn_normal(dir);

        if (this.freedoms == 2) {
            var xp = cn_sub(this.start_position, p0);
            if (Math.abs(cn_dot(xp, nor)) >= this.distance) return false;
            var e = cn_dot(xp, dir);
            if (e < -this.distance) return false;
            if (e > length + this.distance) return false;
            if (e < this.distance) {
                this.freedoms = 0;
                this.position = p0;
                this.distance = cn_dist(this.position, this.start_position);
                return true;
            }
            if (e > length - this.distance) {
                this.freedoms = 0;
                this.position = p1;
                this.distance = cn_dist(this.position, this.start_position);
                return true;
            }
            if (check_center && Math.abs(e - length * 0.5) < this.distance) {
                this.center = true;
                this.freedoms = 0;
                this.position = cn_middle(p1, p0);
                this.distance = cn_dist(this.position, this.start_position);
                var sc0 = this.camera.world_to_screen(p0);
                var sc1 = this.camera.world_to_screen(p1);
                this.svg += '<line class=\'snap_angle\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
                var dir2 = cn_add(nor, dir);
                cn_normalize(dir2);
                dir2 = cn_mul(dir2, 20);
                for (var k = 0; k < 2; k++) {
                    sc0 = this.camera.world_to_screen(cn_middle((k == 0) ? p0 : p1, this.position));
                    sc1 = cn_add(sc0, dir2);
                    sc0 = cn_sub(sc0, dir2);
                    this.svg += '<line class=\'snap_angle\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
                }
                return true;
            }

            this.freedoms = 1;
            this.position = cn_add(p0, cn_mul(dir, e));
            this.distance = cn_dist(this.position, this.start_position);
            this.direction = dir;

            return true;
        }

        var prj = cn_intersect_line(this.position, this.direction, p0, dir);
        if (prj == null) return false;
        var dist = cn_dist(this.start_position, prj);
        if (dist > this.range) return false;
        var l = cn_dot(dir, cn_sub(prj, p0));
        if (l < 0.01 || l >= length - 0.01) return false;

        this.freedoms = 0;
        this.position = prj;
        this.distance = dist;

        return true;
    }

    //*****************************************************************
    //*** SNAP on a contour
    //*****************************************************************
    check_contour(contour, inner) {
        if (this.freedoms == 0) return false;

        var vertices = (inner) ? contour.inner_contour : contour.vertices;
        var l = vertices.length;
        for (var i = 0; i < l; i++) {
            var v0 = (inner) ? vertices[i] : vertices[i].position;
            var v1 = (inner) ? vertices[(i + 1) % l] : vertices[(i + 1) % l].position;
            if (this.check_segment(v0, v1))
                return true;
        }

        return false;
    }

    //*****************************************************************
    //*** SNAP on a space
    //*****************************************************************
    check_space(space, inner) {
        if (this.freedoms == 0) return false;
        if (space == null) return false;
        for (var i in space.contours) {
            if (this.check_contour(space.contours[i], inner))
                return true;
        }
        return false;
    }

    //*****************************************************************
    /**
     * SNAP on a scene spce limits
     * @param {cn_scene} scene
     * @param {boolean} outside_space : if trus, will also check outside spaces
     * @returns {boolean} returns true if snap had an effect
     */
    check_space_limits(scene, outside_space) {
        if (!scene) return false;
        if (this.freedoms == 0) return false;
        for (var i in scene.spaces) {
            var space = scene.spaces[i];
            if (!outside_space && space.outside) continue;
            if (this.check_space(space, true))
                return true;
        }
        return false;
    }

    //*****************************************************************
    //*** SNAP on a parallel direction
    //*****************************************************************
    check_parallel(p0, p1) {
        if (this.freedoms == 0) return false;
        if (this.previous_point == null) return false;
        var direction = cn_sub(p1, p0);
        if (cn_normalize(direction) < 0.1) return false;
        if (this.freedoms == 2) {
            var prj = cn_project_line(this.start_position, this.previous_point, direction);
            var dist = cn_dist(this.start_position, prj);
            if (dist > this.range) return false;

            this.freedoms = 1;
            this.position = prj;
            this.distance = dist;
            this.direction = direction;
        } else {
            var prj = cn_intersect_line(this.position, this.direction, this.previous_point, direction);
            if (prj == null) return false;
            var dist = cn_dist(this.start_position, prj);
            if (dist > this.range) return false;

            this.freedoms = 0;
            this.position = prj;
            this.distance = dist;
        }

        var dd = cn_mul(direction, 20);
        var sc0 = this.camera.world_to_screen(cn_sub(p0, dd));
        var sc1 = this.camera.world_to_screen(cn_add(p1, dd));
        this.svg += '<line class=\'snap_parallel\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
        sc0 = this.camera.world_to_screen(cn_sub(this.previous_point, dd));
        sc1 = this.camera.world_to_screen(cn_add(this.position, dd));
        this.svg += '<line class=\'snap_parallel\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';

        return true;
    }

    //*****************************************************************
    //*** SNAP on a orthogonal direction
    //*****************************************************************
    check_orthogonal(p0, p1) {
        if (this.freedoms == 0) return false;
        if (this.previous_point == null) return false;
        var direction = cn_sub(p1, p0);
        if (cn_normalize(direction) < 0.1) return false;
        var normal = cn_normal(direction);
        if (this.freedoms == 2) {
            var prj = cn_project_line(this.start_position, this.previous_point, normal);
            var dist = cn_dist(this.start_position, prj);
            if (dist > this.range) return false;

            this.freedoms = 1;
            this.position = prj;
            this.distance = dist;
            this.direction = normal;
        } else {
            var prj = cn_intersect_line(this.position, this.direction, this.previous_point, normal);
            if (prj == null) return false;
            var dist = cn_dist(this.start_position, prj);
            if (dist > this.range) return false;

            this.freedoms = 0;
            this.position = prj;
            this.distance = dist;
        }

        var dd = cn_mul(direction, 20);
        var nn = cn_mul(normal, 20);
        var sc0 = this.camera.world_to_screen(cn_sub(p0, dd));
        var sc1 = this.camera.world_to_screen(cn_add(p1, dd));
        this.svg += '<line class=\'snap_orthogonal\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
        sc0 = this.camera.world_to_screen(cn_sub(this.previous_point, nn));
        sc1 = this.camera.world_to_screen(cn_add(this.position, nn));
        this.svg += '<line class=\'snap_orthogonal\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';

        var cc = cn_intersect_line(p0, direction, this.previous_point, normal);
        dd = cn_mul(direction, this.camera.snap_world_distance);
        nn = cn_mul(normal, this.camera.snap_world_distance);
        var sc = this.camera.world_to_screen(cn_add(cc, cn_add(nn, dd)));
        this.svg += '<path class=\'snap_orthogonal\' d=\'M ' + sc[0] + ' ' + sc[1] + ' ';
        sc = this.camera.world_to_screen(cn_add(cc, cn_sub(nn, dd)));
        this.svg += 'L ' + sc[0] + ' ' + sc[1] + ' ';
        sc = this.camera.world_to_screen(cn_sub(cc, cn_add(nn, dd)));
        this.svg += ' ' + sc[0] + ' ' + sc[1] + ' ';
        sc = this.camera.world_to_screen(cn_sub(cc, cn_sub(nn, dd)));
        this.svg += ' ' + sc[0] + ' ' + sc[1] + ' ';
        this.svg += ' Z\' />';
        return true;
    }

    //*****************************************************************
    //*** SNAP on a orthogonal direction
    //*****************************************************************
    check_direction(p0, p1, direction, check_dir, angle) {
        if (this.freedoms == 0) return false;
        if (this.freedoms == 2) {
            var prj = cn_project_line(this.start_position, this.previous_point, check_dir);
            var dist = cn_dist(this.start_position, prj);
            if (dist > this.range) return false;

            this.freedoms = 1;
            this.position = prj;
            this.distance = dist;
            this.direction = check_dir;
        } else {
            var prj = cn_intersect_line(this.position, this.direction, this.previous_point, check_dir);
            if (prj == null) return false;
            var dist = cn_dist(this.start_position, prj);
            if (dist > this.range) return false;

            this.freedoms = 0;
            this.position = prj;
            this.distance = dist;
        }

        var dd = cn_mul(direction, 20);
        var nn = cn_mul(check_dir, 20);
        var sc0 = this.camera.world_to_screen(cn_sub(p0, dd));
        var sc1 = this.camera.world_to_screen(cn_add(p1, dd));
        this.svg += '<line class=\'snap_angle\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
        sc0 = this.camera.world_to_screen(cn_sub(this.previous_point, nn));
        sc1 = this.camera.world_to_screen(cn_add(this.position, nn));
        this.svg += '<line class=\'snap_angle\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';

        if (angle != 0 && this.draw_svg_angles) {
            var cc = cn_intersect_line(p0, direction, this.previous_point, check_dir);
            this.svg += this.camera.draw_svg_angle(cc, direction, check_dir);
        }
        return true;
    }

    //*****************************************************************
    //*** SNAP on a orthogonal direction
    //*****************************************************************
    check_angles(p0, p1, delta_angle = 45) {
        if (this.freedoms == 0) return false;
        if (this.previous_point == null) return false;
        var direction = cn_sub(p1, p0);
        cn_normalize(direction);
        for (var a = 0; a < 180; a += delta_angle) {
            var polar_dir = cn_polar(direction);
            polar_dir[1] += a * Math.PI / 180;
            var check_direction = cn_cart(polar_dir);
            if (this.check_direction(p0, p1, direction, check_direction, a))
                return true;
        }
        return false;
    }

    check_line(p0, direction) {
        if (this.freedoms == 0) return false;
        var impact = null;
        if (this.freedoms == 1) {
            impact = cn_intersect_line(p0, direction, this.position, this.direction);
        } else
            impact = cn_project_line(this.position, p0, direction);
        if (!impact) return false;

        const dst = cn_dist(impact, this.start_position);
        if (dst >= this.range) return false;

        this.freedoms--;
        this.position = impact;
        this.direction = cn_clone(direction);
        this.distance = dst;
        const sc0 = this.camera.world_to_screen(p0);
        const sc1 = this.camera.world_to_screen(this.position);
        this.svg += '<line class=\'snap_angle\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
        return true;
    }

    //*****************************************************************
    //*** SNAP on the grid
    //*****************************************************************
    check_grid() {
        if (this.freedoms == 0) return false;
        if (!this.camera.use_grid) return false;
        var pos = [0, 0];
        pos[0] = this.camera._grid_min_step * Math.round(this.start_position[0] / this.camera._grid_min_step);
        pos[1] = this.camera._grid_min_step * Math.round(this.start_position[1] / this.camera._grid_min_step);
        if (this.freedoms == 2) {
            var ix = (Math.abs(pos[0] - this.start_position[0]) > this.range);
            var iy = (Math.abs(pos[1] - this.start_position[1]) > this.range);
            if (ix && iy) return false;
            if (!ix && !iy) {
                this.freedoms = 0;
                this.position = pos;
                return true;
            }
            if (ix) pos[0] = this.start_position[0];
            if (iy) pos[1] = this.start_position[1];
            this.freedoms = 1;
            this.position = pos;
            this.direction = (!ix) ? [1, 0] : [0, 1];
            this.distance = cn_dist(this.position, this.start_position);
            return true;
        }

        var min_prj = null;
        var min_dist = this.range;
        for (var k = 0; k < 2; k++) {
            if (Math.abs(this.direction[k]) < 0.01) continue;
            var lambda = (pos[k] - this.position[k]) / this.direction[k];
            var prj = cn_add(this.position, cn_mul(this.direction, lambda));
            var dist = cn_dist(prj, this.start_position);
            if (dist >= min_dist) continue;
            min_dist = dist;
            min_prj = prj;
        }
        if (min_prj == null) return false;

        this.freedoms = 0;
        this.position = min_prj;
        return true;
    }

    //*****************************************************************
    /**
     * Visit all elements and call input function on each point found.
     * @param {function} func
     */
    visit_snap_vertices(func) {
        this.snap_elements.forEach(e => {
            if (e.constructor == cn_space) {
                e.contours.forEach(ctr => {
                    ctr.inner_contour.forEach(func);
                });
            } else if (e.constructor == cn_vertex) {
                func(e.position);
            } else if (e.constructor == cn_wall) {
                func(e.vertices[0].position);
                func(e.vertices[1].position);
            } else if (e.constructor == cn_slab_opening) {
                e.contours.forEach(ctr => {
                    ctr.vertices.forEach(vtx => {
                        func(vtx.position);
                    });
                });
            } else if (e.constructor == cn_stairs) {
                for (var i = 0; i < e.left_vertices.length; i++) {
                    func(e.left_vertices[i]);
                    func(e.right_vertices[i]);
                }
            } else if (e.constructor == cn_pipe) {
                for (var i = 0; i < e.vertices.length; i++) {
                    func(e.vertices[i]);
                }
            } else if (e.constructor == cn_beam) {
                for (var i = 0; i < e.vertices.length; i++) {
                    func(e.vertices[i]);
                }
            } else if (e.constructor == cn_column) {
                func(e.position);
            } else if (e.constructor == cn_roof_line) {
                func(e.vertices[0].position);
                func(e.vertices[1].position);
            } else if (e.constructor == cn_roof_height) {
                func(e.position);
            } else if (e.constructor == cn_roof_opening) {
                e.vertices.forEach(v => func(v));
            }
        });
    }

    //*****************************************************************
    /**
     * Visit all elements and call input function on each couple of points found.
     * @param {function} func
     */
    visit_snap_edges(func) {
        this.snap_elements.forEach(e => {
            if (e.constructor == cn_space) {
                e.contours.forEach(ctr => {
                    for (var i = 0; i < ctr.inner_contour.length; i++) {
                        var v0 = ctr.inner_contour[(i + 1) % ctr.inner_contour.length];
                        var v1 = ctr.inner_contour[i];
                        func(v0, v1);
                    }
                });
            } else if (e.constructor == cn_slab_opening) {
                e.contours.forEach(ctr => {
                    for (var i = 0; i < ctr.vertices.length; i++) {
                        var v0 = ctr.vertices[(i + 1) % ctr.vertices.length];
                        var v1 = ctr.vertices[i];
                        func(v0.position, v1.position);
                    }
                });
            } else if (e.constructor == cn_wall) {
                func(e.vertices[0].position, e.vertices[1].position);
            } else if (e.constructor == cn_stairs) {
                if (e.valid) {
                    for (var i = 0; i < e.left_vertices.length - 1; i++) {
                        func(e.left_vertices[i], e.left_vertices[i + 1]);
                        func(e.right_vertices[i], e.right_vertices[i + 1]);
                    }
                    func(e.left_vertices[0], e.right_vertices[0]);
                    func(e.left_vertices[e.left_vertices.length - 1], e.right_vertices[e.right_vertices.length - 1]);
                }
            } else if (e.constructor == cn_pipe && !e.is_vertical()) {
                func(e.vertices[0], e.vertices[1]);
            } else if (e.constructor == cn_beam) {
                func(e.vertices[0], e.vertices[1]);
            } else if (e.constructor == cn_roof_line) {
                func(e.vertices[0].position, e.vertices[1].position);
            } else if (e.constructor == cn_roof_opening) {
                e.vertices.forEach((v, i) => func(v, e.vertices[(i + 1) % 4]));
            }
        });
    }

    //*****************************************************************
    /**
     * When translating a shape, try to first match one vertex of the shape with  vertices of the environment (0  freedoms).
     * If dos not work, try to match one vertex of the shape with one line of the environment (freedom = 1).
     * start_position is the mouse position when translating.
     * previous_position contains the previous mouse position.
     * @param {number[][]} shape
     * @returns
     */
    snap_translation(shape) {
        if (this.snap_elements.length == 0) return false;
        this.freedoms = 2;
        this.svg = '';

        var best_distance = this.range;
        var obj = this;
        var delta = cn_sub(this.start_position, this.previous_point);

        //*** first try to snap on vertices */
        this.visit_snap_vertices(v0 => {
            shape.forEach(sh => {
                var dst = cn_dist(cn_add(sh, delta), v0);
                if (dst < best_distance) {
                    best_distance = dst;
                    obj.freedoms = 0;
                    obj.position = cn_add(obj.previous_point, cn_sub(v0, sh));
                    obj.svg = '';
                    obj._add_snap_vertex(v0);
                }
            });
        });
        if (this.freedoms < 2) return true;

        //*** Second try to snap on edges */
        this.visit_snap_edges((v0, v1) => {
            shape.forEach(sh => {
                var shd = cn_add(sh, delta);
                if (cn_point_on_segment(shd, v0, v1)) {
                    var proj = cn_project_segment(shd, v0, v1);
                    var new_p = cn_add(obj.previous_point, cn_sub(proj, sh));
                    var dst = cn_dist(new_p, obj.start_position);
                    if (dst < best_distance) {
                        best_distance = dst;
                        obj.freedoms = 1;
                        obj.position = new_p;
                        obj.direction = cn_sub(v1, v0);
                        cn_normalize(obj.direction);
                        obj.svg = '';
                        obj._add_snap_line(v0, v1, 1);
                    }
                }
            });
        });
        if (this.freedoms < 2) return true;

        return false;
    }

    //*****************************************************************
    /**
     * When rotationg a shape around a center, try to match alignment of shape edges with environment.
     * start_position is the mouse position when rotating.
     * previous_^position contains the previous mouse position.
     * @param {number[]} center
     * @param {number[][]} shape
     * @param {boolean} display_log
     * @returns {boolean} true if there was a snap.
     */
    snap_rotation(center, shape, display_log = false) {
        if (this.snap_elements.length == 0) return false;
        this.position = cn_clone(this.start_position);
        var angle_precision = this.range / cn_dist(center, this.start_position);

        var angles = [];
        for (var i = 0; i < shape.length; i++) {
            var d = cn_sub(shape[(i + 1) % shape.length], shape[i]);
            angles.push(cn_polar(d)[1]);
        }

        var start_delta = cn_polar(cn_sub(this.start_position, center))[1] - cn_polar(cn_sub(this.previous_point, center))[1];
        var current_delta = start_delta;

        var obj = this;

        //*** try to snap on edges */
        this.visit_snap_edges((v0, v1) => {
            var d = cn_sub(v0, v1);
            var a = cn_polar(d)[1];
            angles.forEach((angle, index) => {
                var delta = start_delta + angle - a;
                //if (Math.abs(delta - Math.PI) < Math.abs(delta)) delta -= Math.PI;
                //if (Math.abs(delta + Math.PI) < Math.abs(delta)) delta += Math.PI;
                if (Math.abs(delta) < angle_precision * 0.99) {
                    obj.svg = '';
                    angle_precision = Math.abs(delta);
                    current_delta = a - angle;
                    obj._add_snap_line(v0, v1, 1);
                    obj._add_snap_line(cn_rotate(shape[index], center, current_delta), cn_rotate(shape[(index + 1) % shape.length], center, current_delta), 1);
                }
            });
        });

        if (this.svg == '') {
            return false;
        }
        if (display_log) logger.log('current angle:', current_delta - start_delta, current_delta, start_delta);
        this.freedoms = 0;
        this.position = cn_rotate(this.start_position, center, current_delta - start_delta);
        return true;
    }

    //*****************************************************************
    /**
     *
     * @returns {boolean} true if there was a snap.
     */
    snap_point() {
        var obj = this;
        const freedoms = this.freedoms;

        //*** check on existing vertices */
        if (this.freedoms == 2) {
            this.visit_snap_vertices(v => {
                obj.check_point(v);
            });
            if (this.freedoms < 2) {
                obj._add_snap_vertex(obj.position);
                return true;
            }
        }

        //*** check on existing edges */
        this.visit_snap_edges((v0, v1) => {
            obj.check_segment(v0, v1);
        });

        return (this.freedoms < freedoms);
    }

    //*****************************************************************
    /**
     * Adds a snap line to the svg
     * @param {number[]} v0
     * @param {number[]} v1
     * @param {number} enlarge
     */
    _add_snap_line(v0, v1, enlarge = 0) {
        this.svg += cn_snap.draw_snap_line(this.camera, v0, v1, enlarge);
    }

    //*****************************************************************
    /**
     * Draws a snap line
     * @param {cn_camera} camera
     * @param {number[]} v0
     * @param {number[]} v1
     * @param {number} enlarge
     */
    static draw_snap_line(camera, v0, v1, enlarge = 0) {
        var sc0 = camera.world_to_screen(v0);
        if (sc0.length < 2) return '';
        var sc1 = camera.world_to_screen(v1);
        if (sc1.length < 2) return '';
        if (enlarge > 0) {
            var d = cn_sub(sc1, sc0);
            sc1 = cn_add(sc1, d);
            sc0 = cn_sub(sc0, d);
        }
        return '<line class=\'snap_angle\' x1=\'' + sc0[0] + '\' y1=\'' + sc0[1] + '\' x2=\'' + sc1[0] + '\' y2=\'' + sc1[1] + '\' />';
    }

    //*****************************************************************
    /**
     * Adds a snap vertex to the svg
     * @param {number[]} v
     */
    _add_snap_vertex(v) {
        var sc = this.camera.world_to_screen(v);
        this.svg += `<circle class="snap_vertex" cx="${sc[0]}" cy="${sc[1]}" radius="5" />`;
    }
}

/**
 *
 * @param {number[]} dir
 * @param {number[]} p1
 * @param {number[]} p
 * @param {number} e
 * @param {boolean} display_log
 * @returns {number}
 */
function compute_impact_thickness(dir, p1, p, e, display_log = false) {
    var best_x = -1;

    if (display_log)
        logger.log('compute_impact_thickness');

    if (e == 0) return 0;
    const v = cn_sub(p1, p);
    const a = cn_dot(v, v);
    const b = -2 * e * v[0];
    const c = e * e - v[1] * v[1];
    const delta = b * b - 4 * a * c;
    if (delta <= 0) {
        return best_x;
    }
    for (var n0 = 0; n0 < 2; n0++) {
        var nn = [Math.sqrt(delta), 0];
        if (n0) nn[0] *= -1;
        nn[0] = (-b - nn[0]) / (2 * a);
        if (nn[0] > 1) continue;
        nn[1] = Math.sqrt(1 - nn[0] * nn[0]);
        for (var n1 = 0; n1 < 2; n1++) {
            nn[1] = -nn[1];
            var x = cn_dot(nn, dir);
            if (Math.abs(x) < 0.01) continue;
            x = e / x;
            if (x < 0) continue;
            if (best_x < 0 || (x < best_x)) best_x = x;
        }
    }
    return best_x;
}
