import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { Drawing, Project } from '../model/project.model';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BuildingService, BuildingStore, IEditorHandler } from '@enerbim/cnmap-angular-editor-lib';
import { DrawingApiService } from '../services/drawing-api.service';
import { ProjectService } from '../services/project.service';
import { AuthenticationStore } from '../../core/auth/authentication.store';
import { cn_building } from '@enerbim/cnmap-editor';
import { HttpEventType } from '@angular/common/http';
import { EditorHandler } from 'src/app/features/editor/handler/editor-handler';

@Injectable({
  providedIn: 'root',
})
export class DrawingStore {
  private _currentDrawing$ = new BehaviorSubject<Drawing>(undefined);
  private _currentVersion$ = new BehaviorSubject<number>(undefined);

  readonly currentDrawing$ = this._currentDrawing$.pipe(filter(drawing => drawing !== undefined));

  get currentDrawingSnapshot(): Drawing {
    return this._currentDrawing$.getValue();
  }

  constructor(
    private authenticationStore: AuthenticationStore,
    private drawingApiService: DrawingApiService,
    private projectService: ProjectService,
    private buildingService: BuildingService,
  ) {}

  loadDrawing$(project: Project): Observable<unknown> {
    if (!project.drawingDetails) {
      this._currentDrawing$.next(null);
      this._currentVersion$.next(null);
      return this.buildingService.loadCnBuilding(null, project.name);
    }

    this._currentDrawing$.next(project.drawingDetails);
    return forkJoin([
      this.drawingApiService.getContent(project.id, project.drawingDetails.id).pipe(
        switchMap(content =>
          this.buildingService.loadCnBuilding(content, project.name).pipe(
            tap(building => {
              if (building) {
                building.rename_storeys();
                building.update_roofs();
                building.compute_altitudes();
              }
            }),
          ),
        ),
      ),
      this.drawingApiService
        .getVersion(project.id, project.drawingDetails.id)
        .pipe(tap(version => this._currentVersion$.next(version))),
    ]);
  }

  createEmptyDrawing$(project: Project): Observable<Project> {
    return this.drawingApiService.upsert(project.id, this._createEmptyDrawing(project)).pipe(
      tap(drawing => this._currentDrawing$.next(drawing)),
      switchMap(() => this.projectService.reloadCurrentProject$()),
      switchMap(project => this.loadDrawing$(project).pipe(map(() => project))),
    );
  }

  updateContent$(project: Project, drawing: Drawing, building: cn_building): Observable<unknown> {
    return this.drawingApiService
      .updateContent(project.id, drawing.id, this._currentVersion$.getValue(), building.serialize())
      .pipe(
        tap(() => this._currentDrawing$.next(drawing)),
        switchMap(() => this.drawingApiService.getVersion(project.id, drawing.id)),
        tap((version: number) => this._currentVersion$.next(version)),
      );
  }

  private _createEmptyDrawing(project: Project): Drawing {
    const newDrawing = new Drawing();
    newDrawing.name = project.name;
    newDrawing.createdBy = this.authenticationStore.getCurrentUserSnapshot();
    newDrawing.createdDate = new Date().toISOString();
    return newDrawing;
  }
}
