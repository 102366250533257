import { EmitterInterface } from './emitter-interface';

export type TAP_TYPE = 'T' | 'M' | ''; // T for thermostatic, M for Manual;

export class PacEmitterDescription implements EmitterInterface {
  id: string;
  slug: string;
  label: string;
  height: number;
  thickness: number;
  width: number;
  rawPower?: number;
  overridedPower?: number;
  isElectrical = false;
  isWaterTowelDryer = false;
  isFeederTank = false;
  isFake = false;
  isReplaced = false;
  tapType: TAP_TYPE;
  isAddedHeater = false;
}
