import { cn_building, cn_opening, cn_slab, cn_space, cn_storey, cn_wall } from '@enerbim/cnmap-editor';

export type WallAndStorey = [cn_wall, cn_storey];
export type SpaceAndStorey = [cn_space, cn_storey];
export type OpeningAndStoreyAndWall = [cn_opening, cn_storey, cn_wall];
/**
 * Type qui réunit un slab, l'étage au dessus ou null si plafond du dernier étage, et l'index d'étage.
 */
export type SlabAndStoreyAndIndex = [cn_slab, cn_storey, number];
export type StoreyAndIndex = [cn_storey, number];

export function isThermicWall(wall: cn_wall): boolean {
  return wall.spaces[0].indoor != wall.spaces[1].indoor;
}

export function uniqueId(storey: cn_storey, o: any): string {
  return (cn_building as any).create_unique_id(storey, o);
}

export function allThermicWalls(cnBuilding: cn_building): WallAndStorey[] {
  return cnBuilding.storeys
    .map(storey => storey.scene.walls.filter(isThermicWall).map(wall => [wall as cn_wall, storey] as WallAndStorey))
    .flat();
}

export function allInteriorSpaces(cnBuilding: cn_building) {
  return cnBuilding.storeys
    .map(buildingStorey =>
      buildingStorey.scene.spaces.filter(space => space.indoor).map(space => [space, buildingStorey] as SpaceAndStorey),
    )
    .flat();
}

export function allOuterOpenings(cnBuilding: cn_building) {
  return allThermicWalls(cnBuilding)
    .map(([wall, storey]) =>
      (wall.openings as cn_opening[])
        .filter(opening => opening.valid && opening.opening_type.category == 'window')
        .map(opening => [opening, storey, wall] as OpeningAndStoreyAndWall),
    )
    .flat();
}

export function allThermicSlabs(cnBuilding: cn_building) {
  const liste = cnBuilding.storeys
    .map((storey, idx) => [storey, idx] as StoreyAndIndex)
    .concat([[null, cnBuilding.storeys.length]]);
  return liste
    .map(([storey, upperIndex]) =>
      cnBuilding
        .build_slabs(upperIndex - 1, upperIndex)
        .filter(slab => {
          const indoor0 = slab.spaces[0] ? slab.spaces[0].indoor : false;
          const indoor1 = slab.spaces[1] ? slab.spaces[1].indoor : false;
          return indoor0 !== indoor1;
        })
        .map(slab => [slab, storey, upperIndex] as SlabAndStoreyAndIndex),
    )
    .flat();
}
