'use strict';

import { cn_storey_element } from '../model/cn_storey_element';
import { cn_dist, cnx_add, cnx_clone, cnx_dot, cnx_mul, cnx_sub } from '../utils/cn_utilities';
import { cn_3d_impact, cn_3d_ray } from './cn_view_base';

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_mouse_event
 * A class to describe mouse events
 */

export class cn_mouse_event {
    //***********************************************************************************
    /**
     * Constructor
     */
    constructor() {
        //*** Position of the mouse, in screen coordinates (pixels, from top left) */
        this.mouse_screen = [0, 0];

        //*** Position of the mouse, in world coordinates. Only 2 values if camera is 2D */
        this.mouse_world = [0, 0, 0];

        //*** Position of the mouse, in screen coordinates, at the last mouse down event */
        this.mouse_down = [0, 0];

        //*** Camera, class cn_camera */
        this.camera = null;

        //*** true if control key is being pressed */
        this.ctrlKey = false;

        //*** true if shift key is being pressed */
        this.shiftKey = false;

        //*** true if alt key is being pressed */
        this.altKey = false;

        //*** button state */
        this.buttons = 0;

        //*** in case of a wheel event, direction of the wheel */
        this.wheel_up = false;

        //*** in case of a key event, the key */
        this.key = 0;

        //***  useful ??? */
        this.multi_selection = false;

        //*** drag & drop element */
        this.drag_and_drop_element = null;

        //*** drag and drop current owner */
        this.drag_and_drop_owner = null;

        //*** if camera is 3D, the impact under the mouse, or null if no impact. */
        this.impact = new cn_3d_impact([0, 0, 0], [0, 0, 0]);

        //*** if camera is 3D, impact behind selection */
        this.impact_behind = new cn_3d_impact([0, 0, 0], [0, 0, 0]);

        //*** List of impacts */
        this.impacts = [];

        //*** if camera is 3D, the ray from camera to mouse, in world coordinates. */
        this.ray = new cn_3d_ray([0, 0, 0], [0, 0, 0]);

        //*** building */
        this.building = null;

        //*** current scene and storey, only for 2D */
        this.scene = null;
        this.storey = null;

        //*** title to appear. */
        this.title = '';
    }

    /**
     * returns true if point (in screen coordinates) is under mouse
     * @param {number[]} point
     * @param {number} mult_coef
     * @returns
     */
    under_mouse_screen(point, mult_coef = 1) {
        return cn_dist(point, this.mouse_screen) < this.camera.snap_screen_distance * mult_coef;
    }

    /**
     * returns true if point (in world coordinates) is under mouse
     * @param {number[]} point
     * @returns
     */
    under_mouse_world(point) {
        return this.under_mouse_screen(this.camera.world_to_screen(point));
    }

    /**
     * For a 3D camera, moves world point to intersection with a given plane.
     * @param {number[]} point
     * @param {number[] | null} normal
     * @returns {boolean}
     */
    move_to_plane(point, normal = null) {
        if (!this.ray) return false;
        if (normal == null) normal = this.ray.direction;
        const num = cnx_dot(normal, this.ray.direction);
        if (Math.abs(num) < 0.001) return false;
        const lambda = cnx_dot(normal, cnx_sub(point, this.ray.origin)) / num;
        if (lambda < 0) return false;
        this.mouse_world = cnx_add(this.ray.origin, cnx_mul(this.ray.direction, lambda));
        this.camera._workplane = [cnx_clone(point), cnx_clone(normal)];
        return true;
    }

    /**
     * Returns first impact behind storey element.
     * @param {cn_storey_element} storey_element
     * @returns {cn_3d_impact}
     */
    first_impact_behind(storey_element) {
        return this.impacts.find(impact => !impact.storey_element.equals(storey_element));
    }
}

