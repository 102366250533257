'use strict';

import { cn_uuid } from '../utils/cn_utilities';

export class cn_config_export {
    //*******************************************************
    /**
     * Constructor
     */
    constructor(name, pages = [], auto = true) {
        this.ID = cn_uuid(42);
        this.name = name;
        this.auto = auto
        this.pages = pages
    }

    set_pages(pages) {
        this.pages = pages;
    }

    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize() {
        const json = {};
        json.ID = this.ID;
        json.name = this.name;
        json.auto = this.auto;
        json.pages = JSON.stringify(this.pages);
        return json;
    }


    //*******************************************************
    /**
     * unserialize
     * @returns {cn_config_export} cn_config_export object
     */
    static unserialize(json) {
        const result = new cn_config_export(json.name, [], json.auto);
        result.ID = json.ID;
        result.pages = JSON.parse(json.pages);
        return result;
    }
}
