'use strict';

import { cn_element } from '../model/cn_element';

//***********************************************************************************
//***********************************************************************************
//**** Transaction elements
//***********************************************************************************
//***********************************************************************************

//**************************************************************
//***** transaction steps for undo redo management
//**************************************************************
export class cn_transaction_step {
    constructor(item, param_names, callback, date) {
        this.item = item;
        if (item instanceof cn_element) item.set_date(param_names, date);
        this.param_names = param_names;
        this.item_before = {};
        this.callback = callback;
        for (var i in param_names) {
            var name = param_names[i];
            if (Array.isArray(item[name]))
                this.item_before[name] = item[name].slice(0);
            else
                this.item_before[name] = item[name];
        }
    }

    perform(date) {
        var new_item_before = {};
        for (var i in this.param_names) {
            var name = this.param_names[i];
            if (Array.isArray(this.item[name]))
                new_item_before[name] = this.item[name].slice(0);
            else
                new_item_before[name] = this.item[name];
            this.item[name] = this.item_before[name];
        }
        this.item_before = new_item_before;
        if (this.item instanceof cn_element) this.item.set_date(this.param_names, date);
        if (this.callback) this.callback(this.item);
    };
};

//**************************************************************
//***** transaction for undo redo management
//**************************************************************

export class cn_transaction {
    constructor() {
        this.transaction_name = '';
        this.transaction_code = '';
        this.steps = [];
        this.callback = null;
    }
};
