import { EMITTER_CODE_BIM, EMITTER_LABELS } from '../constants/emitter-constantes';
import { EmitterInterface } from './emitter-interface';
import { TAP_TYPE } from './pac-emitter-description';

export class AdditionnalHeater implements EmitterInterface {
  id: string;
  ref: string;
  storeyId: string;
  spaceId: string;
  width: number;
  height: number;
  thickness: number;
  slug: string;
  rawPower: number;
  tapType: TAP_TYPE = 'T';
  objectIdReplaced: string;
  description: string;

  constructor() {}

  static getHeaterName(heater: AdditionnalHeater): string {
    return `${EMITTER_LABELS.get(heater.slug as EMITTER_CODE_BIM)} ${Math.round(heater.width * 100)}x${Math.round(
      heater.thickness * 100,
    )}x${Math.round(heater.height * 100)}`;
  }

  static generateObjectInstanceContract(heater: AdditionnalHeater): ObjectInstanceContract {
    return {
      addedHeater: true,
      storey: { ID: heater.storeyId },
      instance: {
        ID: heater.id,
        space: { ID: heater.spaceId },
        object: {
          source: {
            product_type: heater.slug,
            parameters: {},
          },
          name: this.getHeaterName(heater),
          size: [heater.thickness, heater.width, heater.height],
        },
        parameters: {
          puissance: heater.rawPower,
          type_robinet: heater.tapType,
        },
      },
    };
  }
}

export interface ObjectInstanceContract {
  addedHeater: boolean;
  storey: { ID: string };
  instance: {
    ID: string;
    space: { ID: string };
    object: {
      source: {
        product_type: string;
        parameters: {
          puissance?: number;
          type_robinet?: string;
        };
      };
      name: string;
      size: number[];
    };
    parameters: {
      puissance?: number;
      type_robinet?: string;
    };
  };
}
