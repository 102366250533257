<div class="diagnostics full-dpe" *ngIf="label" [ngClass]="mode">
  <div class="dpe-wrapper" *ngFor="let level of labelRange">
    <div class="segment" [ngClass]="level" [class.focus]="label.toLocaleLowerCase() === level">
      <div class="info-letter" [class.before]="labelBefore.toLocaleLowerCase() === level">{{
        level.toUpperCase()
      }}</div>
    </div>
    <div class="arrow line-arrow" [ngClass]="level" [class.focus]="label.toLocaleLowerCase() === level"></div>
    <div *ngIf="label.toLocaleLowerCase() === level" class="arrow-container">
      <svg
        class="arrow-image"
        [style.top]="distance ? '8px' : '4px'"
        *ngIf="dimensions.width > 0"
        [attr.width]="dimensions.width"
        [attr.height]="dimensions.height"
        [attr.viewBox]="'0 0 ' + dimensions.width + ' ' + dimensions.height"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [innerHTML]="svgContent"></svg>
    </div>
  </div>
</div>
