'use strict';
//***********************************************************************************
//**** Manage file opening / reading on client side
//***********************************************************************************

import * as $ from 'jquery';

export function fh_open_file(filter, file_type, on_ok) {
    $('#_file_opener').remove();

    $(document.body).append('<input type=\'file\' id=\'_file_opener\' accept=\'' + filter + '\' hidden />');
    var $_file_opener = $('#_file_opener');

    $_file_opener.on('change', function (ev) {
        var reader = new FileReader();
        var file_name = ev.originalEvent.target.files[0].name;
        reader.onload = function (event) {
            if (on_ok)
                on_ok(file_name, event.target.result);
        };
        var file = ev.originalEvent.target.files[0];
        reader.readAsText(new Blob([file], { 'type': file_type }));
    });

    $_file_opener[0].click();
}

//***********************************************************************************
//**** Manage file saving
//***********************************************************************************

export function fh_save_file(file_name, file_content, file_type, charset = 'utf-8') {
    var blob = new Blob([file_content], { 'type': file_type });
    var a = document.createElement('a');
    a.download = file_name;
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

//***********************************************************************************
//**** Manage url saving
//***********************************************************************************

export function fh_save_url(file_name, file_url, file_type) {
    var a = document.createElement('a');
    a.download = file_name;
    a.href = file_url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
