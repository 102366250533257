//**************************************************************
//**************************************************************
/*
----- FHTAGN Project ---------------------------------------

Copyright(C) 2015-2018 EnerBIM

------------------------------------------------------------
*/
//**************************************************************
//**************************************************************
//*****  Bounding Box management
//**************************************************************
//**************************************************************

import {fh_add, fh_clone, fh_dot, fh_sub} from "./fh_vector";

export class fh_box
{
	//*********************************************
	//*** constructors
	//*********************************************
	constructor() {
		this.empty= true;
		this.position=[0,0,0];
		this.size=[0,0,0];
	}

	//*********************************************
	//*** clear
	//*********************************************
	clear() {
		this.empty = true;
	}

	//*********************************************
	//*** clone
	//*********************************************
	clone() {
		var box = new fh_box();
		box.empty = this.empty;
		box.position = fh_clone(this.position);
		box.size = fh_clone(this.size);
		return box;
	}

	//*********************************************
	//*** Enlarge by a point
	//*********************************************
	enlarge_point(p) {
		if (this.empty)
		{
			this.position=fh_clone(p);
			this.size = [0,0,0];
			this.empty = false;
			return;
		}
		for (var k = 0; k < 3; k++)
		{
			var tmp = this.position[k] + this.size[k];
			if (p[k] < this.position[k])
			{
				this.position[k] = p[k];
				this.size[k] = tmp - this.position[k];
			}
			else if (p[k] > tmp)
				this.size[k] = p[k] - this.position[k];
		}
	}

	//*********************************************
	//*** Enlarge by a box
	//*********************************************
	enlarge_box(box) {
		if (box.empty) return;
		if (this.empty)
		{
			this.empty = false;
			this.position = fh_clone(box.position);
			this.size = fh_clone(box.size);
			return;
		}

		this.enlarge_point(box.position);
		this.enlarge_point(fh_add(box.position,box.size));
	}

	//*********************************************
	//*** Does box contain a point ?
	//*********************************************
	contains(p)
	{
		for (var k=0; k<3; k++)
		{
			if (p[k] < this.position[k]) return false;
			if (p[k] > this.position[k] + this.size[k]) return false;
		}
		return true;
	}

	//*********************************************
	//*** Does box intersects another box ?
	//*********************************************
	intersects(other_box)
	{
		for (var k=0; k<3; k++)
		{
			if (other_box.position[k] >= this.position[k] + this.size[k]) return false;
			if (other_box.position[k] + other_box.size[k] <= this.position[k]) return false;
		}
		return true;
	}

	//*********************************************
	//*** returns the vertices of the box
	//*********************************************
	get_vertices() {
		var vertices = [];
		for (var k = 0; k < 8; k++)
		{
			var p = fh_clone(this.position);
			if (k & 1) p[0] += this.size[0];
			if (k & 2) p[1] += this.size[1];
			if (k & 4) p[2] += this.size[2];
			vertices.push(p);
		}
		return vertices;
	}

	//*********************************************
	//*** checks status of box / plane : if box is below, returns -1. If box is fully above, returns 1. Otherwise returns 0.
	//*********************************************
	plane_status(plane_position, plane_normal)
	{
		var ppos0 = fh_dot(fh_sub(this.position,plane_position),plane_normal);
		var nb_above = 0;
		var nb_below = 0;
		for (var k = 0; k < 8; k++)
		{
			var ppos = ppos0;
			if (k & 1) ppos += plane_normal[0] * this.size[0];
			if (k & 2) ppos += plane_normal[1] * this.size[1];
			if (k & 4) ppos += plane_normal[2] * this.size[2];
			if (ppos < 0)
				nb_below++;
			else
				nb_above++;
		}
		if (nb_below == 8) return -1;
		if (nb_above == 8) return 1;
		return 0;
	}
}
