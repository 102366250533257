'use strict';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Wall type
//***********************************************************************************
//***********************************************************************************


import { cn_material, CN_MATERIAL_TYPE_HEAVY_STRUCTURE, CN_MATERIAL_TYPE_INSULATED_STRUCTURE, CN_MATERIAL_TYPE_INSULATOR, CN_MATERIAL_TYPE_LIGHT_STRUCTURE } from './cn_material';
import { cn_element_type_visitor } from '..';
import { cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab } from './cn_configuration';
import { cn_element_type_layer_material } from './cn_element_type_layer_material';

var CN_WALL_TYPE_CATEGORY_LIST = ['generic', 'facade', 'inner', 'curtain'];

var CONFIGURATION_INSULATORS = [];

export const WALL_STRUCTURE_LIST = [
    { label: 'Béton', code: 'concrete' },
    { label: 'Parpaing', code: 'block' },
    { label: 'Brique', code: 'brick' },
    { label: 'Pierre', code: 'stone' },
    { label: 'Bois', code: 'wood' },
    { label: 'Acier', code: 'steel' }
];

export class cn_wall_type extends cn_element_type_layer_material {
    constructor() {
        super();
        this.name = '';
        this.thickness = 0.3;
        this.category = 'generic';
        this.free = false;

        //*** Physics: */
        this.user_physics = false;
        this.U = 0;
    }

    //***********************************************************************************
    //**** set category
    //***********************************************************************************
    set_category(category) {
        if (this.category == category) return;
        this.category = category;
        this.free = false;
        if (this.category == 'generic') {
            this.thickness = 0.3;
            this.layers = [new cn_material('Inconnu', 'unknown', 0.3)];
            return;
        }
        if (this.category == 'facade') {
            this.thickness = 0.32;
            this.layers = [];
            this.layers.push(cn_material.material_by_code('cement', 0.01));
            this.layers.push(cn_material.material_by_code('concrete', 0.2));
            this.layers.push(cn_material.material_by_code('insulator', 0.1));
            this.layers.push(cn_material.material_by_code('gypsum', 0.01));
            return;
        }
        if (this.category == 'inner') {
            this.thickness = 0.08;
            this.layers = [];
            this.layers.push(cn_material.material_by_code('gypsum', 0.01));
            this.layers.push(cn_material.material_by_code('air', 0.06));
            this.layers.push(cn_material.material_by_code('gypsum', 0.01));
            return;
        }
    }

    //***********************************************************************************
    /**
     * category label
     * @returns {string} displayable category
     */
    get_category_label() {
        if (this.free)
            return 'Séparation fictive';
        if (this.category == 'facade')
            return 'Façade';
        if (this.category == 'inner')
            return 'Cloison';
        return 'Mur';
    }

    //***********************************************************************************
    get_generic_label() {
        return 'Type de ' + this.get_category_label().toLowerCase();
    }

    //***********************************************************************************
    /**
     * Structure label
     * @returns {string} displayable structure
     */
    get_structure_label() {
        if (this.free)
            return 'Néant';
        if (this.category == 'inner')
            return 'Plâtre';
        if (this.category == 'facade') {
            for (var i in this.layers) {
                if (this.layers[i].type === CN_MATERIAL_TYPE_HEAVY_STRUCTURE)
                    return this.layers[i].name;
            }
            return 'Néant';
        }
        return 'Générique';
    }

    //***********************************************************************************
    /**
     * Structure label
     * @returns {string} displayable structure
     */
    get_insulation_label() {
        if (this.free)
            return '';

        var label = '';
        let structure = false;
        for (let i in this.layers) {
            var new_label = '';
            if (this.layers[i].type === CN_MATERIAL_TYPE_INSULATED_STRUCTURE)
                new_label = 'Intégrée';
            if ([CN_MATERIAL_TYPE_HEAVY_STRUCTURE, CN_MATERIAL_TYPE_LIGHT_STRUCTURE].includes(this.layers[i].type))
                structure = true;
            if (this.layers[i].type === CN_MATERIAL_TYPE_INSULATOR)
                new_label = (structure) ? 'Intérieure' : 'Extérieure';

            if (new_label != '') {
                if (label != '') label += ' + ';
                label += new_label;
            }
        }

        return label;
    }

    /**
     * Computes and returns U value
     * @returns {number}
     */
    get_U() {
        if (this.free) return 0;
        if (this.user_physics) return this.U;
        if (this.category == 'generic') return 0;
        var U = 0;
        this.layers.forEach(l => U += l.thickness / l.conductivity);
        return 1 / U;
    }

    //***********************************************************************************
    //**** default
    //***********************************************************************************
    static default_outer_wall() {
        return new cn_wall_type();
    }

    static default_inner_wall() {
        var wt = new cn_wall_type();
        wt.thickness = 0.08;
        wt.category = 'generic';
        wt.layers = [new cn_material('Inconnu', 'unknown', 0.08)];
        return wt;
    }

    static default_free() {
        var wt = new cn_wall_type();
        wt.free = true;
        wt.thickness = 0;
        wt.category = 'inner';
        return wt;
    }

    //***********************************************************************************
    //**** Clone
    //***********************************************************************************
    clone() {
        var c = new cn_wall_type();
        c.name = this.name;
        c.thickness = this.thickness;
        c.category = this.category;
        c.free = this.free;
        c.layers = [];
        for (var i in this.layers)
            c.layers.push(this.layers[i].clone());

        c.user_physics = this.user_physics;
        c.U = this.U;
        return c;
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ['name', 'thickness', 'category', 'free', 'layers', 'user_physics', 'U'];
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.class_name = 'cn_wall_type';
        json.name = this.name;
        json.ID = this.ID;
        json.thickness = this.thickness;
        json.category = this.category;
        json.layers = [];
        for (var i in this.layers)
            json.layers.push(this.layers[i].serialize());
        json.free = this.free;
        json.user_physics = this.user_physics;
        json.U = this.U;
        return json;
    }

    static unserialize(json) {
        if (typeof (json.ID) != 'string') return false;
        if (json.class_name != 'cn_wall_type') return false;
        if (typeof (json.thickness) != 'number') return false;
        var wt = new cn_wall_type();
        wt.ID = json.ID;
        wt.thickness = json.thickness;
        if (typeof (json.name) == 'string') wt.name = json.name;

        if (typeof (json.category) == 'string' && CN_WALL_TYPE_CATEGORY_LIST.indexOf(json.category) >= 0)
            wt.category = json.category;

        wt.layers = [];
        if (typeof (json.layers) == 'object' && json.layers.length > 0) {
            var thickness = 0;
            for (var i in json.layers) {
                var layer = cn_material.unserialize(json.layers[i]);
                if (layer == null) continue;
                wt.layers.push(layer);
                thickness += layer.thickness;
            }
            if (wt.layers.length > 0)
                wt.thickness = thickness;
        }
        if (wt.layers.length == 0)
            wt.layers = [new cn_material('Inconnu', 'unknown', wt.thickness)];

        if (typeof (json.free) == 'boolean')
            wt.free = json.free;

        if (typeof (json.user_physics) == 'boolean')
            wt.user_physics = json.user_physics;

        if (typeof (json.U) == 'number')
            wt.U = json.U;

        if (wt.free)
            wt.thickness = 0;

        return wt;
    }

    //***********************************************************************************
    //**** return label
    //***********************************************************************************
    get_label() {
        if (this.name != '') return this.name;

        if (this.free) {
            return 'Séparation fictive';
        }

        var element_type_name = '';
        if (this.category == 'facade')
            element_type_name += 'Mur ' + this.get_structure_label().toLowerCase();
        else if (this.category == 'inner')
            element_type_name += 'Cloison';
        else
            element_type_name += 'Générique';
        element_type_name += ' ' + (this.thickness * 100).toFixed(0) + 'cm';
        return element_type_name;
    }

    //***********************************************************************************
    //**** Returns description
    //***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        var description = [];

        description.push({ label: 'Catégorie', value: this.get_category_label() });
        if (this.free) return description;

        var th = { label: 'Epaisseur' };
        th.value = this.thickness * 100;
        th.decimals = 1;
        th.unit = 'cm';
        description.push(th);

        if (this.category == 'generic') {
            if (this.user_physics)
                description.push({ label: 'U', value: this.get_U(), decimals: 3, unit: 'W/m²/K' });
            return description;
        }
        description.push({ label: 'U', value: this.get_U(), decimals: 3, unit: 'W/m²/K' });

        var layers = { label: 'Matériaux' };
        description.push(layers);
        layers.value = [];

        for (var i in this.layers) {
            layers.value.push(this.layers[i].get_label());
        }

        return description;
    }

    //***********************************************************************************
    //**** draw icon
    //***********************************************************************************
    draw_svg_icon(width, height, fit_box = false) {
        var html = '';

        if (this.free) {
            html += '<line x1=\'' + (width / 2) + '\' y1=\'0\' x2=\'' + (width / 2) + '\' y2=\'' + height + '\' style=\'stroke:black; stroke-width: 2px; stroke-dasharray: 5,5;\' />';
            return html;
        }

        var sz = [width, height];
        var p0 = [0, 0];
        var p1 = [width, height];
        var scale = (fit_box && this.thickness > 0) ? width / this.thickness : width;
        p0[0] = (fit_box) ? 0 : sz[0] * (0.5 - this.thickness * 0.5);
        for (var i = 0; i < this.layers.length; i++) {
            p1[0] = p0[0];
            var vertical_line = '<line x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' style=\'stroke:black\' />';
            p1[0] += scale * this.layers[i].thickness;
            html += this.layers[i].draw(p0, p1[0] - p0[0], p1[1] - p0[1], scale);
            html += vertical_line;
            p0[0] = p1[0];
            if (i == this.layers.length - 1)
                html += '<line x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' style=\'stroke:black\' />';
        }
        return html;
    }

    //***********************************************************************************
    //**** Comparison
    //***********************************************************************************
    is_equal_to(other) {
        if (this.name != other.name) return false;
        if (this.category != other.category) return false;
        if (this.layers.length != other.layers.length) return false;
        if (this.user_physics != other.user_physics) return false;
        if (this.user_physics && this.U != other.U) return false;

        for (var i = 0; i < this.layers.length; i++) {
            if (!this.layers[i].is_equal_to(other.layers[i])) return false;
        }
        return true;
    }

    //***********************************************************************************
    //**** Get configuration
    //***********************************************************************************
    static configuration() {
        var configuration = new cn_configuration();

        var param_group = new cn_configuration_param_group('Thermique', 'thermics', true);
        configuration.add_param_group(param_group);
        param_group.add_param(new cn_configuration_param('U (sans Rs)', 'U', 0, 10, 'W/m²/K', 3, 0.1));

        //***********************************************
        //*** Generic configuration
        var generic = new cn_configuration_tab('Générique', 'generic');
        configuration.add_tab(generic);

        var generic_list = new cn_configuration_line('Mur générique');
        generic.add_line(generic_list);

        generic_list.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));

        generic_list.add_choice(new cn_configuration_choice());

        //***********************************************
        //*** Wall configuration
        var walls = new cn_configuration_tab('Murs', 'facade');
        configuration.add_tab(walls);

        var wall_structure = new cn_configuration_line('Structure', 'structure');
        walls.add_line(wall_structure);
        wall_structure.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));
        WALL_STRUCTURE_LIST.forEach(ws => {
            wall_structure.add_choice(new cn_configuration_choice(ws.label, ws.code));
        });

        configuration['insulator_labels'] = ['Minéral', 'Naturel', 'Synthétique', 'Lame d\'air'];
        configuration['insulator_codes'] = ['insulator', 'wood_fiber', 'styrofoam', 'air_layer'];

        var wall_insulation = new cn_configuration_line('Isolant 1', 'insulator1');
        walls.add_line(wall_insulation);
        wall_insulation.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));
        wall_insulation.add_param(cn_configuration_param.choice_list('Type', 'insulator_type', configuration['insulator_labels']));
        wall_insulation.add_choice(new cn_configuration_choice('Aucun', 'none'));
        wall_insulation.add_choice(new cn_configuration_choice('Extérieur', 'outer'));
        wall_insulation.add_choice(new cn_configuration_choice('Intérieur', 'inner'));
        wall_insulation.add_choice(new cn_configuration_choice('Intégré', 'integrated'));

        wall_insulation = new cn_configuration_line('Isolant 2', 'insulator2');
        walls.add_line(wall_insulation);
        wall_insulation.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));
        wall_insulation.add_param(cn_configuration_param.choice_list('Type', 'insulator_type', configuration['insulator_labels']));
        wall_insulation.add_choice(new cn_configuration_choice('Aucun', 'none'));
        wall_insulation.add_choice(new cn_configuration_choice('Extérieur', 'outer'));
        wall_insulation.add_choice(new cn_configuration_choice('Intérieur', 'inner'));

        var wall_outer_facing = new cn_configuration_line('Parement extérieur', 'outer_facing');
        walls.add_line(wall_outer_facing);
        wall_outer_facing.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));
        wall_outer_facing.add_choice(new cn_configuration_choice('Aucun', 'none'));
        wall_outer_facing.add_choice(new cn_configuration_choice('Enduit', 'cement'));
        wall_outer_facing.add_choice(new cn_configuration_choice('Bardage', 'tiles'));
        wall_outer_facing.add_choice(new cn_configuration_choice('Bardage ventilé', 'ventilated_tiles'));

        var wall_inner_facing = new cn_configuration_line('Parement intérieur', 'inner_facing');
        walls.add_line(wall_inner_facing);
        wall_inner_facing.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));
        wall_inner_facing.add_choice(new cn_configuration_choice('Aucun', 'none'));
        wall_inner_facing.add_choice(new cn_configuration_choice('Plâtre', 'gypsum'));

        //***********************************************
        //*** Inner configuration
        var inner_walls = new cn_configuration_tab('Cloisons', 'inner');
        configuration.add_tab(inner_walls);

        var inner_walls_line = new cn_configuration_line();
        inner_walls.add_line(inner_walls_line);
        inner_walls_line.add_param(new cn_configuration_param('Epaisseur', 'thickness', 1, 200, 'cm', 1, 0.5));
        inner_walls_line.add_choice(new cn_configuration_choice('Cloison plâtre', 'gypsum'));
        inner_walls_line.add_choice(new cn_configuration_choice('Cloison plâtre isolée', 'insulatedgypsum'));
        inner_walls_line.add_choice(new cn_configuration_choice('Séparation fictive', 'free'));

        //***********************************************
        //*** Default values
        var wt = new cn_wall_type();
        wt.set_category('facade');
        wt.fill_configuration(configuration);
        wt.set_category('generic');
        wt.fill_configuration(configuration);
        wt.set_category('inner');
        wt.fill_configuration(configuration);

        return configuration
    }

    //***********************************************************************************
    //**** Fill configuration
    //***********************************************************************************
    fill_configuration(config = null) {
        const configuration = (config) ? config : cn_wall_type.configuration();

        var param_group = configuration.get_param_group('thermics');
        param_group.checked = this.user_physics;
        param_group.set_param('U', this.get_U());
        param_group.visible = !this.free;

        //*** default to generic
        configuration.selection = 0;

        //*** find proper tab
        const tab_index = configuration.get_tab_index(this.category);
        if (tab_index < 0) return false;
        var configuration_tab = configuration.tabs[tab_index];

        if (this.category == 'generic') {
            configuration.tabs[0].lines[0].get_param('thickness').value = this.thickness * 100;
            return configuration;
        }

        if (this.category == 'facade') {
            var structure = 'concrete';
            var structure_thickness = 0.2;
            var insulator = 'none';
            var facingext = 'none';
            var facingint = 'none';

            var layers = this.layers;
            if (layers.length == 0) return false;

            //find structure layer
            var structure_layer_found = false;

            var structure_line = configuration_tab.get_line('structure');

            var insulator1_line = configuration_tab.get_line('insulator1');
            insulator1_line.set_selection('none');
            insulator1_line.set_param('thickness', 10, false);
            insulator1_line.get_param('insulator_type').visible = false;

            var insulator2_line = configuration_tab.get_line('insulator2');
            insulator2_line.set_selection('none');
            insulator2_line.set_param('thickness', 10, false);
            insulator2_line.get_param('insulator_type').visible = false;

            var first_insulator = null;

            var outer_facing_line = configuration_tab.get_line('outer_facing');
            outer_facing_line.set_selection('none');
            outer_facing_line.set_param('thickness', 1, false);

            var inner_facing_line = configuration_tab.get_line('inner_facing');
            inner_facing_line.set_selection('none');
            inner_facing_line.set_param('thickness', 1, false);

            for (var i = 0; i < layers.length; i++) {
                var insulator_line = (first_insulator == null) ? insulator1_line : insulator2_line;
                var structure_choice = structure_line.get_choice_index(layers[i].code);
                if (structure_choice >= 0) {
                    structure_line.selection = structure_choice;
                    structure_line.set_param('thickness', layers[i].thickness * 100);
                    structure_layer_found = true;
                } else if (layers[i].code == 'insulated_wood') {
                    structure_line.set_selection('wood');
                    structure_line.set_param('thickness', layers[i].thickness * 100);

                    if (first_insulator) {
                        insulator2_line.set_selection('outer');
                        insulator2_line.set_param('thickness', first_insulator.thickness * 100);
                        insulator2_line.set_param('insulator_type', configuration['insulator_codes'].indexOf(first_insulator.code), true);
                        insulator1_line.set_selection('integrated');
                        insulator1_line.get_param('insulator_type').visible = false;
                    } else
                        insulator_line.set_selection('integrated');
                    first_insulator = layers[i];
                    structure_layer_found = true;
                } else if (layers[i].code == 'insulated_steel') {
                    structure_line.set_selection('steel');
                    structure_line.set_param('thickness', layers[i].thickness * 100);

                    if (first_insulator) {
                        insulator2_line.set_selection('outer');
                        insulator2_line.set_param('thickness', first_insulator.thickness * 100);
                        insulator2_line.set_param('insulator_type', configuration['insulator_codes'].indexOf(first_insulator.code), true);
                        insulator1_line.set_selection('integrated');
                        insulator1_line.get_param('insulator_type').visible = false;
                    } else
                        insulator_line.set_selection('integrated');
                    first_insulator = layers[i];
                    structure_layer_found = true;
                } else if (layers[i].type == CN_MATERIAL_TYPE_INSULATOR) {
                    if (structure_layer_found)
                        insulator_line.set_selection('inner');
                    else
                        insulator_line.set_selection('outer');
                    insulator_line.set_param('thickness', layers[i].thickness * 100);
                    insulator_line.set_param('insulator_type', configuration['insulator_codes'].indexOf(layers[i].code), true);
                    first_insulator = layers[i];
                } else if (layers[i].code == 'cement' || layers[i].code == 'tiles') {
                    if (i == 0) {
                        outer_facing_line.set_selection(layers[i].code);
                        outer_facing_line.set_param('thickness', layers[i].thickness * 100);
                    }
                } else if (layers[i].code == 'air') {
                    if (i == 1 && layers[0].code == 'tiles') {
                        outer_facing_line.set_selection('ventilated_tiles');
                        outer_facing_line.set_param('thickness', (layers[0].thickness + layers[i].thickness) * 100);
                    }
                } else if (layers[i].code == 'gypsum') {
                    if (i == layers.length - 1) {
                        inner_facing_line.set_selection(layers[i].code);
                        inner_facing_line.set_param('thickness', layers[i].thickness * 100);
                    }
                }
            }
            if (!structure_layer_found) return false;
            configuration.selection = tab_index;

            insulator1_line.get_choice('integrated').visible = (structure_line.get_selection() == 'wood' || structure_line.get_selection() == 'steel');

            return configuration;
        }

        if (this.category == 'inner') {
            configuration.set_selection('inner');
            var configuration_tab = configuration.get_tab('inner');
            var configuration_line = configuration_tab.lines[0];
            var layers = this.layers;
            if (this.free)
                configuration_line.set_selection('free');
            else if (layers.length == 3 && layers[1].code == 'insulator')
                configuration_line.set_selection('insulatedgypsum');
            else
                configuration_line.set_selection('gypsum');
            configuration_line.set_param('thickness', this.thickness * 100, layers.length > 0);
            return configuration;
        }

        return null;
    }

    //***********************************************************************************
    //**** Load configuration
    //***********************************************************************************
    load_configuration(configuration, actual_change = false) {
        var configuration_tab = configuration.tabs[configuration.selection];
        this.category = configuration_tab.name;

        this.free = false;
        this.layers = [];

        var param_group = configuration.get_param_group('thermics');
        param_group.visible = true;
        this.user_physics = param_group.checked;
        if (this.user_physics) {
            this.U = param_group.get_param('U').value;
        }

        if (this.category == 'generic') {
            var configuration_line = configuration_tab.lines[0];
            this.thickness = 0.01 * configuration_line.get_param('thickness').value;
            this.layers = [new cn_material('Inconnu', 'unknown', this.thickness)];
        } else if (this.category == 'facade') {
            var structure_line = configuration_tab.get_line('structure');
            var structure = structure_line.get_selection();
            var structure_thickness = 0.01 * structure_line.get_param('thickness').value;

            var insulator1_line = configuration_tab.get_line('insulator1');
            var insulator1 = insulator1_line.get_selection();
            var insulator1_thickness = 0.01 * insulator1_line.get_param('thickness').value;
            var insulator1_code = configuration['insulator_codes'][insulator1_line.get_param('insulator_type').value];

            var insulator2_line = configuration_tab.get_line('insulator2');
            var insulator2 = insulator2_line.get_selection();
            var insulator2_thickness = 0.01 * insulator2_line.get_param('thickness').value;
            var insulator2_code = configuration['insulator_codes'][insulator2_line.get_param('insulator_type').value];

            var outer_facing_line = configuration_tab.get_line('outer_facing');
            var outer_facing = outer_facing_line.get_selection();
            var outer_facing_thickness = 0.01 * outer_facing_line.get_param('thickness').value;

            var inner_facing_line = configuration_tab.get_line('inner_facing');
            var inner_facing = inner_facing_line.get_selection();
            var inner_facing_thickness = 0.01 * inner_facing_line.get_param('thickness').value;

            if (outer_facing != 'none') {
                if (outer_facing == 'ventilated_tiles') {
                    this.layers.push(cn_material.material_by_code('tiles', 0.5 * outer_facing_thickness));
                    this.layers.push(cn_material.material_by_code('air', 0.5 * outer_facing_thickness));
                } else
                    this.layers.push(cn_material.material_by_code(outer_facing, outer_facing_thickness));
            }
            if (insulator1 == 'outer')
                this.layers.push(cn_material.material_by_code(insulator1_code, insulator1_thickness));

            if (insulator2 == 'outer')
                this.layers.push(cn_material.material_by_code(insulator2_code, insulator2_thickness));

            if (insulator1 == 'integrated' && structure == 'wood')
                this.layers.push(cn_material.material_by_code('insulated_wood', structure_thickness));
            else if (insulator1 == 'integrated' && structure == 'steel')
                this.layers.push(cn_material.material_by_code('insulated_steel', structure_thickness));
            else
                this.layers.push(cn_material.material_by_code(structure, structure_thickness));

            if (insulator1 == 'inner')
                this.layers.push(cn_material.material_by_code(insulator1_code, insulator1_thickness));

            if (insulator2 == 'inner')
                this.layers.push(cn_material.material_by_code(insulator2_code, insulator2_thickness));

            if (inner_facing != 'none')
                this.layers.push(cn_material.material_by_code(inner_facing, inner_facing_thickness));

            this.thickness = 0;
            for (var i in this.layers)
                this.thickness += this.layers[i].thickness;
        } else if (this.category == 'inner') {
            var configuration_line = configuration_tab.lines[0];
            var thickness = configuration_line.get_param('thickness').value * 0.01;
            var inner_wall = configuration_line.get_selection();

            if (inner_wall != 'free') {
                this.free = false;
                this.layers.push(cn_material.material_by_code('gypsum', 0.01));

                if (inner_wall == 'gypsum')
                    this.layers.push(cn_material.material_by_code('air', thickness - 0.02));
                else
                    this.layers.push(cn_material.material_by_code('insulator', thickness - 0.02));

                this.layers.push(cn_material.material_by_code('gypsum', 0.01));

                this.thickness = thickness;
            } else {
                this.free = true;
                this.thickness = 0;
            }
        }

        if (!this.user_physics) {
            param_group = configuration.get_param_group('thermics');
            param_group.checked = this.user_physics;
            param_group.set_param('U', this.get_U());
        }

        return true;
    }

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_wall_type(this);
    }

    /**
     * returns true if wall geometry hasn't changed since date
     * @param {number} date
     * @returns {boolean}
     */
    up_to_date_thickness(date) {
        if (this.get_date() <= date) return true;
        if (this.get_date('layers') > date) return false;
        return !this.layers.some(l => this.get_date('thickness') > date);
    }
}

