import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefixedNumber',
})
export class PrefixedNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value >= 0 ? `+ ${Math.abs(value)}` : `- ${Math.abs(value)}`;
  }
}
