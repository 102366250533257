//***********************************************************************************
//***********************************************************************************
//**** fh_view_elevation : 2D elevation
//***********************************************************************************
//***********************************************************************************

import {
    Box3,
    DoubleSide,
    ImageUtils,
    LineBasicMaterial,
    Matrix3,
    MeshBasicMaterial,
    RepeatWrapping,
    Vector3
} from 'three';
import {fh_cart, fh_polar} from "./fh_vector";
import {fh_view_ortho} from "./fh_view_ortho";
import {fh_view_elevation_map} from "./fh_view_elevation_map";
// @ts-ignore
import cut_plane from '../images/cut_plane.png';


//***********************************************************************************
//**** Eleavation class
//***********************************************************************************

export class fh_view_elevation extends fh_view_ortho {
    //*****************************************************
    //*** Constructor
    constructor(div_id, map_div_id, scene) {

        super(div_id, scene, true);

        this._camera.position.x = 0;
        this._camera.position.y = -10;
        this._camera.position.z = 0;
        this._camera.up.set(0, 0, 1);
        this._camera.lookAt(new Vector3(0, 0, 0));
        this._elevation_angle = 0; //*** North */
        this._view = "3d";

        this._view_2d = new fh_view_elevation_map(map_div_id, scene, this);
        this.secondary_views.push(this._view_2d);

        var obj = this;
        this._view_2d.on_change = function () {
            obj._update_elevation_camera();
            obj.refresh_rendering();
        }

        var map = new ImageUtils.loadTexture(cut_plane);

        this._cut_material = new MeshBasicMaterial();
        this._cut_material.map = map;
        this._cut_material.map.wrapS = RepeatWrapping;
        this._cut_material.map.wrapT = RepeatWrapping;
        this._cut_material.map.matrixAutoUpdate = false;
        this._cut_material.side = DoubleSide;
        this._cut_material.color.r = 0.7;
        this._cut_material.color.g = 0.7;
        this._cut_material.color.b = 0.7;
        this._cut_material.transparent = false;
        this._cut_material.opacity = 1;
        this._cut_material.depthTest = false;
        this._cut_material.depthWrite = false;

        this._cut_line_material = new LineBasicMaterial({color: 0x000000});
        this._cut_line_material.depthTest = false;
        this._cut_line_material.depthWrite = false;
        this._perspective = false;
        this.update_scene();
    }

    //*****************************************************
    //**** animation mode
    //*****************************************************
    set_animation_mode(v) {
        super.set_animation_mode(v);
        this._view_2d.set_animation_mode(v);
    }

    //*****************************************************
    //**** mouse event management. Default is true;
    //*****************************************************
    set_mouse_management(v) {
        super.set_mouse_management(v);
        this._view_2d.set_mouse_management(v);
    }

    //*****************************************************
    //**** Elevation angle
    //*****************************************************
    set_elevation_angle(v) {
        this._elevation_angle = v;
        this._view_2d.set_elevation_angle(this._elevation_angle);
        this._update_elevation_camera();
        this.call("view_change");
        this.refresh_rendering();
    }

    get_elevation_angle() {
        return this._view_2d.get_elevation_angle();
    }

    //*****************************************************
    //**** Elevation angle
    //*****************************************************
    set_elevation_position(index, v) {
        this._view_2d.set_elevation_position(index,this._elevation_angle);
        this._update_elevation_camera();
    }

    get_elevation_position(index) {
        return this._view_2d.get_elevation_position(index);
    }

    //*****************************************************
    //**** Serialisation
    //*****************************************************
    serialize() {
        var json = {};
        json.type = "elevation";
        json.position = [this._camera.position.x, this._camera.position.y, this._camera.position.z];
        json.elevation_angle = this._view_2d.get_elevation_angle();
        json.elevation_position_near = this._view_2d.get_elevation_position(0);
        json.elevation_position_far = this._view_2d.get_elevation_position(1);
        json.scale = this._camera.scene_scale;
        return json;
    }

    unserialize(json) {
        if (json.type != "elevation") return;

        this._view_2d.set_elevation_angle(json.elevation_angle);
        this._view_2d.set_elevation_position(0, json.elevation_position_near);
        this._view_2d.set_elevation_position(1, json.elevation_position_far);
        //this._update_elevation_camera();

        this.start_animation();

        this._end_position.set(json.position[0], json.position[1], json.position[2]);
        var dir = this._view_2d.get_elevation_dir();
        var l = this._view_2d.get_elevation_position(0) - dir.dot(this._end_position);
        this._end_position.addScaledVector(dir, l);
        this._end_target.copy(this._end_position);
        this._end_target.add(dir);
        this._end_scale = json.scale;
        this.call("elevation_angle_change");
    }

    //*****************************************************
    //**** Export map
    //*****************************************************
    map_to_image_url(w, h) {

        return this._view_2d.to_image_url(w, h);
    }

    //*****************************************************
    //**** Update camera
    //*****************************************************
    _update_elevation_camera() {
        var dir = new Vector3();
        this._camera.getWorldDirection(dir);
        var dirpol = fh_polar([dir.x, dir.y, dir.z]);
        var old_elevation_angle = dirpol[2] * 180 / Math.PI - 90;

        dir = this._view_2d.get_elevation_dir();
        var pol = fh_polar([this._camera.position.x - this._scene._scene_center.x, this._camera.position.y - this._scene._scene_center.y, this._camera.position.z - this._scene._scene_center.z]);
        pol[2] += (this.get_elevation_angle() - old_elevation_angle) * Math.PI / 180;
        var cart = fh_cart(pol);
        this._camera.position.x = this._scene._scene_center.x + cart[0];
        this._camera.position.y = this._scene._scene_center.y + cart[1];
        this._camera.position.z = this._scene._scene_center.z + cart[2];
        var dotprod = this._view_2d.get_elevation_position(0) - dir.dot(this._camera.position);
        this._camera.position.x += dotprod * dir.x;
        this._camera.position.y += dotprod * dir.y;
        this._camera.position.z += dotprod * dir.z;

        this._camera.near = 0;
        this._camera.far = this._view_2d.get_elevation_position(1) - this._view_2d.get_elevation_position(0);

        var target = this._camera.position.clone();
        target.add(dir);
        this._camera.lookAt(target);
        this.update_camera();

        this._render_cut_angle = -1;
        this._render_cut_position = -1;
        this._cut_scene = null;
    }

    //*****************************************************
    //**** Callbacks for navigation
    //*****************************************************
    set_current_storey(v) {
        this._view_2d.set_current_storey(v);
        return true;
    }

    //*****************************************************
    //*** animate camera from a given position
    animate_camera(start_camera) {
        if (start_camera == null) return;
        if (start_camera.constructor.name != this._camera.constructor.name) return;

        this._camera.position.copy(start_camera.position);
        var target = start_camera.position.clone();
        var dir = new Vector3();
        start_camera.getWorldDirection(dir);
        target.add(dir);
        this._camera.lookAt(target);
        if (typeof (start_camera.scene_scale) == 'number')
            this._camera.scene_scale = start_camera.scene_scale;

        this.start_animation();
        this.refresh_rendering();
    };

    //*****************************************************
    //*** start animation
    start_animation() {
        var started = this._animation;
        this._animation = true;
        this._animation_start = new Date();
        this._animation_duration = 1000;

        this._start_position.copy(this._camera.position);
        this._camera.getWorldDirection(this._start_target);
        this._start_target.add(this._start_position);

        this._start_scale = this._camera.scene_scale;
    }

    //*****************************************************
    //**** Called when scene changes
    update_scene() {
        super.update_scene();
        this._view_2d.set_elevation_angle(0);
        var dir = this._view_2d.get_elevation_dir();
        var plane = dir.dot(this._scene._scene_center) - this._scene._scene_radius;
        this._view_2d.set_elevation_position(0, plane);
        this._view_2d.set_elevation_position(1, plane + 2 * this._scene._scene_radius);
    }

    //*****************************************************
    //*** camera reset
    reset_camera() {
        this._view_2d.reset_camera();

        this.start_animation();
        var dir = this._view_2d.get_elevation_dir();
        this._end_position.copy(this._scene._scene_center);
        var l = dir.dot(this._end_position) - this._view_2d.get_elevation_position(0);
        this._end_position.addScaledVector(dir, -l);
        this._end_target.copy(this._end_position);
        this._end_target.add(dir);
        this._end_scale = this._scene._scene_radius * 2;

        this._camera.near = 0;
        this._camera.far = this._view_2d.get_elevation_position(1) - this._view_2d.get_elevation_position(0);

        this.refresh_rendering();
    }

    //***********************************************************************************
    //**** zoom on selection
    zoom_on_selection() {
        if (this._scene._selection.length != 1) return;

        var bb = new Box3().setFromObject(this._scene._selection[0]);
        if (!this._perspective) bb.min.z = bb.max.z;
        var center = bb.min.clone();
        center.add(bb.max);
        center.multiplyScalar(0.5);

        var size = bb.min.distanceTo(bb.max);
        if (size < 1) size = 1;

        this.start_animation();

        var dir = new Vector3();
        this._camera.getWorldDirection(dir);
        dir.multiplyScalar(-2 * this._scene._scene_radius)

        this._end_position.copy(center);
        this._end_position.add(dir);
        this._end_target.copy(center);
        this._end_target.sub(dir);
        this._end_scale = size * 2;

        this.refresh_rendering();
    }


    //***********************************************************************************
    //**** Rendering
    //***********************************************************************************
    render_geometry() {
        //*** Render base geometry (if cu depth is > 0) */
        var p0 = this._view_2d.get_elevation_position(0);
        var p1 = this._view_2d.get_elevation_position(1);

        //*** Iniitialize camera */
        this._camera.near = 0;
        this._camera.far = p1 - p0;
        this._camera.updateProjectionMatrix();

        if (p0 < p1)
            super.render_geometry();

        //*** Build cut plane geometry */
        if (this._cut_scene == null || this._render_cut_angle != this._view_2d.get_elevation_angle() || this._render_cut_position != p0) {
            this._render_cut_angle = this._view_2d.get_elevation_angle();
            this._render_cut_position = p0;
            this._cut_scene = this._scene.build_plane_intersection(this._view_2d.get_elevation_dir(), p0, this._cut_material, this._cut_line_material);
        }

        //*** Update cut texture scale */
        this._cut_material.map.matrix = new Matrix3();
        this._cut_material.map.matrix.identity();
        var x = 2000 / this._camera.scene_scale * 0.1;
        this._cut_material.map.matrix.set(x, 0, 0, 0, x, 0, 0, 0, 1);

        //*** Set camera to match cut geometry */
        this._camera.near = -0.1;
        this._camera.far = 0.2;
        this._camera.updateProjectionMatrix();

        //*** Draw cut geometry */
        this._renderer.render(this._cut_scene, this._camera);

        //*** Restore camera */
        this._camera.near = 0;
        this._camera.far = p1 - p0;
        this._camera.updateProjectionMatrix();
    }
}
