<!-- On doit déclarer le ng-content car on ne peut pas le déclarer plusieurs fois ;-) -->
<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="enabled">
  <div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-12">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!enabled">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
