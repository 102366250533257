import { Injectable } from '@angular/core';
import { Perimeter, ROLE_ADMIN, User } from '../model/user.model';
import { AuthenticationStore } from '../../core/auth/authentication.store';
import { CompanyRights } from '../model/company.model';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private authenticationStore: AuthenticationStore) {}

  isUserHasRight(requestedRight: CompanyRights | typeof ROLE_ADMIN, companyId?: string) {
    return (
      (requestedRight === ROLE_ADMIN && this.isSuperAdmin()) ||
      (requestedRight !== ROLE_ADMIN && this.isAuthorizedByRole(requestedRight as CompanyRights, companyId))
    );
  }

  isAuthorizedByRole(requiredRight: CompanyRights, companyId?: string): boolean {
    const user = this.authenticationStore.getCurrentUserSnapshot();
    if (!user) {
      return false;
    }

    if (this.isSuperAdmin()) {
      return true;
    }

    let companiesToCheckIn = this.authenticationStore.currentUserCompaniesSnapshot.filter(
      company => company.type !== 'INDIVIDUAL',
    );
    if (companyId) {
      const company = companiesToCheckIn.find(c => c.id === companyId);
      companiesToCheckIn = [
        company,
        ...(company.ancestors || []).map(ancestorId => companiesToCheckIn.find(c => c.id === ancestorId)),
      ];
    }
    return companiesToCheckIn.some(c => c && this.isAllowedTo(requiredRight, c.rights));
  }

  isSuperAdmin(): boolean {
    const user = this.authenticationStore.getCurrentUserSnapshot();
    return !!user && User.isSuperAdmin(user);
  }

  private isAllowedTo(requestedRight: CompanyRights, currentRight: CompanyRights): boolean {
    const ascRights: CompanyRights[] = [
      CompanyRights.TECHNICIAN,
      CompanyRights.TECHNICIAN_EXPERIENCED,
      CompanyRights.BUSINESS_MANAGER,
      CompanyRights.ADMIN,
    ];
    return ascRights.indexOf(currentRight) >= ascRights.indexOf(requestedRight);
  }

  /**
   * WARNING: this function check "if the user is allowed to create a project in the specified perimeter"
   *
   * Note if the user is related to any company with a certain perimeter
   *
   * @param perimeter
   */
  isAuthorizedByPerimeter(perimeter: Perimeter): boolean {
    if (this.isSuperAdmin()) {
      return true;
    }
    const companies = this.authenticationStore.currentUserCompaniesSnapshot;
    return companies?.some(c => c.type !== 'ADMINISTRATIVE' && c.perimeters?.includes(perimeter));
  }
}
