import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { PROJECT_STATUS, Project } from '../model/project.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { cn_building } from '@enerbim/cnmap-editor';
import { ProjectApiService } from './project-api.service';
import { FileUtils } from '../utils/file.utils';
import { Page, PageRequest } from '../model/page';
import { updateProjectFromBuilding } from '../utils/project-drawing.utils';
import { DrawingApiService } from './drawing-api.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private currentProject = new BehaviorSubject<Project>(undefined);

  constructor(private projectApiService: ProjectApiService, private drawingApiService: DrawingApiService) {}

  /**
   * PROJECT
   */

  setCurrentProject(project: Project) {
    this.currentProject.next(project);
  }

  getCurrentProject(): Observable<Project> {
    return this.currentProject
      .asObservable()
      .pipe(map(project => (project ? Object.assign(new Project(project.type), project) : project)));
  }

  reloadCurrentProject$(): Observable<Project> {
    return this.projectApiService
      .getProject(this.getCurrentProjectSnapshot().id)
      .pipe(tap(project => this.currentProject.next(project)));
  }

  /**
   * Renvoie le projet courant (valeur du BS).
   * Ne pas appeler dans les composants, uniquement par les services.
   */
  getCurrentProjectSnapshot(): Project {
    return this.currentProject.getValue();
  }

  updateProjectFromBuilding$(project: Project, building: cn_building): Observable<Project> {
    updateProjectFromBuilding(building, project);
    return this.upsert(project);
  }

  findAllForCurrentUser(
    req: PageRequest<Project> = null,
    query: string,
    projectStatus: PROJECT_STATUS[],
    includeMyProjects: boolean,
    includeCompanyProjects: boolean,
  ): Observable<Page<Project>> {
    let params = new HttpParams();
    params = params.append('page', String(req.page));
    params = params.append('size', String(req.size));
    if (req && req.sort) {
      params = params.append('sort', `${req.sort.property},${req.sort.order}`);
    }
    params = params.append('query', String(query));
    params = params.append('status', projectStatus.join(','));
    params = params.append('includeMyProjects', includeMyProjects);
    params = params.append('includeCompanyProjects', includeCompanyProjects);
    return this.projectApiService.getAllProjects(params).pipe(
      map(httpResponse => {
        return {
          content: httpResponse.body,
          totalElements: Number(httpResponse.headers.get('X-Total-Count')),
          size: req.size,
          number: req.page,
        };
      }),
    );
  }

  findOne(projectId: string): Observable<Project> {
    return this.projectApiService.getProject(projectId).pipe(switchMap(project => this.setImagesUrl(project)));
  }

  upsert(project: Project): Observable<Project> {
    if (!project.id) {
      project.name = this.makeid(6);
    }
    return this.projectApiService.upsertProject(project).pipe(
      tap(project => this.currentProject.next(project)),
      switchMap(projectResult => this.setImagesUrl(projectResult)),
    );
  }

  /** Générateur de nom de projet 6 lettres majuscules au hasard */
  private makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  deleteProject(projectId: string) {
    return this.projectApiService.deleteProject(projectId);
  }

  updatePinnedStatus(projectId: string, pinned: boolean) {
    return this.projectApiService.updatePinnedStatus(projectId, pinned);
  }

  /**
   * IMAGES
   */

  /**
   * Get url for all images in project (BMs and comment pictures)
   * @param project
   * @private
   */
  private setImagesUrl(project: Project): Observable<Project> {
    return this.getImageUrl(project).pipe(
      tap(imageDataUri => (project.imageUrl = imageDataUri)),
      map(() => project),
    );
  }

  /**
   * Get image url
   * @param project
   */
  getImageUrl(project: Project): Observable<string> {
    if (!project.imageFileId) {
      return of(null);
    }
    // Récupère l'image côté serveur et la transforme en DataURI
    return this.getImage(project.id, project.imageFileId).pipe(switchMap(blob => FileUtils.fileToDataURI(blob)));
  }

  /**
   * Get image
   * @param projectId
   * @param imageFileId
   */
  getImage(projectId: string, imageFileId: string): Observable<Blob> {
    return this.drawingApiService.getDrawingPicture(projectId, imageFileId);
  }
}
