import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiside-container',
  templateUrl: './multiside-container.component.html',
  styleUrls: ['./multiside-container.component.scss'],
})
export class MultisideContainerComponent implements OnInit {
  @Input() hideLeftPart = false;

  constructor() {}

  ngOnInit() {}
}
