'use strict';

import { cn_element_type } from '../model/cn_element_type';
import { cn_background_map } from '../model/cn_background_map';
import { cn_comment_picture } from '..';

//***********************************************************************************
//***********************************************************************************
/**
 * Classes to manage input request to the User Interface
 */
//***********************************************************************************
//***********************************************************************************

/**Severity flags for notifications */
export const SEVERITY_NOTIFICATION_INFORMATION = 0;
export const SEVERITY_NOTIFICATION_SUCCESS = 1;
export const SEVERITY_NOTIFICATION_WARNING = 2;
export const SEVERITY_NOTIFICATION_ERROR = 3;

/**Severity flags for questions */
export const SEVERITY_QUESTION_REGULAR = 0;
export const SEVERITY_QUESTION_WARNING = 1;

/**
 * @class cn_notification_input
 * A class used to display a simple notification
 */
export class cn_notification_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the onput
     * @param {number} severity - Severity
     */
    constructor(label = '', severity = SEVERITY_NOTIFICATION_INFORMATION) {
        this.label = label;
        this.severity = severity;
    }
}

/**
 * @class cn_question_input
 * A class used to request a simple question
 */
export class cn_question_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the onput
     * @param {function} cb
     */
    constructor(label = '', cb = null) {
        this.label = label;
        this.callback = cb;
        this.severity = SEVERITY_QUESTION_REGULAR;
    }
}

/**
 * @class cn_text_input
 * A class used to request a single text
 */
export class cn_text_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the onput
     * @param {string} value - (i/o) text input
     * @param {function} cb
     */
    constructor(label = '', value = '', cb = null) {
        this.label = label;
        this.value = value;
        this.callback = cb;
    }
}

/**
 * @class cn_number_input
 * A class used to request a single value change from the UI
 */
export class cn_number_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the onput
     * @param {number} value - (i/o) value
     * @param {string} unit
     * @param {number} decimals
     * @param {number} min_value
     * @param {number} max_value
     * @param {string} checkbox_label - If not empty, the control must add a checkbox with that label. If checked, the value is not visible (by default, can be overriden).
     * @param {boolean} checkbox_status - (i/o) Initial status of the above checkbox (default false)
     * @param {boolean} checked_hides_value - If checkbox is checked, the value is not visible (default true)
     * @param {function} cb
     */
    constructor(label = '', value = 0, unit = '', decimals = 0, min_value = -Infinity, max_value = Infinity, checkbox_label = '', checkbox_status = false, checked_hides_value = true, cb = null) {
        this.label = label;
        this.value = value;
        this.unit = unit;
        this.decimals = decimals;
        this.min_value = min_value;
        this.max_value = max_value;
        this.checkbox_label = checkbox_label;
        this.checkbox_status = checkbox_status;
        this.checked_hides_value = checked_hides_value;
        this.callback = cb;
        this.slider = false;
    }
}


//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_number_multi_input
 * A class used to request several value change from the UI
 */
export class cn_number_list_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the onput
     * @param {Array<cn_number_input>} values - (i/o) List of start values. The callback is not used for these inputs.
     * @param {function} cb
     */
    constructor(label = '', values = [], cb = null) {
        this.label = label;
        this.values = values;
        this.callback = cb;
    }
}

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_number_select_input
 * A class used to request a single value change, with select choice
 */
export class cn_number_select_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the onput
     * @param {number} choice - (i/o) initial choice
     * @param {Array<cn_number_input>} values - (i/o) List of start values. The callback is not used for these inputs.
     * @param {function} cb
     */
    constructor(label = '', choice = 0, values = [], cb = null) {
        this.label = label;
        this.choice = choice;
        this.values = values;
        this.callback = cb;
    }
}


//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_element_type_input
 * A class used to request choice between several element types
 */
export class cn_element_type_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the input
     * @param {Array<number>} selection - (i/o) indices of elements initially selected
     * @param {Array<cn_element_type>} choices - list of element types
     * @param {string} checkbox_label - If not empty, the control must add a checkbox with that label
     * @param {boolean} checkbox_status - (i/o) Initial status of the above checkbox
     * @param {function} cb - Callback
     */
    constructor(label = '', selection = [], choices = [], checkbox_label = '', checkbox_status = false, cb = null) {
        this.label = label;
        this.selection = selection;
        this.choices = choices;
        this.checkbox_label = checkbox_label;
        this.checkbox_status = checkbox_status;
        this.callback = cb;
    }
}

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_image_input
 * A class used to request choice between several images
 */
export class cn_image_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the input
     * @param {Array<number>} selection - (i/o) indices of elements initially selected
     * @param {Array<{label:string, url:string}>} choices - list of images
     * @param {Array<string>} options_labels - If not empty, the control must add a checkbox with that label
     * @param {function} cb - Callback
     */
    constructor(label = '', selection = [], choices = [], options_labels = [''], cb = null) {
        this.label = label;
        this.selection = selection;
        this.choices = choices;
        this.options_labels = options_labels;
        this.options_selected = options_labels[0];
        this.callback = cb;
        this.helper = '';
    }
}

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_color_input
 * A class used to request a color choice
 */
export class cn_color_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the input
     * @param {string} color - (i/o) editable color
     * @param {string} checkbox_label - If not empty, the control must add a checkbox with that label
     * @param {boolean} checkbox_status - (i/o) Initial status of the above checkbox
     * @param {function} cb - Callback
     */
    constructor(label = '', color = '', checkbox_label = '', checkbox_status = false, cb = null) {
        this.label = label;
        this.color = color;
        this.checkbox_label = checkbox_label;
        this.checkbox_status = checkbox_status;
        this.callback = cb;
    }
}

//***********************************************************************************
//***********************************************************************************
export const PARAM_STATUS_USE_DEFAULT_VALUE = 0;
export const PARAM_STATUS_VARIABLE = 1;
export const PARAM_STATUS_CUSTOM_VALUE = 2;

/**
 * @class cn_object_parameters_input
 * A class used to request edition of an object instance's parameters
 */
export class cn_object_parameters_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - Visible label of the input
     * @param {string} product_type - product type
     * @param {Array<{code_bim: string, default_value: any, value:any, status: number}>} parameters - (i/o) initial choice. status can be 0 (use default value), 1 (variable), 2 (personalized value)
     * @param {function} cb : this function will simply change the contents of 'parameters'.
     */
    constructor(label = '', product_type = '', parameters = [], cb = null) {
        this.label = label;
        this.product_type = product_type;
        this.parameters = parameters;
        this.callback = cb;
        this.readonly = false;
    }
}


//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_marker_input
 * A class used to request edition of a marker
 */
export class cn_marker_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {function} type - marker constructor (i)
     * @param {string} label - marker title (i/o)
     * @param {string} content - marker content (i/o)
     * @param {boolean} anomaly - does marker indicates an anomaly (i/o)
     * @param {string} group - marker group (i/o)
     * @param {object} parameters - other marker parameters (i/o)
     * @param {string} color - (i/o) editable color
     * @param {string} color_checkbox_label - If not empty, the color control must add a checkbox with that label
     * @param {boolean} color_checkbox_status - (i/o) Initial status of the above checkbox
     * @param {cn_comment_picture[]} pictures - marker pictures (i/o)
     * @param {boolean} label_required - is marker title required
     * @param {function} cb - callback
     */
    constructor(type, label = '', content = '', anomaly = true, group = '', parameters = {}, color = '', color_checkbox_label = '', color_checkbox_status = false, pictures = [], label_required = true, cb = null) {
        this.type = type;
        this.label = label;
        this.content = content;
        this.anomaly = anomaly;
        this.group = group;
        this.parameters = parameters;
        this.color = color;
        this.color_checkbox_label = color_checkbox_label;
        this.color_checkbox_status = color_checkbox_status;
        this.pictures = pictures;
        this.label_required = label_required;
        this.callback = cb;
        this.specific_params = {};
        this.readonly = false;

    }
}

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_space_input
 * A class used to request name and usage of a space
 */
export class cn_space_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} ID - space ID (i)
     * @param {string} storey_ID - storey ID (i)
     * @param {string} name - space name (i/o)
     * @param {string} usage - space usage (code bim) (i/o)
     * @param {boolean} heated - space heated (i/o)
     * @param {boolean} various_names - true if several different names (i/o)
     * @param {boolean} various_usages - true if several different usages (i/o)
     * @param {boolean} heated_relevant - true if heated parameter is relevant (i)
     * @param {boolean} various_heated - true if several different heated (i/o)
     * @param {function} cb - callback
     */
    constructor(ID = '', storey_ID = '', name = '', usage = '', heated = true, various_names = false, various_usages = false, heated_relevant = false, various_heated = false, cb = null) {
        this.ID = ID;
        this.storey_ID = storey_ID;
        this.name = name;
        this.usage = usage;
        this.heated = heated;
        this.various_names = various_names;
        this.various_usages = various_usages;
        this.heated_relevant = heated_relevant;
        this.various_heated = various_heated;
        this.callback = cb;
        this.readonly = false;
    }
}

//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_space_equipment_input
 * A class used to manage equipments inside a space
 */
export class cn_space_equipments_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} name - space name (i)
     * @param {string} usage - space usage (i)
     * @param {{[id: string]: number}} objects - list of objects inside the space. Key is the Object ID, value is the number of instances in the space. (i/o)
     * @param {function} cb - callback
     */
    constructor(name = '', usage = '', objects = {}, cb = null) {
        this.name = name;
        this.usage = usage;
        this.objects = objects;
        this.callback = cb;
    }
}


//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_description_input
 * A class used to display description
 */
export class cn_description_input {
    //***********************************************************************************
    /**
     * Constructor
     * @param {string} label - main label (i)
     * @param {{label: string, value?: any, decimals?: number, unit?: string}[]} description - list of descriptions lines.
     */
    constructor(label, description) {
        this.label = label;
        this.description = description;
    }
}

/**
 * @class cn_background_map_selection_input
 * A class used to select a background map
 */
export class cn_background_map_selection_input {

    /**
     * @param {string} label
     * @param {cn_background_map[]} background_maps
     * @param {function} cb - callback
     */
    constructor(label, background_maps, cb = null) {
        this.label = label;
        this.content = '';
        this.background_maps = background_maps;
        /**
         * @type {cn_background_map}
         */
        this.selection = null;
        this.callback = cb;
    }
}


