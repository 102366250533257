import { Injectable } from '@angular/core';
import { BuildingStore } from '@enerbim/cnmap-angular-editor-lib';
import { cn_building } from '@enerbim/cnmap-editor';
import moment from 'moment';
import { HandlingSpec, Project } from 'src/app/shared/model/project.model';
import {
  GWeighting,
  IncomeColor,
  MinMax,
  PacReferentialStore,
  ReferentialEntry,
} from 'src/app/shared/store/pac-referential-store.service';
import { EMITTER_CODE_BIM, EMITTER_SPECIFICATIONS } from '../constants/emitter-constantes';
import { EmitterInterface } from '../model/emitter-interface';
import { StepStatus } from '../model/project-stepper/project-step';
import { HEATING_FLOOR_LIMIT } from '../utils/constants';
import { PacCnBuildingService } from './pac-cn-building.service';
import { PacSizingPicturesReferential } from '../constants/pac-sizing-pictures';

export interface Influence {
  label: string;
  gWeighting: number;
}

export interface RenovationInfluence {
  buildingElementLabel: string;
  categoryLabel: string;
  gWeighting: number;
}

export interface GInfo {
  builtType: ReferentialEntry & MinMax;
  groundPosition: Influence;
  jointness: Influence;
  windowSurface: number;
  totalSurface: number;
  windowRatio: ReferentialEntry & MinMax & GWeighting;
  ventilation: Influence;
  renovations: RenovationInfluence[];
  getUpdatedG: (g: number) => number;
}

@Injectable({ providedIn: 'root' })
export class PacLogicService {
  constructor(
    private pacReferentialStore: PacReferentialStore,
    private pacCnBuildingService: PacCnBuildingService,
    private buildingStore: BuildingStore,
  ) {}

  clientInfoStatus(project: Project): StepStatus {
    const isComplete =
      !!project?.client?.nbPersonneFoyer &&
      !!project?.client?.civility &&
      !!project?.client?.firstname &&
      !!project?.client?.lastname &&
      !!project?.address?.address &&
      !!project?.address?.postcode &&
      !!project?.address?.city;
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  projectInfoStatus(project: Project): StepStatus {
    const isComplete =
      !!project?.pacSizing?.heatingSystemToChange &&
      !!project?.pacSizing?.pacType &&
      !!project?.pacSizing?.pacFunction &&
      !!project?.pacSizing?.supportSlab &&
      !!project?.pacSizing?.insideHeight &&
      !!project?.pacSizing?.heaterOutputDiameter &&
      !!project?.pacSizing?.heaterInputDiameter &&
      !!project?.pacSizing?.gridType &&
      (!this.pacReferentialStore.isDoubleService(project?.pacSizing?.pacFunction) ||
        project?.pacSizing?.externalHotWaterTank !== null);
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  locationInfoStatus(project: Project): StepStatus {
    const lowTemperaturesReference = this.pacReferentialStore.referentialSnapshot.lowTemperatures;
    const isComplete =
      !!project?.generalInformation?.localisation &&
      !!project?.pacSizing?.climateArea &&
      !!project?.pacSizing?.altitude;
    const hasLowTemperature = lowTemperaturesReference.some(
      lt => lt.climateArea === project?.pacSizing.climateArea && lt.altitude === project?.pacSizing.altitude,
    );
    return isComplete && hasLowTemperature
      ? StepStatus.COMPLETE
      : isComplete && !hasLowTemperature
      ? StepStatus.ERRORED
      : StepStatus.OPEN;
  }

  housingInfoStatus(project: Project): StepStatus {
    const isComplete =
      !!project?.pacSizing?.housingFloor &&
      !!project?.pacSizing?.jointness &&
      !!project?.pacSizing?.groundPosition &&
      !!project?.pacSizing?.ventilation &&
      !!project?.pacSizing?.buildType &&
      !!project?.pacSizing?.wallThickNess;
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  renovationInfoStatus(project: Project): StepStatus {
    const renovationBuildingElements = this.pacReferentialStore.referentialSnapshot.renovationBuildingElements;
    const isComplete =
      !!project?.pacSizing?.renovationByBuildingElement &&
      renovationBuildingElements.every(entry => !!project?.pacSizing.renovationByBuildingElement[entry.slug]);
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  energyInfoStatus(project: Project): StepStatus {
    const isComplete =
      !!project?.pacSizing?.temperature &&
      !!project?.pacSizing?.electricityType &&
      !!project?.pacSizing?.electricitySubscription;
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  handlingInfoStatus(project: Project): StepStatus {
    const isComplete =
      this.isHandlingComplete(project?.pacSizing?.handling?.interior) &&
      this.isHandlingComplete(project?.pacSizing?.handling?.exterior);
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  handlingTechnicalVisitAndPhotosStatus(project: Project): StepStatus {
    const isComplete = Object.keys(PacSizingPicturesReferential).every(
      key => !!project?.pacSizing?.pictures && !!project?.pacSizing?.pictures[key],
    );
    return isComplete ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  private isHandlingComplete(handlingSpec: HandlingSpec): boolean {
    return (
      !!handlingSpec?.distance &&
      !!handlingSpec?.doorDimensions &&
      !!handlingSpec?.groundNature &&
      !!handlingSpec?.slope &&
      !!handlingSpec?.stairs
    );
  }

  isGeneralInfoComplete(project: Project): boolean {
    const allStatus = [
      this.clientInfoStatus(project),
      this.projectInfoStatus(project),
      this.locationInfoStatus(project),
      this.housingInfoStatus(project),
      this.renovationInfoStatus(project),
      this.energyInfoStatus(project),
      this.handlingInfoStatus(project),
    ];
    return allStatus.every(s => s === StepStatus.COMPLETE);
  }

  isDrawingComplete(project: Project): boolean {
    const rooms = this.pacCnBuildingService.getRoomsDescriptions(this.buildingStore.getCurrentBuildingSnapshot());
    return !!project.drawingDetails && !!rooms.length;
  }

  mapStatus(project: Project): StepStatus {
    if (!project.id) {
      return StepStatus.LOCKED;
    }

    return this.isDrawingComplete(project) ? StepStatus.COMPLETE : StepStatus.OPEN;
  }

  validationStatus(project: Project): StepStatus {
    if (this.mapStatus(project) !== StepStatus.COMPLETE) {
      return StepStatus.LOCKED;
    }

    return project.pacSizing.studyCompleteValidatedAt &&
      moment(project.pacSizing.studyCompleteValidatedAt).isAfter(project.pacSizing.generalInformationUpdatedAt) &&
      moment(project.pacSizing.studyCompleteValidatedAt).isAfter(project.drawingDetails.lastUpdatedDate)
      ? StepStatus.COMPLETE
      : StepStatus.OPEN;
  }

  configurationStatus(project: Project): StepStatus {
    if (this.validationStatus(project) !== StepStatus.COMPLETE) {
      return StepStatus.LOCKED;
    }
    return project.pacSizing.g &&
      moment(project.pacSizing.configurationValidatedAt).isSameOrAfter(project.pacSizing.studyCompleteValidatedAt)
      ? StepStatus.COMPLETE
      : StepStatus.OPEN;
  }

  calculationStatus(project: Project): StepStatus {
    if (this.configurationStatus(project) !== StepStatus.COMPLETE) {
      return StepStatus.LOCKED;
    }
    return project.pacSizing.waterRegimeMax &&
      project.pacSizing.heatLossAtValidation &&
      moment(project.pacSizing.calculationValidatedAt).isSameOrAfter(project.pacSizing.configurationValidatedAt)
      ? StepStatus.COMPLETE
      : StepStatus.OPEN;
  }

  propositionStatus(project: Project): StepStatus {
    if (
      this.calculationStatus(project) !== StepStatus.COMPLETE ||
      this.hasHeatedFloorRoomWithOversizeHeatLoss(project)
    ) {
      return StepStatus.LOCKED;
    }
    return !!project.pacSizing?.proposedProduct &&
      moment(project.pacSizing.propositionValidatedAt).isAfter(project.pacSizing.calculationValidatedAt)
      ? StepStatus.COMPLETE
      : StepStatus.OPEN;
  }

  private hasHeatedFloorRoomWithOversizeHeatLoss(project): boolean {
    const deltaT =
      project.pacSizing.temperature -
      this.pacReferentialStore.getLowTemperature(project.pacSizing.climateArea, project.pacSizing.altitude);
    const building = this.buildingStore.getCurrentBuildingSnapshot();
    const g = this.extractGInfo(project, building).getUpdatedG(project.pacSizing.g);
    const rooms = this.pacCnBuildingService.getRoomsDescriptions(building);
    return rooms
      .filter(room => room.hasHeatedFloor)
      .some(room => (room.volume * g * deltaT) / room.area > HEATING_FLOOR_LIMIT);
  }

  getRenovationInfluence(
    project: Project,
    renovationBuildingElementSlug: string,
    categorySlug: string = project.pacSizing.renovationByBuildingElement[renovationBuildingElementSlug],
  ): RenovationInfluence {
    const referential = this.pacReferentialStore.referentialSnapshot;
    const renovationBuildingElement = referential.renovationBuildingElements.find(
      element => element.slug === renovationBuildingElementSlug,
    );
    const category = referential.renovationCategories.find(c => c.slug === categorySlug);
    const renovationInfluence = referential.renovationInfluence.find(
      entry =>
        entry.renovationCategory === categorySlug &&
        entry.renovationBuildingElement === renovationBuildingElementSlug &&
        entry.builtTypes.includes(project.pacSizing.buildType),
    );

    return {
      buildingElementLabel: renovationBuildingElement.label,
      categoryLabel: category.label,
      gWeighting: renovationInfluence?.gWeighting || 0,
    };
  }

  extractGInfo(project: Project, building: cn_building): GInfo {
    const referential = this.pacReferentialStore.referentialSnapshot;
    const builtType = referential.builtTypes.find(entry => entry.slug === project.pacSizing.buildType);
    const groundPosition = referential.groundPositions.find(entry => entry.slug === project.pacSizing.groundPosition);
    const groundPositionInfluence =
      referential.groundPositionInfluence.find(
        entry =>
          entry.slug === project.pacSizing.groundPosition && entry.builtTypes.includes(project.pacSizing.buildType),
      )?.gWeighting || 0;
    const jointness = referential.jointness.find(entry => entry.slug === project.pacSizing.jointness);
    const windowSurface = this.pacCnBuildingService.getTotalGlazingArea(building);
    const totalSurface = this.pacCnBuildingService.getTotalArea(building);
    const ratio = windowSurface / totalSurface;
    const windowRatio = referential.windowRatios.find(wr => wr.min <= ratio && ratio < wr.max);
    const ventilation = referential.ventilations.find(entry => entry.slug === project.pacSizing.ventilation);
    const ventilationInfluence =
      referential.ventilationInfluence.find(
        entry => entry.slug === project.pacSizing.ventilation && entry.builtTypes.includes(project.pacSizing.buildType),
      )?.gWeighting || 0;
    const renovations = referential.renovationBuildingElements.map(element =>
      this.getRenovationInfluence(project, element.slug),
    );

    const gModifier =
      groundPositionInfluence +
      jointness.gWeighting +
      windowRatio.gWeighting +
      ventilationInfluence +
      renovations.reduce((sum, reno) => sum + reno.gWeighting, 0);

    return {
      builtType,
      jointness,
      windowSurface,
      totalSurface,
      windowRatio,
      renovations,
      ventilation: { label: ventilation.label, gWeighting: ventilationInfluence },
      groundPosition: { label: groundPosition.label, gWeighting: groundPositionInfluence },
      getUpdatedG: (g: number) => Math.max(g + gModifier, 0),
    };
  }

  calculateEmitterPower(emitter: EmitterInterface, waterRegime: MinMax, tDesired: number, heatLoss: number): number {
    switch (emitter.slug) {
      case EMITTER_CODE_BIM.LAMELLES:
        return this._calculateBasicEmitterPower(emitter, waterRegime, tDesired, [6.5, 9.5, 12]);
      case EMITTER_CODE_BIM.ALUMINIUM:
        return this._calculateBasicEmitterPower(emitter, waterRegime, tDesired, [8, 9.5, 14]);
      case EMITTER_CODE_BIM.SECHE_SERVIETTE_EAU:
        return this._calculateWaterTowelDryer(emitter, waterRegime, tDesired);
      case EMITTER_CODE_BIM.NOURRICE_PLANCHER_CHAUFFANT:
        return heatLoss;
      case EMITTER_CODE_BIM.CONVECTEUR_ELECTRIQUE:
      case EMITTER_CODE_BIM.SECHE_SERVIETTE_ELECTRIQUE:
        return Math.min(emitter.rawPower, heatLoss);
      default: {
        return this._calculateBasicEmitterPower(emitter, waterRegime, tDesired);
      }
    }
  }

  private _calculateBasicEmitterPower(
    emitter: EmitterInterface,
    waterRegime: MinMax,
    tDesired: number,
    thicknessPossibilities?: number[],
  ): number {
    const emitterSpec = EMITTER_SPECIFICATIONS.get(this.getEmitterSlug(emitter, thicknessPossibilities));
    if (!emitterSpec) {
      throw new Error('Unsupported emitter type');
    }
    const p50 = (emitterSpec.a * emitter.height ** 2 + emitterSpec.b * emitter.height + emitterSpec.c) * emitter.width;
    const deltaT = (waterRegime.max + waterRegime.min) / 2 - tDesired;
    return p50 * Math.pow(deltaT / 50, emitterSpec.n);
  }

  getEmitterSlug(emitter: EmitterInterface, thicknessPossibilities?: number[]): string {
    if (!thicknessPossibilities) {
      return emitter.slug;
    }

    const closestThickness = thicknessPossibilities.reduce((closest, ref) =>
      Math.abs(ref - emitter.thickness) < Math.abs(closest - emitter.thickness) ? ref : closest,
    );
    return `${emitter.slug}_${closestThickness}`;
  }

  private _calculateWaterTowelDryer(emitter: EmitterInterface, waterRegime: MinMax, tDesired: number): number {
    const deltaT = (waterRegime.max + waterRegime.min) / 2 - tDesired;
    return emitter.rawPower * Math.pow(deltaT / 50, 1.3);
  }

  isIDF(project: Project): boolean {
    if (!project?.generalInformation?.localisation) {
      return undefined;
    }

    return ['75', '77', '78', '91', '92', '93', '94', '95'].includes(project.generalInformation.localisation);
  }

  getMaPrimRenov(project: Project): IncomeColor {
    if (
      !project?.generalInformation?.localisation ||
      !project?.client?.nbPersonneFoyer ||
      (!project?.client?.revenuFiscal && project?.client?.revenuFiscal !== 0)
    ) {
      return undefined;
    }

    const isIDF = this.isIDF(project);
    const { nbPersonneFoyer, revenuFiscal } = project.client;

    return this.pacReferentialStore.referentialSnapshot.incomeColors.find((ic, i, arr) => {
      if (arr.length === i + 1) {
        return true; // no need to calculate the revenuFiscalColor for the last column since it will group everybody above the ceil
      }

      const revenuFiscalSlices = ic[isIDF ? 'idf' : 'noneIdf'];
      const firstSlices = revenuFiscalSlices.slice(0, -1);
      const perAdditionalSlices = revenuFiscalSlices[revenuFiscalSlices.length - 1];

      const revenuFiscalSliceMax =
        +nbPersonneFoyer < firstSlices.length
          ? firstSlices[+nbPersonneFoyer - 1]
          : firstSlices[firstSlices.length - 1] + (+nbPersonneFoyer - firstSlices.length) * perAdditionalSlices;

      return revenuFiscal < revenuFiscalSliceMax;
    });
  }

  getAreaNotExcluded(building: cn_building, excludedRoomIds: { id: string; storey: string }[]): number {
    return building.storeys
      .flatMap(storey => storey.scene.spaces.map(space => ({ space, storey: storey.ID })))
      .filter(
        room =>
          !room.space.outside &&
          !excludedRoomIds.some(
            excludedRoom => excludedRoom.id === room.space.ID && excludedRoom.storey === room.storey,
          ),
      )
      .reduce((a, b) => a + b.space.get_area(), 0);
  }
}
