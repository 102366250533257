'use strict';
//***********************************************************************************
//***********************************************************************************
//**** cn_camera : a 2D camera
//***********************************************************************************
//***********************************************************************************

import { fh_polygon, fh_solid } from '@enerbim/fh-3d-viewer';
import {
    cn_add,
    cn_cart,
    cn_clone,
    cn_dist,
    cn_dot,
    cn_middle,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_polar,
    cn_sub,
    cn_uuid,
    cnx_add,
    cnx_build_axis,
    cnx_dist,
    cnx_mul
} from '../utils/cn_utilities';

var SNAP_RATIO = 0.005;
var _NB_CAMERAS = 0;

export class cn_camera {
    constructor() {
        var obj = this;

        this.ID = cn_uuid('camera' + _NB_CAMERAS);
        _NB_CAMERAS++;

        //*** 0: world x of screen center
        //*** 1: world y of screen center
        //*** 2: world length of screen diagonal
        this._matrix = [0, 0, 30];
        this._width = 100;
        this._height = 100;
        this._screen_to_world = [0, 0];
        this._world_to_screen = [0, 0];

        this.snap_threshold = 0.01;

        this._grid_min_step = 1;

        this.screen_to_world_scale = 0;
        this.world_to_screen_scale = 0;

        this.padding = [0, 0, 0, 0];
        this.show_angles = true;
        this.show_measures = true;
        this.show_space_measure = true;
        this.show_wall_type = false;
        this.show_facings = false;
        this.use_grid = true;
        this.draw_objects_icon = true;
        this.draw_objects_top_view = true;


        this.snap_world_distance = 0;
        this.snap_screen_distance = 0;

        this.thumb_size = 40;

        this.element_filter = null;

        this.storey = null;
    }

    /**
     * Does the camera uses 3D (true for derivate such as cn_overlay_camera)
     * @returns {boolean}
     */
    is_3d() {
        return false;
    }

    //***********************************************************************************
    //**** Sets padding for camera center
    //***********************************************************************************
    set_padding(top = 0, right = 0, bottom = 0, left = 0) {
        this.padding[0] = top;
        this.padding[1] = right;
        this.padding[2] = bottom;
        this.padding[3] = left;
    }

    //***********************************************************************************
    //**** Set size
    //***********************************************************************************
    set_size(width, height) {
        if (this._width === width && this._height === height) return;
        this._width = width;
        this._height = height;
        this._update_matrices();
    }

    get_screen_size() {
        return [this._width, this._height];
    }

    get_world_size() {
        return [this._width * this.screen_to_world_scale, this._height * this.screen_to_world_scale];
    }

    //***********************************************************************************
    //**** Camera wrold (world center of camera)
    //***********************************************************************************
    get_actual_padding() {
        var pp = this.padding.concat([]);
        if (this._width - this.padding[1] - this.padding[3] < 10) {
            pp[1] = 0;
            pp[3] = 0;
        }
        if (this._height - this.padding[0] - this.padding[2] < 10) {
            pp[0] = 0;
            pp[2] = 0;
        }
        return pp;
    }

    get_world_center() {
        var padding = this.get_actual_padding();
        return this.screen_to_world([padding[3] + (this._width - padding[1] - padding[3]) / 2, padding[0] + (this._height - padding[2] - padding[0]) / 2]);
    }

    get_world_height() {
        var padding = this.get_actual_padding();
        return (this._height - padding[2] - padding[0]) * this.screen_to_world_scale;
    }

    set_world_focus(world_center, world_height) {
        var padding = this.get_actual_padding();
        var height = this._height - padding[0] - padding[2];
        var screen_to_world = world_height / height;
        this._matrix[0] = world_center[0] + (padding[1] - padding[3]) * screen_to_world * 0.5;
        this._matrix[1] = world_center[1] - (padding[2] - padding[0]) * screen_to_world * 0.5;
        var diagonal = Math.sqrt(this._width * this._width + this._height * this._height);
        if (diagonal < 1) diagonal = 1;
        this._matrix[2] = screen_to_world * diagonal;
        this._update_matrices();
    }

    //***********************************************************************************
    //**** Fit camera to given box
    //***********************************************************************************
    fit_box(box) {
        if (!box.posmin) return;
        var padding = this.get_actual_padding();

        var width = this._width - padding[1] - padding[3];
        var height = this._height - padding[0] - padding[2];

        var scale0 = (1 + box.size[1]) / height;
        var scale1 = (1 + box.size[0]) / width;
        var scale = (scale0 > scale1) ? scale0 : scale1;
        var center = cn_add(box.posmin, cn_mul(box.size, 0.5));
        this.set_world_focus(center, scale * height);
    }

    //***********************************************************************************
    //**** Copy
    //***********************************************************************************
    copy(other_camera) {
        for (var k = 0; k < 3; k++)
            this._matrix[k] = other_camera._matrix[k];
        this._update_matrices();
    }

    //***********************************************************************************
    //**** Coordinates
    //***********************************************************************************

    /**
     * Screen to world projection
     * @param {number[]} src
     * @returns {number[]}
     */
    screen_to_world(src) {
        return [this._screen_to_world[0] + src[0] * this.screen_to_world_scale, this._screen_to_world[1] + (this._height - src[1]) * this.screen_to_world_scale];
    }

    /**
     * World to screen projection
     * @param {number[]} src
     * @returns {number[]}
     */
    world_to_screen(src, z = 0) {
        return [this._world_to_screen[0] + src[0] * this.world_to_screen_scale, this._height - this._world_to_screen[1] - src[1] * this.world_to_screen_scale];
    }


    /**
     * Checks if a world point is visible on screen (only for 3D camera)
     * @param {number[]} point
     * @returns {boolean}
     */
    check_visibility(point) {
        return true;
    }

    //***********************************************************************************
    //**** Draw the grid
    //***********************************************************************************
    draw_grid(min_spacing = 0, extra_classes = []) {
        var html = '';
        var p0 = this.screen_to_world([0, this._height]);
        var p1 = this.screen_to_world([this._width, 0]);

        var threshold = (min_spacing) ? min_spacing / 2 : 0.005;
        var nscale = 0;
        for (var scale = 10; scale > threshold; scale *= 0.1) {
            if (min_spacing === 0 && scale * this.world_to_screen_scale < 20) break;
            this._grid_min_step = scale;
            for (var x = scale * Math.floor(p0[0] / scale); x < p1[0]; x += scale) {
                var xx = this._world_to_screen[0] + x * this.world_to_screen_scale;
                var extra = (Math.abs(x) < scale / 2) ? ' grid_origin' : '';
                if (extra_classes.length > 0) extra += ' ' + extra_classes.join(' ');
                html += '<line class=\'grid_' + nscale + extra + '\' x1=\'' + xx + '\' y1=\'0\' x2=\'' + xx + '\' y2=\'' + this._height + '\' />';
            }
            for (var y = scale * Math.floor(p0[1] / scale); y < p1[1]; y += scale) {
                var yy = this._height - this._world_to_screen[1] - y * this.world_to_screen_scale;
                var extra = (Math.abs(y) < scale / 2) ? ' grid_origin' : '';
                if (extra_classes.length > 0) extra += ' ' + extra_classes.join(' ');
                html += '<line class=\'grid_' + nscale + extra + '\' x1=\'0\' y1=\'' + yy + '\' x2=\'' + this._width + '\' y2=\'' + yy + '\' />';
            }
            nscale++;
        }
        return html;
    }

    draw_background() {
        let html = '';
        html += `<rect x="0" y="0" width="100%" height="100%" class="map_background"></rect>`
        return html;
    }

    //***********************************************************************************
    //**** Draw the scale
    //***********************************************************************************
    draw_scale(extra_classes = []) {
        var lengths = [1, 2, 5, 10, 20, 50, 100];
        var length = lengths[0];
        for (var i = 0; i < lengths.length; i++) {
            length = lengths[i];
            if (length * this.world_to_screen_scale > 50) break;
        }

        var sw = length * this.world_to_screen_scale;
        var html = '';
        var extra = extra_classes.join(' ');
        html += '<rect class=\'scale_background ' + extra + '\' x=\'' + (this._width - 30 - sw) + '\' y=\'' + (this._height - 40) + '\' width=\'' + (20 + sw) + '\' height=\'30\' />';
        html += '<line class=\'scale_line ' + extra + '\' x1=\'' + (this._width - 20) + '\' y1=\'' + (this._height - 20) + '\' x2=\'' + (this._width - 20 - sw) + '\' y2=\'' + (this._height - 20) + '\' />';
        html += '<line class=\'scale_end_line ' + extra + '\' x1=\'' + (this._width - 20) + '\' y1=\'' + (this._height - 25) + '\' x2=\'' + (this._width - 20) + '\' y2=\'' + (this._height - 15) + '\' />';
        html += '<line class=\'scale_end_line ' + extra + '\' x1=\'' + (this._width - 20 - sw) + '\' y1=\'' + (this._height - 25) + '\' x2=\'' + (this._width - 20 - sw) + '\' y2=\'' + (this._height - 15) + '\' />';
        html += '<text class=\'scale_text ' + extra + '\' x=\'' + (this._width - 20 - sw * 0.5) + '\' y=\'' + (this._height - 30) + '\'>' + length.toFixed(0) + ' m</text>';
        return html;
    }

    //***********************************************************************************
    //**** Draw Layers Legend
    //***********************************************************************************
    draw_layers_legend(layers_legend) {
        const step = 10;
        const x_start = 5
        let y_start = 0
        let y = 14;
        let html = `<text class="legend_title exp" x="${x_start}" y="${y}">Légende des calques</text>`;
        y += step + 5;
        const legend_details = this._draw_layer_legend_entry(layers_legend, x_start + 5, y, step, false)
        html += legend_details.html;
        y = legend_details.adjusted_y + step;
        html = `<rect class='legend_background exp' x="0" y="${y_start}" width="250"
            height="${y - y_start}" />` + html
        return html;
    }

    //***********************************************************************************
    //**** Draw Zones Legend
    //***********************************************************************************
    draw_zones_legend(zone_legend) {
        const step = 10;
        const x_start = 5
        let y_start = 0
        let y = 14;
        let html = `<text class="legend_title exp" x="${x_start}" y="${y}">Légende des zones</text>`;
        y += step + 5;
        zone_legend.sort((a, b) => a.name.localeCompare(b.name, 'fr', { numeric: true })).forEach(zone => {
            html += `<rect width="40px" height="20px" x="${x_start}" y="${y}" fill="${zone.color}"></rect>`
            html += `<text class="legend_entry_text" x="${x_start + 60}" y="${y + 14}">${zone.name}</text>`
            y += 20 + step;
        });
        html = `<rect class='legend_background exp' x="0" y="${y_start}" width="250"
            height="${y - y_start}" />` + html
        return html;
    }

    //***********************************************************************************
    //**** Draw legend for ZPSO report
    //***********************************************************************************
    draw_rapport_zpso_legend(zpso_legend, sampling_legend, zone_legend, title_bloc_specs) {
        let html = '';
        const step = 10;
        const title = (title_bloc_specs.level || '') + (title_bloc_specs.title ? ' - ' + title_bloc_specs.title : '');
        const auteur = title_bloc_specs.auteur || '';
        const numDossier = title_bloc_specs.numeroDossier || '';
        const adresse = title_bloc_specs.adresse || '';
        const indice = title_bloc_specs.indice || '';
        const planche = title_bloc_specs.planche || '';
        const logo = title_bloc_specs.logo || '';
        let y_start = 0
        let y = 14;
        let x_start_title = 5;
        let new_line_regex = /.{1,40}( |$)/g;
        if (logo) {
            x_start_title = 52;
            new_line_regex = /.{1,30}( |$)/g;
            html += `<image xlink:href="${logo}" x="2" y="2" height="48" width="48"/>`
        }
        if (title) {
            title.match(new_line_regex).forEach((span, index, allEntries) => {
                if (index === 0) {
                    html += `<text class="legend_title exp" x="${x_start_title}" y="${y}">`;
                }
                html += `<tspan x="${x_start_title}" y="${y}">${span}</tspan>`;
                y += step + 5;
                if (index === allEntries.length - 1) {
                    html += '</text>';
                }
            });
        }
        y -= 5;
        y = Math.max(y, 50);
        html = `<rect class="legend_title_block exp" x="0" y="${y_start}" width="250" height="${y - y_start}"></rect>` + html;
        y += step + 3;
        html += `<text class="legend_text exp" x="5" y="${y}">Auteur : ${auteur}</text>`;
        y += step + 3;
        html += `<text class="legend_text exp" x="5" y="${y}">Dossier n° : ${numDossier}</text>`;
        y += step + 3;
        const address = `Adresse du bien : ${adresse}`;
        if (address) {
            address.match(/.{1,48}( |$)/g).forEach((span, index, allEntries) => {
                if (index === 0) {
                    html += `<text class="legend_text exp" x="5" y="${y}">`;
                }
                html += `<tspan x="5" y="${y}">${span}</tspan>`;
                y += step + 3;
                if (index === allEntries.length - 1) {
                    html += '</text>';
                }
            });
        }
        html += `<text class="legend_text exp" x="5" y="${y}">Indice : ${indice}, (${planche})</text>`;
        y += 5;
        html = `<rect class="legend_background exp" x="0" y="${y_start}" width="250" height="${y - y_start}"></rect>` + html;
        if (zpso_legend.length || sampling_legend.length || zone_legend.length) {
            const x = 10;
            y_start = y;
            y += step * 2;
            html += `<text class="legend_title exp" x="${x}" y="${y}">Légende</text>`
            y += step * 2;
            if (zpso_legend.length) {
                const zpso_amianted = zpso_legend.filter(zpso => zpso.control_result === 'Amianté');
                const zpso_not_amianted = zpso_legend.filter(zpso => zpso.control_result === 'Non amianté');
                const zpso_potential = zpso_legend.filter(zpso => !zpso.control_result);
                if (zpso_amianted.length) {
                    html += `<text class="legend_text exp" x="${x}" y="${y}">Matériaux contenant de l'amiante</text>`;
                    y += step;
                    const zpso_entry = this._draw_layer_legend_entry(zpso_amianted, x, y, step);
                    html += zpso_entry.html;
                    y = zpso_entry.adjusted_y
                    y += step;
                }
                if (zpso_not_amianted.length) {
                    html += `<text class="legend_text exp" x="${x}" y="${y}">Matériaux ne contenant pas d'amiante</text>`;
                    y += step;
                    const zpso_entry = this._draw_layer_legend_entry(zpso_not_amianted, x, y, step);
                    html += zpso_entry.html;
                    y = zpso_entry.adjusted_y
                    y += step;
                }
                if (zpso_potential.length) {
                    html += `<text class="legend_text exp" x="${x}" y="${y}">Matériaux susceptibles de contenir de l'amiante</text>`;
                    y += step;
                    const zpso_entry = this._draw_layer_legend_entry(zpso_potential, x, y, step);
                    html += zpso_entry.html;
                    y = zpso_entry.adjusted_y
                    y += step;
                }
            } else if (zone_legend.length) {
                html += `<text class="legend_text exp" x="${x}" y="${y}">Zones</text>`;
                y += step;
                zone_legend.sort((a, b) => a.name.localeCompare(b.name, 'fr', { numeric: true })).forEach(zone => {
                    html += `<rect width="40px" height="20px" x="${x}" y="${y}" fill="${zone.color}"></rect>`
                    html += `<text class="legend_entry_text" x="${x + 60}" y="${y + 14}">${zone.name}</text>`
                    y += 20 + step;
                });
                y += step;
            }
            if (sampling_legend.length) {
                html += `<text class="legend_text exp" x="${x}" y="${y}">Prélèvements</text>`;
                y += step;
                html += `<rect width="30px" height="20px" class="sampling_amianted_sampler" x="${x}" y="${y}"></rect>`;
                html += `<text class="legend_entry_text" x="${x + 35}" y="${y + 8}">Avec</text>`;
                html += `<text class="legend_entry_text" x="${x + 35}" y="${y + 19}">amiante</text>`;
                html += `<rect width="30px" height="20px" class="sampling_not_amianted_sampler" x="${x + 80}" y="${y}"></rect>`;
                html += `<text class="legend_entry_text" x="${x + 115}" y="${y + 8}">Sans</text>`;
                html += `<text class="legend_entry_text" x="${x + 115}" y="${y + 19}">amiante</text>`;
                html += `<rect width="30px" height="20px" class="sampling_no_result_sampler" x="${x + 160}" y="${y}"></rect>`;
                html += `<text class="legend_entry_text" x="${x + 195}" y="${y + 8}">En</text>`;
                html += `<text class="legend_entry_text" x="${x + 195}" y="${y + 19}">attente</text>`;
                y += 20 + step;
                html += `<circle class="exp marker_sampler" cx="${x + 10}" cy="${y + 10}" r="10"></circle>`;
                html += `<text class="legend_entry_text" x="${x + 25}" y="${y + 14}">Au plafond</text>`;
                html += `<rect class="exp marker_sampler" x="${x + 120}" y="${y}" width="20" height="20"></rect>`;
                html += `<text class="legend_entry_text" x="${x + 145}" y="${y + 14}">Au sol</text>`;
                y += 20 + step;
                html += `<path class="exp marker_sampler" d="M ${x} ${y + 10}
                L ${x + 10} ${y + 10} ${x + 10} ${y} ${x + 20} ${y + 10} ${x + 10} ${y + 20} ${x + 10} ${y + 10} Z" />`;
                html += `<text class="legend_entry_text" x="${x + 25}" y="${y + 14}">Sur mur</text>`;
                html += `<path class="exp marker_sampler" d="M ${x + 120} ${y}
                L ${x + 140} ${y + 20} M ${x + 120} ${y + 20} L ${x + 140} ${y}" />`;
                html += `<text class="legend_entry_text" x="${x + 145}" y="${y + 14}">Sur autre type</text>`;
                y += 20 + step;
            }
            html = `<rect class='legend_background exp' x="0" y="${y_start}" width="250"
            height="${y - y_start}" />` + html;
        }
        return html;
    }

    _draw_layer_legend_entry(layer_entries, x, y, step, description = true) {
        let html = '';
        let adjusted_y = y;
        layer_entries.sort((a, b) => a.name.localeCompare(b.name, 'fr', { numeric: true })).forEach(layer => {
            const rotation = layer.stripes === 'vertical' ? 0 : layer.stripes === 'diagonal' ? -45 : 90;
            html += `<pattern id="${layer.ID}" width="10" height="10" patternUnits="userSpaceOnUse"
                        patternTransform="rotate(${rotation})">
                        <rect width="2" height="10" fill="${layer.color}"></rect>
                    </pattern>`;
            const fill = layer.stripes !== 'full' ? 'url(#' + layer.ID + ')' : layer.color;
            html += `<rect width="40px" height="20px" x="${x}" y="${adjusted_y}" fill="${fill}"></rect>`;
            let name = layer.name;
            if (description) name += ' - ' + layer.description;
            if (name.length > 40) {
                html += `<text class="legend_entry_text" x="${x + 60}" y="${adjusted_y}">`;
                let [_, first, second] = name.match(/(.{1,40}) (.{1,40})/);
                html += `<tspan x="${x + 60}" y="${adjusted_y + 8}">${first}</tspan>`;
                if (second.length === 40) {
                    second = second.slice(0, -3) + '...';
                }
                html += `<tspan x="${x + 60}" y="${adjusted_y + 19}">${second}</tspan>`;
                html += `</text>`;
            } else {
                html += `<text class="legend_entry_text" x="${x + 60}" y="${adjusted_y + 14}">${name}</text>`;
            }
            adjusted_y += 20 + step;
        });
        return { html, adjusted_y };
    }

    //***********************************************************************************
    //**** Draw the compass
    //***********************************************************************************
    draw_compass(orientation, mouseover = false) {
        let html = ``;
        const corner = this._compass_corner();
        if (mouseover) {
            const radius = this._compass_radius();
            const center = cn_add(corner, [radius, radius]);
            html += `<circle cx="${center[0]}" cy="${center[1]}" r="${radius}" style="stroke: none; fill: rgb(255,255,0); opacity: 0.5" />`;
        }
        html += `<svg x="${corner[0]}" y="${corner[1]}" width="60" height="60" viewBox="0 0 100 100">`;
        html += `<g transform="rotate(${-180.5 + orientation} 50 50) scale(0.01)" fill="#000000" stroke="none">
<path d="M4560 9789 c-1035 -75 -1998 -463 -2800 -1128 -150 -123 -501 -475 -622 -621 -881 -1068 -1270 -2403 -1098
-3765 119 -934 501 -1801 1124 -2545 98 -117 370 -393 496 -504 1310 -1150 3104 -1521 4760 -984 1113 361 2076 1129
2686 2144 503 838 746 1840 684 2819 -70 1094 -491 2109 -1216 2935 -112 128 -386 398 -509 501 -762 642 -1665 1026
-2665 1134 -174 19 -664 27 -840 14z m761 -455 c1240 -117 2371 -748 3128 -1744 424 -557 710 -1208 835 -1900 52 -286
61 -399 61 -790 0 -391 -9 -504 -61 -790 -141 -778 -483 -1499 -999 -2105 -183 -215 -476 -491 -697 -657 -646 -484 -1361
-772 -2168 -874 -170 -21 -683 -30 -865 -15 -380 32 -701 96 -1055 212 -205 67 -343 125 -575 240 -434 214 -782 461 -1128
799 -394 384 -674 772 -906 1252 -237 490 -375 983 -425 1517 -21 217 -21 625 0 842 118 1247 751 2377 1762 3142 629 477
1433 792 2207 867 61 5 124 12 140 14 96 11 598 4 746 -10z"
style="stroke-width: 0; stroke: rgb(186, 218, 85); fill: rgb(100, 100, 100);">
</path>
<path d="M 3327 5687 L 4113 4113 L 5687 3327 L 7260 2540 L 6473 4114 L 5685 5688 L 4113 6474 L 2540 7260 L 3327 5687 Z M
4310 5920 C 4868 5600 5327 5336 5329 5334 C 5334 5330 4480 4460 4471 4460 C 4466 4460 3338 6384 3281 6488 C 3270 6509 3270
6512 3282 6508 C 3289 6505 3752 6240 4310 5920 Z"
style="paint-order: fill; stroke-miterlimit: 17; stroke-width: 0; stroke: rgb(186, 218, 85);"
transform="matrix(-0.71934, 0.694658, -0.694658, -0.71934, 11828.591045, 5020.93852)">
</path>
<path d="M 529.541 -602.23 L 1649.54 2737.764 L -590.458 2737.764 L 529.541 -602.23 Z"
data-bx-shape="triangle -590.458 -602.23 2239.998 3339.994 0.5 0 1@2bca6eb4"
style="stroke: rgb(0, 0, 0); fill: rgb(255, 0, 0);"
transform="matrix(0.999848, 0.01745, 0.01745, -0.999848, 4322.733101, 7628.084939)">
</path>
</g>
</svg>`;
        return html;
    }

    mouseover_compass(screen_position) {
        return cn_dist(this._compass_center(), screen_position) < this._compass_radius();
    }

    _compass_corner() {
        return [this._width - 90, this._height - 120];
    }

    _compass_radius() {
        return 30;
    }

    _compass_center() {
        return cn_add(this._compass_corner(), [this._compass_radius(), this._compass_radius()]);
    }

    //***********************************************************************************
    //**** Snap methods
    //***********************************************************************************
    snap_world(p) {
        if (!this.use_grid) return cn_clone(p);
        var x = this._grid_min_step * Math.round(p[0] / this._grid_min_step);
        if (Math.abs(x - p[0]) > this.snap_world_distance)
            x = p[0];
        var y = this._grid_min_step * Math.round(p[1] / this._grid_min_step);
        if (Math.abs(y - p[1]) > this.snap_world_distance)
            y = p[1];
        return [x, y];
    }

    snap_screen(p) {
        return this.world_to_screen(this.snap_world(this.screen_to_world(p)));
    }

    snap_edge(p, edge0, edge1, previous_point = null) {

        var res = {};
        res.snap_grid = [false, false];
        res.snap_vertex = -1;
        res.snap_previous = false;

        var snap = false;

        var direction = cn_sub(edge1, edge0);
        var length = cn_normalize(direction);
        var z0 = cn_dot(cn_sub(p, edge0), direction);
        res.point = cn_add(edge0, cn_mul(direction, z0));

        //*** special case if snap on an edge vertex
        if (z0 <= this.snap_world_distance) {
            res.point = [edge0[0], edge0[1]];
            res.snap_vertex = 0;
            res.position = 0;
            return res;
        }
        if (z0 >= length - this.snap_world_distance) {
            res.point = [edge1[0], edge1[1]];
            res.snap_vertex = 1;
            res.position = length;
            return res;
        }

        res.snap_vertex = -1;
        var min_distance = this.snap_world_distance;
        for (var k = 0; k < 2; k++) {
            if (Math.abs(direction[k]) < 0.0001) continue;

            //*** snap on grid ?
            if (this.use_grid) {
                var x = this._grid_min_step * Math.round(p[k] / this._grid_min_step);
                var z = (x - edge0[k]) / direction[k];
                var dst = Math.abs(z - z0);
                if (z > 0.01 && z < length - 0.01 && dst < min_distance) {
                    min_distance = dst;
                    res.point = cn_add(edge0, cn_mul(direction, z));
                    res.snap_grid[k] = true;
                    res.snap_previous = false;
                    res.position = z / length;
                    snap = true;
                }
            }

            //*** snap on previous point ?
            if (previous_point == null) continue;
            z = (previous_point[k] - edge0[k]) / direction[k];
            if (z <= 0.01 || z >= length - 0.01) continue;
            dst = Math.abs(z - z0);
            if (dst >= min_distance) continue;
            res.point = cn_add(edge0, cn_mul(direction, z));
            res.snap_grid[0] = false;
            res.snap_grid[1] = false;
            res.snap_previous = true;
            res.position = z / length;
            snap = true;
        }

        var screen_point = this.world_to_screen(res.point);
        if (res.snap_grid[0])
            res.svg += '<line class=\'snap_grid\' x1=\'' + screen_point[0] + '\' y1=\'' + (screen_point[1] - this.snap_screen_distance * 2) + '\' x2=\'' + screen_point[0] + '\' y2=\'' + (screen_point[1] + this.snap_screen_distance * 2) + '\' />';

        if (res.snap_grid[1])
            res.svg += '<line class=\'snap_grid\' x1=\'' + (screen_point[0] - this.snap_screen_distance * 2) + '\' y1=\'' + screen_point[1] + '\' x2=\'' + (screen_point[0] + this.snap_screen_distance * 2) + '\' y2=\'' + screen_point[1] + '\' />';

        if (res.snap_previous) {
            var pp = this.world_to_screen(previous_point);
            res.svg += '<line class=\'snap_previous\' x1=\'' + pp[0] + '\' y1=\'' + pp[1] + '\' x2=\'' + screen_point[0] + '\' y2=\'' + screen_point[1] + '\' />';
        }
        return res;
    }

    //***********************************************************************************
    //**** Mouseover utilities
    //***********************************************************************************
    on_vertex(mouse_world_position, p, pixels = 10) {
        const v0 = this.world_to_screen(mouse_world_position);
        const v1 = this.world_to_screen(p);
        if (!v0 || !v1) return false;
        return cn_dist(v0, v1) < pixels;
    }

    on_edge(mouse_world_position, p0, p1) {
        var dir = cn_sub(p1, p0);
        var length = cn_normalize(dir);
        if (length < 10 * this.screen_to_world_scale) return false;
        var x = cn_dot(cn_sub(mouse_world_position, p0), dir);
        if (x < 0 || x > length) return false;
        var y = cn_dot(cn_sub(mouse_world_position, p0), cn_normal(dir));
        if (Math.abs(y) > 10 * this.screen_to_world_scale) return false;
        return true;
    }

    //***********************************************************************************
    //**** Mouse utilities
    //***********************************************************************************

    pan(dx, dy) {
        this._matrix[0] -= dx * this.screen_to_world_scale;
        this._matrix[1] += dy * this.screen_to_world_scale;
        this._update_matrices();
    }

    wheel(mouse_position, forward) {
        this.pan(0.5 * this._width - mouse_position[0], 0.5 * this._height - mouse_position[1]);
        if (forward)
            this._matrix[2] *= 1.05;
        else
            this._matrix[2] /= 1.05;
        this._update_matrices();
        this.pan(-0.5 * this._width + mouse_position[0], -0.5 * this._height + mouse_position[1]);
    }

    wheel_ratio(mouse_position, ratio) {
        this.pan(0.5 * this._width - mouse_position[0], 0.5 * this._height - mouse_position[1]);
        this._matrix[2] *= ratio;
        this._update_matrices();
        this.pan(-0.5 * this._width + mouse_position[0], -0.5 * this._height + mouse_position[1]);
    }

    //***********************************************************************************
    //**** Draw a measure
    //***********************************************************************************
    /**
     *
     * @param {Array<number>} p0 : first point of the measure
     * @param {Array<number>} p1 : second point of the measure
     * @param {Array<number>} sel : if not null, output for the screen coordinates of the actual ends of the measure
     * @param {boolean} selected : highlight on the measure
     * @param {number|boolean} offset : disrance from the acutal measured points to the measure line, in pixels. If boolean, 20 pixels for true.
     * @param {string[]} extra_classes : additional class'es for the measure line and text box
     * @param {string} text : text to be displayed in the measure box
     * @param {boolean} force_display : override camera show measure constraint
     */
    draw_measure(p0, p1, sel = null, selected = false, offset = true, extra_classes = [], text = '', force_display = false) {

        let html = ``;
        if (this.show_measures || force_display) {
            const extra = extra_classes.join(' ');
            html += `<line class="dimensionning_line ${extra}" `;
            let v0 = this.world_to_screen(p0);
            let v1 = this.world_to_screen(p1);
            if (!v0.length || !v1.length) return html;
            if (offset) {
                let o = cn_normal(cn_sub(v1, v0));
                cn_normalize(o);
                let off = (typeof (offset) == 'number') ? offset : 20;
                o = cn_mul(o, off);
                v0 = cn_add(v0, o);
                v1 = cn_add(v1, o);
            }

            html += `x1="${v0[0]}" y1="${v0[1]}" `;
            html += `x2="${v1[0]}" y2="${v1[1]}" `;
            html += `/>`;

            const text_css_class = [...extra_classes];
            if (sel) {
                if (selected)
                    text_css_class.push('selected')
                else
                    text_css_class.push('dimensionning_text_selectable')
                const pp = cn_middle(v0, v1);
                sel[0] = pp[0];
                sel[1] = pp[1];
                if (sel.length >= 3) sel[2] = cnx_dist(p0, p1);
            }
            const displayed_text = (text !== '') ? text : cnx_dist(p0, p1).toFixed(3);
            html += `<text class="dimensionning_text ${text_css_class.join(' ')}" x='${(v0[0] + v1[0]) * 0.5}' y='${(v0[1] + v1[1]) * 0.5 + 7}'>${displayed_text}</text>`;
        }
        return html;
    }

    //***********************************************************************************
    //**** Draws an angle
    //***********************************************************************************
    draw_svg_angle(point, dir0, dir1) {

        let html = ``;
        if (this.show_angles) {
            let a0 = cn_polar(dir0)[1];
            let a1 = cn_polar(dir1)[1];
            while (a1 < a0) a1 += 2 * Math.PI;
            while (a1 - a0 > 2 * Math.PI) a1 -= 2 * Math.PI;

            const c0 = this.world_to_screen(point);
            const d0 = cn_cart([1, a0]);
            const d1 = cn_cart([1, a1]);

            const base_line_length = 50 * this.screen_to_world_scale;

            let v0 = this.world_to_screen(cn_add(point, cn_cart([base_line_length, a0])));
            html += `<line class="angle_base_line" x1="${c0[0]}" y1="${c0[1]}" x2="${v0[0]}" y2="${v0[1]}" />`;

            let v1 = this.world_to_screen(cn_add(point, cn_cart([base_line_length, a1])));
            html += `<line class="angle_base_line" x1="${c0[0]}" y1="${c0[1]}" x2="${v1[0]}" y2="${v1[1]}" />`;

            const screen_radius = 40;
            const radius = screen_radius * this.screen_to_world_scale;
            v0 = this.world_to_screen(cn_add(point, cn_cart([radius, a0])));
            v1 = this.world_to_screen(cn_add(point, cn_cart([radius, a1])));

            const sense = (a1 - a0 > Math.PI) ? 1 : 0;
            html += `<path class="angle_circle_line" d="M ${v0[0]} ${v0[1]} A ${screen_radius} ${screen_radius} 0 ${sense} 0 ${v1[0]} ${v1[1]}" />`;

            const cc = this.world_to_screen(cn_add(point, cn_cart([radius, (a0 + a1) * 0.5])));
            html += `<circle class="angle_label_fill" cx="${cc[0]}" cy="${cc[1]}" r="20" />`;

            html += `<text class="angle_label_text" x="${cc[0]}" y="${cc[1]}">${((a1 - a0) * 180 / Math.PI).toFixed(1)} °</text>`;
        }
        return html;
    }

    //***********************************************************************************
    //**** draw camembert
    //***********************************************************************************
    draw_camembert(center, a0, a1, radius, text, graph_class, text_class) {
        var html = '';
        var cc = this.world_to_screen(center);
        var v0 = this.world_to_screen(cn_add(center, cn_cart([radius * this.screen_to_world_scale, a0])));
        var v1 = this.world_to_screen(cn_add(center, cn_cart([radius * this.screen_to_world_scale, a1])));
        var sense = (a1 - a0 > Math.PI) ? 1 : 0;
        html += '<path class=\'' + graph_class + '\' d=\'M ' + cc[0] + ' ' + cc[1] + ' L ' + v0[0] + ' ' + v0[1] + ' A ' + radius + ' ' + radius + ' 0 ' + sense + ' 0 ' + v1[0] + ' ' + v1[1] + ' Z\' />';

        if (text !== '') {
            var p = this.world_to_screen(cn_add(center, cn_cart([radius * this.screen_to_world_scale * 0.5, (a0 + a1) * 0.5])));
            html += '<text class=\'' + text_class + '\' x=\'' + p[0] + '\' y=\'' + p[1] + '\'>' + text + '</text>';
        }

        return html;
    }

    //***********************************************************************************
    //**** draw text box
    //***********************************************************************************
    draw_text_box(center, w, h, text, graph_class, text_class) {
        var html = '';

        var p = this.world_to_screen(center);
        html += '<rect class=\'' + graph_class + '\' x=\'' + (p[0] - w / 2) + '\' y=\'' + (p[1] - h / 2) + '\' width=\'' + w + '\' height=\'' + h + '\' />';
        html += '<text class=\'' + text_class + '\' x=\'' + p[0] + '\' y=\'' + p[1] + '\'>' + text + '</text>';
        return html;
    }

    draw_text_box_screen(p, w, h, text, graph_class, text_class) {
        var html = '';
        html += '<rect class=\'' + graph_class + '\' x=\'' + (p[0] - w / 2) + '\' y=\'' + (p[1] - h / 2) + '\' width=\'' + w + '\' height=\'' + h + '\' />';
        html += '<text class=\'' + text_class + '\' x=\'' + p[0] + '\' y=\'' + p[1] + '\'>' + text + '</text>';
        return html;
    }

    //***********************************************************************************
    //**** Update matrices
    //***********************************************************************************
    _update_matrices() {
        var diagonal = Math.sqrt(this._width * this._width + this._height * this._height);
        if (diagonal < 1) diagonal = 1;
        this.screen_to_world_scale = this._matrix[2] / diagonal;
        this._screen_to_world[0] = this._matrix[0] - this.screen_to_world_scale * 0.5 * this._width;
        this._screen_to_world[1] = this._matrix[1] - this.screen_to_world_scale * 0.5 * this._height;

        this.world_to_screen_scale = diagonal / this._matrix[2];
        this._world_to_screen[0] = 0.5 * this._width - this._matrix[0] * this.world_to_screen_scale;
        this._world_to_screen[1] = 0.5 * this._height - this._matrix[1] * this.world_to_screen_scale;

        this.snap_world_distance = (this._width + this._height) * SNAP_RATIO * this.screen_to_world_scale;
        if (this.snap_world_distance < 0.01) this.snap_world_distance = 0.01;
        this.snap_screen_distance = (this._width + this._height) * SNAP_RATIO;
        if (this.snap_screen_distance < 10) this.snap_screen_distance = 10;
    }

    //***********************************************************************************
    //**** Draw a line
    //***********************************************************************************
    draw_line(v0, v1, classes = '') {
        var p0 = this.world_to_screen(v0);
        var p1 = this.world_to_screen(v1);
        if (!p0 || !p1) return '';
        return '<line class=\'' + classes + '\' x1=\'' + p0[0] + '\' y1=\'' + p0[1] + '\' x2=\'' + p1[0] + '\' y2=\'' + p1[1] + '\' />';
    }

    /**
     * Draws 4 arrows around given point
     * @param {number[]} v : point in world coordinates
     * @param {string} classes : draw classes
     * @returns {string} returns svg element
     */
    draw_move_arrow(v, classes = '') {
        return this.draw_move_arrow_screen(this.world_to_screen(v), classes);
    }

    /**
     * Draws 4 arrows around given point
     * @param {number[]} v : point in screen coordinates
     * @param {string} classes : draw classes
     * @returns {string} returns svg element
     */
    draw_move_arrow_screen(v, classes = '') {
        const x = v[0];
        const y = v[1];
        const rect_width = 4;
        const padding = 8;
        const rect_length = 20;
        let svg = '';
        svg += `<path class="move_arrow ${classes}" d="M ${x - rect_width / 2} ${y + padding} V ${y + padding + rect_length} H ${x + rect_width / 2} V ${y + padding} Z" />`;
        svg += `<path class="move_arrow ${classes}" d="M ${x - rect_width / 2} ${y - padding} V ${y - padding - rect_length} H ${x + rect_width / 2} V ${y - padding} Z" />`;
        svg += `<path class="move_arrow ${classes}" d="M ${x + padding} ${y - rect_width / 2} H ${x + padding + rect_length} V ${y + rect_width / 2} H ${x + padding} Z" />`;
        svg += `<path class="move_arrow ${classes}" d="M ${x - padding} ${y - rect_width / 2} H ${x - padding - rect_length} V ${y + rect_width / 2} H ${x - padding} Z" />`;
        return svg;
    }

    /**
     * returns true if mouse is over the point
     * @param {number[]} mouse_world
     * @param {number[]} point
     * @returns {boolean}
     */
    move_arrow_selected(mouse_world, point) {
        return cn_dist(mouse_world, point) < this.snap_world_distance * 3;
    }

    /**
     * returns true if mouse is over the point
     * @param {number[]} mouse_screen
     * @param {number[]} point
     * @returns {boolean}
     */
    move_arrow_selected_screen(mouse_screen, point) {
        if (!point || !mouse_screen) return false;
        return cn_dist(mouse_screen, point) < this.snap_screen_distance * 3;
    }

    /**
     * Draws a cross around given point
     * @param {number[]} v : point in world coordinates
     * @returns {string} returns svg element
     */
    draw_scissor(v) {
        const p0 = this.world_to_screen(v);
        const x = p0[0];
        const y = p0[1];
        const e = 15
        let svg = '';
        svg += `<line x1='${x - e}' x2="${x + e}" y1="${y - e}" y2="${y + e}" stroke="red" stroke-dasharray="4" stroke-width="2"/>`;
        svg += `<line x1='${x - e}' x2="${x + e}" y1="${y + e}" y2="${y - e}" stroke="red" stroke-dasharray="4" stroke-width="2"/>`;
        return svg;
    }

    /**
     * Draws 4 arrows around given point
     * @param {number[]} v0 : point 0 in world coordinates
     * @param {number[]} v1 : point 1 in world coordinates
     * @param {string} classes : draw classes
     * @returns {string} returns svg element
     */
    draw_line_move_arrow(v0, v1, classes = '') {
        var p0 = this.world_to_screen(cn_middle(v0, v1));
        var x = p0[0];
        var y = p0[1];
        var sz0 = 15;
        var sz1 = 40;
        var dsz = 12;
        var svg = '';
        svg += `<g transform="translate(${x},${y}) rotate(${cn_polar(cn_sub(this.world_to_screen(v0), this.world_to_screen(v1)))[1] * 180 / Math.PI}) ">`;
        svg += `<path class="move_arrow ${classes}" d="M ${-dsz}  ${sz0} L 0 ${sz1} ${dsz} ${sz0} Z" />`;
        svg += `<path class="move_arrow ${classes}" d="M ${-dsz}  ${-sz0} L 0 ${-sz1} ${dsz} ${-sz0} Z" />`;
        svg += `</g>`;
        return svg;
    }

    /**
     * Draws 4 arrows around given point
     * @param {number[]} v0 : point 0 in world coordinates
     * @param {number[]} v1 : point 1 in world coordinates
     * @param {string} classes : draw classes
     * @returns {string} returns svg element
     */
    draw_line_move_arrow_screen(v0, v1, classes = '') {
        var p0 = cn_middle(v0, v1);
        var x = p0[0];
        var y = p0[1];
        var sz0 = 15;
        var sz1 = 40;
        var dsz = 12;
        var svg = '';
        svg += `<g transform="translate(${x},${y}) rotate(${cn_polar(cn_sub(v0, v1))[1] * 180 / Math.PI}) ">`;
        svg += `<path class="move_arrow ${classes}" d="M ${-dsz}  ${sz0} L 0 ${sz1} ${dsz} ${sz0} Z" />`;
        svg += `<path class="move_arrow ${classes}" d="M ${-dsz}  ${-sz0} L 0 ${-sz1} ${dsz} ${-sz0} Z" />`;
        svg += `</g>`;
        return svg;
    }

    /**
     * Draws a selectable arrow head
     * @param {Array<number>} p0 : origin of arrow, in screen coordinates
     * @param {Array<number>} dir : direction of arrow, in screen coordinates
     * @param {string} classes : additional classees
     * @returns
     */
    draw_arrow_head(p0, dir, classes = '') {
        const nor = cn_mul(cn_normal(dir), 10);
        const ddir = cn_mul(dir, 30);
        var svg = '';
        svg += `<path class="selectable_arrow_head ${classes}" d="M ${p0[0] + ddir[0]}  ${p0[1] + ddir[1]} L ${p0[0] + nor[0]}  ${p0[1] + nor[1]} ${p0[0] - nor[0]}  ${p0[1] - nor[1]} Z" />`;
        return svg;
    }

    /**
     * returns true if mouse id over the arrow
     * @param {Array<number>} screen_mouse : mouseposition, in screen coordinates
     * @param {Array<number>} p0 : origin of arrow, in screen coordinates
     * @param {Array<number>} dir : direction of arrow, in screen coordinates
     * @returns
     */
    mouse_over_arrow_head(screen_mouse, p0, dir) {
        const nor = cn_normal(dir);
        const d = cn_sub(screen_mouse, p0);
        const x = cn_dot(d, dir);
        if (x < 0 || x > 30) return false;
        const y = cn_dot(d, nor);
        if (Math.abs(y) > 10) return false;
        return true;
    }

    /**
     * Draw a path
     * @param {Array<number[]>} vertices
     * @param {string} classes
     * @param {boolean} closed
     */
    draw_path(vertices, classes, closed = false) {
        var svg = '';
        if (vertices.length < 2) return svg;
        svg += `<path class="${classes}" d="`;

        vertices.forEach((v, i) => {
            const p = this.world_to_screen(v);
            if (!p) return '';
            if (i == 0) svg += 'M ';
            if (i == 1) svg += 'L ';
            svg += `${p[0]} ${p[1]} `;
        });
        if (closed) svg += 'Z';
        svg += `" />`;
        return svg;
    }

    //***********************************************************************************
    /**
     * Draws a polygon in SVG.
     * @param {fh_polygon} polygon
     * @param {string} classes
     */
    draw_polygon(polygon, classes = '') {
        var svg = '';
        polygon.compute_contours();
        if (polygon.contour_sizes.length == 0) return svg;
        svg += `<path class="${classes}" d="`;
        var offset = 0;
        for (var nc = 0; nc < polygon.contour_sizes.length; nc++) {
            var sz = polygon.contour_sizes[nc];
            for (var nv = 0; nv < sz; nv++) {
                if (nv == 0) svg += 'M ';
                else if (nv == 1) svg += 'L ';
                var p = this.world_to_screen(polygon.contour_vertices[offset + nv]);
                if (!p.length) return '';
                svg += `${p[0]} ${p[1]} `;
            }
            svg += `Z `;

            offset += sz;
        }
        svg += '"  fill-rule="evenodd" />';

        return svg;
    }

    //***********************************************************************************

    //***********************************************************************************
    /**
     * Draws a solid in SVG.
     * @param {fh_solid} solid
     * @param {string} classes
     */
    draw_solid(solid, classes = '') {
        var svg = '';
        const faces = solid.get_faces();
        faces.forEach(f => svg += this.draw_polygon(f, classes));
        return svg;
    }

    /**
     * Draws a 3D circle from 3D world coordinates
     * @param {Array<number>} center
     * @param {number} radius
     * @param {Array<number>} normal
     * @param {string} classes
     * @param {number} nb_samples
     * @returns
     */
    draw_3d_circle(center, radius, normal, classes = '', nb_samples = 32) {
        const dx = [0, 0, 0];
        const dy = [0, 0, 0];
        cnx_build_axis(normal, dx, dy);
        var svg = '';
        svg += `<path class="${classes}" d="`;
        for (var s = 0; s < nb_samples; s++) {
            const theta = 2 * Math.PI * s / nb_samples;
            const p = cnx_add(center, cnx_mul(cnx_add(cnx_mul(dx, Math.cos(theta)), cnx_mul(dy, Math.sin(theta))), radius));
            const sp = this.world_to_screen(p);
            if (!sp.length) return '';
            if (s == 0) svg += 'M ';
            else if (s == 1) svg += 'L ';
            svg += `${sp[0]} ${sp[1]} `;
        }
        svg += `Z" />`;
        return svg;
    }
}

