'use strict';

import { cn_facing_layer } from './cn_facing_layer';
import { cn_element_type } from './cn_element_type';
import { cn_element_type_visitor } from '../utils/visitors/cn_element_type_visitor';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************


//***********************************************************************************
/**
 * @class cn_facing
 * Class to modelize the facing of a wall, slab, etc.
 */
export class cn_facing extends cn_element_type {
    //***********************************************************************************
    /**
     * Constructor
     * @param { 'floor' | 'ceiling' | 'wall' | 'facade' | 'exterior' } support
     */
    constructor(support) {
        super();
        this.class_name = 'cn_facing';
        this.support = support
        this.name = '';
        this.category = '';
        this.texture = '';
        this.color = '';
        this.description = '';
        this.facing_layers = [];
    }

    //*******************************************************
    /**
     * clone this
     * @returns {cn_facing}
     */
    clone() {
        const cloned = cn_facing.unserialize(this.serialize());
        cloned.ID = cn_element_type.generate_ID(); // generates another ID
        return cloned;
    }

    //***********************************************************************************
    /**
     * serialize
     * @returns {object}
     */
    serialize() {
        const json = {};
        json.class_name = 'cn_facing';
        json.ID = this.ID;
        json.name = this.name;
        json.support = this.support;
        json.description = this.description;
        json.texture = this.texture;
        json.color = this.color;
        json.category = this.category;
        json.facing_layers = this.facing_layers.map(layer => layer.serialize());
        return json;
    }

    //***********************************************************************************
    /**
     * unserialize
     * @param {object} json
     * @returns {cn_facing | null}
     */
    static unserialize(json) {
        const result = new cn_facing(json.support);
        result.ID = json.ID;
        result.name = json.name;
        result.description = json.description;
        result.category = json.category;
        result.texture = json.texture;
        result.facing_layers = json.facing_layers.map(layer => cn_facing_layer.unserialize(layer));
        result.color = json.color;
        return result;
    }

    //***********************************************************************************
    //**** return label
    //***********************************************************************************
    get_label() {
        return this.name;
    }

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        const description = [];

        if (this.description) {
            description.push({ label: 'Description', value: this.description });
        }

        const layers = { label: 'Matériaux' };
        layers.value = [];

        for (let i in this.facing_layers) {
            layers.value.push(this.facing_layers[i].get_label());
        }
        if (layers.value.length) {
            description.push(layers);
        }

        return description;
    }

    /**
     * Accept element visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_facing_type(this);
    }

}

