export class EmitterPowerSpecifications {
  a: number;
  b: number;
  c: number;
  n: number;

  constructor(a: number, b: number, c: number, n: number) {
    this.a = a;
    this.b = b;
    this.c = c;
    this.n = n;
  }
}
